import React, {Suspense} from "react";
import {BillingBaseV3} from "./common/BillingBaseV3";
import {BillingStep} from "./common/BillingStep";
import {BillingDetailsStep} from "./billing/BillingDetailsStep";
import {CartStep} from "./cart/CartStep";
import {OrderFlowContextProvider} from "../hooks/OrderFlowContext";
import {PaymentStep} from "./payment/PaymentStep";
import {useFragment} from "react-relay";
import {OrderFlowScreen_OrderFragment$key} from "../../../../__generated__/OrderFlowScreen_OrderFragment.graphql";
import {graphql} from "babel-plugin-relay/macro";
import {OrderFlowScreen_ViewerFragment$key} from "../../../../__generated__/OrderFlowScreen_ViewerFragment.graphql";
import {ReviewStep} from "./review/ReviewStep";
import {OrderFlowFooter} from "./footer/OrderFlowFooter";
import {BillingDetailsStepClosed} from "./billing/BillingDetailsStepClosed";
import {CartStepClosed} from "./cart/CartStepClosed";
import {PaymentStepClosed} from "./payment/PaymentStepClosed";
import {ThankYouV3} from "./ThankYouV3";

const ORDER_FRAGMENT = graphql`
    fragment OrderFlowScreen_OrderFragment on Order {
        id
        status
        cart {
            ...CartStep_CartFragment
            ...CartStepClosed_CartFragment
        }
        billingDetails {
            ...BillingDetailsStepClosed_BillingDetailsFragment
        }
        ...BillingDetailsStep_OrderFragment
        ...PaymentStep_OrderFragment
        ...PaymentStepClosed_OrderFragment
        ...ReviewStep_OrderFragment
        ...OrderFlowFooter_OrderFragment
        ...OrderFlowContextProvider_OrderFragment
    }
`;

const VIEWER_FRAGMENT = graphql`
    fragment OrderFlowScreen_ViewerFragment on Query {
        Viewer {
            Auth {
                User {
                    ...BillingDetailsStep_UserFragment
                }
            }
        }
    }
`;

interface OrderFlowProps {
    orderFragmentRef: OrderFlowScreen_OrderFragment$key
    viewerFragmentRef: OrderFlowScreen_ViewerFragment$key
}

export const OrderFlow = ({orderFragmentRef, viewerFragmentRef}: OrderFlowProps) => {

    const order = useFragment<OrderFlowScreen_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)
    const viewer = useFragment<OrderFlowScreen_ViewerFragment$key>(VIEWER_FRAGMENT, viewerFragmentRef)

    if (!order) {
        return <div>Bestellung nicht gefunden.</div>;
    }

    if (order.status === "paymentReceived" || order.status === "purchased") {
        return <BillingBaseV3 mainSlot={
            <ThankYouV3/>
        }/>
    }

    return <OrderFlowContextProvider orderFragmentRef={order}>
        <BillingBaseV3 mainSlot={<div>
            <BillingStep
                stage={"cart"}
                renderOpen={() => <Suspense fallback={<div>Lade...</div>}>
                    <CartStep orderId={order.id} cartFragmentRef={order.cart}/>
                </Suspense>}
                renderClosed={() => <Suspense fallback={<div>Lade...</div>}>
                    <CartStepClosed cartFragmentRef={order.cart}/>
                </Suspense>}
            />

            <BillingStep
                stage={"billing"}
                renderOpen={() => {
                    return <Suspense fallback={<div>Lade</div>}>
                        <BillingDetailsStep userFragmentRef={viewer.Viewer.Auth.User}
                                            orderFragmentRef={order}/></Suspense>
                }}
                renderClosed={() => <Suspense fallback={<div>Lade</div>}>
                    <BillingDetailsStepClosed billingDetailsFragmentRef={order.billingDetails}/>
                </Suspense>}
            />

            <BillingStep
                stage={"payment"}
                renderOpen={() => {
                    return <Suspense fallback={<div>Lade</div>}><PaymentStep orderFragmentRef={order}/></Suspense>
                }}
                renderClosed={() => <Suspense fallback={<div>Lade</div>}>
                    <PaymentStepClosed orderFragmentRef={order}/>
                </Suspense>}
            />

            <BillingStep
                stage={"review"}
                renderOpen={() => {
                    return <Suspense fallback={<div>Lade</div>}><ReviewStep orderFragmentRef={order}/></Suspense>
                }}/>

            <OrderFlowFooter orderFragmentRef={order}/>
        </div>}/>
    </OrderFlowContextProvider>
}

