import React from "react"
import LogoBlack from "./logo-black.png"
import Background from "./background.svg"
import styled from "styled-components";
import {RRNLRequestError} from "react-relay-network-modern";
import {useTranslation} from "react-i18next";
import {tkTypography} from "../_modules/core/style/tkTypography";
import {tkColors} from "../_modules/core/style/tkColors";

interface OwnProps {
    error: Error
}

export const ErrorScreen = ({error}: OwnProps) => {
    const {t} = useTranslation("core");

    let errorMessage = <>
        Ups, es gab einen Fehler: {error.message}. Versuche einmal die Seite neuzuladen. Wir haben den Fehler
        geloggt und werden ihn umgehend beheben. Sollte der Fehler häufiger auftreten, wende dich bitte an <a
        href="mailto:app@thekey.academy">app@thekey.academy</a>
    </>;
    if ((error as RRNLRequestError).res?.errors) {
        errorMessage = <>
            {
                (error as RRNLRequestError).res?.errors?.map((error, index) =>
                    <div key={index}
                         dangerouslySetInnerHTML={{__html: t("errors." + error.message)}}/>)
            }
        </>
    }

    return <SplashScreenContainer className="position-absolute top-0 left-0 w-100 h-100">
        <div className="d-flex justify-content-center align-items-center cover-fill h-100 w-100 flex-column p-4"
             style={{
                 backgroundSize: 'cover',
                 backgroundImage: `url('${Background}')`,
             }}>
            <div>
                <img className="w-100 h-auto mb-4" alt={"Lade..."} src={LogoBlack}/>
            </div>
            <Heading className="text-center">
                {errorMessage}
            </Heading>
        </div>
    </SplashScreenContainer>
}

const SplashScreenContainer = styled.div`
  z-index: 100;
`

const Heading = styled.h1`
  ${tkTypography.largeBold};
  ${tkColors.darkBlue};
  max-width: 1024px;
`
