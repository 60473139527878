/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PrivateForm_UserFragment = {
    readonly id: string;
    readonly email: string;
    readonly meta: {
        readonly firstName: string;
        readonly lastName: string;
    } | null;
    readonly " $refType": "PrivateForm_UserFragment";
};
export type PrivateForm_UserFragment$data = PrivateForm_UserFragment;
export type PrivateForm_UserFragment$key = {
    readonly " $data"?: PrivateForm_UserFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"PrivateForm_UserFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PrivateForm_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserMeta",
      "kind": "LinkedField",
      "name": "meta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '4b3542a72428e90ff4c34aed06831295';
export default node;
