import {combineEpics} from "redux-observable";
import {
    API_SEND_SUBMISSIONS,
    fetchGamificationDataAction,
    fetchUnacknowledgedAchievementsAction
} from "@thekeytechnology/thekey-academy-frontend-library";
import {Action} from "redux";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {fetchStatesAction} from "../../state/actions/fetch-states";
import {matchesAnyOfTheseApiSuccesses} from "@thekeytechnology/framework-react";

const refetchStates = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({apiType: API_SEND_SUBMISSIONS}),
    map(() => fetchStatesAction())
);

const refetchGamificationDataOnSubmission$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({apiType: API_SEND_SUBMISSIONS}),
    map(() => fetchGamificationDataAction())
);

const refetchUnacknowledgedAchievementsOnSubmission$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({apiType: API_SEND_SUBMISSIONS}),
    map(() => fetchUnacknowledgedAchievementsAction())
);


export const additionalSubmissionModuleEpics = combineEpics(
    refetchStates,
    refetchGamificationDataOnSubmission$,
    refetchUnacknowledgedAchievementsOnSubmission$
)
