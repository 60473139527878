/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type RegistrationInLicensePoolScreen_LicensePoolInformationQueryVariables = {
    licensePoolId: string;
};
export type RegistrationInLicensePoolScreen_LicensePoolInformationQueryResponse = {
    readonly LicenseAuth: {
        readonly LicensePoolInformation: {
            readonly accountName: string;
        };
    };
};
export type RegistrationInLicensePoolScreen_LicensePoolInformationQuery = {
    readonly response: RegistrationInLicensePoolScreen_LicensePoolInformationQueryResponse;
    readonly variables: RegistrationInLicensePoolScreen_LicensePoolInformationQueryVariables;
};



/*
query RegistrationInLicensePoolScreen_LicensePoolInformationQuery(
  $licensePoolId: ID!
) {
  LicenseAuth {
    LicensePoolInformation(licensePoolId: $licensePoolId) {
      accountName
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "licensePoolId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "LicenseAuthQueries",
    "kind": "LinkedField",
    "name": "LicenseAuth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "licensePoolId",
            "variableName": "licensePoolId"
          }
        ],
        "concreteType": "LicensePoolInformationType",
        "kind": "LinkedField",
        "name": "LicensePoolInformation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RegistrationInLicensePoolScreen_LicensePoolInformationQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RegistrationInLicensePoolScreen_LicensePoolInformationQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d217e00cf03cddf18565aaec4a0d0a44",
    "id": null,
    "metadata": {},
    "name": "RegistrationInLicensePoolScreen_LicensePoolInformationQuery",
    "operationKind": "query",
    "text": "query RegistrationInLicensePoolScreen_LicensePoolInformationQuery(\n  $licensePoolId: ID!\n) {\n  LicenseAuth {\n    LicensePoolInformation(licensePoolId: $licensePoolId) {\n      accountName\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '2ab5dc6b0d9354a39f3c117a7874c173';
export default node;
