/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FailedElementsDisplay_SubmissionResultFragment = {
    readonly failedElements: ReadonlyArray<{
        readonly " $fragmentRefs": FragmentRefs<"FailedElementCard_FailedElementFragment" | "FailedElementDisplay_FailedElementFragment">;
    }>;
    readonly " $refType": "FailedElementsDisplay_SubmissionResultFragment";
};
export type FailedElementsDisplay_SubmissionResultFragment$data = FailedElementsDisplay_SubmissionResultFragment;
export type FailedElementsDisplay_SubmissionResultFragment$key = {
    readonly " $data"?: FailedElementsDisplay_SubmissionResultFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"FailedElementsDisplay_SubmissionResultFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FailedElementsDisplay_SubmissionResultFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FailedElement",
      "kind": "LinkedField",
      "name": "failedElements",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FailedElementCard_FailedElementFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FailedElementDisplay_FailedElementFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SubmissionResult",
  "abstractKey": null
};
(node as any).hash = '1cc92a177de9d94d6c550fa7717562a0';
export default node;
