import "./redirect-to-mobile-screen.scss"
import React, {useEffect} from "react";

import AppStore from "./app-store.png"
import GooglePlay from "./google-play.png"

function getCookie(name: string) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export const RedirectToMobileAppScreen = () => {
    useEffect(() => {
        const htmlElements = document.getElementsByTagName("html");

        for (let i = 0; i < htmlElements.length; i++) {
            htmlElements.item(i)!.classList.add("no-overflow")
        }
        return () => {
            for (let i = 0; i < htmlElements.length; i++) {
                htmlElements.item(i)!.classList.remove("no-overflow")
            }
        }
    }, [])


    const cookieValue = getCookie("original_source");
    let query: string = "";
    if (cookieValue) {
        const params = JSON.parse(cookieValue);
        query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
    }

    const asjAnonymousId = getCookie("ajs_anonymous_id");
    if (query && asjAnonymousId) {
        query = query + "&anonymous_id=" + asjAnonymousId
    } else if (asjAnonymousId) {
        query = "anonymous_id=" + asjAnonymousId
    }

    let appStoreLink = "https://app.adjust.com/1c25h4g"
    let androidStoreLink = "https://app.adjust.com/q3h5fuh"
    if (cookieValue) {
        appStoreLink = "https://app.adjust.com/fpfee8c"
        androidStoreLink = "https://app.adjust.com/5pzmnlx"
    }
    query = btoa(query);

    return <div className="redirect-to-mobile-screen">
        <div className="h-100 w-100 hello-background content-wrapper p-5">
            <h1 className="tk-academy-larger-bold mb-3">Es kommt auf die Größe an...</h1>
            <p className="tk-academy-large-bold mb-5">Dein Gerät hat eine zu geringe Auflösung für unsere Web-Anwendung.
                Wir haben eine optimierte
                Smartphone App für Dich!</p>

            <p className="tk-academy-text tk-academy-bold mb-2">Jetzt herunterladen und loslernen</p>
            <div className="d-flex flex-wrap">
                <a href={appStoreLink + "?label=" + query} className="mr-2 mb-2">
                    <img alt="Download im AppStore" src={AppStore}/>
                </a>
                <a href={androidStoreLink + "?label=" + query}>
                    <img alt="Download im PlayStore" src={GooglePlay}/>
                </a>
            </div>
        </div>
    </div>
}
