import {default as React, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {removeMessageAction, selectMessages} from "@thekeytechnology/framework-react";

export const MessagesToToast = () => {
    const {t} = useTranslation('core')

    const messages = useSelector(selectMessages)
    const dispatch = useDispatch()

    useEffect(() => {
        messages.forEach(message => {
            switch (message.messageType) {
                case "info" :
                    toast.info(<div dangerouslySetInnerHTML={{__html: t(message.message)}}/>, {toastId: message.id});
                        break
                    case "warning":
                        toast.warning(<div
                            dangerouslySetInnerHTML={{__html: t(message.message)}}/>, {toastId: message.id});
                        break;
                    case "danger":
                        toast.error(<div
                            dangerouslySetInnerHTML={{__html: t(message.message)}}/>, {toastId: message.id});
                        break;
                    case "success":
                        toast.success(<div
                            dangerouslySetInnerHTML={{__html: t(message.message)}}/>, {toastId: message.id});
                        break;
                }

                dispatch(removeMessageAction(message.id))
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [messages]
    )

    return <></>
}
