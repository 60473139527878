/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TextElementDisplay_TextElementFragment = {
    readonly id: string;
    readonly text: string;
    readonly title: string;
    readonly " $refType": "TextElementDisplay_TextElementFragment";
};
export type TextElementDisplay_TextElementFragment$data = TextElementDisplay_TextElementFragment;
export type TextElementDisplay_TextElementFragment$key = {
    readonly " $data"?: TextElementDisplay_TextElementFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"TextElementDisplay_TextElementFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TextElementDisplay_TextElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "TextElement",
  "abstractKey": null
};
(node as any).hash = '453480dc396d047416b9dad362130350';
export default node;
