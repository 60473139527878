/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LessonType = "normal" | "test" | "%future added value";
export type LearnFooter_LessonFragment = {
    readonly lessonType: LessonType;
    readonly " $refType": "LearnFooter_LessonFragment";
};
export type LearnFooter_LessonFragment$data = LearnFooter_LessonFragment;
export type LearnFooter_LessonFragment$key = {
    readonly " $data"?: LearnFooter_LessonFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LearnFooter_LessonFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LearnFooter_LessonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lessonType",
      "storageKey": null
    }
  ],
  "type": "Lesson",
  "abstractKey": "__isLesson"
};
(node as any).hash = 'f347a165a5be278e25f585be7042e791';
export default node;
