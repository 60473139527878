import {Field, FormikState} from "formik";
import React, {ReactNode} from "react";

import "./validated-field.scss";

interface ValidatedFieldProps {
    label?: string;
    name: string;

    formikState: FormikState<any>;

    iconClass?: string;
    helpText?: ReactNode;

    containerClassName?: string;

    // 'Indexer'
    [x: string]: any
}

export const ValidatedField = ({
                                   iconClass,
                                   name,
                                   required,
                                   label,
                                   formikState,
                                   helpText,
                                   containerClassName,
                                   ...rest
                               }: ValidatedFieldProps) => {
    const hasError = formikState.errors[name] && formikState.touched[name];
    const columnClass = label ? "col-sm-10" : null;

    return (
        <div className={`validated-field ${containerClassName ? containerClassName : ""}`}>
            {label ?
                <label htmlFor={name} className="col-sm-2 col-form-label">{label} {required ? "*" : ""}</label> : null}

            <div className={columnClass ? columnClass : ""}>
                {iconClass ? <div className="input-field-wrapper position-relative d-flex align-items-center">
                        <span className={"waterBlue20 position-absolute icon left15 " + iconClass}/>
                        <Field {...rest} name={name} className="waterBlue20 pl-5 form-control default-input"/>
                    </div> :
                    <Field {...rest} name={name} className="form-control default-input"/>
                }

                {hasError ? (
                    <div
                        className="tk-academy-small-text tk-color-orange validation-error">{formikState.errors[name]}</div>
                ) : null}

                {helpText ? (
                    <small className="form-text text-muted">{helpText}</small>
                ) : null}
            </div>
        </div>);
};
