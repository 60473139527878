import React, {useEffect, useState} from "react";

import "./checkout-form.scss"
import {useDispatch, useSelector} from "react-redux";
import {selectCombinedApiState, selectCurrentUser} from "@thekeytechnology/framework-react";
import {useTranslation} from "react-i18next";
import {LoadingAnimation} from "../common/LoadingAnimation";
import {CustomerTypeSelector} from "./checkout/CustomerTypeSelect";
import {CheckoutFormBusinessV2} from "./checkout/CheckoutFormBusinessV2";
import {CheckoutFormPrivateV2} from "./checkout/CheckoutFormPrivateV2";
import {NavLink} from "react-router-dom";
import {AcademyButton} from "../../../../core/components/button/AcademyButton";
import {
    API_ENSURE_ORDER,
    CoursePriceTableItem,
    ensureOrderAction,
    fetchUserMetaV2,
    PersonalizedOfferV2,
    selectAppliedVouchers,
    selectSelectedUpsells,
    selectUserMetaV2
} from "@thekeytechnology/thekey-academy-frontend-library";
import {CartLoginV2} from "./cart/CartLoginV2";
import {CustomerType} from "../../../../../__generated__/usePaymentLogicV3_OrderFragment.graphql";

interface OwnProps {
    offer: PersonalizedOfferV2
}

export const CheckoutFormV2 = ({offer}: OwnProps) => {
    const {t} = useTranslation("billing")
    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser);
    const userMeta = useSelector(selectUserMetaV2);
    const apiCallState = useSelector(selectCombinedApiState(API_ENSURE_ORDER))
    const appliedVouchers = useSelector(selectAppliedVouchers)
    const selectedUpsells = useSelector(selectSelectedUpsells)

    const [customerType, setCustomerType] = useState<CustomerType>(offer.baseOffer.entity.forcedCustomerType || "private");

    useEffect(() => {
        if (user) {
            dispatch(fetchUserMetaV2())
        }
    }, [user, dispatch])


    const everythingOwned = offer.priceTable.offerItems.map(i => {
        if (i.itemType === "course-item") {
            return (i as CoursePriceTableItem).isOwned
        } else {
            return true;
        }
    }).reduce((a, b) => a && b)

    return everythingOwned ?
        <div className="d-flex justify-content-center flex-column p-5 align-items-center p-3 tk-academy-card">
            <h3 className="tk-academy-huge-text tk-color-waterblue tk-academy-bold text-center mb-2">Du besitzt alle
                Lehrgänge
                bereits</h3>
            <div className="tk-academy-large tk-color-darkblue mb-3 text-center">
                Dieses Angebot kannst du leider nicht wahrnehmen.
            </div>
            <NavLink to={`/}`}>
                <AcademyButton text={"Lernerfahrung starten"}/>
            </NavLink>
        </div> : <div className="checkout-form position-relative p-3 tk-academy-card">
            <h3 className="tk-academy-large tk-color-waterblue-80 tk-academy-bold">{t("checkout-form.heading")}</h3>
            <hr className="mt-3 mb-3"/>

            <div className="tk-color-darkblue tk-academy-large mb-4">{t("checkout-form.explanation")}</div>

            <CartLoginV2 className={"mb-4"}/>

            <h3 className="tk-academy-large tk-color-waterblue-80 tk-academy-bold mb-4">{t("checkout-form.billing-data")}</h3>

            {userMeta ?
                <h3 className="tk-academy-large tk-color-waterblue-80 tk-academy-bold mb-3">
                    {t("checkout-form.greeting", {
                        firstName: userMeta.entity.firstName,
                        lastName: userMeta.entity.lastName
                    })}
                </h3> : null}
            <CustomerTypeSelector className="mb-3"
                                  customerType={customerType}
                                  forcedCustomerType={offer.baseOffer.entity.forcedCustomerType}
                                  updateCustomerType={c => setCustomerType(c)}/>

            {customerType === "business" ?
                <CheckoutFormBusinessV2
                    apiCallState={apiCallState}
                    user={user}
                    userMeta={userMeta} onSubmit={(billingDetails, licenseAttribution, signup) => {
                    dispatch(ensureOrderAction(
                        offer.baseOffer.id!,
                        appliedVouchers,
                        selectedUpsells,
                        billingDetails,
                        licenseAttribution,
                        signup
                    ))
                }
                }/> :
                <CheckoutFormPrivateV2 apiCallState={apiCallState} user={user} userMeta={userMeta}
                                       onSubmit={(billingDetails, licenseAttribution, signup) => {
                                           dispatch(ensureOrderAction(
                                               offer.baseOffer.id!,
                                               appliedVouchers,
                                               selectedUpsells,
                                               billingDetails,
                                               licenseAttribution,
                                               signup
                                           ))
                                       }}/>
            }

            {apiCallState.inProgress ? <LoadingAnimation/> : null}
        </div>
}
