/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseDetailFooter_CourseFragment = {
    readonly " $fragmentRefs": FragmentRefs<"AdvanceCourseButton_CourseFragment">;
    readonly " $refType": "CourseDetailFooter_CourseFragment";
};
export type CourseDetailFooter_CourseFragment$data = CourseDetailFooter_CourseFragment;
export type CourseDetailFooter_CourseFragment$key = {
    readonly " $data"?: CourseDetailFooter_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseDetailFooter_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseDetailFooter_CourseFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdvanceCourseButton_CourseFragment"
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = 'a3f306ad76c2231f13d4367f958944b9';
export default node;
