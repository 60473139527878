import React from "react";
import {Menu} from "../../../menu/components/Menu";
import "../../../course/components/common/course-sidebar/course-sidebar.scss"
import {CourseSidebar} from "../../../course/components/common/course-sidebar/CourseSidebar";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {LessonDetailSidebar_LessonFragment$key} from "../../../../__generated__/LessonDetailSidebar_LessonFragment.graphql";
import {LessonDetailSidebar_CourseStateFragment$key} from "../../../../__generated__/LessonDetailSidebar_CourseStateFragment.graphql";

const LESSON_FRAGMENT = graphql`
    fragment LessonDetailSidebar_LessonFragment on Lesson {
        containerMeta {
            title
        }
        course {
            ...CourseSidebar_CourseFragment
        }
        id
        lessonPath {
            lessonIndex
            moduleIndex
        }
        lessonType
        ... on NormalLesson {
            additionalContent {
                additionalContent {
                    id
                }
            }
            glossary {
                id
            }
        }
    }`

const COURSE_STATE_FRAGMENT = graphql`
    fragment LessonDetailSidebar_CourseStateFragment on CourseState {
        ...CourseSidebar_CourseStateFragment
    }`

interface OwnProps {
    courseId: string;
    lessonFragmentRef: LessonDetailSidebar_LessonFragment$key;
    courseStateFragmentRef?: LessonDetailSidebar_CourseStateFragment$key | null;
    moduleId: string;
    numUnseenCustomerMessages: number;
    newLicensesInAccount: boolean;
}

export const LessonDetailSidebar = ({courseId, lessonFragmentRef, courseStateFragmentRef, moduleId, numUnseenCustomerMessages, newLicensesInAccount}: OwnProps) => {

    const lesson = useFragment<LessonDetailSidebar_LessonFragment$key>(LESSON_FRAGMENT, lessonFragmentRef);

    const courseState = useFragment<LessonDetailSidebar_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, courseStateFragmentRef || null);

    const subItems = [
        {
            key: "course.overview.info",
            path: `/courses/${courseId}/modules/${moduleId}/lessons/${lesson.id}#info`,
            icon: "icon-a_info"
        },
        {
            key: "course.overview.experts",
            path: `/courses/${courseId}/modules/${moduleId}/lessons/${lesson.id}#experts`,
            icon: "icon-a_trainer"
        }
    ];

    if (lesson.lessonType === "normal" && lesson.additionalContent!.length > 0) {
        subItems.push({
            key: "course.overview.additional-content",
            path: `/courses/${courseId}/modules/${moduleId}/lessons/${lesson.id}#additional-content`,
            icon: "icon-a_material"
        })
    }

    const items = [
        {
            key: "course.back-to-course",
            path: `/courses/${courseId}`
        },
        {
            key: "course.overview.label",
            path: `/courses/${courseId}/modules/${moduleId}/lessons/${lesson.id}`,
            subItems
        },
        {
            key: "course.notes",
            path: `/courses/${courseId}/modules/${moduleId}/lessons/${lesson.id}/notes`
        },
        {
            key: "course.qnas",
            path: `/courses/${courseId}/modules/${moduleId}/lessons/${lesson.id}/qnas`
        }];

    if (lesson.lessonType === "normal" && lesson.glossary && lesson.glossary.length > 0) {
        items.push({
            key: "course.glossary",
            path: `/courses/${courseId}/modules/${moduleId}/lessons/${lesson.id}/glossary`
        });
    }

    return <CourseSidebar courseFragmentRef={lesson.course} courseStateFragmentRef={courseState} numUnseenCustomerMessages={numUnseenCustomerMessages} newLicensesInAccount={newLicensesInAccount}
        underHeaderSlot={<div className="pl-3 ">
            <div className="tk-academy-text tk-academy-bold tk-color-waterblue-80"
                 id="info">
                Lektion {lesson.lessonPath.moduleIndex + 1}.{lesson.lessonPath.lessonIndex + 1} | {lesson.containerMeta.title}
            </div>
        </div>}
        submenuSlot={
            <div className="tk-bg-waterblue flex-grow-1 overflow-auto">
                <Menu
                    items={items}
                    rootSizeClass="tk-academy-text"
                />
            </div>
        }/>;
}
