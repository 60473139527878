import React from "react";
import {AcademyButton} from "../../core/components/button/AcademyButton";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {FileAdditionalContentDisplay_FileAdditionalContentFragment$key} from "../../../__generated__/FileAdditionalContentDisplay_FileAdditionalContentFragment.graphql";

const FILE_ADDITIONAL_CONTENT_DISPLAY_FILE_ADDITIONAL_CONTENT_FRAGMENT = graphql`
    fragment FileAdditionalContentDisplay_FileAdditionalContentFragment on FileAdditionalContent {
        file {
            url
        }
        title
    }`

interface OwnProps {
    fileAdditionalContentFragmentRef: FileAdditionalContentDisplay_FileAdditionalContentFragment$key;
}

export const FileAdditionalContentDisplay = ({
                                                 fileAdditionalContentFragmentRef
                                             }: OwnProps) => {

    const additionalContent = useFragment<FileAdditionalContentDisplay_FileAdditionalContentFragment$key>(FILE_ADDITIONAL_CONTENT_DISPLAY_FILE_ADDITIONAL_CONTENT_FRAGMENT, fileAdditionalContentFragmentRef);

    return <div>
        <h1 className="tk-academy-larger-bold mb-3 tk-color-darkblue">{additionalContent.title}</h1>
        {additionalContent.file?.url && <a rel="noopener noreferrer" target="_blank" href={additionalContent.file.url}><AcademyButton
            text={"Herunterladen"}/></a>}
    </div>

}
