import React from "react";
import {
    LESSON_STATUS_FINISHED
} from "@thekeytechnology/thekey-academy-frontend-library";
import "./progress-bar.scss";
import {StatusIcon} from "../status/StatusIcon";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {AverageCoursePointsProgressBar_CourseStateFragment$key} from "../../../../__generated__/AverageCoursePointsProgressBar_CourseStateFragment.graphql";

const AVERAGE_COURSE_POINTS_PROGRESS_BAR_COURSE_STATE_FRAGMENT = graphql`
    fragment AverageCoursePointsProgressBar_CourseStateFragment on CourseState {
        course {
            lessons {
                id
                containerMeta {
                    points
                }
            }
        }
        id
        lessonStates {
            points
            status
        }
        progress {
            performancePercentage
        }
    }`

interface OwnProps {
    courseStateFragmentRef: AverageCoursePointsProgressBar_CourseStateFragment$key;
}

export const AverageCoursePointsProgressBar = ({
                                                   courseStateFragmentRef,
                                               }: OwnProps) => {

    const courseState = useFragment<AverageCoursePointsProgressBar_CourseStateFragment$key>(AVERAGE_COURSE_POINTS_PROGRESS_BAR_COURSE_STATE_FRAGMENT, courseStateFragmentRef);

    const course = courseState.course;

    const pointsGotten: number = Object.entries(courseState.lessonStates)
        .map(ls => ls[1].points)
        .reduce((a, b) => a + b);

    const pointsList = Object.entries(courseState.lessonStates)
        .filter(ls => ls[1].status === LESSON_STATUS_FINISHED)
        .map(ls => course.lessons.find(l => l.id === ls[0])?.containerMeta.points)
        .filter(n => n !== undefined);
    const pointsPossible: number | undefined = pointsList.length > 0 ? pointsList
        .reduce((a, b) => a! + b!) : 0;

    const performancePercentage = courseState.progress.performancePercentage ? courseState.progress.performancePercentage : 0;

    return <div className="d-flex flex-column">
        <div className="d-flex mb-2">
            <div className="tk-academy-text tk-key-blue-30 flex-grow-1 tk-academy-bold">Durchschnittliche Leistung
            </div>
            <div className="tk-academy-text tk-key-blue-30">{performancePercentage.toFixed(0)}% <StatusIcon
                pointsAwarded={pointsGotten}
                possiblePoints={pointsPossible}
                stateStatus={"finished"}/></div>
        </div>
        <div className="tk-progress-bar tk-bg-waterblue-80">
            <div className={`inner-bar`} style={{
                width: performancePercentage + "%",
                backgroundImage: "linear-gradient(to left, #ffb122, #ffdc2a)"
            }}/>
            <div className="performance-limit" style={{left: "75%"}}/>
            <div className="performance-limit" style={{left: "95%"}}/>
        </div>
    </div>
};
