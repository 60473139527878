/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type PaymentMethod = "card" | "giropay" | "iapApple" | "iapGoogle" | "invoicePartner" | "invoiceTk" | "monthlyPartner" | "monthlyTk" | "paypal" | "sepa" | "sofort" | "%future added value";
export type OrderList_QueryVariables = {};
export type OrderList_QueryResponse = {
    readonly Viewer: {
        readonly Billing: {
            readonly Orders: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly createdAt: string;
                        readonly id: string;
                        readonly selectedPaymentMethod: {
                            readonly paymentMethodId: PaymentMethod;
                            readonly chosenOption?: {
                                readonly totalAmount: {
                                    readonly grossPrice: number;
                                };
                            };
                        } | null;
                        readonly cart: {
                            readonly cartTotals: {
                                readonly totalsIncludingAllDiscounts: {
                                    readonly grossPrice: number;
                                } | null;
                            };
                        };
                    };
                } | null> | null;
            };
        };
    };
};
export type OrderList_Query = {
    readonly response: OrderList_QueryResponse;
    readonly variables: OrderList_QueryVariables;
};



/*
query OrderList_Query {
  Viewer {
    Billing {
      Orders {
        edges {
          node {
            createdAt
            id
            selectedPaymentMethod {
              __typename
              paymentMethodId
              ... on MonthlySelectedPaymentMethod {
                chosenOption {
                  totalAmount {
                    grossPrice
                  }
                }
              }
            }
            cart {
              cartTotals {
                totalsIncludingAllDiscounts {
                  grossPrice
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentMethodId",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "grossPrice",
    "storageKey": null
  }
],
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MonthlyOption",
      "kind": "LinkedField",
      "name": "chosenOption",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceV3",
          "kind": "LinkedField",
          "name": "totalAmount",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MonthlySelectedPaymentMethod",
  "abstractKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Cart",
  "kind": "LinkedField",
  "name": "cart",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CartTotals",
      "kind": "LinkedField",
      "name": "cartTotals",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceV3",
          "kind": "LinkedField",
          "name": "totalsIncludingAllDiscounts",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrderList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingViewerSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrdersConnection",
                "kind": "LinkedField",
                "name": "Orders",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrdersEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Order",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "selectedPaymentMethod",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OrderList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingViewerSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrdersConnection",
                "kind": "LinkedField",
                "name": "Orders",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrdersEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Order",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "selectedPaymentMethod",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              },
                              (v2/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "403ea94f146d24d0d25d6749b5b00125",
    "id": null,
    "metadata": {},
    "name": "OrderList_Query",
    "operationKind": "query",
    "text": "query OrderList_Query {\n  Viewer {\n    Billing {\n      Orders {\n        edges {\n          node {\n            createdAt\n            id\n            selectedPaymentMethod {\n              __typename\n              paymentMethodId\n              ... on MonthlySelectedPaymentMethod {\n                chosenOption {\n                  totalAmount {\n                    grossPrice\n                  }\n                }\n              }\n            }\n            cart {\n              cartTotals {\n                totalsIncludingAllDiscounts {\n                  grossPrice\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '6a55977577601681d3f12a67f9330f52';
export default node;
