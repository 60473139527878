import {useLazyLoadQuery} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import React from "react";
import {OrderFlowFromIdScreen_Query} from "../../../../__generated__/OrderFlowFromIdScreen_Query.graphql";
import {OrderFlow} from "./OrderFlowScreen";

interface OwnProps {
    orderId: string;
}

export const OrderFlowFromIdScreen = ({orderId}: OwnProps) => {
    const data = useLazyLoadQuery<OrderFlowFromIdScreen_Query>(graphql`
        query OrderFlowFromIdScreen_Query($id: ID!) {
            node(id: $id) {
                ... on Order {
                    id
                    ...OrderFlowScreen_OrderFragment
                }
            }
            ...OrderFlowScreen_ViewerFragment

        }
    `, {id: orderId})

    return data?.node ? <OrderFlow
        orderFragmentRef={data.node}
        viewerFragmentRef={data}
    /> : null
}
