import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_FETCH_LICENSES_IN_ACCOUNT = "fetch-licenses-in-account";

export const fetchLicensesInAccountAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FETCH_LICENSES_IN_ACCOUNT,
            callType: API_FETCH_LICENSES_IN_ACCOUNT
        },
        payload: undefined
    } as ApiCallAction<void>;
};
