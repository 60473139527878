/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TrialOverButton_CourseFragment = {
    readonly containerMeta: {
        readonly title: string;
    };
    readonly mainProduct: string | null;
    readonly mainDiscountCode: string | null;
    readonly " $refType": "TrialOverButton_CourseFragment";
};
export type TrialOverButton_CourseFragment$data = TrialOverButton_CourseFragment;
export type TrialOverButton_CourseFragment$key = {
    readonly " $data"?: TrialOverButton_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"TrialOverButton_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TrialOverButton_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContainerMeta",
      "kind": "LinkedField",
      "name": "containerMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mainProduct",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mainDiscountCode",
      "storageKey": null
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = 'b878bcadbdfb8c3dce7a7ee554b11e89';
export default node;
