import React, {ReactNode} from "react";
import styled from "styled-components";
import {tkBackgrounds} from "../core/style/tkBackgrounds";

interface OwnProps {
    children?: ReactNode;
}

export const LearnReviewSecondarySidebar = ({children}: OwnProps) => {
    return children ? <Container className="p-5">{children}</Container> : <Container/>
}

const Container = styled.div`
    ${tkBackgrounds.lightBackground}
    min-width: 350px;
    overflow: scroll;
`