/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ReviewProcessScreen_CourseFragment = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"ReviewProcessCurrentElementScreen_CourseFragment">;
    readonly " $refType": "ReviewProcessScreen_CourseFragment";
};
export type ReviewProcessScreen_CourseFragment$data = ReviewProcessScreen_CourseFragment;
export type ReviewProcessScreen_CourseFragment$key = {
    readonly " $data"?: ReviewProcessScreen_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ReviewProcessScreen_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewProcessScreen_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReviewProcessCurrentElementScreen_CourseFragment"
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = '68a123c944e4745aac2da84ea874ef99';
export default node;
