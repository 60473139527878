import {useDispatch, useSelector} from "react-redux";
import {hideCelebrationAction, selectCelebrationContent} from "@thekeytechnology/thekey-academy-frontend-library";
import {CelebrationModal} from "./CelebrationModal";
import {AcademyButton} from "../../core/components/button/AcademyButton";
import React from "react";
import {NavLink} from "react-router-dom";
import {AdvanceCourseButton} from "../../state/components/AdvanceCourseButton";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {CelebrationPopup_CourseStateFragment$key} from "../../../__generated__/CelebrationPopup_CourseStateFragment.graphql";
import {CelebrationPopup_CourseFragment$key} from "../../../__generated__/CelebrationPopup_CourseFragment.graphql";
import {CelebrationPopup_LicensePools$key} from "../../../__generated__/CelebrationPopup_LicensePools.graphql";

const COURSE_FRAGMENT = graphql`
    fragment CelebrationPopup_CourseFragment on Course {
        ...AdvanceCourseButton_CourseFragment
    }`

const COURSE_STATE_FRAGMENT = graphql`
    fragment CelebrationPopup_CourseStateFragment on CourseState {
        ...AdvanceCourseButton_CourseStateFragment
    }`

const LICENSE_POOLS_FRAGMENT = graphql`
    fragment CelebrationPopup_LicensePools on LicensePoolsConnection {
        ...AdvanceCourseButton_LicensePoolsFragment
    }
`;

interface OwnProps {
    courseFragmentRef?: CelebrationPopup_CourseFragment$key | null
    courseStateFragmentRef?: CelebrationPopup_CourseStateFragment$key | null
    licensePoolsFragmentRef?: CelebrationPopup_LicensePools$key | null
}

export const CelebrationPopup = ({courseFragmentRef, courseStateFragmentRef, licensePoolsFragmentRef}: OwnProps) => {

    const dispatch = useDispatch()

    const course = useFragment<CelebrationPopup_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef || null)
    const courseState = useFragment<CelebrationPopup_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, courseStateFragmentRef || null)
    const licensePools = useFragment<CelebrationPopup_LicensePools$key>(LICENSE_POOLS_FRAGMENT, licensePoolsFragmentRef || null)

    const content = useSelector(selectCelebrationContent)

    if (!content) return null

    let Button;
    if (content.buttonDescription) {
        if (content.buttonDescription.specialType === "NextLesson") {
            if (!course && !courseState) {
                return null;
            }

            Button = course && licensePools ?
                <AdvanceCourseButton courseFragmentRef={course} courseStateFragmentRef={courseState}
                                     licensePoolsFragmentRef={licensePools}
                                     onSwitchToLesson={() => dispatch(hideCelebrationAction())}/> : null;
        } else if (content.buttonDescription.navigationPath) {
            Button = <NavLink to={content.buttonDescription.navigationPath} onClick={() => {
                dispatch(hideCelebrationAction());
            }}>
                <AcademyButton text={content.buttonDescription.label}/>
            </NavLink>
        }
    } else {
        Button = <AcademyButton text={"Ok"} onClick={() => dispatch(hideCelebrationAction())}/>
    }

    return <CelebrationModal title={content.title}
                             explanation={content.explanation}
                             text={content.text}
                             image={content.image}
                             onHide={() => dispatch(hideCelebrationAction())}>
        {Button}
    </CelebrationModal>;
}
