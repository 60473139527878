import React from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {showCelebrationAction} from "@thekeytechnology/thekey-academy-frontend-library";
import {AcademyButton} from "../../core/components/button/AcademyButton";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {generateCartLink} from "../../billing/v3/utils/cart-link";
import {TrialOverButtonEarlyBird_CourseFragment$key} from "../../../__generated__/TrialOverButtonEarlyBird_CourseFragment.graphql";

const COURSE_FRAGMENT = graphql`
    fragment TrialOverButtonEarlyBird_CourseFragment on Course {
        containerMeta {
            title
        }
        mainProduct
        mainDiscountCode
    }`

interface OwnProps {
    courseFragmentRef: TrialOverButtonEarlyBird_CourseFragment$key
}

export const TrialOverButtonEarlyBird = ({courseFragmentRef}: OwnProps) => {

    const {t} = useTranslation("state")

    const dispatch = useDispatch()

    const course = useFragment<TrialOverButtonEarlyBird_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef);

    return <AcademyButton
        icon={"icon-a_tick"}
        text={t("trial-over-button-early-bird.label")}
        onClick={() =>
            dispatch(showCelebrationAction({
                title: t("trial-over-button-early-bird.celebration.title"),
                explanation: t("trial-over-button-early-bird.celebration.explanation"),
                image: "special-trial-over",
                text: t("trial-over-button-early-bird.celebration.text", {courseName: course.containerMeta.title}),
                buttonDescription: {
                    label: t("trial-over-button-early-bird.celebration.cta-text"),
                    navigationPath: course.mainProduct ? generateCartLink(course.mainProduct, course.mainDiscountCode) : "",
                    specialType: "Link",
                    params: {}
                }
            }))
        }/>
}
