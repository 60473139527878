import React from "react";
import {Result} from "./Result";
import "./finished-display.scss";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {ResultDisplay_SubmissionFragment$key} from "../../../__generated__/ResultDisplay_SubmissionFragment.graphql";
import {PointsAndMedal} from "./PointsAndMedal";
import CertificationPending from "../../celebration/components/certification-pending.png";

const RESULT_DISPLAY_SUBMISSION_FRAGMENT = graphql`
    fragment ResultDisplay_SubmissionFragment on Submission {
        submissionType
        result {
            grading
            pointsGotten
            pointsPossible
        }
        ...Result_SubmissionFragment
    }`

interface StateProps {
    submissionFragmentRef: ResultDisplay_SubmissionFragment$key;
}

type Props = StateProps

export const ResultDisplay = ({
                                  submissionFragmentRef
                              }: Props) => {

    const submission = useFragment<ResultDisplay_SubmissionFragment$key>(RESULT_DISPLAY_SUBMISSION_FRAGMENT, submissionFragmentRef)

    let backgroundColor;
    switch (submission.result.grading) {
        case "perfect":
        case "gold":
            backgroundColor = "#fffbe9";
            break;
        case "silver":
            backgroundColor = "#f3f5f8";
            break;
        case "bronze":
            backgroundColor = "#fff5ed";
            break;
        case 'noPoints':
        case 'fail':
            backgroundColor = "#fff5ed";
            break;
        default:
            backgroundColor = "white";
            break;
    }

    const isCertificationTest: boolean = submission.submissionType === 'certificationTest';

    return <div style={{backgroundColor}}
                className="d-flex flex-column justify-content-center align-items-center w-100 finished-display h-100 p-4">
        <div className="wrapper d-flex flex-column justify-content-center align-items-center w-100">

            {isCertificationTest ?
                <>
                    {submission.result.grading !== 'fail' ? <img alt={"Zertifikat ist auf dem Weg"} src={CertificationPending} className={"pb-5 certification-pending-img"}/> : null}
                </> : <PointsAndMedal pointsAwarded={submission.result.pointsGotten}
                                      possiblePoints={submission.result.pointsPossible}/>
            }
            <Result submissionFragmentRef={submission}/>
        </div>
    </div>
}
