import {ValidatedField} from "../../../../../core/components/form/ValidatedField";
import {SimpleOptionSelectField} from "../../../../../core/components/select/SimpleOptionSelectField";
import {DateField} from "../../../../../certificate/components/DateField";
import {AcademyButton} from "../../../../../core/components/button/AcademyButton";
import {Form, Formik} from "formik";
import React from "react";
import {useTranslation} from "react-i18next";
import {ApiCallState, EntityWrapper, UserWithRoles} from "@thekeytechnology/framework-react";
import {
    LicenseAttribution,
    PrivateBillingDetails,
    Signup,
    UseForPurchaser,
    UserMeta
} from "@thekeytechnology/thekey-academy-frontend-library";
import * as yup from "yup";
import * as Yup from "yup";
import {SignupFormPart} from "./SignupFormPart";
import moment from "moment";

interface OwnProps {
    apiCallState: ApiCallState;
    user: EntityWrapper<UserWithRoles> | undefined
    userMeta: EntityWrapper<UserMeta> | undefined
    onSubmit: (billingDetails: PrivateBillingDetails, licenseAttribution: LicenseAttribution, signup?: Signup) => void;
}

export const CheckoutFormPrivateV2 = ({apiCallState, user, userMeta, onSubmit}: OwnProps) => {
    const {t} = useTranslation("billing")

    return <Formik
        initialValues={{
            userId: user ? user.id : undefined,
            salutation: "",
            firstName: userMeta?.entity.firstName ? userMeta?.entity.firstName : "",
            lastName: userMeta?.entity.lastName ? userMeta?.entity.lastName : "",
            street: "",
            houseNumber: "",
            postalCode: "",
            city: "",
            email: "",
            email2: "",
            password: "",
            password2: "",
            legal: false,
            country: "de",
            dateOfBirth: "",
            licenseAttribution: {attributionType: "use-for-purchaser"} as UseForPurchaser
        }}
        enableReinitialize={true}
        validationSchema={
            yup.object().shape({
                salutation: Yup
                    .string()
                    .required(t("core:forms.required-field", {fieldName: t("checkout-form.salutation")})),
                firstName: Yup
                    .string()
                    .required(t("core:forms.required-field", {fieldName: t("checkout-form.first-name")})),
                lastName: Yup
                    .string()
                    .required(t("core:forms.required-field", {fieldName: t("checkout-form.last-name")})),
                street: Yup
                    .string()
                    .required(t("core:forms.required-field", {fieldName: t("checkout-form.street")})),
                houseNumber: Yup
                    .string()
                    .max(8, t("core:forms.max-length-housenumber"))
                    .required(t("core:forms.required-field", {fieldName: t("checkout-form.house-number")})),
                postalCode: Yup
                    .string()
                    .matches(new RegExp("^[0-9]{5}$"), "Das ist keine gültige PLZ")
                    .required(t("core:forms.required-field", {fieldName: t("checkout-form.postal-code")})),
                city: Yup
                    .string()
                    .required(t("core:forms.required-field", {fieldName: t("checkout-form.city")})),
                country: Yup
                    .string()
                    .required(t("core:forms.required-field", {fieldName: t("checkout-form.country")})),
                email: Yup.string()
                    .email(t("checkout-form.email-error"))
                    .test("test", "test", function (value) {
                        if (!user && !value) {
                            return this.createError({
                                path: "email",
                                message: t("core:forms.required-field", {fieldName: t("checkout-form.email")})
                            });
                        }
                        return true;
                    }),
                email2: Yup.string()
                    .oneOf([Yup.ref("email")], t("checkout-form.email2-error")),
                password: Yup
                    .string()
                    .min(8, t("checkout-form.password-error"))
                    .test("test", "test", function (value) {
                        if (!user && !value) {
                            return this.createError({
                                path: "password",
                                message: t("core:forms.required-field", {fieldName: t("checkout-form.password")})
                            });
                        }
                        return true;
                    }),
                password2: Yup.string()
                    .oneOf([Yup.ref("password")], t("checkout-form.password2-error")),
                legal: Yup
                    .boolean()
                    .test("test", "test", function (value) {
                        if (!user && !value) {
                            return this.createError({
                                path: "legal",
                                message: t("core:forms.required-field", {fieldName: "Nutzungsbedingungen & Datenschutz"})
                            });
                        }
                        return true;
                    }),
                dateOfBirth: Yup
                    .string()
                    .required(t("core:forms.required-field", {fieldName: "Geburtsdatum"}))
            })
        }
        onSubmit={(values, {setSubmitting}) => {
            onSubmit({
                customerType: "private",
                salutation: values.salutation,
                firstName: values.firstName,
                lastName: values.lastName,
                street: values.street,
                city: values.city,
                country: values.country,
                postalCode: values.postalCode,
                houseNumber: values.houseNumber,
                dateOfBirth: moment(values.dateOfBirth as any as Date).format("yyyy-MM-DD"),
                invoiceEmail: user?.entity.email || values.email
            }, values.licenseAttribution, user ? undefined : {
                email: values.email!,
                firstName: values.firstName!,
                lastName: values.lastName!,
                password: values.password!
            })
            setSubmitting(false);
        }}
    >
        {formikState =>
            <Form>
                <div className="tk-form-row">
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        placeholder={t("checkout-form.salutation")}
                                        name="salutation"
                                        component={SimpleOptionSelectField}
                                        options={[
                                            {
                                                value: "mr",
                                                label: "Herr"
                                            },
                                            {
                                                value: "mrs",
                                                label: "Frau"
                                            }
                                        ]}
                                        className="form-control default-input"
                                        required/>
                    </div>
                </div>
                <div className="tk-form-row">
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        placeholder={t("checkout-form.first-name")}
                                        name="firstName"
                                        className="form-control default-input"
                                        required/>
                    </div>
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        placeholder={t("checkout-form.last-name")}
                                        name="lastName"
                                        className="form-control default-input"
                                        required/>
                    </div>
                </div>

                <div className="tk-form-row">
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        placeholder={t("checkout-form.street")}
                                        name="street"
                                        className="form-control default-input"
                                        required
                        />
                    </div>
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        placeholder={t("checkout-form.house-number")}
                                        name="houseNumber"
                                        className="form-control default-input"
                                        required/>
                    </div>
                </div>

                <div className="tk-form-row">
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        placeholder={t("checkout-form.postal-code")}
                                        name="postalCode"
                                        className="form-control default-input"
                                        required
                        />
                    </div>
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        placeholder={t("checkout-form.city")}
                                        name="city"
                                        className="form-control default-input"
                                        required/>
                    </div>
                </div>

                <div className="tk-form-row">
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        placeholder={t("checkout-form.country")}
                                        name="country"
                                        component={SimpleOptionSelectField}
                                        options={[{
                                            value: "de",
                                            label: "Deutschland"
                                        }]
                                        }
                                        className="form-control default-input"
                                        required/>
                    </div>
                    <div className="form-group">
                        <ValidatedField
                            name="dateOfBirth"
                            helpText={"Dies wird für Dein offizielles IHK Zertifikat benötigt."}
                            component={DateField}
                            placeholder={"Geburtsdatum"}
                            className="form-control default-input"
                            formikState={formikState}/>
                    </div>
                </div>

                <SignupFormPart formikState={formikState} user={user}/>

                <AcademyButton
                    className="ml-auto"
                    onClick={() => formikState.handleSubmit()}
                    disabled={apiCallState.inProgress || formikState.isSubmitting}
                    text={!user ? "Registrieren und weiter zur Bezahlung" : "Weiter zur Bezahlung"}
                    type={"submit"}/>
            </Form>
        }
    </Formik>
}
