import React from "react";
import {Link} from "react-router-dom";

import "./menu-header.scss";

import DarkLogo from "../../../../assets/images/logo-black.png"
import WhiteLogo from "../../../../assets/images/logo-white.png"
import {ReactComponent as MiniLogo} from "../../../../assets/images/mini-logo.svg"


interface OwnProps {
    collapsed?: boolean;
    className?: string;
    dark?: boolean;
}

export const MenuHeader = ({
                               collapsed,
                               className,
                               dark
                           }: OwnProps) => {
    return <div
        className={`d-flex align-items-center position-relative headerBar menu-header ${collapsed ? "justify-content-center " : "justify-content-start pl-4 "}` + (className ? className : "")}>
        <Link to={"/"}>{collapsed ?
            <MiniLogo/> :
            dark ?
                <img src={DarkLogo} alt="the-key-academy"/> :
                <img src={WhiteLogo} alt="the-key-academy"/>}
        </Link>
    </div>;
}
