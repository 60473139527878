import {
    API_INVITE_USERS,
    ApiCallAction,
    apiCallSucceededAction,
    apiDelete,
    apiGet,
    apiPost,
    defaultApiErrorHandling,
    matchesAnyOfTheseApiSuccesses,
    matchesApiCall,
    selectApiBase,
    selectAuthState,
    withAuthedAxios
} from "@thekeytechnology/framework-react";
import {combineEpics, StateObservable} from "redux-observable";
import {API_FETCH_ACCOUNT_META} from "../action/fetch-account-meta";
import {API_FETCH_USERS_IN_ACCOUNT, fetchUsersInAccountAction} from "../action/fetch-users-in-accounts";
import {API_UPDATE_USER_ROLES} from "../action/update-user-roles";
import {from, Observable} from "rxjs";
import {Action} from "redux";
import {map, mergeMap, withLatestFrom} from "rxjs/operators";
import {API_FETCH_INVITATIONS_IN_ACCOUNT, fetchInvitationsInAccount} from "../action/fetch-invitations-in-account";
import {API_DELETE_INVITATION} from "../action/remove-invitation";
import {API_FETCH_LICENSES_IN_ACCOUNT, fetchLicensesInAccountAction} from "../action/fetch-licenses-in-accounts";
import {API_APPLY_LICENSE_FOR_USER} from "../action/apply-license";
import {API_REMOVE_USER_FROM_ACCOUNT} from "../action/remove-user-from-account";
import {API_DOWNLOAD_LICENSE_LOG} from "../action/download-license-log";
import {AxiosResponse} from "axios";

const fetchAccountMeta$ = apiGet(
    {apiType: API_FETCH_ACCOUNT_META},
    `/api/v1/manager/account-meta`,
);

const fetchUsersInAccount$ = apiGet(
    {apiType: API_FETCH_USERS_IN_ACCOUNT},
    `/api/v1/manager/users`,
);

const fetchLicensesInAccount$ = apiGet(
    {apiType: API_FETCH_LICENSES_IN_ACCOUNT},
    `/api/v1/manager/licenses`,
);

const applyLicense$ = apiPost(
    {apiType: API_APPLY_LICENSE_FOR_USER},
    `/api/v1/manager/licenses/apply`,
);

const fetchInvitationsInAccount$ = apiGet(
    {apiType: API_FETCH_INVITATIONS_IN_ACCOUNT},
    `/api/v1/manager/invitations`,
);

const updateUserRoles$ = apiPost(
    {apiType: API_UPDATE_USER_ROLES},
    `/api/v1/manager/update-user-roles`,
);

const deleteInvitation$ = apiDelete(
    {apiType: API_DELETE_INVITATION},
    (action: ApiCallAction<string>) => `/api/v1/manager/invitations/${action.payload}`,
);

const removeUserFromAccount$ = apiDelete(
    {apiType: API_REMOVE_USER_FROM_ACCOUNT},
    (action: ApiCallAction<string>) => `/api/v1/manager/users/${action.payload}`,
);

export const reloadUsers$ = (action: Observable<Action>) => action.pipe(
    matchesAnyOfTheseApiSuccesses({apiType: API_UPDATE_USER_ROLES}, {apiType: API_APPLY_LICENSE_FOR_USER}, {apiType: API_REMOVE_USER_FROM_ACCOUNT}),
    map(() => fetchUsersInAccountAction())
)

export const reloadLicenses$ = (action: Observable<Action>) => action.pipe(
    matchesAnyOfTheseApiSuccesses({apiType: API_APPLY_LICENSE_FOR_USER}),
    map(() => fetchLicensesInAccountAction())
)

export const reloadInvitations$ = (action: Observable<Action>) => action.pipe(
    matchesAnyOfTheseApiSuccesses({apiType: API_INVITE_USERS}, {apiType: API_DELETE_INVITATION}),
    map(() => fetchInvitationsInAccount())
)

export const downloadLogEpics$ = (action$: Observable<Action>, state$: StateObservable<any>) => action$.pipe(
    matchesApiCall(API_DOWNLOAD_LICENSE_LOG),
    withLatestFrom(state$),
    mergeMap(([action, state]: [ApiCallAction<null>, any]) => {
            return from(withAuthedAxios(selectApiBase(state), selectAuthState(state)).get(`/api/v1/manager/licenses/csv`, {headers: {Accept: "text/csv"}}))
                .pipe(
                    map((response: AxiosResponse) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", `licenses.csv`);
                        document.body.appendChild(link);
                        link.click();
                        if (link.parentNode) {
                            link.parentNode.removeChild(link);
                        }

                        return apiCallSucceededAction(action.apiQualifier, null);
                    }),
                    defaultApiErrorHandling(action.apiQualifier)
                );
        }
    )
);

export const managerModuleEpics$ = combineEpics(
    fetchAccountMeta$,
    fetchUsersInAccount$,
    updateUserRoles$,
    reloadUsers$,
    fetchInvitationsInAccount$,
    deleteInvitation$,
    reloadInvitations$,
    fetchLicensesInAccount$,
    applyLicense$,
    reloadLicenses$,
    removeUserFromAccount$,
    downloadLogEpics$
);
