import LogoBlack from "../../../../../assets/images/logo-black.png";
import {LogoutLink} from "../../../../core/components/logout/LogoutLink";
import LogoWhite from "../../../../../assets/images/logo-white.png";
import React, {ReactNode} from "react";
import "./billing-base.scss"

interface OwnProps {
    hideHeading?: boolean;
    underHeadingSlot?: ReactNode;
    mainSlot: ReactNode;
    sidebarSlot: ReactNode;
}

export const BillingBaseV2 = ({
                                  hideHeading,
                                  underHeadingSlot,
                                  mainSlot,
                                  sidebarSlot
                              }: OwnProps) => {

    return <div className="billing d-flex flex-column h-100 overflow-auto">
        <div className="pl-3 pl-lg-0 pr-3 pr-lg-0 pt-4 pb-4 d-flex">
            <div className="content-container d-flex align-items-center">
                <img className="logo" src={LogoBlack} alt={"thekey.academy"}/>

                <LogoutLink className="ml-auto tk-black tk-academy-text">
                    Ausloggen
                </LogoutLink>
            </div>
        </div>
        <div className="pl-3 pr-3 pl-lg-0 pr-lg-0 tk-bg-light pt-5 pb-5 flex-grow-1">
            <div className="content-container">
                {hideHeading ? null : <h1 className="tk-academy-huge-bold-text tk-color-waterblue mt-3 mb-4">
                    Jetzt bestellen
                </h1>}

                {underHeadingSlot}

                <div className="main-content">
                    <div className="tk-form-wrapper">
                        {mainSlot}
                    </div>
                    <div className="sidebar">
                        {sidebarSlot}
                    </div>
                </div>
            </div>
        </div>
        <div className="footer tk-bg-waterblue pt-5 pb-5 pl-3 pl-lg-0 pr-3 pr-lg-3">
            <div className="content-container">
                <div>
                    <img className="logo-footer" src={LogoWhite} alt="thekey.academy"/>
                </div>
                <div className="footer-content">
                    <div className="text tk-white tk-academy-large pt-3">
                        Lade Dir jetzt die kostenlose
                        thekey.academy Mobile App auf Dein
                        Smartphone und lerne sofort drauf los.
                    </div>
                    <div>
                    </div>
                    <div className="menu-wrapper">
                        <h3 className="tk-academy-text tk-key-blue-30 pb-2">Rechtliches</h3>
                        <ul>
                            <li className="pb-2">
                                <a className="tk-academy-text tk-key-blue-30 tk-academy-bold" target="_blank"
                                   rel="noopener noreferrer"
                                   href="https://www.thekey.academy/impressum/">Impressum</a>
                            </li>
                            <li className="tk-academy-text tk-key-blue-30 tk-academy-bold pb-2">AGB</li>
                            <li className="pb-2">
                                <a className="tk-academy-text tk-key-blue-30 tk-academy-bold" target="_blank"
                                   rel="noopener noreferrer"
                                   href="https://www.thekey.academy/datenschutzerklaerung/">Datenschutz</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr className="tk-bg-keyblue-20"/>
                <div className="copyright-wrapper">
                    <div className="tk-academy-small-text tk-white">© the key - academy GmbH</div>
                </div>
            </div>
        </div>
    </div>
}
