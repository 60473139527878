import React from "react";
import "./text-element-display.scss";
import {BaseElementDisplay} from "../common/BaseElementDisplay";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {TextElementDisplay_TextElementFragment$key} from "../../../../__generated__/TextElementDisplay_TextElementFragment.graphql";

const TEXT_ELEMENT_DISPLAY_TEXT_ELEMENT_FRAGMENT = graphql`
    fragment TextElementDisplay_TextElementFragment on TextElement {
        id
        text
        title
    }`

interface OwnProps {
    textElementFragmentRef: TextElementDisplay_TextElementFragment$key;
}

export const TextElementDisplay = ({
                                       textElementFragmentRef
                                   }: OwnProps) => {
    const element = useFragment<TextElementDisplay_TextElementFragment$key>(TEXT_ELEMENT_DISPLAY_TEXT_ELEMENT_FRAGMENT, textElementFragmentRef)

    /*const [elementText, setElementText] = useState<string>(element.text);

    useEffect(() => {
        setElementText(element.text);

        return () => setElementText('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [element.id]);*/

    return <BaseElementDisplay
        className="text-element-display"
        heading={element.title}
        elementSpecificSlot={<div dangerouslySetInnerHTML={{__html: element.text}}/>}
    />
}
