import { useSelector } from "react-redux";
import { selectOwnedAchievements, } from "@thekeytechnology/thekey-academy-frontend-library";
import React from "react";
import { AchievementItem } from "./AchievementItem";

export const AchievementsDisplay = () => {
    const ownedAchievements = useSelector(selectOwnedAchievements);

    return <div className="achievements mt-5 tk-academy-card p-4">
        <h2 className="tk-academy-large-bold tk-color-darkblue mb-4">Deine Auszeichnungen</h2>
        <div className="achievement-item-wrapper d-flex flex-wrap">
            {ownedAchievements.map((achievement, index) => {
                return <AchievementItem
                    key={index}
                    hasAchievement={achievement.hasAchievement}
                    achievement={achievement}/>
            })}
        </div>
    </div>
}
