/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LessonNextButton_CourseFragment = {
    readonly " $fragmentRefs": FragmentRefs<"AdvanceCourseButton_CourseFragment">;
    readonly " $refType": "LessonNextButton_CourseFragment";
};
export type LessonNextButton_CourseFragment$data = LessonNextButton_CourseFragment;
export type LessonNextButton_CourseFragment$key = {
    readonly " $data"?: LessonNextButton_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LessonNextButton_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonNextButton_CourseFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdvanceCourseButton_CourseFragment"
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = '996eef7bdc52b31c14791eb12f6cf976';
export default node;
