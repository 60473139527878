import React from "react";
import { News } from "@thekeytechnology/thekey-academy-frontend-library"
import "./news-card.scss";

interface OwnProps {
    className?: string;
    news: News;
}

export const NewsCard = ({
                             className,
                             news,
                         }: OwnProps) => {

    return <a
        rel="noopener noreferrer"
        href={news.permalink} target="_blank">
        <div className={"tk-academy-card d-flex flex-column news-card " + (className ? className : "")}>
            {news.image ? <div className="tk-image" style={{backgroundImage: `url(${news.image})`}}>
            </div> : null}
            <div className="tk-content p-4">
                <div className="tk-academy-large-bold mb-2">{news.title.replace(/(<([^>]+)>)/ig, "")}</div>
                <div className="tk-academy-text tk-color-waterblue-80" dangerouslySetInnerHTML={{__html: news.excerpt.replace(/(<([^>]+)>)/ig, "")}} />
            </div>
        </div>
    </a>
}
