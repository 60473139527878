import React from "react";
import {GlossaryItem} from "./GlossaryItem";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {GlossaryEntriesOfNormalLessonDisplay_NormalLessonFragment$key} from "../../../__generated__/GlossaryEntriesOfNormalLessonDisplay_NormalLessonFragment.graphql";

const NORMAL_LESSON_FRAGMENT = graphql`
    fragment GlossaryEntriesOfNormalLessonDisplay_NormalLessonFragment on NormalLesson {
        glossary {
            ...GlossaryItem_GlossaryEntryFragment
        }
    }`

interface OwnProps {
    normalLessonFragmentRef: GlossaryEntriesOfNormalLessonDisplay_NormalLessonFragment$key;
}

export const GlossaryEntriesOfNormalLessonDisplay = ({normalLessonFragmentRef}: OwnProps) => {

    const normalLesson = useFragment<GlossaryEntriesOfNormalLessonDisplay_NormalLessonFragment$key>(NORMAL_LESSON_FRAGMENT, normalLessonFragmentRef)

    const glossaryEntries = normalLesson.glossary;

    return <>
        {
            glossaryEntries.map((gE, index) => {
                return <GlossaryItem key={index} glossaryEntryFragmentRef={gE}/>
            })
        }
        </>
}