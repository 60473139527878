import {Screen} from "../../core/components/screen/Screen";
import React from "react";
import {BlueBackground} from "../../core/components/screen/BlueBackground";
import {NotesScreenContent} from "./NotesScreenContent";
import {graphql} from "babel-plugin-relay/macro";
import {useLazyLoadQuery} from "react-relay";
import {AllNotesScreen_Query} from "../../../__generated__/AllNotesScreen_Query.graphql";
import {MainSidebar} from "../../core/components/sidebar/MainSidebar";

const QUERY = graphql`
    query AllNotesScreen_Query  {
        Viewer {
            CustomerMessageV2 {
                CustomerMessageInformation {
                    id
                    numUnseenCustomerMessages
                }
            }
            License {
                NewLicensesInAccount {
                    id
                    newLicenses
                }
            }
        }
    }
`

export const AllNotesScreen = () => {

    const query = useLazyLoadQuery<AllNotesScreen_Query>(QUERY, {}, {fetchPolicy: "network-only"});

    return <Screen
        headerBarBackgroundSlot={<BlueBackground height={60}/>}
        sidebarSlot={<MainSidebar
            numUnseenCustomerMessages={query.Viewer.CustomerMessageV2.CustomerMessageInformation.numUnseenCustomerMessages}
            hasNewLicensesInAccount={query.Viewer.License.NewLicensesInAccount.newLicenses}/>}
        headerMargin={30}
    >

        <NotesScreenContent
            headingSlot={<>
                <h1 className="mb-4">Alle Notizen</h1>

                <div className="tk-academy-text mb-3">In diesem Bereich findest Du Deine Notizen. Sie sind nach
                    Lektionen
                    sortiert. Jede Notiz führt Dich, wenn
                    Du möchtest, direkt zu dem Baustein, in dem Du Deine Notiz angelegt hast. Echte
                    thekey.academy-Profis
                    wissen, dass man sich damit auch bestimmte Bausteine für später speichern und immer wieder ansehen
                    kann.
                    Wenn Dir also ein Baustein besonders gut gefallen hat, lege einfach eine Notiz an und finde ihn hier
                    jederzeit wieder.
                </div>
            </>}
        />
    </Screen>
}
