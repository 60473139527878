/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AccountManager_QueryVariables = {};
export type AccountManager_QueryResponse = {
    readonly Viewer: {
        readonly CustomerMessageV2: {
            readonly CustomerMessageInformation: {
                readonly id: string;
                readonly numUnseenCustomerMessages: number;
            };
        };
        readonly License: {
            readonly NewLicensesInAccount: {
                readonly id: string;
                readonly newLicenses: boolean;
            };
            readonly LicenseInAccountContainer: {
                readonly " $fragmentRefs": FragmentRefs<"ManagerMainPage_LicenseInAccountContainerFragment">;
            };
        };
    };
};
export type AccountManager_Query = {
    readonly response: AccountManager_QueryResponse;
    readonly variables: AccountManager_QueryVariables;
};



/*
query AccountManager_Query {
  Viewer {
    CustomerMessageV2 {
      CustomerMessageInformation {
        id
        numUnseenCustomerMessages
      }
    }
    License {
      NewLicensesInAccount {
        id
        newLicenses
      }
      LicenseInAccountContainer {
        ...ManagerMainPage_LicenseInAccountContainerFragment
        id
      }
    }
  }
}

fragment AssignLicenseButton_LicensesInAccountContainerFragment on LicenseInAccountContainer {
  id
  courseLocksByUser {
    user
    coursesToUnlock {
      courseId
      title
    }
    coursesUnlocked {
      title
    }
  }
  licenses {
    limitedToCoursesRef
    id
  }
  courses {
    id
    containerMeta {
      title
    }
  }
  usersInAccount {
    id
    name
  }
}

fragment LicensesInAccount_LicenseInAccountContainerFragment on LicenseInAccountContainer {
  id
  mainLicensePool {
    id
    rawId
    account {
      name
      id
    }
  }
  licenses {
    id
    limitedToCoursesRef
    usageData {
      usedAt
      usedByUser
      usedForCourse
    }
  }
  licenseRequests {
    id
    courseRef
    createdAt
    licensePoolRef
    userRef
  }
  courses {
    rawId
    id
    shortTitle
  }
  usersInAccount {
    rawId
    id
    name
  }
  ...AssignLicenseButton_LicensesInAccountContainerFragment
}

fragment ManagerMainPage_LicenseInAccountContainerFragment on LicenseInAccountContainer {
  ...LicensesInAccount_LicenseInAccountContainerFragment
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "CustomerMessageV2ViewerSchema",
  "kind": "LinkedField",
  "name": "CustomerMessageV2",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerMessageInformation",
      "kind": "LinkedField",
      "name": "CustomerMessageInformation",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numUnseenCustomerMessages",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "NewLicensesInAccount",
  "kind": "LinkedField",
  "name": "NewLicensesInAccount",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "newLicenses",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountManager_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LicenseViewerSchema",
            "kind": "LinkedField",
            "name": "License",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LicenseInAccountContainer",
                "kind": "LinkedField",
                "name": "LicenseInAccountContainer",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ManagerMainPage_LicenseInAccountContainerFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountManager_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LicenseViewerSchema",
            "kind": "LinkedField",
            "name": "License",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LicenseInAccountContainer",
                "kind": "LinkedField",
                "name": "LicenseInAccountContainer",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LicensePool",
                    "kind": "LinkedField",
                    "name": "mainLicensePool",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "account",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "License",
                    "kind": "LinkedField",
                    "name": "licenses",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "limitedToCoursesRef",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UsageData",
                        "kind": "LinkedField",
                        "name": "usageData",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "usedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "usedByUser",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "usedForCourse",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LicenseRequest",
                    "kind": "LinkedField",
                    "name": "licenseRequests",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "courseRef",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "licensePoolRef",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "userRef",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Course",
                    "kind": "LinkedField",
                    "name": "courses",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shortTitle",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContainerMeta",
                        "kind": "LinkedField",
                        "name": "containerMeta",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "usersInAccount",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v0/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseLocksByUserType",
                    "kind": "LinkedField",
                    "name": "courseLocksByUser",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "user",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CourseIdWithTitle",
                        "kind": "LinkedField",
                        "name": "coursesToUnlock",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "courseId",
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CourseIdWithTitle",
                        "kind": "LinkedField",
                        "name": "coursesUnlocked",
                        "plural": true,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1509bc2ef927f3312f9808779eb39238",
    "id": null,
    "metadata": {},
    "name": "AccountManager_Query",
    "operationKind": "query",
    "text": "query AccountManager_Query {\n  Viewer {\n    CustomerMessageV2 {\n      CustomerMessageInformation {\n        id\n        numUnseenCustomerMessages\n      }\n    }\n    License {\n      NewLicensesInAccount {\n        id\n        newLicenses\n      }\n      LicenseInAccountContainer {\n        ...ManagerMainPage_LicenseInAccountContainerFragment\n        id\n      }\n    }\n  }\n}\n\nfragment AssignLicenseButton_LicensesInAccountContainerFragment on LicenseInAccountContainer {\n  id\n  courseLocksByUser {\n    user\n    coursesToUnlock {\n      courseId\n      title\n    }\n    coursesUnlocked {\n      title\n    }\n  }\n  licenses {\n    limitedToCoursesRef\n    id\n  }\n  courses {\n    id\n    containerMeta {\n      title\n    }\n  }\n  usersInAccount {\n    id\n    name\n  }\n}\n\nfragment LicensesInAccount_LicenseInAccountContainerFragment on LicenseInAccountContainer {\n  id\n  mainLicensePool {\n    id\n    rawId\n    account {\n      name\n      id\n    }\n  }\n  licenses {\n    id\n    limitedToCoursesRef\n    usageData {\n      usedAt\n      usedByUser\n      usedForCourse\n    }\n  }\n  licenseRequests {\n    id\n    courseRef\n    createdAt\n    licensePoolRef\n    userRef\n  }\n  courses {\n    rawId\n    id\n    shortTitle\n  }\n  usersInAccount {\n    rawId\n    id\n    name\n  }\n  ...AssignLicenseButton_LicensesInAccountContainerFragment\n}\n\nfragment ManagerMainPage_LicenseInAccountContainerFragment on LicenseInAccountContainer {\n  ...LicensesInAccount_LicenseInAccountContainerFragment\n}\n"
  }
};
})();
(node as any).hash = '0762a1d91817389a02ad9f9750645df6';
export default node;
