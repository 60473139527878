/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ApplicationRuleStatus = "applicationInProgress" | "applicationNecessary" | "canFreelyUse" | "cannotApply" | "duplicateApplicationNotPossible" | "noPool" | "poolEmpty" | "%future added value";
export type LicensePoolDisplay_LicensePoolFragment = {
    readonly id: string;
    readonly account: {
        readonly name: string;
    };
    readonly applicationRuleStatus: ApplicationRuleStatus;
    readonly " $fragmentRefs": FragmentRefs<"ApplyLicenseButton_LicensePoolFragment">;
    readonly " $refType": "LicensePoolDisplay_LicensePoolFragment";
};
export type LicensePoolDisplay_LicensePoolFragment$data = LicensePoolDisplay_LicensePoolFragment;
export type LicensePoolDisplay_LicensePoolFragment$key = {
    readonly " $data"?: LicensePoolDisplay_LicensePoolFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LicensePoolDisplay_LicensePoolFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "courseId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "LicensePoolDisplay_LicensePoolFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "courseId",
          "variableName": "courseId"
        }
      ],
      "kind": "ScalarField",
      "name": "applicationRuleStatus",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplyLicenseButton_LicensePoolFragment"
    }
  ],
  "type": "LicensePool",
  "abstractKey": null
};
(node as any).hash = 'f4988b9086c52ca08db28d614a3d041d';
export default node;
