/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CustomerType = "business" | "private" | "%future added value";
export type PaymentMethod = "card" | "giropay" | "iapApple" | "iapGoogle" | "invoicePartner" | "invoiceTk" | "monthlyPartner" | "monthlyTk" | "paypal" | "sepa" | "sofort" | "%future added value";
export type SelectedPaymentMethodType = "monthly" | "oneTime" | "%future added value";
export type usePaymentLogicV3_OrderFragment = {
    readonly id: string;
    readonly selectedPaymentMethod: {
        readonly paymentMethodId: PaymentMethod;
        readonly selectedPaymentMethodType: SelectedPaymentMethodType;
        readonly stripeData?: {
            readonly paymentIntentSecret: string;
        } | null;
        readonly chosenOption?: {
            readonly totalAmount: {
                readonly netPrice: number;
            };
        };
    } | null;
    readonly billingDetails: {
        readonly firstName: string;
        readonly lastName: string;
        readonly street: string;
        readonly houseNumber: string;
        readonly postalCode: string;
        readonly city: string;
        readonly country: string;
        readonly invoiceEmail: string;
        readonly customerType: CustomerType;
        readonly companyBillingDetails: {
            readonly company: string;
        } | null;
    } | null;
    readonly cart: {
        readonly cartItems: ReadonlyArray<{
            readonly product?: {
                readonly id: string;
                readonly title: string;
            };
        }>;
        readonly cartSelection: {
            readonly selectedProducts: ReadonlyArray<{
                readonly amount: number;
                readonly productId: string;
            }>;
        };
        readonly cartTotals: {
            readonly totalsIncludingAllDiscounts: {
                readonly netPrice: number;
            } | null;
        };
    };
    readonly " $refType": "usePaymentLogicV3_OrderFragment";
};
export type usePaymentLogicV3_OrderFragment$data = usePaymentLogicV3_OrderFragment;
export type usePaymentLogicV3_OrderFragment$key = {
    readonly " $data"?: usePaymentLogicV3_OrderFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"usePaymentLogicV3_OrderFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "netPrice",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "usePaymentLogicV3_OrderFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "selectedPaymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethodId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "selectedPaymentMethodType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StripeData",
              "kind": "LinkedField",
              "name": "stripeData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paymentIntentSecret",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "OneTimeSelectedPaymentMethod",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyOption",
              "kind": "LinkedField",
              "name": "chosenOption",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceV3",
                  "kind": "LinkedField",
                  "name": "totalAmount",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "MonthlySelectedPaymentMethod",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BillingDetails",
      "kind": "LinkedField",
      "name": "billingDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "street",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "houseNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postalCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceEmail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyBillingDetail",
          "kind": "LinkedField",
          "name": "companyBillingDetails",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "company",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "cartItems",
          "plural": true,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "ProductCartItem",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CartSelection",
          "kind": "LinkedField",
          "name": "cartSelection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SelectedProduct",
              "kind": "LinkedField",
              "name": "selectedProducts",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "productId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CartTotals",
          "kind": "LinkedField",
          "name": "cartTotals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceV3",
              "kind": "LinkedField",
              "name": "totalsIncludingAllDiscounts",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();
(node as any).hash = '54e477665aeeee85096813e23f7840ed';
export default node;
