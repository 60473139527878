import LogoBlack from "../../../../../assets/images/logo-black.png";
import {LogoutLink} from "../../../../core/components/logout/LogoutLink";
import React, {ReactNode} from "react";
import styled from "styled-components";

interface OwnProps {
    mainSlot: ReactNode;
}

export const BillingBaseV3 = ({
                                  mainSlot,
                              }: OwnProps) => {

    return <BillingBase>
        <PageHeader>
            <PageHeaderInner>
                <LogoImage src={LogoBlack} alt={"thekey.academy"}/>

                <LogoutLink afterClick={() => {
                    window.location.reload()
                }} className="ml-auto tk-black tk-academy-text">
                    Ausloggen
                </LogoutLink>
            </PageHeaderInner>
        </PageHeader>
        <PageContent>
            <InnerContent>
                <h1 className="tk-academy-larger-bold tk-color-waterblue mt-3 mb-4">
                    Deine Bestellung
                </h1>
                {mainSlot}
            </InnerContent>
        </PageContent>
    </BillingBase>
}

const BillingBase = styled.div`
  background-color: white;
  width: 100vw;
  padding-bottom: 100px;
`

const PageHeader = styled.div`
  padding: 20px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`

const PageHeaderInner = styled.div`
  display: flex;
  max-width: 1024px;
  width: 100%;
  flex-direction: row;
`

const LogoImage = styled.img`
  width: 200px;
`

const PageContent = styled.div`
  background: white;
  display: flex;
  justify-content: center;
  padding: 0 20px;
`

const InnerContent = styled.div`
  max-width: 893px;
  width: 100%;
`



