import React, {useEffect} from "react";
import {EntityWrapper, selectCurrentUser, UserWithRoles} from "@thekeytechnology/framework-react";
import {connect} from "react-redux";

import "./welcome.scss";
import {useTranslation} from "react-i18next";
import {fetchGamificationDataAction} from "@thekeytechnology/thekey-academy-frontend-library"

import {RankMessage} from "../../../../gamification/components/rank/RankMessage";

interface StateProps {
    user?: EntityWrapper<UserWithRoles>;
}

interface DispatchProps {
    fetchGamificationData: typeof fetchGamificationDataAction
}

type Props = StateProps & DispatchProps;

export const WelcomeComponent = ({
                                     user,
                                     fetchGamificationData,
                                 }: Props) => {
    const {t} = useTranslation("courses")

    useEffect(() => {
        fetchGamificationData()
    }, [fetchGamificationData])

    return user ? <RankMessage title={t("welcome.hi", {userName: user.entity.name})} text={t("welcome.text")}/> : null;
}

export const Welcome = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        user: selectCurrentUser(state),
    }),
    {
        fetchGamificationData: fetchGamificationDataAction
    }
)(WelcomeComponent);
