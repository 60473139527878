import React from "react";
import {UserSettingsScreen} from "./components/UserSettingsScreen";
import {RouteAuthState, RouteDefinition} from "../core/components/routing/RouteDefinition";

export const UserSettingsRouteDefinitions: RouteDefinition[] = [
    {
        path: "/account",
        component: () => <UserSettingsScreen/>,
        authState: RouteAuthState.OnlyLoggedIn
    }
]