import {ReactNode} from "react";
import {RouteComponentProps} from "react-router";

export enum RouteAuthState {
    OnlyLoggedIn = "OnlyLoggedIn",
    OnlyLoggedOut = "OnlyLoggedOut",
    Independent = "Independent"
}

export interface RouteDefinition {
    path: string
    component: (props: RouteComponentProps<any>) => ReactNode
    authState: RouteAuthState
}
