import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import React from "react";
import styled from "styled-components";
import {CurrencyDisplay} from "../../../../core/components/currency/CurrencyDisplay";
import {
    ReviewCartTotalsDisplay_OrderFragment$key
} from "../../../../../__generated__/ReviewCartTotalsDisplay_OrderFragment.graphql";

const ORDER_FRAGMENT = graphql`
    fragment ReviewCartTotalsDisplay_OrderFragment on Order {
        selectedPaymentMethod {
            paymentMethodId
            selectedPaymentMethodType
            ... on MonthlySelectedPaymentMethod {
                chosenOption {
                    totalAmount{
                        grossPrice
                    }
                    downPayment {
                        netPrice
                        tax
                        grossPrice
                    }
                    financedAmount {
                        netPrice
                        tax
                        grossPrice
                    }
                    monthlyRate {
                        netPrice
                        tax
                        grossPrice
                    }
                    totalAmount {
                        netPrice
                        tax
                        grossPrice
                    }
                    rateCount
                }
            }
        }
        cart {
            cartTotals {
                totalAppliedDiscountPercentage
                totalsIncludingAllDiscounts {
                    netPrice
                    tax
                    grossPrice
                }
                totalsWithoutDiscounts {
                    netPrice
                    tax
                    grossPrice
                }
            }
        }

    }
`;

interface OwnProps {
    orderFragmentRef: ReviewCartTotalsDisplay_OrderFragment$key
}

export const ReviewCartTotalsDisplay = ({orderFragmentRef}: OwnProps) => {
    const order = useFragment<ReviewCartTotalsDisplay_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)

    const hasDownPayment = !!order.selectedPaymentMethod?.chosenOption?.downPayment.grossPrice
    const hasExtraCharge = !!order.selectedPaymentMethod?.chosenOption?.rateCount && order.selectedPaymentMethod.chosenOption?.rateCount > 12

    return <div className="mb-4">
        {order.selectedPaymentMethod?.selectedPaymentMethodType === "monthly" && <>
            {hasDownPayment && (<TotalRow>
                <div>Anzahlung</div>
                <RightColumn><CurrencyDisplay
                    value={order.selectedPaymentMethod.chosenOption?.downPayment.grossPrice!}/></RightColumn>
            </TotalRow>)}
            <TotalRow>
                <div>{order.selectedPaymentMethod.chosenOption?.rateCount} Monatliche Zahlungen je</div>
                <RightColumn>
                    <TotalDisplay>
                        <CurrencyDisplay
                            value={order.selectedPaymentMethod.chosenOption?.monthlyRate.grossPrice!}/>
                    </TotalDisplay>
                </RightColumn>
            </TotalRow>

            <SmallRow fullWidth={true}>
                {hasDownPayment && <>
                    Die Anzahlung wird mit der ersten monatlichen Zahlung fällig.
                </>}
                {hasExtraCharge ? <p>
                    Mit dem Aufschlag für monatliche Zahlweise
                    ergibt sich eine Gesamtzahlsumme von <CurrencyDisplay
                    value={order.selectedPaymentMethod.chosenOption?.financedAmount.grossPrice ?? 0}/>
                </p> : <p>
                    Dadurch ergibt sich eine Gesamtzahlsumme von <CurrencyDisplay
                    value={order.selectedPaymentMethod.chosenOption?.financedAmount.grossPrice ?? 0}/>
                </p>
                }
            </SmallRow>

            <TotalRow>
                <MwstInfo>
                    Dieses Weiterbildungsangebot ist im Sinne des § 4 Nr. 21 UStG von der Umsatzsteuer befreit.
                </MwstInfo>
            </TotalRow>
        </>}

        {order.selectedPaymentMethod?.selectedPaymentMethodType === "oneTime" && <>
            <TotalRow>
                <div>Gesamtbetrag</div>
                <RightColumn>
                    <TotalDisplay>
                        {order.cart.cartTotals.totalAppliedDiscountPercentage &&
                            <StrikePrice><CurrencyDisplay
                                value={order.cart.cartTotals.totalsWithoutDiscounts.grossPrice}/></StrikePrice>}
                        <CurrencyDisplay
                            value={order.cart.cartTotals.totalsIncludingAllDiscounts?.grossPrice!}/>
                    </TotalDisplay>
                </RightColumn>
            </TotalRow>
            <TotalRow>
                <MwstInfo>
                    Dieses Weiterbildungsangebot ist im Sinne des § 4 Nr. 21 UStG von der Umsatzsteuer befreit.
                </MwstInfo>
            </TotalRow>
        </>}

    </div>
}

interface SmallRowProps {
    fullWidth?: boolean
}

const SmallRow = styled.div<SmallRowProps>`
  display: grid;
  grid-template-columns: ${props => props && props.fullWidth ? '1fr' : '1fr 1fr'};
  font-size: 14px;
  margin-bottom: 10px;
`

const TotalRow = styled.div`
  font-weight: bold;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const TotalDisplay = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`

const StrikePrice = styled.div`
  text-decoration: line-through;
  font-weight: normal;
  margin-right: 5px;
  @media (max-width: 1024px) {
    margin-right: 0;
  }
`

const RightColumn = styled.div`
  margin-left: auto;
  text-align: right;
  display: flex;
  flex-direction: row;
`

const MwstInfo = styled.div`
  font-size: 12px;
  font-weight: normal;
  grid-column-start: 1;
  grid-column-end: 3;
`
