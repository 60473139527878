/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ClozeTextPartType = "cloze" | "text" | "%future added value";
export type ElementType = "clozeText" | "image" | "multipleChoice" | "order" | "persolog" | "questionnaire" | "resolution" | "text" | "video" | "%future added value";
export type LessonType = "normal" | "test" | "%future added value";
export type PersologClassCode = "d" | "g" | "i" | "s" | "%future added value";
export type LearnScreen_LessonFragment = {
    readonly course: {
        readonly " $fragmentRefs": FragmentRefs<"CelebrationPopup_CourseFragment">;
    };
    readonly elements: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly elementType: ElementType;
                readonly generalSettings: {
                    readonly points: number;
                };
                readonly id: string;
                readonly additionalWords?: ReadonlyArray<string>;
                readonly parts?: ReadonlyArray<{
                    readonly partType: ClozeTextPartType;
                    readonly cloze?: string;
                    readonly text?: string;
                }>;
                readonly allCorrectAnswersNeedToBeGiven?: boolean;
                readonly allowMultipleAnswers?: boolean;
                readonly answerOptions?: ReadonlyArray<{
                    readonly isCorrect: boolean;
                }>;
                readonly orderables?: ReadonlyArray<{
                    readonly text: string;
                }>;
                readonly questions?: ReadonlyArray<{
                    readonly answers: ReadonlyArray<{
                        readonly classCode: PersologClassCode;
                    }>;
                    readonly reverseScale?: boolean;
                    readonly title?: string;
                }>;
                readonly classes?: ReadonlyArray<{
                    readonly description: string;
                    readonly image: {
                        readonly url: string | null;
                    } | null;
                    readonly pointsThreshold: number;
                    readonly title: string;
                }>;
            };
        } | null> | null;
    } | null;
    readonly id: string;
    readonly lessonType: LessonType;
    readonly lessonPath: {
        readonly courseRef: string;
        readonly moduleRef: string;
    };
    readonly isCertificationTest?: boolean;
    readonly " $fragmentRefs": FragmentRefs<"LearnCurrentElementScreen_LessonFragment">;
    readonly " $refType": "LearnScreen_LessonFragment";
};
export type LearnScreen_LessonFragment$data = LearnScreen_LessonFragment;
export type LearnScreen_LessonFragment$key = {
    readonly " $data"?: LearnScreen_LessonFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LearnScreen_LessonFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LearnScreen_LessonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "course",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CelebrationPopup_CourseFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ElementsConnection",
      "kind": "LinkedField",
      "name": "elements",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ElementsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "elementType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GeneralElementSettings",
                  "kind": "LinkedField",
                  "name": "generalSettings",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "points",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "additionalWords",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "parts",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "partType",
                          "storageKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "cloze",
                              "storageKey": null
                            }
                          ],
                          "type": "ClozePart",
                          "abstractKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": (v1/*: any*/),
                          "type": "TextPart",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "ClozeTextElement",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "allCorrectAnswersNeedToBeGiven",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "allowMultipleAnswers",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AnswerOption",
                      "kind": "LinkedField",
                      "name": "answerOptions",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isCorrect",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "MultipleChoiceElement",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Orderable",
                      "kind": "LinkedField",
                      "name": "orderables",
                      "plural": true,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "OrderElement",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PersologQuestion",
                      "kind": "LinkedField",
                      "name": "questions",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PersologAnswer",
                          "kind": "LinkedField",
                          "name": "answers",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "classCode",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "PersologElement",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "QuestionnaireClass",
                      "kind": "LinkedField",
                      "name": "classes",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "description",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "File",
                          "kind": "LinkedField",
                          "name": "image",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "url",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "pointsThreshold",
                          "storageKey": null
                        },
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "QuestionnaireQuestion",
                      "kind": "LinkedField",
                      "name": "questions",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "reverseScale",
                          "storageKey": null
                        },
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "QuestionnaireElement",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lessonType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LessonPath",
      "kind": "LinkedField",
      "name": "lessonPath",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "courseRef",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleRef",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCertificationTest",
          "storageKey": null
        }
      ],
      "type": "TestLesson",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LearnCurrentElementScreen_LessonFragment"
    }
  ],
  "type": "Lesson",
  "abstractKey": "__isLesson"
};
})();
(node as any).hash = '4fb3e370f1ba7c42d168a19d57778e88';
export default node;
