import React, {useContext, useEffect, useState} from "react";
import "./video-element-display.scss";

import {BaseElementDisplay} from "../common/BaseElementDisplay";
import {VideoPlayer} from "../../../core/components/video/VideoPlayer";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {VideoElementDisplay_VideoElementFragment$key} from "../../../../__generated__/VideoElementDisplay_VideoElementFragment.graphql";
import {NoteContextV2} from "@thekeytechnology/thekey-academy-frontend-library";
import {LoadingAnimation} from "../../../billing/v2/components/common/LoadingAnimation";

const VIDEO_ELEMENT_DISPLAY_VIDEO_ELEMENT_FRAGMENT = graphql`
    fragment VideoElementDisplay_VideoElementFragment on VideoElement {
        highDefVideoFile {
            rawId
            url
        }
        id
        title
        videoData {
            videoId
        }
        videoFile {
            url
        }
    }
`

interface State {
    videoFileToUse?: string
}

interface OwnProps {
    videoElementFragmentRef: VideoElementDisplay_VideoElementFragment$key;
    timestamp?: number;
    setTimestampForSubtitles?: (timestamp: number) => void;
    isSettingTimestampInNoteContext?: boolean;
}

export const VideoElementDisplay = ({videoElementFragmentRef, timestamp, setTimestampForSubtitles, isSettingTimestampInNoteContext}: OwnProps) => {
    const element = useFragment<VideoElementDisplay_VideoElementFragment$key>(VIDEO_ELEMENT_DISPLAY_VIDEO_ELEMENT_FRAGMENT, videoElementFragmentRef)

    const noteContext = useContext(NoteContextV2)
    const setNoteContext = isSettingTimestampInNoteContext ? noteContext?.setNoteContext : undefined;

    const [state, setState] = useState<State>({
        videoFileToUse: undefined
    });

    useEffect(() => {
        if (element.highDefVideoFile) {
            const streamUrl = `https://streams.thekey.academy/${element.highDefVideoFile.rawId}/${element.videoData.videoId}.mp4.m3u8`
            fetch(streamUrl).then(result => {
                if (result.status === 200) {
                    setState({
                        videoFileToUse: streamUrl
                    })
                } else if (element.highDefVideoFile!.url) {
                    setState({
                        videoFileToUse: element.highDefVideoFile!.url
                    })
                }

            }).catch(() => {
                if (element.highDefVideoFile!.url) {
                    setState({
                        videoFileToUse: element.highDefVideoFile!.url
                    })
                }
            })
        }

        return () => {
            if(setNoteContext) setNoteContext({videoTimestamp: undefined});
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [element.id])

    if (!element.videoFile?.url) {
        return null;
    }

    return state.videoFileToUse ?
        <BaseElementDisplay
            className="video-element-display"
            heading={element.title}
            elementSpecificSlot={
                <VideoPlayer
                    videoFile={state.videoFileToUse}
                    timestamp={timestamp}
                    onCurrentTimeChange={
                        (updatedTime: number) => {
                            if (setTimestampForSubtitles) setTimestampForSubtitles(updatedTime);
                            if (setNoteContext) setNoteContext({videoTimestamp: Math.floor(updatedTime)});
                        }
                    }
                />
            }
        /> : <LoadingAnimation/>;
}
