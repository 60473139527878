import React from "react";
import {PriceRenderer} from "./PriceRenderer";
import {MonthlyPriceExplanation, PriceExplanation} from "@thekeytechnology/thekey-academy-frontend-library";

interface OwnProps {
    priceRenderer: PriceRenderer
    offerItem: {
        title: string;
        priceExplanation: PriceExplanation,
        monthlyPriceExplanation?: MonthlyPriceExplanation
    };
}

export const DiscountRow = ({offerItem, priceRenderer}: OwnProps) => {
    const price = priceRenderer(offerItem.priceExplanation, offerItem.monthlyPriceExplanation, true)

    return price ? <div className="cart-table-row discount-item-row">
        <hr className="w-100"/>

        <div className="d-flex row-content align-items-center">
            <div className="mr-3 row-title">Rabatt</div>
            <div
                className="discount-title">{offerItem.title}</div>
            <div className="ml-auto d-flex price-wrapper">
                {price}
            </div>
        </div>
    </div> : null;
}
