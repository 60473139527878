import React, {Suspense} from "react";
import {LearnScreen} from "./components/LearnScreen";
import {RouteAuthState, RouteDefinition} from "../../core/components/routing/RouteDefinition";
import {LoadingFakeLearnReviewScreen} from "../../core/loading/flow/learnReview/LoadingFakeLearnReviewScreen";

export const LearnRouteDefinitions: RouteDefinition[] = [
    {
        path: "/courses/:courseId/modules/:moduleId/lessons/:lessonId/learn*",
        component: () => <Suspense fallback={<LoadingFakeLearnReviewScreen/>}>
                <LearnScreen/>
            </Suspense>,
        authState: RouteAuthState.OnlyLoggedIn
    }
]