/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type FaqsScreen_QueryVariables = {};
export type FaqsScreen_QueryResponse = {
    readonly Viewer: {
        readonly CustomerMessageV2: {
            readonly CustomerMessageInformation: {
                readonly id: string;
                readonly numUnseenCustomerMessages: number;
            };
        };
        readonly License: {
            readonly NewLicensesInAccount: {
                readonly id: string;
                readonly newLicenses: boolean;
            };
        };
    };
};
export type FaqsScreen_Query = {
    readonly response: FaqsScreen_QueryResponse;
    readonly variables: FaqsScreen_QueryVariables;
};



/*
query FaqsScreen_Query {
  Viewer {
    CustomerMessageV2 {
      CustomerMessageInformation {
        id
        numUnseenCustomerMessages
      }
    }
    License {
      NewLicensesInAccount {
        id
        newLicenses
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ViewerQueryType",
    "kind": "LinkedField",
    "name": "Viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomerMessageV2ViewerSchema",
        "kind": "LinkedField",
        "name": "CustomerMessageV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerMessageInformation",
            "kind": "LinkedField",
            "name": "CustomerMessageInformation",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numUnseenCustomerMessages",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LicenseViewerSchema",
        "kind": "LinkedField",
        "name": "License",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NewLicensesInAccount",
            "kind": "LinkedField",
            "name": "NewLicensesInAccount",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newLicenses",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FaqsScreen_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FaqsScreen_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "343e1e01d6d73d847ddbfce421017821",
    "id": null,
    "metadata": {},
    "name": "FaqsScreen_Query",
    "operationKind": "query",
    "text": "query FaqsScreen_Query {\n  Viewer {\n    CustomerMessageV2 {\n      CustomerMessageInformation {\n        id\n        numUnseenCustomerMessages\n      }\n    }\n    License {\n      NewLicensesInAccount {\n        id\n        newLicenses\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5ea554d21f6140da8af42352d4552294';
export default node;
