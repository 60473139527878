/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ClassCardGraphQLWrapper_QuestionnaireClassFragment = {
    readonly description: string;
    readonly image: {
        readonly url: string | null;
    } | null;
    readonly pointsThreshold: number;
    readonly title: string;
    readonly " $refType": "ClassCardGraphQLWrapper_QuestionnaireClassFragment";
};
export type ClassCardGraphQLWrapper_QuestionnaireClassFragment$data = ClassCardGraphQLWrapper_QuestionnaireClassFragment;
export type ClassCardGraphQLWrapper_QuestionnaireClassFragment$key = {
    readonly " $data"?: ClassCardGraphQLWrapper_QuestionnaireClassFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ClassCardGraphQLWrapper_QuestionnaireClassFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClassCardGraphQLWrapper_QuestionnaireClassFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pointsThreshold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "QuestionnaireClass",
  "abstractKey": null
};
(node as any).hash = 'e6649e99be3591ea0591af7bcbd6206a';
export default node;
