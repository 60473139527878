import React, {useEffect, useState} from "react";
import {AcademyButton} from "../../../core/components/button/AcademyButton";

interface OwnProps {
    numberOfPages: number;
    currentPage: number;

    goNext: () => void;
    goBack: () => void;
    close: () => void;
}

interface State {
    isLastScreen: boolean;
    canGoBack: boolean;
}


export const FailedElementFooter = ({numberOfPages, currentPage, goBack, goNext, close}: OwnProps) => {
    const [state, setState] = useState<State>({
        canGoBack: false,
        isLastScreen: false
    })

    useEffect(() => setState({
        canGoBack: currentPage > 0,
        isLastScreen: currentPage === numberOfPages - 1
    }), [numberOfPages, currentPage])

    return (
        <div className="d-flex flex-row bottom-0 justify-content-between align-items-center p-3 w-100 bg-light">
            <AcademyButton
                type="reset"
                buttonStyle={"outline"}
                hideIcon={true}
                text={"Zurück"}
                disabled={!state.canGoBack}
                onClick={goBack}/>
            {state.isLastScreen ?
                <AcademyButton
                    type="reset"
                    hideIcon={true}
                    buttonStyle="primary"
                    text={"Schließen"}
                    onClick={close}/> :
                <AcademyButton
                    type="reset"
                    hideIcon={true}
                    buttonStyle="primary"
                    text={"Weiter"}
                    onClick={goNext}/>
            }
        </div>
    )
}