/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CustomerMessagePage_CustomerMessageDefinitionPageFragment = {
    readonly imageOpt: {
        readonly url: string | null;
    } | null;
    readonly customerMessageDefinition: {
        readonly buttonOpt: {
            readonly " $fragmentRefs": FragmentRefs<"CustomerMessageDefinitionButton_CustomerMessageDefinitionButtonFragment">;
        } | null;
    } | null;
    readonly " $refType": "CustomerMessagePage_CustomerMessageDefinitionPageFragment";
};
export type CustomerMessagePage_CustomerMessageDefinitionPageFragment$data = CustomerMessagePage_CustomerMessageDefinitionPageFragment;
export type CustomerMessagePage_CustomerMessageDefinitionPageFragment$key = {
    readonly " $data"?: CustomerMessagePage_CustomerMessageDefinitionPageFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CustomerMessagePage_CustomerMessageDefinitionPageFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerMessagePage_CustomerMessageDefinitionPageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "imageOpt",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerMessageDefinitionV2",
      "kind": "LinkedField",
      "name": "customerMessageDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "buttonOpt",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CustomerMessageDefinitionButton_CustomerMessageDefinitionButtonFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CustomerMessageDefinitionPage",
  "abstractKey": null
};
(node as any).hash = 'd1963df2c465bc7cd2d9c949e93ca7a7';
export default node;
