/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PaypalButtonV3_OrderFragment = {
    readonly id: string;
    readonly purchaser: {
        readonly userRef: string;
    } | null;
    readonly cart: {
        readonly cartItems: ReadonlyArray<{
            readonly product?: {
                readonly id: string;
                readonly title: string;
            };
        }>;
        readonly cartSelection: {
            readonly selectedProducts: ReadonlyArray<{
                readonly amount: number;
                readonly productId: string;
            }>;
        };
        readonly cartTotals: {
            readonly totalsIncludingAllDiscounts: {
                readonly grossPrice: number;
                readonly netPrice: number;
            } | null;
        };
    };
    readonly " $refType": "PaypalButtonV3_OrderFragment";
};
export type PaypalButtonV3_OrderFragment$data = PaypalButtonV3_OrderFragment;
export type PaypalButtonV3_OrderFragment$key = {
    readonly " $data"?: PaypalButtonV3_OrderFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"PaypalButtonV3_OrderFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PaypalButtonV3_OrderFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Purchaser",
      "kind": "LinkedField",
      "name": "purchaser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userRef",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "cartItems",
          "plural": true,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "ProductCartItem",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CartSelection",
          "kind": "LinkedField",
          "name": "cartSelection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SelectedProduct",
              "kind": "LinkedField",
              "name": "selectedProducts",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "productId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CartTotals",
          "kind": "LinkedField",
          "name": "cartTotals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceV3",
              "kind": "LinkedField",
              "name": "totalsIncludingAllDiscounts",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "grossPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "netPrice",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();
(node as any).hash = '4dc455919dfe54ba08383dba22c8788d';
export default node;
