/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseAdditionalContentModuleCard_AdditionalContentByModuleFragment = {
    readonly additionalContent: ReadonlyArray<{
        readonly additionalContent: {
            readonly id: string;
        };
        readonly lessonPath: {
            readonly moduleIndex: number;
        };
        readonly " $fragmentRefs": FragmentRefs<"AdditionalContentItem_AdditionalContentWithLessonPathFragment">;
    }>;
    readonly module: {
        readonly containerMeta: {
            readonly title: string;
        };
        readonly modulePath: {
            readonly moduleIndex: number;
        };
    };
    readonly " $refType": "CourseAdditionalContentModuleCard_AdditionalContentByModuleFragment";
};
export type CourseAdditionalContentModuleCard_AdditionalContentByModuleFragment$data = CourseAdditionalContentModuleCard_AdditionalContentByModuleFragment;
export type CourseAdditionalContentModuleCard_AdditionalContentByModuleFragment$key = {
    readonly " $data"?: CourseAdditionalContentModuleCard_AdditionalContentByModuleFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseAdditionalContentModuleCard_AdditionalContentByModuleFragment">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "moduleIndex",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseAdditionalContentModuleCard_AdditionalContentByModuleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdditionalContentWithLessonPath",
      "kind": "LinkedField",
      "name": "additionalContent",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "additionalContent",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LessonPath",
          "kind": "LinkedField",
          "name": "lessonPath",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AdditionalContentItem_AdditionalContentWithLessonPathFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Module",
      "kind": "LinkedField",
      "name": "module",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContainerMeta",
          "kind": "LinkedField",
          "name": "containerMeta",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ModulePath",
          "kind": "LinkedField",
          "name": "modulePath",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AdditionalContentByModule",
  "abstractKey": null
};
})();
(node as any).hash = 'a2f73b08478123f13131dfbd35041d7b';
export default node;
