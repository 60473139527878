import {tkColorCodes} from "./tkColorCodes";

export const tkBackgrounds = {
    keyBlue: `
        background-color: ${tkColorCodes.keyBlue};
     `,
    keyGreen: `
        background-color: ${tkColorCodes.keyGreen};
     `,
    keyBlue30: `
        background-color: ${tkColorCodes.keyBlue30};
     `,
    keyBlue20: `
        background-color: ${tkColorCodes.keyBlue20};
     `,
    keyGreen20: `
        background-color: ${tkColorCodes.keyGreen20};
     `,
    darkBlue: `
        background-color: ${tkColorCodes.darkBlue};
     `,
    waterBlue: `
        background-color: ${tkColorCodes.waterBlue};
     `,
    waterBlue80: `
        background-color: ${tkColorCodes.waterBlue80};
     `,
    waterBlue40: `
        background-color: ${tkColorCodes.waterBlue40};
     `,
    waterBlue20: `
        background-color: ${tkColorCodes.waterBlue20};
     `,
    waterBlue10: `
        background-color: ${tkColorCodes.waterBlue10};
     `,
    lightBackground: `
        background-color: ${tkColorCodes.lightBackground};
     `,
    orange: `
        background-color: ${tkColorCodes.orange};
     `,
    sun: `
        background-color: ${tkColorCodes.sun};
     `,
    orange20: `
        background-color: ${tkColorCodes.orange20};
     `,
    sun20: `
        background-color: ${tkColorCodes.sun20};
     `,
}