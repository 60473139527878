import {CourseDisplay} from "../CourseDisplay";
import {EndOfMonth} from "../../../../../../core/components/end-of-month/EndOfMonth";
import React, {ReactNode} from "react";
import {PriceRenderer} from "./PriceRenderer";
import {
    CourseInformation,
    DiscountInformation,
    MonthlyPriceExplanation,
    PriceExplanation
} from "@thekeytechnology/thekey-academy-frontend-library";

interface OwnProps {
    priceRenderer: PriceRenderer
    offerItem: {
        priceExplanation: PriceExplanation
        monthlyPriceExplanation?: MonthlyPriceExplanation
    };
    course: CourseInformation

    amount?: number
    isOwned?: boolean;
    appliedDiscount?: DiscountInformation

    beforePriceSlot?: ReactNode
    afterCourseTitleSlot?: ReactNode
}

export const CourseOfferRow = ({
                                   offerItem,
                                   appliedDiscount,
                                   amount,
                                   course,
                                   priceRenderer,
                                   isOwned,
                                   beforePriceSlot,
                                   afterCourseTitleSlot
                               }: OwnProps) => {
    const price = priceRenderer(
        offerItem.priceExplanation,
        offerItem.monthlyPriceExplanation
    );

    return price ? <div className="cart-table-row course-item-row">
        {appliedDiscount ? <div className="tk-academy-large-bold tk-color-waterblue-40 mb-2 applied-discount">
            <span className="text-uppercase">{appliedDiscount.title}:</span> Nur noch bis
            zum <EndOfMonth/> {appliedDiscount.text}
        </div> : null}

        <div className="d-flex row-content">
            <div className="mr-3 d-flex">
                {course.courseIcon ? <img className="course-icon" src={course.courseIcon.entity.url}
                                          alt={course.title}/> : <div className="course-icon-placeholder"/>}
            </div>

            <div className="d-flex flex-column w-100">
                <div className="d-flex course-and-price">
                    <CourseDisplay afterTitleSlot={afterCourseTitleSlot} className="mr-3" course={course}
                                   beforeTitleSlot={amount ? `${amount}x` : undefined}/>
                    <div className="d-flex price-wrapper ml-auto">
                        {beforePriceSlot}
                        {price}
                    </div>
                </div>
                <hr className="w-100"/>
            </div>
        </div>


        {isOwned ?
            <div className="already-owned d-flex">
                <div className="alert alert-success m-auto">
                    Du besitzt diesen Lehrgang bereits und musst ihn nicht erneut kaufen.
                </div>
            </div> : null}
    </div> : null;
}
