import React from "react";
import styled from "styled-components";
import {tkTypography} from "../../../core/style/tkTypography";
import {tkColors} from "../../../core/style/tkColors";
import {tkBackgrounds} from "../../../core/style/tkBackgrounds";
import {tkColorCodes} from "../../../core/style/tkColorCodes";

interface OwnProps {
    word: string;
    selectWord: (word: string) => void;
}

export const DraggableWord = ({word, selectWord}: OwnProps) => (
    <DraggableWordContainer className={"pb-1 pt-1 pr-2 pl-2"} onClick={() => selectWord(word)}>{word}</DraggableWordContainer>
)


const DraggableWordContainer = styled.span`
  border-radius: 5px;
  border: solid 1px ${tkColorCodes.waterBlue20};
  cursor: pointer;

  ${tkTypography.large};
  ${tkColors.keyBlue};
  ${tkBackgrounds.lightBackground};
`