/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type QnasScreen_QueryFragment = {
    readonly Qnas: {
        readonly Qnas: {
            readonly __id: string;
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly " $fragmentRefs": FragmentRefs<"QnaItem_QnaFragment">;
                };
            } | null> | null;
        } | null;
    };
    readonly " $refType": "QnasScreen_QueryFragment";
};
export type QnasScreen_QueryFragment$data = QnasScreen_QueryFragment;
export type QnasScreen_QueryFragment$key = {
    readonly " $data"?: QnasScreen_QueryFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"QnasScreen_QueryFragment">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "Qnas",
  "Qnas"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "courseId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "elementId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lessonId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./QnasScreenQueryFragmentRefetchQuery.graphql.ts')
    }
  },
  "name": "QnasScreen_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "QnasQueries",
      "kind": "LinkedField",
      "name": "Qnas",
      "plural": false,
      "selections": [
        {
          "alias": "Qnas",
          "args": [
            {
              "kind": "Variable",
              "name": "courseId",
              "variableName": "courseId"
            },
            {
              "kind": "Variable",
              "name": "elementId",
              "variableName": "elementId"
            },
            {
              "kind": "Variable",
              "name": "lessonId",
              "variableName": "lessonId"
            }
          ],
          "concreteType": "QnasConnection",
          "kind": "LinkedField",
          "name": "__QnasScreen_Query_Qnas_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QnasEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Qna",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "QnaItem_QnaFragment"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "ClientExtension",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__id",
                  "storageKey": null
                }
              ]
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '023e0099a78bf47c5beedc544f25ea84';
export default node;
