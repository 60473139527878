/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PersologAnswerDisplay_PersologAnswerFragment = {
    readonly answer: string;
    readonly " $refType": "PersologAnswerDisplay_PersologAnswerFragment";
};
export type PersologAnswerDisplay_PersologAnswerFragment$data = PersologAnswerDisplay_PersologAnswerFragment;
export type PersologAnswerDisplay_PersologAnswerFragment$key = {
    readonly " $data"?: PersologAnswerDisplay_PersologAnswerFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"PersologAnswerDisplay_PersologAnswerFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersologAnswerDisplay_PersologAnswerFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answer",
      "storageKey": null
    }
  ],
  "type": "PersologAnswer",
  "abstractKey": null
};
(node as any).hash = '9d3c519f4d088415fffc65775022f418';
export default node;
