import {NavLink} from "react-router-dom";
import React from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {FeedbackButton} from "../../feedback/controllers/FeedbackButton";
import {SubmissionFinishedHeader_SubmissionFragment$key} from "../../../__generated__/SubmissionFinishedHeader_SubmissionFragment.graphql";

const SUBMISSION_FINISHED_HEADER_SUBMISSION_FRAGMENT = graphql`
    fragment SubmissionFinishedHeader_SubmissionFragment on Submission {
        courseRef
        ... on LessonSubmission {
            lesson {
                id
                lessonPath {
                    courseRef
                    moduleRef
                }
            }
        }
        submissionType
    }`

interface OwnProps {
    submissionFragmentRef: SubmissionFinishedHeader_SubmissionFragment$key;
}

export const SubmissionFinishedHeader = ({submissionFragmentRef}: OwnProps) => {

    const submission = useFragment<SubmissionFinishedHeader_SubmissionFragment$key>(SUBMISSION_FINISHED_HEADER_SUBMISSION_FRAGMENT, submissionFragmentRef)

    const feedbackButton = submission.submissionType === 'learn' ?
        <FeedbackButton className="mr-2" courseId={submission.lesson!.lessonPath.courseRef}
                        lessonId={submission.lesson!.id} screen={"learn-finished"}/> : undefined;

    const navLink = (_ => {
        switch (submission.submissionType) {
            case 'test':
            case 'certificationTest':
            case 'learn':
                return <NavLink
                    className="tk-academy-larger-bold tk-white ml -3 d-flex"
                    to={`/courses/${submission.courseRef}/modules/${submission.lesson!.lessonPath.moduleRef}/lessons/${submission.lesson!.id}`}>
                    <span className="icon-a_close"/>
                </NavLink>;
            case 'review':
                return <NavLink
                    className="tk-academy-larger-bold tk-white ml-3 d-flex"
                    to={`/courses/${submission.courseRef}/review`}>
                    <span className="icon-a_close"/>
                </NavLink>;
            default:
                return undefined;
        }
    })()

    return <div className="ml-auto d-flex align-items-center">
        {feedbackButton}
        {navLink}
    </div>
}
