import React from "react";
import {AcademyButton} from "../../../../core/components/button/AcademyButton";
import {NavLink} from "react-router-dom";


export const ThankYouV2 = () => {

    return <div className="d-flex justify-content-center flex-column p-5 align-items-center p-3 tk-academy-card">
        <h3 className="tk-academy-huge-text tk-color-waterblue tk-academy-bold text-center mb-2">Vielen Dank für Deine
            Bestellung</h3>
        <div className="tk-academy-large tk-color-darkblue mb-3 text-center">
            Deine Rechnung schicken wir Dir umgehend an Deine hinterlegte E-Mail Adresse. Nun steht Deiner persönlichen
            Weiterentwicklung nichts mehr im Weg. <strong>Deine Lehrgänge werden in wenigen Minuten
            freigeschaltet.</strong> Du erhältst
            eine E-Mail, sobald die Freischaltung erfolgt ist. Klicke hier unten, um ins Dashboard zu gelangen.
        </div>
        <NavLink to={`/`}>
            <AcademyButton text={"zum Dashboard"}/>
        </NavLink>
    </div>
}
