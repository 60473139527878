import React from "react";
import {LessonCard} from "./LessonCard";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {CourseContent_CourseFragment$key} from "../../../../../__generated__/CourseContent_CourseFragment.graphql";
import {CourseContent_CourseStateFragment$key} from "../../../../../__generated__/CourseContent_CourseStateFragment.graphql";
import {useRouteMatch} from "react-router";

const COURSE_CONTENT_COURSE_FRAGMENT = graphql`
    fragment CourseContent_CourseFragment on Course {
        id
        modules {
            id
            lessons {
                id
                ...LessonCard_LessonFragment
            }
            modulePath {
                moduleIndex
            }
            ...LessonCard_ModuleFragment
        }
    }`

const COURSE_CONTENT_COURSE_STATE_FRAGMENT = graphql`
    fragment CourseContent_CourseStateFragment on CourseState {
        lessonStates {
            lessonRef
            ...LessonCard_LessonStateFragment
        }
    }`

interface OwnProps {
    courseFragmentRef: CourseContent_CourseFragment$key;
    courseStateFragmentRef: CourseContent_CourseStateFragment$key | null;
    moduleId: string;
}

const CourseContentComponent = ({courseFragmentRef, courseStateFragmentRef, moduleId}: OwnProps) => {

    const course = useFragment<CourseContent_CourseFragment$key>(COURSE_CONTENT_COURSE_FRAGMENT, courseFragmentRef);

    const courseState = useFragment<CourseContent_CourseStateFragment$key>(COURSE_CONTENT_COURSE_STATE_FRAGMENT, courseStateFragmentRef);

    const module = course.modules.find(m => m.id === moduleId);

    if (!module) return null;

    return <>
        <div className="tk-academy-text tk-academy-bold mb-2 tk-color-waterblue-80"
             id="info">Modul {module.modulePath.moduleIndex + 1}</div>
        <div className="tk-academy-extra-large-heading-bold mb-5">Kursinhalt</div>

        {module.lessons.map(lesson => {
            const lessonState = courseState?.lessonStates.find(lS => lS.lessonRef === lesson.id);
            return <LessonCard key={lesson.id} lessonFragmentRef={lesson} lessonStateFragmentRef={lessonState}
                               moduleFragmentRef={module}/>;
        })}
    </>;
}

export const CourseContent = ({courseFragmentRef, courseStateFragmentRef}: {
    courseFragmentRef: CourseContent_CourseFragment$key; courseStateFragmentRef: CourseContent_CourseStateFragment$key | null;
}) => {
    const router = useRouteMatch<{ moduleId?: string }>();

    if (!router.params.moduleId) return null;

    return <CourseContentComponent courseFragmentRef={courseFragmentRef} courseStateFragmentRef={courseStateFragmentRef} moduleId={router.params.moduleId}/>
}
