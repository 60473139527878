/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProductType = "courseProduct" | "%future added value";
export type ProductDisplay_ProductFragment = {
    readonly id: string;
    readonly productType: ProductType;
    readonly price: {
        readonly netPrice: number;
        readonly grossPrice: number;
    };
    readonly title: string;
    readonly course?: {
        readonly isIHK: boolean;
        readonly icon: {
            readonly url: string | null;
        } | null;
    } | null;
    readonly " $refType": "ProductDisplay_ProductFragment";
};
export type ProductDisplay_ProductFragment$data = ProductDisplay_ProductFragment;
export type ProductDisplay_ProductFragment$key = {
    readonly " $data"?: ProductDisplay_ProductFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ProductDisplay_ProductFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductDisplay_ProductFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceV3",
      "kind": "LinkedField",
      "name": "price",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "netPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grossPrice",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Course",
          "kind": "LinkedField",
          "name": "course",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isIHK",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "icon",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "CourseProduct",
      "abstractKey": null
    }
  ],
  "type": "Product",
  "abstractKey": "__isProduct"
};
(node as any).hash = '7b13362bf91f6366bf10470654b19662';
export default node;
