import React, {useContext} from "react";
import {OrderFlowContext} from "../../hooks/OrderFlowContext";
import {AcademyButton} from "../../../../core/components/button/AcademyButton";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {OrderFlowFooter_OrderFragment$key} from "../../../../../__generated__/OrderFlowFooter_OrderFragment.graphql";
import {PaypalButtonV3} from "../review/PaypalButtonV3";
import styled from "styled-components";

const ORDER_FRAGMENT = graphql`
    fragment OrderFlowFooter_OrderFragment on Order {
        selectedPaymentMethod{
            paymentMethodId
        }
        ...PaypalButtonV3_OrderFragment
    }
`;

interface OwnProps {
    orderFragmentRef: OrderFlowFooter_OrderFragment$key
}

export const OrderFlowFooter = ({orderFragmentRef}: OwnProps) => {
    const order = useFragment<OrderFlowFooter_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)

    const {canAdvance, canGoBack, tryAdvance, goBack, openStage, reviewFormValid} = useContext(OrderFlowContext)

    return <FooterContainer>
        <InnerContainer>
            <AcademyButton
                disabled={!canGoBack}
                onClick={() => goBack()}
                buttonStyle={"outline"} icon={"back"} type={"reset"} text={"Zurück"}/>

            {order.selectedPaymentMethod?.paymentMethodId === "paypal" && openStage === "review" && reviewFormValid ?
                <PaypalButtonV3 orderFragmentRef={order}/> :
                <AcademyButton
                    disabled={!canAdvance}
                    onClick={() => tryAdvance()}
                    className="ml-auto"
                    text={openStage === "review" ? "Jetzt kostenpflichtig bestellen" : "Weiter"}/>}
        </InnerContainer>
    </FooterContainer>
}

const FooterContainer = styled.div`
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 20px 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  @media (max-width: 1024px) {
    padding: 20px;
  }
`

const InnerContainer = styled.div`
  display: flex;
  max-width: 893px;
  width: 100%;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  align-items: center;

  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;

    button {
      min-width: auto;
      width: 100%;
      line-height: 1 !important;
    }
  }
`
