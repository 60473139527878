import React from "react";

interface OwnProps {
    className?: string;
}

export const LoadingFakeFlowSidebar = ({className}: OwnProps) => {

    return <div className={`flex-row flex-nowrap ${className ? className : ""}`}>
        <div className="academy-sidebar menu-column h-100 d-flex flex-column">
            <div className="tk-bg-waterblue p-4">
                <div className="tk-white tk-academy-small-text tk-academy-bold"/>
            </div>
            <div className="tk-bg-waterblue flex-grow-1">
            </div>
        </div>
    </div>;
}
