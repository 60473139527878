/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LessonCard_ModuleFragment = {
    readonly id: string;
    readonly " $refType": "LessonCard_ModuleFragment";
};
export type LessonCard_ModuleFragment$data = LessonCard_ModuleFragment;
export type LessonCard_ModuleFragment$key = {
    readonly " $data"?: LessonCard_ModuleFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LessonCard_ModuleFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonCard_ModuleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Module",
  "abstractKey": null
};
(node as any).hash = 'f3ea09456e366f247cc381bf3abaef96';
export default node;
