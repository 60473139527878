/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Subtitles_VideoElementFragment = {
    readonly subtitles: {
        readonly subtitles: ReadonlyArray<{
            readonly endTime: unknown;
            readonly startTime: unknown;
            readonly text: string;
        }>;
    } | null;
    readonly " $refType": "Subtitles_VideoElementFragment";
};
export type Subtitles_VideoElementFragment$data = Subtitles_VideoElementFragment;
export type Subtitles_VideoElementFragment$key = {
    readonly " $data"?: Subtitles_VideoElementFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"Subtitles_VideoElementFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Subtitles_VideoElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Subtitles",
      "kind": "LinkedField",
      "name": "subtitles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Subtitle",
          "kind": "LinkedField",
          "name": "subtitles",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "VideoElement",
  "abstractKey": null
};
(node as any).hash = 'ee42dd2703e5851c65b7536a7c5dae4f';
export default node;
