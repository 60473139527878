/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseAttributes = "earlyBird" | "hidden" | "new" | "published" | "%future added value";
export type LicenseAvailable_CourseFragment = {
    readonly attributes: ReadonlyArray<CourseAttributes>;
    readonly " $fragmentRefs": FragmentRefs<"LicensePoolDisplay_CourseFragment">;
    readonly " $refType": "LicenseAvailable_CourseFragment";
};
export type LicenseAvailable_CourseFragment$data = LicenseAvailable_CourseFragment;
export type LicenseAvailable_CourseFragment$key = {
    readonly " $data"?: LicenseAvailable_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LicenseAvailable_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LicenseAvailable_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attributes",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LicensePoolDisplay_CourseFragment"
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = 'a54b049778b37af2a09353aed6e11bd4';
export default node;
