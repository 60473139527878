/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ElementType = "clozeText" | "image" | "multipleChoice" | "order" | "persolog" | "questionnaire" | "resolution" | "text" | "video" | "%future added value";
export type QuestionnaireElementDisplay_QuestionnaireElementFragment = {
    readonly classes: ReadonlyArray<{
        readonly description: string;
        readonly image: {
            readonly url: string | null;
        } | null;
        readonly pointsThreshold: number;
        readonly title: string;
        readonly " $fragmentRefs": FragmentRefs<"ClassCardGraphQLWrapper_QuestionnaireClassFragment">;
    }>;
    readonly elementType: ElementType;
    readonly generalSettings: {
        readonly points: number;
    };
    readonly id: string;
    readonly questions: ReadonlyArray<{
        readonly reverseScale: boolean;
        readonly title: string;
    }>;
    readonly " $refType": "QuestionnaireElementDisplay_QuestionnaireElementFragment";
};
export type QuestionnaireElementDisplay_QuestionnaireElementFragment$data = QuestionnaireElementDisplay_QuestionnaireElementFragment;
export type QuestionnaireElementDisplay_QuestionnaireElementFragment$key = {
    readonly " $data"?: QuestionnaireElementDisplay_QuestionnaireElementFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"QuestionnaireElementDisplay_QuestionnaireElementFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionnaireElementDisplay_QuestionnaireElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionnaireClass",
      "kind": "LinkedField",
      "name": "classes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pointsThreshold",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClassCardGraphQLWrapper_QuestionnaireClassFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GeneralElementSettings",
      "kind": "LinkedField",
      "name": "generalSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "points",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionnaireQuestion",
      "kind": "LinkedField",
      "name": "questions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reverseScale",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "QuestionnaireElement",
  "abstractKey": null
};
})();
(node as any).hash = 'b4f1c8d58771350fa1d7922d96b87a03';
export default node;
