/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ClozeTextPartType = "cloze" | "text" | "%future added value";
export type FailedClozeElement_FailedElementFragment = {
    readonly element: {
        readonly additionalWords?: ReadonlyArray<string>;
        readonly availableWordsInRandomOrder?: ReadonlyArray<string>;
        readonly parts?: ReadonlyArray<{
            readonly partType: ClozeTextPartType;
            readonly text?: string;
            readonly cloze?: string;
        }>;
    };
    readonly mistakes: {
        readonly explanations?: ReadonlyArray<{
            readonly cloze: string;
            readonly text: string;
            readonly isCorrect: boolean;
        }>;
    };
    readonly " $refType": "FailedClozeElement_FailedElementFragment";
};
export type FailedClozeElement_FailedElementFragment$data = FailedClozeElement_FailedElementFragment;
export type FailedClozeElement_FailedElementFragment$key = {
    readonly " $data"?: FailedClozeElement_FailedElementFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"FailedClozeElement_FailedElementFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cloze",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FailedClozeElement_FailedElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "element",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "additionalWords",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "availableWordsInRandomOrder",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "parts",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "partType",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "type": "TextPart",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "type": "ClozePart",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ClozeTextElement",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "mistakes",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClozePartExplanation",
              "kind": "LinkedField",
              "name": "explanations",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isCorrect",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ClozeTextMistakes",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FailedElement",
  "abstractKey": null
};
})();
(node as any).hash = '0aa9a08bd123f1bd40fb85580088072a';
export default node;
