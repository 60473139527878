import React, {ReactNode} from "react";

import "./menu.scss"
import {MenuItemDisplay} from "./MenuItemDisplay";

export type SubItemFactory = (item: MenuItem, collapsed?: boolean) => ReactNode

interface OwnProps {
    collapsed?: boolean;
    items: MenuItem[];
    rootSizeClass?: string
}

export interface MenuItem {
    key: string;
    path: string;
    icon?: string;
    subItems?: MenuItem[];
    subItemFactory?: SubItemFactory;
    data?: any,
    showBadge?: boolean;
    badgeContent?: string;
}

export const Menu = ({
                         items,
                         collapsed,
                         rootSizeClass,
                     }: OwnProps) => {

    return <ul
        className="menu pl-0 pr-0 pt-3 pb-2 d-flex flex-column align-item-center">
        {items.map(route => {
            return <MenuItemDisplay
                key={route.key}
                route={route}
                collapsed={collapsed}
                rootSizeClass={rootSizeClass}
                badgeContent={route.badgeContent}
                showBadge={route.showBadge}
            />
        })}
    </ul>
}
