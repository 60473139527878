import React from "react";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {BillingDetailsStepClosed_BillingDetailsFragment$key} from "../../../../../__generated__/BillingDetailsStepClosed_BillingDetailsFragment.graphql";
import styled from "styled-components";

interface OwnProps {
    billingDetailsFragmentRef: BillingDetailsStepClosed_BillingDetailsFragment$key | null | undefined
}

export const BillingDetailsStepClosed = ({billingDetailsFragmentRef}: OwnProps) => {
    const billingDetails = useFragment<BillingDetailsStepClosed_BillingDetailsFragment$key>(graphql`
        fragment BillingDetailsStepClosed_BillingDetailsFragment on BillingDetails {
            customerType
            firstName
            lastName
            companyBillingDetails {
                company
            }
        }
    `, billingDetailsFragmentRef || null)

    return <ClosedStateContainer>
        {billingDetails?.customerType === "private" && <>
            Private Bestellung für {billingDetails.firstName} {billingDetails.lastName}
        </>}
        {billingDetails?.customerType === "business" && <>
            Geschäftliche Bestellung für {billingDetails.companyBillingDetails?.company}
        </>}
    </ClosedStateContainer>
}

const ClosedStateContainer = styled.div`
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  line-height: 1.38;
  color: #184276;
  margin-top: 5px;
`
