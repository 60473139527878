import React from "react";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {CollapsibleCard} from "../../core/components/collapsible-card/CollapsibleCard";
import {FAQ} from "@thekeytechnology/thekey-academy-frontend-library"

interface OwnProps {
    faq: EntityWrapper<FAQ>
}

export const FAQItem = ({faq}: OwnProps) => {
    return <CollapsibleCard
        title={faq.entity.question}
        className="tk-bg-white mb-4"
        renderChildren={() => <div className="tk-academy-text" dangerouslySetInnerHTML={{__html: faq.entity.answer}}/>}
    />
}
