import React from 'react';
import styled from "styled-components";
import {academyColorsForStyledComps} from "../../../theme/academy-colors-for-styled-comps";
import {academyFontsForStyledComps} from "../../../theme/academy-fonts-for-styled-comps";
import {academyTextSizesForStyledComps} from "../../../theme/academy-text-sizes-for-styled-comps";
import {tkColorCodes} from "../../../core/style/tkColorCodes";

export const COURSE_STATUS = {
    EARLY_BIRD: "early-bird",
    NEW: "new",
    ACTIVE: "active",
    TEST: "test",
    NONE: "none",
    FINISHED: "finished"
}

const getTextAndColorFromStatus = (status: string): {text: string, color: string} | undefined => {
    switch (status) {
        case COURSE_STATUS.EARLY_BIRD:
            return {text: "Early Bird", color: tkColorCodes.sun};
        case COURSE_STATUS.NEW:
            return {text: "Neu", color: tkColorCodes.sun};
        case COURSE_STATUS.ACTIVE:
            return {text: "Aktiv", color: '#247dc5'};
        case COURSE_STATUS.TEST:
            return {text: "Testzugang", color: '#a2b3c8'};
        case COURSE_STATUS.NONE:
            return undefined;
        case COURSE_STATUS.FINISHED:
            return {text: "Abgeschlossen", color: '#4ace7f'};
        default:
            return undefined;
    }
}

interface OwnProps {
    status: string;
}

export const CourseStatusBadge = ({status}: OwnProps) => {

    const textAndColor = getTextAndColorFromStatus(status)

    return textAndColor ? <Container backgroundColor={textAndColor.color}><Badge>{textAndColor.text}</Badge></Container> : null;
};

const Container = styled.div<{backgroundColor: string}>`
    padding: 0px 4px;
    background-color: ${props => props.backgroundColor};
    border-radius: 10px;
`

const Badge = styled.span`
    ${academyColorsForStyledComps.white}
    ${academyFontsForStyledComps.bold}
    ${academyTextSizesForStyledComps.small}
`