import React from "react";
import {VideoAdditionalContentDisplay} from "./VideoAdditionalContentDisplay";
import {TextAdditionalContentDisplay} from "./TextAdditionalContentDisplay";
import {FileAdditionalContentDisplay} from "./FileAdditionalContentDisplay";
import {
    ADDITIONAL_CONTENT_TYPE_FILE,
    ADDITIONAL_CONTENT_TYPE_TEXT,
    ADDITIONAL_CONTENT_TYPE_VIDEO
} from "@thekeytechnology/thekey-academy-frontend-library";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {AdditionalContentDisplay_AdditionalContentFragment$key} from "../../../__generated__/AdditionalContentDisplay_AdditionalContentFragment.graphql";

export const ADDITIONAL_CONTENT_DISPLAY_ADDITIONAL_CONTENT_FRAGMENT = graphql`
    fragment AdditionalContentDisplay_AdditionalContentFragment on AdditionalContent {
        contentType
        ... on FileAdditionalContent {
            ...FileAdditionalContentDisplay_FileAdditionalContentFragment
        }
        ... on TextAdditionalContent {
            ...TextAdditionalContentDisplay_TextAdditionalContentFragment
        }
        ... on VideoAdditionalContent {
            ...VideoAdditionalContentDisplay_VideoAdditionalContentFragment
        }
    }`

interface OwnProps {
    additionalContentFragmentRef: AdditionalContentDisplay_AdditionalContentFragment$key;
}

export const AdditionalContentDisplay = ({
                                             additionalContentFragmentRef
                                         }: OwnProps) => {

    const additionalContent = useFragment<AdditionalContentDisplay_AdditionalContentFragment$key>(ADDITIONAL_CONTENT_DISPLAY_ADDITIONAL_CONTENT_FRAGMENT, additionalContentFragmentRef)

    let ContentDisplay: any | null;
    switch (additionalContent.contentType) {
        case ADDITIONAL_CONTENT_TYPE_VIDEO:
            ContentDisplay = <VideoAdditionalContentDisplay videoAdditionalContentFragmentRef={additionalContent}/>
            break;
        case ADDITIONAL_CONTENT_TYPE_TEXT:
            ContentDisplay = <TextAdditionalContentDisplay textAdditionalContentFragmentRef={additionalContent}/>
            break;
        case ADDITIONAL_CONTENT_TYPE_FILE:
            ContentDisplay = <FileAdditionalContentDisplay fileAdditionalContentFragmentRef={additionalContent}/>
            break;
    }

    return <div className="p-5">{ContentDisplay}</div>
}
