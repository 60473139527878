import React from "react";
import {AdvanceCourseButton} from "../../../../state/components/AdvanceCourseButton";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {CourseDetailFooter_CourseFragment$key} from "../../../../../__generated__/CourseDetailFooter_CourseFragment.graphql";
import {CourseDetailFooter_CourseStateFragment$key} from "../../../../../__generated__/CourseDetailFooter_CourseStateFragment.graphql";
import {CourseDetailFooter_LicensePoolsFragment$key} from "../../../../../__generated__/CourseDetailFooter_LicensePoolsFragment.graphql";

const COURSE_FRAGMENT = graphql`
    fragment CourseDetailFooter_CourseFragment on Course {
        ...AdvanceCourseButton_CourseFragment
    }`

const COURSE_STATE_FRAGMENT = graphql`
    fragment CourseDetailFooter_CourseStateFragment on CourseState {
        ...AdvanceCourseButton_CourseStateFragment
    }`

const LICENSE_POOLS_FRAGMENT = graphql`
    fragment CourseDetailFooter_LicensePoolsFragment on LicensePoolsConnection {
        ...AdvanceCourseButton_LicensePoolsFragment
    }
`

interface OwnProps {
    courseFragmentRef: CourseDetailFooter_CourseFragment$key
    courseStateFragmentRef: CourseDetailFooter_CourseStateFragment$key | null
    licensePoolsFragmentRef: CourseDetailFooter_LicensePoolsFragment$key
    refetchCourseDetailScreenQuery: () => void
}

export const CourseDetailFooter = ({courseFragmentRef, courseStateFragmentRef, licensePoolsFragmentRef, refetchCourseDetailScreenQuery}: OwnProps) => {
    const course = useFragment<CourseDetailFooter_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef)
    const courseState = useFragment<CourseDetailFooter_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, courseStateFragmentRef)
    const licensePools = useFragment<CourseDetailFooter_LicensePoolsFragment$key>(LICENSE_POOLS_FRAGMENT, licensePoolsFragmentRef)

    return <div className="d-flex align-items-end p-3">
        <AdvanceCourseButton licensePoolsFragmentRef={licensePools} courseFragmentRef={course}
                             courseStateFragmentRef={courseState} className="ml-auto" refetchCourseDetailScreenQuery={refetchCourseDetailScreenQuery}/>
    </div>
}
