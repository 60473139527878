/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AssignLicenseButton_LicensesInAccountContainerFragment = {
    readonly id: string;
    readonly courseLocksByUser: ReadonlyArray<{
        readonly user: string;
        readonly coursesToUnlock: ReadonlyArray<{
            readonly courseId: string;
            readonly title: string;
        }>;
        readonly coursesUnlocked: ReadonlyArray<{
            readonly title: string;
        }>;
    }>;
    readonly licenses: ReadonlyArray<{
        readonly limitedToCoursesRef: ReadonlyArray<string> | null;
    }>;
    readonly courses: ReadonlyArray<{
        readonly id: string;
        readonly containerMeta: {
            readonly title: string;
        };
    }>;
    readonly usersInAccount: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
    }>;
    readonly " $refType": "AssignLicenseButton_LicensesInAccountContainerFragment";
};
export type AssignLicenseButton_LicensesInAccountContainerFragment$data = AssignLicenseButton_LicensesInAccountContainerFragment;
export type AssignLicenseButton_LicensesInAccountContainerFragment$key = {
    readonly " $data"?: AssignLicenseButton_LicensesInAccountContainerFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"AssignLicenseButton_LicensesInAccountContainerFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignLicenseButton_LicensesInAccountContainerFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CourseLocksByUserType",
      "kind": "LinkedField",
      "name": "courseLocksByUser",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "user",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CourseIdWithTitle",
          "kind": "LinkedField",
          "name": "coursesToUnlock",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "courseId",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CourseIdWithTitle",
          "kind": "LinkedField",
          "name": "coursesUnlocked",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "License",
      "kind": "LinkedField",
      "name": "licenses",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "limitedToCoursesRef",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "courses",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ContainerMeta",
          "kind": "LinkedField",
          "name": "containerMeta",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "usersInAccount",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LicenseInAccountContainer",
  "abstractKey": null
};
})();
(node as any).hash = 'a2418b62c020c25970b9e05ca20f66ee';
export default node;
