/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CustomerType = "business" | "private" | "%future added value";
export type BillingDetailsFormV3_OrderFragment = {
    readonly id: string;
    readonly billingDetails: {
        readonly customerType: CustomerType;
        readonly " $fragmentRefs": FragmentRefs<"PrivateForm_BillingDetailsFragment" | "BusinessForm_BillingDetailsFragment">;
    } | null;
    readonly " $refType": "BillingDetailsFormV3_OrderFragment";
};
export type BillingDetailsFormV3_OrderFragment$data = BillingDetailsFormV3_OrderFragment;
export type BillingDetailsFormV3_OrderFragment$key = {
    readonly " $data"?: BillingDetailsFormV3_OrderFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"BillingDetailsFormV3_OrderFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingDetailsFormV3_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BillingDetails",
      "kind": "LinkedField",
      "name": "billingDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerType",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PrivateForm_BillingDetailsFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BusinessForm_BillingDetailsFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
(node as any).hash = '8f944be34b60ac93ad69061f0a4c3fb2';
export default node;
