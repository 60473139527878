/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LearnSidebar_LessonFragment = {
    readonly containerMeta: {
        readonly title: string;
    };
    readonly course: {
        readonly containerMeta: {
            readonly title: string;
        };
        readonly id: string;
    };
    readonly id: string;
    readonly lessonPath: {
        readonly courseRef: string;
        readonly lessonIndex: number;
        readonly moduleIndex: number;
        readonly moduleRef: string;
    };
    readonly " $refType": "LearnSidebar_LessonFragment";
};
export type LearnSidebar_LessonFragment$data = LearnSidebar_LessonFragment;
export type LearnSidebar_LessonFragment$key = {
    readonly " $data"?: LearnSidebar_LessonFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LearnSidebar_LessonFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "ContainerMeta",
  "kind": "LinkedField",
  "name": "containerMeta",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LearnSidebar_LessonFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "course",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LessonPath",
      "kind": "LinkedField",
      "name": "lessonPath",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "courseRef",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lessonIndex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleIndex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleRef",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Lesson",
  "abstractKey": "__isLesson"
};
})();
(node as any).hash = '5f2b229afd43a5c63ee5d1a6bc345d5d';
export default node;
