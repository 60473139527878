/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type LicensesInAccount_DownloadLicenseLogMutationVariables = {};
export type LicensesInAccount_DownloadLicenseLogMutationResponse = {
    readonly License: {
        readonly downloadLicenseLog: {
            readonly licenseCsvString: string;
        } | null;
    };
};
export type LicensesInAccount_DownloadLicenseLogMutation = {
    readonly response: LicensesInAccount_DownloadLicenseLogMutationResponse;
    readonly variables: LicensesInAccount_DownloadLicenseLogMutationVariables;
};



/*
mutation LicensesInAccount_DownloadLicenseLogMutation {
  License {
    downloadLicenseLog(input: {}) {
      licenseCsvString
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "LicenseMutations",
    "kind": "LinkedField",
    "name": "License",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "input",
            "value": {}
          }
        ],
        "concreteType": "DownloadLicenseInputPayload",
        "kind": "LinkedField",
        "name": "downloadLicenseLog",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "licenseCsvString",
            "storageKey": null
          }
        ],
        "storageKey": "downloadLicenseLog(input:{})"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LicensesInAccount_DownloadLicenseLogMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LicensesInAccount_DownloadLicenseLogMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ec5c7631ffb720216b10439bdf944fab",
    "id": null,
    "metadata": {},
    "name": "LicensesInAccount_DownloadLicenseLogMutation",
    "operationKind": "mutation",
    "text": "mutation LicensesInAccount_DownloadLicenseLogMutation {\n  License {\n    downloadLicenseLog(input: {}) {\n      licenseCsvString\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '91691eaf82afb53286fcd680366e5d05';
export default node;
