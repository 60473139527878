/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NoteLocationHeading_CourseFragment = {
    readonly shortTitle: string;
    readonly " $refType": "NoteLocationHeading_CourseFragment";
};
export type NoteLocationHeading_CourseFragment$data = NoteLocationHeading_CourseFragment;
export type NoteLocationHeading_CourseFragment$key = {
    readonly " $data"?: NoteLocationHeading_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"NoteLocationHeading_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NoteLocationHeading_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortTitle",
      "storageKey": null
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = '1d685ea36d0c388ea3d1480042293aa6';
export default node;
