/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BillingDetailsFormV3_UserFragment = {
    readonly " $fragmentRefs": FragmentRefs<"BusinessForm_UserFragment" | "PrivateForm_UserFragment">;
    readonly " $refType": "BillingDetailsFormV3_UserFragment";
};
export type BillingDetailsFormV3_UserFragment$data = BillingDetailsFormV3_UserFragment;
export type BillingDetailsFormV3_UserFragment$key = {
    readonly " $data"?: BillingDetailsFormV3_UserFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"BillingDetailsFormV3_UserFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingDetailsFormV3_UserFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BusinessForm_UserFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PrivateForm_UserFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '216bf67ccdc11843489f7aad1d72397d';
export default node;
