import React from "react"
import LogoBlack from "./logo-black.png"
import Background from "./background.svg"
import styled from "styled-components";
import {tkTypography} from "../_modules/core/style/tkTypography";
import {tkColors} from "../_modules/core/style/tkColors";

export const SplashScreen = () => {
    return <SplashScreenContainer className="position-absolute top-0 left-0 w-100 h-100">
        <div className="d-flex justify-content-center align-items-center cover-fill h-100 w-100 flex-column p-4"
            style={{
                backgroundSize: 'cover',
                backgroundImage: `url('${Background}')`,
            }}>
            <div>
                <img className="w-100 h-auto mb-4" alt={"Lade..."} src={LogoBlack}/>
            </div>
            <Heading className="text-center"> Deine Lernerfahrung wird geladen... </Heading>
        </div>
    </SplashScreenContainer>
}

const SplashScreenContainer = styled.div`
  z-index: 100;
`

const Heading = styled.h1`
  ${tkTypography.largeBold};
  ${tkColors.darkBlue};
  max-width: 1024px;
`
