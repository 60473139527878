/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AnonymizeMyselfButton_AnonymizeMutationVariables = {};
export type AnonymizeMyselfButton_AnonymizeMutationResponse = {
    readonly Viewer: {
        readonly User: {
            readonly anonymizeMyself: {
                readonly clientMutationId: string | null;
            } | null;
        };
    };
};
export type AnonymizeMyselfButton_AnonymizeMutation = {
    readonly response: AnonymizeMyselfButton_AnonymizeMutationResponse;
    readonly variables: AnonymizeMyselfButton_AnonymizeMutationVariables;
};



/*
mutation AnonymizeMyselfButton_AnonymizeMutation {
  Viewer {
    User {
      anonymizeMyself(input: {}) {
        clientMutationId
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ViewerMutationType",
    "kind": "LinkedField",
    "name": "Viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserViewerMutationSchema",
        "kind": "LinkedField",
        "name": "User",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "input",
                "value": {}
              }
            ],
            "concreteType": "AnonymizeMyselfPayload",
            "kind": "LinkedField",
            "name": "anonymizeMyself",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": "anonymizeMyself(input:{})"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AnonymizeMyselfButton_AnonymizeMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AnonymizeMyselfButton_AnonymizeMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "3f7f13e19dfad34e081c0dde1b3e2851",
    "id": null,
    "metadata": {},
    "name": "AnonymizeMyselfButton_AnonymizeMutation",
    "operationKind": "mutation",
    "text": "mutation AnonymizeMyselfButton_AnonymizeMutation {\n  Viewer {\n    User {\n      anonymizeMyself(input: {}) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '3643671bc873c94f3a64e29401eab8b5';
export default node;
