import React, {useEffect} from "react";
import {useRouteMatch} from "react-router";

import "./checkout.scss"
import {useDispatch, useSelector} from "react-redux";
import {CheckoutFormV2} from "./CheckoutFormV2";
import {PaymentMethods} from "../methods/PaymentMethods";
import {OfferCart} from "./cart/OfferCart";
import {reloadPersonalizedOfferAction, selectOfferV2} from "@thekeytechnology/thekey-academy-frontend-library";
import {BillingBaseV2} from "../common/BillingBaseV2";

export const CheckoutV2 = () => {
    const dispatch = useDispatch();

    const route = useRouteMatch<{ offerId: string }>();

    useEffect(() => {
        dispatch(reloadPersonalizedOfferAction(route.params.offerId))
    }, [route.params.offerId, dispatch])

    const personalizedOffer = useSelector(selectOfferV2);

    return <BillingBaseV2
        mainSlot={personalizedOffer ? <>
            <OfferCart offer={personalizedOffer}/>
            <CheckoutFormV2 offer={personalizedOffer}/></> : null}
        underHeadingSlot={null}
        sidebarSlot={<>
            <div className="checkout-card p-3 hide-mobile">
                <PaymentMethods exclude={personalizedOffer?.baseOffer.entity.disabledPaymentMethods}/>
            </div>
        </>}/>;
}
