import React, {Suspense} from "react";
import {
    AllCustomerMessagesScreen
} from "./components/all-messages/AllCustomerMessagesScreen";
import {LoadingFakeDashboardScreen} from "../core/loading/dashboard/LoadingFakeDashboardScreen";
import {RouteAuthState, RouteDefinition} from "../core/components/routing/RouteDefinition";

export const CustomerMessageRouteDefinitions: RouteDefinition[] = [
    {
        path: "/customer-messages",
        component: () => <Suspense fallback={<LoadingFakeDashboardScreen/>}>
            <AllCustomerMessagesScreen/>
        </Suspense>,
        authState: RouteAuthState.OnlyLoggedIn
    }
]