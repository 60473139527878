import React from "react";
import {CourseInformation, PricedUpsellOption} from "@thekeytechnology/thekey-academy-frontend-library";
import {CourseOfferRow} from "./CourseOfferRow";
import {PriceRenderer} from "./PriceRenderer";

interface OwnProps {
    upsellOption: PricedUpsellOption
    course: CourseInformation
    onSelectUpsell?: (courseRef: string) => void;
    priceRenderer: PriceRenderer

}

export const UpsellOptionsRow = ({
                                     upsellOption,
                                     course,
                                     onSelectUpsell,
                                     priceRenderer
                                 }: OwnProps) => {
    return <div className="cart-table-row upsell-option-row">
        <div className="tk-academy-large-bold tk-color-waterblue-40 mb-2">
            {upsellOption.description ? upsellOption.description : "Angebot: Jetzt direkt dazu buchen"}
        </div>
        <div className="w-100 d-flex flex-column">
            <CourseOfferRow
                priceRenderer={priceRenderer}
                offerItem={upsellOption}
                course={course}
            />
            <button className="btn btn-link text-right tk-academy-large-bold tk-color-keyblue p-0"
                    onClick={() => onSelectUpsell ? onSelectUpsell(upsellOption.courseRef) : null}>Zum Warenkorb
                hinzufügen
            </button>
        </div>
    </div>
}
