/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SubmissionType = "certificationTest" | "learn" | "review" | "test" | "%future added value";
export type SubmissionFinishedSidebar_SubmissionFragment = {
    readonly courseRef: string;
    readonly submissionType: SubmissionType;
    readonly lesson?: {
        readonly containerMeta: {
            readonly title: string;
        };
        readonly course: {
            readonly containerMeta: {
                readonly title: string;
            };
        };
        readonly id: string;
        readonly lessonPath: {
            readonly lessonIndex: number;
            readonly moduleIndex: number;
        };
    };
    readonly moduleRef?: string;
    readonly " $refType": "SubmissionFinishedSidebar_SubmissionFragment";
};
export type SubmissionFinishedSidebar_SubmissionFragment$data = SubmissionFinishedSidebar_SubmissionFragment;
export type SubmissionFinishedSidebar_SubmissionFragment$key = {
    readonly " $data"?: SubmissionFinishedSidebar_SubmissionFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"SubmissionFinishedSidebar_SubmissionFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "ContainerMeta",
  "kind": "LinkedField",
  "name": "containerMeta",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubmissionFinishedSidebar_SubmissionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "courseRef",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submissionType",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "lesson",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Course",
              "kind": "LinkedField",
              "name": "course",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LessonPath",
              "kind": "LinkedField",
              "name": "lessonPath",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lessonIndex",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "moduleIndex",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleRef",
          "storageKey": null
        }
      ],
      "type": "LessonSubmission",
      "abstractKey": null
    }
  ],
  "type": "Submission",
  "abstractKey": "__isSubmission"
};
})();
(node as any).hash = '449d52b36ef0a1983b1b49c69d213d4d';
export default node;
