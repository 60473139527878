/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ElementType = "clozeText" | "image" | "multipleChoice" | "order" | "persolog" | "questionnaire" | "resolution" | "text" | "video" | "%future added value";
export type LearnContentArea_ElementFragment = {
    readonly elementPath: {
        readonly courseRef: string;
        readonly lessonRef: string;
    };
    readonly elementType: ElementType;
    readonly id: string;
    readonly subtitles?: {
        readonly subtitles: ReadonlyArray<{
            readonly text: string;
        }>;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"NotesScreenContent_ElementFragment" | "Subtitles_VideoElementFragment">;
    readonly " $refType": "LearnContentArea_ElementFragment";
};
export type LearnContentArea_ElementFragment$data = LearnContentArea_ElementFragment;
export type LearnContentArea_ElementFragment$key = {
    readonly " $data"?: LearnContentArea_ElementFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LearnContentArea_ElementFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LearnContentArea_ElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ElementPath",
      "kind": "LinkedField",
      "name": "elementPath",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "courseRef",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lessonRef",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Subtitles",
          "kind": "LinkedField",
          "name": "subtitles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Subtitle",
              "kind": "LinkedField",
              "name": "subtitles",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "text",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Subtitles_VideoElementFragment"
        }
      ],
      "type": "VideoElement",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotesScreenContent_ElementFragment"
    }
  ],
  "type": "Element",
  "abstractKey": "__isElement"
};
(node as any).hash = 'f4ce00d3cf2d7975bf42df53a1e852b9';
export default node;
