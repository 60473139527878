/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LessonDetailScreen_CourseStateFragment = {
    readonly id: string;
    readonly lessonStates: ReadonlyArray<{
        readonly lessonRef: string;
        readonly " $fragmentRefs": FragmentRefs<"LessonDetailScreen_LessonStateFragment">;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"CelebrationPopup_CourseStateFragment" | "LessonDetailFooter_CourseStateFragment" | "LessonInfo_CourseStateFragment" | "LessonDetailSidebar_CourseStateFragment">;
    readonly " $refType": "LessonDetailScreen_CourseStateFragment";
};
export type LessonDetailScreen_CourseStateFragment$data = LessonDetailScreen_CourseStateFragment;
export type LessonDetailScreen_CourseStateFragment$key = {
    readonly " $data"?: LessonDetailScreen_CourseStateFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LessonDetailScreen_CourseStateFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonDetailScreen_CourseStateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LessonState",
      "kind": "LinkedField",
      "name": "lessonStates",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lessonRef",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LessonDetailScreen_LessonStateFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CelebrationPopup_CourseStateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LessonDetailFooter_CourseStateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LessonInfo_CourseStateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LessonDetailSidebar_CourseStateFragment"
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};
(node as any).hash = '736598183b7444bc12999aad81df3747';
export default node;
