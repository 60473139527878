import React, {Suspense} from "react";
import {AllNotesScreen} from "./components/AllNotesScreen";
import {LoadingFakeDashboardScreen} from "../core/loading/dashboard/LoadingFakeDashboardScreen";
import {RouteAuthState, RouteDefinition} from "../core/components/routing/RouteDefinition";

export const NotesRouteDefinitions: RouteDefinition[] = [
    {
        path: "/notes",
        component: () => <Suspense fallback={<LoadingFakeDashboardScreen/>}>
            <AllNotesScreen/>
        </Suspense>,
        authState: RouteAuthState.OnlyLoggedIn
    }
]