import React, {useState} from "react";
import {
    ElementFlowContextProvider,
    elementGraphQLToElement,
    NoteContextProvider
} from "@thekeytechnology/thekey-academy-frontend-library"
import {LearnCurrentElementScreen} from "./LearnCurrentElementScreen";
import {useFragment, useLazyLoadQuery, useMutation, useSubscribeToInvalidationState} from "react-relay";
import {LearnScreen_LessonFragment$key} from "../../../../__generated__/LearnScreen_LessonFragment.graphql";
import {LearnScreen_SubmitMutation} from "../../../../__generated__/LearnScreen_SubmitMutation.graphql";
import {LearnScreen_Query} from "../../../../__generated__/LearnScreen_Query.graphql";
import {useHistory, useRouteMatch} from "react-router";
import {graphql} from "babel-plugin-relay/macro";
import {CelebrationPopup} from "../../../celebration/components/CelebrationPopup";
import {LearnScreen_CourseStateFragment$key} from "../../../../__generated__/LearnScreen_CourseStateFragment.graphql";
import {trackFirstSubmission} from "../../../analytics/analytics";

const QUERY = graphql`
    query LearnScreen_Query($courseId: ID!, $lessonId: ID!) {
        node(id: $lessonId) {
            ... on Lesson {
                ...LearnScreen_LessonFragment
            }
        }
        Viewer {
            CourseState {
                CourseState(Input: {courseId: $courseId}) {
                    ...LearnScreen_CourseStateFragment
                }
            }
            License {
                LicensePools {
                    ...CelebrationPopup_LicensePools
                }
            }
        }
    }`

const SUBMIT_MUTATION = graphql`
    mutation LearnScreen_SubmitMutation($courseId: ID!, $moduleId: ID!, $lessonId: ID!, $elementSubmissionsJson: String!) {
        Submission {
            submitLesson(input: {courseId: $courseId, moduleId: $moduleId,lessonId: $lessonId, elementSubmissions: $elementSubmissionsJson }) {
                isFirstSubmission
                submission {
                    id
                    ... on LessonSubmission {
                        userRef
                    }
                }
            }
        }
    }`

const COURSE_STATE_FRAGMENT = graphql`
    fragment LearnScreen_CourseStateFragment on CourseState {
        id
        ...CelebrationPopup_CourseStateFragment
    }`

const LESSON_FRAGMENT = graphql`
    fragment LearnScreen_LessonFragment on Lesson {
        course {
            ...CelebrationPopup_CourseFragment
        }
        elements {
            edges {
                node {
                    elementType
                    generalSettings {
                        points
                    }
                    id
                    ... on ClozeTextElement {
                        additionalWords
                        parts {
                            partType
                            ... on ClozePart {
                                cloze
                            }
                            ... on TextPart {
                                text
                            }
                        }
                    }
                    ... on MultipleChoiceElement {
                        allCorrectAnswersNeedToBeGiven
                        allowMultipleAnswers
                        answerOptions {
                            isCorrect
                        }
                    }
                    ... on OrderElement {
                        orderables {
                            text
                        }
                    }
                    ... on PersologElement {
                        questions {
                            answers {
                                classCode
                            }
                        }
                    }
                    ... on QuestionnaireElement {
                        classes {
                            description
                            image {
                                url
                            }
                            pointsThreshold
                            title
                        }
                        questions {
                            reverseScale
                            title
                        }
                    }
                }
            }
        }
        id
        lessonType
        lessonPath {
            courseRef
            moduleRef
        }
        ... on TestLesson {
            isCertificationTest
        }
        ...LearnCurrentElementScreen_LessonFragment
    }`

interface State {
    fetchKey: number
}

interface OwnProps {
    courseId: string
    lessonId: string
}

const LearnScreenComponent = ({courseId, lessonId}: OwnProps) => {
    const history = useHistory();

    const [state, setState] = useState<State>({fetchKey: 0})

    const [submit] = useMutation<LearnScreen_SubmitMutation>(SUBMIT_MUTATION)

    const learnScreenQuery = useLazyLoadQuery<LearnScreen_Query>(QUERY, {
        courseId,
        lessonId
    }, {fetchPolicy: "network-only", fetchKey: state.fetchKey});

    const courseState = useFragment<LearnScreen_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, learnScreenQuery.Viewer.CourseState.CourseState);

    const lesson = useFragment<LearnScreen_LessonFragment$key>(LESSON_FRAGMENT, learnScreenQuery.node);

    useSubscribeToInvalidationState([courseState?.id].filter(id => !!id).map(id => id!), () => {
        setState(state => ({...state, fetchKey: state.fetchKey + 1}))
    })

    if (!lesson) return null;

    const elements = lesson.elements?.edges?.filter(e => !!e).map(e => elementGraphQLToElement(e!.node)).filter(el => !!el).map(el => el!);

    return <>
        {elements?.length ? <NoteContextProvider>
            <ElementFlowContextProvider
                elements={elements}
                isTestLesson={lesson.lessonType === 'test'}
                isCertificationTest={lesson.isCertificationTest || false}
                onFinished={finalState => {
                    submit({
                        variables: {
                            courseId: lesson.lessonPath.courseRef,
                            moduleId: lesson.lessonPath.moduleRef,
                            lessonId: lesson.id,
                            elementSubmissionsJson: JSON.stringify(finalState.elementSubmissions)
                        },
                        onCompleted: (response) => {
                            const base64EncodedUserId = response.Submission.submitLesson?.submission.userRef

                            if (response.Submission.submitLesson?.isFirstSubmission && base64EncodedUserId) trackFirstSubmission(base64EncodedUserId)

                            history.push(`/courses/${lesson.lessonPath.courseRef}/submissions/${response.Submission.submitLesson?.submission.id}`)
                        }
                    })
                }
                }
            >
                <LearnCurrentElementScreen lessonFragmentRef={lesson}/>
            </ElementFlowContextProvider>
        </NoteContextProvider> : null}
        <CelebrationPopup courseFragmentRef={lesson.course} courseStateFragmentRef={courseState}
                          licensePoolsFragmentRef={learnScreenQuery.Viewer.License.LicensePools}/>
    </>;
}

export const LearnScreen = () => {
    const router = useRouteMatch<{ courseId?: string, lessonId?: string }>();

    if (!router.params.courseId || !router.params.lessonId) return null;

    return <LearnScreenComponent courseId={router.params.courseId} lessonId={router.params.lessonId}/>;
}
