import ReactPlayer from "react-player";
import PlayButton from "../../../elementFlow/components/video/play-button.png";
import {formatDuration} from "@thekeytechnology/thekey-academy-frontend-library";
import FullScreenButton from "../../../elementFlow/components/video/fullscreen.png";
import React, {useEffect, useRef, useState} from "react";
import screenfull from "screenfull";
import {findDOMNode} from "react-dom"
import "./video-player.scss";

interface OwnProps {
    timestamp?: number;
    videoFile: string;
    onCurrentTimeChange?: (seconds: number) => void;

}

const screenFullInstance = screenfull as screenfull.Screenfull;

export const VideoPlayer = ({
                                timestamp,
                                videoFile,
                                onCurrentTimeChange
                            }: OwnProps) => {
    const [state, setState] = useState({
        playing: false,
        rate: 1,
        timestamp: timestamp ? timestamp : 0,
        duration: 0,
        isFullscreen: false
    })

    const seekWrapper = useRef<HTMLDivElement | null>(null)
    const playerWrapper = useRef<HTMLDivElement | null>(null)
    const videoPlayer = useRef<ReactPlayer | null>(null)

    useEffect(() => {
        if (onCurrentTimeChange && timestamp) {
            onCurrentTimeChange(timestamp);
        }

        screenFullInstance.onchange(() => {
            setState({...state, isFullscreen: screenFullInstance.isFullscreen})
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timestamp, state])

    return <div className={`video-player flex-grow-1 ${state.isFullscreen ? "fullscreen" : ""}`}
                ref={playerWrapper}>
        <div className="player-wrapper w-100 h-100">
            <ReactPlayer
                playbackRate={state.rate}
                playing={state.playing}
                onDuration={duration => {
                    setState({...state, duration})
                    if (timestamp) {
                        videoPlayer.current?.seekTo(timestamp);
                    }

                }}
                onPause={() => {
                    setState({...state, playing: false})
                }}
                onEnded={() => {
                    setState({...state, playing: false})
                }}
                ref={videoPlayer}
                onProgress={progress => {
                    setState({...state, timestamp: progress.playedSeconds})
                    if (onCurrentTimeChange) {
                        onCurrentTimeChange(progress.playedSeconds)
                    }
                }}
                onSeek={seconds => {
                    setState({...state, timestamp: seconds})
                    if (onCurrentTimeChange) {
                        onCurrentTimeChange(seconds)
                    }
                }}
                width={"100%"}
                height={"100%"}
                url={videoFile}
                config={{
                    file: {
                        hlsOptions: {
                            startLevel: 3
                        }
                    }
                }}
            />
            <div className={`video-overlay ${state.playing ? "playing" : "stopped"}`}>
                <div className="upper-controls">
                    <select
                        className="rate"
                        value={state.rate}
                        onChange={event => {
                            setState({...state, rate: parseFloat(event.target.value)})
                        }}
                    >
                        <option value={0.5}>0.5x</option>
                        <option value={1}>1x</option>
                        <option value={1.5}>1.5x</option>
                        <option value={2}>2x</option>
                    </select>
                </div>
                <div className="play-button" onClick={() => setState({...state, playing: !state.playing})}>
                    {!state.playing ? <img alt="Video starten" src={PlayButton}/> : null}
                </div>
                <div className="lower-controls">
                    {
                        !state.playing ?
                            <div className="timestamp">
                                {formatDuration(videoPlayer.current?.getCurrentTime() || 0)} / {formatDuration(state.duration)}
                            </div> : null
                    }
                    <div className="fullscreen" onClick={() => {
                        if (screenFullInstance.isFullscreen) {
                            screenFullInstance.exit()
                            setState({...state, isFullscreen: false})
                        } else {
                            screenFullInstance.request(findDOMNode(playerWrapper.current as any) as any)
                            setState({...state, isFullscreen: true})
                        }
                    }}>
                        <img alt={"Vollbild"} src={FullScreenButton}/>
                    </div>
                </div>

                <div ref={seekWrapper} className="seek" onClick={event => {
                    const wrapperElement = seekWrapper.current as HTMLDivElement;
                    const relativeClickX = event.clientX - wrapperElement.getBoundingClientRect().x
                    const clickPercentage = ((relativeClickX * 100) / wrapperElement.getBoundingClientRect().width) / 100;
                    const durationToGoTo = clickPercentage * state.duration;
                    videoPlayer.current!.seekTo(durationToGoTo, "seconds")
                }}>
                    <div className="bar">
                        <div className="bar-fill"
                             style={{
                                 width: `${(100 * state.timestamp) / (state.duration + 1)}%`
                             }}/>
                        <div className="bubble"/>
                    </div>
                </div>
            </div>

        </div>
    </div>
}
