import React, { useCallback } from "react";
import { FileResolutionStrategy } from "@thekeytechnology/thekey-academy-frontend-library";

const regex = new RegExp("src=\"tkfile-([a-zA-Z0-9-]*)", "g")

interface OwnProps {
    fileResolutionStrategy: FileResolutionStrategy
    text: string;
}

export const HtmlTextWithResolvedImages = ({
                                               fileResolutionStrategy,
                                               text
                                           }: OwnProps) => {

    const parseText = useCallback(() => {
        let textToUse = text;
        let match = regex.exec(text);
        while (match != null) {
            const fileId = match[1];
            const fileUrl = fileResolutionStrategy.resolveFile(fileId);
            if (fileUrl) {
                textToUse = textToUse.replace(`tkfile-${fileId}`, fileUrl)
            }
            match = regex.exec(text);
        }
        return textToUse;
    }, [text, fileResolutionStrategy])

    return <div dangerouslySetInnerHTML={{__html: parseText()}}/>
}
