import React, {ReactNode, Suspense} from "react";
import {Subtitles} from "./Subtitles";
import {QnasScreen} from "../../../qnas/components/QnasScreen";
import {NotesScreenContent} from "../../../notes/components/NotesScreenContent";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {LearnContentArea_ElementFragment$key} from "../../../../__generated__/LearnContentArea_ElementFragment.graphql";
import {LearnContentArea_CourseFragment$key} from "../../../../__generated__/LearnContentArea_CourseFragment.graphql";
import {LearnContentArea_LessonFragment$key} from "../../../../__generated__/LearnContentArea_LessonFragment.graphql";
import {LearnReviewSecondarySidebar} from "../../LearnReviewSecondarySidebar";
import {LearnReviewSecondaryContentArea} from "../../LearnReviewSecondaryContentArea";
import {LearnReviewPrimaryContentArea} from "../../LearnReviewPrimaryContentArea";
import {Route, Switch} from "react-router-dom";

const COURSE_FRAGMENT = graphql`
    fragment LearnContentArea_CourseFragment on Course {
        id
        ...NotesScreenContent_CourseFragment
    }`

const LESSON_FRAGMENT = graphql`
    fragment LearnContentArea_LessonFragment on Lesson {
        id
        ...NotesScreenContent_LessonFragment
    }`

const ELEMENT_FRAGMENT = graphql`
    fragment LearnContentArea_ElementFragment on Element {
        elementPath {
            courseRef
            lessonRef
        }
        elementType
        id
        ... on VideoElement {
            subtitles {
                subtitles {
                    text
                }
            }
            ...Subtitles_VideoElementFragment
        }
        ...NotesScreenContent_ElementFragment
    }`

interface OwnProps {
    courseFragmentRef: LearnContentArea_CourseFragment$key;
    lessonFragmentRef: LearnContentArea_LessonFragment$key;
    elementFragmentRef: LearnContentArea_ElementFragment$key;
    children: ReactNode;
    timestampForSubtitles: number;
}

export const LearnContentArea = ({
                                     courseFragmentRef,
                                     lessonFragmentRef,
                                     elementFragmentRef,
                                     children,
                                     timestampForSubtitles
                                 }: OwnProps) => {
    const course = useFragment<LearnContentArea_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef)
    const lesson = useFragment<LearnContentArea_LessonFragment$key>(LESSON_FRAGMENT, lessonFragmentRef)
    const element = useFragment<LearnContentArea_ElementFragment$key>(ELEMENT_FRAGMENT, elementFragmentRef)

    return <LearnReviewPrimaryContentArea>
        <Suspense fallback={<LearnReviewSecondarySidebar/>}>
            <Switch>
                {element.elementType === 'video' && element.subtitles?.subtitles ?
                    <Route path="/courses/:courseId/modules/:moduleId/lessons/:lessonId/learn"
                                  exact
                                  component={() => <LearnReviewSecondarySidebar>
                                      <Subtitles
                                          videoElementFragmentRef={element}
                                          timestamp={timestampForSubtitles}
                                      />
                                  </LearnReviewSecondarySidebar>}/> : null}
                <Route path="/courses/:courseId/modules/:moduleId/lessons/:lessonId/learn/notes"
                              exact
                              component={() => <LearnReviewSecondarySidebar>
                                  <NotesScreenContent
                                      courseFragmentRef={course}
                                      lessonFragmentRef={lesson}
                                      elementFragmentRef={element}
                                      headingSlot={<div className="tk-academy-large-bold mb-3 ">Notizen für
                                          Lektion</div>}
                                  />
                              </LearnReviewSecondarySidebar>}/>
                <Route path="/courses/:courseId/modules/:moduleId/lessons/:lessonId/learn/qnas"
                              exact
                              component={() => <LearnReviewSecondarySidebar>
                                  <QnasScreen
                                      courseId={course.id}
                                      lessonId={lesson.id}
                                      elementId={element.id}
                                      headingSlot={<div className="tk-academy-large-bold mb-3 ">Fragen & Antworten in
                                          der
                                          Lektion</div>}
                                  />
                              </LearnReviewSecondarySidebar>}/>
            </Switch>
        </Suspense>
        <LearnReviewSecondaryContentArea>
            {children}
        </LearnReviewSecondaryContentArea>
    </LearnReviewPrimaryContentArea>
}