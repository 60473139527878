import {useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import React, {useEffect} from "react";
import {
    CartSelectionInput,
    OrderFlowFromCartBase64Screen_CreateOrderMutation
} from "../../../../__generated__/OrderFlowFromCartBase64Screen_CreateOrderMutation.graphql";
import {useHistory} from "react-router";
import {trackInitiateCheckout} from "../../../analytics/analytics";
import * as Sentry from "@sentry/react";

const CREATE_ORDER_MUTATION = graphql`
    mutation OrderFlowFromCartBase64Screen_CreateOrderMutation($createOrderInput: CreateOrderInput!) {
        Billing {
            createOrder(input: $createOrderInput) {
                order {
                    id
                    cart {
                        cartItems {
                            ... on ProductCartItem {
                                product {
                                    id
                                    title
                                }
                            }
                        }
                        cartTotals {
                            totalsIncludingAllDiscounts {
                                netPrice
                            }
                        }
                    }
                }
            }
        }
    }`

interface OwnProps {
    cartBase64: string;
}

export const OrderFlowFromCartBase64Screen = ({cartBase64}: OwnProps) => {
    const history = useHistory();

    const [createOrderMutation] = useMutation<OrderFlowFromCartBase64Screen_CreateOrderMutation>(CREATE_ORDER_MUTATION)

    useEffect(() => {
        let cartSelection: CartSelectionInput | undefined = undefined
        if (cartBase64) {
            try {
                const newState = JSON.parse(atob(cartBase64))
                if (newState.selectedProducts) {
                    cartSelection = newState
                }
            } catch {
            }
        }

        createOrderMutation({
            variables: {
                createOrderInput: {
                    cartSelection: cartSelection ? cartSelection : undefined
                }
            },
            onCompleted: response => {
                const order = response.Billing.createOrder?.order
                try {
                    if (order?.id) {
                        const selectedProducts = cartSelection?.selectedProducts || []
                        const selectedProductTitles = selectedProducts.map(sp => order.cart.cartItems.find(ci => ci.product?.id === sp.productId)?.product?.title).filter(spt => !!spt).map(spt => spt!)
                        const products = selectedProductTitles.length === selectedProducts.length ? selectedProducts.map((sp, i) => ({
                            base64EncodedProductId: sp.productId,
                            quantity: sp.amount,
                            productName: selectedProductTitles[i]
                        })) : undefined
                        if (products) trackInitiateCheckout(
                            products,
                            order.id,
                            order.cart.cartTotals.totalsIncludingAllDiscounts?.netPrice || 0
                        )
                    }
                } catch (e) {
                    Sentry.captureException(e)
                }

                history.replace({pathname: `/checkout/orders-v3/${order?.id}`})
            }
        })
        // eslint-disable-next-line
    }, [])

    return <div>
        Lade...
    </div>
}
