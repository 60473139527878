import React from 'react';
import {Screen} from "../../components/screen/Screen";
import {BlueBackground} from "../../components/screen/BlueBackground";
import {LoadingFakeMainSidebar} from "../LoadingFakeMainSidebar";

export const LoadingFakeDashboardScreen = () => {
    return <Screen
        headerBarBackgroundSlot={<BlueBackground height={60}/>}
        sidebarSlot={<LoadingFakeMainSidebar/>}
        headerMargin={30}>
        <></>
    </Screen>
};
