import {ValidatedField} from "../../../../../core/components/form/ValidatedField";
import {SwitchField} from "../../../../../core/components/form/SwitchField";
import React from "react";
import {useTranslation} from "react-i18next";
import {EntityWrapper, UserWithRoles} from "@thekeytechnology/framework-react";

interface OwnProps {
    formikState: any
    user: EntityWrapper<UserWithRoles> | undefined
}

export const SignupFormPart = ({formikState, user}: OwnProps) => {
    const {t} = useTranslation("billing")

    return user ? null : <>
        <h3 className="tk-academy-large tk-color-waterblue-80 tk-academy-bold mb-2">{t("checkout-form.create-account-heading")}</h3>
        <div className="tk-form-row">
            <div className="form-group">
                <ValidatedField formikState={formikState}
                                placeholder={t("checkout-form.email")}
                                name="email"
                                type="email"
                                className="form-control default-input"
                                required
                />
            </div>
            <div className="form-group">
                <ValidatedField formikState={formikState}
                                placeholder={t("checkout-form.email2")}
                                name="email2"
                                type="email"
                                className="form-control default-input"
                                required/>
            </div>
        </div>
        <div className="tk-form-row">
            <div className="form-group">
                <ValidatedField formikState={formikState}
                                type="password"
                                placeholder={t("checkout-form.password")}
                                name="password"
                                className="form-control default-input"
                                required/>
            </div>
            <div className="form-group">
                <ValidatedField formikState={formikState}
                                type="password"
                                placeholder={t("checkout-form.password2")}
                                name="password2"
                                className="form-control default-input"
                                required/>
            </div>
        </div>
        <div className="mb-3">
            <ValidatedField
                name="legal"
                component={SwitchField}
                titleRight={true}
                titleClassName="tk-academy-large tk-color-waterblue ml-3"
                title={<>Mit der Anmeldung stimmst du der <a
                    rel="noopener noreferrer"
                    href='https://www.thekey.academy/datenschutzerklaerung/'
                    target='_blank'>Datenschutzerklärung</a> und den <a
                    rel="noopener noreferrer"
                    href='https://www.thekey.academy/agbs/'
                    target='_blank'>Nutzungsbedingungen</a> zu.</>}
                formikState={formikState}/>
        </div>
    </>
}
