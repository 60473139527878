import React, { useState } from "react";
import { FeedbackModal } from "./FeedbackModal";

interface OwnProps {
    className?: string;
    courseId?: string;
    lessonId?: string;
    elementId?: string;
    screen?: string
}

export const FeedbackButton = ({
                                   className,
                                   courseId,
                                   lessonId,
                                   elementId,
                                   screen
                               }: OwnProps) => {
    const [state, setState] = useState<{ modalVisible: boolean }>({
        modalVisible: false
    });

    return <>
        <button
            onClick={() => {
                setState({modalVisible: true})
            }}
            className={`review-link d-flex align-items-center tk-academy-small-text tk-color-keyblue ${className ? className : ""}`}>
            <span className="icon-g_feedback mr-2"/>
            Feedback
        </button>
        {state.modalVisible ? <FeedbackModal
            courseId={courseId}
            lessonId={lessonId}
            elementId={elementId}
            screen={screen}

            onClose={() => setState({
                ...state,
                modalVisible: false
            })}/> : null}
    </>;
}
