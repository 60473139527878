import {ImageWithFallback} from "../../../../core/components/image/ImageWithFallback";
import React from "react";
import "./course-icon.scss"

interface OwnProps {
    className?: string
    courseTitle: string;
    iconUrl?: string;
    isTest?: boolean
}

export const CourseIcon = ({
                               className,
                               courseTitle,
                               iconUrl,
                               isTest
                           }: OwnProps) => {


    return <div className={`course-icon-wrapper ${className || ""}`}>
        <ImageWithFallback className="course-icon" alt={courseTitle}
                           src={iconUrl}/>
        {isTest ? <div className="test-banner">Testzugang</div> : null}
    </div>
}
