import Plus from "../plus.png";
import React, {useState} from "react";
import {InviteUsersModal} from "./InviteUsersModal";

interface OwnProps {
    className?: string
}

export const AddUserButton = ({className}: OwnProps) => {
    const [modalOpen, setModalOpen] = useState<boolean>();

    return <>
        <button onClick={() => setModalOpen(true)} className={`${className} add-user-button`}>
            <img alt="plus" src={Plus}/>
        </button>
        {modalOpen ? <InviteUsersModal onClose={() => setModalOpen(false)}/> : null}
    </>
}
