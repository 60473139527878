/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LessonType = "normal" | "test" | "%future added value";
export type LessonDetailScreen_LessonFragment = {
    readonly containerMeta: {
        readonly points: number;
        readonly title: string;
    };
    readonly course: {
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"CelebrationPopup_CourseFragment" | "NotesScreenContent_CourseFragment">;
    };
    readonly id: string;
    readonly lessonPath: {
        readonly moduleRef: string;
    };
    readonly lessonType: LessonType;
    readonly glossary?: ReadonlyArray<{
        readonly id: string;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"LessonDetailFooter_LessonFragment" | "LessonDetailSidebar_LessonFragment" | "LessonInfo_LessonFragment" | "NotesScreenContent_LessonFragment" | "GlossaryEntriesOfNormalLessonDisplay_NormalLessonFragment">;
    readonly " $refType": "LessonDetailScreen_LessonFragment";
};
export type LessonDetailScreen_LessonFragment$data = LessonDetailScreen_LessonFragment;
export type LessonDetailScreen_LessonFragment$key = {
    readonly " $data"?: LessonDetailScreen_LessonFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LessonDetailScreen_LessonFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonDetailScreen_LessonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContainerMeta",
      "kind": "LinkedField",
      "name": "containerMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "points",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "course",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CelebrationPopup_CourseFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NotesScreenContent_CourseFragment"
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LessonPath",
      "kind": "LinkedField",
      "name": "lessonPath",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleRef",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lessonType",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GlossaryEntry",
          "kind": "LinkedField",
          "name": "glossary",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GlossaryEntriesOfNormalLessonDisplay_NormalLessonFragment"
        }
      ],
      "type": "NormalLesson",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LessonDetailFooter_LessonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LessonDetailSidebar_LessonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LessonInfo_LessonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotesScreenContent_LessonFragment"
    }
  ],
  "type": "Lesson",
  "abstractKey": "__isLesson"
};
})();
(node as any).hash = '1cd23c050bf088a846b6a80c5dfd9f09';
export default node;
