/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CartItemType = "bulkDiscountCartItem" | "discountCodeCartItem" | "productCartItem" | "%future added value";
export type BulkDiscount_Cart = {
    readonly cartItems: ReadonlyArray<{
        readonly itemType: CartItemType;
        readonly amount?: number;
    }>;
    readonly " $refType": "BulkDiscount_Cart";
};
export type BulkDiscount_Cart$data = BulkDiscount_Cart;
export type BulkDiscount_Cart$key = {
    readonly " $data"?: BulkDiscount_Cart$data;
    readonly " $fragmentRefs": FragmentRefs<"BulkDiscount_Cart">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BulkDiscount_Cart",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "cartItems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "itemType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            }
          ],
          "type": "ProductCartItem",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Cart",
  "abstractKey": null
};
(node as any).hash = '4cbb0c8748b1aea872f56f8e52dc4848';
export default node;
