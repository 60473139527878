/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DiscountRefusalReason = "duplicateSystemCode" | "duplicateUserCode" | "expired" | "overLimit" | "overUserLimit" | "wrongAccount" | "wrongProducts" | "%future added value";
export type CodeRefusalExplanation_RefusalsFragment = {
    readonly discountCodeRefusals: ReadonlyArray<{
        readonly code: string;
        readonly reason: DiscountRefusalReason;
        readonly title: string;
    }>;
    readonly " $refType": "CodeRefusalExplanation_RefusalsFragment";
};
export type CodeRefusalExplanation_RefusalsFragment$data = CodeRefusalExplanation_RefusalsFragment;
export type CodeRefusalExplanation_RefusalsFragment$key = {
    readonly " $data"?: CodeRefusalExplanation_RefusalsFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CodeRefusalExplanation_RefusalsFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CodeRefusalExplanation_RefusalsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DiscountCodeRefusal",
      "kind": "LinkedField",
      "name": "discountCodeRefusals",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reason",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Cart",
  "abstractKey": null
};
(node as any).hash = 'e84bc88c568af37b9cdbd79a5685e6c6';
export default node;
