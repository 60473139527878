/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ManagerMainPage_LicenseInAccountContainerFragment = {
    readonly " $fragmentRefs": FragmentRefs<"LicensesInAccount_LicenseInAccountContainerFragment">;
    readonly " $refType": "ManagerMainPage_LicenseInAccountContainerFragment";
};
export type ManagerMainPage_LicenseInAccountContainerFragment$data = ManagerMainPage_LicenseInAccountContainerFragment;
export type ManagerMainPage_LicenseInAccountContainerFragment$key = {
    readonly " $data"?: ManagerMainPage_LicenseInAccountContainerFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ManagerMainPage_LicenseInAccountContainerFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ManagerMainPage_LicenseInAccountContainerFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LicensesInAccount_LicenseInAccountContainerFragment"
    }
  ],
  "type": "LicenseInAccountContainer",
  "abstractKey": null
};
(node as any).hash = '9c933c9ed7638ccaf2832aa96a073265';
export default node;
