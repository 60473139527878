import React from "react";
import { Menu } from "../../../menu/components/Menu";
import { determineElementIcon } from "../../../elementFlow/components/ElementDisplay";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {ReviewSidebar_ElementFragment$key} from "../../../../__generated__/ReviewSidebar_ElementFragment.graphql";
import {ReviewSidebar_CourseFragment$key} from "../../../../__generated__/ReviewSidebar_CourseFragment.graphql";

const REVIEW_SIDEBAR_COURSE_FRAGMENT = graphql`
    fragment ReviewSidebar_CourseFragment on Course {
        id
    }`

const REVIEW_SIDEBAR_ELEMENT_FRAGMENT = graphql`
    fragment ReviewSidebar_ElementFragment on Element {
        elementPath {
            courseRef
        }
        elementType
    }`

interface OwnProps {
    className?: string;
    courseFragmentRef: ReviewSidebar_CourseFragment$key
    elementFragmentRef?: ReviewSidebar_ElementFragment$key | null;
}

export const ReviewSidebar = ({className, courseFragmentRef, elementFragmentRef}: OwnProps) => {

    const course = useFragment<ReviewSidebar_CourseFragment$key>(REVIEW_SIDEBAR_COURSE_FRAGMENT, courseFragmentRef)

    const element = useFragment<ReviewSidebar_ElementFragment$key>(REVIEW_SIDEBAR_ELEMENT_FRAGMENT, elementFragmentRef || null)

    const mainMenuIcon = determineElementIcon(element?.elementType);

    const menuItems = element ? [
        {
            key: "course." + element.elementType,
            path: `/courses/${element.elementPath.courseRef}/review`,
            icon: mainMenuIcon
        },
        {
            key: "course.notes",
            path: `/courses/${element.elementPath.courseRef}/review/notes`,
            icon: "icon-a_notes"
        },
        {
            key: "course.qnas",
            path: `/courses/${element.elementPath.courseRef}/review/qnas`,
            icon: "icon-a_qanda"
        }
    ] : [{
        key: "course.finished",
        path: `/courses/${course.id}/review`,
        icon: mainMenuIcon
    }]

    return <div className={`flex-row flex-nowrap ${className ? className : ""}`}>
        <div className="academy-sidebar menu-column h-100 d-flex flex-column">
            <div className="tk-bg-waterblue p-4">
                <div
                    className="tk-white tk-academy-large tk-academy-bold">Review
                </div>
            </div>
            <div className="tk-bg-waterblue flex-grow-1">
                <Menu items={menuItems}/>
            </div>
        </div>
    </div>;
}
