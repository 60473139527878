/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ApplicationRuleStatus = "applicationInProgress" | "applicationNecessary" | "canFreelyUse" | "cannotApply" | "duplicateApplicationNotPossible" | "noPool" | "poolEmpty" | "%future added value";
export type AdvanceCourseButton_LicensePoolsFragment = {
    readonly edges: ReadonlyArray<{
        readonly node: {
            readonly id: string;
            readonly isMainPool: boolean;
            readonly applicationRuleStatus: ApplicationRuleStatus;
            readonly " $fragmentRefs": FragmentRefs<"ApplyLicenseButton_LicensePoolFragment">;
        };
    } | null> | null;
    readonly " $refType": "AdvanceCourseButton_LicensePoolsFragment";
};
export type AdvanceCourseButton_LicensePoolsFragment$data = AdvanceCourseButton_LicensePoolsFragment;
export type AdvanceCourseButton_LicensePoolsFragment$key = {
    readonly " $data"?: AdvanceCourseButton_LicensePoolsFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"AdvanceCourseButton_LicensePoolsFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "courseId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdvanceCourseButton_LicensePoolsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LicensePoolsEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LicensePool",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isMainPool",
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "courseId",
                  "variableName": "courseId"
                }
              ],
              "kind": "ScalarField",
              "name": "applicationRuleStatus",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ApplyLicenseButton_LicensePoolFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LicensePoolsConnection",
  "abstractKey": null
};
(node as any).hash = '52f2a2fa516abe3eba228095d89431a0';
export default node;
