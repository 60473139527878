import React, {useEffect} from "react";
import {DateTimeDisplay} from "../../../core/components/display/DateTimeDisplay";
import _ from "lodash";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {LicensesInAccount_ApplyLicenseMutation} from "../../../../__generated__/LicensesInAccount_ApplyLicenseMutation.graphql";
import {AssignLicenseButton} from "./AssignLicenseButton";
import {Message, postMessageAction} from "@thekeytechnology/framework-react";
import {useDispatch} from "react-redux";
import * as Sentry from "@sentry/react";
import {LicensesInAccount_LicenseInAccountContainerFragment$key} from "../../../../__generated__/LicensesInAccount_LicenseInAccountContainerFragment.graphql";
import {LicensesInAccount_DownloadLicenseLogMutation} from "../../../../__generated__/LicensesInAccount_DownloadLicenseLogMutation.graphql";
import {LicensesInAccount_AcknowledgeNewLicensesInAccountMutation} from "../../../../__generated__/LicensesInAccount_AcknowledgeNewLicensesInAccountMutation.graphql";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {tkTypography} from "../../../core/style/tkTypography";
import {tkColors} from "../../../core/style/tkColors";

const LICENSES_IN_ACCOUNT_CONTAINER_FRAGMENT = graphql`
    fragment LicensesInAccount_LicenseInAccountContainerFragment on LicenseInAccountContainer {
        id
        mainLicensePool {
            id
            rawId
            account {
                name
            }
        }
        licenses {
            id
            limitedToCoursesRef
            usageData {
                usedAt
                usedByUser
                usedForCourse
            }
        }
        licenseRequests {
            id
            courseRef
            createdAt
            licensePoolRef
            userRef
        }
        courses {
            rawId
            id
            shortTitle
        }
        usersInAccount {
            rawId
            id
            name
        }
        ...AssignLicenseButton_LicensesInAccountContainerFragment
    }
`

const APPLY_LICENSE_MUTATION = graphql`
    mutation LicensesInAccount_ApplyLicenseMutation($courseId: ID!, $licensePoolId: ID!, $userId: ID, $licenseIdOpt: ID) {
        License {
            applyLicense(input: {courseId: $courseId, licensePoolId: $licensePoolId, userId: $userId, licenseIdOpt: $licenseIdOpt}) {
                licensesInAccount {
                    id
                    licenses {
                        usageData {
                            usedByUser
                            usedAt
                            usedForCourse
                        }
                    }
                    licenseRequests {
                        rawId
                        courseRef
                        id
                        userRef
                        createdAt
                    }
                    ...AssignLicenseButton_LicensesInAccountContainerFragment
                }
                courseState {
                    node {
                        id
                    }
                }
            }
        }
    }`

const DOWNLOAD_LICENSE_LOG_MUTATION = graphql`
    mutation LicensesInAccount_DownloadLicenseLogMutation {
        License {
            downloadLicenseLog(input: {}) {
                licenseCsvString
            }
        }
    }`

const ACKNOWLEDGE_NEW_LICENSES_IN_ACCOUNT_MUTATION = graphql`
    mutation LicensesInAccount_AcknowledgeNewLicensesInAccountMutation {
        Viewer {
            License{
                acknowledgeNewLicensesInAccount(input: {}){
                    newLicensesInAccount {
                        id
                        newLicenses
                    }
                    clientMutationId
                }
            }
        }
    }`

interface OwnProps {
    licensesInAccountContainerFragmentRef: LicensesInAccount_LicenseInAccountContainerFragment$key
}

export const LicensesInAccount = ({licensesInAccountContainerFragmentRef}: OwnProps) => {

    const {t} = useTranslation("manager")

    const dispatch = useDispatch()

    const licensesInAccount = useFragment<LicensesInAccount_LicenseInAccountContainerFragment$key>(LICENSES_IN_ACCOUNT_CONTAINER_FRAGMENT, licensesInAccountContainerFragmentRef)

    const [applyLicense] = useMutation<LicensesInAccount_ApplyLicenseMutation>(APPLY_LICENSE_MUTATION)
    const [downloadLicenses] = useMutation<LicensesInAccount_DownloadLicenseLogMutation>(DOWNLOAD_LICENSE_LOG_MUTATION)
    const [acknowledgeNewLicensesInAccount] = useMutation<LicensesInAccount_AcknowledgeNewLicensesInAccountMutation>(ACKNOWLEDGE_NEW_LICENSES_IN_ACCOUNT_MUTATION)


    useEffect(() => {
        acknowledgeNewLicensesInAccount({variables: {}})
    }, [acknowledgeNewLicensesInAccount])

    if (!licensesInAccount) return null

    const freeLicenses = licensesInAccount.licenses.filter(l => !l.usageData)
    const usedLicenses = licensesInAccount.licenses.filter(l => l.usageData)

    const courseLimitations = _.groupBy(freeLicenses.map(x => {
        return {
            limit: x.limitedToCoursesRef ? x.limitedToCoursesRef.map(ltc => ltc).sort().map(limitedToCourse => licensesInAccount.courses.find(c => c.id === limitedToCourse)?.shortTitle).join(", ") : "Unbeschränkt",
            ...x
        }
    }), "limit")


    const downloadLicenseLog = (data: string) => {
        const link = document.createElement("a")
        link.href = "data:text/csv;charset=utf-8," + data
        link.download = "licenses.csv"
        link.click()
    }

    return licensesInAccount.mainLicensePool ? <div className="mt-5">
        <Heading className="mb-2 d-flex align-items-center">
            <span>{t("manager-main-page.licenses-in-account.heading")}</span>

            <DownloadButton className="btn btn-success ml-auto" onClick={() =>
                downloadLicenses({
                    variables: {},
                    onCompleted: (response) => {
                        if (response.License.downloadLicenseLog?.licenseCsvString) {
                            downloadLicenseLog(response.License.downloadLicenseLog?.licenseCsvString)
                        } else {
                            dispatch(postMessageAction(Message.TYPE_DANGER, t("manager-main-page.licenses-in-account.error-when-downloading-license")))
                            Sentry.captureException(`empty csv string for licensepool: ${licensesInAccount.mainLicensePool!.id}`)
                        }
                    },
                    onError: (_ => {
                        dispatch(postMessageAction(Message.TYPE_DANGER, t("manager-main-page.licenses-in-account.error-when-downloading-license")))
                    })
                })
            }>
                <span className="icon-b_data-download"/>
            </DownloadButton>
        </Heading>

        <Text className="mb-2">{t("manager-main-page.licenses-in-account.first-explanation")}</Text>
        <Text className="mb-2">
            <strong>{t("manager-main-page.licenses-in-account.second-explanation.danger")}</strong> {t("manager-main-page.licenses-in-account.second-explanation.text")}
        </Text>
        <Text className="mb-2">{t("manager-main-page.licenses-in-account.third-explanation")}</Text>


        <div className="tk-academy-card p-3">
            <RegistrationLinkForNewUsersText className="mb-3">
                {t("manager-main-page.licenses-in-account.registration-for-new-users.text")} <a target="_blank"
                                                                                                rel="noreferrer noopener"
                                                                                                href={window.location.origin + "/register/" + licensesInAccount.mainLicensePool.rawId + "?utm_source=webapp&utm_medium=accountlink&utm_campaign=" + encodeURI(licensesInAccount.mainLicensePool.account.name)}>{t("manager-main-page.licenses-in-account.registration-for-new-users.link")}</a>
            </RegistrationLinkForNewUsersText>

            {freeLicenses ? <Table className="mb-3">
                <TableHeading>
                    {t("manager-main-page.licenses-in-account.free-licenses-table.heading")}
                </TableHeading>
                <Row>
                    <ColumnHeading>{t("manager-main-page.licenses-in-account.free-licenses-table.columns.restrictions")}</ColumnHeading>
                    <ColumnHeading>{t("manager-main-page.licenses-in-account.free-licenses-table.columns.amount")}</ColumnHeading>
                    <ColumnHeading/>
                    <ColumnHeading>{t("manager-main-page.licenses-in-account.free-licenses-table.columns.action")}</ColumnHeading>
                </Row>
                {Object.keys(courseLimitations).map((l, index) => {
                    const licensesForKey = courseLimitations[l]
                    const courseIdOrNull = l === "Unbeschränkt" ? null : Object.values(licensesForKey).map(l => l.limitedToCoursesRef && l.limitedToCoursesRef[0])[0]

                    return <Row key={index}>
                        <div>
                            {l}
                        </div>
                        <div>
                            {licensesForKey.length}
                        </div>
                        <div/>
                        <div>
                            <AssignLicenseButton
                                courseId={courseIdOrNull}
                                applyLicense={(courseId, userId) => {
                                    applyLicense({
                                        variables: {
                                            userId: userId,
                                            courseId: courseId,
                                            licensePoolId: licensesInAccount.mainLicensePool!.id,
                                            licenseIdOpt: licensesForKey[0].id!

                                        },
                                        updater: (store, data) => {
                                            if (data.License.applyLicense?.courseState.node.id) store.get(data.License.applyLicense?.courseState.node.id)?.invalidateRecord()
                                        },
                                        onCompleted: _ => {
                                            dispatch(postMessageAction(
                                                Message.TYPE_SUCCESS,
                                                t("manager-main-page.licenses-in-account.free-licenses-table.license-successfully-applied")
                                            ))
                                        }
                                    })
                                }}
                                LicensesInAccountContainerRef={licensesInAccount}
                            />
                        </div>
                    </Row>
                })}
            </Table> : null}

            {usedLicenses.length > 0 ? <Table className="mb-3">
                <TableHeading>
                    {t("manager-main-page.licenses-in-account.used-licenses-table.heading")}
                </TableHeading>
                <Row>
                    <ColumnHeading>{t("manager-main-page.licenses-in-account.used-licenses-table.columns.license")}</ColumnHeading>
                    <ColumnHeading>{t("manager-main-page.licenses-in-account.used-licenses-table.columns.used")}</ColumnHeading>
                    <ColumnHeading>{t("manager-main-page.licenses-in-account.used-licenses-table.columns.by")}</ColumnHeading>
                    <ColumnHeading>{t("manager-main-page.licenses-in-account.used-licenses-table.columns.for")}</ColumnHeading>
                </Row>
                {usedLicenses.map((l, index) => <Row key={index}>
                        <div>
                            {l.limitedToCoursesRef && l.limitedToCoursesRef.length > 0 ? t("manager-main-page.licenses-in-account.used-licenses-table.restricted-license") : t("manager-main-page.licenses-in-account.used-licenses-table.unrestricted-license")}
                        </div>
                        <div>
                            <DateTimeDisplay dateTime={l.usageData?.usedAt}/>
                        </div>
                        <div>
                            {licensesInAccount?.usersInAccount.find(u => u.rawId === l.usageData?.usedByUser)?.name}
                        </div>
                        <div>
                            {licensesInAccount?.courses.find(c => c.rawId === l.usageData?.usedForCourse)?.shortTitle}
                        </div>
                    </Row>
                )}
            </Table> : null}

            {licensesInAccount.licenseRequests.length > 0 ? <Table>
                <TableHeading>
                    {t("manager-main-page.licenses-in-account.requested-licenses-table.heading")}
                </TableHeading>
                <Row>
                    <ColumnHeading>{t("manager-main-page.licenses-in-account.requested-licenses-table.columns.when")}</ColumnHeading>
                    <ColumnHeading>{t("manager-main-page.licenses-in-account.requested-licenses-table.columns.by-whom")}</ColumnHeading>
                    <ColumnHeading>{t("manager-main-page.licenses-in-account.requested-licenses-table.columns.for")}</ColumnHeading>
                    <ColumnHeading>{t("manager-main-page.licenses-in-account.requested-licenses-table.columns.action")}</ColumnHeading>
                </Row>
                {
                    licensesInAccount.licenseRequests.map((lr, index) => <Row key={index}>
                            <div>
                                <DateTimeDisplay dateTime={lr.createdAt}/>
                            </div>
                            <div>
                                {licensesInAccount?.usersInAccount.find(u => u.id === lr.userRef)?.name}
                            </div>
                            <div>
                                {licensesInAccount?.courses.find(c => c.id === lr.courseRef)?.shortTitle}
                            </div>
                            <div>
                                <button
                                    onClick={() => applyLicense({
                                        variables: {
                                            courseId: lr.courseRef,
                                            licensePoolId: lr.licensePoolRef,
                                            userId: lr.userRef
                                        },
                                        updater: (store, data) => {
                                            if (data.License.applyLicense?.courseState.node.id) store.get(data.License.applyLicense?.courseState.node.id)?.invalidateRecord()
                                        }
                                    })}
                                    className="btn btn-link p-0">
                                    {t("manager-main-page.licenses-in-account.requested-licenses-table.approve")}
                                </button>
                            </div>
                        </Row>
                    )}
            </Table> : null}
        </div>
    </div> : null
}

const Heading = styled.span`
    ${tkTypography.largeBold}
    ${tkColors.waterBlue80}
`

const DownloadButton = styled.button`
    height: 25px!important;
    width: 25px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Text = styled.div`
    ${tkTypography.regular}
`

const Table = styled.div`
    max-height: 500px;
    padding: 2rem;
    overflow: auto;
`

const TableHeading = styled.h3`
    ${tkTypography.regularBold}
    ${tkColors.waterBlue80}
`

const ColumnHeading = styled.div`
    ${tkTypography.small}
            `

const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
`

const RegistrationLinkForNewUsersText = styled.div`
    ${tkTypography.regular}
`
