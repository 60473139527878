import {ValidatedField} from "../../../../../core/components/form/ValidatedField";
import {SimpleOptionSelectField} from "../../../../../core/components/select/SimpleOptionSelectField";
import CompanyTypes from "../../../../v3/components/billing/forms/company-types.json";

import {AcademyButton} from "../../../../../core/components/button/AcademyButton";
import {Form, Formik} from "formik";
import React from "react";
import {useTranslation} from "react-i18next";
import {ApiCallState, EntityWrapper, UserWithRoles} from "@thekeytechnology/framework-react";
import {
    BusinessBillingDetails,
    LicenseAttribution,
    Signup,
    SpecifyLicensees,
    UseForPurchaser,
    UserMeta
} from "@thekeytechnology/thekey-academy-frontend-library";
import * as yup from "yup";
import {SwitchField} from "../../../../../core/components/form/SwitchField";

interface OwnProps {
    apiCallState: ApiCallState;
    user: EntityWrapper<UserWithRoles> | undefined
    userMeta: EntityWrapper<UserMeta> | undefined
    onSubmit: (billingDetails: BusinessBillingDetails, licenseAttribution: LicenseAttribution, signup?: Signup) => void;
}

export const CheckoutFormBusinessV2 = ({apiCallState, user, userMeta, onSubmit}: OwnProps) => {
    const {t} = useTranslation("billing")

    return <Formik
        initialValues={{
            userId: user ? user.id : undefined,
            salutation: "",
            firstName: userMeta?.entity.firstName ? userMeta?.entity.firstName : "",
            lastName: userMeta?.entity.lastName ? userMeta?.entity.lastName : "",
            company: "",
            companyDetails: undefined,
            companyType: "",
            invoiceEmail: user ? user.entity.email : "",
            street: "",
            houseNumber: "",
            postalCode: "",
            city: "",
            email: "",
            email2: "",
            password: "",
            password2: "",
            legal: false,
            country: "de",
            dateOfBirth: "",
            licenseAttribution: {attributionType: "use-for-purchaser"} as UseForPurchaser
        }}
        enableReinitialize={true}
        validationSchema={
            yup.object().shape({
                salutation: yup.string().when('$exist', {
                    is: exist => exist,
                    then: yup.string()
                        .required(t("core:forms.required-field", {fieldName: t("checkout-form.salutation")}))
                }),
                firstName:
                    yup.string().when('$exist', {
                        is: exist => exist,
                        then: yup
                            .string()
                            .required(t("core:forms.required-field", {fieldName: t("checkout-form.first-name")}))
                    }),
                lastName: yup.string().when('$exist', {
                    is: exist => exist,
                    then: yup
                        .string()
                        .required(t("core:forms.required-field", {fieldName: t("checkout-form.last-name")}))
                }),
                company: yup
                    .string()
                    .required(t("core:forms.required-field", {fieldName: t("checkout-form.company")})),
                companyType: yup
                    .string()
                    .required(t("core:forms.required-field", {fieldName: t("checkout-form.company-type")})),
                invoiceEmail: yup.string().email(
                    t("core:forms.required-field", {fieldName: t("checkout-form.invoice-email")})
                ).required(
                    t("core:forms.required-field", {fieldName: t("checkout-form.invoice-email")})
                ),
                street: yup
                    .string()
                    .required(t("core:forms.required-field", {fieldName: t("checkout-form.street")})),
                houseNumber: yup
                    .string()
                    .max(8, t("core:forms.max-length-housenumber"))
                    .required(t("core:forms.required-field", {fieldName: t("checkout-form.house-number")})),
                postalCode: yup
                    .string()
                    .matches(new RegExp("^[0-9]{5}$"), "Das ist keine gültige PLZ")
                    .required(t("core:forms.required-field", {fieldName: t("checkout-form.postal-code")})),
                city: yup
                    .string()
                    .required(t("core:forms.required-field", {fieldName: t("checkout-form.city")})),
                country: yup
                    .string()
                    .required(t("core:forms.required-field", {fieldName: t("checkout-form.country")})),
                email: yup.string().when('$exist', {
                    is: exist => exist,
                    then: yup.string()
                        .email(t("checkout-form.email-error"))
                        .test("test", "test", function (value) {
                            if (!user && !value) {
                                return this.createError({
                                    path: "email",
                                    message: t("core:forms.required-field", {fieldName: t("checkout-form.email")})
                                });
                            }
                            return true;
                        })
                }),
                email2: yup.string().when('$exist', {
                    is: exist => exist,
                    then: yup.string()
                        .oneOf([yup.ref("email")], t("checkout-form.email2-error"))
                }),
                password:
                    yup.string().when('$exist', {
                        is: exist => exist,
                        then: yup
                            .string()
                            .min(8, t("checkout-form.password-error"))
                            .test("test", "test", function (value) {
                                if (!user && !value) {
                                    return this.createError({
                                        path: "password",
                                        message: t("core:forms.required-field", {fieldName: t("checkout-form.password")})
                                    });
                                }
                                return true;
                            })
                    }),
                password2: yup.string().when('$exist', {
                    is: exist => exist,
                    then: yup.string()
                        .oneOf([yup.ref("password")], t("checkout-form.password2-error"))
                }),
                legal: yup
                    .boolean()
                    .test("test", "test", function (value) {
                        if (!user && !value) {
                            return this.createError({
                                path: "legal",
                                message: t("core:forms.required-field", {fieldName: "Nutzungsbedingungen & Datenschutz"})
                            });
                        }
                        return true;
                    }),
                licenseAttribution: yup
                    .object()
                    .test("licenseAttribution", "licenseAttribution", function (value) {
                        const licenseAttribution = value as LicenseAttribution;

                        if (licenseAttribution.attributionType === "specify-licensees") {
                            const specifyLicensees = licenseAttribution as SpecifyLicensees;

                            const courseTuples: [string, string][] = [];

                            for (let licensee of specifyLicensees.licensees) {
                                if (courseTuples.find(t => t[0] === licensee.courseRef && t[1] === licensee.email)) {
                                    return this.createError({
                                        path: "licenseAttribution",
                                        message: t("checkout-form.license-attribution.error-duplicate")
                                    });
                                }
                                courseTuples.push([licensee.courseRef, licensee.email])
                            }
                        }

                        return true;
                    }),
            })
        }
        onSubmit={(values, {setSubmitting}) => {
            onSubmit({
                customerType: "business",
                salutation: values.salutation,
                firstName: values.firstName,
                lastName: values.lastName,
                street: values.street,
                city: values.city,
                country: values.country,
                postalCode: values.postalCode,
                houseNumber: values.houseNumber,
                company: values.company,
                companyDetails: values.companyDetails,
                companyType: values.companyType,
                invoiceEmail: values.invoiceEmail
            }, values.licenseAttribution, user ? undefined : {
                email: values.email!,
                firstName: values.firstName!,
                lastName: values.lastName!,
                password: values.password!
            })
            setSubmitting(false);
        }}
    >
        {formikState =>
            <Form>
                <div className="tk-form-row">
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        placeholder={t("checkout-form.company")}
                                        name="company"
                                        className="form-control default-input"
                                        required/>
                    </div>
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        placeholder={t("checkout-form.company-details")}
                                        name="companyDetails"
                                        className="form-control default-input"
                                        required/>
                    </div>
                </div>
                <div className="tk-form-row">
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        placeholder={t("checkout-form.company-type")}
                                        name="companyType"
                                        component={SimpleOptionSelectField}
                                        options={
                                            Object.entries(CompanyTypes).map(entry => {
                                                return {
                                                    value: entry[0].toLowerCase(),
                                                    label: entry[1]
                                                }
                                            })
                                        }
                                        className="form-control default-input"
                                        required/>
                    </div>
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        placeholder={t("checkout-form.invoice-email")}
                                        name="invoiceEmail"
                                        type="email"
                                        className="form-control default-input"
                        />
                    </div>
                </div>

                <div className="tk-form-row">
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        placeholder={t("checkout-form.street")}
                                        name="street"
                                        className="form-control default-input"
                                        required
                        />
                    </div>
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        placeholder={t("checkout-form.house-number")}
                                        name="houseNumber"
                                        className="form-control default-input"
                                        required/>
                    </div>
                </div>

                <div className="tk-form-row">
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        placeholder={t("checkout-form.postal-code")}
                                        name="postalCode"
                                        className="form-control default-input"
                                        required
                        />
                    </div>
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        placeholder={t("checkout-form.city")}
                                        name="city"
                                        className="form-control default-input"
                                        required/>
                    </div>
                </div>

                <div className="tk-form-row">
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        placeholder={t("checkout-form.country")}
                                        name="country"
                                        component={SimpleOptionSelectField}
                                        options={[{
                                            value: "de",
                                            label: "Deutschland"
                                        }]
                                        }
                                        className="form-control default-input"
                                        required/>
                    </div>
                </div>


                {user ? null : <>
                    <h3 className="tk-academy-large tk-color-waterblue-80 tk-academy-bold mb-2">{"Teilnehmer anlegen"}</h3>
                    <p className="tk-academy-large mb-2">
                        <strong>Hinweis:</strong> Wenn Dein Unternehmen mehr als eine Lizenz erwerben will, nimm&nbsp;
                        <a href='mailto:app@thekey.academy'>direkt Kontakt mit uns auf</a>. Wir melden uns, um ein auf Euch
                        zugeschnittenes Angebot mit attraktiven
                        Mengenrabatten zu erstellen.
                    </p>
                    <div className="tk-form-row">
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            placeholder={t("checkout-form.salutation")}
                                            name="salutation"
                                            component={SimpleOptionSelectField}
                                            options={[
                                                {
                                                    value: "mr",
                                                    label: "Herr"
                                                },
                                                {
                                                    value: "mrs",
                                                    label: "Frau"
                                                }
                                            ]}
                                            className="form-control default-input"
                                            required/>
                        </div>
                    </div>
                    <div className="tk-form-row">
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            placeholder={t("checkout-form.first-name")}
                                            name="firstName"
                                            className="form-control default-input"
                                            required/>
                        </div>
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            placeholder={t("checkout-form.last-name")}
                                            name="lastName"
                                            className="form-control default-input"
                                            required/>
                        </div>
                    </div>

                    <div className="tk-form-row">
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            placeholder={t("checkout-form.email")}
                                            name="email"
                                            type="email"
                                            className="form-control default-input"
                                            required
                            />
                        </div>
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            placeholder={t("checkout-form.email2")}
                                            name="email2"
                                            type="email"
                                            className="form-control default-input"
                                            required/>
                        </div>
                    </div>
                    <p className="tk-academy-large mb-2">Bitte vergib ein Teilnehmer-Passwort. Bitte Deinen Kollegen darum,
                        dieses in seinen Einstellungen zu
                        ändern:</p>
                    <div className="tk-form-row">
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            type="password"
                                            placeholder={t("checkout-form.password")}
                                            name="password"
                                            className="form-control default-input"
                                            required/>
                        </div>
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            type="password"
                                            placeholder={t("checkout-form.password2")}
                                            name="password2"
                                            className="form-control default-input"
                                            required/>
                        </div>
                    </div>
                    <div className="mb-3">
                        <ValidatedField
                            name="legal"
                            component={SwitchField}
                            titleRight={true}
                            titleClassName="tk-academy-large tk-color-waterblue ml-3"
                            title={<>Mit der Anmeldung stimmst du der <a
                                rel="noopener noreferrer"
                                href='https://www.thekey.academy/datenschutzerklaerung/'
                                target='_blank'>Datenschutzerklärung</a> und den <a
                                rel="noopener noreferrer"
                                href='https://www.thekey.academy/agbs/'
                                target='_blank'>Nutzungsbedingungen</a> zu.</>}
                            formikState={formikState}/>
                    </div>
                </>}

                {/*<h3 className="tk-academy-large tk-color-waterblue-80 tk-academy-bold mb-2 mt-4">{t("checkout-form.assign-licenses")}</h3>*/}
                {/*<ValidatedField formikState={formikState}*/}
                {/*                placeholder={t("checkout-form.license-attribution")}*/}
                {/*                name="licenseAttribution"*/}
                {/*                component={LicenseAttributionField}*/}
                {/*                offer={offer}*/}
                {/*                className="form-control default-input"*/}
                {/*                required/>*/}

                <AcademyButton
                    className="ml-auto"
                    onClick={() => formikState.handleSubmit()}
                    disabled={apiCallState.inProgress || formikState.isSubmitting}
                    text={!user ? "Registrieren und weiter zur Bezahlung" : "Weiter zur Bezahlung"}
                    type={"submit"}/>
            </Form>
        }
    </Formik>
}
