/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LessonImage_ImageFragment = {
    readonly url: string | null;
    readonly " $refType": "LessonImage_ImageFragment";
};
export type LessonImage_ImageFragment$data = LessonImage_ImageFragment;
export type LessonImage_ImageFragment$key = {
    readonly " $data"?: LessonImage_ImageFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LessonImage_ImageFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonImage_ImageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "type": "File",
  "abstractKey": null
};
(node as any).hash = 'a69965c9e6a17ff908db906df1d4dabd';
export default node;
