import {AcademyButton} from '../../../core/components/button/AcademyButton';
import React from 'react';
import {useFragment} from 'react-relay';
import {AdvanceCourseButton} from "../../../state/components/AdvanceCourseButton";
import {LessonNextButton_CourseFragment$key} from "../../../../__generated__/LessonNextButton_CourseFragment.graphql";
import {LessonNextButton_CourseStateFragment$key} from "../../../../__generated__/LessonNextButton_CourseStateFragment.graphql";
import {LessonNextButton_LessonFragment$key} from "../../../../__generated__/LessonNextButton_LessonFragment.graphql";
import {NavLink} from "react-router-dom";
import {graphql} from "babel-plugin-relay/macro";
import {LessonNextButton_SubmissionFragment$key} from "../../../../__generated__/LessonNextButton_SubmissionFragment.graphql";
import {LessonNextButton_LicensePoolsFragment$key} from "../../../../__generated__/LessonNextButton_LicensePoolsFragment.graphql";

const COURSE_FRAGMENT = graphql`
    fragment LessonNextButton_CourseFragment on Course {
        ...AdvanceCourseButton_CourseFragment
    }`

const COURSE_STATE_FRAGMENT = graphql`
    fragment LessonNextButton_CourseStateFragment on CourseState {
        ...AdvanceCourseButton_CourseStateFragment
    }`

const LESSON_FRAGMENT = graphql`
    fragment LessonNextButton_LessonFragment on Lesson {
        id
        lessonPath {
            courseRef
            moduleRef
        }
    }`

const SUBMISSION_FRAGMENT = graphql`
    fragment LessonNextButton_SubmissionFragment on Submission {
        submissionType
        result {
            grading
        }
    }`

const LICENSE_POOLS_FRAGMENT = graphql`
    fragment LessonNextButton_LicensePoolsFragment on LicensePoolsConnection {
        ...AdvanceCourseButton_LicensePoolsFragment
    }
`

interface OwnProps {
    courseFragmentRef: LessonNextButton_CourseFragment$key;
    courseStateFragmentRef: LessonNextButton_CourseStateFragment$key;
    lessonFragmentRef: LessonNextButton_LessonFragment$key;
    submissionFragmentRef: LessonNextButton_SubmissionFragment$key;
    licensePoolsFragmentRef: LessonNextButton_LicensePoolsFragment$key

    canGoBack: boolean;
    isLastPage: boolean;
    goForward: () => void;
}

export const LessonNextButton = ({
                                     courseFragmentRef,
                                     courseStateFragmentRef,
                                     lessonFragmentRef,
                                     submissionFragmentRef,
                                     licensePoolsFragmentRef,
                                     isLastPage,
                                     goForward
                                 }: OwnProps) => {
    const course = useFragment<LessonNextButton_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef)
    const courseState = useFragment<LessonNextButton_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, courseStateFragmentRef)
    const lesson = useFragment<LessonNextButton_LessonFragment$key>(LESSON_FRAGMENT, lessonFragmentRef)
    const submission = useFragment<LessonNextButton_SubmissionFragment$key>(SUBMISSION_FRAGMENT, submissionFragmentRef)
    const licensePools = useFragment<LessonNextButton_LicensePoolsFragment$key>(LICENSE_POOLS_FRAGMENT, licensePoolsFragmentRef)

    const certificationTestFailed = submission.submissionType === 'certificationTest' && submission.result.grading === 'fail'

    return isLastPage && certificationTestFailed ?
        <NavLink to={`/courses/${lesson.lessonPath.courseRef}`}>
            <AcademyButton text={'Zum Kurs'}/>
        </NavLink> :
        isLastPage ?
            <AdvanceCourseButton
                licensePoolsFragmentRef={licensePools}
                courseFragmentRef={course}
                courseStateFragmentRef={courseState}/> :
            <AcademyButton
                text={'Weiter'}
                onClick={goForward}/>
}
