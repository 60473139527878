import React, {useState} from "react";
import {AcademyButton} from "../../core/components/button/AcademyButton";
import {OrderCertificateModal} from "../../certificate/components/OrderCertificateModal";

interface OwnProps {
    disabled?: boolean
    courseId: string,
}

export const OrderCertificateButton = ({disabled, courseId}: OwnProps) => {
    const [isModalVisible, setModalVisible] = useState(false);

    return <>
        <AcademyButton onClick={() => setModalVisible(true)} disabled={disabled} text="Zertifikat anfordern"/>

        {isModalVisible ? <OrderCertificateModal courseId={courseId} closeModal={() => setModalVisible(false)}/> : null}
    </>;
}
