import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_FETCH_ACCOUNT_META = "fetch-account-meta";

export const fetchAccountMetaAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FETCH_ACCOUNT_META,
            callType: API_FETCH_ACCOUNT_META
        },
        payload: undefined
    } as ApiCallAction<void>;
};
