import React, { useEffect, useState } from "react"
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./points-circle.scss";

interface OwnProps {
    pointsAwarded: number;
    pointsPossible: number;
}

export const PointsCircle = ({
                                 pointsAwarded,
                                 pointsPossible
                             }: OwnProps) => {

    const [percentage, setPercentage] = useState(0);
    useEffect(() => {
        setPercentage((pointsAwarded / pointsPossible) * 100)
    }, [pointsPossible, pointsAwarded])

    return <CircularProgressbarWithChildren
        className="points-circle"
        value={percentage}
        styles={buildStyles({
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: "butt",

            // Text size
            textSize: "12px",

            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 3,

            // Can specify path transition in more detail, or remove it entirely
            // pathTransition: 'none',

            // Colors
            pathColor: `#ffd426`,
            trailColor: "#d3e5f4",
            backgroundColor: "#d3e5f4",
        })}>
        <div className="d-flex flex-column align-items-center">
            <div
                className="tk-color-darkblue tk-academy-extra-large-heading-bold">{pointsAwarded}/{pointsPossible}</div>
            <div className="tk-color-darkblue tk-academy-text">Punkte</div>
        </div>
    </CircularProgressbarWithChildren>;
}
