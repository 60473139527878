import React, {useContext, useEffect, useState} from "react";
import {AcademyButton} from "../../../../core/components/button/AcademyButton";
import {ElementFlowContext} from "@thekeytechnology/thekey-academy-frontend-library";
import "./learn-footer.scss";
import {motion} from "framer-motion"

import Success from "./success.png";
import Failure from "./failure.png";
import {ProgressBottomBar} from "./ProgressBottomBar";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {LearnFooter_LessonFragment$key} from "../../../../../__generated__/LearnFooter_LessonFragment.graphql";

const LEARN_FOOTER_LESSON_FRAGMENT = graphql`
    fragment LearnFooter_LessonFragment on Lesson {
        lessonType
    }
`;

interface OwnProps {
    lessonFragmentRef: LearnFooter_LessonFragment$key;
}

export const LearnFooter = ({lessonFragmentRef}: OwnProps) => {
    const {
        flowState: {canGoBack, canSkip, currentElementId, currentTry, isValidationFailed, isValidationSuccess},
        goBack,
        trySubmit,
        skipElement,
        tryAgain
    } = useContext(ElementFlowContext)

    const lesson = useFragment<LearnFooter_LessonFragment$key>(LEARN_FOOTER_LESSON_FRAGMENT, lessonFragmentRef)

    const [blockTemporarily, setBlockTemporarily] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setBlockTemporarily(false);
        }, 1000);
        return () => {
            clearTimeout(timer);
            setBlockTemporarily(true);
        }
    }, [currentElementId]);

    const disabled = blockTemporarily || !currentTry;

    let AdvanceButton;
    let StatusAndBackButton;
    let className;
    if (isValidationFailed) {
        AdvanceButton = <motion.div
            initial={{x: 10}}
            animate={{x: 0}}
            className={'mt-5 d-flex flex-row'}
            transition={{type: "spring", stiffness: 1000}}>
            {(canSkip && lesson.lessonType === "normal") ?
                <AcademyButton
                    className={"mr-4 tk-skip-button tk-color-waterblue-80"}
                    onClick={skipElement}
                    text={"Frage überspringen"}
                /> :
                null
            }
            <AcademyButton
                buttonStyle={"danger"}
                onClick={tryAgain}
                text={"Nochmal versuchen"}
            />

        </motion.div>

        StatusAndBackButton =
            <div className="status-display failure-display position-absolute bottom-0 left-0 d-flex align-items-center">
                <img alt="Falsch" src={Failure}/>
                <div className="tk-color-orange mt-5">Ups. Falsche Antwort.</div>
            </div>
        className = "tk-bg-orange-20"
    } else if (isValidationSuccess) {
        AdvanceButton = <motion.div
            initial={{y: 10}}
            animate={{y: 0}}
            transition={{type: "spring", stiffness: 1000}}>
            <AcademyButton
                disabled={disabled}
                onClick={trySubmit}
                text={"Weiter"}
            />
        </motion.div>
        StatusAndBackButton =
            <div className="status-display success-display position-absolute bottom-0 left-0 d-flex align-items-end">
                <img alt="Richtig" src={Success}/>
                <div className="tk-color-keygreen"><strong>Yeah!</strong> Das ist richtig.</div>
            </div>
        className = "tk-bg-keygreen-20"
    } else {
        AdvanceButton = <AcademyButton
            disabled={disabled}
            onClick={trySubmit}
            text={"Weiter"}
        />
        StatusAndBackButton = <AcademyButton
            type="reset"
            hideIcon={true}
            buttonStyle="outline"
            className="mr-auto"
            text={"Zurück"} disabled={!canGoBack}
            onClick={goBack}/>
        className = "";
    }

    return <div className={`learn-footer d-flex justify-content-end p-3 position-relative ${className}`}>
        <ProgressBottomBar/>
        {StatusAndBackButton}
        {AdvanceButton}
    </div>

}
