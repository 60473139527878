import React from "react";
import {BlueBackground} from "../../../components/screen/BlueBackground";
import {Screen} from "../../../components/screen/Screen";
import {LoadingFakeFlowSidebar} from "../LoadingFakeFlowSidebar";
import styled from "styled-components";
import {tkTypography} from "../../../style/tkTypography";
import {tkColors} from "../../../style/tkColors";
import {LearnReviewPrimaryContentArea} from "../../../../learnReview/LearnReviewPrimaryContentArea";
import {LearnReviewSecondaryContentArea} from "../../../../learnReview/LearnReviewSecondaryContentArea";

export const LoadingFakeSubmitLessonOrReviewScreen = () => {

    return <Screen
        className="learn-screen"
        sidebarSlot={<LoadingFakeFlowSidebar/>}
        headerBarBackgroundSlot={
            <BlueBackground height={90}/>
        }
        floatingFooterSlot={
            <></>
        }
        contentAreaRenderer={children =>
            <LearnReviewPrimaryContentArea>
                <LearnReviewSecondaryContentArea>
                    <div className="d-flex justify-content-center w-100">
                        {children}
                    </div>
                </LearnReviewSecondaryContentArea>
            </LearnReviewPrimaryContentArea>
        }>
        <div className="d-flex flex-column justify-content-center">
            <Spinner className="spinner-grow align-self-center" role="status">
                <span className="sr-only">Lade...</span>
            </Spinner>
            <Text>Einen kleinen Moment bitte... Wir zählen kurz Deine Schlüssel.</Text>
        </div>
    </Screen>;
}

const Spinner = styled.div`
    ${tkColors.keyGreen}
    margin-bottom: 100px;
`

const Text = styled.p`
    ${tkTypography.large}
`