import React, {ReactNode} from "react";
import "./registration-screen.scss"
import {MenuHeader} from "../../../core/components/header/MenuHeader";

interface OwnProps {
    children?: ReactNode
}

export const RegistrationScreenContainer = ({children}: OwnProps) => {

    return <div className="h-100 row registration-screen">
        <div className="col-12 col-lg-4 tk-bg-light d-flex flex-column hello-background">
            <MenuHeader className="p-4" dark={true}/>
            <div className="h-100 align-items-center d-flex flex-column justify-content-center pt-0 pr-5 pb-5 pl-5">
                {children}
            </div>
        </div>
        <div className="d-none d-lg-block col-8">
            <div className="registration-background">
            </div>
            <div className="p-5 registration-foreground">
                <div className="p-5 m-5">
                    <div className="tk-academy-huge-bold-text">Wir ermöglichen<br/> Weiterbildungen für jeden</div>
                    <div className="tk-academy-huge-text">Kurse digital und bequem von jedem<br/> Ort der Welt besuchen!
                    </div>
                </div>
            </div>
        </div>
    </div>
}
