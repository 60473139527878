/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OrderFlowScreen_ViewerFragment = {
    readonly Viewer: {
        readonly Auth: {
            readonly User: {
                readonly " $fragmentRefs": FragmentRefs<"BillingDetailsStep_UserFragment">;
            } | null;
        };
    };
    readonly " $refType": "OrderFlowScreen_ViewerFragment";
};
export type OrderFlowScreen_ViewerFragment$data = OrderFlowScreen_ViewerFragment;
export type OrderFlowScreen_ViewerFragment$key = {
    readonly " $data"?: OrderFlowScreen_ViewerFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"OrderFlowScreen_ViewerFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderFlowScreen_ViewerFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerQueryType",
      "kind": "LinkedField",
      "name": "Viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AuthViewerSchema",
          "kind": "LinkedField",
          "name": "Auth",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "User",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "BillingDetailsStep_UserFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
(node as any).hash = '63332364db98e20a1b4339fd0002010a';
export default node;
