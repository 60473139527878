import React from "react"
import DatePicker from "react-datepicker";
import de from "date-fns/locale/de"; // the locale you want
import subYears from "date-fns/subYears"

interface OwnProps {
    placeholder?: string
    disabled?: boolean;
    value?: Date
    updateValue: (date: Date) => void;
}

type Props = OwnProps;

export const DateInput = ({value, updateValue, disabled, placeholder}: Props) => {
    return <DatePicker
        placeholderText={placeholder}
        disabled={disabled}
        selected={value}
        locale={de}
        dateFormat="dd.MM.yyyy"
        maxDate={subYears(new Date(), 17)}
        showYearDropdown={true}
        yearDropdownItemNumber={15}
        scrollableYearDropdown={true}
        onChange={(date) => updateValue(date as Date)}/>
}
