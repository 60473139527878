/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PaymentMethod = "card" | "giropay" | "iapApple" | "iapGoogle" | "invoicePartner" | "invoiceTk" | "monthlyPartner" | "monthlyTk" | "paypal" | "sepa" | "sofort" | "%future added value";
export type SelectedPaymentMethodType = "monthly" | "oneTime" | "%future added value";
export type MonthlyPaymentCategory_OrderFragment = {
    readonly id: string;
    readonly selectedPaymentMethod: {
        readonly paymentMethodId: PaymentMethod;
        readonly selectedPaymentMethodType: SelectedPaymentMethodType;
        readonly chosenOption?: {
            readonly rateCount: number;
        };
    } | null;
    readonly allowedPaymentMethods: ReadonlyArray<PaymentMethod>;
    readonly cart: {
        readonly cartTotals: {
            readonly monthlyOptions: ReadonlyArray<{
                readonly totalAmount: {
                    readonly grossPrice: number;
                };
                readonly downPayment: {
                    readonly netPrice: number;
                    readonly grossPrice: number;
                    readonly taxRatePercentage: number;
                };
                readonly financedAmount: {
                    readonly netPrice: number;
                    readonly grossPrice: number;
                    readonly taxRatePercentage: number;
                };
                readonly monthlyRate: {
                    readonly netPrice: number;
                    readonly grossPrice: number;
                    readonly taxRatePercentage: number;
                };
                readonly rateCount: number;
            }>;
        };
    };
    readonly " $refType": "MonthlyPaymentCategory_OrderFragment";
};
export type MonthlyPaymentCategory_OrderFragment$data = MonthlyPaymentCategory_OrderFragment;
export type MonthlyPaymentCategory_OrderFragment$key = {
    readonly " $data"?: MonthlyPaymentCategory_OrderFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"MonthlyPaymentCategory_OrderFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rateCount",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grossPrice",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "netPrice",
    "storageKey": null
  },
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "taxRatePercentage",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MonthlyPaymentCategory_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "selectedPaymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethodId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "selectedPaymentMethodType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyOption",
              "kind": "LinkedField",
              "name": "chosenOption",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "MonthlySelectedPaymentMethod",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowedPaymentMethods",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CartTotals",
          "kind": "LinkedField",
          "name": "cartTotals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyOption",
              "kind": "LinkedField",
              "name": "monthlyOptions",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceV3",
                  "kind": "LinkedField",
                  "name": "totalAmount",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceV3",
                  "kind": "LinkedField",
                  "name": "downPayment",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceV3",
                  "kind": "LinkedField",
                  "name": "financedAmount",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceV3",
                  "kind": "LinkedField",
                  "name": "monthlyRate",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();
(node as any).hash = 'a5f93c2687745ccb86cb9008db4132de';
export default node;
