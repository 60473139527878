import React from 'react'
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {FailedElementDisplay_FailedElementFragment$key} from "../../../../__generated__/FailedElementDisplay_FailedElementFragment.graphql";
import {FailedMultipleChoiceElement} from "./multiplechoice/FailedMultipleChoiceElement";
import {FailedClozeElement} from "./cloze/FailedClozeElement";
import {FailedOrderElement} from "./order/FailedOrderElement";

const FAILED_ELEMENT_DISPLAY_FAILED_ELEMENT_FRAGMENT = graphql`
    fragment FailedElementDisplay_FailedElementFragment on FailedElement {
        mistakes {
            mistakeType
        }
        ...FailedClozeElement_FailedElementFragment
        ...FailedMultipleChoiceElement_FailedElementFragment
        ...FailedOrderElement_FailedElementFragment
    }
`

interface OwnProps {
    failedElementFragmentRef: FailedElementDisplay_FailedElementFragment$key;
}

export const FailedElementDisplay = ({failedElementFragmentRef}: OwnProps) => {

    const failedElement = useFragment<FailedElementDisplay_FailedElementFragment$key>(FAILED_ELEMENT_DISPLAY_FAILED_ELEMENT_FRAGMENT, failedElementFragmentRef)

    let Component;

    switch (failedElement.mistakes.mistakeType) {
        case 'multipleChoice':
            Component = <FailedMultipleChoiceElement failedElementFragmentRef={failedElement}/>
            break;
        case 'clozeText':
            Component = <FailedClozeElement failedElementFragmentRef={failedElement}/>
            break;
        case 'order':
            Component = <FailedOrderElement failedElementFragmentRef={failedElement}/>
            break;
        default:
            Component = <div>Not supported: {failedElement.mistakes.mistakeType}</div>
    }

    return <div className={"tk-bg-waterblue-10"}>{Component}</div>;
}