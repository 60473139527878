import React from "react";
import {LoadingFakeMainSidebar} from "../LoadingFakeMainSidebar";
import {GradientBackgroundHeader} from "../../components/screen/GradientBackground";
import {Screen} from "../../components/screen/Screen";

export const LoadingFakeDetailScreen = () => {

    return <Screen
        sidebarSlot={<LoadingFakeMainSidebar className="course-sidebar" collapsed={true} secondSidebarSlot={
            <div className="tk-bg-waterblue flex-grow-1 overflow-auto">
                <div className="submenu-column d-flex flex-column">
                    <div className="tk-bg-gradient-top-down pb-3" style={{height: 250}}/>
                </div>
            </div>
        }/>}
        headerBarBackgroundSlot={<GradientBackgroundHeader height={250}/>}
        headerMargin={60}
        floatingFooterSlot={<></>}>
        <></>
    </Screen>;
}