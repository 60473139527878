/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ElementType = "clozeText" | "image" | "multipleChoice" | "order" | "persolog" | "questionnaire" | "resolution" | "text" | "video" | "%future added value";
export type LessonType = "normal" | "test" | "%future added value";
export type LessonInfo_LessonFragment = {
    readonly containerMeta: {
        readonly description: string;
        readonly title: string;
    };
    readonly course: {
        readonly experts: ReadonlyArray<{
            readonly id: string;
        }>;
        readonly " $fragmentRefs": FragmentRefs<"ExpertList_CourseFragment" | "CourseAd_CourseFragment">;
    };
    readonly elements: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly elementType: ElementType;
            };
        } | null> | null;
    } | null;
    readonly id: string;
    readonly image: {
        readonly " $fragmentRefs": FragmentRefs<"LessonImage_ImageFragment">;
    } | null;
    readonly lessonPath: {
        readonly lessonIndex: number;
        readonly moduleIndex: number;
    };
    readonly lessonType: LessonType;
    readonly additionalExperts?: ReadonlyArray<{
        readonly id: string;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"ExpertList_NormalLessonFragment" | "LessonAdditionalContentList_NormalLessonFragment">;
    readonly " $refType": "LessonInfo_LessonFragment";
};
export type LessonInfo_LessonFragment$data = LessonInfo_LessonFragment;
export type LessonInfo_LessonFragment$key = {
    readonly " $data"?: LessonInfo_LessonFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LessonInfo_LessonFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonInfo_LessonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContainerMeta",
      "kind": "LinkedField",
      "name": "containerMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "course",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Expert",
          "kind": "LinkedField",
          "name": "experts",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExpertList_CourseFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CourseAd_CourseFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ElementsConnection",
      "kind": "LinkedField",
      "name": "elements",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ElementsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "elementType",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LessonImage_ImageFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LessonPath",
      "kind": "LinkedField",
      "name": "lessonPath",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lessonIndex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleIndex",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lessonType",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Expert",
          "kind": "LinkedField",
          "name": "additionalExperts",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExpertList_NormalLessonFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LessonAdditionalContentList_NormalLessonFragment"
        }
      ],
      "type": "NormalLesson",
      "abstractKey": null
    }
  ],
  "type": "Lesson",
  "abstractKey": "__isLesson"
};
})();
(node as any).hash = '6488aba24aa6743085e8b63618b3555f';
export default node;
