import React, { ReactNode, useState } from "react";

interface OwnProps {
    className?: string;

    renderChildren: () => ReactNode;

    indexText?: string | ReactNode;
    title: string;

    isOpenInitially?: boolean;
}

export const CollapsibleCard = ({
                                    className,
                                    renderChildren,
                                    indexText,
                                    title,
                                    isOpenInitially
                                }: OwnProps) => {

    const [isOpen, setOpen] = useState(isOpenInitially ? isOpenInitially : false);

    const contentClass = indexText ? "col-10" : "col-11";

    return <div className={`p-3 tk-border-radius ${className}`}>
        <button className="btn-link w-100" onClick={() => setOpen(!isOpen)}>
            <div className={`d-flex flex-row align-items-center ${isOpen ? "mb-3" : ""}`}>
                {indexText ? <div className="col-1 tk-academy-small-text">
                    {indexText}
                </div> : null}
                <div className={`${contentClass} tk-academy-small-text tk-academy-bold tk-color-darkblue`}>
                    {title}
                </div>
                <div className="col-1 d-flex justify-content-end tk-academy-large">
                    <span className={isOpen ? "icon-a_arrow-up" : "icon-a_arrow-down"}/>
                </div>
            </div>
        </button>
        {isOpen ? <div className="d-flex flex-row">
            {indexText ? <div className="col-1">
                &nbsp;
            </div> : null}
            <div className={contentClass}>
                {renderChildren()}
            </div>
            <div className="col-1 d-flex justify-content-end">
                &nbsp;
            </div>
        </div> : null}
    </div>
}
