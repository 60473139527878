/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type GlossaryEntriesOfNormalLessonDisplay_NormalLessonFragment = {
    readonly glossary: ReadonlyArray<{
        readonly " $fragmentRefs": FragmentRefs<"GlossaryItem_GlossaryEntryFragment">;
    }>;
    readonly " $refType": "GlossaryEntriesOfNormalLessonDisplay_NormalLessonFragment";
};
export type GlossaryEntriesOfNormalLessonDisplay_NormalLessonFragment$data = GlossaryEntriesOfNormalLessonDisplay_NormalLessonFragment;
export type GlossaryEntriesOfNormalLessonDisplay_NormalLessonFragment$key = {
    readonly " $data"?: GlossaryEntriesOfNormalLessonDisplay_NormalLessonFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"GlossaryEntriesOfNormalLessonDisplay_NormalLessonFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GlossaryEntriesOfNormalLessonDisplay_NormalLessonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GlossaryEntry",
      "kind": "LinkedField",
      "name": "glossary",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GlossaryItem_GlossaryEntryFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NormalLesson",
  "abstractKey": null
};
(node as any).hash = '45ce02d7adacf3cbe5fc1c43a8de0218';
export default node;
