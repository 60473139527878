import React, {ReactNode} from "react";
import {BillingBaseV3} from "./common/BillingBaseV3";
import {BillingLoginV3} from "./billing/forms/BillingLoginV3";
import styled from "styled-components";

interface State {
    error?: any
}

interface OwnProps {
    children: ReactNode
}

export class LoginErrorBoundary extends React.Component<OwnProps, State> {
    static getDerivedStateFromError(error: any): State {
        if (error?.res?.errors?.find((e: any) => e.message === "billing_order-cannot-be-accessed-by-this-user")) {
            return {error};
        }
        throw error;
    }

    public render() {
        if (this.state?.error) {
            return <BillingBaseV3 mainSlot={<div>
                <Heading>Du musst eingeloggt sein um auf diese Bestellung zuzugreifen</Heading>

                <BillingLoginV3 onSuccess={() => {
                    window.location.reload();
                }}/>
            </div>}/>
        }

        return this.props.children;
    }
}

const Heading = styled.h3`
  font-family: "Nunito Sans", sans-serif;

  font-size: 24px;
  line-height: 1.42;
  color: #184276;
  font-weight: normal;
  margin-bottom: 25px;

  @media (max-width: 1024px) {
    font-size: 18px;
  }
`
