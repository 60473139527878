import React, {ReactNode, useEffect, useState} from "react";
import {MenuHeader} from "../header/MenuHeader";
import {Menu, MenuItem} from "../../../menu/components/Menu";
import {LowerSidebarMenu} from "../../../menu/components/LowerSidebarMenu";
import "./main-sidebar.scss";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentAccount} from "@thekeytechnology/framework-react";
import {fetchAccountMetaAction} from "../../../manager/action/fetch-account-meta";
import {selectAccountMeta} from "../../../manager/selectors";

interface OwnProps {
    className?: string;
    collapsed?: boolean
    numUnseenCustomerMessages: number;
    hasNewLicensesInAccount: boolean;
    secondSidebarSlot?: ReactNode;
}

interface State {
    mainMenuItems: MenuItem[]
}

export const MainSidebar = ({className, collapsed, numUnseenCustomerMessages, secondSidebarSlot, hasNewLicensesInAccount}: OwnProps) => {
    const [state, setState] = useState<State>({
        mainMenuItems: [
            {key: "dashboard", path: "/", icon: "icon-d_dashboard"},
            {key: "gamification", path: "/achievements", icon: "icon-a_star"},
            {key: "notes", path: "/notes", icon: "icon-a_notes"},
            {key: "customer-messages", path: "/customer-messages", icon: "icon-a_inbox"},
            {key: "faqs", path: "/faqs", icon: "icon-a_question"}
        ]
    })

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAccountMetaAction())
    }, [dispatch])

    useEffect(() => {
        setState({
            mainMenuItems: state.mainMenuItems.map(item => {
                if (item.key === 'customer-messages') {
                    return {
                        ...item,
                        showBadge: numUnseenCustomerMessages > 0,
                        badgeContent: numUnseenCustomerMessages.toString()
                    }
                } else return item
            })
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numUnseenCustomerMessages])


    const account = useSelector(selectCurrentAccount);
    const accountMeta = useSelector(selectAccountMeta);

    return <div className={(className ? className : "") + " flex-row flex-nowrap"}>
        <div className="academy-sidebar menu-column h-100 d-flex flex-column">
            <MenuHeader collapsed={collapsed} className="p-0 tk-bg-waterblue"/>
            <div className="tk-bg-keyblue flex-grow-1">
                <Menu items={state.mainMenuItems} collapsed={collapsed}/>
            </div>
            <div className="tk-bg-keyblue">
                <LowerSidebarMenu collapsed={collapsed} hasNewLicensesInAccount={hasNewLicensesInAccount}/>
                {account?.name === "Telefónica" || accountMeta?.entity.isSlaveAccount ?
                    <div className={`o2-logo ${collapsed ? 'collapsed' : ''}`}/> : null}

            </div>
        </div>
        {secondSidebarSlot ? secondSidebarSlot : null}
    </div>;
}
