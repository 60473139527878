/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LearnContentArea_CourseFragment = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"NotesScreenContent_CourseFragment">;
    readonly " $refType": "LearnContentArea_CourseFragment";
};
export type LearnContentArea_CourseFragment$data = LearnContentArea_CourseFragment;
export type LearnContentArea_CourseFragment$key = {
    readonly " $data"?: LearnContentArea_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LearnContentArea_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LearnContentArea_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotesScreenContent_CourseFragment"
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = '0677a3af34e16dc5f2f69fda17c0afb0';
export default node;
