import React from "react";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {CourseAdditionalContentList_CourseFragment$key} from "../../../../../../__generated__/CourseAdditionalContentList_CourseFragment.graphql";
import {CourseAdditionalContentModuleCard} from "./CourseAdditionalContentModuleCard";
import {CourseAdditionalContentList_CourseStateFragment$key} from "../../../../../../__generated__/CourseAdditionalContentList_CourseStateFragment.graphql";

const COURSE_ADDITIONAL_CONTENT_LIST_COURSE_FRAGMENT = graphql`
    fragment CourseAdditionalContentList_CourseFragment on Course {
        additionalContent {
            module {
                id
            }
            ...CourseAdditionalContentModuleCard_AdditionalContentByModuleFragment
        }
    }`

const COURSE_ADDITIONAL_CONTENT_LIST_COURSE_STATE_FRAGMENT = graphql`
    fragment CourseAdditionalContentList_CourseStateFragment on CourseState {
        ...CourseAdditionalContentModuleCard_CourseStateFragment
    }`

interface OwnProps {
    courseFragmentRef: CourseAdditionalContentList_CourseFragment$key;
    courseStateFragmentRef: CourseAdditionalContentList_CourseStateFragment$key;
}

export const CourseAdditionalContentList = ({courseFragmentRef, courseStateFragmentRef}: OwnProps) => {

    const course = useFragment<CourseAdditionalContentList_CourseFragment$key>(COURSE_ADDITIONAL_CONTENT_LIST_COURSE_FRAGMENT, courseFragmentRef);

    const courseState = useFragment<CourseAdditionalContentList_CourseStateFragment$key>(COURSE_ADDITIONAL_CONTENT_LIST_COURSE_STATE_FRAGMENT, courseStateFragmentRef);

    return course.additionalContent.length > 0 ? <div className="tk-academy-card p-5" id="additional-content">
        <div className="tk-academy-text tk-academy-bold tk-color-waterblue-80 mb-4">Material</div>
        {course.additionalContent.map(aC => <CourseAdditionalContentModuleCard key={aC.module.id}
                                                                               additionalContentByModuleFragmentRef={aC}
                                                                               courseStateFragmentRef={courseState}/>)}
    </div> : null;
}