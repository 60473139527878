import {
    apiGet,
    ChangeAccount,
    logoutAction,
    matchesApiSuccess
} from "@thekeytechnology/framework-react";
import {combineEpics, ofType, StateObservable} from "redux-observable";
import {Observable} from "rxjs";
import {Action} from "redux";
import {map, withLatestFrom} from "rxjs/operators";
import {push} from "connected-react-router";
import {saveState} from "../../../localStorage";
import {API_LEAVE_ACCOUNT} from "../action/leave-account";

const reloadOnAccountChange$ = (action$: Observable<Action>, state$: StateObservable<any>) => action$.pipe(
    ofType(ChangeAccount),
    withLatestFrom(state$),
    map(([, state]: [Action, any]) => {
        saveState({
            auth: {
                authState: state.auth.authState
            },
        });
        setTimeout(() => {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
        }, 300);
        return push("/");
    })
);

const leaveAccount$ = apiGet(
    {apiType: API_LEAVE_ACCOUNT},
    `/api/v1/auth/leave-account`,
);

const logoutOnAccountLeave$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_LEAVE_ACCOUNT),
    map(() => {
        return logoutAction();
    })
);

export const extendedAcademyAuthModuleEpics$ = combineEpics(
    reloadOnAccountChange$,
    leaveAccount$,
    logoutOnAccountLeave$,
)
