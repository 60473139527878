import {CustomerTypeSelectorV3} from "./CustomerTypeSelect";
import {BusinessForm} from "./BusinessForm";
import {PrivateForm} from "./PrivateForm";
import React, {useState} from "react";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {
    BillingDetailsFormV3_OrderFragment$key,
    CustomerType
} from "../../../../../../__generated__/BillingDetailsFormV3_OrderFragment.graphql";
import {BillingDetailsFormV3_UserFragment$key} from "../../../../../../__generated__/BillingDetailsFormV3_UserFragment.graphql";

interface OwnProps {
    userFragmentRef: BillingDetailsFormV3_UserFragment$key | null
    orderFragmentRef: BillingDetailsFormV3_OrderFragment$key
}

export const BillingDetailsFormV3 = ({orderFragmentRef, userFragmentRef}: OwnProps) => {
    const order = useFragment<BillingDetailsFormV3_OrderFragment$key>(graphql`
        fragment BillingDetailsFormV3_OrderFragment on Order {
            id
            billingDetails {
                customerType
                ...PrivateForm_BillingDetailsFragment
                ...BusinessForm_BillingDetailsFragment
            }
        }
    `, orderFragmentRef)

    const user = useFragment<BillingDetailsFormV3_UserFragment$key>(graphql`
        fragment BillingDetailsFormV3_UserFragment on User {
            ...BusinessForm_UserFragment
            ...PrivateForm_UserFragment
        }
    `, userFragmentRef)


    const [customerType, setCustomerType] = useState<CustomerType>("private");

    return <div>
        <CustomerTypeSelectorV3 customerType={customerType}
                                updateCustomerType={c => setCustomerType(c)}/>
        {customerType === "business" ?
            <BusinessForm
                orderId={order.id}
                billingDetailsFragmentRef={order.billingDetails}
                userDataFragmentRef={user}/> :
            <PrivateForm
                orderId={order.id}
                billingDetailsFragmentRef={order.billingDetails}
                userDataFragmentRef={user}/>
        }
    </div>
}
