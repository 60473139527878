import React from "react";
import {PriceTable} from "@thekeytechnology/thekey-academy-frontend-library";
import {CurrencyDisplay} from "../../../../../../core/components/currency/CurrencyDisplay";
import {PriceRenderer} from "./PriceRenderer";

interface OwnProps {
    priceRenderer: PriceRenderer

    monthlyPossible?: boolean
    showNetPrices?: boolean;
    priceTable: PriceTable;

    isMonthly?: boolean
}

export const CartTotalRow = ({
                                 priceRenderer,
                                 showNetPrices,
                                 priceTable,
                                 isMonthly
                             }: OwnProps) => {
    const price = priceRenderer(priceTable.total, priceTable.monthlyTotal, true)

    return <div className="cart-table-row cart-total-row">
        <div className="row-title">
            <div>Gesamt</div>
        </div>
        <div className="ml-auto price-wrapper text-right">
            <div className="tax tk-academy-small-text tk-color-waterblue-80">
                {isMonthly && priceTable.monthlyTotal ?
                    <><CurrencyDisplay
                        value={priceTable.monthlyTotal.discountedSumGross - priceTable.monthlyTotal.discountedSumNet}/></> :
                    <CurrencyDisplay
                        value={priceTable.total.discountedGrossAmount - priceTable.total.discountedNetAmount}/>}
            </div>
            {price}
        </div>
    </div>
}
