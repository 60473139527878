/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EditQnaModal_PostQnaMutationVariables = {
    courseId: string;
    elementId?: string | null;
    lessonId?: string | null;
    qnasConnectionIds: Array<string>;
    question: string;
};
export type EditQnaModal_PostQnaMutationResponse = {
    readonly Qnas: {
        readonly postQna: {
            readonly qna: {
                readonly node: {
                    readonly " $fragmentRefs": FragmentRefs<"QnaItem_QnaFragment">;
                };
            };
        } | null;
    };
};
export type EditQnaModal_PostQnaMutation = {
    readonly response: EditQnaModal_PostQnaMutationResponse;
    readonly variables: EditQnaModal_PostQnaMutationVariables;
};



/*
mutation EditQnaModal_PostQnaMutation(
  $courseId: ID!
  $elementId: ID
  $lessonId: ID
  $question: String!
) {
  Qnas {
    postQna(input: {courseId: $courseId, elementId: $elementId, lessonId: $lessonId, question: $question}) {
      qna {
        node {
          ...QnaItem_QnaFragment
          id
        }
      }
    }
  }
}

fragment QnaItem_QnaFragment on Qna {
  answer
  id
  isFromCurrentUser
  isPublic
  question
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "courseId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "elementId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lessonId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "qnasConnectionIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "question"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "courseId",
        "variableName": "courseId"
      },
      {
        "kind": "Variable",
        "name": "elementId",
        "variableName": "elementId"
      },
      {
        "kind": "Variable",
        "name": "lessonId",
        "variableName": "lessonId"
      },
      {
        "kind": "Variable",
        "name": "question",
        "variableName": "question"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditQnaModal_PostQnaMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "QnasMutations",
        "kind": "LinkedField",
        "name": "Qnas",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "PostQnaPayload",
            "kind": "LinkedField",
            "name": "postQna",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "QnasEdge",
                "kind": "LinkedField",
                "name": "qna",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Qna",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "QnaItem_QnaFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditQnaModal_PostQnaMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "QnasMutations",
        "kind": "LinkedField",
        "name": "Qnas",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "PostQnaPayload",
            "kind": "LinkedField",
            "name": "postQna",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "QnasEdge",
                "kind": "LinkedField",
                "name": "qna",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Qna",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "answer",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isFromCurrentUser",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isPublic",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "question",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "appendEdge",
                "key": "",
                "kind": "LinkedHandle",
                "name": "qna",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "qnasConnectionIds"
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a31a89cffdaab0b770fbe5c39ae10243",
    "id": null,
    "metadata": {},
    "name": "EditQnaModal_PostQnaMutation",
    "operationKind": "mutation",
    "text": "mutation EditQnaModal_PostQnaMutation(\n  $courseId: ID!\n  $elementId: ID\n  $lessonId: ID\n  $question: String!\n) {\n  Qnas {\n    postQna(input: {courseId: $courseId, elementId: $elementId, lessonId: $lessonId, question: $question}) {\n      qna {\n        node {\n          ...QnaItem_QnaFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment QnaItem_QnaFragment on Qna {\n  answer\n  id\n  isFromCurrentUser\n  isPublic\n  question\n}\n"
  }
};
})();
(node as any).hash = '987768c84cc6c6067e56b517ac7fd581';
export default node;
