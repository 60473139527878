import {Observable, timer} from "rxjs";
import {Action} from "redux";
import {combineEpics, ofType} from "redux-observable";
import {ApiCallFailedAction, Message, postMessageAction} from "@thekeytechnology/framework-react";
import {debounce, filter, map} from "rxjs/operators";
import {AxiosError} from "axios";
import * as Sentry from "@sentry/react";

export const postMessageOnApiError$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(ApiCallFailedAction),
        map((action: Action<any>) => action as ApiCallFailedAction),
        filter((action: ApiCallFailedAction) =>
            (action.error.response && action.error.response.status !== 401) || (
            action.error.isAxiosError && action.error.response === undefined)
        ),
        map((action: ApiCallFailedAction) => {
                let errorKey;
                if (action.error.response) {
                    if ((action.error as AxiosError)?.response?.status === 400) {
                        const axiosError = action.error as AxiosError;
                        Sentry.captureMessage(`Error during API call ${axiosError.response?.status} - ${JSON.stringify(axiosError.response?.data)}`)
                    }

                    errorKey = action.error.response.data.key;
                    if (action.error.response.data.additionalInformation) {
                        errorKey = errorKey + "." + action.error.response.data.additionalInformation;
                    }
                }
                if (errorKey) {
                    return postMessageAction(Message.TYPE_DANGER, "errors." + errorKey);
                } else if (action.error.message) {
                    return postMessageAction(Message.TYPE_DANGER, action.error.message);
                } else {
                    return postMessageAction(Message.TYPE_DANGER, "errors.api-offline");
                }
            }
        ),
        debounce(() => timer(500)),
    );

export const customAPiModuleEpics$ = combineEpics(
    postMessageOnApiError$
);
