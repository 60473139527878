import React from "react";
import {DroppableCloze} from "./DroppableCloze";
import {DraggableWord} from "./DraggableWord";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {ClozeText_ClozeTextElementFragment$key} from "../../../../__generated__/ClozeText_ClozeTextElementFragment.graphql";
import styled from "styled-components";
import {tkColors} from "../../../core/style/tkColors";
import {tkTypography} from "../../../core/style/tkTypography";

const CLOZE_TEXT_CLOZE_TEXT_ELEMENT_FRAGMENT = graphql`
    fragment ClozeText_ClozeTextElementFragment on ClozeTextElement {
        additionalWords
        elementType
        parts {
            partType
            ... on ClozePart {
                cloze
            }
            ... on TextPart {
                text
            }
        }
        image {
            url
        }
        generalSettings {
            points
        }
        id
    }`

interface OwnProps {
    clozeTextElementFragmentRef: ClozeText_ClozeTextElementFragment$key;
    matchings: { [cloze: string]: string };
    selectWord: (word: string) => void;
}

export const ClozeText = ({
                              clozeTextElementFragmentRef,
                              matchings,
                              selectWord
                          }: OwnProps) => {
    const element = useFragment<ClozeText_ClozeTextElementFragment$key>(CLOZE_TEXT_CLOZE_TEXT_ELEMENT_FRAGMENT, clozeTextElementFragmentRef)

    return <div>
        {element.parts.map((part, index) => {
            if (part.partType === 'text') {
                const text: string = part.text!;
                return <ClozeTextElement key={`text-${index}`}>
                    {text.trim() + " "}
                </ClozeTextElement>
            } else if (part.partType === 'cloze') {
                const cloze: string = part.cloze!;
                const currentFill = matchings[cloze]

                return <DroppableCloze
                    key={"dc" + cloze}
                    Fill={currentFill ? <DraggableWord
                        selectWord={selectWord}
                        key={"dwinplace" + index + currentFill}
                        word={currentFill}/> : undefined}
                />
            }
            return null;
        })}
    </div>
}

const ClozeTextElement = styled.span`
  ${tkTypography.large};
  ${tkColors.darkBlue};
  
  line-height: 2.5;
`