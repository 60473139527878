import React from "react";
import {Menu} from "../../menu/components/Menu";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {SubmissionFinishedSidebar_SubmissionFragment$key} from "../../../__generated__/SubmissionFinishedSidebar_SubmissionFragment.graphql";

const SUBMISSION_SIDEBAR_SUBMISSION_FRAGMENT = graphql`
    fragment SubmissionFinishedSidebar_SubmissionFragment on Submission {
        courseRef
        submissionType
        ... on LessonSubmission {
            lesson {
                containerMeta {
                    title
                }
                course {
                    containerMeta {
                        title
                    }
                }
                id
                lessonPath {
                    lessonIndex
                    moduleIndex
                }
            }
            moduleRef
        }
    }`

interface OwnProps {
    submissionFragmentRef: SubmissionFinishedSidebar_SubmissionFragment$key;
}

export const SubmissionFinishedSidebar = ({submissionFragmentRef}: OwnProps) => {
    const submission = useFragment<SubmissionFinishedSidebar_SubmissionFragment$key>(SUBMISSION_SIDEBAR_SUBMISSION_FRAGMENT, submissionFragmentRef)

    const menuItems = [{
        key: "course.finished",
        path: (_ => {
            switch (submission.submissionType) {
                case 'certificationTest':
                case 'learn':
                    return `/courses/${submission.courseRef}/modules/${submission.moduleRef}/lessons/${submission.lesson!.id}`;
                case 'review':
                    return `/courses/${submission.courseRef}/review`;
                default:
                    return '';
            }
        })(),
        icon: ""
    }];

    const text = (_ => {
        switch (submission.submissionType) {
            case 'certificationTest':
            case 'learn':
                return
            case 'review':
                return <div
                    className="tk-white tk-academy-large tk-academy-bold">Review
                </div>
            default:
                return <>
                    <div className="tk-white tk-academy-small-text tk-academy-bold">Mein Kurs</div>
                    <div
                        className="tk-white tk-academy-large tk-academy-bold">{submission.lesson!.course.containerMeta.title}</div>
                    <div className="tk-academy-text tk-academy-bold tk-white"
                         id="info">
                        Lektion {submission.lesson!.lessonPath.moduleIndex + 1}.{submission.lesson!.lessonPath.lessonIndex + 1} | {submission.lesson!.containerMeta.title}
                    </div>
                </>;
        }
    })()

    return <div className={"flex-row flex-nowrap"}>
        <div className="academy-sidebar menu-column h-100 d-flex flex-column">
            <div className="tk-bg-waterblue p-4">{text}</div>
            <div className="tk-bg-waterblue flex-grow-1">
                <Menu items={menuItems}/>
            </div>
        </div>
    </div>;
}
