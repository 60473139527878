import { useSelector } from "react-redux";
import { selectRanksAndStatus } from "@thekeytechnology/thekey-academy-frontend-library";
import React from "react";
import { RankItem } from "./RankItem";

export const RanksDisplay = () => {
    const ranksAndStatus = useSelector(selectRanksAndStatus);

    return <div className="ranks mt-5 tk-academy-card p-4">
        <h2 className="tk-academy-large-bold tk-color-darkblue mb-4">Deine Ränge</h2>
        <div className="rank-item-wrapper d-flex flex-wrap">
            {ranksAndStatus.map((rank, index) => <RankItem key={"rank-" + index} hasRank={rank[0]} rank={rank[1]}/>)}
        </div>
    </div>

}
