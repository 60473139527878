import {PaymentMethod} from "../../../../__generated__/MonthlyPaymentCategory_OrderFragment.graphql";


export const paymentMethodIsMonthlyV3 = (method: PaymentMethod): boolean => {
    switch (method) {
        case "card":
            return false;
        case "sepa":
            return false;
        case "giropay":
            return false;
        case "sofort":
            return false;
        case "paypal":
            return false;
        case "monthlyPartner":
            return true;
        case "monthlyTk":
            return true;
        case "invoicePartner":
            return false;
        case "invoiceTk":
            return false;
    }
    return false;
}


export const paymentMethodNeedsSepaMandateV3 = (method: PaymentMethod): boolean => {
    switch (method) {
        case "card":
            return false;
        case "sepa":
            return true;
        case "giropay":
            return false;
        case "sofort":
            return false;
        case "paypal":
            return false;
        case "monthlyPartner":
            return true;
        case "monthlyTk":
            return true;
        case "invoicePartner":
            return false;
        case "invoiceTk":
            return false;
    }
    return false;
}
