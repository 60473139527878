/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LearnHeader_LessonFragment = {
    readonly id: string;
    readonly lessonPath: {
        readonly courseRef: string;
        readonly moduleRef: string;
    };
    readonly " $refType": "LearnHeader_LessonFragment";
};
export type LearnHeader_LessonFragment$data = LearnHeader_LessonFragment;
export type LearnHeader_LessonFragment$key = {
    readonly " $data"?: LearnHeader_LessonFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LearnHeader_LessonFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LearnHeader_LessonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LessonPath",
      "kind": "LinkedField",
      "name": "lessonPath",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "courseRef",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleRef",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Lesson",
  "abstractKey": "__isLesson"
};
(node as any).hash = 'b8abdffb97478df4323cc671e0e99772';
export default node;
