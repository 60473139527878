/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LessonCard_LessonFragment = {
    readonly containerMeta: {
        readonly description: string;
        readonly points: number;
        readonly title: string;
    };
    readonly course: {
        readonly id: string;
    };
    readonly id: string;
    readonly image: {
        readonly " $fragmentRefs": FragmentRefs<"LessonImage_ImageFragment">;
    } | null;
    readonly lessonPath: {
        readonly lessonIndex: number;
        readonly moduleIndex: number;
    };
    readonly " $refType": "LessonCard_LessonFragment";
};
export type LessonCard_LessonFragment$data = LessonCard_LessonFragment;
export type LessonCard_LessonFragment$key = {
    readonly " $data"?: LessonCard_LessonFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LessonCard_LessonFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonCard_LessonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContainerMeta",
      "kind": "LinkedField",
      "name": "containerMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "points",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "course",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LessonImage_ImageFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LessonPath",
      "kind": "LinkedField",
      "name": "lessonPath",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lessonIndex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleIndex",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Lesson",
  "abstractKey": "__isLesson"
};
})();
(node as any).hash = '22516da4bce16596dc4a784b47772ac5';
export default node;
