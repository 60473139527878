/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LessonStateStatus = "current" | "finished" | "%future added value";
export type NextLessonButton_CourseStateFragment = {
    readonly course: {
        readonly lessons: ReadonlyArray<{
            readonly id: string;
            readonly lessonPath: {
                readonly moduleRef: string;
            };
        }>;
    };
    readonly courseRef: string;
    readonly lessonStates: ReadonlyArray<{
        readonly status: LessonStateStatus;
        readonly lessonRef: string;
    }>;
    readonly " $refType": "NextLessonButton_CourseStateFragment";
};
export type NextLessonButton_CourseStateFragment$data = NextLessonButton_CourseStateFragment;
export type NextLessonButton_CourseStateFragment$key = {
    readonly " $data"?: NextLessonButton_CourseStateFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"NextLessonButton_CourseStateFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NextLessonButton_CourseStateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "course",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "lessons",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LessonPath",
              "kind": "LinkedField",
              "name": "lessonPath",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "moduleRef",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "courseRef",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LessonState",
      "kind": "LinkedField",
      "name": "lessonStates",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lessonRef",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};
(node as any).hash = 'b1ca546eb3cbd33dfd7398e89b05c272';
export default node;
