import {useFormik} from "formik";
import React, {useContext, useEffect} from "react";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {SignupFormPartV3} from "./SignupFormPartV3";
import {useFragment, useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {PrivateForm_UserFragment$key} from "../../../../../../__generated__/PrivateForm_UserFragment.graphql";
import {OrderFlowContext} from "../../../hooks/OrderFlowContext";
import moment from "moment";
import {
    PrivateForm_BillingDetailsFragment$key
} from "../../../../../../__generated__/PrivateForm_BillingDetailsFragment.graphql";
import {
    PrivateForm_UpdateBillingDetailsMutation,
    SignupInput
} from "../../../../../../__generated__/PrivateForm_UpdateBillingDetailsMutation.graphql";
import {StyledForm} from "../../../../../theme/Form";
import {ValidatedFieldV2} from "../../../../../core/components/form/ValidatedFieldV2";
import {SimpleOptionSelect} from "../../../../../core/components/select/SimpleOptionSelect";
import {DateInput} from "../../../../../certificate/components/DateInput";
import {trackRegistration} from "../../../../../analytics/analytics";

const BILLING_DETAILS_FRAGMENT = graphql`
    fragment PrivateForm_BillingDetailsFragment on BillingDetails {
        salutation,
        firstName,
        lastName,
        street
        houseNumber
        postalCode
        city
        dateOfBirth,
    }`

const USER_FRAGMENT = graphql`
    fragment PrivateForm_UserFragment on User {
        id
        email
        meta {
            firstName
            lastName
        }
    }`

const UPDATE_BILLING_DETAILS_MUTATION = graphql`
    mutation PrivateForm_UpdateBillingDetailsMutation($billingDetails: UpdateBillingDetailsV2Input!) {
        Billing {
            updateBillingDetailsV2(input: $billingDetails) {
                order {
                    purchaser {
                        userRef
                    }
                    ...OrderFlowScreen_OrderFragment
                }
            }
        }
    }`

interface OwnProps {
    orderId: string
    billingDetailsFragmentRef: PrivateForm_BillingDetailsFragment$key | null
    userDataFragmentRef: PrivateForm_UserFragment$key | null
}

interface PrivateFormState {
    userId?: string,
    salutation: string
    firstName: string
    lastName: string
    street: string
    houseNumber: string
    postalCode: string
    city: string
    email: string,
    email2: string,
    password: string,
    password2: string,
    legal: boolean,
    dateOfBirth?: Date,
    phoneNumber?: string,
    adsOptIn: Boolean
}

export const PrivateForm = ({orderId, billingDetailsFragmentRef, userDataFragmentRef}: OwnProps) => {
    const {t} = useTranslation("billing")

    const {setSubmitBillingForm, advance} = useContext(OrderFlowContext)
    const billingDetails = useFragment<PrivateForm_BillingDetailsFragment$key>(BILLING_DETAILS_FRAGMENT, billingDetailsFragmentRef)
    const user = useFragment<PrivateForm_UserFragment$key>(USER_FRAGMENT, userDataFragmentRef)

    const [updateBillingDetails, isInFlight] = useMutation<PrivateForm_UpdateBillingDetailsMutation>(UPDATE_BILLING_DETAILS_MUTATION)

    const formikConfig = useFormik<PrivateFormState>({
        initialValues: {
            userId: user?.id,
            salutation: billingDetails?.salutation || "",
            firstName: user?.meta?.firstName || billingDetails?.firstName || "",
            lastName: user?.meta?.lastName || billingDetails?.lastName || "",
            street: billingDetails?.street || "",
            houseNumber: billingDetails?.houseNumber || "",
            postalCode: billingDetails?.postalCode || "",
            city: billingDetails?.city || "",
            phoneNumber: "",
            email: "",
            email2: "",
            password: "",
            password2: "",
            legal: false,
            dateOfBirth: billingDetails?.dateOfBirth ? moment(billingDetails.dateOfBirth as string).toDate() : undefined,
            adsOptIn: false,
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            salutation: Yup
                .string()
                .required(t("core:forms.required-field", {fieldName: t("checkout-form.salutation")})),
            firstName: Yup
                .string()
                .required(t("core:forms.required-field", {fieldName: t("checkout-form.first-name")})),
            lastName: Yup
                .string()
                .required(t("core:forms.required-field", {fieldName: t("checkout-form.last-name")})),
            street: Yup
                .string()
                .required(t("core:forms.required-field", {fieldName: t("checkout-form.street")})),
            houseNumber: Yup
                .string()
                .max(8, t("core:forms.max-length-housenumber"))
                .required(t("core:forms.required-field", {fieldName: t("checkout-form.house-number")})),
            postalCode: Yup
                .string()
                .matches(new RegExp("^[0-9]{5}$"), "Das ist keine gültige PLZ")
                .required(t("core:forms.required-field", {fieldName: t("checkout-form.postal-code")})),
            city: Yup
                .string()
                .required(t("core:forms.required-field", {fieldName: t("checkout-form.city")})),
            email: Yup.string()
                .email(t("checkout-form.email-error"))
                .test("test", "test", function (value) {
                    if (!user && !value) {
                        return this.createError({
                            path: "email",
                            message: t("core:forms.required-field", {fieldName: t("checkout-form.email")})
                        });
                    }
                    return true;
                }),
            email2: Yup.string()
                .oneOf([Yup.ref("email")], t("checkout-form.email2-error")),
            password: Yup
                .string()
                .min(8, t("checkout-form.password-error"))
                .test("test", "test", function (value) {
                    if (!user && !value) {
                        return this.createError({
                            path: "password",
                            message: t("core:forms.required-field", {fieldName: t("checkout-form.password")})
                        });
                    }
                    return true;
                }),
            password2: Yup.string()
                .oneOf([Yup.ref("password")], t("checkout-form.password2-error")),
            legal: Yup
                .boolean()
                .test("test", "test", function (value) {
                    if (!user && !value) {
                        return this.createError({
                            path: "legal",
                            message: t("core:forms.required-field", {fieldName: "Nutzungsbedingungen & Datenschutz"})
                        });
                    }
                    return true;
                }),
            dateOfBirth: Yup
                .string()
                .required(t("core:forms.required-field", {fieldName: "Geburtsdatum"})),
            phoneNumber: Yup.string().phone("DE", "Bitte gib eine gültige Telefonnummer ein")

        }),
        onSubmit: (values, {setSubmitting}) => {
            updateBillingDetails({
                variables: {
                    billingDetails: {
                        orderId: orderId,
                        billingDetailsV3: {
                            customerType: "private",
                            salutation: values.salutation,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            street: values.street,
                            city: values.city,
                            postalCode: values.postalCode,
                            houseNumber: values.houseNumber,
                            dateOfBirth: moment(values.dateOfBirth as any as Date).format("yyyy-MM-DD"),
                            phoneNumber: values.phoneNumber,
                            invoiceEmail: user?.email || values.email
                        },
                        signup: user ? undefined : {
                            email: values.email!,
                            firstName: values.firstName!,
                            lastName: values.lastName!,
                            password: values.password!,
                            adsOptIn: values.adsOptIn
                        } as SignupInput
                    }
                },
                onCompleted: (response) => {
                    advance()

                    const base64EncodedUserId = response.Billing.updateBillingDetailsV2?.order.purchaser?.userRef
                    if (!user && base64EncodedUserId) trackRegistration(values.email, values.firstName, values.lastName, base64EncodedUserId)
                }
            })
            setSubmitting(false);
        }
    })

    useEffect(() => {
        setSubmitBillingForm(formikConfig.handleSubmit)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return isInFlight ? <div>Lade...</div> :
        <StyledForm>
            <form onSubmit={formikConfig.handleSubmit}>
                <div className="tk-form-row">
                    <div className="form-group">
                        <ValidatedFieldV2<PrivateFormState, string>
                            name={"salutation"} formikConfig={formikConfig}
                            required={true}
                            component={(fieldValue, updateField, name) =>
                                <SimpleOptionSelect<string>
                                    value={fieldValue}
                                    name={name}
                                    options={[
                                        {
                                            value: "mr",
                                            label: "Herr"
                                        },
                                        {
                                            value: "mrs",
                                            label: "Frau"
                                        },
                                        {
                                            value: "divers",
                                            label: "Divers"
                                        }
                                    ]}
                                    placeholder={t("checkout-form.salutation")}
                                    setValue={updateField as (value: string | string[]) => void}
                                />
                            }/>
                    </div>
                </div>
                <div className="tk-form-row">
                    <div className="form-group">
                        <ValidatedFieldV2<PrivateFormState, string>
                            name={"firstName"} formikConfig={formikConfig}
                            required={true}
                            component={(fieldValue, updateField, name, required) =>
                                <input name={name}
                                       className="form-control default-input"
                                       placeholder={t("checkout-form.first-name")}
                                       required={required}
                                       type="text"
                                       value={fieldValue}
                                       onChange={event => updateField(event.target.value)}
                                />
                            }/>
                    </div>
                    <div className="form-group">
                        <ValidatedFieldV2<PrivateFormState, string>
                            name={"lastName"} formikConfig={formikConfig}
                            required={true}
                            component={(fieldValue, updateField, name, required) =>
                                <input name={name}
                                       className="form-control default-input"
                                       placeholder={t("checkout-form.last-name")}
                                       required={required}
                                       type="text"
                                       value={fieldValue}
                                       onChange={event => updateField(event.target.value)}
                                />
                            }/>
                    </div>
                </div>

                <div className="tk-form-row">
                    <div className="form-group">
                        <ValidatedFieldV2<PrivateFormState, string>
                            name={"street"} formikConfig={formikConfig}
                            required={true}
                            component={(fieldValue, updateField, name, required) =>
                                <input name={name}
                                       className="form-control default-input"
                                       placeholder={t("checkout-form.street")}
                                       required={required}
                                       type="text"
                                       value={fieldValue}
                                       onChange={event => updateField(event.target.value)}
                                />
                            }/>
                    </div>
                    <div className="form-group">
                        <ValidatedFieldV2<PrivateFormState, string>
                            name={"houseNumber"} formikConfig={formikConfig}
                            required={true}
                            component={(fieldValue, updateField, name, required) =>
                                <input name={name}
                                       className="form-control default-input"
                                       placeholder={t("checkout-form.house-number")}
                                       required={required}
                                       value={fieldValue}
                                       type="text"
                                       onChange={event => updateField(event.target.value)}
                                />
                            }/>
                    </div>
                </div>

                <div className="tk-form-row">
                    <div className="form-group">
                        <ValidatedFieldV2<PrivateFormState, string>
                            name={"postalCode"} formikConfig={formikConfig}
                            required={true}
                            component={(fieldValue, updateField, name, required) =>
                                <input name={name}
                                       className="form-control default-input"
                                       placeholder={t("checkout-form.postal-code")}
                                       required={required}
                                       value={fieldValue}
                                       type="text"
                                       onChange={event => updateField(event.target.value)}
                                />
                            }/>
                    </div>
                    <div className="form-group">
                        <ValidatedFieldV2<PrivateFormState, string>
                            name={"city"} formikConfig={formikConfig}
                            required={true}
                            component={(fieldValue, updateField, name, required) =>
                                <input name={name}
                                       className="form-control default-input"
                                       placeholder={t("checkout-form.city")}
                                       required={required}
                                       value={fieldValue}
                                       type="text"
                                       onChange={event => updateField(event.target.value)}
                                />
                            }/>
                    </div>
                </div>

                <div className="tk-form-row">
                    <div className="form-group validated-field">
                        <input name={"country"}
                               className="form-control default-input tk-cursor-na"
                               disabled={true}
                               value={"Deutschland"}
                               type="text"
                        />
                    </div>
                    <div className="form-group">
                        <ValidatedFieldV2<PrivateFormState, Date>
                            name={"dateOfBirth"} formikConfig={formikConfig}
                            required={true}
                            helpText={"Dies wird für Dein offizielles IHK Zertifikat benötigt."}
                            component={(fieldValue, updateField) => <DateInput
                                value={fieldValue}
                                placeholder={"Geburtsdatum"}
                                updateValue={date => updateField(date)}
                            />}/>
                    </div>
                </div>
                <div className="tk-form-row">
                    <div className="form-group">
                        <ValidatedFieldV2<PrivateFormState, string>
                            name={"phoneNumber"}
                            formikConfig={formikConfig}
                            helpText={"Ausschließlich verwendet für Service-Zwecke, z.B. in Bezug auf Dein IHK-Zertifikat."}
                            component={(fieldValue, updateField, name, required) =>
                                <input name={name}
                                       className="form-control default-input"
                                       placeholder={t("checkout-form.phone-number")}
                                       required={required}
                                       value={fieldValue}
                                       type="text"
                                       onChange={event => updateField(event.target.value)}
                                />
                            }/>
                    </div>
                </div>

                {!user && <SignupFormPartV3 formikConfig={formikConfig}/>}
            </form>
        </StyledForm>
}
