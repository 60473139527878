import React from 'react';
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {CustomerMessageDefinitionButton_CustomerMessageDefinitionButtonFragment$key} from "../../../../../__generated__/CustomerMessageDefinitionButton_CustomerMessageDefinitionButtonFragment.graphql";
import {LinkButton} from "./LinkButton";
import {ConfirmButton} from "./ConfirmButton";
import styled from "styled-components";

const CUSTOMER_MESSAGE_DEFINITION_BUTTON_FRAGMENT = graphql`
    fragment CustomerMessageDefinitionButton_CustomerMessageDefinitionButtonFragment on CustomerMessageDefinitionButtonInterface {
        type
        ... on CustomerMessageDefinitionLinkButton {
            ...LinkButton_CustomerMessageDefinitionLinkButtonFragment
        }
        ... on CustomerMessageDefinitionConfirmButton {
            ...ConfirmButton_CustomerMessageDefinitionConfirmButtonFragment
        }
    }`

interface OwnProps {
    onClose: () => void
    modalWidth: number
    customerMessageDefinitionButtonFragmentRef?: CustomerMessageDefinitionButton_CustomerMessageDefinitionButtonFragment$key | null
}

export const CustomerMessageDefinitionButton = ({
                                                    onClose,
                                                    modalWidth,
                                                    customerMessageDefinitionButtonFragmentRef
                                                }: OwnProps) => {
    const button = useFragment<CustomerMessageDefinitionButton_CustomerMessageDefinitionButtonFragment$key>(CUSTOMER_MESSAGE_DEFINITION_BUTTON_FRAGMENT, customerMessageDefinitionButtonFragmentRef || null)

    const chooseButton = () => {
        switch (button?.type) {
            case "link":
                return <LinkButton onClose={onClose} modalWidth={modalWidth}
                                   customerMessageDefinitionLinkButtonFragmentRef={button}/>
            case "confirm":
                return <ConfirmButton onClick={onClose} modalWidth={modalWidth}
                                      customerMessageDefinitionConfirmButtonFragmentRef={button}/>
            default:
                return null;
        }
    }

    return <ButtonContainer modalWidth={modalWidth}>{chooseButton()}</ButtonContainer>
}

const ButtonContainer = styled.div<{ modalWidth: number }>`
    position: absolute;
    bottom: ${props => props.modalWidth * 0.0625}px;
    display: flex;
    width: ${props => props.modalWidth}px;
`
