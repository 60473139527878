/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ImageElementDisplay_ImageElementFragment = {
    readonly image: {
        readonly url: string | null;
    } | null;
    readonly title: string;
    readonly " $refType": "ImageElementDisplay_ImageElementFragment";
};
export type ImageElementDisplay_ImageElementFragment$data = ImageElementDisplay_ImageElementFragment;
export type ImageElementDisplay_ImageElementFragment$key = {
    readonly " $data"?: ImageElementDisplay_ImageElementFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ImageElementDisplay_ImageElementFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ImageElementDisplay_ImageElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "ImageElement",
  "abstractKey": null
};
(node as any).hash = 'cdef3d8d76a4462cba2f30b8ed592b32';
export default node;
