import React, {useContext} from "react";
import {ElementFlowContext} from "@thekeytechnology/thekey-academy-frontend-library"
import {ElementDisplay} from "../../../elementFlow/components/ElementDisplay";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {Screen} from "../../../core/components/screen/Screen";
import {ReviewSidebar} from "./ReviewSidebar";
import {ReviewFooter} from "./ReviewFooter";
import {BlueBackground} from "../../../core/components/screen/BlueBackground";
import {ReviewHeader} from "./ReviewHeader";
import {ReviewContentArea} from "./ReviewContentArea";
import {Prompt} from "react-router-dom";
import {ReviewProcessCurrentElementScreen_ElementsConnectionFragment$key} from "../../../../__generated__/ReviewProcessCurrentElementScreen_ElementsConnectionFragment.graphql";
import {ReviewProcessCurrentElementScreen_CourseFragment$key} from "../../../../__generated__/ReviewProcessCurrentElementScreen_CourseFragment.graphql";
import {useTranslation} from "react-i18next";
import {LoadingFakeSubmitLessonOrReviewScreen} from "../../../core/loading/flow/submission/LoadingFakeSubmitLessonOrReviewScreen";

const COURSE_FRAGMENT = graphql`
    fragment ReviewProcessCurrentElementScreen_CourseFragment on Course {
        ...ReviewContentArea_CourseFragment
        ...ReviewHeader_CourseFragment
        ...ReviewSidebar_CourseFragment
    }`

const ELEMENTS_CONNECTION_FRAGMENT = graphql`
    fragment ReviewProcessCurrentElementScreen_ElementsConnectionFragment on ElementsConnection {
        edges {
            node {
                id
                ...ElementDisplay_ElementFragment
                ...ReviewContentArea_ElementFragment
                ...ReviewHeader_ElementFragment
                ...ReviewSidebar_ElementFragment
            }
        }
    }`

interface OwnProps {
    courseFragmentRef: ReviewProcessCurrentElementScreen_CourseFragment$key;
    elementsConnectionFragmentRef: ReviewProcessCurrentElementScreen_ElementsConnectionFragment$key;
}

export const ReviewProcessCurrentElementScreen = ({courseFragmentRef, elementsConnectionFragmentRef}: OwnProps) => {
    const {t} = useTranslation("review")

    const {flowState: {currentElementId, isFinished}} = useContext(ElementFlowContext)

    const course = useFragment<ReviewProcessCurrentElementScreen_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef)

    const elements = useFragment<ReviewProcessCurrentElementScreen_ElementsConnectionFragment$key>(ELEMENTS_CONNECTION_FRAGMENT, elementsConnectionFragmentRef)

    const element = elements.edges?.filter(e => !!e).find(e => e!.node.id === currentElementId)?.node

    if (isFinished && !element) return <LoadingFakeSubmitLessonOrReviewScreen/>

    return element ? <Screen
        sidebarSlot={<ReviewSidebar courseFragmentRef={course} elementFragmentRef={element}/>}
        floatingFooterSlot={<ReviewFooter/>}
        headerBarBackgroundSlot={<BlueBackground
            buttonSlot={<ReviewHeader courseFragmentRef={course} elementFragmentRef={element}/>} height={90}/>}
        contentAreaRenderer={(children) => <ReviewContentArea courseFragmentRef={course}
                                                              elementFragmentRef={element}>{children}</ReviewContentArea>}
    >
        <Prompt
            message={location => {
                if (!location.pathname.includes("review")) {
                    return t("review-screen.exit-dialog.question") + " " + t("review-screen.exit-dialog.explanation")
                }
                return true
            }}
        />
        <ElementDisplay elementFragmentRef={element}/>
    </Screen> : null
}
