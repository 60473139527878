import React, {Suspense} from "react";
import {SubmissionFinishedScreen} from "./components/SubmissionFinishedScreen";
import {LoadingFakeSubmissionScreen} from "../core/loading/flow/submission/LoadingFakeSubmissionScreen";
import {RouteAuthState, RouteDefinition} from "../core/components/routing/RouteDefinition";

export const SubmissionRouteDefinitions: RouteDefinition[] = [
    {
        path: "/courses/:courseId/submissions/:submissionId",
        component: () => <Suspense fallback={<LoadingFakeSubmissionScreen/>}>
                <SubmissionFinishedScreen/>
            </Suspense>,
        authState: RouteAuthState.OnlyLoggedIn
    }
]