import {motion} from "framer-motion";
import Bronze from "../../../assets/images/bronze.png";
import Silver from "../../../assets/images/silber.png";
import Gold from "../../../assets/images/gold.png";
import React from "react";
import {useIncrementalMedalLogic} from "@thekeytechnology/thekey-academy-frontend-library";
import {PointsCircle} from "../../core/components/radial-progress/PointsCircle";

interface OwnProps {
    pointsAwarded: number;
    possiblePoints: number;
}

export const PointsAndMedal = ({
                                   pointsAwarded,
                                   possiblePoints
                               }: OwnProps) => {

    const [medal, upgradeMedal] = useIncrementalMedalLogic(pointsAwarded, possiblePoints)
    const transition = {type: "spring", stiffness: 1000, damping: 25};

    return <div className="d-flex justify-content-center pt-5 position-relative mb-5">
        {medal === "bronze" ? <motion.div
            className="top-0 position-absolute zIndex10"
            style={{top: 0, position: "absolute", zIndex: 10}}
            initial={{y: 10}}
            animate={{y: 0}}
            onAnimationComplete={() => upgradeMedal()}
            transition={transition}>
            <img alt={"Bronze"} style={{width: 100}} src={Bronze}/>
        </motion.div> : null}
        {medal === "silver" ? <motion.div
            className="top-0 position-absolute zIndex10"
            initial={{y: 10}}
            animate={{y: 0}}
            onAnimationComplete={() => upgradeMedal()}
            transition={transition}>
            <img alt={"Silver"} style={{width: 100}} src={Silver}/>
        </motion.div> : null}
        {medal === "gold" ? <motion.div
            className="top-0 position-absolute zIndex10"
            initial={{y: 10}}
            animate={{y: 0}}
            onAnimationComplete={() => upgradeMedal()}
            transition={transition}>
            <img alt={"Gold"} style={{width: 100}} src={Gold}/>
        </motion.div> : null}
        <PointsCircle pointsAwarded={pointsAwarded} pointsPossible={possiblePoints}/>
    </div>
}
