import React from "react";

import "./customer-type-select.scss";
import {CustomerType} from "../../../../../../__generated__/BillingDetailsFormV3_OrderFragment.graphql";


interface OwnProps {
    className?: string
    customerType: CustomerType;
    forcedCustomerType?: CustomerType;
    updateCustomerType: (customerType: CustomerType) => void;
}

export const CustomerTypeSelector = ({
                                         className,
                                         customerType,
                                         forcedCustomerType,
                                         updateCustomerType
                                     }: OwnProps) => {

    return <div className={`d-flex align-items-center customer-type-select-field ${className || ""}`}>
        <label htmlFor="private"
               className={`radio-wrapper d-flex align-items-center tk-academy-text tk-academy-bold tk-black mr-5 ${forcedCustomerType === "business" ? "disabled" : ""}`}>
            <input type="radio"
                   disabled={forcedCustomerType === "business"}
                   id="private"
                   name="customerType"
                   value={"private"}
                   checked={customerType === "private"}
                   className="mr-2"
                   onChange={() => {
                       updateCustomerType("private")
                   }}
            />
            <span className="checkmark"/>
            <span className="tk-academy-text tk-academy-bold tk-black">Privatkund:in</span>
        </label>
        <label htmlFor="business"
               className={`radio-wrapper d-flex align-items-center ${forcedCustomerType === "private" ? "disabled" : ""}`}>
            <input type="radio"
                   id="business"
                   disabled={forcedCustomerType === "private"}
                   name="customerType"
                   value={"business"}
                   checked={customerType === "business"}
                   className="mr-2"
                   onChange={() => {
                       updateCustomerType("business")
                   }}
            />
            <span className="checkmark"/>
            <span className="tk-academy-text tk-academy-bold tk-black">Geschäftskund:in</span>
        </label>
    </div>
}
