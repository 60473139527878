import React, {useEffect, useState} from "react";
import {Form, Formik} from "formik";
import * as yup from "yup";
import * as Yup from "yup";
import {ValidatedField} from "../../core/components/form/ValidatedField";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {SimpleOptionSelectField} from "../../core/components/select/SimpleOptionSelectField";
import Countries from "../../core/countries.json";
import "./order-certificate-modal.scss"
import {AcademyButton} from "../../core/components/button/AcademyButton";
import {DateField} from "./DateField";
import moment from "moment";
import {BasicModal} from "../../core/components/modal/BasicModal";
import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {OrderCertificateModal_OrderCertificateMutation} from "../../../__generated__/OrderCertificateModal_OrderCertificateMutation.graphql";
import {Message, postMessageAction} from "@thekeytechnology/framework-react";
import {fetchUserMetaV2, selectUserMetaV2} from "@thekeytechnology/thekey-academy-frontend-library";

const ORDER_CERTIFICATE_MUTATION = graphql`
    mutation OrderCertificateModal_OrderCertificateMutation($input: OrderCertificateInput!) {
        Certificate {
            orderCertificate(input: $input) {
                courseState {
                    id
                    ...CourseDetailScreen_CourseStateFragment
                    ...LessonDetailScreen_CourseStateFragment
                }
            }
        }
    }`

interface OwnProps {
    courseId: string;
    closeModal: () => void;
}

export const OrderCertificateModal = ({courseId, closeModal}: OwnProps) => {
    const {t} = useTranslation(["certficate", "core"])

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUserMetaV2())
    }, [dispatch])

    const userMeta = useSelector(selectUserMetaV2)

    const [orderCertificate, isInFlight] = useMutation<OrderCertificateModal_OrderCertificateMutation>(ORDER_CERTIFICATE_MUTATION)

    const [orderSuccessful, setOrderSuccessful] = useState<boolean>(false);

    return <BasicModal size={"lg"} centered={true} onHide={() => closeModal()} dialogClassName="order-certificate-modal"
                       show={true}>
        <div className="celebration-modal-content tk-bg-light">
            <Formik
                initialValues={{
                    title: null,
                    birthday: userMeta?.entity.certificateIssuingData?.birthday ? moment(userMeta?.entity.certificateIssuingData?.birthday).toDate() : undefined,
                    salutation: userMeta?.entity.certificateIssuingData?.deliveryData.salutation,
                    firstName: userMeta?.entity.certificateIssuingData?.deliveryData.firstName,
                    lastName: userMeta?.entity.certificateIssuingData?.deliveryData.lastName,
                    street: userMeta?.entity.certificateIssuingData?.deliveryData.street,
                    houseNumber: userMeta?.entity.certificateIssuingData?.deliveryData.houseNumber,
                    postalCode: userMeta?.entity.certificateIssuingData?.deliveryData.postalCode,
                    city: userMeta?.entity.certificateIssuingData?.deliveryData.city,
                    country: userMeta?.entity.certificateIssuingData?.deliveryData.country,
                }}
                enableReinitialize={true}
                validationSchema={
                    yup.object().shape({
                        birthday: yup
                            .string()
                            .required(t('core:forms.required-field', {fieldName: 'Geburtstag'})),
                        firstName: Yup
                            .string()
                            .required(t("core:forms.required-field", {fieldName: "Vorname"})),
                        lastName: Yup
                            .string()
                            .required(t("core:forms.required-field", {fieldName: "Nachname"})),
                        street: Yup
                            .string()
                            .required(t("core:forms.required-field", {fieldName: "Straße"})),
                        houseNumber: Yup
                            .string()
                            .required(t("core:forms.required-field", {fieldName: "Hausnummer"})),
                        postalCode: Yup
                            .string()
                            .required(t("core:forms.required-field", {fieldName: "PLZ"})),
                        city: Yup
                            .string()
                            .required(t("core:forms.required-field", {fieldName: "Ort"})),
                        country: Yup
                            .string()
                            .required(t("core:forms.required-field", {fieldName: "Land"})),
                    })
                }
                onSubmit={async (values, formikHelpers) => {
                    const birthday = moment(values.birthday as any as Date).format("yyyy-MM-DD");

                    orderCertificate({
                        variables: {
                            input: {
                                firstName: values.firstName!,
                                lastName: values.lastName!,
                                street: values.street!,
                                houseNumber: values.houseNumber!,
                                postalCode: values.postalCode!,
                                city: values.city!,
                                country: values.country!,
                                birthday: birthday,
                                salutation: values.salutation!,
                                title: values.title,
                                courseId: courseId
                            }
                        },
                        onCompleted: () => {
                            setOrderSuccessful(true);
                            dispatch(postMessageAction(Message.TYPE_SUCCESS, "Du hast Dein Zertifikat erfolgreich angefordert. Weitere Informationen erhältst Du per E-Mail."));
                        }
                    })
                }}
            >
                {formikState => {
                    return (
                        <Form>
                            <div>
                                <h1 className="tk-academy-extra-large-heading-bold tk-color-darkblue text-center mb-2">Dein
                                    IHK-Zertifikat</h1>

                                <p className="tk-academy-text tk-color-darkblue mb-2">Durch Deine Leistungen hast Du Dir
                                    Dein IHK-Zertifikat
                                    nun mehr als verdient! Damit
                                    es
                                    erstens prüfsicher auf Dich personalisiert werden kann und zweitens den Weg zu Dir
                                    nach
                                    Hause findet, benötigen wir noch folgende Daten von Dir. Bitte trage diese in das
                                    Formular ein und bestätige mit „Abschicken”. Dein IHK-Zertifikat wird spätestens innerhalb der nächsten 30 Tage bei Dir eintreffen. </p>
                                <div
                                    className="tk-academy-small-text tk-academy-semibold tk-color-waterblue-80 mb-2">
                                    Daten für das Zertifikat
                                </div>
                                <div className="form-group">
                                    <ValidatedField
                                        disabled={orderSuccessful}
                                        name="birthday"
                                        component={DateField}
                                        placeholder={"Geburtstag"}
                                        className="form-control default-input"
                                        formikState={formikState}
                                        required
                                    />
                                </div>
                                <div
                                    className="tk-academy-small-text tk-academy-semibold tk-color-waterblue-80 mb-2">
                                    Privatadresse
                                </div>
                                <div className="form-group">
                                    <ValidatedField
                                        disabled={orderSuccessful}
                                        name="title"
                                        type="text"
                                        placeholder={"Titel"}
                                        className="form-control default-input"
                                        formikState={formikState}/>
                                </div>
                                <div className="form-group">
                                    <ValidatedField
                                        disabled={orderSuccessful}
                                        formikState={formikState}
                                        placeholder={"Anrede"}
                                        name="salutation"
                                        component={SimpleOptionSelectField}
                                        options={[
                                            {
                                                value: "mr",
                                                label: "Herr"
                                            },
                                            {
                                                value: "mrs",
                                                label: "Frau"
                                            },
                                            {
                                                value: "neutral",
                                                label: "Neutral"
                                            }
                                        ]}
                                        className="form-control default-input"
                                        required/>
                                </div>
                                <div className="form-group two-column-row mb-4">
                                    <ValidatedField
                                        disabled={orderSuccessful}
                                        name="firstName"
                                        type="text"
                                        placeholder={"Vorname"}
                                        className="form-control default-input"

                                        formikState={formikState}/>
                                    <ValidatedField
                                        disabled={orderSuccessful}
                                        name="lastName"
                                        type="text"
                                        placeholder={"Nachname"}
                                        formikState={formikState}
                                        className="form-control default-input"
                                    />
                                </div>
                                <div className="form-group">
                                    <ValidatedField
                                        disabled={orderSuccessful}

                                        formikState={formikState}
                                        placeholder={"Land"}
                                        name="country"
                                        component={SimpleOptionSelectField}
                                        options={
                                            Object.entries(Countries).map(entry => {
                                                return {
                                                    value: entry[0].toLowerCase(),
                                                    label: entry[1]
                                                }
                                            })
                                        }
                                        className="form-control default-input"
                                        required/>
                                </div>
                                <div className="form-group two-column-row">
                                    <ValidatedField
                                        disabled={orderSuccessful}
                                        formikState={formikState}
                                        placeholder={"Straße"}
                                        name="street"
                                        type="text"
                                        className="form-control default-input"
                                        required
                                    />
                                    <ValidatedField
                                        disabled={orderSuccessful}
                                        formikState={formikState}
                                        placeholder={"Hausnummer"}
                                        name="houseNumber"
                                        type="text"
                                        className="form-control default-input"
                                        required/>
                                </div>
                                <div className="form-group two-column-row">
                                    <ValidatedField
                                        disabled={orderSuccessful}
                                        formikState={formikState}
                                        placeholder={"PLZ"}
                                        name="postalCode"
                                        type="text"
                                        className="form-control default-input"
                                        required
                                    />
                                    <ValidatedField
                                        disabled={orderSuccessful}
                                        formikState={formikState}
                                        placeholder={"Ort"}
                                        name="city"
                                        type="text"
                                        className="form-control default-input"
                                        required/>
                                </div>

                                {orderSuccessful ?
                                    <AcademyButton className="ml-auto" type="button" onClick={() => closeModal()}
                                                   text={"Zertifkat erfolgreich angefordert!"}/> :
                                    <AcademyButton disabled={isInFlight || formikState.isSubmitting}
                                                   className="ml-auto" type="submit"
                                                   text={"Abschicken"}/>
                                }
                            </div>

                        </Form>)
                }}
            </Formik>
        </div>
    </BasicModal>
}
