import {VideoElementDisplay} from "./video/VideoElementDisplay";
import {TextElementDisplay} from "./text/TextElementDisplay";
import {MultipleChoiceElementDisplay} from "./multiple-choice/MultipleChoiceElementDisplay";
import {ImageElementDisplay} from "./image/ImageElementDisplay";
import {ClozeTextElementDisplay} from "./cloze-text/ClozeTextElementDisplay";
import React from "react";
import {OrderElementDisplay} from "./order/OrderElementDisplay";
import {ResolutionElementDisplay} from "./resolution/ResolutionElementDisplay";
import {QuestionnaireElementDisplay} from "./questionnaire/QuestionnaireElementDisplay";
import {PersologElementDisplay} from "./persolog/PersologElementDisplay";
import {ElementDisplay_ElementFragment$key} from "../../../__generated__/ElementDisplay_ElementFragment.graphql";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";

const ELEMENT_DISPLAY_ELEMENT_FRAGMENT = graphql`
    fragment ElementDisplay_ElementFragment on Element {
        elementType
        rawId
        ... on ClozeTextElement {
            ...ClozeTextElementDisplay_ClozeTextElementFragment
        }
        ... on ImageElement {
            ...ImageElementDisplay_ImageElementFragment
        }
        ... on MultipleChoiceElement {
            ...MultipleChoiceElementDisplay_MultipleChoiceElementFragment
        }
        ... on OrderElement {
            ...OrderElementDisplay_OrderElementFragment
        }
        ... on PersologElement {
            ...PersologElementDisplay_PersologElementFragment
        }
        ... on QuestionnaireElement {
            ...QuestionnaireElementDisplay_QuestionnaireElementFragment
        }
        ... on ResolutionElement {
            ...ResolutionElementDisplay_ResolutionElementFragment
        }
        ... on TextElement {
            ...TextElementDisplay_TextElementFragment
        }
        ...on VideoElement {
            ...VideoElementDisplay_VideoElementFragment
        }
    }
`

interface OwnProps {
    elementFragmentRef: ElementDisplay_ElementFragment$key;
    videoElementSpecific?: {
        timestamp?: number;
        setTimestampForSubtitles?: (timestamp: number) => void;
        isSettingTimestampInNoteContext?: boolean;
    }
}

export const ElementDisplay = ({elementFragmentRef, videoElementSpecific}: OwnProps) => {
    const element = useFragment<ElementDisplay_ElementFragment$key>(ELEMENT_DISPLAY_ELEMENT_FRAGMENT, elementFragmentRef)
    let Component;

    switch (element.elementType) {
        case 'clozeText':
            Component = <ClozeTextElementDisplay
                clozeTextElementFragmentRef={element}/>
            break;
        case 'image':
            Component = <ImageElementDisplay
                imageElementFragmentRef={element}/>
            break;
        case 'multipleChoice':
            Component = <MultipleChoiceElementDisplay
                multipleChoiceElementFragmentRef={element}/>
            break;
        case 'order':
            Component = <OrderElementDisplay
                orderElementFragmentRef={element}/>
            break;
        case 'persolog':
            Component = <PersologElementDisplay
                persologElementFragmentRef={element}/>
            break;
        case 'questionnaire':
            Component = <QuestionnaireElementDisplay
                questionnaireElementFragmentRef={element}/>
            break;
        case 'resolution':
            Component = <ResolutionElementDisplay
                resolutionElementFragmentRef={element}/>
            break;
        case 'text':
            Component = <TextElementDisplay
                textElementFragmentRef={element}/>
            break;
        case 'video':
            Component = <VideoElementDisplay
                videoElementFragmentRef={element}
                timestamp={videoElementSpecific?.timestamp}
                setTimestampForSubtitles={videoElementSpecific?.setTimestampForSubtitles}
                isSettingTimestampInNoteContext={videoElementSpecific?.isSettingTimestampInNoteContext || false}
            />
            break;
        default:
            Component = <div>Not supported: {element.elementType}</div>
    }

    return Component;
}

export const determineElementIcon = (elementType?: string) => {
    let icon: string;
    switch (elementType) {
        case 'clozeText':
            icon = "icon-c_blanks"
            break;
        case 'image':
            icon = "icon-c_picture";
            break;
        case 'multipleChoice':
            icon = "icon-c_multiple-choice"
            break
        case 'order':
            icon = "icon-c_order";
            break;
        case 'questionnaire':
            icon = "icon-a_quiz";
            break;
        case 'resolution':
            icon = "icon-c_info";
            break;
        case 'text':
            icon = "icon-a_text";
            break;
        case 'video':
            icon = "icon-a_video"
            break;
        default:
            icon = "";
    }
    return icon;
}
