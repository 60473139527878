import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import authDE from "./auth/i18n/auth.de.json";
import coreDE from "./core/i18n/core.de.json";
import menuDE from "./menu/i18n/menu.de.json";
import coursesDE from "./course/i18n/courses.de.json";
import customerMessagesDE from "./customer-message-v2/i18n/customer-messages.de.json";
import {
    TRANSLATION_ACHIEVEMENTS_DE,
    TRANSLATION_LEARN_DE,
    TRANSLATION_USER_SETTINGS_DE
} from "@thekeytechnology/thekey-academy-frontend-library";
import notesDE from "./notes/i18n/notes.de.json";
import qnasDE from "./qnas/i18n/qnas.de.json";
import lessonsDE from "./lesson/i18n/lessons.de.json"
import stateDE from "./state/i18n/state.de.json"
import feedbackDE from "./feedback/i18n/feedbacks.de.json";
import billingDE from "./billing/i18n/billing.de.json";
import licensesDE from "./licenses/i18n/licenses.de.json";
import managerDE from "./manager/i18n/manager.de.json";
import submissionDE from "./submission/i18n/submission.de.json";
import elementFlowDE from "./elementFlow/i18n/element-flow.de.json";
import reviewDE from "./learnReview/review/i18n/review.de.json";

// the translations
const resources = {
    de: {
        core: coreDE,
        auth: authDE,
        menu: menuDE,
        courses: coursesDE,
        customerMessages: customerMessagesDE,
        elementflow: elementFlowDE,
        achievements: TRANSLATION_ACHIEVEMENTS_DE,
        learn: TRANSLATION_LEARN_DE,
        review: reviewDE,
        usersettings: TRANSLATION_USER_SETTINGS_DE,
        notes: notesDE,
        qnas: qnasDE,
        lessons: lessonsDE,
        billing: billingDE,
        state: stateDE,
        feedback: feedbackDE,
        licenses: licensesDE,
        manager: managerDE,
        submission: submissionDE
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "de",
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: {
            checkWhitelist: true
        }
    });

i18n.changeLanguage("de");
