/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseDetailSidebar_CourseStateFragment = {
    readonly moduleStates: ReadonlyArray<{
        readonly moduleRef: string;
        readonly " $fragmentRefs": FragmentRefs<"ModuleSubItem_ModuleStateFragment">;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"AverageCoursePointsProgressBar_CourseStateFragment" | "CourseSidebar_CourseStateFragment">;
    readonly " $refType": "CourseDetailSidebar_CourseStateFragment";
};
export type CourseDetailSidebar_CourseStateFragment$data = CourseDetailSidebar_CourseStateFragment;
export type CourseDetailSidebar_CourseStateFragment$key = {
    readonly " $data"?: CourseDetailSidebar_CourseStateFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseDetailSidebar_CourseStateFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseDetailSidebar_CourseStateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ModuleState",
      "kind": "LinkedField",
      "name": "moduleStates",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleRef",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ModuleSubItem_ModuleStateFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AverageCoursePointsProgressBar_CourseStateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseSidebar_CourseStateFragment"
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};
(node as any).hash = 'a6ab60423f4b97378f43a168f9442da9';
export default node;
