import { BAR_COLOR_BLUE, ProgressBar } from "../../../core/components/progress/ProgressBar";
import React from "react";
import { useSelector } from "react-redux";
import {
    selectCurrentLevel,
    selectCurrentRank,
    selectPercentageToNextLevel
} from "@thekeytechnology/thekey-academy-frontend-library";
import { ImageWithFallback } from "../../../core/components/image/ImageWithFallback";

interface OwnProps {
    title: string;
    text: string;
}

export const RankMessage = ({
                                title,
                                text
                            }: OwnProps) => {
    const rank = useSelector(selectCurrentRank);
    const level = useSelector(selectCurrentLevel);
    const percentageToNextLevel = useSelector(selectPercentageToNextLevel);

    return <div className="welcome position-relative">
        <div className="welcome-foreground p-5 bgGradientLeftRight tk-border-radius w-100 d-flex">
            <div>
                <h1 className="tk-heading-bold mb-2">
                    {title}
                </h1>
                <p className="tk-academy-text">
                    {text}
                </p>
            </div>

            {rank ? <div className="ml-auto d-flex align-items-center">
                <div>
                    <div className="tk-academy-text tk-academy-bold tk-color-waterblue-80">Dein
                        Rang: {rank.entity.title}</div>
                    <div className="tk-academy-text tk-color-waterblue-80">Level {level}</div>
                    <ProgressBar barColor={BAR_COLOR_BLUE}
                                 whiteBackground={true}
                                 showPercentage={true}
                                 percentage100={percentageToNextLevel}/>
                </div>
                <ImageWithFallback className="rank-image tk-bg-white ml-4"
                                   src={rank.entity.image?.entity.url}
                                   alt={rank.entity.title}/>
            </div> : null}

        </div>
    </div>;
}
