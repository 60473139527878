/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NextLessonCard_LessonFragment = {
    readonly id: string;
    readonly lessonPath: {
        readonly moduleIndex: number;
        readonly lessonIndex: number;
        readonly moduleRef: string;
    };
    readonly containerMeta: {
        readonly title: string;
    };
    readonly " $refType": "NextLessonCard_LessonFragment";
};
export type NextLessonCard_LessonFragment$data = NextLessonCard_LessonFragment;
export type NextLessonCard_LessonFragment$key = {
    readonly " $data"?: NextLessonCard_LessonFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"NextLessonCard_LessonFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NextLessonCard_LessonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LessonPath",
      "kind": "LinkedField",
      "name": "lessonPath",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleIndex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lessonIndex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleRef",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContainerMeta",
      "kind": "LinkedField",
      "name": "containerMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Lesson",
  "abstractKey": "__isLesson"
};
(node as any).hash = 'ae15c7435d9b570bb663cab820f877ef';
export default node;
