import React from "react";
import "./radio.scss";

interface OwnProps {
    className?: string;
    isChecked: boolean;
    onToggle: (newSelection: boolean) => void;
}

export const Radio = ({
                          className,
                          isChecked,
                          onToggle,
                      }: OwnProps) => {

    return <div className={`radio-wrapper ${className ? className : ""}`}
                onClick={event => {
                    onToggle(!isChecked)
                    return false;
                }}
    >
        <div className={`checkbox ${isChecked ? "is-checked" : ""}`}>
            {isChecked ? <span className="radio-bubble"/> : null}
        </div>
    </div>
}
