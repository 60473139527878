import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchUsersInAccountAction} from "../../action/fetch-users-in-accounts";
import {selectUsersInAccountContainer} from "../../selectors";
import {UserCard} from "./UserCard";
import "./user-cards.scss"
import {AddUserButton} from "../invited/AddUserButton";
import {InvitedUsersList} from "../invited/InvitedUsersList";
import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {
    UsersInAccount_DownloadCourseStateInfoInAccountMutation
} from "../../../../__generated__/UsersInAccount_DownloadCourseStateInfoInAccountMutation.graphql";
import styled from "styled-components";

const DOWNLOAD_COURSE_STATE_INFO_IN_ACCOUNT_MUTATION = graphql`
    mutation UsersInAccount_DownloadCourseStateInfoInAccountMutation {
        Viewer {
            Manager {
                downloadCourseStateInfoInAccount(input: {}) {
                    csv
                }
            }
        }
    }`

export const UsersInAccount = () => {
    const dispatch = useDispatch();
    const usersInAccount = useSelector(selectUsersInAccountContainer)
    useEffect(() => {
        dispatch(fetchUsersInAccountAction())
    }, [dispatch])

    const [downloadCourseStateInfoInAccount] = useMutation<UsersInAccount_DownloadCourseStateInfoInAccountMutation>(DOWNLOAD_COURSE_STATE_INFO_IN_ACCOUNT_MUTATION)

    const downloadCourseStateInfoInAccountFile = (data: string) => {
        const link = document.createElement("a")
        link.href = "data:text/csv;charset=utf-8," + data
        link.download = "user_progress.csv"
        link.click()
    }


    return usersInAccount ? <div className="user-cards">
        <h2 className="tk-academy-large tk-academy-bold mb-2 tk-color-waterblue-80 d-flex align-items-center">
            <span>
            Nutzer:Innen im Konto
            </span>
            <AddUserButton className="ml-auto"/>
        </h2>

        <div className="tk-academy-text mb-2">In diesem Bereich kannst Du Deine Konto-Mitglieder verwalten. Indem Du
            Nutzer:innen Deinem Konto hinzufügst,
            kannst Du ihnen gekaufte Lizenzen zuweisen und ihren Lernfortschritt sehen.
        </div>

        <div className="tk-academy-text mb-2">Klicke auf das blaue + um Nutzer:innen zu Deinem Konto
            einzuladen.
        </div>


        <InvitedUsersList/>

        <DownloadButton className="btn btn-success ml-auto" onClick={() =>
            downloadCourseStateInfoInAccount({
                variables: {},
                onCompleted: (response) => {
                    if (response.Viewer.Manager.downloadCourseStateInfoInAccount?.csv) downloadCourseStateInfoInAccountFile(response.Viewer.Manager.downloadCourseStateInfoInAccount.csv)
                }
            })
        }>
            <span className="icon-b_data-download"/>
        </DownloadButton>

        {usersInAccount.users.map(u => {
            const courseStatesOfUser = usersInAccount?.courseStates.filter(cs => cs.entity.userRef === u.userId)
            const courseIds = courseStatesOfUser.map(cs => cs.entity.courseRef)
            const applicableCourses = usersInAccount?.courses.filter(c => courseIds.includes(c.id))
            return <UserCard
                key={u.userId}
                courses={applicableCourses}
                courseStates={courseStatesOfUser}
                user={u}/>
        })}
    </div> : null;
}

const DownloadButton = styled.button`
    height: 25px!important;
    width: 25px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
`
