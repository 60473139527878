import {showCelebrationAction} from "@thekeytechnology/thekey-academy-frontend-library";
import React, {useState} from "react";
import {AdditionalContentDisplay} from "./AdditionalContentDisplay";
import {useDispatch} from "react-redux";
import {BasicModal} from "../../core/components/modal/BasicModal";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {AdditionalContentItem_CourseStateFragment$key} from "../../../__generated__/AdditionalContentItem_CourseStateFragment.graphql";
import {AdditionalContentItem_AdditionalContentWithLessonPathFragment$key} from "../../../__generated__/AdditionalContentItem_AdditionalContentWithLessonPathFragment.graphql";
import {generateCartLink} from "../../billing/v3/utils/cart-link";

const ADDITIONAL_CONTENT_ITEM_ADDITIONAL_CONTENT_WITH_LESSON_PATH_FRAGMENT = graphql`
    fragment AdditionalContentItem_AdditionalContentWithLessonPathFragment on AdditionalContentWithLessonPath {
        lessonPath {
            lessonIndex
            moduleIndex
        }
        additionalContent {
            contentType
            id
            title
            ...AdditionalContentDisplay_AdditionalContentFragment
        }
    }`

const ADDITIONAL_CONTENT_ITEM_COURSE_STATE_FRAGMENT = graphql`
    fragment AdditionalContentItem_CourseStateFragment on CourseState {
        course {
            mainProduct
            mainDiscountCode
        }
        isBought
    }`

interface OwnProps {
    additionalContentWithLessonPathFragmentRef: AdditionalContentItem_AdditionalContentWithLessonPathFragment$key;
    courseStateFragmentRef: AdditionalContentItem_CourseStateFragment$key;
}


export const AdditionalContentItem = ({
                                          additionalContentWithLessonPathFragmentRef,
                                          courseStateFragmentRef
                                      }: OwnProps) => {
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const dispatch = useDispatch()

    const additionalContentWithLessonPath = useFragment<AdditionalContentItem_AdditionalContentWithLessonPathFragment$key>(ADDITIONAL_CONTENT_ITEM_ADDITIONAL_CONTENT_WITH_LESSON_PATH_FRAGMENT, additionalContentWithLessonPathFragmentRef)

    const courseState = useFragment<AdditionalContentItem_CourseStateFragment$key>(ADDITIONAL_CONTENT_ITEM_COURSE_STATE_FRAGMENT, courseStateFragmentRef)

    let icon;
    switch (additionalContentWithLessonPath.additionalContent.contentType) {
        case 'video':
            icon = "icon-a_video"
            break;
        case 'text':
            icon = "icon-a_text"
            break;
        case 'file':
            icon = "icon-b_data-download"
            break;
    }

    const closeModal = () => setModalVisible(false)

    return <div onClick={() => courseState.isBought ?
        setModalVisible(true) :
        dispatch(showCelebrationAction({
            title: "Zugriff gesperrt",
            explanation: "Sobald Du die Vollversion dieses Lehrgangs erworben hast, findest Du in diesem Bereich Skripte und weitere Begleitmaterialien. Diese kannst Du nach Kauf bequem herunterladen und ausdrucken.",
            text: "",
            image: "special-locked",
            buttonDescription: {
                specialType: "Link",
                label: "Jetzt Vollversion kaufen",
                navigationPath: courseState.course.mainProduct ? generateCartLink(courseState.course.mainProduct, courseState.course.mainDiscountCode) : ""
            }
        }))}
                className="d-flex flex-row tk-bg-white tk-border-radius pt-2 pr-4 pb-2 pl-4 mb-2 align-items-center tk-cursor-pointer"
                key={additionalContentWithLessonPath.additionalContent.id}>
        <div className="mr-3 tk-academy-large-bold">
            <span className={icon}/>
        </div>
        <div className="flex-grow-1">
            <div
                className="tk-academy-small-text">Lektion {additionalContentWithLessonPath.lessonPath.moduleIndex + 1}.{additionalContentWithLessonPath.lessonPath.lessonIndex + 1}</div>
            <div className="tk-academy-text tk-black">{additionalContentWithLessonPath.additionalContent.title}</div>
        </div>
        <BasicModal size={"lg"} onHide={closeModal} show={modalVisible}>
            <AdditionalContentDisplay additionalContentFragmentRef={additionalContentWithLessonPath.additionalContent}/>
        </BasicModal>
    </div>
}
