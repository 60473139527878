import React, {useState} from "react";
import {TextWrapper, ToggleButton} from "./Expandable.styles";

export const Expandable = () => {
	const [isExpanded, setIsExpanded] = useState(false)

	const handleToggle = () => setIsExpanded(!isExpanded)

	return <>
		<TextWrapper isExpanded={isExpanded}>
			<div style={{height: "100%", overflow: "hidden", fontSize: 14}}>
				Wir, <a href={"https://www.thekey.academy/impressum/"} target={"_blank"} rel={"noreferrer"}>the key -
				academy GmbH</a>, nutzen auf unseren Websites <a href={"https://thekey.academy/"}
																 target={"_blank"}
																 rel="noreferrer">https://www.thekey.academy/</a> und
				<a href={"https://app.thekey.academy/"} target={"_blank"}
				   rel="noreferrer"> https://app.thekey.academy/</a> Cookies und ähnliche Tracking Technologien wie z.B.
				Beacons (gemeinsam „Cookies”).
				Mit Klick auf „Alle Akzeptieren” willigst Du in die Nutzung von Analyse-Cookies, Präferenz-Cookies und
				Externe-Medien-Cookies und in die dadurch erfolgende Verarbeitung Deiner personenbezogenen Daten für die
				oben beschriebenen Zwecken durch uns oder Dritte, wie zum Beispiel Google, LLC ein. Weitere
				Informationen findest Du in unserer <a href={"https://www.thekey.academy/datenschutzerklaerung/"}
													   target={"_blank"} rel={"noreferrer"}>Datenschutzerklärung</a> und
				unter “Mehr Details”. Wenn Du auf
				„Ablehnen” klickst, werden wir nur Essentielle Cookie nutzen. Du kannst unter “Details” Deine
				Einwilligung jederzeit widerrufen und Deine Cookie-Einstellungen ändern.
			</div>
			<ToggleButton onClick={handleToggle} isExpanded={isExpanded}
						  className={isExpanded ? "icon-a_arrow-up" : "icon-a_arrow-down"}></ToggleButton>
		</TextWrapper>
	</>
}

