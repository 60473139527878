import React from 'react'
import {useLazyLoadQuery, useRelayEnvironment, commitLocalUpdate} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {CustomerMessageModalScreen_Query} from "../../../../__generated__/CustomerMessageModalScreen_Query.graphql";
import {CustomerMessageModal} from "./CustomerMessageModal";

const QUERY = graphql`
    query CustomerMessageModalScreen_Query {
        Viewer {
            CustomerMessageV2 {
                LastUnseenCustomerMessage {
                    ...CustomerMessageModal_CustomerMessageFragment
                    id
                    customerMessageDefinition {
                        pages {
                            edges {
                                node {
                                    id
                                    imageOpt {
                                        url
                                    }
                                    ...CustomerMessagePage_CustomerMessageDefinitionPageFragment
                                }
                            }
                        }
                    }
                }
                showModal
            }
        }
    }`

export const CustomerMessageModalScreen = () => {

    const environment = useRelayEnvironment()

    const query = useLazyLoadQuery<CustomerMessageModalScreen_Query>(QUERY, {})

    const lastUnseenCustomerMessage = query.Viewer.CustomerMessageV2.LastUnseenCustomerMessage || undefined

    const showModalFromStore = query.Viewer.CustomerMessageV2.showModal
    const showModal = (showModalFromStore === null || showModalFromStore === undefined || showModalFromStore)

    const hideModal = () => {
        commitLocalUpdate(environment, store => {
            store.getRoot().getLinkedRecord("Viewer")?.getLinkedRecord("CustomerMessageV2")?.setValue(false, "showModal")
        });
    }

    return <CustomerMessageModal
        hideModal={hideModal}
        customerMessageFragment={showModal ? lastUnseenCustomerMessage : undefined}
    />
}