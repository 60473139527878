import {Action} from "redux";
import {
    ApiCallSucceededAction,
    EntityWrapper,
    isApiSuccess,
    LogoutAction,
    PaginationResponse
} from "@thekeytechnology/framework-react";
import {API_FETCH_STATES} from "../actions/fetch-states";
import {CourseState} from "@thekeytechnology/thekey-academy-frontend-library"

export interface StateModuleState {
    states: EntityWrapper<CourseState>[]
}

export const initialState: StateModuleState = {
    states: [],
};

export function stateModuleReducer(state = initialState, action: Action): StateModuleState {
    if (action.type === LogoutAction) {
        return initialState;
    }

    if (action.type === LogoutAction) {
        return initialState;
    }

    if (isApiSuccess(API_FETCH_STATES)(action)) {
        const courseOverviewAction = action as ApiCallSucceededAction<PaginationResponse<EntityWrapper<CourseState>>>;

        return {
            ...state,
            states: courseOverviewAction.payload.entities,
        };
    }
    return state;
}
