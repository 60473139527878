import { useDispatch, useSelector } from "react-redux";
import { selectActiveStreak, showCelebrationAction } from "@thekeytechnology/thekey-academy-frontend-library";
import React from "react";
import moment from "moment";
import "./streak-display.scss";

interface OwnProps {
    className?: string
}

const weekDayToString = (weekday: number) => {
    switch (weekday) {
        case 1:
            return "Mo"
        case 2:
            return "Di"
        case 3:
            return "Mi"
        case 4:
            return "Do"
        case 5:
            return "Fr"
        case 6:
            return "Sa"
        case 7:
            return "So"
    }
}

export const StreakDisplay = ({
                                  className
                              }: OwnProps) => {
    const dispatch = useDispatch();
    const activeStreak = useSelector(selectActiveStreak);

    if (!activeStreak) {
        return null;
    }

    const originalStartingWeekday = moment(activeStreak.started).isoWeekday();
    const firstDay = moment().weekday(originalStartingWeekday).startOf("day");
    if (firstDay.isAfter(moment())) {
        firstDay.subtract(1, "week");
    }

    const startedStreak = moment(activeStreak.started).startOf("day");
    const lastUpdate = moment(activeStreak.lastUpdate).startOf("day");

    function isDayActive(dayDifference: number): false | boolean {
        const day = firstDay.clone().add(dayDifference, "days").startOf("day");
        return startedStreak.isSameOrBefore(day) && lastUpdate.isSameOrAfter(day)
    }

    const activeDays = [0, 1, 2, 3, 4, 5, 6].filter(dayDifference => {
        return isDayActive(dayDifference);
    }).length

    const daysToEnd = 7 - activeDays;

    return <div onClick={() => {
        dispatch(showCelebrationAction({
            title: "Die Streak",
            explanation: "Steter Tropfen höhlt den Stein",
            text: "Deine Streak zeigt Dir an, wie viele Tage Du hintereinander gelernt hast. Sie erscheint, wenn Du mindestens eine Lektion - und damit einen Tag am Stück - gelernt hast. Sie verschwindet aber auch wieder, sobald Du einen Tag nicht lernst. Lernst Du sieben Tage am Stück, warten Bonus-Schlüssel auf Dich.",
            image: "special-mountain"
        }))
    }} className={`streak-display ${className ? className : ""}`}>
        <div className="tk-white tk-academy-text tk-academy-bold">
            {activeStreak.length === 1 ? "Du hast Deinen ersten Tag gelernt!" : `Du hast schon ${activeStreak.length} Tage in Folge gelernt.`}
        </div>
        <div className="tk-white tk-academy-text mb-3">Noch {daysToEnd} Tage bis zur Wochen-Auszeichnung</div>
        <div className="days">
            {[0, 1, 2, 3, 4, 5, 6].map(dayDifference => {
                const day = firstDay.clone().add(dayDifference, "days").startOf("day");
                const isActive = isDayActive(dayDifference);

                return <div key={dayDifference}
                            className={isActive ? "active" : ""}
                            title={day.toISOString(true)}>{weekDayToString(day.isoWeekday())}</div>
            })}
        </div>
    </div>;
}
