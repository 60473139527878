import React from 'react'
import styled from 'styled-components'
import {tkColorCodes} from "../../../core/style/tkColorCodes";

interface OwnProps {
    modalWidth: number
    numPages: number
    active: boolean
}

export const PageIndicator = ({modalWidth, numPages, active}: OwnProps) => {
    return <Indicator modalWidth={modalWidth} numPages={numPages} active={active}/>
}

const Indicator = styled.div<{ modalWidth: number, numPages: number, active: boolean }>`
  width: ${props => props.modalWidth * (0.95 - (props.numPages - 1) * 0.025) / props.numPages}px;
  height: ${props => props.modalWidth * 0.0125}px;
  border-radius: 5px;
  background-color: ${props => props.active ? tkColorCodes.waterBlue : tkColorCodes.keyBlue30};
`