import React from "react";
import {
    LESSON_STATUS_CURRENT,
    LESSON_STATUS_FINISHED
} from "@thekeytechnology/thekey-academy-frontend-library"

import "./lesson-image.scss"
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {LessonImage_LessonStateFragment$key} from "../../../../../__generated__/LessonImage_LessonStateFragment.graphql";
import {LessonImage_ImageFragment$key} from "../../../../../__generated__/LessonImage_ImageFragment.graphql";

const LESSON_IMAGE_IMAGE_FRAGMENT = graphql`
    fragment LessonImage_ImageFragment on File {
        url
    }
`

const LESSON_IMAGE_LESSON_STATE_FRAGMENT = graphql`
    fragment LessonImage_LessonStateFragment on LessonState {
        status
    }
`

interface OwnProps {
    className?: string;
    imageFragmentRef?: LessonImage_ImageFragment$key;
    lessonStateFragmentRef?: LessonImage_LessonStateFragment$key;
}

export const LessonImage = ({
                                className,
                                imageFragmentRef,
                                lessonStateFragmentRef
                            }: OwnProps) => {

    const image = useFragment<LessonImage_ImageFragment$key>(LESSON_IMAGE_IMAGE_FRAGMENT, imageFragmentRef || null)
    const lessonState = useFragment<LessonImage_LessonStateFragment$key>(LESSON_IMAGE_LESSON_STATE_FRAGMENT, lessonStateFragmentRef || null)

    const lessonStatus = lessonState?.status;

    let overlayClass;
    if (lessonStatus === LESSON_STATUS_FINISHED) {
        overlayClass = "overlay finished-overlay"
    } else if (lessonStatus === LESSON_STATUS_CURRENT) {
        overlayClass = "overlay current-overlay"
    } else {
        overlayClass = "overlay locked-overlay"
    }

    return <div className={`lesson-image position-relative ${className}`}
                style={image?.url ? {backgroundImage: `url(${image.url})`} : {}}>
        <div className={`position-absolute w-100 bottom-0 left-0 ${overlayClass}`}/>
    </div>
}
