import React from "react";
import {BlueBackground} from "../../../components/screen/BlueBackground";
import {Screen} from "../../../components/screen/Screen";
import {LoadingFakeSubmitButton} from "./LoadingFakeSubmitButton";
import {LoadingFakeFlowSidebar} from "../LoadingFakeFlowSidebar";

export const LoadingFakeSubmissionScreen = () => {

    return <Screen
        className="learn-screen"
        sidebarSlot={<LoadingFakeFlowSidebar/>}
        headerBarBackgroundSlot={
            <BlueBackground height={90}/>
        }
        contentAreaRenderer={children =>
            <div className="d-flex flex-row flex-nowrap flex-grow-1 submission-finished-content-area" style={{marginTop: 60}}>
                {children}
            </div>
        }>
        <div className="d-flex flex-column justify-content-center align-items-center w-100 finished-display h-100">
            <div className={'d-flex h-100 w-100'}/>
            <div className="d-flex flex-row bottom-0 justify-content-between align-items-center p-3 w-100 bg-light">
                <LoadingFakeSubmitButton/>
                <LoadingFakeSubmitButton/>
            </div>
        </div>
    </Screen>;
}