/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseAttributes = "earlyBird" | "hidden" | "new" | "published" | "%future added value";
export type AtEndOfCourseButton_CourseFragment = {
    readonly attributes: ReadonlyArray<CourseAttributes>;
    readonly containerMeta: {
        readonly title: string;
    };
    readonly " $refType": "AtEndOfCourseButton_CourseFragment";
};
export type AtEndOfCourseButton_CourseFragment$data = AtEndOfCourseButton_CourseFragment;
export type AtEndOfCourseButton_CourseFragment$key = {
    readonly " $data"?: AtEndOfCourseButton_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"AtEndOfCourseButton_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AtEndOfCourseButton_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attributes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContainerMeta",
      "kind": "LinkedField",
      "name": "containerMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = 'a5c2d0320fed5963fef13b142e98e9d5';
export default node;
