import React from "react";
import styled from "styled-components";


export const LoadingOverlay = () => {

    return <LoadingContainer className="d-flex justify-content-center align-items-center">
        <div className="spinner-grow tk-color-keygreen" role="status">
            <span className="sr-only">Lade...</span>
        </div>
    </LoadingContainer>
}

const LoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  opacity: 45%;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
`

