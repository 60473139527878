/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ReviewPreparationScreen_CourseStateFragment = {
    readonly " $fragmentRefs": FragmentRefs<"CourseDetailSidebar_CourseStateFragment">;
    readonly " $refType": "ReviewPreparationScreen_CourseStateFragment";
};
export type ReviewPreparationScreen_CourseStateFragment$data = ReviewPreparationScreen_CourseStateFragment;
export type ReviewPreparationScreen_CourseStateFragment$key = {
    readonly " $data"?: ReviewPreparationScreen_CourseStateFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ReviewPreparationScreen_CourseStateFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewPreparationScreen_CourseStateFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseDetailSidebar_CourseStateFragment"
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};
(node as any).hash = '94499ecce9d7778a3e208c2b74545a40';
export default node;
