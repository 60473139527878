import React, {useCallback, useState} from "react";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {Rank} from "@thekeytechnology/thekey-academy-frontend-library";
import "./rank-item.scss"
import {CelebrationModal} from "../../../celebration/components/CelebrationModal";
import {AcademyButton} from "../../../core/components/button/AcademyButton";

interface OwnProps {
    rank: EntityWrapper<Rank>;
    hasRank: boolean
}

export const RankItem = ({
                             rank,
                             hasRank
                         }: OwnProps) => {

    const [state, setState] = useState({
        modalVisible: false
    });

    const hideModal = useCallback(() => {
        setState({...state, modalVisible: false})
    }, [state]);

    return <>
        <div className="rank-item mb-5 mr-5">
            <button className="btn-link" onClick={() => setState({...state, modalVisible: true})}>
                <div className={`image-wrapper ${hasRank ? "has-rank" : ""}`}>
                    {rank.entity.image ?
                        <img alt={rank.entity.title} src={rank.entity.image.entity.url}/>
                        : null}
                    {hasRank ? <div className="tick">
                        <span className={"icon-a_tick_circle"}/>
                    </div> : null}
                </div>
                <div className="title">{rank.entity.title}</div>
                <div className="explanation">ab Level {rank.entity.levelThreshold + 1}</div>
            </button>
        </div>
        {state.modalVisible ?
            <CelebrationModal
                title={rank.entity.title}
                explanation={rank.entity.explanation ? rank.entity.explanation : ""}
                text={rank.entity.text ? rank.entity.text : ""}
                image={rank.entity.image?.entity.url}
                onHide={() => hideModal()}>
                <AcademyButton text={"Ok"} onClick={hideModal}/>
            </CelebrationModal>
            : null}
    </>
}
