/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NoteItem_NoteFragment = {
    readonly content: string;
    readonly course: {
        readonly " $fragmentRefs": FragmentRefs<"NoteLocationHeading_CourseFragment">;
    };
    readonly lesson: {
        readonly containerMeta: {
            readonly title: string;
        };
        readonly " $fragmentRefs": FragmentRefs<"NoteLocationHeading_LessonFragment">;
    } | null;
    readonly title: string;
    readonly " $refType": "NoteItem_NoteFragment";
};
export type NoteItem_NoteFragment$data = NoteItem_NoteFragment;
export type NoteItem_NoteFragment$key = {
    readonly " $data"?: NoteItem_NoteFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"NoteItem_NoteFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NoteItem_NoteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "course",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NoteLocationHeading_CourseFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "lesson",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContainerMeta",
          "kind": "LinkedField",
          "name": "containerMeta",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NoteLocationHeading_LessonFragment"
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "type": "Note",
  "abstractKey": null
};
})();
(node as any).hash = 'd2b00622b19295be0e58f9a6d3af0366';
export default node;
