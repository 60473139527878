import React, {useState} from "react";
import {useFormik} from "formik";
import styled from "styled-components";
import {LargeText} from "../../../../theme/Typography";
import {AcademyButton} from "../../../../core/components/button/AcademyButton";

interface OwnProps {
    discountCodesInCart: string[]
    onAddedDiscount: (code: string) => void;
}

interface FormState {
    discountCode?: string
}

export const DiscountCodeInput = ({discountCodesInCart, onAddedDiscount}: OwnProps) => {
    const [submittedCode, setSubmittedCode] = useState<string>()

    const {setFieldValue, handleSubmit, values} = useFormik<FormState>({
        initialValues: {
            discountCode: undefined
        },
        onSubmit: (values, {setSubmitting}) => {
            if (values.discountCode) {
                onAddedDiscount(values.discountCode)
                setSubmittedCode(values.discountCode)
            }
            setSubmitting(false)
        }
    })

    return <DiscountCodeContainer>
        <Heading>Rabattcode (optional)</Heading>
        <form onSubmit={handleSubmit}>
            <InputWrapper>
                <input value={values.discountCode} onChange={e => setFieldValue("discountCode", e.target.value)}/>

                <AcademyButton type={"submit"} text={"Bestätigen"} buttonStyle={"secondary"}/>
            </InputWrapper>

            {submittedCode && !discountCodesInCart.includes(submittedCode) && <CodeError>
                Code ist nicht gültig
            </CodeError>}
        </form>
    </DiscountCodeContainer>
}

const DiscountCodeContainer = styled.div`
`
const Heading = styled.h3`
  ${LargeText};
  font-weight: bold;
  margin-bottom: 15px;
  color: #184276;
`

const InputWrapper = styled.div`
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;
    
    input {
      margin-bottom: 10px;
    }
  }

  input {
    border-radius: 1px;
    border: solid 1px #e9eef8;
    background-color: white;
    height: 45px;
    margin-right: 15px;
    padding: 0 20px;
    @media (max-width: 1024px) {
     margin-right: 0;
    }
  }

  button {
    height: 45px !important;
  }
`

const CodeError = styled.div`
  font-size: 16px;
  color: red;
`
