import React from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {NoteLocationHeading_CourseFragment$key} from "../../../__generated__/NoteLocationHeading_CourseFragment.graphql";
import {NoteLocationHeading_LessonFragment$key} from "../../../__generated__/NoteLocationHeading_LessonFragment.graphql";
import {truncateText} from "../../core/utils/utils";
import {useTranslation} from "react-i18next";

const COURSE_FRAGMENT = graphql`
    fragment NoteLocationHeading_CourseFragment on Course {
        shortTitle
    }`

const LESSON_FRAGMENT = graphql`
    fragment NoteLocationHeading_LessonFragment on Lesson {
        lessonPath {
            lessonIndex
            moduleIndex
        }
    }`

interface OwnProps {
    courseFragmentRef: NoteLocationHeading_CourseFragment$key;
    lessonFragmentRef?: NoteLocationHeading_LessonFragment$key | null;
}

export const NoteLocationHeading = ({
                                        courseFragmentRef,
                                        lessonFragmentRef,
                                    }: OwnProps) => {
    const {t} = useTranslation('notes')

    const course = useFragment<NoteLocationHeading_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef);

    const lesson = useFragment<NoteLocationHeading_LessonFragment$key>(LESSON_FRAGMENT, lessonFragmentRef || null);

    return <div className="tk-academy-text tk-academy-bold tk-color-waterblue-80">
        {truncateText(course.shortTitle, 15)}{lesson && ` | ${t('note-location-heading.module-shortcut')} ${lesson.lessonPath.moduleIndex + 1} | ${t('note-location-heading.lesson')} ${lesson.lessonPath.moduleIndex + 1}.${lesson.lessonPath.lessonIndex + 1}`}
    </div>
}

