/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CustomerMessageDefinitionButtonType = "confirm" | "link" | "%future added value";
export type CustomerMessageDefinitionButton_CustomerMessageDefinitionButtonFragment = {
    readonly type: CustomerMessageDefinitionButtonType;
    readonly " $fragmentRefs": FragmentRefs<"LinkButton_CustomerMessageDefinitionLinkButtonFragment" | "ConfirmButton_CustomerMessageDefinitionConfirmButtonFragment">;
    readonly " $refType": "CustomerMessageDefinitionButton_CustomerMessageDefinitionButtonFragment";
};
export type CustomerMessageDefinitionButton_CustomerMessageDefinitionButtonFragment$data = CustomerMessageDefinitionButton_CustomerMessageDefinitionButtonFragment;
export type CustomerMessageDefinitionButton_CustomerMessageDefinitionButtonFragment$key = {
    readonly " $data"?: CustomerMessageDefinitionButton_CustomerMessageDefinitionButtonFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CustomerMessageDefinitionButton_CustomerMessageDefinitionButtonFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerMessageDefinitionButton_CustomerMessageDefinitionButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LinkButton_CustomerMessageDefinitionLinkButtonFragment"
        }
      ],
      "type": "CustomerMessageDefinitionLinkButton",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ConfirmButton_CustomerMessageDefinitionConfirmButtonFragment"
        }
      ],
      "type": "CustomerMessageDefinitionConfirmButton",
      "abstractKey": null
    }
  ],
  "type": "CustomerMessageDefinitionButtonInterface",
  "abstractKey": "__isCustomerMessageDefinitionButtonInterface"
};
(node as any).hash = 'a8657344025e535fd1b8bfac7ba3eff6';
export default node;
