/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseAd_CourseStateFragment = {
    readonly isBought: boolean;
    readonly " $refType": "CourseAd_CourseStateFragment";
};
export type CourseAd_CourseStateFragment$data = CourseAd_CourseStateFragment;
export type CourseAd_CourseStateFragment$key = {
    readonly " $data"?: CourseAd_CourseStateFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseAd_CourseStateFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseAd_CourseStateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBought",
      "storageKey": null
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};
(node as any).hash = '7f368fced8843e1fb819bcea550238b5';
export default node;
