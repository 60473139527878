/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CustomerMessageModalScreen_QueryVariables = {};
export type CustomerMessageModalScreen_QueryResponse = {
    readonly Viewer: {
        readonly CustomerMessageV2: {
            readonly LastUnseenCustomerMessage: {
                readonly id: string;
                readonly customerMessageDefinition: {
                    readonly pages: {
                        readonly edges: ReadonlyArray<{
                            readonly node: {
                                readonly id: string;
                                readonly imageOpt: {
                                    readonly url: string | null;
                                } | null;
                                readonly " $fragmentRefs": FragmentRefs<"CustomerMessagePage_CustomerMessageDefinitionPageFragment">;
                            };
                        } | null> | null;
                    };
                } | null;
                readonly " $fragmentRefs": FragmentRefs<"CustomerMessageModal_CustomerMessageFragment">;
            } | null;
            readonly showModal: boolean | null;
        };
    };
};
export type CustomerMessageModalScreen_Query = {
    readonly response: CustomerMessageModalScreen_QueryResponse;
    readonly variables: CustomerMessageModalScreen_QueryVariables;
};



/*
query CustomerMessageModalScreen_Query {
  Viewer {
    CustomerMessageV2 {
      LastUnseenCustomerMessage {
        ...CustomerMessageModal_CustomerMessageFragment
        id
        customerMessageDefinition {
          pages {
            edges {
              node {
                id
                imageOpt {
                  url
                  id
                }
                ...CustomerMessagePage_CustomerMessageDefinitionPageFragment
              }
            }
          }
          id
        }
      }
    }
  }
}

fragment ConfirmButton_CustomerMessageDefinitionConfirmButtonFragment on CustomerMessageDefinitionConfirmButton {
  title
}

fragment CustomerMessageDefinitionButton_CustomerMessageDefinitionButtonFragment on CustomerMessageDefinitionButtonInterface {
  __isCustomerMessageDefinitionButtonInterface: __typename
  type
  ... on CustomerMessageDefinitionLinkButton {
    ...LinkButton_CustomerMessageDefinitionLinkButtonFragment
  }
  ... on CustomerMessageDefinitionConfirmButton {
    ...ConfirmButton_CustomerMessageDefinitionConfirmButtonFragment
  }
}

fragment CustomerMessageModal_CustomerMessageFragment on CustomerMessageV2 {
  id
  customerMessageDefinition {
    pages {
      edges {
        node {
          id
          imageOpt {
            url
            id
          }
          ...CustomerMessagePage_CustomerMessageDefinitionPageFragment
        }
      }
    }
    id
  }
  hasSeen
}

fragment CustomerMessagePage_CustomerMessageDefinitionPageFragment on CustomerMessageDefinitionPage {
  imageOpt {
    url
    id
  }
  customerMessageDefinition {
    buttonOpt {
      __typename
      ...CustomerMessageDefinitionButton_CustomerMessageDefinitionButtonFragment
    }
    id
  }
}

fragment LinkButton_CustomerMessageDefinitionLinkButtonFragment on CustomerMessageDefinitionLinkButton {
  title
  link
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v2 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showModal",
      "storageKey": null
    }
  ]
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerMessageModalScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerMessageV2ViewerSchema",
            "kind": "LinkedField",
            "name": "CustomerMessageV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerMessageV2",
                "kind": "LinkedField",
                "name": "LastUnseenCustomerMessage",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerMessageDefinitionV2",
                    "kind": "LinkedField",
                    "name": "customerMessageDefinition",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomerMessageDefinitionPagesConnection",
                        "kind": "LinkedField",
                        "name": "pages",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CustomerMessageDefinitionPagesEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CustomerMessageDefinitionPage",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v0/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "File",
                                    "kind": "LinkedField",
                                    "name": "imageOpt",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "CustomerMessagePage_CustomerMessageDefinitionPageFragment"
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CustomerMessageModal_CustomerMessageFragment"
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CustomerMessageModalScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerMessageV2ViewerSchema",
            "kind": "LinkedField",
            "name": "CustomerMessageV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerMessageV2",
                "kind": "LinkedField",
                "name": "LastUnseenCustomerMessage",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerMessageDefinitionV2",
                    "kind": "LinkedField",
                    "name": "customerMessageDefinition",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomerMessageDefinitionPagesConnection",
                        "kind": "LinkedField",
                        "name": "pages",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CustomerMessageDefinitionPagesEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CustomerMessageDefinitionPage",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v0/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "File",
                                    "kind": "LinkedField",
                                    "name": "imageOpt",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/),
                                      (v0/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CustomerMessageDefinitionV2",
                                    "kind": "LinkedField",
                                    "name": "customerMessageDefinition",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "buttonOpt",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                          },
                                          {
                                            "kind": "TypeDiscriminator",
                                            "abstractKey": "__isCustomerMessageDefinitionButtonInterface"
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "type",
                                            "storageKey": null
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v3/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "link",
                                                "storageKey": null
                                              }
                                            ],
                                            "type": "CustomerMessageDefinitionLinkButton",
                                            "abstractKey": null
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v3/*: any*/)
                                            ],
                                            "type": "CustomerMessageDefinitionConfirmButton",
                                            "abstractKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      (v0/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasSeen",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dd894db703994471a7048aadcfe360af",
    "id": null,
    "metadata": {},
    "name": "CustomerMessageModalScreen_Query",
    "operationKind": "query",
    "text": "query CustomerMessageModalScreen_Query {\n  Viewer {\n    CustomerMessageV2 {\n      LastUnseenCustomerMessage {\n        ...CustomerMessageModal_CustomerMessageFragment\n        id\n        customerMessageDefinition {\n          pages {\n            edges {\n              node {\n                id\n                imageOpt {\n                  url\n                  id\n                }\n                ...CustomerMessagePage_CustomerMessageDefinitionPageFragment\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment ConfirmButton_CustomerMessageDefinitionConfirmButtonFragment on CustomerMessageDefinitionConfirmButton {\n  title\n}\n\nfragment CustomerMessageDefinitionButton_CustomerMessageDefinitionButtonFragment on CustomerMessageDefinitionButtonInterface {\n  __isCustomerMessageDefinitionButtonInterface: __typename\n  type\n  ... on CustomerMessageDefinitionLinkButton {\n    ...LinkButton_CustomerMessageDefinitionLinkButtonFragment\n  }\n  ... on CustomerMessageDefinitionConfirmButton {\n    ...ConfirmButton_CustomerMessageDefinitionConfirmButtonFragment\n  }\n}\n\nfragment CustomerMessageModal_CustomerMessageFragment on CustomerMessageV2 {\n  id\n  customerMessageDefinition {\n    pages {\n      edges {\n        node {\n          id\n          imageOpt {\n            url\n            id\n          }\n          ...CustomerMessagePage_CustomerMessageDefinitionPageFragment\n        }\n      }\n    }\n    id\n  }\n  hasSeen\n}\n\nfragment CustomerMessagePage_CustomerMessageDefinitionPageFragment on CustomerMessageDefinitionPage {\n  imageOpt {\n    url\n    id\n  }\n  customerMessageDefinition {\n    buttonOpt {\n      __typename\n      ...CustomerMessageDefinitionButton_CustomerMessageDefinitionButtonFragment\n    }\n    id\n  }\n}\n\nfragment LinkButton_CustomerMessageDefinitionLinkButtonFragment on CustomerMessageDefinitionLinkButton {\n  title\n  link\n}\n"
  }
};
})();
(node as any).hash = '835af750ec2c526f1406711175a1d6b0';
export default node;
