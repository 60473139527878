/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ReviewCart_CartFragment = {
    readonly cartItems: ReadonlyArray<{
        readonly " $fragmentRefs": FragmentRefs<"ReviewCartItem_CartItemFragment">;
    }>;
    readonly " $refType": "ReviewCart_CartFragment";
};
export type ReviewCart_CartFragment$data = ReviewCart_CartFragment;
export type ReviewCart_CartFragment$key = {
    readonly " $data"?: ReviewCart_CartFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ReviewCart_CartFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewCart_CartFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "cartItems",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ReviewCartItem_CartItemFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Cart",
  "abstractKey": null
};
(node as any).hash = 'bfc24203a58f39e7bf9f561ba9e6f9a1';
export default node;
