import {useFormik} from "formik";
import React, {useContext, useEffect} from "react";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {ReviewForm_OrderFragment$key} from "../../../../../../__generated__/ReviewForm_OrderFragment.graphql";
import * as yup from "yup";
import {isValidIBANNumber} from "../../../../utils/iban-utils";
import {useTranslation} from "react-i18next";
import {paymentMethodIsMonthlyV3, paymentMethodNeedsSepaMandateV3} from "../../../utils/payment-method-utils";
import styled from "styled-components";
import {LargeText} from "../../../../../theme/Typography";
import {usePaymentLogicV3} from "./usePaymentLogicV3";
import {PaymentDataFormPart} from "./PaymentDataFormPart";
import {OrderFlowContext} from "../../../hooks/OrderFlowContext";
import {Separator} from "../../payment/PaymentStep";
import {LoadingOverlay} from "../../cart/LoadingOverlay";
import {ValidatedFieldV2} from "../../../../../core/components/form/ValidatedFieldV2";
import {Switch} from "../../../../../core/components/form/Switch";

interface OwnProps {
    orderFragmentRef: ReviewForm_OrderFragment$key
}

export interface ReviewFormState {
    paymentMethodField: string
    legal: boolean
    iban?: string

    sepaMandate: boolean
    foregoCancellation: boolean
}

export const ReviewForm = ({orderFragmentRef}: OwnProps) => {
    const {t} = useTranslation("billing")

    const {updateReviewFormState} = useContext(OrderFlowContext)

    const order = useFragment<ReviewForm_OrderFragment$key>(graphql`
        fragment ReviewForm_OrderFragment on Order {
            selectedPaymentMethod {
                paymentMethodId
            }
            ...usePaymentLogicV3_OrderFragment
            ...PaymentDataFormPart_OrderFragment
        }
    `, orderFragmentRef)

    const {startPayment, paymentInProcess} = usePaymentLogicV3(order)

    const formikConfig = useFormik<ReviewFormState>({
        initialValues: {
            paymentMethodField: "",
            iban: "",
            legal: false,
            foregoCancellation: false,
            sepaMandate: false
        },
        enableReinitialize: true,
        validationSchema: yup.object().shape({
            legal: yup
                .boolean()
                .oneOf([true], t("core:forms.required-field", {fieldName: t("purchase-form.legal")}))
                .required(t("core:forms.required-field", {fieldName: t("purchase-form.legal")})),
            foregoCancellation: yup
                .boolean()
                .oneOf([true], t("core:forms.required-field", {fieldName: t("purchase-form.forego-cancellation")}))
                .required(t("core:forms.required-field", {fieldName: t("payment-form.forego-cancellation")})),
            iban: yup
                .string()
                .test("test", "test", function (value) {
                    const valueIsFilled = !value || value.length > 8
                    if (paymentMethodIsMonthlyV3(order.selectedPaymentMethod?.paymentMethodId!) && (valueIsFilled && !isValidIBANNumber(value!))) {
                        return this.createError({
                            path: "iban",
                            message: t("purchase-form.iban-error")
                        });
                    }
                    return true;
                }),
            sepaMandate: yup
                .boolean()
                .test("test", "test", function (value) {
                    if (paymentMethodNeedsSepaMandateV3(order.selectedPaymentMethod?.paymentMethodId!) && !value) {
                        return this.createError({
                            path: "sepaMandate",
                            message: t("purchase-form.sepa-mandate")
                        });
                    }

                    return true;
                })
        }),
        onSubmit: (values, {setSubmitting, setErrors}) => {
            startPayment(values, setErrors, setSubmitting)
        }
    })

    useEffect(() => {
        if (formikConfig.handleSubmit) {
            updateReviewFormState(formikConfig.handleSubmit, formikConfig.isValid)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formikConfig.handleSubmit, formikConfig.isValid])


    return <div className="position-relative">
        {paymentInProcess && <LoadingOverlay/>}

        <form onSubmit={formikConfig.handleSubmit}>
            <PaymentDataFormPart formikState={formikConfig} orderFragmentRef={order}/>

            <Separator/>
            <Heading>Rechtliches</Heading>
            {paymentMethodNeedsSepaMandateV3(order.selectedPaymentMethod?.paymentMethodId!) ?
                <div className="form-group">
                    <ValidatedFieldV2<ReviewFormState, boolean>
                        name="sepaMandate"
                        required={true}
                        component={(value, updateValue) =>
                            <Switch title={<span
                                dangerouslySetInnerHTML={{__html: t("checkout-form.sepa-mandate")}}/>}
                                    titleClassName={"tk-academy-text tk-color-waterblue ml-3"}
                                    titleRight={true}
                                    isChecked={value}
                                    setChecked={updateValue}/>
                        }
                        formikConfig={formikConfig}/>
                </div> : null}

            <div className="form-group">
                <ValidatedFieldV2<ReviewFormState, boolean>
                    name="legal"
                    required={true}
                    component={(value, updateValue) =>
                        <Switch title={<span
                            dangerouslySetInnerHTML={{__html: t("checkout-form.legal")}}/>}
                                titleClassName={"tk-academy-text tk-color-waterblue ml-3"}
                                titleRight={true}
                                isChecked={value}
                                setChecked={updateValue}/>
                    }
                    formikConfig={formikConfig}/>
            </div>
            <div className="form-group">
                <ValidatedFieldV2<ReviewFormState, boolean>
                    name="foregoCancellation"
                    required={true}
                    component={(value, updateValue) =>
                        <Switch
                            title={<span
                                dangerouslySetInnerHTML={{__html: t("checkout-form.forego-cancellation")}}/>}
                            titleClassName={"tk-academy-text tk-color-waterblue ml-3"}
                            titleRight={true}
                            isChecked={value}
                            setChecked={updateValue}/>
                    }
                    formikConfig={formikConfig}/>
            </div>
        </form>
    </div>
}
const Heading = styled.h3`
  ${LargeText};
  font-weight: bold;
  margin-bottom: 15px;
  color: #184276;
`
