/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SubmissionType = "certificationTest" | "learn" | "review" | "test" | "%future added value";
export type LessonPreviousButton_SubmissionFragment = {
    readonly submissionType: SubmissionType;
    readonly " $refType": "LessonPreviousButton_SubmissionFragment";
};
export type LessonPreviousButton_SubmissionFragment$data = LessonPreviousButton_SubmissionFragment;
export type LessonPreviousButton_SubmissionFragment$key = {
    readonly " $data"?: LessonPreviousButton_SubmissionFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LessonPreviousButton_SubmissionFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonPreviousButton_SubmissionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submissionType",
      "storageKey": null
    }
  ],
  "type": "Submission",
  "abstractKey": "__isSubmission"
};
(node as any).hash = '4576622c5197726aea65bb2f11572745';
export default node;
