import React from "react";
import "./cart-contents.scss"
import {useDispatch} from "react-redux";
import {CartTable} from "./table/CartTable";
import {ShowOptionsPriceRenderer} from "./table/PriceRenderer";
import {
    applyVoucherAction,
    PersonalizedOfferV2,
    selectUpsellAction,
    unselectUpsellAction
} from "@thekeytechnology/thekey-academy-frontend-library";
import {CartTotalRow} from "./table/CartTotalRow";

interface OwnProps {
    offer?: PersonalizedOfferV2
}

export const OfferCart = ({
                              offer
                          }: OwnProps) => {
    const dispatch = useDispatch();

    if (!offer) {
        return null;
    }

    const monthlyPossible = !offer.baseOffer.entity.disabledPaymentMethods.includes("monthly-tk") && !offer.baseOffer.entity.disabledPaymentMethods.includes("monthly-partner")

    return <div className="cart-contents-v2 tk-academy-card p-3 mb-3">
        <div className="d-flex align-items-center">
            <div
                className="tk-academy-large tk-color-waterblue-80 tk-academy-bold">
                Angebot {offer.baseOffer.entity.title}
            </div>
        </div>
        <hr className="mt-3 mb-3"/>
        <CartTable priceTable={offer.priceTable}
                   referredCourses={offer.referredCourses}
                   onSelectUpsell={courseRef => dispatch(selectUpsellAction(courseRef))}
                   applyVoucher={code => dispatch(applyVoucherAction(code))}
                   appliedVouchers={offer.appliedVouchers}
                   priceRenderer={((priceExplanation, monthlyPriceExplanation, isDiscount) =>
                       <ShowOptionsPriceRenderer
                           priceExplanation={priceExplanation}
                           monthlyPriceExplanation={monthlyPriceExplanation}
                           showNetPrices={offer?.baseOffer.entity.showNetPrices}
                           showMonthly={monthlyPossible}
                           isDiscount={isDiscount}
                       />)}
                   onUnselectUpsell={courseRef => dispatch(unselectUpsellAction(courseRef))}/>
        <hr/>
        <CartTotalRow
            priceRenderer={((priceExplanation, monthlyPriceExplanation, isDiscount) =>
                <ShowOptionsPriceRenderer
                    priceExplanation={priceExplanation}
                    monthlyPriceExplanation={monthlyPriceExplanation}
                    showNetPrices={offer?.baseOffer.entity.showNetPrices}
                    showMonthly={monthlyPossible}
                />)}

            priceTable={offer.priceTable} showNetPrices={offer.baseOffer.entity.showNetPrices}
            monthlyPossible={monthlyPossible}/>
    </div>;
}
