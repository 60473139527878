/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ReviewStep_OrderFragment = {
    readonly cart: {
        readonly " $fragmentRefs": FragmentRefs<"ReviewCart_CartFragment">;
    };
    readonly " $fragmentRefs": FragmentRefs<"ReviewForm_OrderFragment" | "ReviewCartTotalsDisplay_OrderFragment">;
    readonly " $refType": "ReviewStep_OrderFragment";
};
export type ReviewStep_OrderFragment$data = ReviewStep_OrderFragment;
export type ReviewStep_OrderFragment$key = {
    readonly " $data"?: ReviewStep_OrderFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ReviewStep_OrderFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewStep_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ReviewCart_CartFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReviewForm_OrderFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReviewCartTotalsDisplay_OrderFragment"
    }
  ],
  "type": "Order",
  "abstractKey": null
};
(node as any).hash = '6643111b60e2a67e687e4fc88a8ee7c1';
export default node;
