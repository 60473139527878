export const tkColorCodes = {
    keyBlue: "#247dc5",
    keyGreen: "#4ACE7F",
    keyBlue30: "#a7cbe8",
    keyBlue20: "#d3e5f4",
    keyGreen20: "#dcf8e7",
    darkBlue: "#031d3c",
    waterBlue: "#184276",
    waterBlue80: "#466791",
    waterBlue40: "#a2b3c8",
    waterBlue20: "#d0d9e3",
    waterBlue10: "#e7ebf1",
    lightBackground: "#f3f5f8",
    orange: "#ea561c",
    sun: "#ffd426",
    orange20: "#fedecc",
    sun20: "#fff7d3"
}