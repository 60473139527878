import styled from "styled-components";


export const StyledForm = styled.div`
  width: 100%;
  
  .tk-form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }

  input {
    padding: 0 20px 0 20px;
    border-radius: 1px !important;
    border: solid 1px #e9eef8 !important;
    background-color: white;
  }
  
  .react-select {
    padding: 0 10px 0 10px;

    border-radius: 1px !important;
    border: solid 1px #e9eef8 !important;
  }

  @media (max-width: 1024px) {
    .tk-form-row {
      display: flex;
      flex-direction: column;
    }
  }
`
