import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchNewsAction, News, selectNews } from "@thekeytechnology/thekey-academy-frontend-library"
import { NewsCard } from "./NewsCard";

interface StateProps {
    news: News[]
}

interface DispatchProps {
    fetchNews: typeof fetchNewsAction
}

type Props = StateProps & DispatchProps

const NewsListComponent = ({
                               news,
                               fetchNews
                           }: Props) => {

    useEffect(() => {
        fetchNews();
    }, [fetchNews])

    return <div className="course-list">
        <div className="mb-3 d-flex align-items-center">
            <h2 className="tk-academy-small-text tk-color-waterblue-80">Neuigkeiten</h2>
            <a className="ml-auto d-flex align-items-center tk-academy-small-text tk-academy-bold"
               href="https://www.thekey.academy/blog/"
               rel="noopener noreferrer"
               target="_blank">
                <span className="mr-2">Zeige alle</span>
                <span className="icon-a_forward"/>
            </a>
        </div>
        <div className="d-flex">
            {news.slice(0, 3).map((newsItem, index) => {
                return <NewsCard key={index} news={newsItem} className="mr-4"/>
            })}
        </div>
    </div>;
}

export const NewsList = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        news: selectNews(state)
    }),
    {
        fetchNews: fetchNewsAction
    }
)(NewsListComponent)
