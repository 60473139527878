/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseAdditionalContentList_CourseStateFragment = {
    readonly " $fragmentRefs": FragmentRefs<"CourseAdditionalContentModuleCard_CourseStateFragment">;
    readonly " $refType": "CourseAdditionalContentList_CourseStateFragment";
};
export type CourseAdditionalContentList_CourseStateFragment$data = CourseAdditionalContentList_CourseStateFragment;
export type CourseAdditionalContentList_CourseStateFragment$key = {
    readonly " $data"?: CourseAdditionalContentList_CourseStateFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseAdditionalContentList_CourseStateFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseAdditionalContentList_CourseStateFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseAdditionalContentModuleCard_CourseStateFragment"
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};
(node as any).hash = '28741debfa13f910d98fa2f377080571';
export default node;
