import React, {createContext, ReactNode, useState} from "react";
import {RelayEnvironmentProvider} from 'react-relay';
import {RelayEnvironment} from "./RelayEnvironment";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";

export const CurrentRelayEnvironmentContext = createContext<{
    reset: () => void;
}>(null as any)

interface OwnProps {
    store: any;
    children: ReactNode;
}

export const CurrentRelayEnvironmentContextProvider = ({
                                                    store,
                                                    children
                                                }: OwnProps) => {

    const resetRelayEnv = () => setRelayEnv(RelayEnvironment(store, resetRelayEnv))

    const [relayEnv, setRelayEnv] = useState<RelayModernEnvironment>(RelayEnvironment(store, resetRelayEnv));

    return <CurrentRelayEnvironmentContext.Provider value={{
        reset: resetRelayEnv,
    }}>
        <RelayEnvironmentProvider environment={relayEnv}>
            {children}
        </RelayEnvironmentProvider>
    </CurrentRelayEnvironmentContext.Provider>
}

