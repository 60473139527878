/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ElementType = "clozeText" | "image" | "multipleChoice" | "order" | "persolog" | "questionnaire" | "resolution" | "text" | "video" | "%future added value";
export type OrderElementDisplay_OrderElementFragment = {
    readonly elementType: ElementType;
    readonly elementPath: {
        readonly courseRef: string;
        readonly moduleRef: string;
        readonly lessonRef: string;
    };
    readonly generalSettings: {
        readonly points: number;
    };
    readonly id: string;
    readonly orderables: ReadonlyArray<{
        readonly text: string;
        readonly " $fragmentRefs": FragmentRefs<"OrderableDisplay_OrderableFragment">;
    }>;
    readonly title: string;
    readonly " $refType": "OrderElementDisplay_OrderElementFragment";
};
export type OrderElementDisplay_OrderElementFragment$data = OrderElementDisplay_OrderElementFragment;
export type OrderElementDisplay_OrderElementFragment$key = {
    readonly " $data"?: OrderElementDisplay_OrderElementFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"OrderElementDisplay_OrderElementFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderElementDisplay_OrderElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ElementPath",
      "kind": "LinkedField",
      "name": "elementPath",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "courseRef",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleRef",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lessonRef",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GeneralElementSettings",
      "kind": "LinkedField",
      "name": "generalSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "points",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Orderable",
      "kind": "LinkedField",
      "name": "orderables",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OrderableDisplay_OrderableFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "OrderElement",
  "abstractKey": null
};
(node as any).hash = '34de1c655ce60cc5d206b02348e2c282';
export default node;
