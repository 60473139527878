/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ModuleStateStatus = "current" | "finished" | "%future added value";
export type ModuleSubItem_ModuleStateFragment = {
    readonly firstUpdate: string | null;
    readonly lastUpdate: string;
    readonly points: number;
    readonly status: ModuleStateStatus;
    readonly " $refType": "ModuleSubItem_ModuleStateFragment";
};
export type ModuleSubItem_ModuleStateFragment$data = ModuleSubItem_ModuleStateFragment;
export type ModuleSubItem_ModuleStateFragment$key = {
    readonly " $data"?: ModuleSubItem_ModuleStateFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ModuleSubItem_ModuleStateFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModuleSubItem_ModuleStateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "points",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "ModuleState",
  "abstractKey": null
};
(node as any).hash = '467358676ec0a3708ff044965bcdeafc';
export default node;
