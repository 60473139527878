/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CelebrationPopup_CourseFragment = {
    readonly " $fragmentRefs": FragmentRefs<"AdvanceCourseButton_CourseFragment">;
    readonly " $refType": "CelebrationPopup_CourseFragment";
};
export type CelebrationPopup_CourseFragment$data = CelebrationPopup_CourseFragment;
export type CelebrationPopup_CourseFragment$key = {
    readonly " $data"?: CelebrationPopup_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CelebrationPopup_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CelebrationPopup_CourseFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdvanceCourseButton_CourseFragment"
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = '54722a075a94def9e80c94d3197fdd19';
export default node;
