import React from "react";
import {useDispatch} from "react-redux";
import {AcademyButton} from "../../core/components/button/AcademyButton";
import {NavLink} from "react-router-dom";
import {
    showCelebrationAction
} from "@thekeytechnology/thekey-academy-frontend-library";
import {useTranslation} from "react-i18next";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {LessonDetailFooter_LessonFragment$key} from "../../../__generated__/LessonDetailFooter_LessonFragment.graphql";
import {LessonDetailFooter_CourseStateFragment$key} from "../../../__generated__/LessonDetailFooter_CourseStateFragment.graphql";
import {OrderCertificateButton} from "../../state/components/OrderCertificateButton";

const isDev = process.env.REACT_APP_APP_ENVIRONMENT === 'dev';
const isStaging = process.env.REACT_APP_APP_ENVIRONMENT === 'staging';

const COURSE_STATE_FRAGMENT = graphql`
    fragment LessonDetailFooter_CourseStateFragment on CourseState {
        courseStartedMoreThanOneWeekAgo
        isCanOrderCertificate
        isCertificateOrdered
    }`

const LESSON_FRAGMENT = graphql`
    fragment LessonDetailFooter_LessonFragment on Lesson {
        lessonPath {
            courseRef
            moduleRef
        }
        lessonType
        id
        ... on TestLesson {
            isCertificationTest
        }
    }`

interface OwnProps {
    courseStateFragmentRef?: LessonDetailFooter_CourseStateFragment$key | null;
    hasLessonState: boolean;
    lessonFragmentRef: LessonDetailFooter_LessonFragment$key;
}

export const LessonDetailFooter = ({
                                       courseStateFragmentRef,
                                       hasLessonState,
                                       lessonFragmentRef
                                   }: OwnProps) => {
    const {t} = useTranslation("state")

    const courseState = useFragment<LessonDetailFooter_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, courseStateFragmentRef || null)

    const lesson = useFragment<LessonDetailFooter_LessonFragment$key>(LESSON_FRAGMENT, lessonFragmentRef)

    const dispatch = useDispatch()

    const isCertificationTest = lesson.lessonType === "test" && lesson.isCertificationTest;

    if (hasLessonState) {

        const navLink = <NavLink className="ml-auto"
                                 to={`/courses/${lesson.lessonPath.courseRef}/modules/${lesson.lessonPath.moduleRef}/lessons/${lesson.id}/learn`}>
            <AcademyButton
                text={"Lektion starten"}
            />
        </NavLink>;

        let content;
        if (!courseState) {
            content = navLink;
        } else {
            if (isCertificationTest && courseState.isCanOrderCertificate) {
                if (!courseState.isCertificateOrdered) {
                    return <div className="d-flex align-items-end justify-content-end p-3">
                        <OrderCertificateButton disabled={courseState.isCertificateOrdered}
                                                courseId={lesson.lessonPath.courseRef}/>
                    </div>
                } else {
                    return <div className="d-flex align-items-end justify-content-end p-3">
                        <AcademyButton
                            text={"Lektion starten"}
                            disabled={true}
                        />
                    </div>;
                }
            }

            content = !courseState.courseStartedMoreThanOneWeekAgo && isCertificationTest && !isDev && !isStaging ?
                <AcademyButton text={
                    t("next-lesson-button.start-lesson")
                } onClick={() => dispatch(showCelebrationAction({
                    title: t("next-lesson-button.block-certification.title"),
                    text: t("next-lesson-button.block-certification.text"),
                    image: "special-locked",
                    explanation: ""
                }))}/> : navLink
        }

        return <div className="d-flex align-items-end justify-content-end p-3">
            {content}
        </div>
    } else {
        return <div className="d-flex align-items-end justify-content-end p-3">
            <AcademyButton
                icon="icon-a_lock"
                text="Lektion starten"
                onClick={() =>  dispatch(showCelebrationAction({
                    title: "Lektion gesperrt",
                    explanation: "Nicht so voreilig!",
                    text: "Diese Lektion hast Du Dir noch nicht freigeschaltet. Die Interaktivlehrgänge von thekey.academy sind auf Deinen maximalen Lernerfolg optimiert und daher in einer festen Reihenfolge zu absolvieren.",
                    image: "special-locked"
                }))
                }
            />
        </div>
    }
}