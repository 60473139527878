import React from "react";
import styled from "styled-components";


interface OwnProps {
    value: number
    updateValue: (newValue: number) => void;
}

export const AmountInput = ({value, updateValue}: OwnProps) => {

    return <AmountInputContainer>
        <ButtonControl disabled={value === 0} onClick={() => {
            updateValue(value - 1)
        }}>-</ButtonControl>
        <InputField
            type="number"
            value={value}
            step={1}
            onChange={event => {
                const updatedValue = parseInt(event.target.value);
                if (updatedValue > 0) {
                    updateValue(updatedValue)
                }
            }}
        />
        <ButtonControl onClick={() => {
            updateValue(value + 1)
        }}>+</ButtonControl>
    </AmountInputContainer>
}

const AmountInputContainer = styled.div`
  display: flex;
  align-items: center;


  @media (max-width: 1024px) {
    border-top: 1px solid #e7ebf1;
    min-width: 150px;
    padding-top: 20px;
    z-index: 99;
  }
`

const InputField = styled.input`
  -webkit-appearance: none;
  -moz-appearance: textfield;

  width: 20px;
  border: 0;

  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  line-height: 1.29;
  color: #466791;
  text-align: center;
  margin-right: 10px;

  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`


const ButtonControl = styled.button`
  border: solid 1px #e7ebf1;
  background-color: white;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  display: flex;

  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  line-height: 1.29;
  color: #466791;
  margin-right: 10px;

  &:disabled {
    background-color: #f3f5f8;
    color: #d0d9e3;
  }
`

