import {CourseOfferRow} from "./CourseOfferRow";
import {DiscountRow} from "./DiscountRow";
import React from "react";
import {PriceRenderer} from "./PriceRenderer";
import {
    CourseInformation,
    CoursePriceTableItem,
    DiscountPriceTableItem,
    PriceTable,
    UpsellPriceTableItem,
    VoucherPriceTableItem
} from "@thekeytechnology/thekey-academy-frontend-library";
import {VoucherInputRow} from "./VoucherInputRow";
import {UpsellOptionsRow} from "./UpsellOptionsRow";

interface OwnProps {
    className?: string;
    priceRenderer: PriceRenderer
    priceTable: PriceTable
    referredCourses: CourseInformation[]

    onSelectUpsell?: (courseRef: string) => void;
    onUnselectUpsell?: (courseRef: string) => void;

    applyVoucher?: (code: string) => void;
    appliedVouchers?: string[]
}


export const CartTable = ({
                              className,
                              priceTable,
                              priceRenderer,
                              referredCourses,
                              onUnselectUpsell,
                              onSelectUpsell,
                              appliedVouchers,
                              applyVoucher
                          }: OwnProps) => {

    return <div className={`cart-table ${className ? className : ""}`}>
        <div className="courses">
            {priceTable.offerItems.map((offerItem, index) => {
                switch (offerItem.itemType) {
                    case "course-item": {
                        const courseItem = offerItem as CoursePriceTableItem
                        const course = referredCourses.find(c => c.id === courseItem.courseRef);
                        return course ? <CourseOfferRow
                            key={index}
                            amount={courseItem.amount}
                            appliedDiscount={courseItem.appliedDiscount}
                            isOwned={courseItem.isOwned}
                            priceRenderer={priceRenderer}
                            offerItem={courseItem} course={course}/> : null
                    }
                    case "selected-upsell-item":
                        const upsellItem = offerItem as UpsellPriceTableItem
                        const course = referredCourses.find(c => c.id === upsellItem.courseRef);
                        return course ?
                            <CourseOfferRow
                                key={index}
                                priceRenderer={priceRenderer}
                                offerItem={upsellItem}
                                course={course}
                                afterCourseTitleSlot={onUnselectUpsell ?
                                    <button onClick={() => onUnselectUpsell(upsellItem.courseRef)}
                                            className="btn btn-link"><span className="tk-color-darkblue icon-a_trash"/>
                                    </button> : null}

                            /> : null;
                }

                return null;
            })}
        </div>


        <div className="mt-4">
            {priceTable.upsellOptions.map((upsellOption, index) => {
                const course = referredCourses.find(c => c.id === (upsellOption.courseRef));

                return course ?
                    <UpsellOptionsRow
                        key={"upsell-" + index}
                        priceRenderer={priceRenderer}
                        course={course}
                        upsellOption={upsellOption}
                        onSelectUpsell={onSelectUpsell}/> : null;
            })}
        </div>


        <div className="discounts">
            {priceTable.offerItems.map((offerItem, index) => {
                switch (offerItem.itemType) {
                    case "discount-item":
                        const discountItem = offerItem as DiscountPriceTableItem
                        return <DiscountRow
                            key={index}
                            priceRenderer={priceRenderer}
                            offerItem={discountItem}
                        />
                    case "applied-voucher-item":
                        const voucherItem = offerItem as VoucherPriceTableItem
                        return <DiscountRow
                            key={index}
                            priceRenderer={priceRenderer}
                            offerItem={{
                                title: "Code " + voucherItem.code,
                                priceExplanation: voucherItem.priceExplanation,
                                monthlyPriceExplanation: voucherItem.monthlyPriceExplanation
                            }}
                        />
                }

                return null;
            })}
        </div>

        {applyVoucher && appliedVouchers ?
            <VoucherInputRow applyVoucher={applyVoucher} appliedVouchers={appliedVouchers}/> : null}
    </div>
}
