/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ReviewProcessScreen_SubmitReviewMutationVariables = {
    courseId: string;
    elementSubmissionsJson: string;
};
export type ReviewProcessScreen_SubmitReviewMutationResponse = {
    readonly Submission: {
        readonly submitReview: {
            readonly submission: {
                readonly id: string;
            };
        } | null;
    };
};
export type ReviewProcessScreen_SubmitReviewMutation = {
    readonly response: ReviewProcessScreen_SubmitReviewMutationResponse;
    readonly variables: ReviewProcessScreen_SubmitReviewMutationVariables;
};



/*
mutation ReviewProcessScreen_SubmitReviewMutation(
  $courseId: ID!
  $elementSubmissionsJson: String!
) {
  Submission {
    submitReview(input: {courseId: $courseId, elementSubmissions: $elementSubmissionsJson}) {
      submission {
        __typename
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "courseId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "elementSubmissionsJson"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "courseId",
        "variableName": "courseId"
      },
      {
        "kind": "Variable",
        "name": "elementSubmissions",
        "variableName": "elementSubmissionsJson"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReviewProcessScreen_SubmitReviewMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SubmissionMutations",
        "kind": "LinkedField",
        "name": "Submission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ReviewSubmissionPayload",
            "kind": "LinkedField",
            "name": "submitReview",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "submission",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReviewProcessScreen_SubmitReviewMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SubmissionMutations",
        "kind": "LinkedField",
        "name": "Submission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ReviewSubmissionPayload",
            "kind": "LinkedField",
            "name": "submitReview",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "submission",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "72a35c1807d4ba0744f89e3dfab60437",
    "id": null,
    "metadata": {},
    "name": "ReviewProcessScreen_SubmitReviewMutation",
    "operationKind": "mutation",
    "text": "mutation ReviewProcessScreen_SubmitReviewMutation(\n  $courseId: ID!\n  $elementSubmissionsJson: String!\n) {\n  Submission {\n    submitReview(input: {courseId: $courseId, elementSubmissions: $elementSubmissionsJson}) {\n      submission {\n        __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '4fed5402d677678c4f029383ed684384';
export default node;
