import {FormikState} from "formik";
import React, {ReactNode} from "react";

import "./validated-field.scss";

interface ValidatedFieldPropsV2<State, FieldType> {
    label?: string;
    name: keyof State & string;
    required?: boolean;

    formikConfig: FormikState<State> & {
        setFieldTouched: (field: string, touched?: boolean, shouldValidate?: boolean | undefined) => any;
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
    }

    iconClass?: string;
    helpText?: ReactNode;

    containerClassName?: string;

    component: (fieldValue: FieldType, updateField: (newValue: FieldType) => void, fieldName: string, required: boolean) => ReactNode
}

export function ValidatedFieldV2<State, FieldType>({
                                                       iconClass,
                                                       name,
                                                       label,
                                                       formikConfig,
                                                       helpText,
                                                       containerClassName,
                                                       component,
                                                       required
                                                   }: ValidatedFieldPropsV2<State, FieldType>) {
    const hasError = formikConfig.errors[name] && formikConfig.touched[name];
    const columnClass = label ? "col-sm-10" : null;

    const value = formikConfig.values[name] as unknown as FieldType

    const updateValue = (updatedValue: FieldType) => {
        formikConfig.setFieldTouched(name, true);
        formikConfig.setFieldValue(name, updatedValue);
    }

    return (
        <div className={`validated-field ${containerClassName ? containerClassName : ""}`}>
            {label ?
                <label htmlFor={name} className="col-sm-2 col-form-label">{label} {required ? "*" : ""}</label> : null}

            <div className={columnClass ? columnClass : ""}>
                {iconClass ? <div className="input-field-wrapper position-relative d-flex align-items-center">
                        <span className={"waterBlue20 position-absolute icon left15 " + iconClass}/>
                        {component(value, updateValue, name, required || false)}
                    </div> :
                    component(value, updateValue, name, required || false)
                }

                {hasError ? (
                    <div
                        className="tk-academy-small-text tk-color-orange validation-error">{(formikConfig.errors as any)[name]}</div>
                ) : null}

                {helpText ? (
                    <small className="form-text text-muted">{helpText}</small>
                ) : null}
            </div>
        </div>);
}
