import React, {useRef, useState, Suspense} from "react";
import {Screen} from '../../../core/components/screen/Screen'
import {BlueBackground} from "../../../core/components/screen/BlueBackground";
import {useLazyLoadQuery, usePaginationFragment} from "react-relay"
import {graphql} from "babel-plugin-relay/macro";
import {AllCustomerMessagesScreen_Query} from "../../../../__generated__/AllCustomerMessagesScreen_Query.graphql";
import {MainSidebar} from "../../../core/components/sidebar/MainSidebar";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {tkTypography} from "../../../core/style/tkTypography";
import {Carousel} from "primereact/carousel";
import {CustomerMessagesPaginationFragmentRefetchQuery} from "../../../../__generated__/CustomerMessagesPaginationFragmentRefetchQuery.graphql";
import {AllCustomerMessagesScreen_CustomerMessagesPaginationFragment$key} from "../../../../__generated__/AllCustomerMessagesScreen_CustomerMessagesPaginationFragment.graphql";
import {useViewportDimensions} from "../../../core/components/useViewportDimensions";
import {CustomerMessagePreview} from "./CustomerMessagePreview";
import ArrowForward from "../../images/arrow-forward.svg";
import ArrowBackward from "../../images/arrow-backward.svg";
import {AcademyCard} from "../../../theme/AcademyCard";
import {LoadingSpinner} from "../../../core/components/LoadingSpinner";
import {LoadEachImageBeforeShowingComponentWrapper} from "../../../core/components/LoadEachImageBeforeShowingComponentWrapper";
import {CustomerMessageModal} from "../modal/CustomerMessageModal";
import {CustomerMessageModal_CustomerMessageFragment$key} from "../../../../__generated__/CustomerMessageModal_CustomerMessageFragment.graphql";

const NUM_CUSTOMER_MESSAGES_LOADED_AT_ONCE = 10

const QUERY = graphql`
    query AllCustomerMessagesScreen_Query($first: Int!) {
        Viewer {
            CustomerMessageV2 {
                CustomerMessageInformation {
                    id
                    numUnseenCustomerMessages
                }
            }
            License {
                NewLicensesInAccount {
                    id
                    newLicenses
                }
            }
        }
        ...AllCustomerMessagesScreen_CustomerMessagesPaginationFragment @arguments(first: $first)
    }`


const CUSTOMER_MESSAGES_PAGINATION_FRAGMENT = graphql`
    fragment AllCustomerMessagesScreen_CustomerMessagesPaginationFragment on Query @refetchable(queryName: "CustomerMessagesPaginationFragmentRefetchQuery") @argumentDefinitions(
        after: {type: "String"},
        first: {type: "Int"}
    ){
        Viewer {
            CustomerMessageV2 {
                CustomerMessages(after: $after, first: $first) @connection(key: "AllCustomerMessagesScreen_CustomerMessages") {
                    edges {
                        node {
                            id
                            customerMessageDefinition {
                                pages {
                                    edges {
                                        node {
                                            imageOpt {
                                                url
                                            }
                                        }
                                    }
                                }
                            }
                            ...CustomerMessagePreview_CustomerMessageFragment
                            ...CustomerMessageModal_CustomerMessageFragment
                        }
                    }
                }
            }
        }
    }`

interface State {
    allMessagesCarouselPage: number
    selectedMessage?: CustomerMessageModal_CustomerMessageFragment$key
}

export const AllCustomerMessagesScreen = () => {

    const {t} = useTranslation("customerMessages")

    const query = useLazyLoadQuery<AllCustomerMessagesScreen_Query>(QUERY, {first: NUM_CUSTOMER_MESSAGES_LOADED_AT_ONCE}, {fetchPolicy: "network-only"})

    const {
        data,
        loadNext,
        hasNext,
        isLoadingNext
    } = usePaginationFragment<CustomerMessagesPaginationFragmentRefetchQuery, AllCustomerMessagesScreen_CustomerMessagesPaginationFragment$key>(CUSTOMER_MESSAGES_PAGINATION_FRAGMENT, query)

    const carousel = useRef<any>(null)

    const [state, setState] = useState<State>({allMessagesCarouselPage: 0})

    const {height: viewportHeight} = useViewportDimensions()

    const customerMessages = data.Viewer.CustomerMessageV2.CustomerMessages.edges?.filter(e => e?.node.customerMessageDefinition?.pages.edges?.filter(e => e?.node.imageOpt?.url)[0]).map(e => e!.node) || []

    const numDefinitionsInCarousel = customerMessages.length > 2 ? 3 : customerMessages.length

    const canMoveForward = state.allMessagesCarouselPage < customerMessages.length - 3 || hasNext
    const canMoveBackward = state.allMessagesCarouselPage > 0

    const navForward = () => {
        if (carousel.current) {
            if (state.allMessagesCarouselPage === customerMessages.length - 3) {
                loadNext(NUM_CUSTOMER_MESSAGES_LOADED_AT_ONCE, {
                    onComplete: () => {
                        setState(state => ({...state, allMessagesCarouselPage: state.allMessagesCarouselPage + 1}))
                        carousel.current.navForward({cancelable: false}, state.allMessagesCarouselPage + 1)
                    }
                })
            } else {
                setState(state => ({...state, allMessagesCarouselPage: state.allMessagesCarouselPage! + 1}))
                carousel.current.navForward({cancelable: false}, state.allMessagesCarouselPage! + 1)
            }
        }
    }

    const navBackward = () => {
        if (carousel.current) {
            setState(state => ({...state, allMessagesCarouselPage: state.allMessagesCarouselPage - 1}))
            carousel.current.navBackward({cancelable: false}, state.allMessagesCarouselPage - 1)
        }
    }

    const limitHeight = 520
    const modalHeightBellowMaxHeight = 0.58 * viewportHeight
    const modalHeight = modalHeightBellowMaxHeight > limitHeight ? limitHeight : modalHeightBellowMaxHeight
    const modalWidth = modalHeight * 9 / 16

    return <>
        <Screen
            headerBarBackgroundSlot={<BlueBackground height={60}/>}
            sidebarSlot={<MainSidebar
                numUnseenCustomerMessages={query.Viewer.CustomerMessageV2.CustomerMessageInformation.numUnseenCustomerMessages} hasNewLicensesInAccount={query.Viewer.License.NewLicensesInAccount.newLicenses}/>}
            headerMargin={30}
        >
            <>
                <h1 className="mb-4">{t("all-customer-messages.heading")}</h1>
                <Description className="mb-3">{t("all-customer-messages.description")}</Description>

                <Suspense fallback={<LoadingSpinner/>}>{!isLoadingNext ?
                    <LoadEachImageBeforeShowingComponentWrapper
                        imageUrls={customerMessages.map(cmd => cmd.customerMessageDefinition!.pages.edges!.map(e => e!.node.imageOpt!.url)[0]!)}
                        showBeforeLoaded={<LoadingSpinner/>}><StyledCard numDefinitions={numDefinitionsInCarousel}
                                                                         modalWidth={modalWidth}>
                        {customerMessages.length ?
                            <CarouselContainer viewportHeight={viewportHeight}
                                               className={"d-flex justify-content-center"}>
                                <NavImage modalWidth={modalWidth} onClick={navBackward} src={ArrowBackward} alt={""}
                                          show={canMoveBackward}/>
                                <StyledCarousel
                                    numDefinitions={numDefinitionsInCarousel}
                                    modalWidth={modalWidth}
                                    value={customerMessages}
                                    itemTemplate={(customerMessage) => <CustomerMessagePreview
                                        key={customerMessage.id}
                                        selectMessage={() => setState(state => ({
                                            ...state,
                                            selectedMessage: customerMessage
                                        }))}
                                        modalWidth={modalWidth}
                                        customerMessageFragmentRef={customerMessage}
                                    />}
                                    numVisible={3}
                                    numScroll={1}
                                    ref={carousel}
                                >
                                </StyledCarousel>
                                <NavImage modalWidth={modalWidth} onClick={navForward} src={ArrowForward} alt={""}
                                          show={canMoveForward}/>
                            </CarouselContainer> : <div className="p-3">{t("all-customer-messages.no-messages")}</div>}
                    </StyledCard></LoadEachImageBeforeShowingComponentWrapper> : <LoadingSpinner/>}</Suspense>
            </>
        </Screen>
        <CustomerMessageModal customerMessageFragment={state.selectedMessage}
                              hideModal={() => setState(state => ({...state, selectedMessage: undefined}))}/>
    </>
}

const Description = styled.div`
    ${tkTypography.regular}
`

const NavImage = styled.img<{ modalWidth: number, show: boolean }>`
    margin-top: ${props => (16 / 18 - 0.065) * props.modalWidth}px;
    cursor: pointer;
    width: ${props => props.modalWidth * 0.065}px;
    height: ${props => props.modalWidth * 0.065}px;
    ${props => !props.show && "visibility: hidden;"}
`

const StyledCard = styled(AcademyCard)<{ numDefinitions: number, modalWidth: number }>`
    padding: ${props => props.modalWidth * 0.05}px 0px;
`

const CarouselContainer = styled.div<{ viewportHeight: number }>`
    height: 0.7 * ${props => props.viewportHeight}px;
`

const StyledCarousel = styled(Carousel)<{ numDefinitions: number, modalWidth: number }>`
    .p-carousel-indicators {
        display: none;
    }
    
    .p-carousel-next {
        display: none;
    }
    
    .p-carousel-prev {
        display: none;
    }
    
    .p-carousel-item {
        justify-content: center;
        display: flex;
    }
    
    width: ${props => props.modalWidth * props.numDefinitions * 1.1}px;
`
