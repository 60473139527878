/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdditionalContentType = "file" | "text" | "video" | "%future added value";
export type AdditionalContentItem_AdditionalContentWithLessonPathFragment = {
    readonly lessonPath: {
        readonly lessonIndex: number;
        readonly moduleIndex: number;
    };
    readonly additionalContent: {
        readonly contentType: AdditionalContentType;
        readonly id: string;
        readonly title: string;
        readonly " $fragmentRefs": FragmentRefs<"AdditionalContentDisplay_AdditionalContentFragment">;
    };
    readonly " $refType": "AdditionalContentItem_AdditionalContentWithLessonPathFragment";
};
export type AdditionalContentItem_AdditionalContentWithLessonPathFragment$data = AdditionalContentItem_AdditionalContentWithLessonPathFragment;
export type AdditionalContentItem_AdditionalContentWithLessonPathFragment$key = {
    readonly " $data"?: AdditionalContentItem_AdditionalContentWithLessonPathFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"AdditionalContentItem_AdditionalContentWithLessonPathFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdditionalContentItem_AdditionalContentWithLessonPathFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LessonPath",
      "kind": "LinkedField",
      "name": "lessonPath",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lessonIndex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleIndex",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "additionalContent",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contentType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AdditionalContentDisplay_AdditionalContentFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AdditionalContentWithLessonPath",
  "abstractKey": null
};
(node as any).hash = 'ebbf248c3fe9c3ffe58f30020472908c';
export default node;
