import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_DELETE_INVITATION = "delete-invitation";

export const deleteInvitationAction = (invitationId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DELETE_INVITATION,
            callType: API_DELETE_INVITATION
        },
        payload: invitationId
    } as ApiCallAction<string>;
};
