/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CartStep_CartFragment = {
    readonly cartSelection: {
        readonly selectedDiscountCodes: ReadonlyArray<string>;
        readonly selectedProducts: ReadonlyArray<{
            readonly amount: number;
            readonly productId: string;
        }>;
    };
    readonly cartTotals: {
        readonly totalsIncludingAllDiscounts: {
            readonly netPrice: number;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"CartTotalDisplay_CartTotalsFragment">;
    };
    readonly cartItems: ReadonlyArray<{
        readonly code?: string;
        readonly " $fragmentRefs": FragmentRefs<"CartItem_CartItemFragment">;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"UpsellsDisplay_CartFragment" | "CodeRefusalExplanation_RefusalsFragment" | "BulkDiscount_Cart">;
    readonly " $refType": "CartStep_CartFragment";
};
export type CartStep_CartFragment$data = CartStep_CartFragment;
export type CartStep_CartFragment$key = {
    readonly " $data"?: CartStep_CartFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CartStep_CartFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CartStep_CartFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CartSelection",
      "kind": "LinkedField",
      "name": "cartSelection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "selectedDiscountCodes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SelectedProduct",
          "kind": "LinkedField",
          "name": "selectedProducts",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CartTotals",
      "kind": "LinkedField",
      "name": "cartTotals",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceV3",
          "kind": "LinkedField",
          "name": "totalsIncludingAllDiscounts",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "netPrice",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CartTotalDisplay_CartTotalsFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "cartItems",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CartItem_CartItemFragment"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            }
          ],
          "type": "DiscountCodeCartItem",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpsellsDisplay_CartFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CodeRefusalExplanation_RefusalsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BulkDiscount_Cart"
    }
  ],
  "type": "Cart",
  "abstractKey": null
};
(node as any).hash = '04d9c7aa03314e2e5ce3b307708502a9';
export default node;
