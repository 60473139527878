import React from 'react';


interface OwnProps {
    persologClass: string
    percentage: number
}

export const PersologProgressBar = ({persologClass, percentage}: OwnProps) => {
    let color = 'black'
    switch (persologClass) {
        case 'd':
            color = 'rgb(206,75,37)'
            break;
        case 'i':
            color = 'rgb(241, 206, 68)';
            break;
        case 's':
            color = 'rgb(98, 195, 115)';
            break;
        case 'g':
            color = 'rgb(50, 102, 178)';
            break;
    }

    return <div
        className="d-flex flex-row align-items-center position-relative justify-content-center persolog-progress-bar">
        <div className="tk-academy-larger-bold label">
            <p>{persologClass.toUpperCase()}</p>
        </div>
        <div className="progress-bar-container">
            <div className="progress-bar" style={{
                backgroundColor: color,
                width: percentage + "%"
            }}/>
        </div>
        <div className="progress-bar-middle-mark">
            <div className="middle-mark"/>
        </div>
    </div>
}

