/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type RegistrationInput = {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    companySize?: string | null;
    industry?: string | null;
    position?: string | null;
    adsOptIn?: boolean | null;
    clientMutationId?: string | null;
};
export type RegistrationScreen_RegisterMutationVariables = {
    input: RegistrationInput;
};
export type RegistrationScreen_RegisterMutationResponse = {
    readonly Auth: {
        readonly register: {
            readonly userId: string;
        } | null;
    };
};
export type RegistrationScreen_RegisterMutation = {
    readonly response: RegistrationScreen_RegisterMutationResponse;
    readonly variables: RegistrationScreen_RegisterMutationVariables;
};



/*
mutation RegistrationScreen_RegisterMutation(
  $input: RegistrationInput!
) {
  Auth {
    register(input: $input) {
      userId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthMutations",
    "kind": "LinkedField",
    "name": "Auth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "RegistrationPayload",
        "kind": "LinkedField",
        "name": "register",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RegistrationScreen_RegisterMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RegistrationScreen_RegisterMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f29a666ee49e5ab5999a3f569c115776",
    "id": null,
    "metadata": {},
    "name": "RegistrationScreen_RegisterMutation",
    "operationKind": "mutation",
    "text": "mutation RegistrationScreen_RegisterMutation(\n  $input: RegistrationInput!\n) {\n  Auth {\n    register(input: $input) {\n      userId\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '3f4a707ef9cce3399150157538969ca3';
export default node;
