import React from "react";
import {CourseCard} from "../../common/course-card/CourseCard";
import "./course-list.scss"
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {CourseList_CourseStatesConnectionFragment$key} from "../../../../../__generated__/CourseList_CourseStatesConnectionFragment.graphql";
import {
    CourseAttributes,
    CourseList_CoursesConnectionFragment$key
} from "../../../../../__generated__/CourseList_CoursesConnectionFragment.graphql";
import {COURSE_STATUS} from "../../common/CourseStatusBadge";

const COURSES_CONNECTION_FRAGMENT = graphql`
    fragment CourseList_CoursesConnectionFragment on CoursesConnection {
        edges {
            node {
                id
                attributes
                ...CourseCard_CourseFragment
            }
        }
    }`

const COURSE_STATES_CONNECTION_FRAGMENT = graphql`
    fragment CourseList_CourseStatesConnectionFragment on CourseStatesConnection {
        edges {
            node {
                course {
                    id
                }
                isBought
                isDone
                ...CourseCard_CourseStateFragment
            }
        }
    }`

interface OwnProps {
    coursesConnectionFragmentRef: CourseList_CoursesConnectionFragment$key;
    courseStatesConnectionFragmentRef: CourseList_CourseStatesConnectionFragment$key;
}

export const CourseList = ({coursesConnectionFragmentRef, courseStatesConnectionFragmentRef}: OwnProps) => {
    const coursesConnection = useFragment<CourseList_CoursesConnectionFragment$key>(COURSES_CONNECTION_FRAGMENT, coursesConnectionFragmentRef)
    const courseStatesConnection = useFragment<CourseList_CourseStatesConnectionFragment$key>(COURSE_STATES_CONNECTION_FRAGMENT, courseStatesConnectionFragmentRef)

    const allCourses = coursesConnection.edges?.filter(e => !!e).map(e => e!.node) || [];
    const allCourseStates = courseStatesConnection.edges?.filter(e => !!e).map(e => e!.node) || [];

    const courses = allCourses.map(course => {
        const state = allCourseStates.find(cs => course.id === cs.course.id);
        return state ? {course: course, state: state} : {course: course, state: undefined}
    })

    const addStatusToCourse = (course: { course: any, state: any }, status: string) => ({
        course: course.course,
        state: course.state,
        status: status
    })

    const coursesWithoutState = courses.filter(c => !c.state)
    const earlyBirdCoursesWithoutState = coursesWithoutState.filter(cws => cws.course.attributes.find(a => a === "earlyBird" as CourseAttributes) && !cws.course.attributes.find(a => a === "new" as CourseAttributes)).map(c => addStatusToCourse(c, COURSE_STATUS.EARLY_BIRD))
    const newCoursesWithoutState = coursesWithoutState.filter(cws => cws.course.attributes.find(a => a === "new" as CourseAttributes) && !cws.course.attributes.find(a => a === "earlyBird" as CourseAttributes)).map(c => addStatusToCourse(c, COURSE_STATUS.NEW))
    const coursesWithoutStateThatAreNotEarlyBirdAndNotNew = coursesWithoutState.filter(cws => !cws.course.attributes.find(a => a === "new" as CourseAttributes) && !cws.course.attributes.find(a => a === "earlyBird" as CourseAttributes)).map(c => addStatusToCourse(c, COURSE_STATUS.NONE))
    const coursesWithState = courses.filter(c => c.state)
    const finishedCourses = coursesWithState.filter(c => c.state!.isDone).map(c => addStatusToCourse(c, COURSE_STATUS.FINISHED))
    const unfinishedCoursesWithState = coursesWithState.filter(c => !c.state!.isDone)
    const boughtUnfinishedCoursesWithState = unfinishedCoursesWithState.filter(c => c.state!.isBought).map(c => addStatusToCourse(c, COURSE_STATUS.ACTIVE))
    const notBoughtUnfinishedCoursesWithState = unfinishedCoursesWithState.filter(c => !c.state!.isBought).map(c => addStatusToCourse(c, COURSE_STATUS.TEST))

    const allCoursesSorted = [...earlyBirdCoursesWithoutState, ...newCoursesWithoutState, ...boughtUnfinishedCoursesWithState, ...notBoughtUnfinishedCoursesWithState, ...coursesWithoutStateThatAreNotEarlyBirdAndNotNew, ...finishedCourses];

    return <div className="course-list">
        <div className="mb-3 d-flex align-items-center">
            <h2 className="tk-academy-small-text tk-color-waterblue-80">Meine Lehrgänge</h2>
        </div>
        <div className="course-card-wrapper">
            {allCoursesSorted.map(c => {
                return <CourseCard key={c.course.id} courseFragmentRef={c.course} courseStateFragmentRef={c.state} courseStatus={c.status}/>
            })}
        </div>
    </div>
}