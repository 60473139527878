import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_FETCH_STATES = "fetch-states";

export const fetchStatesAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FETCH_STATES,
            callType: API_FETCH_STATES
        },
        payload: undefined
    } as ApiCallAction<undefined>;
};
