import {ExpertList} from "./expert-list/ExpertList";
import React from "react";
import {CourseAd} from "../../../../core/components/coursead/CourseAd";
import {LicenseAvailable} from "../../../../licenses/components/LicenseAvailable";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {CourseInfo_CourseFragment$key} from "../../../../../__generated__/CourseInfo_CourseFragment.graphql";
import {CourseInfo_CourseStateFragment$key} from "../../../../../__generated__/CourseInfo_CourseStateFragment.graphql";
import {CourseAdditionalContentList} from "./additional-content/CourseAdditionalContentList";
import {CourseInfo_LicensePoolsFragment$key} from "../../../../../__generated__/CourseInfo_LicensePoolsFragment.graphql";

const COURSE_FRAGMENT = graphql`
    fragment CourseInfo_CourseFragment on Course {
        containerMeta {
            description
            title
        }
        ...CourseAd_CourseFragment
        ...CourseAdditionalContentList_CourseFragment
        ...ExpertList_CourseFragment
        ...LicenseAvailable_CourseFragment
    }`

const COURSE_STATE_FRAGMENT = graphql`
    fragment CourseInfo_CourseStateFragment on CourseState {
        ...CourseAd_CourseStateFragment
        ...CourseAdditionalContentList_CourseStateFragment
        ...LicenseAvailable_CourseStateFragment
    }`

const LICENSE_POOLS_FRAGMENT = graphql`
    fragment CourseInfo_LicensePoolsFragment on LicensePoolsConnection {
        ...LicenseAvailable_LicensePoolsFragment
    }`

interface OwnProps {
    courseFragmentRef: CourseInfo_CourseFragment$key
    courseStateFragmentRef: CourseInfo_CourseStateFragment$key | null
    licensePoolsFragmentRef: CourseInfo_LicensePoolsFragment$key
    refetchCourseDetailScreenQuery: () => void
}

export const CourseInfo = ({
                               courseFragmentRef,
                               courseStateFragmentRef,
                               licensePoolsFragmentRef,
                               refetchCourseDetailScreenQuery
                           }: OwnProps) => {

    const course = useFragment<CourseInfo_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef)
    const courseState = useFragment<CourseInfo_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, courseStateFragmentRef)
    const licensePools = useFragment<CourseInfo_LicensePoolsFragment$key>(LICENSE_POOLS_FRAGMENT, licensePoolsFragmentRef)

    return course && <>
        <CourseAd courseFragmentRef={course} courseStateFragmentRef={courseState}/>
        <LicenseAvailable licensePoolsFragmentRef={licensePools} courseFragmentRef={course}
                          courseStateFragmentRef={courseState} refetchCourseDetailScreenQuery={refetchCourseDetailScreenQuery}/>

        <div className="tk-academy-text tk-academy-bold mb-2 tk-color-waterblue-80" id="info">Übersicht</div>
        <div className="tk-academy-extra-large-heading-bold mb-5">{course.containerMeta.title}</div>

        <div className="w-100 tk-academy-card p-5 mb-5 tk-academy-text tk-color-waterblue-80"
             dangerouslySetInnerHTML={{__html: course.containerMeta.description}}>
        </div>

        <ExpertList courseFragmentRef={course}/>
        {courseState && <CourseAdditionalContentList courseFragmentRef={course} courseStateFragmentRef={courseState}/>}
    </>
}
