import {AcademyButton} from "../../core/components/button/AcademyButton";
import {NavLink} from "react-router-dom";
import React from "react";
import {useFragment, useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {useTranslation} from "react-i18next";
import {LicensePoolDisplay_LicensePoolFragment$key} from "../../../__generated__/LicensePoolDisplay_LicensePoolFragment.graphql";
import {LicensePoolDisplay_CourseFragment$key} from "../../../__generated__/LicensePoolDisplay_CourseFragment.graphql";
import {LicensePoolDisplay_RequestLicenseMutation} from "../../../__generated__/LicensePoolDisplay_RequestLicenseMutation.graphql";
import styled from "styled-components";
import {AcademyCard} from "../../theme/AcademyCard";
import {generateCartLink} from "../../billing/v3/utils/cart-link";
import {ApplyLicenseButton} from "../../state/components/ApplyLicenseButton";

const COURSE_FRAGMENT = graphql`
    fragment LicensePoolDisplay_CourseFragment on Course {
        id
        mainProduct
        mainDiscountCode
        
        ...ApplyLicenseButton_CourseFragment
    }
`

const LICENSE_POOL_FRAGMENT = graphql`
    fragment LicensePoolDisplay_LicensePoolFragment on LicensePool {
        id
        account {
            name
        }
        applicationRuleStatus(courseId: $courseId)
        
        ...ApplyLicenseButton_LicensePoolFragment
    }
`

const REQUEST_LICENSE_MUTATION = graphql`
    mutation LicensePoolDisplay_RequestLicenseMutation($courseId: ID!, $licensePoolId: ID!) {
        License {
            requestLicense(input: {courseId: $courseId, licensePoolId: $licensePoolId}) {
                licensePool {
                    node {
                        ...LicensePoolDisplay_LicensePoolFragment
                    }
                }
            }
        }
    }
`

interface OwnProps {
    courseFragmentRef: LicensePoolDisplay_CourseFragment$key
    licensePoolFragmentRef: LicensePoolDisplay_LicensePoolFragment$key
    refetchCourseDetailScreenQuery?: () => void
}

export const LicensePoolDisplay = ({courseFragmentRef, licensePoolFragmentRef, refetchCourseDetailScreenQuery}: OwnProps) => {
    const {t} = useTranslation("licenses")

    const course = useFragment<LicensePoolDisplay_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef)

    const licensePool = useFragment<LicensePoolDisplay_LicensePoolFragment$key>(LICENSE_POOL_FRAGMENT, licensePoolFragmentRef)

    const [requestLicense, licenseBeingRequested] = useMutation<LicensePoolDisplay_RequestLicenseMutation>(REQUEST_LICENSE_MUTATION)

    switch (licensePool.applicationRuleStatus) {
        case "applicationInProgress":
            return <LicensePoolContainer>
                <div className="mr-4 tk-academy-text">
                    {t("licenses-available.application-in-progress", {accountName: licensePool.account.name})}
                </div>
            </LicensePoolContainer>
        case "canFreelyUse":
            return <LicensePoolContainer>
                <div className="mr-4 tk-academy-text">
                    {t("licenses-available.licenses-available", {accountName: licensePool.account.name})}
                </div>
                <ApplyLicenseButton courseFragmentRef={course} licensePoolFragmentRef={licensePool} refetchCourseDetailScreenQuery={refetchCourseDetailScreenQuery}/>
            </LicensePoolContainer>
        case "applicationNecessary":
            return <LicensePoolContainer>
                <div className="mr-4 tk-academy-text">
                    {t("licenses-available.application-necessary", {accountName: licensePool.account.name})}
                </div>
                <AcademyButton
                    disabled={licenseBeingRequested}
                    onClick={() => {
                        requestLicense({
                            variables: {
                                courseId: course.id,
                                licensePoolId: licensePool.id
                            }
                        })
                    }}
                    className="ml-auto"
                    text={t("licenses-available.apply-for-more", {accountName: licensePool.account.name})}/>
            </LicensePoolContainer>;

        case "cannotApply":
        case "poolEmpty":
            return <LicensePoolContainer>
                <div className="mr-4 tk-academy-text">
                    {t("licenses-available.pool-empty")}
                </div>
                <NavLink className="ml-auto"
                         to={course.mainProduct ? generateCartLink(course.mainProduct, course.mainDiscountCode) : ""}>
                    <AcademyButton
                        text={t("licenses-available.buy-more", {accountName: licensePool.account.name})}/>
                </NavLink>
            </LicensePoolContainer>
        case "duplicateApplicationNotPossible":
            return <LicensePoolContainer>
                <div className="mr-4 tk-academy-text">
                    {t("licenses-available.duplicate-application-not-possible", {accountName: licensePool.account.name})}
                </div>
            </LicensePoolContainer>
        default:
            return null;
    }
}

const LicensePoolContainer = styled(AcademyCard)`
  padding: 25px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.palette.white};
  background: linear-gradient(90deg, rgba(41,228,155,1) 0%, rgba(36,125,197,1) 100%);
`
