import {Action} from "redux";
import {LOCATION_CHANGE} from "connected-react-router";

interface State {
    previousLocation?: Location
    location?: Location
}

export default function extendedRouter(state: State = {}, action: Action) {
    switch (action.type) {
        case LOCATION_CHANGE:
            const newState = {
                ...state,
                previousLocation: state.location,
                ...((action as any).payload)
            }
            return newState;
        default:
            return state;
    }
}
