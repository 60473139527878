import {ProductDisplay} from "./ProductDisplay";
import React from "react";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {UpsellsDisplay_CartFragment$key} from "../../../../../__generated__/UpsellsDisplay_CartFragment.graphql";
import styled from "styled-components";
import {LargeText} from "../../../../theme/Typography";
import {Carousel, CarouselItem} from "react-bootstrap";

const CART_FRAGMENT = graphql`
    fragment UpsellsDisplay_CartFragment on Cart {
        cartItems {
            itemType
        }
        otherAvailableProducts {
            edges {
                node {
                    id
                    ...ProductDisplay_ProductFragment
                }
            }
        }
    }
`;

interface OwnProps {
    cartFragmentRef: UpsellsDisplay_CartFragment$key | null
    addToCart: (productId: string) => void;
}

export const UpsellsDisplay = ({cartFragmentRef, addToCart}: OwnProps) => {
    const cart = useFragment<UpsellsDisplay_CartFragment$key>(CART_FRAGMENT, cartFragmentRef)

    return cart?.otherAvailableProducts.edges?.length ? <UpsellContainer>
        <Heading>{cart?.cartItems.length === 0 ? "Produkte" : "Weitere Produkte"}</Heading>

        <HideOnMobile>
            <ItemContainer>
                {cart?.otherAvailableProducts.edges?.map(edge => {
                    return <ProductDisplay
                        key={edge!.node.id}
                        productFragmentRef={edge!.node}
                        addToCart={() => {
                            const productId = edge!.node.id;
                            addToCart(productId)
                        }}
                    />
                })}
            </ItemContainer>
        </HideOnMobile>
        <HideOnDesktop>
            <Carousel touch={true}>
                {cart?.otherAvailableProducts.edges?.map(edge => {
                    return <CarouselItem key={edge!.node.id}>
                        <ProductDisplay
                            productFragmentRef={edge!.node}
                            addToCart={() => {
                                const productId = edge!.node.id;
                                addToCart(productId)
                            }}
                        />
                    </CarouselItem>
                })}
            </Carousel>
        </HideOnDesktop>
    </UpsellContainer> : null;
}

const UpsellContainer = styled.div`
  margin-top: 25px;
  padding-top: 25px;
  border-top: solid 1px #e7ebf1;
  padding-bottom: 20px;

  .carousel-indicators {
    bottom: -40px;

    li {
      background-color: #a7c9ea;

      &.active {
        background-color: #2577c9;
      }
    }
  }
`

const Heading = styled.h3`
  ${LargeText};
  font-weight: bold;
  margin-bottom: 15px;
  color: #184276;
`

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`

const HideOnMobile = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`
const HideOnDesktop = styled.div`
  @media (min-width: 1025px) {
    display: none;
  }
`
