import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_START_COURSE = "start-couse";

export interface StartCoursePayload {
    courseId: string
}

export const startCourseAction = (courseId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_START_COURSE,
            callType: API_START_COURSE
        },
        payload: {
            courseId
        }
    } as ApiCallAction<StartCoursePayload>;
};
