import React, { ReactNode } from "react";
import "./screen.scss";

interface OwnProps {
    className?: string;
    sidebarSlot?: ReactNode;
    headerBarBackgroundSlot?: ReactNode;
    floatingFooterSlot?: ReactNode;
    contentAreaRenderer?: (children: any) => ReactNode;
    headerMargin?: number;
    children: ReactNode;
}

export const Screen = ({
                           className,
                           sidebarSlot,
                           headerBarBackgroundSlot,
                           headerMargin,
                           floatingFooterSlot,
                           children,
                           contentAreaRenderer
                       }: OwnProps) => {
    return <div className={(className ? className : "") + " screen d-flex w-100"}>
        {sidebarSlot ? sidebarSlot : null}
        <div className="position-relative h-100 w-100 d-flex flex-column"
             style={floatingFooterSlot ? {paddingBottom: 80} : {}}>
            {headerBarBackgroundSlot ? headerBarBackgroundSlot : null}

            {contentAreaRenderer ? contentAreaRenderer(children) : <div
                style={{marginTop: headerMargin}}
                className="content-area tk-bg-light flex-grow-1 p-0 d-flex flex-column p-5">
                <div className={"inner-content"}>
                    {children}
                </div>
            </div>}

            {floatingFooterSlot ? <div className="position-absolute floating-footer-wrapper w-100 tk-bg-white">
                {floatingFooterSlot}
            </div> : null}
        </div>

    </div>

}
