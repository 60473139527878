import React from "react";

interface ImageProps {
    src?: string;
    alt?: string;
    className?: string;
}

export const ImageWithFallback = ({src, alt, className}: ImageProps) => {
    return <img src={src} alt={alt} className={className}/>
}
