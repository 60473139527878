import {Screen} from "../../core/components/screen/Screen";
import {BlueBackground} from "../../core/components/screen/BlueBackground";
import React from "react";
import {UserSettingsForm} from "./UserSettingsForm";
import {OrderList} from "./OrderList";
import {CertificateList} from "./CertificateList";
import {graphql} from "babel-plugin-relay/macro";
import {useLazyLoadQuery} from "react-relay";
import {MainSidebar} from "../../core/components/sidebar/MainSidebar";
import {UserSettingsScreen_Query} from "../../../__generated__/UserSettingsScreen_Query.graphql";
import {AnonymizeMyselfButton} from "../../manager/components/AnonymizeMyselfButton";
import {useTranslation} from "react-i18next";

const QUERY = graphql`
    query UserSettingsScreen_Query  {
        Viewer {
            CustomerMessageV2 {
                CustomerMessageInformation {
                    id
                    numUnseenCustomerMessages
                }
            }
            License{
                NewLicensesInAccount {
                    id
                    newLicenses
                }
            }
        }
    }
`

export const UserSettingsScreen = () => {
    const {t} = useTranslation("manager")

    const query = useLazyLoadQuery<UserSettingsScreen_Query>(QUERY, {}, {fetchPolicy: "network-only"})

    return <Screen
        sidebarSlot={<MainSidebar
            numUnseenCustomerMessages={query.Viewer.CustomerMessageV2.CustomerMessageInformation.numUnseenCustomerMessages}
            hasNewLicensesInAccount={query.Viewer.License.NewLicensesInAccount.newLicenses}
        />}
        headerBarBackgroundSlot={<BlueBackground height={90}/>}
        headerMargin={30}
    >
        <UserSettingsForm/>
        <h2 className="tk-academy-large-bold mb-2 mt-4">{t("manager-main-page.heading-anonymize-myself")}</h2>
        <p className="tk-academy-text mb-4"
           dangerouslySetInnerHTML={{__html: t("manager-main-page.explanation-anonymize-myself")}}/>
        <AnonymizeMyselfButton/>

        <OrderList/>
        <CertificateList/>
    </Screen>
}
