import React, {useCallback, useState} from "react";

import "./../../auth/components/registration/registration-screen.scss";
import {RegistrationData, RegistrationForm} from "../../auth/components/registration/RegistrationForm";
import {useHistory, useRouteMatch} from "react-router";
import {RegistrationScreenContainer} from "../../auth/components/registration/RegistrationScreenContainer";
import {TelefonicaRegistrationForm} from "../../auth/components/registration/TelefonicaRegistrationsForm";
import {graphql} from "babel-plugin-relay/macro";
import {useLazyLoadQuery, useMutation} from "react-relay";
import {RegistrationInLicensePoolScreen_LicensePoolInformationQuery} from "../../../__generated__/RegistrationInLicensePoolScreen_LicensePoolInformationQuery.graphql";
import {RegistrationInLicensePoolScreen_RegisterMutation} from "../../../__generated__/RegistrationInLicensePoolScreen_RegisterMutation.graphql";
import {trackRegistration} from "../../analytics/analytics";
import * as Sentry from "@sentry/react";

const QUERY = graphql`
    query RegistrationInLicensePoolScreen_LicensePoolInformationQuery($licensePoolId: ID!) {
        LicenseAuth {
            LicensePoolInformation(licensePoolId: $licensePoolId) {
                accountName
            }
        }
    }`

const MUTATION = graphql`
    mutation RegistrationInLicensePoolScreen_RegisterMutation($input: RegistrationInLicensePoolInput!) {
        LicenseAuth {
            register(input: $input) {
                userId
            }
        }
    }`

interface State {
    registrationSucceeded: boolean
}

export const RegistrationInLicensePoolScreen = () => {

    const route = useRouteMatch<{ licensePoolId: string }>();

    const [state, setState] = useState<State>({registrationSucceeded: false})

    const history = useHistory()

    const query = useLazyLoadQuery<RegistrationInLicensePoolScreen_LicensePoolInformationQuery>(QUERY, {licensePoolId: route.params.licensePoolId});

    const [register, isInFlight] = useMutation<RegistrationInLicensePoolScreen_RegisterMutation>(MUTATION)

    const doRegister = useCallback((input: RegistrationData) => {
        register({
            variables: {
                input: {
                    accountMeta: input.accountMeta ? Object.entries(input.accountMeta).map(am => ({
                        key: am[0],
                        value: am[1]
                    })) : [],
                    adsOptIn: input.adsOptIn,
                    companySize: input.companySize,
                    email: input.email,
                    firstName: input.firstName,
                    industry: input.industry,
                    lastName: input.lastName,
                    licensePoolId: route.params.licensePoolId,
                    password: input.password,
                    position: input.position
                }
            },
            onCompleted: (response) => {
                setState(_ => ({registrationSucceeded: true}))

                try {
                    if(response.LicenseAuth.register?.userId) trackRegistration(input.email, input.firstName, input.lastName, response.LicenseAuth.register.userId)
                } catch (e) {
                    Sentry.captureException(e)
                }
            }
        })
        // eslint-disable-next-line
    }, [route.params.licensePoolId])

    return <RegistrationScreenContainer>
        {query.LicenseAuth.LicensePoolInformation.accountName === "Telefónica" ? <TelefonicaRegistrationForm
            doRegister={doRegister}
            isInFlight={isInFlight}
            registrationSucceeded={state.registrationSucceeded}
        /> : <RegistrationForm
            doRegister={doRegister}
            isInFlight={isInFlight}
            registrationSucceeded={state.registrationSucceeded}
            switchToLogin={() => history.push("/login")}
        />}
    </RegistrationScreenContainer>
}
