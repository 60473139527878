import React from 'react';
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import styled from 'styled-components';
import {CustomerMessagePreview_CustomerMessageFragment$key} from "../../../../__generated__/CustomerMessagePreview_CustomerMessageFragment.graphql";

const CUSTOMER_MESSAGE_FRAGMENT = graphql`
    fragment CustomerMessagePreview_CustomerMessageFragment on CustomerMessageV2 {
        customerMessageDefinition {
            pages {
                edges {
                    node {
                        imageOpt {
                            url
                        }
                    }
                }
            }
        }
        hasSeen
    }`

interface OwnProps {
    selectMessage: () => void
    modalWidth: number
    customerMessageFragmentRef: CustomerMessagePreview_CustomerMessageFragment$key
}

export const CustomerMessagePreview = ({
                                           selectMessage,
                                           modalWidth,
                                           customerMessageFragmentRef
                                       }: OwnProps) => {
    const customerMessage = useFragment<CustomerMessagePreview_CustomerMessageFragment$key>(CUSTOMER_MESSAGE_FRAGMENT, customerMessageFragmentRef)

    const edges = customerMessage.customerMessageDefinition?.pages.edges

    if(!edges) return null

    const url = edges[0]?.node.imageOpt?.url

    if (!url) return null

    return <div>
        {!customerMessage.hasSeen && <Badge modalWidth={modalWidth}/>}
        <Image onClick={selectMessage} src={url} alt={""} modalWidth={modalWidth}/>
    </div>
}

const Badge = styled.div<{ modalWidth: number }>`
    width: ${props => props.modalWidth * 0.05}px;
    height: ${props => props.modalWidth * 0.05}px;
    border-radius: 50%;
    background-color: red;
    position: absolute;
    margin-top: ${props => props.modalWidth * 0.02}px;
    margin-left: ${props => props.modalWidth * 0.02}px;
`

const Image = styled.img<{ modalWidth: number }>`
  width: ${props => props.modalWidth}px;
  border-radius: 10px;
  cursor: pointer;
`