import {
    orderElementGraphQLToOrderElement,
    useOrderLogicV2
} from "@thekeytechnology/thekey-academy-frontend-library";
import {BaseElementDisplay} from "../common/BaseElementDisplay";
import React from "react";
import {OrderableDisplay} from "./OrderableDisplay";
import "./order-element-display.scss";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import * as Sentry from "@sentry/react";
import {OrderElementDisplay_OrderElementFragment$key} from "../../../../__generated__/OrderElementDisplay_OrderElementFragment.graphql";

const ORDER_ELEMENT_DISPLAY_ORDER_ELEMENT_FRAGMENT = graphql`
    fragment OrderElementDisplay_OrderElementFragment on OrderElement {
        elementType
        elementPath {
            courseRef
            moduleRef
            lessonRef
        }
        generalSettings {
            points
        }
        id
        orderables {
            text
            ...OrderableDisplay_OrderableFragment
        }
        title
    }`

interface OwnProps {
    orderElementFragmentRef: OrderElementDisplay_OrderElementFragment$key;
}

export const OrderElementDisplay = ({
                                        orderElementFragmentRef
                                    }: OwnProps) => {
    const element = useFragment<OrderElementDisplay_OrderElementFragment$key>(ORDER_ELEMENT_DISPLAY_ORDER_ELEMENT_FRAGMENT, orderElementFragmentRef)

    const {currentOrder, moveElement} = useOrderLogicV2(orderElementGraphQLToOrderElement(element))

    return <BaseElementDisplay
        className="order-element-display"
        heading={element.title}
        elementSpecificSlot={
            <div className="orderable-container">
                {currentOrder.map((orderableIndex, index) => {
                    const orderable = element.orderables.length > orderableIndex ? element.orderables[orderableIndex] : undefined
                    if (!orderable)
                        Sentry.captureException(`OrderElement: Orderable is undefined (Prop. element.orderables out of index [CourseRef: ${element.elementPath.courseRef} | ModuleRef: ${element.elementPath.moduleRef} | LessonRef: ${element.elementPath.lessonRef}] | ElementRef: ${element.id}`)

                    return orderable ?
                        <OrderableDisplay key={index} currentIndex={index} orderableFragmentRef={orderable}
                                          moveElement={moveElement}/> :
                        null
                })}
            </div>
        }/>
}
