import React from "react";
import {selectCombinedApiState} from "@thekeytechnology/framework-react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {API_CHANGE_USER_SETTINGS} from "@thekeytechnology/thekey-academy-frontend-library";
import {AcademyButton} from "../../core/components/button/AcademyButton";

interface OwnProps {
    className?: string;
    blockSubmit: boolean;
    onSubmit: () => void;
}

type Props = OwnProps;

export const SaveUserSettingsButton = ({
                                           blockSubmit,
                                           onSubmit,
                                           className,
                                       }: Props) => {
    const {t} = useTranslation("usersettings");

    const apiCallState = useSelector(selectCombinedApiState(API_CHANGE_USER_SETTINGS));

    return <AcademyButton
        className={className}
        hideIcon={true}
        disabled={apiCallState.inProgress || blockSubmit}
        text={t("user-settings-form.submit")}
        onClick={() => {
            onSubmit();
        }}/>;
}
