/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BusinessForm_BillingDetailsFragment = {
    readonly salutation: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly companyBillingDetails: {
        readonly company: string;
        readonly companyDetails: string | null;
        readonly companyType: string;
    } | null;
    readonly street: string;
    readonly houseNumber: string;
    readonly postalCode: string;
    readonly city: string;
    readonly " $refType": "BusinessForm_BillingDetailsFragment";
};
export type BusinessForm_BillingDetailsFragment$data = BusinessForm_BillingDetailsFragment;
export type BusinessForm_BillingDetailsFragment$key = {
    readonly " $data"?: BusinessForm_BillingDetailsFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"BusinessForm_BillingDetailsFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BusinessForm_BillingDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salutation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CompanyBillingDetail",
      "kind": "LinkedField",
      "name": "companyBillingDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "company",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyDetails",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "street",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "houseNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    }
  ],
  "type": "BillingDetails",
  "abstractKey": null
};
(node as any).hash = '7c7296e4e24042c0a78c74976c8047d2';
export default node;
