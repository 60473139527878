import './polyfills';
import * as serviceWorker from "./serviceWorker";

import React from "react";
import ReactDOM from "react-dom";

import "./_modules/i18n";
import "./assets/icon-font/style.css";
import "./assets/scss/index.scss";
import {App} from "./App";

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
