/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FailedMultipleChoiceElement_FailedElementFragment = {
    readonly element: {
        readonly answerOptions?: ReadonlyArray<{
            readonly answer: {
                readonly alternatives: ReadonlyArray<string>;
            };
            readonly image: {
                readonly url: string | null;
            } | null;
        }>;
        readonly image?: {
            readonly url: string | null;
        } | null;
        readonly question?: {
            readonly " $fragmentRefs": FragmentRefs<"RandomPhrasing_PhrasingAlternativesFragment">;
        };
    };
    readonly mistakes: {
        readonly explanations?: ReadonlyArray<{
            readonly hasUserChecked: boolean;
            readonly isCorrect: boolean;
        }>;
    };
    readonly " $refType": "FailedMultipleChoiceElement_FailedElementFragment";
};
export type FailedMultipleChoiceElement_FailedElementFragment$data = FailedMultipleChoiceElement_FailedElementFragment;
export type FailedMultipleChoiceElement_FailedElementFragment$key = {
    readonly " $data"?: FailedMultipleChoiceElement_FailedElementFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"FailedMultipleChoiceElement_FailedElementFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FailedMultipleChoiceElement_FailedElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "element",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AnswerOption",
              "kind": "LinkedField",
              "name": "answerOptions",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PhrasingAlternatives",
                  "kind": "LinkedField",
                  "name": "answer",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "alternatives",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "PhrasingAlternatives",
              "kind": "LinkedField",
              "name": "question",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RandomPhrasing_PhrasingAlternativesFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "MultipleChoiceElement",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "mistakes",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MultipleChoiceExplanation",
              "kind": "LinkedField",
              "name": "explanations",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasUserChecked",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isCorrect",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "MultipleChoiceMistakes",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FailedElement",
  "abstractKey": null
};
})();
(node as any).hash = '6222c7bfd68f9abd8a8bc2dae71259bc';
export default node;
