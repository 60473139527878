/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AllCustomerMessagesScreen_CustomerMessagesPaginationFragment = {
    readonly Viewer: {
        readonly CustomerMessageV2: {
            readonly CustomerMessages: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly customerMessageDefinition: {
                            readonly pages: {
                                readonly edges: ReadonlyArray<{
                                    readonly node: {
                                        readonly imageOpt: {
                                            readonly url: string | null;
                                        } | null;
                                    };
                                } | null> | null;
                            };
                        } | null;
                        readonly " $fragmentRefs": FragmentRefs<"CustomerMessagePreview_CustomerMessageFragment" | "CustomerMessageModal_CustomerMessageFragment">;
                    };
                } | null> | null;
            };
        };
    };
    readonly " $refType": "AllCustomerMessagesScreen_CustomerMessagesPaginationFragment";
};
export type AllCustomerMessagesScreen_CustomerMessagesPaginationFragment$data = AllCustomerMessagesScreen_CustomerMessagesPaginationFragment;
export type AllCustomerMessagesScreen_CustomerMessagesPaginationFragment$key = {
    readonly " $data"?: AllCustomerMessagesScreen_CustomerMessagesPaginationFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"AllCustomerMessagesScreen_CustomerMessagesPaginationFragment">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "Viewer",
  "CustomerMessageV2",
  "CustomerMessages"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./CustomerMessagesPaginationFragmentRefetchQuery.graphql.ts')
    }
  },
  "name": "AllCustomerMessagesScreen_CustomerMessagesPaginationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerQueryType",
      "kind": "LinkedField",
      "name": "Viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomerMessageV2ViewerSchema",
          "kind": "LinkedField",
          "name": "CustomerMessageV2",
          "plural": false,
          "selections": [
            {
              "alias": "CustomerMessages",
              "args": null,
              "concreteType": "CustomerMessagesV2Connection",
              "kind": "LinkedField",
              "name": "__AllCustomerMessagesScreen_CustomerMessages_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CustomerMessagesV2Edge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CustomerMessageV2",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "CustomerMessageDefinitionV2",
                          "kind": "LinkedField",
                          "name": "customerMessageDefinition",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "CustomerMessageDefinitionPagesConnection",
                              "kind": "LinkedField",
                              "name": "pages",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "CustomerMessageDefinitionPagesEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "CustomerMessageDefinitionPage",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "File",
                                          "kind": "LinkedField",
                                          "name": "imageOpt",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "kind": "ScalarField",
                                              "name": "url",
                                              "storageKey": null
                                            }
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "CustomerMessagePreview_CustomerMessageFragment"
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "CustomerMessageModal_CustomerMessageFragment"
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '3be2c24a4e66ca3e71fefbb5d1e4dbe4';
export default node;
