/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ElementType = "clozeText" | "image" | "multipleChoice" | "order" | "persolog" | "questionnaire" | "resolution" | "text" | "video" | "%future added value";
export type PersologClassCode = "d" | "g" | "i" | "s" | "%future added value";
export type PersologElementDisplay_PersologElementFragment = {
    readonly elementType: ElementType;
    readonly elementPath: {
        readonly courseRef: string;
        readonly moduleRef: string;
        readonly lessonRef: string;
    };
    readonly generalSettings: {
        readonly points: number;
    };
    readonly id: string;
    readonly questions: ReadonlyArray<{
        readonly answers: ReadonlyArray<{
            readonly answer: string;
            readonly classCode: PersologClassCode;
            readonly " $fragmentRefs": FragmentRefs<"PersologAnswerDisplay_PersologAnswerFragment">;
        }>;
        readonly question: string;
    }>;
    readonly title: string;
    readonly " $fragmentRefs": FragmentRefs<"PersologResultDisplay_PersologElementFragment">;
    readonly " $refType": "PersologElementDisplay_PersologElementFragment";
};
export type PersologElementDisplay_PersologElementFragment$data = PersologElementDisplay_PersologElementFragment;
export type PersologElementDisplay_PersologElementFragment$key = {
    readonly " $data"?: PersologElementDisplay_PersologElementFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"PersologElementDisplay_PersologElementFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersologElementDisplay_PersologElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ElementPath",
      "kind": "LinkedField",
      "name": "elementPath",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "courseRef",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleRef",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lessonRef",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GeneralElementSettings",
      "kind": "LinkedField",
      "name": "generalSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "points",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PersologQuestion",
      "kind": "LinkedField",
      "name": "questions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PersologAnswer",
          "kind": "LinkedField",
          "name": "answers",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "answer",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "classCode",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PersologAnswerDisplay_PersologAnswerFragment"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "question",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersologResultDisplay_PersologElementFragment"
    }
  ],
  "type": "PersologElement",
  "abstractKey": null
};
(node as any).hash = '99fe44762162ebefd6f1e556c605b4e0';
export default node;
