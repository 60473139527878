import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_UPDATE_USER_ROLES = "update-user-roles";

interface UpdateUserRolesPayload {
    userId: string
    roles: string[]
}

export const updateUserRolesAction = (userId: string, roles: string[]) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_UPDATE_USER_ROLES,
            callType: API_UPDATE_USER_ROLES
        },
        payload: {
            userId,
            roles
        }
    } as ApiCallAction<UpdateUserRolesPayload>;
};
