import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import React from "react";
import {CurrencyDisplay} from "../../../../core/components/currency/CurrencyDisplay";
import {CartStepClosed_CartFragment$key} from "../../../../../__generated__/CartStepClosed_CartFragment.graphql";
import styled from "styled-components";


interface OwnProps {
    cartFragmentRef: CartStepClosed_CartFragment$key | null
}

export const CartStepClosed = ({cartFragmentRef}: OwnProps) => {
    const cart = useFragment<CartStepClosed_CartFragment$key>(graphql`
        fragment CartStepClosed_CartFragment on Cart {
            cartTotals {
                totalsIncludingAllDiscounts {
                    grossPrice
                }
            }
        }
    `, cartFragmentRef)


    return cart?.cartTotals.totalsIncludingAllDiscounts?.grossPrice ? <ClosedStateContainer>
        Warenwert <CurrencyDisplay value={cart?.cartTotals.totalsIncludingAllDiscounts?.grossPrice}/>
    </ClosedStateContainer> : null;
}

const ClosedStateContainer = styled.div`
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  line-height: 1.38;
  color: #184276;
  margin-top: 5px;
`
