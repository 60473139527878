import {academyTextSizesForStyledComps} from "./academy-text-sizes-for-styled-comps";
import {academyFontsForStyledComps} from "./academy-fonts-for-styled-comps";
import {academyColorsForStyledComps} from "./academy-colors-for-styled-comps";

export const academyTypographyForStyledComps = {
    smallText: `
        ${academyTextSizesForStyledComps.small}
        ${academyFontsForStyledComps.regular}
        ${academyColorsForStyledComps.waterblue80}
    `,
    regularTextBold: `
        ${academyTextSizesForStyledComps.regular}
        ${academyFontsForStyledComps.bold}
        ${academyColorsForStyledComps.waterblue80}
    `
}