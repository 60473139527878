import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {ProductDisplay_ProductFragment$key} from "../../../../../__generated__/ProductDisplay_ProductFragment.graphql";
import React from "react";
import styled from "styled-components";
import {ProductImage} from "./ProductImage";
import {PriceDisplay, ProductTitle} from "./CartItem";
import {IHKBadge} from "./IHKBadge";
import {CurrencyDisplay} from "../../../../core/components/currency/CurrencyDisplay";

interface OwnProps {
    productFragmentRef: ProductDisplay_ProductFragment$key
    addToCart: () => void;
}

export const ProductDisplay = ({productFragmentRef, addToCart}: OwnProps) => {

    const product = useFragment<ProductDisplay_ProductFragment$key>(graphql`
        fragment ProductDisplay_ProductFragment on Product {
            id
            productType
            price {
                netPrice
                grossPrice
            }
            title
            ... on CourseProduct {
                course {
                    isIHK
                    icon {
                        url
                    }
                }
            }
        }
    `, productFragmentRef)

    return <ProductContainer>
        <ProductHeader>
            <ProductImageWithMargin url={product?.course?.icon?.url || undefined}/>
            <ProductTitle>
                {product.title}
            </ProductTitle>
        </ProductHeader>

        <BadgeWrapper>
            {product?.course?.isIHK && <IHKBadge/>}
        </BadgeWrapper>
        <ProductFooter>
            <PriceDisplay><CurrencyDisplay value={product.price.grossPrice}/></PriceDisplay>
            <AddToCartButton onClick={() => addToCart()}>Zum Warenkorb</AddToCartButton>
        </ProductFooter>

    </ProductContainer>
}

const ProductContainer = styled.div`
  height: 100%;
  padding: 20px 20px 10px 20px;
  border-radius: 6px;
  border: solid 1px #e7ebf1;
  background-image: linear-gradient(to bottom, #fff59b, #dcf8e7);
  display: flex;
  flex-direction: column;
`

const ProductHeader = styled.div`
  display: flex;
`

const BadgeWrapper = styled.div`
  margin-bottom: auto;
  margin-top: 25px;
`

const ProductImageWithMargin = styled(ProductImage)`
  margin-right: 10px;
`

const ProductFooter = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e7ebf1;
  display: flex;
`

const AddToCartButton = styled.button`
  margin-left: auto;

  font-size: 14px;
  font-weight: bold;
  line-height: 2.29;
  text-align: left;
  color: #247ac7;

`
