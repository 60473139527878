import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchCertificatesAction, selectUserCertificates} from "@thekeytechnology/thekey-academy-frontend-library";
import {DateTimeDisplay} from "../../core/components/display/DateTimeDisplay";
import {useTranslation} from "react-i18next";
import "./certificate-list.scss"

export const CertificateList = () => {
    const {t} = useTranslation("usersettings")
    const dispatch = useDispatch();
    const certificates = useSelector(selectUserCertificates)

    useEffect(() => {
        dispatch(fetchCertificatesAction())
    }, [dispatch])

    return <div className="certificate-list">
        <h2 className="tk-academy-larger-bold mb-3 tk-color-darkblue mt-5">
            {t("certificate-list.heading")}
        </h2>

        {!certificates || certificates.certificates.length === 0 ? <div>
            {t("certificate-list.no-certificates")}
        </div> : null}

        {certificates?.certificates.map(certificate => {
            return <div key={certificate.certificateId} className="certificate tk-academy-card mb-3 p-3">
                <div>
                    <DateTimeDisplay dateTime={certificate.certificateIssueDate}/>
                </div>
                <div>
                    <strong>{certificate.courseTitle} {certificate.isWithHonors ? t("certificate-list.with-honors") : null}</strong>
                </ div>
                <div>
                    <a target="_blank" rel="noopener noreferrer"
                       href={"https://www.thekey.career/zertifikat/" + certificate.certificateId}>{t("certificate-list.to-website")}</a>
                </div>
            </div>
        })}
    </div>
}
