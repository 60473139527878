/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MistakesType = "clozeText" | "multipleChoice" | "order" | "%future added value";
export type FailedElementDisplay_FailedElementFragment = {
    readonly mistakes: {
        readonly mistakeType: MistakesType;
    };
    readonly " $fragmentRefs": FragmentRefs<"FailedClozeElement_FailedElementFragment" | "FailedMultipleChoiceElement_FailedElementFragment" | "FailedOrderElement_FailedElementFragment">;
    readonly " $refType": "FailedElementDisplay_FailedElementFragment";
};
export type FailedElementDisplay_FailedElementFragment$data = FailedElementDisplay_FailedElementFragment;
export type FailedElementDisplay_FailedElementFragment$key = {
    readonly " $data"?: FailedElementDisplay_FailedElementFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"FailedElementDisplay_FailedElementFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FailedElementDisplay_FailedElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "mistakes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mistakeType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FailedClozeElement_FailedElementFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FailedMultipleChoiceElement_FailedElementFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FailedOrderElement_FailedElementFragment"
    }
  ],
  "type": "FailedElement",
  "abstractKey": null
};
(node as any).hash = 'ba1c2fc7d1d683a8517f93808222a5ed';
export default node;
