import IHKIcon from "../../../../../assets/images/ihk.png";
import React from "react";
import styled from "styled-components";


export const IHKBadge = () => {
    return <IHKText>
        <IHKIconImage alt="IHK"
                      className="ihk-icon mr-2"
                      src={IHKIcon}/> inkl. IHK-Zertifikat
    </IHKText>
}

const IHKIconImage = styled.img`
  height: 20px;
  margin-right: 5px;
`

const IHKText = styled.div`
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  line-height: 1.43;
  color: #466791;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`
