import React, {useState} from "react";
import {ReviewPreparationScreen} from "./ReviewPreparationScreen";
import {useLazyLoadQuery, useSubscribeToInvalidationState} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {ReviewScreen_Query} from "../../../../__generated__/ReviewScreen_Query.graphql";
import {Element} from "@thekeytechnology/thekey-academy-frontend-library";
import {ReviewProcessScreen} from "./ReviewProcessScreen";
import {ReviewProcessScreen_ElementsConnectionFragment$key} from "../../../../__generated__/ReviewProcessScreen_ElementsConnectionFragment.graphql";
import {useRouteMatch} from "react-router";
import {CelebrationPopup} from "../../../celebration/components/CelebrationPopup";

const QUERY = graphql`
    query ReviewScreen_Query($courseId: ID!) {
        Course: node(id: $courseId) {
            ...ReviewPreparationScreen_CourseFragment
            ...ReviewProcessScreen_CourseFragment
            ...CelebrationPopup_CourseFragment
        }
        Viewer {
            CourseState {
                CourseState(Input: {courseId: $courseId}) {
                    id
                    ...ReviewPreparationScreen_CourseStateFragment
                    ...CelebrationPopup_CourseStateFragment
                }
            }
            CustomerMessageV2 {
                CustomerMessageInformation {
                    id
                    numUnseenCustomerMessages
                }
            }
            Review {
                ReviewOptions(courseId: $courseId) {
                    ...ReviewPreparationScreen_ReviewOptionsConnectionFragment
                }
            }
            License {
                NewLicensesInAccount {
                    id
                    newLicenses
                }
                LicensePools {
                    ...CelebrationPopup_LicensePools
                }
            }
        }
    }`

interface State {
    fetchKey: number
    elements?: Element[]
    elementsConnectionFragmentRef?: ReviewProcessScreen_ElementsConnectionFragment$key
}

interface OwnProps {
    courseId: string
}

export const ReviewScreenComponent = ({courseId}: OwnProps) => {

    const [state, setState] = useState<State>({
        fetchKey: 0,
        elements: undefined,
        elementsConnectionFragmentRef: undefined
    })

    const query = useLazyLoadQuery<ReviewScreen_Query>(QUERY, {courseId: courseId}, {fetchPolicy: "network-only", fetchKey: state.fetchKey})

    useSubscribeToInvalidationState([query.Viewer.CourseState.CourseState?.id].filter(id => !!id).map(id => id!), () => {
        setState(state => ({...state, fetchKey: state.fetchKey + 1}))
    })

    if (!query.Course) return null

    return <>
        {state.elements && state.elementsConnectionFragmentRef ?
            <ReviewProcessScreen
                courseFragmentRef={query.Course}
                elements={state.elements}
                elementsConnectionFragmentRef={state.elementsConnectionFragmentRef}
                onSubmitReviewCompleted={() => setState(
                    {
                        ...state,
                        elements: undefined,
                        elementsConnectionFragmentRef: undefined
                    }
                )}/> :
            <ReviewPreparationScreen
                courseFragment={query.Course}
                newLicensesInAccount={query.Viewer.License.NewLicensesInAccount.newLicenses}
                courseStateFragment={query.Viewer.CourseState.CourseState}
                numUnseenCustomerMessages={query.Viewer.CustomerMessageV2.CustomerMessageInformation.numUnseenCustomerMessages}
                onStartReviewCompleted={(elements, elementsConnectionFragmentRef) => {
                    setState({...state, elements, elementsConnectionFragmentRef})
                }}
                reviewOptionsConnectionFragment={query.Viewer.Review.ReviewOptions}
            />
        }
        <CelebrationPopup courseFragmentRef={query.Course}
                          courseStateFragmentRef={query.Viewer.CourseState.CourseState}
                          licensePoolsFragmentRef={query.Viewer.License.LicensePools}/>
    </>
}

export const ReviewScreen = () => {
    const router = useRouteMatch<{ courseId?: string }>()

    if (!router.params.courseId) return null

    return <ReviewScreenComponent courseId={router.params.courseId}/>
}
