import {PriceWithTaxDisplay} from "./PriceWithTaxDisplay";
import React from "react";
import {PriceExplanation} from "@thekeytechnology/thekey-academy-frontend-library";

interface OwnProps {
    priceExplanation: PriceExplanation
    showNetPrices?: boolean
    hideStrikePrice?: boolean
}

export const PriceDisplay = ({priceExplanation, showNetPrices, hideStrikePrice}: OwnProps) => {
    return <>
        <div className="tk-academy-large tk-academy-bold tk-color-darkblue">
            {priceExplanation.hasDiscount ?
                <PriceWithTaxDisplay
                    netPrice={priceExplanation.discountedNetAmount}
                    grossPrice={priceExplanation.discountedGrossAmount}
                    showNetPrice={showNetPrices}/>
                : <PriceWithTaxDisplay netPrice={priceExplanation.netAmount}
                                       grossPrice={priceExplanation.grossAmount}
                                       showNetPrice={showNetPrices}/>}
        </div>
        {priceExplanation.hasDiscount && !hideStrikePrice ?
            <div className="tk-academy-text tk-color-darkblue line-through">
                <PriceWithTaxDisplay netPrice={priceExplanation.netAmount}
                                     grossPrice={priceExplanation.grossAmount}
                                     showNetPrice={showNetPrices}/>
            </div> : null}
    </>
}

