import moment from "moment";
import React from "react";

export const EndOfMonth = () => {
    const today = moment()
    const endOfMonth = today.endOf("month")
    const differenceInHoursBetweenNowAndEndOfMonth = today.diff(endOfMonth, "hours");

    const referenceDate = differenceInHoursBetweenNowAndEndOfMonth > -8 && differenceInHoursBetweenNowAndEndOfMonth < 0 ? today.add(1, "day") : today;
    return <>{referenceDate.endOf("month").format("DD.MM.YYYY")}</>
}
