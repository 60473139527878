import {Environment, RecordSource, Store as RelayStore} from 'relay-runtime';
import {logoutAction, Message, postMessageAction, selectAuthState} from '@thekeytechnology/framework-react';
import {Store} from 'redux';
import {
    errorMiddleware,
    loggerMiddleware,
    perfMiddleware,
    progressMiddleware,
    RelayNetworkLayer,
    retryMiddleware,
    uploadMiddleware,
    urlMiddleware
} from 'react-relay-network-modern'
import {isArray} from 'rxjs/internal-compatibility';
import * as Sentry from '@sentry/react';

const isDev = process.env.REACT_APP_APP_ENVIRONMENT === 'dev';

const createNetwork = (store: Store<any, any>, resetRelayEnv: () => void) => new RelayNetworkLayer(
    [
        urlMiddleware({
            url: () => Promise.resolve(`${process.env.REACT_APP_API_BASE}/api/graphql`),
        }),
        isDev ? loggerMiddleware() : null,
        isDev ? errorMiddleware() : null,
        isDev ? perfMiddleware() : null,
        retryMiddleware({
            fetchTimeout: 10000,
            retryDelays: (attempt) => attempt * 1000, // or simple array [3200, 6400, 12800, 25600, 51200, 102400, 204800, 409600],
            beforeRetry: ({abort, attempt}) => {
                if (attempt > 3) {
                    abort();
                }
            }
        }),
        progressMiddleware({
            onProgress: (current, total) => {
                console.log('Downloaded: ' + current + ' B, total: ' + total + ' B');
            },
        }),
        uploadMiddleware(),
        (next) => async (req) => {

            req.fetchOpts.headers['X-Request-Source'] = 'webapp'

            const authModuleState = selectAuthState(store.getState())

            const token = authModuleState ? authModuleState.token : null;
            const accountId = authModuleState ? authModuleState.currentAccount ? authModuleState.currentAccount.accountId : undefined : undefined;

            if (token && accountId) {
                req.fetchOpts.headers['X-Auth-Token'] = token
                req.fetchOpts.headers['X-Auth-Account-Id'] = accountId
            }

            const res = await next(req);

            const tokenUnkownErrors = res.errors?.filter(e => e.message === 'token-unknown')

            if (tokenUnkownErrors?.length) {
                res.errors = res.errors!.filter(e => e.message !== 'token-unknown');
                store.dispatch(logoutAction());
                resetRelayEnv();
            }

            if (isArray(res.errors)) {
                res.errors!.forEach(e => {
                    Sentry.captureException(e.message)
                    store.dispatch(postMessageAction(Message.TYPE_DANGER, "errors." + e.message))
                })
            }

            return res;
        },
    ],
);

export const RelayEnvironment = (store: any, resetRelayEnv: () => void) => {
    return new Environment({
        network: createNetwork(store, resetRelayEnv),
        store: new RelayStore(new RecordSource()),
    })
}
