import React, {ReactNode} from "react"
import {FieldProps} from "formik";
import "./switch.scss";
import {Switch} from "./Switch";

interface OwnProps {
    title: ReactNode;
    titleRight?: boolean;
    titleClassName?: string;
}

type Props = FieldProps & OwnProps;

export const SwitchField = ({title, field, form, titleRight, titleClassName}: Props) => {
    return <Switch title={title}
                   titleClassName={titleClassName}
                   titleRight={titleRight}
                   isChecked={field.value ? field.value : false}
                   setChecked={checked => form.setFieldValue(field.name, checked, true)}/>
}
