import React from "react";
import {ShowOnePriceRenderer} from "./PriceRenderer";
import {PriceTable} from "@thekeytechnology/thekey-academy-frontend-library";
import {CurrencyDisplay} from "../../../../../../core/components/currency/CurrencyDisplay";

interface OwnProps {
    priceTable: PriceTable
    showNetPrices?: boolean
}


export const RatesRow = ({
                             priceTable,
                             showNetPrices
                         }: OwnProps) => {


    return <div className="cart-table-row rates-row">
        <div className="d-flex row-content">
            <div className="mr-3 row-title">
                <div>Monatlich</div>
            </div>

            <div className="d-flex flex-column ml-auto price-wrapper text-right">
                <div className="tax tk-academy-small-text tk-color-waterblue-80">
                    <CurrencyDisplay
                        value={priceTable.monthlyTotal!.discountedMonthlyRateGross - priceTable.monthlyTotal!.discountedMonthlyRateNet}/>
                </div>

                <ShowOnePriceRenderer
                    priceExplanation={priceTable.total}
                    monthlyPriceExplanation={priceTable.monthlyTotal}
                    showNetPrices={showNetPrices}
                    showMonthly={true}/>
            </div>
        </div>

    </div>
}
