import {
    clozeTextElementGraphQLToClozeTextElement,
    useClozeTextLogicV2
} from "@thekeytechnology/thekey-academy-frontend-library";
import React from "react";
import {BaseElementDisplay} from "../common/BaseElementDisplay";
import {WordArea} from "./WordArea";
import {ClozeText} from "./ClozeText";
import {useTranslation} from "react-i18next";
import {LightboxImage} from "../../../core/components/image/LightboxImage";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {ClozeTextElementDisplay_ClozeTextElementFragment$key} from "../../../../__generated__/ClozeTextElementDisplay_ClozeTextElementFragment.graphql";
import styled from "styled-components";

const CLOZE_TEXT_ELEMENT_DISPLAY_CLOZE_TEXT_ELEMENT_FRAGMENT = graphql`
    fragment ClozeTextElementDisplay_ClozeTextElementFragment on ClozeTextElement {
        additionalWords
        availableWordsInRandomOrder
        elementType
        parts {
            partType
            ... on ClozePart {
                cloze
            }
            ... on TextPart {
                text
            }
        }
        image {
            url
        }
        generalSettings {
            points
        }
        id
        ...ClozeText_ClozeTextElementFragment
    }`

interface OwnProps {
    clozeTextElementFragmentRef: ClozeTextElementDisplay_ClozeTextElementFragment$key;
}

export const ClozeTextElementDisplay = ({clozeTextElementFragmentRef}: OwnProps) => {
    const element = useFragment<ClozeTextElementDisplay_ClozeTextElementFragment$key>(CLOZE_TEXT_ELEMENT_DISPLAY_CLOZE_TEXT_ELEMENT_FRAGMENT, clozeTextElementFragmentRef)

    const {t} = useTranslation("elementflow")

    const [words, matchings, updateMatchings] = useClozeTextLogicV2(clozeTextElementGraphQLToClozeTextElement(element))

    const selectWord = (word?: string) => {
        const currentCloze: [string, string] | undefined = Object.entries(matchings).find(m => m[1] === word);
        if (currentCloze) {
            updateMatchings(currentCloze[0], undefined, false);
        } else {
            const clozes = element.parts.filter(p => p.partType === 'cloze').map(p => p as { cloze: string })
            const firstFreeCloze = clozes.find(c => matchings[c.cloze] === undefined)
            if (firstFreeCloze) {
                updateMatchings(firstFreeCloze.cloze, word, false);
            }
        }
    }

    return <BaseElementDisplay
        heading={t("cloze-text-element-display.heading")}
        elementSpecificSlot={
            <div className={"pb-5"}>
                {element.image?.url ? <div className={"mb-4 d-flex justify-content-center"}>
                    <ClozeTextImage image={element.image.url}/>
                </div> : null}

                <WordArea
                    selectWord={selectWord}
                    availableWords={words.filter(word => Object.values(matchings).indexOf(word) === -1)}/>

                <ClozeText
                    selectWord={selectWord}
                    clozeTextElementFragmentRef={element}
                    matchings={matchings}/>
            </div>
        }/>
}

const ClozeTextImage = styled(LightboxImage)`
  max-width: 600px;
`