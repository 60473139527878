/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UpperCourseCard_CourseFragment = {
    readonly containerMeta: {
        readonly title: string;
    };
    readonly isIHK: boolean;
    readonly icon: {
        readonly url: string | null;
    } | null;
    readonly shortTitle: string;
    readonly " $refType": "UpperCourseCard_CourseFragment";
};
export type UpperCourseCard_CourseFragment$data = UpperCourseCard_CourseFragment;
export type UpperCourseCard_CourseFragment$key = {
    readonly " $data"?: UpperCourseCard_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"UpperCourseCard_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpperCourseCard_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContainerMeta",
      "kind": "LinkedField",
      "name": "containerMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isIHK",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "icon",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortTitle",
      "storageKey": null
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = 'bcc24a73584097240b017357a210014f';
export default node;
