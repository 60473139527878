import {
    apiModuleReducer,
    authModuleReducer,
    dialogModuleReducer,
    i18nModuleReducer,
    messagesModuleReducer,
    usersModuleReducer,
} from "@thekeytechnology/framework-react";
import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import {stateModuleReducer} from "./state/reducers";
import {lessonModuleReducer} from "./lesson/reducer";
import {
    achievementsModuleReducer,
    additionalContentModuleReducer,
    billingModuleV2Reducer,
    celebrationModuleReducer,
    certificatesModuleReducer,
    courseModuleReducer,
    customerMessageModuleReducer,
    faqsModuleReducer,
    gamificationModuleReducer,
    glossaryModuleReducer,
    learnModuleReducer,
    licensesModuleReducer,
    newsModuleReducer,
    noteModuleReducer,
    onboardingModuleReducer,
    qnaModuleReducer,
    reviewModuleReducer,
    singleElementModuleReducer,
    submissionModuleReducer,
    userSettingsModuleReducer
} from "@thekeytechnology/thekey-academy-frontend-library"
import extendedRouterReducer from "./extended-router/reducer/extended-router";
import {managerModuleReducer} from "./manager/reducer";

export type RootState = ReturnType<ReturnType<typeof appReducers>>

export const appReducers = (history: any, apiBase: string) => combineReducers({
    router: connectRouter(history),
    auth: authModuleReducer,
    dialog: dialogModuleReducer,
    messages: messagesModuleReducer,
    api: apiModuleReducer(apiBase),
    i18n: i18nModuleReducer,
    state: stateModuleReducer,
    courses: courseModuleReducer,
    gamification: gamificationModuleReducer,
    news: newsModuleReducer,
    additionalContent: additionalContentModuleReducer,
    faqs: faqsModuleReducer,
    lesson: lessonModuleReducer,
    learn: learnModuleReducer,
    achievements: achievementsModuleReducer,
    review: reviewModuleReducer,
    singleElement: singleElementModuleReducer,
    submissions: submissionModuleReducer,
    userSettings: userSettingsModuleReducer,
    customerMessage: customerMessageModuleReducer,
    notes: noteModuleReducer,
    qnas: qnaModuleReducer,
    glossary: glossaryModuleReducer,
    celebration: celebrationModuleReducer,
    billingv2: billingModuleV2Reducer,
    extendedRouter: extendedRouterReducer,
    onboarding: onboardingModuleReducer,
    licenses: licensesModuleReducer,
    manager: managerModuleReducer,
    users: usersModuleReducer,
    certificates: certificatesModuleReducer
});
