import React, {ReactElement, useState} from "react";
import {useTranslation} from "react-i18next";
import {AddButton} from "./AddButton";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment, useLazyLoadQuery, usePaginationFragment} from "react-relay";
import {NotesScreenContent_Query} from "../../../__generated__/NotesScreenContent_Query.graphql";
import {NoteItem} from "./NoteItem";
import {NoteModal} from "./NoteModal";
import {NotesScreenContent_ElementFragment$key} from "../../../__generated__/NotesScreenContent_ElementFragment.graphql";
import {NotesScreenContent_CourseFragment$key} from "../../../__generated__/NotesScreenContent_CourseFragment.graphql";
import {NotesScreenContent_LessonFragment$key} from "../../../__generated__/NotesScreenContent_LessonFragment.graphql";
import {NoteModal_NoteFragment$key} from "../../../__generated__/NoteModal_NoteFragment.graphql";
import {ViewerNotesFragmentRefetchQuery} from "../../../__generated__/ViewerNotesFragmentRefetchQuery.graphql";
import {NotesScreenContent_ViewerNotesFragment$key} from "../../../__generated__/NotesScreenContent_ViewerNotesFragment.graphql";

const QUERY = graphql`
    query NotesScreenContent_Query($courseId: ID, $elementId: ID, $lessonId: ID) {
        ...NotesScreenContent_ViewerNotesFragment @arguments(first: null, after: null, courseId: $courseId, lessonId: $lessonId, elementId: $elementId)
    }`

const VIEWER_NOTES_FRAGMENT = graphql`
    fragment NotesScreenContent_ViewerNotesFragment on Query @refetchable(queryName: "ViewerNotesFragmentRefetchQuery") @argumentDefinitions(
        first: {type: "Int"}
        after: {type: "String"}
        courseId: {type: "ID"}
        lessonId: {type: "ID"}
        elementId: {type: "ID"}
    ) {
        Viewer {
            Notes {
                Notes(first: $first, after: $after, courseId: $courseId, lessonId: $lessonId, elementId: $elementId) @connection(key: "NotesScreenContent_Notes") {
                    __id
                    edges {
                        node {
                            id
                            ...NoteModal_NoteFragment
                            ...NoteItem_NoteFragment
                        }
                    }
                }
            }
        }
    }`

const COURSE_FRAGMENT = graphql`
    fragment NotesScreenContent_CourseFragment on Course {
        id
        ...NoteModal_CourseFragment
    }`

const LESSON_FRAGMENT = graphql`
    fragment NotesScreenContent_LessonFragment on Lesson {
        id
        ...NoteModal_LessonFragment
    }`

const ELEMENT_FRAGMENT = graphql`
    fragment NotesScreenContent_ElementFragment on Element {
        id
        ...NoteModal_ElementFragment
    }`

interface State {
    isModalVisible: boolean;
    editedNoteFragmentRef: NoteModal_NoteFragment$key | undefined
}

interface OwnProps {
    courseFragmentRef?: NotesScreenContent_CourseFragment$key | null;
    lessonFragmentRef?: NotesScreenContent_LessonFragment$key | null;
    elementFragmentRef?: NotesScreenContent_ElementFragment$key | null;
    className?: string;
    headingSlot?: ReactElement;
}

export const NotesScreenContent = ({
                                       courseFragmentRef,
                                       lessonFragmentRef,
                                       elementFragmentRef,
                                       className,
                                       headingSlot
                                   }: OwnProps) => {
    const {t} = useTranslation("notes")

    const course = useFragment<NotesScreenContent_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef || null)
    const lesson = useFragment<NotesScreenContent_LessonFragment$key>(LESSON_FRAGMENT, lessonFragmentRef || null)
    const element = useFragment<NotesScreenContent_ElementFragment$key>(ELEMENT_FRAGMENT, elementFragmentRef || null)

    const query = useLazyLoadQuery<NotesScreenContent_Query>(QUERY, {
        courseId: course?.id,
        lessonId: lesson?.id,
        elementId: element?.id
    }, {fetchPolicy: "network-only"});

    const {data} = usePaginationFragment<ViewerNotesFragmentRefetchQuery, NotesScreenContent_ViewerNotesFragment$key>(VIEWER_NOTES_FRAGMENT, query);

    const notesConnection = data.Viewer.Notes.Notes;

    const notes = notesConnection.edges?.filter(e => !!e).map(e => e!.node) || [];

    const [state, setState] = useState<State>({
        isModalVisible: false,
        editedNoteFragmentRef: undefined,
    });

    const content = notes.length === 0 ? <div className="d-flex align-items-center flex-column">
        <div className="icon-a_text tk-academy-extra-large-heading mb-3 tk-color-darkblue"/>
        <div className="tk-academy-larger-bold tk-black mb-2">{t("notes-tab.empty-list.title")}</div>
        <div
            className="tk-academy-text tk-color-darkblue">{t(course ? "notes-tab.empty-list.explanation" : "notes-tab.empty-list.explanation-no-creation")}</div>
    </div> : notes.map(note =>
        <NoteItem
            key={note.id}
            noteFragmentRef={note}
            onClick={() => setState({...state, isModalVisible: true, editedNoteFragmentRef: note})}
        />);

    return <>
        <div className={"d-flex flex-column overflow-auto " + className}>
            {headingSlot ? headingSlot : null}
            {content}
            {course ? <AddButton onAdd={() => {
                setState({
                    ...state,
                    isModalVisible: true,
                    editedNoteFragmentRef: undefined
                })
            }}/> : null}
        </div>
        {state.isModalVisible &&
        <NoteModal
            courseFragmentRef={course}
            lessonFragmentRef={lesson}
            elementFragmentRef={element}
            onClose={() => setState({...state, isModalVisible: false, editedNoteFragmentRef: undefined})}
            notesConnectionId={notesConnection.__id} editedNoteFragmentRef={state.editedNoteFragmentRef}
        />}
    </>
}
