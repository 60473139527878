import React from "react";
import Select from "react-select";
import {ClearIndicator} from "./ClearIndicator";

interface OwnProps<T> {
    options: OptionType<T>[];
    placeholder: string;
    isMulti?: boolean
    isClearable?: boolean
    disabled?: boolean
    name: string
    value: T | T[]
    setValue: (value: T | T[]) => void;
}

type Props<T> = OwnProps<T>;

export interface OptionType<T> {
    value: T;
    label: string;
}

export function SimpleOptionSelect<T>({
                                          value,
                                          setValue,
                                          name,
                                          placeholder,
                                          options,
                                          isClearable,
                                          isMulti,
                                          disabled
                                      }: Props<T>) {
    return (<Select
        className="react-select category-select"
        classNamePrefix="react-select"
        components={{ClearIndicator}}
        placeholder={placeholder}
        noOptionsMessage={() => "Nichts gefunden."}
        options={options}
        styles={{control: base => ({
                ...base,
                border: 0,
                boxShadow: 'none'
            })}}
        isDisabled={disabled}
        name={name}
        value={isMulti ? options.filter(o => value ? (value as T[]).includes(o.value) : false) : options.find(o => o.value === value)}
        onChange={(item: any) => {
            if (isMulti) {
                setValue(item ? item.map((i: any) => i.value) : [])
            } else {
                setValue(item ? item.value : undefined)
            }
        }}
        isMulti={isMulti}
        isClearable={isClearable}
        getOptionValue={(opt: any) => opt.value}
        getOptionLabel={item => item.label}
    />);
}
