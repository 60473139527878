import React, {useEffect, useState} from "react";
import {randomIntFromInterval} from "@thekeytechnology/thekey-academy-frontend-library";
import {graphql} from "babel-plugin-relay/macro";
import {RandomPhrasing_PhrasingAlternativesFragment$key} from "../../../../__generated__/RandomPhrasing_PhrasingAlternativesFragment.graphql";
import {useFragment} from "react-relay";

const RANDOM_PHRASING_PHRASING_ALTERNATIVES_FRAGMENT = graphql`
    fragment RandomPhrasing_PhrasingAlternativesFragment on PhrasingAlternatives {
        alternatives
    }
`

interface OwnProps {
    phrasingAlternativesFragmentRef: RandomPhrasing_PhrasingAlternativesFragment$key,
    className?: string
}

interface State {
    randomizationReady: boolean,
    selectedPhrasingIndex: number | undefined
}

export const RandomPhrasing = ({
                                   phrasingAlternativesFragmentRef,
                                   className
                               }: OwnProps) => {
    const phrasingAlternatives = useFragment<RandomPhrasing_PhrasingAlternativesFragment$key>(RANDOM_PHRASING_PHRASING_ALTERNATIVES_FRAGMENT, phrasingAlternativesFragmentRef)

    const [state, setState] = useState<State>({
        randomizationReady: false,
        selectedPhrasingIndex: undefined
    })

    useEffect(() => {
        setState({
            ...state,
            selectedPhrasingIndex: randomIntFromInterval(1, phrasingAlternatives.alternatives.length) - 1,
            randomizationReady: true
        })

        return () => {
            setState({
                selectedPhrasingIndex: undefined,
                randomizationReady: false,
            })
        }
        // eslint-disable-next-line
    }, [phrasingAlternatives])

    return state.randomizationReady ?
        <div className={className}>{phrasingAlternatives.alternatives[state.selectedPhrasingIndex!]}</div> : null
}
