import React from "react";
import {useTranslation} from "react-i18next";
import {AcademyButton} from "../../core/components/button/AcademyButton";
import {useDispatch} from "react-redux";
import {showCelebrationAction} from "@thekeytechnology/thekey-academy-frontend-library";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {BoughtEarlyBirdLicenseButton_CourseFragment$key} from "../../../__generated__/BoughtEarlyBirdLicenseButton_CourseFragment.graphql";

const COURSE_FRAGMENT = graphql`
    fragment BoughtEarlyBirdLicenseButton_CourseFragment on Course {
        containerMeta {
            title
        }
    }`

interface OwnProps {
    courseFragmentRef: BoughtEarlyBirdLicenseButton_CourseFragment$key
}

export const BoughtEarlyBirdLicenseButton = ({courseFragmentRef}: OwnProps) => {

    const {t} = useTranslation("state")

    const dispatch = useDispatch()

    const course = useFragment<BoughtEarlyBirdLicenseButton_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef);

    return <AcademyButton
        icon={"icon-a_lock"}
        text={t("bought-early-bird-license-button.label")}
        onClick={() => dispatch(showCelebrationAction({
            title: t("bought-early-bird-license-button.celebration.title"),
            explanation: t("bought-early-bird-license-button.celebration.explanation"),
            image: "special-certification-pending",
            text: t("bought-early-bird-license-button.celebration.text", {courseName: course.containerMeta.title})
        }))}/>
}
