import {Form, Formik} from "formik";
import * as Yup from "yup";
import {NavLink} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";
import {API_RESET_PASSWORD, resetPasswordAction, selectCombinedApiState} from "@thekeytechnology/framework-react";
import {useDispatch, useSelector} from "react-redux";
import {ValidatedField} from "../../../core/components/form/ValidatedField";

import {AuthScreenHeading} from "../common/AuthScreenHeading";
import {AuthFormWrapper} from "../common/AuthFormWrapper";
import {useRouteMatch} from "react-router";

export const ResetPasswordForm = () => {
    const dispatch = useDispatch();
    const apiCallState = useSelector(selectCombinedApiState(API_RESET_PASSWORD))

    const {t} = useTranslation(["auth", "core"]);
    const params = useRouteMatch<{ token: string }>();


    const SuccessScreen = <>
        <AuthScreenHeading
            title={t("reset-password-form.success.heading-1")}
            subtitle={t("reset-password-form.success.heading-2")}
        />
        <p className="tk-bg-green-20 mb-5 p-5">
            {t("reset-password-form.success-text")}
        </p>
        <NavLink to="/login">
            <button type="button"
                    className="btn btn-primary align-self-end w-100"
            >
                {t("reset-password-form.success-to-login")}
            </button>
        </NavLink>
    </>;

    const ErrorScreen = <AuthFormWrapper>
        <AuthScreenHeading
            title={t("reset-password-form.error.heading-1")}
            subtitle={t("reset-password-form.error.heading-2")}
        />
        <p className="bgOrange20 mb-5 p-5">
            {t("reset-password-form.error-text")}
        </p>
        <NavLink to="/login">
            <button type="button"
                    className="btn btn-primary align-self-end w-100"
            >
                {t("reset-password-form.error-to-login")}
            </button>
        </NavLink>
    </AuthFormWrapper>

    return params.params.token ? <Formik
        initialValues={{password: "", password2: ""}}
        validationSchema={Yup.object().shape({
            password: Yup
                .string()
                .min(8, t("registration-form.password-error"))
                .required(t("core:forms.required-field", {fieldName: t("registration-form.password")})),
            password2: Yup.string()
                .oneOf([Yup.ref("password"), undefined], t("registration-form.passwords-must-match")),
        })}
        onSubmit={(values, {setSubmitting}) => {
            dispatch(resetPasswordAction(params.params.token, values.password));
            setSubmitting(false);
        }}
    >
        {formikState => (
            <AuthFormWrapper>
                {apiCallState.succeeded ? SuccessScreen : <Form className="w-100 login-form">
                    <AuthScreenHeading
                        title={t("reset-password-form.heading-1")}
                        subtitle={t("reset-password-form.heading-2")}
                    />
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        type="password"
                                        iconClass={"icon-a_lock"}
                                        placeholder={t("registration-form.password-placeholder")}
                                        name="password"
                                        className="form-control default-input"
                                        required/>
                    </div>
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        type="password"
                                        iconClass={"icon-a_lock"}
                                        placeholder={t("registration-form.password2-placeholder")}
                                        name="password2"
                                        className="form-control default-input"
                                        required/>
                    </div>
                    <div className="form-group d-flex mb-4">
                        <button type="submit" disabled={formikState.isSubmitting || apiCallState.inProgress}
                                className="btn btn-primary align-self-end w-100">
                            {t("forgot-password-form.submit")}
                        </button>
                    </div>
                    <div className="tk-academy-small-text text-center paragraph mb-3">
                        {t("forgot-password-form.to-login-explanation")} <NavLink
                        className="mb-5"
                        to="/login">{t("forgot-password-form.to-login")}</NavLink>
                    </div>
                </Form>}
            </AuthFormWrapper>
        )}
    </Formik> : ErrorScreen;
}

