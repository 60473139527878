import React from "react";
import {NextLessonCard} from "./lesson-card/NextLessonCard";
import "./next-lesson-slider.scss";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {
    NextLessonsSlider_CoursesConnectionFragment$key
} from "../../../../../__generated__/NextLessonsSlider_CoursesConnectionFragment.graphql";
import {NextLessonsSlider_CourseStatesConnectionFragment$key} from "../../../../../__generated__/NextLessonsSlider_CourseStatesConnectionFragment.graphql";
import {COURSE_STATUS} from "../../common/CourseStatusBadge";
import {useTranslation} from "react-i18next";

const COURSES_CONNECTION_FRAGMENT = graphql`
    fragment NextLessonsSlider_CoursesConnectionFragment on CoursesConnection{
        edges {
            node {
                id
                lessons {
                    id
                    lessonPath {
                        courseRef
                    }
                    ...NextLessonCard_LessonFragment
                }
                ...NextLessonCard_CourseFragment
            }
        }
    }
`;

const COURSE_STATES_CONNECTION_FRAGMENT = graphql`
    fragment NextLessonsSlider_CourseStatesConnectionFragment on CourseStatesConnection{
        edges {
            node {
                courseRef
                isBought
                isDone
                isAtEndOfTrial
                lessonStates {
                    lessonRef
                    status
                }
                ...NextLessonCard_CourseStateFragment
            }
        }
    }
`;

interface OwnProps {
    coursesConnectionFragmentRef: NextLessonsSlider_CoursesConnectionFragment$key | null;
    courseStatesConnectionFragmentRef: NextLessonsSlider_CourseStatesConnectionFragment$key | null;
}

export const NextLessonsSlider = ({coursesConnectionFragmentRef, courseStatesConnectionFragmentRef}: OwnProps) => {
    const {t} = useTranslation('courses')

    const coursesConnection = useFragment<NextLessonsSlider_CoursesConnectionFragment$key>(COURSES_CONNECTION_FRAGMENT, coursesConnectionFragmentRef)
    const courses = coursesConnection?.edges?.filter(e => !!e).map(e => e!.node) || []

    const courseStatesConnection = useFragment<NextLessonsSlider_CourseStatesConnectionFragment$key>(COURSE_STATES_CONNECTION_FRAGMENT, courseStatesConnectionFragmentRef)
    const courseStates = courseStatesConnection?.edges?.filter(e => !!e).map(e => e!.node) || []

    const nextLessons = courseStates.filter(courseState => !courseState.isDone && !courseState.isAtEndOfTrial).map(courseState => {
        const currentLesson = courseState.lessonStates.find(ls => ls.status === 'current')
        return courses.find(c => c.id === courseState.courseRef)?.lessons.find(l => l.id === currentLesson?.lessonRef)
    }).filter(l => !!l).map(l => l!) || []

    return nextLessons.length > 0 ? <div className="next-lesson-slider">
        <div className="mb-3 d-flex align-items-center">
            <h2 className="tk-academy-small-text tk-color-waterblue-80">{t('next-lesson.next-lessons')}</h2>
        </div>
        <div className="next-lessons-wrapper">
            {nextLessons.map((lesson) => {
                const courseId: any = lesson.lessonPath.courseRef;
                const course = courses.find(c => c.id === courseId)
                const courseState = courseStates.find(s => s.courseRef === courseId)
                const courseStatus = courseState ? (courseState.isBought ? COURSE_STATUS.ACTIVE : COURSE_STATUS.TEST) : undefined;

                return course && courseState && courseStatus ? <NextLessonCard key={lesson.id} courseFragmentRef={course} lessonFragmentRef={lesson}
                                       courseStateFragmentRef={courseState} courseStatus={courseStatus}/> : null
            })}
        </div>
    </div> : null;
}
