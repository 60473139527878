import React, {useEffect} from "react";
import {Route, RouteComponentProps, Switch, useHistory} from "react-router";
import {AuthRouteDefinitions} from "../../../auth/AuthRouteDefinitions";
import {LicensesRouteDefinitions} from "../../../licenses/LicensesRouteDefinitions";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentUser} from "@thekeytechnology/framework-react";
import {fetchAccountMetaAction} from "../../../manager/action/fetch-account-meta";
import {RouteAuthState} from "./RouteDefinition";
import {NotesRouteDefinitions} from "../../../notes/NotesRouteDefinitions";
import {CustomerMessageRouteDefinitions} from "../../../customer-message-v2/CustomerMessageRouteDefinitions";
import {CourseRouteDefinitions} from "../../../course/CourseRouteDefinitions";
import {FaqsRouteDefinitions} from "../../../faqs/FaqsRouteDefinitions";
import {GamificationRouteDefinitions} from "../../../gamification/GamificationRouteDefinitions";
import {UserSettingsRouteDefinitions} from "../../../user-settings/UserSettingsRouteDefinitions";
import {ManagerRouteDefinitions} from "../../../manager/ManagerRouteDefinitions";
import {LearnRouteDefinitions} from "../../../learnReview/learn/LearnRouteDefinitions";
import {ReviewRouteDefinitions} from "../../../learnReview/review/ReviewRouteDefinitions";
import {SubmissionRouteDefinitions} from "../../../submission/SubmissionRouteDefinitions";
import {BillingV2RouteDefinitions} from "../../../billing/v2/BillingV2RouteDefinitions";
import {BillingV3RouteDefinitions} from "../../../billing/v3/BillingV3RouteDefinitions";
import {LoggedInWrapper} from "./LoggedInWrapper";

const RouterRedirectToDashboard = () => {
    const history = useHistory()

    useEffect(
        () => history.push("/")
    )

    return null
}

const RouterRedirectToLoginKeepingQueryParams = () => {
    const history = useHistory()

    useEffect(
        () => history.push("/login" + history.location.search)
    )

    return null
}

export const RootNavigation = () => {

    const dispatch = useDispatch()
    const user = useSelector(selectCurrentUser)

    useEffect(() => {
        if (user) {
            dispatch(fetchAccountMetaAction())
        }
        // eslint-disable-next-line
    }, [user])

    return <>
        <Switch>
            {
                [
                    ...AuthRouteDefinitions,
                    ...LicensesRouteDefinitions,
                    ...NotesRouteDefinitions,
                    ...CustomerMessageRouteDefinitions,
                    ...CourseRouteDefinitions,
                    ...FaqsRouteDefinitions,
                    ...GamificationRouteDefinitions,
                    ...UserSettingsRouteDefinitions,
                    ...ManagerRouteDefinitions,
                    ...LearnRouteDefinitions,
                    ...ReviewRouteDefinitions,
                    ...SubmissionRouteDefinitions,
                    ...BillingV2RouteDefinitions,
                    ...BillingV3RouteDefinitions
                ].filter(rd => {
                    if (rd.authState === RouteAuthState.Independent) return true

                    return user ? rd.authState === RouteAuthState.OnlyLoggedIn : rd.authState === RouteAuthState.OnlyLoggedOut
                }).map(rd => ({
                    path: rd.path,
                    component: rd.authState === RouteAuthState.OnlyLoggedIn ? (
                        (props: RouteComponentProps<any>) =>
                            <LoggedInWrapper>{rd.component(props)}</LoggedInWrapper>) : rd.component
                })).map((rd, index) => <Route
                        exact
                        path={rd.path}
                        key={index}
                    >
                        {rd.component}
                    </Route>
                )}
            <Route path="*">
                {user ? <RouterRedirectToDashboard/> : <RouterRedirectToLoginKeepingQueryParams/>}
            </Route>
        </Switch>
    </>
}
