import React from 'react'
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {ButtonWithTitle} from "./ButtonWithTitle";
import {ConfirmButton_CustomerMessageDefinitionConfirmButtonFragment$key} from "../../../../../__generated__/ConfirmButton_CustomerMessageDefinitionConfirmButtonFragment.graphql";

const CUSTOMER_MESSAGE_DEFINITION_CONFIRM_BUTTON_FRAGMENT = graphql`
    fragment ConfirmButton_CustomerMessageDefinitionConfirmButtonFragment on CustomerMessageDefinitionConfirmButton {
        title
    }`

interface OwnProps {
    onClick: () => void
    modalWidth: number
    customerMessageDefinitionConfirmButtonFragmentRef: ConfirmButton_CustomerMessageDefinitionConfirmButtonFragment$key
}

export const ConfirmButton = ({onClick, modalWidth, customerMessageDefinitionConfirmButtonFragmentRef}: OwnProps) => {
    const button = useFragment<ConfirmButton_CustomerMessageDefinitionConfirmButtonFragment$key>(CUSTOMER_MESSAGE_DEFINITION_CONFIRM_BUTTON_FRAGMENT, customerMessageDefinitionConfirmButtonFragmentRef)

    return <ButtonWithTitle title={button.title} onClick={onClick} modalWidth={modalWidth}/>

}