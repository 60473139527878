import React, {ReactNode} from 'react'
import {Modal} from 'react-bootstrap'
import './basic-modal.scss'

interface OwnProps {
    size?: 'sm' | 'lg' | 'xl';
    centered?: boolean;
    backdropClassName?: string;
    dialogClassName?: string;
    onHide: () => void;
    show: boolean;
    children: ReactNode;
}

export const BasicModal = ({size, centered, dialogClassName, onHide, show, children}: OwnProps) => {
    return <div onClick={e => e.stopPropagation()}>
        <Modal centered={centered} dialogClassName={dialogClassName} size={size} onHide={onHide} show={show}>
            <span className="icon-a_close close-button" onClick={onHide}/>
            {children}
        </Modal>
    </div>
}
