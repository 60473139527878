import React, {useEffect, useRef, useState} from "react";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {BillingLoginV3} from "./forms/BillingLoginV3";
import {Separator} from "../cart/CartStep";
import styled, {css} from "styled-components";
import {BillingDetailsFormV3} from "./forms/BillingDetailsFormV3";
import {BillingDetailsStep_UserFragment$key} from "../../../../../__generated__/BillingDetailsStep_UserFragment.graphql";
import {BillingDetailsStep_OrderFragment$key} from "../../../../../__generated__/BillingDetailsStep_OrderFragment.graphql";

const USER_FRAGMENT = graphql`
    fragment BillingDetailsStep_UserFragment on User {
        __typename
        id
        meta {
            firstName
            lastName
        }
        ...BillingDetailsFormV3_UserFragment
    }
`;

const ORDER_FRAGMENT = graphql`
    fragment BillingDetailsStep_OrderFragment on Order{
        id
        ...BillingDetailsFormV3_OrderFragment
    }
`;

interface OwnProps {
    userFragmentRef: BillingDetailsStep_UserFragment$key | null | undefined
    orderFragmentRef: BillingDetailsStep_OrderFragment$key
}

export const BillingDetailsStep = ({userFragmentRef, orderFragmentRef}: OwnProps) => {
    const user = useFragment<BillingDetailsStep_UserFragment$key>(USER_FRAGMENT, userFragmentRef || null)

    const order = useFragment<BillingDetailsStep_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)

    const [hasAccount, setHasAccount] = useState<boolean | undefined>(undefined)

    const ref = useRef<any>(null)

    useEffect(() => {
        ref.current?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }, [])

    return <Container ref={ref}>
        <Separator/>
        <p className="tk-academy-large mb-2">
            <strong>Hinweis:</strong> Du möchtest aus dem Ausland bestellen? Dann wende Dich bitte an&nbsp;
            <a href='mailto:app@thekey.academy'>app@thekey.academy</a>
        </p>
        {!user ? <>
            <Heading>Hast Du bereits ein Konto?</Heading>

            <LoginSelectorContainer>
                <LoginNeededSelector selected={!!hasAccount} onClick={() => setHasAccount(true)}>Ja ich habe bereits ein
                    Konto.</LoginNeededSelector>
                <LoginNeededSelector selected={!hasAccount} onClick={() => setHasAccount(false)}>Nein, noch
                    nicht.</LoginNeededSelector>
            </LoginSelectorContainer>

            {hasAccount ? <BillingLoginV3/> : <BillingDetailsFormV3 userFragmentRef={user} orderFragmentRef={order}/>}
        </> : <>
            <BillingDetailsFormV3 userFragmentRef={user} orderFragmentRef={order}/>
        </>}


    </Container>
}

const Container = styled.div`
  width: 100%;
  scroll-margin: 50px;
`

const Heading = styled.h3`
  font-family: "Nunito Sans", sans-serif;

  font-size: 24px;
  line-height: 1.42;
  color: #184276;
  font-weight: normal;
  margin-bottom: 25px;

  @media (max-width: 1024px) {
    font-size: 18px;
  }
`

const LoginSelectorContainer = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 270px 270px;
  margin-bottom: 30px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }

`

interface LoginNeededSelectorProps {
    selected: boolean
}

const LoginNeededSelector = styled.div<LoginNeededSelectorProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 70px;
  border-radius: 6px;
  border: solid 2px #e7ebf1;
  background-color: #f3f5f8;
  cursor: pointer;

  @media (max-width: 1024px) {
    margin-bottom: 20px;
    width: 100%;
  }

  ${props => props.selected && css`
    background-color: white;
    border: solid 2px #4ace7f;
  `}
`
