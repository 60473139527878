import {RootState} from "../../reducers";
import {createSelector} from "reselect";
import {ManagerModuleState} from "../reducer";

export const selectStateModuleState = (state: RootState) => state.manager;

export const selectAccountMeta = createSelector(
    selectStateModuleState,
    (moduleState: ManagerModuleState) =>
        moduleState.accountMeta
);
export const selectUsersInAccountContainer = createSelector(
    selectStateModuleState,
    (moduleState: ManagerModuleState) =>
        moduleState.usersInAccountContainer
);
export const selectInvitationsInAccount = createSelector(
    selectStateModuleState,
    (moduleState: ManagerModuleState) =>
        moduleState.invitations
);

export const selectLicensesInAccount = createSelector(
    selectStateModuleState,
    (moduleState: ManagerModuleState) =>
        moduleState.licenses
);
