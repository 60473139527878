import {
    DURATION_NEVER,
    DURATION_ONE_DAY,
    DURATION_ONE_MONTH,
    DURATION_SEVEN_DAYS,
    DURATION_THREE_DAYS,
    DURATION_THREE_MONTHS, useResolutionLogicV2
} from "@thekeytechnology/thekey-academy-frontend-library";
import {BaseElementDisplay} from "../common/BaseElementDisplay";
import React from "react";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import "./resolution-element-display.scss";
import {ClearIndicator} from "../../../core/components/select/ClearIndicator";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {ResolutionElementDisplay_ResolutionElementFragment$key} from "../../../../__generated__/ResolutionElementDisplay_ResolutionElementFragment.graphql";

const RESOLUTION_ELEMENT_DISPLAY_RESOLUTION_ELEMENT_FRAGMENT = graphql`
    fragment ResolutionElementDisplay_ResolutionElementFragment on ResolutionElement {
        task
        title
    }`

interface OwnProps {
    resolutionElementFragmentRef: ResolutionElementDisplay_ResolutionElementFragment$key;
}

export const ResolutionElementDisplay = ({
                                             resolutionElementFragmentRef
                                         }: OwnProps) => {
    const element = useFragment<ResolutionElementDisplay_ResolutionElementFragment$key>(RESOLUTION_ELEMENT_DISPLAY_RESOLUTION_ELEMENT_FRAGMENT, resolutionElementFragmentRef)

    const {t} = useTranslation("elementflow");

    const [resolution, duration, updateText, updateDuration] = useResolutionLogicV2()

    const options = [DURATION_NEVER,
        DURATION_ONE_DAY,
        DURATION_THREE_DAYS,
        DURATION_SEVEN_DAYS,
        DURATION_ONE_MONTH,
        DURATION_THREE_MONTHS]
        .map((resolutionTry, index) => ({
            key: index,
            label: t(`resolution-try.notification-duration.${resolutionTry}`),
            value: resolutionTry
        }));
    return <BaseElementDisplay
        className="resolution-element-display" heading={element.title}
        elementSpecificSlot={
            <>
                <textarea
                    value={resolution}
                    placeholder={element.task}
                    onChange={event => updateText(event.target.value)}/>

                <Select
                    className="react-select category-select"
                    classNamePrefix="react-select"
                    components={{ClearIndicator}}
                    placeholder={"Erinnerungszeitpunkt auswählen"}
                    options={options}
                    value={options.find(o => o.value === duration)}
                    onChange={(item: any) => updateDuration(item.value)}
                    getOptionValue={(opt: any) => opt.value}
                    getOptionLabel={item => item.label}
                />
            </>
        }/>

}
