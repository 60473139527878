/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdvanceCourseButton_CourseStateFragment = {
    readonly isAtEndOfTrial: boolean;
    readonly isBought: boolean;
    readonly isCanOrderCertificate: boolean;
    readonly isCertificateOrdered: boolean;
    readonly isDone: boolean;
    readonly " $fragmentRefs": FragmentRefs<"NextLessonButton_CourseStateFragment">;
    readonly " $refType": "AdvanceCourseButton_CourseStateFragment";
};
export type AdvanceCourseButton_CourseStateFragment$data = AdvanceCourseButton_CourseStateFragment;
export type AdvanceCourseButton_CourseStateFragment$key = {
    readonly " $data"?: AdvanceCourseButton_CourseStateFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"AdvanceCourseButton_CourseStateFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdvanceCourseButton_CourseStateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAtEndOfTrial",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBought",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCanOrderCertificate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCertificateOrdered",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDone",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NextLessonButton_CourseStateFragment"
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};
(node as any).hash = 'feaa2615d79b001d6f0967a4e88429e1';
export default node;
