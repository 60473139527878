import React from "react";
import {generateString, UserWithRoles,} from "@thekeytechnology/framework-react";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Form, Formik} from "formik";
import {ValidatedField} from "../../../core/components/form/ValidatedField";
import {useDispatch} from "react-redux";
import {updateUserRolesAction} from "../../action/update-user-roles";
import {SimpleOptionSelectField} from "../../../core/components/select/SimpleOptionSelectField";
import "./inline-roles-editor.scss"

interface OwnProps {
    userId: string
    roles: string[]
}


export const InlineRolesEditor = ({userId, roles}: OwnProps) => {
    const {t} = useTranslation(["manager"])
    const dispatch = useDispatch();

    const OPTIONS = [UserWithRoles.ROLE_ADMIN, UserWithRoles.ROLE_USER].map(role => {
        return {
            value: role,
            label: t("user-roles." + role)
        }
    })

    const popover = <Popover id={generateString(10)}>
        <Popover.Title as="h3" className="d-flex align-items-center roles-editor-popover">
            {t("user-roles-editor.label")}
            <button
                onClick={() => {
                    document.body.click()
                }}
                className="ml-auto btn btn-link p-0">
                <FontAwesomeIcon icon={faTimes}/>
            </button>
        </Popover.Title>
        <Popover.Content>
            <Formik
                initialValues={{
                    roles
                }}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                    roles: Yup.array().min(1, t("user-roles-editor.role-error"))
                })}
                onSubmit={(values, {setSubmitting}) => {
                    dispatch(
                        updateUserRolesAction(
                            userId,
                            values.roles
                        )
                    );
                    document.body.click()
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form className="d-flex flex-column">
                        <ValidatedField formikState={formikState}
                                        component={SimpleOptionSelectField}
                                        options={OPTIONS}
                                        isMulti={true}
                                        placeholder={t("user-roles-editor.placeholder")}
                                        name="roles"
                                        className="form-control default-input"
                        />
                        <button disabled={formikState.isSubmitting} className="btn btn-success w-100 mt-3"
                                type="submit">
                            {t("user-roles-editor.save")}
                        </button>
                    </Form>
                )}
            </Formik>
        </Popover.Content>
    </Popover>;

    return <div className="d-flex align-items-center">
        <span>{roles.map(role => t("user-roles." + role)).join(", ")}</span>

        <OverlayTrigger rootClose={true} trigger="click" placement="right" overlay={popover}>
            <button className="btn btn-link ml-1">
                <FontAwesomeIcon icon={faPencilAlt}/>
            </button>
        </OverlayTrigger>
    </div>
};

