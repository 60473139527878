/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ElementType = "clozeText" | "image" | "multipleChoice" | "order" | "persolog" | "questionnaire" | "resolution" | "text" | "video" | "%future added value";
export type NoteModal_ElementFragment = {
    readonly id: string;
    readonly elementType: ElementType;
    readonly " $fragmentRefs": FragmentRefs<"ElementDisplay_ElementFragment">;
    readonly " $refType": "NoteModal_ElementFragment";
};
export type NoteModal_ElementFragment$data = NoteModal_ElementFragment;
export type NoteModal_ElementFragment$key = {
    readonly " $data"?: NoteModal_ElementFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"NoteModal_ElementFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NoteModal_ElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ElementDisplay_ElementFragment"
    }
  ],
  "type": "Element",
  "abstractKey": "__isElement"
};
(node as any).hash = '3d419fe3ba130cb2f2a3ee94c6dd45b4';
export default node;
