/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OrderStatus = "hasBillingDetails" | "hasCart" | "hasPaymentDetails" | "paymentReceived" | "purchased" | "transient" | "%future added value";
export type OrderFlowScreen_OrderFragment = {
    readonly id: string;
    readonly status: OrderStatus;
    readonly cart: {
        readonly " $fragmentRefs": FragmentRefs<"CartStep_CartFragment" | "CartStepClosed_CartFragment">;
    };
    readonly billingDetails: {
        readonly " $fragmentRefs": FragmentRefs<"BillingDetailsStepClosed_BillingDetailsFragment">;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"BillingDetailsStep_OrderFragment" | "PaymentStep_OrderFragment" | "PaymentStepClosed_OrderFragment" | "ReviewStep_OrderFragment" | "OrderFlowFooter_OrderFragment" | "OrderFlowContextProvider_OrderFragment">;
    readonly " $refType": "OrderFlowScreen_OrderFragment";
};
export type OrderFlowScreen_OrderFragment$data = OrderFlowScreen_OrderFragment;
export type OrderFlowScreen_OrderFragment$key = {
    readonly " $data"?: OrderFlowScreen_OrderFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"OrderFlowScreen_OrderFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderFlowScreen_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CartStep_CartFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CartStepClosed_CartFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BillingDetails",
      "kind": "LinkedField",
      "name": "billingDetails",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BillingDetailsStepClosed_BillingDetailsFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingDetailsStep_OrderFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PaymentStep_OrderFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PaymentStepClosed_OrderFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReviewStep_OrderFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderFlowFooter_OrderFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderFlowContextProvider_OrderFragment"
    }
  ],
  "type": "Order",
  "abstractKey": null
};
(node as any).hash = '6c06c6d1c0b380efa18a5e90c40624b9';
export default node;
