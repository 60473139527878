/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseDetailSidebar_CourseFragment = {
    readonly additionalContent: ReadonlyArray<{
        readonly additionalContent: ReadonlyArray<{
            readonly additionalContent: {
                readonly id: string;
            };
        }>;
    }>;
    readonly id: string;
    readonly modules: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"ModuleSubItem_ModuleFragment">;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"CourseSidebar_CourseFragment">;
    readonly " $refType": "CourseDetailSidebar_CourseFragment";
};
export type CourseDetailSidebar_CourseFragment$data = CourseDetailSidebar_CourseFragment;
export type CourseDetailSidebar_CourseFragment$key = {
    readonly " $data"?: CourseDetailSidebar_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseDetailSidebar_CourseFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseDetailSidebar_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdditionalContentByModule",
      "kind": "LinkedField",
      "name": "additionalContent",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AdditionalContentWithLessonPath",
          "kind": "LinkedField",
          "name": "additionalContent",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "additionalContent",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Module",
      "kind": "LinkedField",
      "name": "modules",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ModuleSubItem_ModuleFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseSidebar_CourseFragment"
    }
  ],
  "type": "Course",
  "abstractKey": null
};
})();
(node as any).hash = '782f0b6253954aa44d910dc628704da4';
export default node;
