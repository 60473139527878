import React from "react";
import {formatDuration} from "@thekeytechnology/thekey-academy-frontend-library"
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {Subtitles_VideoElementFragment$key} from "../../../../__generated__/Subtitles_VideoElementFragment.graphql";
import styled from "styled-components";

const ELEMENT_FRAGMENT = graphql`
    fragment Subtitles_VideoElementFragment on VideoElement {
        subtitles {
            subtitles {
                endTime
                startTime
                text
            }
        }
    }`

interface OwnProps {
    videoElementFragmentRef: Subtitles_VideoElementFragment$key
    timestamp: number
}

export const Subtitles = ({videoElementFragmentRef, timestamp}: OwnProps) => {
    const videoElement = useFragment<Subtitles_VideoElementFragment$key>(ELEMENT_FRAGMENT, videoElementFragmentRef)

    const refs = videoElement.subtitles ? videoElement.subtitles.subtitles.reduce((acc: any, value, index) => {
        acc[index] = React.createRef();
        return acc;
    }, {}) : [];

    const activeIndex = videoElement.subtitles ? videoElement.subtitles.subtitles.findIndex(subtitle => {
        return timestamp ? ((subtitle.startTime as number) <= timestamp && (subtitle.endTime as number) >= timestamp) : false
    }) : -1;

    const handleScrolling = () => {
        for (const key in refs) {
            if (refs.hasOwnProperty(key) && refs[key].current.classList.contains('tk-hide'))
                refs[key].current.classList.remove('tk-hide')
        }
    }

    const selectBoldElement = (index: number) => index === activeIndex

    const hideAllElementsAfterNth = (index: number, nthElementToFocus: number): boolean => index < activeIndex - nthElementToFocus

    return <div onScroll={handleScrolling}>
        {/*kleiner "hack": Sind nur noch wenige Elemente in der angezeigten Liste, dann verschwindet die Scrollbar
               und man kann nicht mehr scrollen um alle Elemente wiederzubekommen -> deswegen wird die Höhe auf 105% gesetzt*/}
        <div style={{height: '105%'}}>
            {
                videoElement.subtitles?.subtitles.map((subtitle: any, index: any) => {
                    return (
                        <Container key={index}
                                   ref={refs[index]}
                                   className={`mb-3 d-flex`}
                                   bold={selectBoldElement(index)} hide={hideAllElementsAfterNth(index, 5)}>
                            <div className="col-2 p-0 tk-academy-text tk-academy-bold tk-black">
                                {formatDuration(subtitle.startTime ?? 0)}
                            </div>
                            <div className="col-10 p-0  tk-academy-text tk-color-darkblue">
                                {subtitle.text}
                            </div>
                        </Container>
                    )
                })
            }
        </div>
    </div>
}

const Container = styled.div<{ bold: boolean, hide: boolean }>`
    ${props => props.bold && "font-weight: bold;"}
    ${props => props.hide && "display: none!important;"}
`