import React from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {NoteItem_NoteFragment$key} from "../../../__generated__/NoteItem_NoteFragment.graphql";
import {NoteLocationHeading} from "./NoteLocationHeading";

const NOTE_FRAGMENT = graphql`
    fragment NoteItem_NoteFragment on Note {
        content
        course {
            ...NoteLocationHeading_CourseFragment
        }
        lesson {
            containerMeta {
                title
            }
            ...NoteLocationHeading_LessonFragment
        }
        title
    }`

interface OwnProps {
    noteFragmentRef: NoteItem_NoteFragment$key;
    onClick: () => void;
}

export const NoteItem = ({
                             noteFragmentRef,
                             onClick,
                         }: OwnProps) => {

    const note = useFragment<NoteItem_NoteFragment$key>(NOTE_FRAGMENT, noteFragmentRef);

    return <div className="tk-academy-card p-4 mb-4 tk-cursor-pointer" onClick={onClick}>
        <div className="d-flex flex-row">
            <div>
                <NoteLocationHeading courseFragmentRef={note.course} lessonFragmentRef={note.lesson}/>
                {note.lesson && <div
                    className="tk-color-darkblue tk-academy-large">{note.lesson.containerMeta.title}</div>}
                <div className="tk-academy-text tk-academy-bold tk-black">{note.title}</div>
            </div>
        </div>
        <div className="tk-academy-text">{note.content}</div>
    </div>
}

