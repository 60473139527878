import React from "react";
import './failed-element.scss'
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {FailedElementCard_FailedElementFragment$key} from "../../../../__generated__/FailedElementCard_FailedElementFragment.graphql";

const FAILED_ELEMENT_FRAGMENT = graphql`
    fragment FailedElementCard_FailedElementFragment on FailedElement {
        element {
            elementPath {
                moduleIndex
                lessonIndex
            }
            elementType
            ... on ClozeTextElement {
                title
            }
            
            ... on MultipleChoiceElement {
                question {
                    alternatives
                }
            }
            ... on OrderElement {
                title
            }
        }
    }`

interface OwnProps {
    failedElementFragmentRef: FailedElementCard_FailedElementFragment$key;
    displayElement: () => void;
}

export const FailedElementCard = ({failedElementFragmentRef, displayElement}: OwnProps) => {
    const failedElement = useFragment<FailedElementCard_FailedElementFragment$key>(FAILED_ELEMENT_FRAGMENT, failedElementFragmentRef)

    let title;
    switch (failedElement.element.elementType) {
        case 'multipleChoice':
            title = failedElement.element.question?.alternatives[0]
            break;
        case 'clozeText':
            title = failedElement.element.title
            break;
        case 'order':
            title = failedElement.element.title
            break;
        default:
    }

    return (
        <div className="order tk-academy-card mb-3 p-3 failed-element-card d-flex flex-row justify-content-around"
             onClick={() => displayElement()}>
            <span className="icon-a_lection d-flex justify-content-center align-items-center"/>
            <div className={'w-75 d-flex flex-column'}>
                <div className={'tk-color-darkblue'}>
                    Lektion {failedElement.element.elementPath.moduleIndex + 1}.{failedElement.element.elementPath.lessonIndex + 1}
                </div>
                <div>
                    {title}
                </div>
            </div>
            <span className="icon-a_forward d-flex justify-content-center align-items-center"/>
        </div>
    )
}
