/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ModuleSubItem_ModuleFragment = {
    readonly containerMeta: {
        readonly points: number;
        readonly title: string;
    };
    readonly modulePath: {
        readonly moduleIndex: number;
    };
    readonly " $refType": "ModuleSubItem_ModuleFragment";
};
export type ModuleSubItem_ModuleFragment$data = ModuleSubItem_ModuleFragment;
export type ModuleSubItem_ModuleFragment$key = {
    readonly " $data"?: ModuleSubItem_ModuleFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ModuleSubItem_ModuleFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModuleSubItem_ModuleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContainerMeta",
      "kind": "LinkedField",
      "name": "containerMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "points",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ModulePath",
      "kind": "LinkedField",
      "name": "modulePath",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleIndex",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Module",
  "abstractKey": null
};
(node as any).hash = 'a616f8ce9dc5a10861bf43cf0cd34af1';
export default node;
