import {AcademyButton} from '../../../core/components/button/AcademyButton';
import React from 'react';
import {NavLink} from "react-router-dom";

interface OwnProps {
    courseId: string

    canGoBack: boolean
    isLastPage: boolean
    goForward: () => void
}

export const ReviewNextButton = ({
                                     courseId,
                                     isLastPage,
                                     goForward
                                 }: OwnProps) => {
    return isLastPage ?
        <NavLink to={`/courses/${courseId}`}><AcademyButton text={'Zum Kurs'}/></NavLink> :
        <AcademyButton text={'Weiter'} onClick={goForward}/>
}
