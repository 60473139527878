/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type LoginInput = {
    email: string;
    password: string;
    clientMutationId?: string | null;
};
export type InvitationScreen_LoginMutationVariables = {
    login: LoginInput;
};
export type InvitationScreen_LoginMutationResponse = {
    readonly Auth: {
        readonly login: {
            readonly login: {
                readonly user: {
                    readonly id: string;
                    readonly name: string;
                    readonly email: string;
                };
                readonly accounts: ReadonlyArray<{
                    readonly accountId: string;
                    readonly name: string;
                    readonly roles: ReadonlyArray<string>;
                }>;
                readonly rolesInCurrentAccount: ReadonlyArray<string>;
                readonly token: string;
            };
        } | null;
    };
};
export type InvitationScreen_LoginMutation = {
    readonly response: InvitationScreen_LoginMutationResponse;
    readonly variables: InvitationScreen_LoginMutationVariables;
};



/*
mutation InvitationScreen_LoginMutation(
  $login: LoginInput!
) {
  Auth {
    login(input: $login) {
      login {
        user {
          id
          name
          email
        }
        accounts {
          accountId
          name
          roles
        }
        rolesInCurrentAccount
        token
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "login"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthMutations",
    "kind": "LinkedField",
    "name": "Auth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "login"
          }
        ],
        "concreteType": "LoginPayload",
        "kind": "LinkedField",
        "name": "login",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LoginResponse",
            "kind": "LinkedField",
            "name": "login",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountInfo",
                "kind": "LinkedField",
                "name": "accounts",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accountId",
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "roles",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rolesInCurrentAccount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "token",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvitationScreen_LoginMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvitationScreen_LoginMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "dfdbd56c26d3829946f900b52cc8fc2f",
    "id": null,
    "metadata": {},
    "name": "InvitationScreen_LoginMutation",
    "operationKind": "mutation",
    "text": "mutation InvitationScreen_LoginMutation(\n  $login: LoginInput!\n) {\n  Auth {\n    login(input: $login) {\n      login {\n        user {\n          id\n          name\n          email\n        }\n        accounts {\n          accountId\n          name\n          roles\n        }\n        rolesInCurrentAccount\n        token\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '9bdcfffa348fadfc13e428ca50147af0';
export default node;
