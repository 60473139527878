import React from "react";
import {RouteAuthState, RouteDefinition} from "../../core/components/routing/RouteDefinition";
import {CheckoutV2} from "./components/checkout/CheckoutV2";
import {PaymentV2} from "./components/payment/PaymentV2";

export const BillingV2RouteDefinitions: RouteDefinition[] = [
    {
        path: "/checkout/offers-v2/:offerId",
        component: () => <CheckoutV2/>,
        authState: RouteAuthState.Independent
    },
    {
        path: "/checkout/orders-v2/:orderId",
        component: () => <PaymentV2/>,
        authState: RouteAuthState.Independent
    },
]