import React from "react";
import {VideoPlayer} from "../../core/components/video/VideoPlayer";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {VideoAdditionalContentDisplay_VideoAdditionalContentFragment$key} from "../../../__generated__/VideoAdditionalContentDisplay_VideoAdditionalContentFragment.graphql";

const VIDEO_ADDITIONAL_CONTENT_DISPLAY_VIDEO_ADDITIONAL_CONTENT_FRAGMENT = graphql`
    fragment VideoAdditionalContentDisplay_VideoAdditionalContentFragment on VideoAdditionalContent {
        title
        videoFile {
            url
        }
    }`

interface OwnProps {
    videoAdditionalContentFragmentRef: VideoAdditionalContentDisplay_VideoAdditionalContentFragment$key;
}

export const VideoAdditionalContentDisplay = ({
                                                  videoAdditionalContentFragmentRef
                                              }: OwnProps) => {

    const additionalContent = useFragment<VideoAdditionalContentDisplay_VideoAdditionalContentFragment$key>(VIDEO_ADDITIONAL_CONTENT_DISPLAY_VIDEO_ADDITIONAL_CONTENT_FRAGMENT, videoAdditionalContentFragmentRef);

    return <div>
        <h1 className="tk-academy-larger-bold mb-3 tk-color-darkblue">{additionalContent.title}</h1>
        {additionalContent.videoFile?.url && <VideoPlayer videoFile={additionalContent.videoFile.url}/>}
    </div>
}
