/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LessonNextButton_LessonFragment = {
    readonly id: string;
    readonly lessonPath: {
        readonly courseRef: string;
        readonly moduleRef: string;
    };
    readonly " $refType": "LessonNextButton_LessonFragment";
};
export type LessonNextButton_LessonFragment$data = LessonNextButton_LessonFragment;
export type LessonNextButton_LessonFragment$key = {
    readonly " $data"?: LessonNextButton_LessonFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LessonNextButton_LessonFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonNextButton_LessonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LessonPath",
      "kind": "LinkedField",
      "name": "lessonPath",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "courseRef",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleRef",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Lesson",
  "abstractKey": "__isLesson"
};
(node as any).hash = '514281166eb011c44c12cc14c5dab1b2';
export default node;
