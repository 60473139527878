/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LessonStateStatus = "current" | "finished" | "%future added value";
export type LessonImage_LessonStateFragment = {
    readonly status: LessonStateStatus;
    readonly " $refType": "LessonImage_LessonStateFragment";
};
export type LessonImage_LessonStateFragment$data = LessonImage_LessonStateFragment;
export type LessonImage_LessonStateFragment$key = {
    readonly " $data"?: LessonImage_LessonStateFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LessonImage_LessonStateFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonImage_LessonStateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "LessonState",
  "abstractKey": null
};
(node as any).hash = '1dd455737e4350682f53028ebaa72bfd';
export default node;
