/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PaymentMethod = "card" | "giropay" | "iapApple" | "iapGoogle" | "invoicePartner" | "invoiceTk" | "monthlyPartner" | "monthlyTk" | "paypal" | "sepa" | "sofort" | "%future added value";
export type SelectedPaymentMethodType = "monthly" | "oneTime" | "%future added value";
export type OneTimePaymentMethodCategory_OrderFragment = {
    readonly id: string;
    readonly selectedPaymentMethod: {
        readonly paymentMethodId: PaymentMethod;
        readonly selectedPaymentMethodType: SelectedPaymentMethodType;
    } | null;
    readonly allowedPaymentMethods: ReadonlyArray<PaymentMethod>;
    readonly cart: {
        readonly cartTotals: {
            readonly totalsIncludingAllDiscounts: {
                readonly netPrice: number;
                readonly grossPrice: number;
            } | null;
        };
    };
    readonly " $refType": "OneTimePaymentMethodCategory_OrderFragment";
};
export type OneTimePaymentMethodCategory_OrderFragment$data = OneTimePaymentMethodCategory_OrderFragment;
export type OneTimePaymentMethodCategory_OrderFragment$key = {
    readonly " $data"?: OneTimePaymentMethodCategory_OrderFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"OneTimePaymentMethodCategory_OrderFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OneTimePaymentMethodCategory_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "selectedPaymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethodId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "selectedPaymentMethodType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowedPaymentMethods",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CartTotals",
          "kind": "LinkedField",
          "name": "cartTotals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceV3",
              "kind": "LinkedField",
              "name": "totalsIncludingAllDiscounts",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "netPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "grossPrice",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
(node as any).hash = '6ba006f2731138fe361f6c6d1bed39b9';
export default node;
