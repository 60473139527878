import React from 'react'
import {LightboxImage} from "../../../../core/components/image/LightboxImage";
import {RandomPhrasing} from "../../../../core/components/random/RandomPhrasing";
import './failed-multiple-choice-element-display.scss'
import {BaseElementDisplay} from "../../../../elementFlow/components/common/BaseElementDisplay";
import {ResultCheckbox} from "../../../../core/components/form/ResultCheckbox";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {FailedMultipleChoiceElement_FailedElementFragment$key} from "../../../../../__generated__/FailedMultipleChoiceElement_FailedElementFragment.graphql";

const FAILED_MULTIPLE_CHOICE_ELEMENT_FAILED_ELEMENT_FRAGMENT = graphql`
    fragment FailedMultipleChoiceElement_FailedElementFragment on FailedElement {
        element {
            ... on MultipleChoiceElement {
                answerOptions {
                    answer {
                        alternatives
                    }
                    image {
                        url
                    }
                }
                image {
                    url
                }
                question {
                    ...RandomPhrasing_PhrasingAlternativesFragment
                }
            }
        }
        mistakes {
            ... on MultipleChoiceMistakes {
                explanations {
                    hasUserChecked
                    isCorrect
                }
            }
        }
    }`

interface OwnProps {
    failedElementFragmentRef: FailedMultipleChoiceElement_FailedElementFragment$key;
}

const alphabet = "ABCDEFGHIHKL"

export const FailedMultipleChoiceElement = ({failedElementFragmentRef}: OwnProps) => {
    const failedElement = useFragment<FailedMultipleChoiceElement_FailedElementFragment$key>(FAILED_MULTIPLE_CHOICE_ELEMENT_FAILED_ELEMENT_FRAGMENT, failedElementFragmentRef)

    return (
        <BaseElementDisplay
            className="multiple-choice-element"
            heading={failedElement.element.question && <RandomPhrasing phrasingAlternativesFragmentRef={failedElement.element.question}
                                     className="tk-academy-extra-large-heading-bold mb-5"/>}
            elementSpecificSlot={<>
                {failedElement.element.image?.url ? <div className="image-wrapper">
                    <LightboxImage className="element-image" image={failedElement.element.image.url}/>
                </div> : null}

                <div className="answers">
                    {failedElement.mistakes.explanations?.map((element, index) => {
                        const answerOption = failedElement.element.answerOptions ? failedElement.element.answerOptions[index] : undefined;

                        return (
                            <div
                                className={`answer mb-3 pt-3 pb-3 d-flex align-items-center ${element.isCorrect ? "failed-answer-checked" : ""} ${answerOption?.image?.url ? "has-image" : ""}`}
                                key={index}>
                                <div
                                    className="answer-index pl-4 pr-3 tk-color-darkblue tk-academy-bold">
                                    {alphabet[index]}
                                </div>
                                <div className="answer-text flex-grow-1">
                                    {answerOption?.image?.url ?
                                        <div className="answer-image-wrapper">
                                            <LightboxImage image={answerOption.image.url} className="element-image"/>
                                        </div> : null}
                                    <div
                                        className="tk-academy-text tk-color-darkblue">{answerOption?.answer.alternatives[0]}</div>
                                </div>
                                <ResultCheckbox
                                    className={'mr-3'}
                                    isFalse={!element.isCorrect && element.hasUserChecked}
                                    isChecked={element.hasUserChecked}
                                    isCorrect={element.isCorrect}/>
                            </div>
                        )
                    })}
                </div>
            </>
            }/>
    )
}