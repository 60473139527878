import React from 'react';
import {useTranslation} from 'react-i18next';
import {CourseIcon} from "../../../../course/components/common/course-icon/CourseIcon";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {PersologClassCard_PersologClassFragment$key} from "../../../../../__generated__/PersologClassCard_PersologClassFragment.graphql";

const PERSOLOG_CLASS_CARD_PERSOLOG_CLASS_FRAGMENT = graphql`
    fragment PersologClassCard_PersologClassFragment on PersologClass {
        classCode
        title,
        image {
            url
        }
    }`

interface OwnProps {
    persologClassFragmentRef: PersologClassCard_PersologClassFragment$key;
}

export const PersologClassCard = ({persologClassFragmentRef}: OwnProps) => {
    const {t} = useTranslation('elementflow')

    const persologClass = useFragment<PersologClassCard_PersologClassFragment$key>(PERSOLOG_CLASS_CARD_PERSOLOG_CLASS_FRAGMENT, persologClassFragmentRef)

    return <div className="tk-academy-card mb-3 d-flex p-3">
        {persologClass.image ? <CourseIcon
            className="mr-3"
            courseTitle={""}
            iconUrl={persologClass.image.url || undefined}
            isTest={false}
        /> : null}
        <div>
            <p className="tk-academy-large-bold">{persologClass.title} {persologClass.title} {persologClass.title}</p>
            <p className="tk-academy-text">{t('persolog-element-display.result.explanation-link')}</p>
        </div>
    </div>
}
