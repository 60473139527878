import React, {ReactNode} from "react";
import "./celebration-modal.scss"

import SpecialKeys from "./keys.png";
import SpecialLocked from "./locked.png";
import SpecialUpdate from "./update.png";
import SpecialTrialOver from "./trial-over.png";
import SpecialCourseDone from "./course-done.png";
import SpecialMountain from "./special-mountain.png";
import SpecialNewbie from "./newbie.png";

import {GenericAchievementImage} from "@thekeytechnology/thekey-academy-frontend-library";

import SpecialCertificationPending from "./certification-pending.png"
import {BasicModal} from "../../core/components/modal/BasicModal";

interface OwnProps {
    title: string,
    explanation: string,
    image?: string;
    text: string,
    children: ReactNode,
    onHide: () => void;
}

export const CelebrationModal = ({
                                     title,
                                     explanation,
                                     image,
                                     text,
                                     children,
                                     onHide
                                 }: OwnProps) => {

    let imageToUse;
    if (!image) {
        imageToUse = GenericAchievementImage;
    } else {
        if (image === "special-keys") {
            imageToUse = SpecialKeys
        } else if (image === "special-locked") {
            imageToUse = SpecialLocked
        } else if (image === "special-update") {
            imageToUse = SpecialUpdate
        } else if (image === "special-trial-over") {
            imageToUse = SpecialTrialOver
        } else if (image === "special-course-done") {
            imageToUse = SpecialCourseDone
        } else if (image === "special-certification-pending") {
            imageToUse = SpecialCertificationPending
        } else if (image === "special-mountain") {
            imageToUse = SpecialMountain
        } else if (image === "special-newbie") {
            imageToUse = SpecialNewbie
        } else {
            imageToUse = image
        }
    }

    return (
        <BasicModal centered={true} onHide={() => onHide()} dialogClassName="celebration-modal" show={true}>
            <div className="achievement-modal-content">
                <img alt={title}
                     src={imageToUse}/>
                <h1>{title}</h1>
                <div className="explanation-wrapper">
                    <div
                        className="explanation tk-academy-text tk-academy-bold">{explanation}</div>
                    <div
                        className="gotten-text tk-academy-text"><p dangerouslySetInnerHTML={{__html: text}}/></div>
                    {children}
                </div>
            </div>
        </BasicModal>)
}
