/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseSidebar_CourseFragment = {
    readonly containerMeta: {
        readonly title: string;
    };
    readonly image: {
        readonly url: string | null;
    } | null;
    readonly " $refType": "CourseSidebar_CourseFragment";
};
export type CourseSidebar_CourseFragment$data = CourseSidebar_CourseFragment;
export type CourseSidebar_CourseFragment$key = {
    readonly " $data"?: CourseSidebar_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseSidebar_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseSidebar_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContainerMeta",
      "kind": "LinkedField",
      "name": "containerMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = '7421b487844b13458e24d31a0d0aad0e';
export default node;
