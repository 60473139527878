import React from "react";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import styled from "styled-components";
import {PaymentStepClosed_OrderFragment$key} from "../../../../../__generated__/PaymentStepClosed_OrderFragment.graphql";
import {useTranslation} from "react-i18next";

const ORDER_FRAGMENT = graphql`
    fragment PaymentStepClosed_OrderFragment on Order {
        selectedPaymentMethod {
            selectedPaymentMethodType
            paymentMethodId
            ... on MonthlySelectedPaymentMethod {
                chosenOption {
                    rateCount
                }
            }
        }
    }
`;

interface OwnProps {
    orderFragmentRef: PaymentStepClosed_OrderFragment$key
}

export const PaymentStepClosed = ({orderFragmentRef}: OwnProps) => {
    const {t} = useTranslation("billing")

    const order = useFragment<PaymentStepClosed_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)

    return <ClosedStateContainer>
        {order.selectedPaymentMethod?.selectedPaymentMethodType === "monthly" ? <>
            Monatlich in {order.selectedPaymentMethod.chosenOption?.rateCount} Zahlungen per Lastschrift.
        </> : <>
            Einmalige Zahlung per {t("payment-methods." + order.selectedPaymentMethod?.paymentMethodId)}.
        </>}
    </ClosedStateContainer>
}

const ClosedStateContainer = styled.div`
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  line-height: 1.38;
  color: #184276;
  margin-top: 5px;
`
