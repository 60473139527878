/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ReviewProcessCurrentElementScreen_ElementsConnectionFragment = {
    readonly edges: ReadonlyArray<{
        readonly node: {
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"ElementDisplay_ElementFragment" | "ReviewContentArea_ElementFragment" | "ReviewHeader_ElementFragment" | "ReviewSidebar_ElementFragment">;
        };
    } | null> | null;
    readonly " $refType": "ReviewProcessCurrentElementScreen_ElementsConnectionFragment";
};
export type ReviewProcessCurrentElementScreen_ElementsConnectionFragment$data = ReviewProcessCurrentElementScreen_ElementsConnectionFragment;
export type ReviewProcessCurrentElementScreen_ElementsConnectionFragment$key = {
    readonly " $data"?: ReviewProcessCurrentElementScreen_ElementsConnectionFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ReviewProcessCurrentElementScreen_ElementsConnectionFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewProcessCurrentElementScreen_ElementsConnectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ElementsEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ElementDisplay_ElementFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ReviewContentArea_ElementFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ReviewHeader_ElementFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ReviewSidebar_ElementFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ElementsConnection",
  "abstractKey": null
};
(node as any).hash = '733edd8c23eba427f06d6d25f3e22e24';
export default node;
