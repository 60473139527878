import React from 'react';
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import styled from 'styled-components';
import {CustomerMessageDefinitionButton} from "./buttons/CustomerMessageDefinitionButton";
import {CustomerMessagePage_CustomerMessageDefinitionPageFragment$key} from "../../../../__generated__/CustomerMessagePage_CustomerMessageDefinitionPageFragment.graphql";

const CUSTOMER_MESSAGE_DEFINITION_PAGE_FRAGMENT = graphql`
    fragment CustomerMessagePage_CustomerMessageDefinitionPageFragment on CustomerMessageDefinitionPage {
        imageOpt {
            url
        }
        customerMessageDefinition {
            buttonOpt {
                ...CustomerMessageDefinitionButton_CustomerMessageDefinitionButtonFragment
            }
        }
    }`

interface OwnProps {
    modalWidth: number
    onClose?: () => void
    customerMessageDefinitionPageFragmentRef?: CustomerMessagePage_CustomerMessageDefinitionPageFragment$key
}

export const CustomerMessagePage = ({
                                                modalWidth,
                                                onClose,
                                                customerMessageDefinitionPageFragmentRef
                                            }: OwnProps) => {
    const page = useFragment<CustomerMessagePage_CustomerMessageDefinitionPageFragment$key>(CUSTOMER_MESSAGE_DEFINITION_PAGE_FRAGMENT, customerMessageDefinitionPageFragmentRef || null)

    if (!page || !page.customerMessageDefinition) return null

    return <>
        <Image src={page.imageOpt!.url!} alt={""} modalWidth={modalWidth}/>
        {onClose && <CustomerMessageDefinitionButton onClose={onClose} modalWidth={modalWidth} customerMessageDefinitionButtonFragmentRef={page.customerMessageDefinition.buttonOpt}/>}
    </>
}

const Image = styled.img<{ modalWidth: number }>`
  width: ${props => props.modalWidth}px;
`