import {useDispatch, useSelector} from "react-redux";
import React, {ReactNode, useContext} from "react";
import {logoutAction, selectCurrentUser} from "@thekeytechnology/framework-react";
import {
    CurrentRelayEnvironmentContext
} from "../../../../CurrentRelayEnvironmentContextProvider";

interface OwnProps {
    children: ReactNode;
    className?: string;
    afterClick?: () => void;
}

export const LogoutLink = ({children, className, afterClick}: OwnProps) => {
    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser);

    const currentRelayEnvironmentContext = useContext(CurrentRelayEnvironmentContext);

    return user ? <button
        onClick={() => {
            dispatch(logoutAction())
            currentRelayEnvironmentContext.reset();
            afterClick && afterClick()
        }}
        className={`btn-link ${className ? className : ""}`}>
        {children}
    </button> : null;

}
