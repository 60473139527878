import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {fetchInvitationsInAccount} from "../../action/fetch-invitations-in-account";
import {selectInvitationsInAccount} from "../../selectors";
import {DateTimeDisplay} from "../../../core/components/display/DateTimeDisplay";
import "./invited-users-list.scss"
import {deleteInvitationAction} from "../../action/remove-invitation";

export const InvitedUsersList = () => {
    const dispatch = useDispatch();

    const invitations = useSelector(selectInvitationsInAccount)
    useEffect(() => {
        dispatch(fetchInvitationsInAccount())
    }, [dispatch])

    return invitations && invitations.length > 0 ? <div className="mb-5 invited-users-list">
        <h3 className="tk-academy-text tk-academy-bold mb-2 tk-color-waterblue-80 d-flex align-items-center">
            Eingeladene Benutzer
        </h3>
        <div className="invited-users">
            {invitations.map(invitation => {
                return <div className="tk-academy-card p-2 invited-user" key={invitation.id}>
                    <div>
                        <div className="tk-academy-text">
                            {invitation.entity.email}</div>
                        <div className="tk-academy-small-text">Gültig bis <DateTimeDisplay
                            dateTime={invitation.entity.validUntil}/></div>
                    </div>
                    <button className="tk-color-orange"
                            onClick={() => dispatch(deleteInvitationAction(invitation.id!))}>
                        <span className="icon-a_trash"/>
                    </button>
                </div>
            })}
        </div>


    </div> : null
}
