/* eslint-disable react-hooks/exhaustive-deps */
import React, {CSSProperties, ReactNode, useEffect, useState} from "react";
import {PageDisplay} from "./PageDisplay";

interface OwnProps {
    pages: ReactNode[];
    initialPage?: number;
    style?: CSSProperties;

    previousButtonSlot: (canGoBack: boolean, isLastPage: boolean, goBackward: () => void) => ReactNode;
    nextButtonSlot: (canGoBack: boolean, isLastPage: boolean, goBackward: () => void) => ReactNode;
}

interface State {
    currentPage: number,
    canGoBack: boolean,
    isLastScreen: boolean,
    pages: ReactNode[]
}

export const MultiPageScreen = ({pages, style, initialPage, previousButtonSlot, nextButtonSlot}: OwnProps) => {
    const [state, setState] = useState<State>({
        isLastScreen: false,
        canGoBack: false,
        currentPage: 0,
        pages
    })

    const moveOnePageBack = () => setState({
        ...state,
        currentPage: Math.max(0, state.currentPage - 1)
    })

    const moveOnePageForward = () => setState({
        ...state,
        currentPage: Math.max(0, state.currentPage + 1)
    })

    useEffect(() => setState({
        ...state,
        canGoBack: state.currentPage > 0,
        isLastScreen: state.currentPage + 1 === state.pages.length
    }), [state.currentPage])

    useEffect(() => {
        const initPage = initialPage ?? 0
        setState({
            ...state,
            currentPage: initPage,
            isLastScreen: initPage + 1 === pages.length,
            canGoBack: initPage > 0,
            pages
        })
    }, [initialPage, pages])

    return (
        <div className="d-flex flex-column justify-content-center align-items-center w-100 finished-display h-100">
            <PageDisplay
                pages={state.pages}
                pageIndex={state.currentPage}
                navigation={(canGoBack: boolean, isLastScreen: boolean) => setState({
                    ...state,
                    canGoBack,
                    isLastScreen
                })}/>
            <div className="d-flex flex-row bottom-0 justify-content-between align-items-center p-3 w-100 bg-light">
                {previousButtonSlot(state.canGoBack, state.isLastScreen, moveOnePageBack)}
                {nextButtonSlot(state.canGoBack, state.isLastScreen, moveOnePageForward)}
            </div>
        </div>
    )
}
