import React from "react";
import "./result-checkbox.scss";

interface OwnProps {
    className?: string;
    isCorrect: boolean;
    isChecked: boolean;
    isFalse: boolean;
}

export const ResultCheckbox = ({className, isCorrect, isChecked, isFalse}: OwnProps) => {
    return (
        <div className={`result-checkbox-wrapper m-2`}>
            <div
                className={`result-checkbox ${isCorrect && isChecked ? 'correct-and-checked' : isCorrect && !isChecked ? 'correct-and-not-checked' : isFalse && isChecked ? 'false-but-checked' : ''} ${className}`}>
                {
                    isCorrect ? <span className="icon-a_tick"/> :
                        isFalse ? <span className="icon-a_close"/> :
                            null
                }
            </div>
        </div>
    )
}
