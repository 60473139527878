/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type QnaItem_QnaFragment = {
    readonly answer: string | null;
    readonly id: string;
    readonly isFromCurrentUser: boolean;
    readonly isPublic: boolean;
    readonly question: string;
    readonly " $refType": "QnaItem_QnaFragment";
};
export type QnaItem_QnaFragment$data = QnaItem_QnaFragment;
export type QnaItem_QnaFragment$key = {
    readonly " $data"?: QnaItem_QnaFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"QnaItem_QnaFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QnaItem_QnaFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFromCurrentUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPublic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "question",
      "storageKey": null
    }
  ],
  "type": "Qna",
  "abstractKey": null
};
(node as any).hash = '96182200d5d37ec3c92c3050e5ad9f35';
export default node;
