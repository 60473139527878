import {Form, Formik} from "formik";
import * as Yup from "yup";
import React from "react";
import {useTranslation} from "react-i18next";
import {ValidatedField} from "../../../core/components/form/ValidatedField";

import {AuthScreenHeading} from "../common/AuthScreenHeading";
import {AuthFormWrapper} from "../common/AuthFormWrapper";
import styled from "styled-components";
import {tkTypography} from "../../../core/style/tkTypography";
import {tkColors} from "../../../core/style/tkColors";
import {NavLink} from "react-router-dom";

interface OwnProps {
    login: (email: string, password: string) => void
    loginIsInFlight: boolean
    switchToRegistration: () => void
    showForgotPassword: boolean
}

export const LoginForm = ({
                              login,
                              loginIsInFlight,
                              switchToRegistration,
                              showForgotPassword
                          }: OwnProps) => {
    const {t} = useTranslation(["auth", "core"]);

    return <AuthFormWrapper>
        <AuthScreenHeading
            title={t("login-form.heading-1")}
            subtitle={t("login-form.heading-2")}
        />
        <Formik
            initialValues={{email: "", password: ""}}
            validationSchema={Yup.object().shape({
                email: Yup
                    .string()
                    .email(t("registration-form.email-error"))
                    .required(t("core:forms.required-field", {fieldName: t("registration-form.email")})),
                password: Yup
                    .string()
                    .min(8, t("registration-form.password-error"))
                    .required(t("core:forms.required-field", {fieldName: t("registration-form.password")})),
            })}
            onSubmit={(values, {setSubmitting}) => {
                login(values.email, values.password);
                setSubmitting(false);
            }}
        >
            {formikState => (
                <Form>
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        iconClass={"icon-d_mail"}
                                        type="email"
                                        placeholder={t("login-form.e-mail-placeholder")}
                                        name="email"
                                        className="form-control default-input"
                                        required/>
                    </div>
                    <div className="form-group mb-2">
                        <ValidatedField formikState={formikState}
                                        type="password"
                                        iconClass={"icon-a_lock"}
                                        placeholder={t("login-form.password-placeholder")}
                                        name="password"
                                        className="form-control default-input"
                                        required/>
                    </div>
                    <div className="small-link mb-4 text-right tk-academy-small-text">
                        {showForgotPassword &&
                        <NavLink to="/forgot-password">{t("login-form.forgot-password")}</NavLink>}
                    </div>
                    <div className="form-group d-flex mb-4">
                        <button type="submit" disabled={formikState.isSubmitting || loginIsInFlight}
                                className="btn btn-primary align-self-end w-100">
                            {t("login-form.login")}
                        </button>
                    </div>
                    <div className="tk-academy-small-text text-center paragraph mb-3">
                        {t("login-form.sign-up-explanation")} <SwitchToRegistrationLink
                        onClick={switchToRegistration}>{t("login-form.sign-up-link")}</SwitchToRegistrationLink>
                    </div>
                </Form>

            )}
        </Formik>
    </AuthFormWrapper>
}

const SwitchToRegistrationLink = styled.a`
    ${tkColors.keyBlue}
    ${tkTypography.small}
`
