declare global {
    interface Window {
        dataLayer: any
    }
}

const track = (params: {}) => {
    if (window.dataLayer === undefined || window.dataLayer === null) return

    window.dataLayer.push(params);
}

export const gtmTrackRegistration = (base64EncodedUserId: string) => {
    const dataObj: {
        event: string
        base64EncodedUserId: string
    } = {
        event: "registration",
        base64EncodedUserId: base64EncodedUserId
    }

    track(dataObj)
}

export const gtmTrackAddToCart = (
    lastAddedProduct: { base64EncodedProductId: string, quantity: number, productName: string },
    selectedProducts: { base64EncodedProductId: string, quantity: number }[],
    lastAddedProductPriceIncludingAllDiscounts: number,
    selectedProductsPriceIncludingAllDiscounts: number
) => {
    const dataObj: {
        event: string
        lastAddedProduct: {
            base64EncodedProductId: string
            quantity: number
            productName: string
        },
        selectedProducts: {
            base64EncodedProductId: string
            quantity: number
        }[],
        productPriceIncludingAllDiscounts: number
        selectedProductsPriceIncludingAllDiscounts: number,
    } = {
        event: "addToCart",
        lastAddedProduct: lastAddedProduct,
        selectedProducts: selectedProducts,
        productPriceIncludingAllDiscounts: lastAddedProductPriceIncludingAllDiscounts,
        selectedProductsPriceIncludingAllDiscounts: selectedProductsPriceIncludingAllDiscounts,
    }

    track(dataObj);
}

export const gtmTrackPurchase = (selectedProducts: { base64EncodedProductId: string, quantity: number, productName: string }[], productPriceIncludingAllDiscounts: number, base64EncodedOrderId: string) => {
    const dataObj: {
        event: string
        selectedProductsPriceIncludingAllDiscounts: number
        base64EncodedOrderId: string
        selectedProducts: {
            base64EncodedProductId: string
            quantity: number
            productName: string
        }[]
    } = {
        event: "purchase",
        selectedProductsPriceIncludingAllDiscounts: productPriceIncludingAllDiscounts,
        base64EncodedOrderId: base64EncodedOrderId,
        selectedProducts: selectedProducts,
    }

    track(dataObj)
}

export const gtmTrackStartTrial = (base64EncodedCourseStateId: string) => {
    const dataObj: {
        event: string
        base64EncodedCourseStateId: string
    } = {
        event: "startTrial",
        base64EncodedCourseStateId: base64EncodedCourseStateId
    }

    track(dataObj)
}

export const gtmTrackInitiateCheckout = (selectedProducts: { base64EncodedProductId: string, quantity: number, productName: string }[], base64EncodedOrderId: string, productPriceIncludingAllDiscounts: number) => {
    const dataObj: {
        event: string
        productPriceIncludingAllDiscounts: number
        base64EncodedOrderId: string
        selectedProducts: {
            base64EncodedProductId: string
            quantity: number
            productName: string
        }[],
    } = {
        event: "initiateCheckout",
        productPriceIncludingAllDiscounts: productPriceIncludingAllDiscounts,
        base64EncodedOrderId: base64EncodedOrderId,
        selectedProducts: selectedProducts
    }

    track(dataObj)
}

export const gtmTrackFirstSubmission = (base64EncodedUserId: string) => {
    const dataObj: {
        event: string
        base64EncodedUserId: string
    } = {
        event: "firstSubmission",
        base64EncodedUserId: base64EncodedUserId
    }

    track(dataObj)
}

export const gtmTrackViewLogin = () => {
    const dataObj: {
        event: string
    } = {
        event: "viewLogin"
    }

    track(dataObj)
}

export const gtmTrackViewCourseDetail = (base64EncodedMainProductId: string, mainProductName: string, allNonHiddenBase64EncodedProductIds: string[]) => {
    const dataObj: {
        event: string,
        base64EncodedMainProductId: string,
        allNonHiddenBase64EncodedProductIds: string[],
        mainProductName: string,

    } = {
        event: "viewCourseDetail",
        base64EncodedMainProductId: base64EncodedMainProductId,
        allNonHiddenBase64EncodedProductIds: allNonHiddenBase64EncodedProductIds,
        mainProductName: mainProductName
    }

    track(dataObj)
}

export const gtmTrackPageView = () => {
    track({event: "pageView"})
}
