export const academyTextSizesForStyledComps = {
    small: `
        font-size: 12px;
        line-height: 1.67;
    `,
    regular: `
        font-size: 14px;
        line-height: 1.43;
    `,
}
