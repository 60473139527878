import React, {useState} from "react";

import {Screen} from "../../core/components/screen/Screen";
import {GradientBackgroundHeader} from "../../core/components/screen/GradientBackground";
import {Switch, useRouteMatch} from "react-router";
import {LessonDetailSidebar} from "./sidebar/LessonDetailSidebar";
import {LessonDetailFooter} from "./LessonDetailFooter";
import {PointsDisplay} from "./PointsDisplay";
import {FeedbackButton} from "../../feedback/controllers/FeedbackButton";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment, useLazyLoadQuery, useSubscribeToInvalidationState} from "react-relay";
import {LessonDetailScreen_Query} from "../../../__generated__/LessonDetailScreen_Query.graphql";
import {LessonDetailScreen_LessonFragment$key} from "../../../__generated__/LessonDetailScreen_LessonFragment.graphql";
import {QnasScreen} from "../../qnas/components/QnasScreen";
import {NotesScreenContent} from "../../notes/components/NotesScreenContent";
import {LessonInfo} from "./info/LessonInfo";
import {
    LessonDetailScreen_CourseStateFragment$key
} from "../../../__generated__/LessonDetailScreen_CourseStateFragment.graphql";
import {
    LessonDetailScreen_LessonStateFragment$key
} from "../../../__generated__/LessonDetailScreen_LessonStateFragment.graphql";
import {CelebrationPopup} from "../../celebration/components/CelebrationPopup";
import {GlossaryEntriesOfNormalLessonDisplay} from "../../glossary/components/GlossaryEntriesOfNormalLessonDisplay";
import {Route} from "react-router-dom";

const QUERY = graphql`
    query LessonDetailScreen_Query($courseId: ID!, $lessonId: ID!) {
        node(id: $lessonId) {
            ...LessonDetailScreen_LessonFragment
        }
        Viewer {
            CourseState {
                CourseState(Input: {courseId: $courseId}) {
                    ...LessonDetailScreen_CourseStateFragment
                }
            }
            CustomerMessageV2 {
                CustomerMessageInformation {
                    id
                    numUnseenCustomerMessages
                }
            }
            License {
                NewLicensesInAccount {
                    id
                    newLicenses
                }
                LicensePools {
                    ...CelebrationPopup_LicensePools
                }
            }
        }
    }`

const COURSE_STATE_FRAGMENT = graphql`
    fragment LessonDetailScreen_CourseStateFragment on CourseState {
        id
        lessonStates {
            lessonRef
            ...LessonDetailScreen_LessonStateFragment
        }
        ...CelebrationPopup_CourseStateFragment
        ...LessonDetailFooter_CourseStateFragment
        ...LessonInfo_CourseStateFragment
        ...LessonDetailSidebar_CourseStateFragment
    }`

const LESSON_FRAGMENT = graphql`
    fragment LessonDetailScreen_LessonFragment on Lesson {
        containerMeta {
            points
            title
        }
        course {
            id
            ...CelebrationPopup_CourseFragment
            ...NotesScreenContent_CourseFragment
        }
        id
        lessonPath {
            moduleRef
        }
        lessonType
        ... on NormalLesson {
            glossary {
                id
            }
            ...GlossaryEntriesOfNormalLessonDisplay_NormalLessonFragment
        }
        ...LessonDetailFooter_LessonFragment
        ...LessonDetailSidebar_LessonFragment
        ...LessonInfo_LessonFragment
        ...NotesScreenContent_LessonFragment
    }`

const LESSON_STATE_FRAGMENT = graphql`
    fragment LessonDetailScreen_LessonStateFragment on LessonState {
        ...LessonInfo_LessonStateFragment
    }`

interface State {
    fetchKey: number
}

interface OwnProps {
    courseId: string
    lessonId: string
}

const LessonDetailScreenComponent = ({courseId, lessonId}: OwnProps) => {

    const [state, setState] = useState<State>({fetchKey: 0})

    const query = useLazyLoadQuery<LessonDetailScreen_Query>(QUERY, {
        courseId: courseId,
        lessonId: lessonId
    }, {fetchPolicy: "network-only", fetchKey: state.fetchKey})

    const courseState = useFragment<LessonDetailScreen_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, query.Viewer.CourseState.CourseState)

    const lesson = useFragment<LessonDetailScreen_LessonFragment$key>(LESSON_FRAGMENT, query.node)

    const lessonState = useFragment<LessonDetailScreen_LessonStateFragment$key>(LESSON_STATE_FRAGMENT, courseState?.lessonStates.find(lS => lS.lessonRef === lesson?.id) || null)

    useSubscribeToInvalidationState([courseState?.id].filter(id => !!id).map(id => id!), () => {
        setState(state => ({...state, fetchKey: state.fetchKey + 1}))
    })

    if (!lesson) return null

    return <Screen
        sidebarSlot={<LessonDetailSidebar
            courseId={courseId} lessonFragmentRef={lesson}
            courseStateFragmentRef={courseState}
            moduleId={lesson.lessonPath.moduleRef}
            numUnseenCustomerMessages={query.Viewer.CustomerMessageV2.CustomerMessageInformation.numUnseenCustomerMessages}
            newLicensesInAccount={query.Viewer.License.NewLicensesInAccount.newLicenses}
        />}
        headerBarBackgroundSlot={
            <GradientBackgroundHeader
                buttonSlot={<div className="d-flex">
                    <FeedbackButton className="mr-2" courseId={courseId} lessonId={lessonId}
                                    screen={"lesson-detail"}/>
                    <PointsDisplay points={lesson.containerMeta.points}/>
                </div>}
                height={250}
            />
        }
        headerMargin={60}
        floatingFooterSlot={<LessonDetailFooter courseStateFragmentRef={courseState} hasLessonState={!!lessonState}
                                                lessonFragmentRef={lesson}/>}
    >
        <Switch>
            <Route
                exact
                path="/courses/:courseId/modules/:moduleId/lessons/:lessonId"
                component={() => <LessonInfo
                    courseStateFragmentRef={courseState}
                    lessonFragmentRef={lesson}
                    lessonStateFragmentRef={lessonState}
                />}/>
            <Route
                exact
                path="/courses/:courseId/modules/:moduleId/lessons/:lessonId/notes"
                component={() => <NotesScreenContent
                    courseFragmentRef={lesson.course}
                    lessonFragmentRef={lesson}
                    headingSlot={<div
                        className="tk-academy-extra-large-heading-bold mb-5 ">{`Notizen für Lektion ${lesson.containerMeta.title}`}</div>}
                />}/>
            <Route
                exact
                path="/courses/:courseId/modules/:moduleId/lessons/:lessonId/qnas"
                component={() => <QnasScreen
                    headingSlot={<div
                        className="tk-academy-extra-large-heading-bold mb-5 ">{`Fragen & Antworten für Lektion ${lesson.containerMeta.title}`}</div>}
                    courseId={courseId}
                    lessonId={lessonId}
                />}/>
            {lesson.lessonType === 'normal' && lesson.glossary && lesson.glossary.length > 0 && <Route
                exact
                path="/courses/:courseId/modules/:moduleId/lessons/:lessonId/glossary"
                component={() => <GlossaryEntriesOfNormalLessonDisplay
                    normalLessonFragmentRef={lesson}
                />}/>}
        </Switch>
        <CelebrationPopup courseFragmentRef={lesson.course} courseStateFragmentRef={courseState}
                          licensePoolsFragmentRef={query.Viewer.License.LicensePools}/>
    </Screen>
}

export const LessonDetailScreen = () => {
    const router = useRouteMatch<{ courseId?: string, lessonId?: string }>()

    if (!router.params.courseId || !router.params.lessonId) return null

    return <LessonDetailScreenComponent courseId={router.params.courseId} lessonId={router.params.lessonId}/>
}
