import {PaymentMethod} from "../../../../../__generated__/OneTimePaymentMethodCategory_OrderFragment.graphql";
import CardImage from "./images/card.png";
import GiropayImage from "./images/giropay.png";
import PaypalImage from "./images/paypal.png";
import SofortImage from "./images/sofort.png";
import SepaImage from "./images/sepa.png";
import InvoiceImage from "./images/invoice.png";
import {useTranslation} from "react-i18next";
import React from "react";
import styled from "styled-components";


interface OwnProps {
    paymentMethod: PaymentMethod
}

export const PaymentOptionDisplay = ({paymentMethod}: OwnProps) => {
    const {t} = useTranslation("billing")

    let Image;
    switch (paymentMethod) {
        case "card":
            Image = CardImage
            break;
        case "giropay":
            Image = GiropayImage;
            break;
        case "paypal":
            Image = PaypalImage;
            break;
        case "sofort":
            Image = SofortImage;
            break;
        case "sepa":
            Image = SepaImage;
            break;
        case "invoiceTk":
        case "invoicePartner":
            Image = InvoiceImage;
            break;
    }

    const title = t("payment-methods." + paymentMethod);

    return <PaymentMethodContainer>
        <PaymentMethodIcon src={Image} alt={title}/> {title}
    </PaymentMethodContainer>
}

const PaymentMethodIcon = styled.img`
  width: 45px;
  height: 25px;
  margin-right: 10px;
`

const PaymentMethodContainer = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  color: #000;
  display: flex;
  align-items: center;
`
