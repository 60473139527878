/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ElementType = "clozeText" | "image" | "multipleChoice" | "order" | "persolog" | "questionnaire" | "resolution" | "text" | "video" | "%future added value";
export type ElementDisplay_ElementFragment = {
    readonly elementType: ElementType;
    readonly rawId: string;
    readonly " $fragmentRefs": FragmentRefs<"ClozeTextElementDisplay_ClozeTextElementFragment" | "ImageElementDisplay_ImageElementFragment" | "MultipleChoiceElementDisplay_MultipleChoiceElementFragment" | "OrderElementDisplay_OrderElementFragment" | "PersologElementDisplay_PersologElementFragment" | "QuestionnaireElementDisplay_QuestionnaireElementFragment" | "ResolutionElementDisplay_ResolutionElementFragment" | "TextElementDisplay_TextElementFragment" | "VideoElementDisplay_VideoElementFragment">;
    readonly " $refType": "ElementDisplay_ElementFragment";
};
export type ElementDisplay_ElementFragment$data = ElementDisplay_ElementFragment;
export type ElementDisplay_ElementFragment$key = {
    readonly " $data"?: ElementDisplay_ElementFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ElementDisplay_ElementFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ElementDisplay_ElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rawId",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClozeTextElementDisplay_ClozeTextElementFragment"
        }
      ],
      "type": "ClozeTextElement",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ImageElementDisplay_ImageElementFragment"
        }
      ],
      "type": "ImageElement",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MultipleChoiceElementDisplay_MultipleChoiceElementFragment"
        }
      ],
      "type": "MultipleChoiceElement",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OrderElementDisplay_OrderElementFragment"
        }
      ],
      "type": "OrderElement",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PersologElementDisplay_PersologElementFragment"
        }
      ],
      "type": "PersologElement",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "QuestionnaireElementDisplay_QuestionnaireElementFragment"
        }
      ],
      "type": "QuestionnaireElement",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ResolutionElementDisplay_ResolutionElementFragment"
        }
      ],
      "type": "ResolutionElement",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TextElementDisplay_TextElementFragment"
        }
      ],
      "type": "TextElement",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "VideoElementDisplay_VideoElementFragment"
        }
      ],
      "type": "VideoElement",
      "abstractKey": null
    }
  ],
  "type": "Element",
  "abstractKey": "__isElement"
};
(node as any).hash = '382c1c849ec85080e367c0d88c2fa87d';
export default node;
