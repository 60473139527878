import React from "react";
import "./qna-item.scss"
import {useTranslation} from "react-i18next";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {QnaItem_QnaFragment$key} from "../../../__generated__/QnaItem_QnaFragment.graphql";

const QNA_ITEM_QNA_FRAGMENT = graphql`
    fragment QnaItem_QnaFragment on Qna {
        answer
        id
        isFromCurrentUser
        isPublic
        question
    }`

interface OwnProps {
    qnaFragmentRef: QnaItem_QnaFragment$key;
}

export const QnaItem = ({qnaFragmentRef}: OwnProps) => {

    const qna = useFragment<QnaItem_QnaFragment$key>(QNA_ITEM_QNA_FRAGMENT, qnaFragmentRef)

    const {t} = useTranslation("qnas")

    return <div className="qna-item mb-4">
        <div className="question p-2">
            <div className="author tk-academy-small-text tk-color-waterblue-80">
                {qna.isFromCurrentUser ? t("qna-item.you-asked") : t("qna-item.a-user-asked")}
            </div>
            <div className="text tk-academy-large-bold">
                {qna.question}
            </div>
            {qna.isPublic && qna.isFromCurrentUser ?
                <div className="tk-academy-small-text tk-color-waterblue-80">{t("qna-item.is-public")}</div> : null}
            {!qna.answer ?
                <div
                    className="tk-academy-small-text tk-color-waterblue-80">{t("qna-item.not-yet-answered")}</div> : null}
        </div>
        {qna.answer ? <div className="answer p-2 ml-5 mt-3">
            <div className="author tk-academy-small-text tk-color-waterblue-80">
                {t("qna-item.answer-heading")}
            </div>
            <div className="text tk-academy-large-bold">
                {qna.answer}
            </div>
        </div> : null}
    </div>
}
