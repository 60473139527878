/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type FeedbackModal_PostFeedbackMutationVariables = {
    courseId?: string | null;
    elementId?: string | null;
    feedback: string;
    lessonId?: string | null;
    location?: string | null;
    source?: string | null;
};
export type FeedbackModal_PostFeedbackMutationResponse = {
    readonly Feedback: {
        readonly postFeedback: {
            readonly clientMutationId: string | null;
        } | null;
    };
};
export type FeedbackModal_PostFeedbackMutation = {
    readonly response: FeedbackModal_PostFeedbackMutationResponse;
    readonly variables: FeedbackModal_PostFeedbackMutationVariables;
};



/*
mutation FeedbackModal_PostFeedbackMutation(
  $courseId: ID
  $elementId: ID
  $feedback: String!
  $lessonId: ID
  $location: String
  $source: String
) {
  Feedback {
    postFeedback(input: {courseId: $courseId, elementId: $elementId, feedback: $feedback, lessonId: $lessonId, location: $location, source: $source}) {
      clientMutationId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "courseId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "elementId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "feedback"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lessonId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "location"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "source"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "FeedbackMutations",
    "kind": "LinkedField",
    "name": "Feedback",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "courseId",
                "variableName": "courseId"
              },
              {
                "kind": "Variable",
                "name": "elementId",
                "variableName": "elementId"
              },
              {
                "kind": "Variable",
                "name": "feedback",
                "variableName": "feedback"
              },
              {
                "kind": "Variable",
                "name": "lessonId",
                "variableName": "lessonId"
              },
              {
                "kind": "Variable",
                "name": "location",
                "variableName": "location"
              },
              {
                "kind": "Variable",
                "name": "source",
                "variableName": "source"
              }
            ],
            "kind": "ObjectValue",
            "name": "input"
          }
        ],
        "concreteType": "PostFeedbackPayload",
        "kind": "LinkedField",
        "name": "postFeedback",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FeedbackModal_PostFeedbackMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FeedbackModal_PostFeedbackMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6560545104b41e9d5c93d27feff95a97",
    "id": null,
    "metadata": {},
    "name": "FeedbackModal_PostFeedbackMutation",
    "operationKind": "mutation",
    "text": "mutation FeedbackModal_PostFeedbackMutation(\n  $courseId: ID\n  $elementId: ID\n  $feedback: String!\n  $lessonId: ID\n  $location: String\n  $source: String\n) {\n  Feedback {\n    postFeedback(input: {courseId: $courseId, elementId: $elementId, feedback: $feedback, lessonId: $lessonId, location: $location, source: $source}) {\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '29b7157663b172e10c68779715970185';
export default node;
