/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LicensesInAccount_LicenseInAccountContainerFragment = {
    readonly id: string;
    readonly mainLicensePool: {
        readonly id: string;
        readonly rawId: string;
        readonly account: {
            readonly name: string;
        };
    } | null;
    readonly licenses: ReadonlyArray<{
        readonly id: string;
        readonly limitedToCoursesRef: ReadonlyArray<string> | null;
        readonly usageData: {
            readonly usedAt: string;
            readonly usedByUser: string;
            readonly usedForCourse: string;
        } | null;
    }>;
    readonly licenseRequests: ReadonlyArray<{
        readonly id: string;
        readonly courseRef: string;
        readonly createdAt: string;
        readonly licensePoolRef: string;
        readonly userRef: string;
    }>;
    readonly courses: ReadonlyArray<{
        readonly rawId: string;
        readonly id: string;
        readonly shortTitle: string;
    }>;
    readonly usersInAccount: ReadonlyArray<{
        readonly rawId: string;
        readonly id: string;
        readonly name: string;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"AssignLicenseButton_LicensesInAccountContainerFragment">;
    readonly " $refType": "LicensesInAccount_LicenseInAccountContainerFragment";
};
export type LicensesInAccount_LicenseInAccountContainerFragment$data = LicensesInAccount_LicenseInAccountContainerFragment;
export type LicensesInAccount_LicenseInAccountContainerFragment$key = {
    readonly " $data"?: LicensesInAccount_LicenseInAccountContainerFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LicensesInAccount_LicenseInAccountContainerFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LicensesInAccount_LicenseInAccountContainerFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LicensePool",
      "kind": "LinkedField",
      "name": "mainLicensePool",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Account",
          "kind": "LinkedField",
          "name": "account",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "License",
      "kind": "LinkedField",
      "name": "licenses",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "limitedToCoursesRef",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UsageData",
          "kind": "LinkedField",
          "name": "usageData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "usedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "usedByUser",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "usedForCourse",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LicenseRequest",
      "kind": "LinkedField",
      "name": "licenseRequests",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "courseRef",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "licensePoolRef",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userRef",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "courses",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shortTitle",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "usersInAccount",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssignLicenseButton_LicensesInAccountContainerFragment"
    }
  ],
  "type": "LicenseInAccountContainer",
  "abstractKey": null
};
})();
(node as any).hash = '4d3d454b278d0b18c219068f27af4316';
export default node;
