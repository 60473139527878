import React from "react";

interface OwnProps {
    title: string;
    subtitle: string;
}

export const AuthScreenHeading = ({
                                      title,
                                      subtitle
                                  }: OwnProps) => {

    return <div className="text-center mb-4">
        <div className="tk-heading-bold">
            {title}
        </div>
        <div className="heading">
            {subtitle}
        </div>
    </div>
}
