import React from "react";
import {LessonImage} from "../../../course/components/common/lesson-image/LessonImage";
import {ExpertList} from "../../../course/components/detail/info/expert-list/ExpertList";
import "./lesson-info.scss"
import {useTranslation} from "react-i18next";
import {CourseAd} from "../../../core/components/coursead/CourseAd";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {LessonInfo_LessonFragment$key} from "../../../../__generated__/LessonInfo_LessonFragment.graphql";
import {LessonAdditionalContentList} from "./additionalcontent/LessonAdditionalContentList";
import {LessonInfo_CourseStateFragment$key} from "../../../../__generated__/LessonInfo_CourseStateFragment.graphql";
import {LessonInfo_LessonStateFragment$key} from "../../../../__generated__/LessonInfo_LessonStateFragment.graphql";

const LESSON_INFO_COURSE_STATE_FRAGMENT = graphql`
    fragment LessonInfo_CourseStateFragment on CourseState {
        lessonStates {
            lessonRef
            ...LessonImage_LessonStateFragment
        }
        ...LessonAdditionalContentList_CourseStateFragment
        ...CourseAd_CourseStateFragment
    }`

const LESSON_INFO_LESSON_FRAGMENT = graphql`
    fragment LessonInfo_LessonFragment on Lesson {
        containerMeta {
            description
            title
        }
        course {
            experts {
                id
            }
            ...ExpertList_CourseFragment
            ...CourseAd_CourseFragment
        }
        elements {
            edges {
                node {
                    id
                    elementType
                }
            }
        }
        id
        image {
            ...LessonImage_ImageFragment
        }
        lessonPath {
            lessonIndex
            moduleIndex
        }
        lessonType
        ... on NormalLesson {
            additionalExperts {
                id
            }
            ...ExpertList_NormalLessonFragment
            ...LessonAdditionalContentList_NormalLessonFragment
        }
    }`

const LESSON_INFO_LESSON_STATE_FRAGMENT = graphql`
    fragment LessonInfo_LessonStateFragment on LessonState {
        lessonRef
        ...LessonImage_LessonStateFragment
    }`


interface OwnProps {
    courseStateFragmentRef?: LessonInfo_CourseStateFragment$key | null;
    lessonFragmentRef: LessonInfo_LessonFragment$key;
    lessonStateFragmentRef?: LessonInfo_LessonStateFragment$key | null;
}

export const LessonInfo = ({
                               courseStateFragmentRef,
                               lessonFragmentRef,
                               lessonStateFragmentRef,
                           }: OwnProps) => {
    const {t} = useTranslation("elementflow");

    const courseState = useFragment<LessonInfo_CourseStateFragment$key>(LESSON_INFO_COURSE_STATE_FRAGMENT, courseStateFragmentRef || null);

    const lesson = useFragment<LessonInfo_LessonFragment$key>(LESSON_INFO_LESSON_FRAGMENT, lessonFragmentRef);

    const lessonState = useFragment<LessonInfo_LessonStateFragment$key>(LESSON_INFO_LESSON_STATE_FRAGMENT, lessonStateFragmentRef || null);
    const course = lesson.course;

    return course && module && lesson ? <div className="lesson-info">
        <CourseAd courseFragmentRef={course} courseStateFragmentRef={courseState}/>

        <div className="tk-academy-text tk-academy-bold mb-2 tk-color-waterblue-80"
             id="info">Modul {lesson.lessonPath.moduleIndex + 1} |
            Lektion {lesson.lessonPath.moduleIndex + 1}.{lesson.lessonPath.lessonIndex + 1}</div>
        <div className="tk-academy-extra-large-heading-bold mb-5">{lesson.containerMeta.title}</div>

        <div className="d-flex flex-column mb-5">
            <LessonImage imageFragmentRef={lesson.image || undefined}
                         lessonStateFragmentRef={lessonState || undefined}/>
            <div className="p-5 tk-academy-card lesson-description">
                <div className="pb-3 tk-academy-text"
                     dangerouslySetInnerHTML={{__html: lesson.containerMeta.description}}>
                </div>
                <div className="tk-academy-text tk-academy-bold">Inhalt</div>
                <div className="tk-academy-text">In dieser Lektion erwarten dich:</div>
                <ol className="pl-3">
                    {lesson.elements?.edges?.filter(e => !!e).map(e => e!.node).map(el => {
                        return <li className="tk-academy-text pl-1"
                                   key={el.id}>{t("element-type." + el.elementType)}</li>
                    })}
                </ol>
            </div>
        </div>
        <ExpertList courseFragmentRef={course}
                    normalLessonFragmentRef={lesson.lessonType === 'normal' ? lesson : undefined}/>
        {lesson.lessonType === 'normal' && courseState &&
        <LessonAdditionalContentList courseStateFragmentRef={courseState} lessonFragmentRef={lesson}/>}
    </div> : null;
}
