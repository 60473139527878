import {StateStatus, useFinishedLogic} from "@thekeytechnology/thekey-academy-frontend-library";
import React from "react";

import BronzeMedal from "../../../../assets/images/bronze.png";
import SilverMedal from "../../../../assets/images/silber.png";
import GoldMedal from "../../../../assets/images/gold.png";

interface OwnProps {
    className?: string;
    stateStatus: StateStatus;
    showIsLocked?: boolean;
    possiblePoints?: number;
    pointsAwarded?: number
}

export const StatusIcon = ({
                               className,
                               stateStatus,
                               showIsLocked,
                               possiblePoints,
                               pointsAwarded
                           }: OwnProps) => {
    const [finishType] = useFinishedLogic(possiblePoints ? possiblePoints : 0, pointsAwarded ? pointsAwarded : 0);

    if (stateStatus === "current") {
        return <div className="status-icon ml-3 icon-a_tick_circle tk-color-keyblue tk-academy-larger-bold"/>
    }
    if (stateStatus === "finished") {
        if (possiblePoints !== undefined && finishType !== "no-points") {
            let medal;
            switch (finishType) {
                case "perfect":
                case "gold":
                    medal = GoldMedal
                    break;
                case "silver":
                    medal = SilverMedal
                    break;
                case "bronze":
                    medal = BronzeMedal;

            }
            return <img src={medal} style={{height: 18, width: 18}} alt={finishType}/>
        } else {
            return <div className="status-icon ml-3 icon-a_tick_circle tk-color-keygreen tk-academy-larger-bold"/>
        }
    }
    return showIsLocked ?
        <div className="status-icon ml-3 icon-a_lock_circle tk-color-waterblue-20 tk-academy-larger-bold"/> : null;
}
