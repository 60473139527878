/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OrderableDisplay_OrderableFragment = {
    readonly image: {
        readonly url: string | null;
    } | null;
    readonly text: string;
    readonly " $refType": "OrderableDisplay_OrderableFragment";
};
export type OrderableDisplay_OrderableFragment$data = OrderableDisplay_OrderableFragment;
export type OrderableDisplay_OrderableFragment$key = {
    readonly " $data"?: OrderableDisplay_OrderableFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"OrderableDisplay_OrderableFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderableDisplay_OrderableFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    }
  ],
  "type": "Orderable",
  "abstractKey": null
};
(node as any).hash = '1efed03b42d9c4304113cb6a4cf63d03';
export default node;
