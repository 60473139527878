/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LessonNextButton_CourseStateFragment = {
    readonly " $fragmentRefs": FragmentRefs<"AdvanceCourseButton_CourseStateFragment">;
    readonly " $refType": "LessonNextButton_CourseStateFragment";
};
export type LessonNextButton_CourseStateFragment$data = LessonNextButton_CourseStateFragment;
export type LessonNextButton_CourseStateFragment$key = {
    readonly " $data"?: LessonNextButton_CourseStateFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LessonNextButton_CourseStateFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonNextButton_CourseStateFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdvanceCourseButton_CourseStateFragment"
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};
(node as any).hash = 'c457d09f55d29e9ed6204af77ebbe673';
export default node;
