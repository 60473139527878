import React, {ReactNode} from "react";
import {RedirectToMobileAppScreen} from "../appscreen/RedirectToMobileAppScreen";
import {AchievementGotPopup} from "../../../achievements/components/AchievementPopup";
import {DialogPortal} from "../../../dialog/components/DialogPortal";
import {CelebrationPopup} from "../../../celebration/components/CelebrationPopup";
import {CustomerMessageModalScreen} from "../../../customer-message-v2/components/modal/CustomerMessageModalScreen";

export const LoggedInWrapper = ({
                                    children
                                }: { children: ReactNode }) => {
    return <>
        <div className="h-100 w-100 row m-0 p-0">
            {children}
        </div>
        <AchievementGotPopup/>
        <RedirectToMobileAppScreen/>
        <DialogPortal/>
        <CelebrationPopup/>
        <CustomerMessageModalScreen/>
    </>
}