/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PersologClassCode = "d" | "g" | "i" | "s" | "%future added value";
export type PersologClassCard_PersologClassFragment = {
    readonly classCode: PersologClassCode;
    readonly title: string;
    readonly image: {
        readonly url: string | null;
    } | null;
    readonly " $refType": "PersologClassCard_PersologClassFragment";
};
export type PersologClassCard_PersologClassFragment$data = PersologClassCard_PersologClassFragment;
export type PersologClassCard_PersologClassFragment$key = {
    readonly " $data"?: PersologClassCard_PersologClassFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"PersologClassCard_PersologClassFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersologClassCard_PersologClassFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "classCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PersologClass",
  "abstractKey": null
};
(node as any).hash = '90e62f2fb890ed52fdb794512a8c7f6e';
export default node;
