/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CustomerType = "business" | "private" | "%future added value";
export type BillingDetailsStepClosed_BillingDetailsFragment = {
    readonly customerType: CustomerType;
    readonly firstName: string;
    readonly lastName: string;
    readonly companyBillingDetails: {
        readonly company: string;
    } | null;
    readonly " $refType": "BillingDetailsStepClosed_BillingDetailsFragment";
};
export type BillingDetailsStepClosed_BillingDetailsFragment$data = BillingDetailsStepClosed_BillingDetailsFragment;
export type BillingDetailsStepClosed_BillingDetailsFragment$key = {
    readonly " $data"?: BillingDetailsStepClosed_BillingDetailsFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"BillingDetailsStepClosed_BillingDetailsFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingDetailsStepClosed_BillingDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CompanyBillingDetail",
      "kind": "LinkedField",
      "name": "companyBillingDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "company",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BillingDetails",
  "abstractKey": null
};
(node as any).hash = '2fdae77c9e32039f731f284dd9020a6a';
export default node;
