/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BusinessForm_UserFragment = {
    readonly id: string;
    readonly email: string;
    readonly meta: {
        readonly firstName: string;
        readonly lastName: string;
    } | null;
    readonly " $refType": "BusinessForm_UserFragment";
};
export type BusinessForm_UserFragment$data = BusinessForm_UserFragment;
export type BusinessForm_UserFragment$key = {
    readonly " $data"?: BusinessForm_UserFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"BusinessForm_UserFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BusinessForm_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserMeta",
      "kind": "LinkedField",
      "name": "meta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '8c91f92d7cada4cc0eea2179cfb1ab3a';
export default node;
