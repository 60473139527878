import React, {useRef} from "react";
import styled from "styled-components";
import {tkColorCodes} from "../../../core/style/tkColorCodes";

interface OwnProps {
    Fill?: React.ReactNode;
}

export const DroppableCloze = ({Fill}: OwnProps) => {
    const ref = useRef<HTMLDivElement>(null);

    return <DroppableClozeContainer className={"w-auto d-inline-block mr-2 h-25 border-1"} ref={ref}>
        {Fill ? Fill : <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
    </DroppableClozeContainer>
}

const DroppableClozeContainer = styled.span`
  border-radius: 5px;
  border: solid 1px ${tkColorCodes.waterBlue20};
`
