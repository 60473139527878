import {useFragment, useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import React, {useEffect, useRef} from "react";
import {CartItem} from "./CartItem";
import {CartTotalDisplay} from "./CartTotalDisplay";
import {DiscountCodeInput} from "./DiscountCodeInput";
import {CodeRefusalExplanation} from "./CodeRefusalExplanation";
import {CartStep_CartFragment$key} from "../../../../../__generated__/CartStep_CartFragment.graphql";
import {CartStep_UpdateCartMutation} from "../../../../../__generated__/CartStep_UpdateCartMutation.graphql";
import {BulkDiscount} from "./BulkDiscount";
import styled from "styled-components";
import {UpsellsDisplay} from "./UpsellsDisplay";
import {LoadingOverlay} from "./LoadingOverlay";
import * as Sentry from "@sentry/react";
import {trackAddToCart} from "../../../../analytics/analytics";

const CART_FRAGMENT = graphql`
    fragment CartStep_CartFragment on Cart {
        cartSelection {
            selectedDiscountCodes
            selectedProducts {
                amount                
                productId
            }
        }
        cartTotals {
            totalsIncludingAllDiscounts {
                netPrice
            }
            ...CartTotalDisplay_CartTotalsFragment
        }
        cartItems {
            ...CartItem_CartItemFragment
            ... on DiscountCodeCartItem {
                code
            }
        }
        ...UpsellsDisplay_CartFragment
        ...CodeRefusalExplanation_RefusalsFragment
        ...BulkDiscount_Cart
    }
`;

const UPDATE_CART_MUTATION = graphql`
    mutation CartStep_UpdateCartMutation($updateCartInput: UpdateCartInput!) {
        Billing {
            updateCart(input: $updateCartInput) {
                order {
                    id
                    cart {
                        cartSelection {
                            selectedProducts {
                                productId                   
                                
                                amount
                            }
                        }
                        cartTotals {
                            totalsIncludingAllDiscounts {
                                netPrice
                            }
                        }
                        cartItems {
                            ... on ProductCartItem {
                                product {
                                    id
                                    title
                                }
                            }
                        }
                    }
                    ...OrderFlowScreen_OrderFragment
                }
            }
        }
    }
`;

interface OwnProps {
    orderId: string
    cartFragmentRef: CartStep_CartFragment$key | null
}

export const CartStep = ({orderId, cartFragmentRef}: OwnProps) => {
    const cart = useFragment<CartStep_CartFragment$key>(CART_FRAGMENT, cartFragmentRef)

    const [changeCart, isInFlight] = useMutation<CartStep_UpdateCartMutation>(UPDATE_CART_MUTATION)

    const updateProductCount = (productId: string, newAmount: number) => {
        const updated = cart?.cartSelection.selectedProducts.find(p => p.productId === productId) ?
            cart.cartSelection.selectedProducts?.flatMap(sp => {
                if (sp.productId === productId) {
                    if (newAmount === 0) {
                        return []
                    } else {
                        return [{
                            ...sp,
                            amount: newAmount
                        }]
                    }
                } else {
                    return [sp]
                }
            }) : [...(cart?.cartSelection.selectedProducts || []), {productId, amount: newAmount}]

        changeCart({
            variables: {
                updateCartInput: {
                    orderId: orderId,
                    cartSelection: {
                        selectedProducts: updated,
                        selectedDiscountCodes: [...(cart?.cartSelection.selectedDiscountCodes || [])]
                    }
                }
            }, onCompleted: (response) => {
                try {
                    const amountBefore = cart?.cartSelection.selectedProducts.find(p => p.productId === productId)?.amount || 0
                    if (amountBefore < newAmount) {
                        const cartAfterUpdateMutation = response.Billing.updateCart?.order.cart
                        const cartTotalBefore = cart?.cartTotals?.totalsIncludingAllDiscounts?.netPrice || 0
                        const cartTotalAfter = cartAfterUpdateMutation?.cartTotals.totalsIncludingAllDiscounts?.netPrice
                        const productName = cartAfterUpdateMutation?.cartItems.find(ci => ci.product?.id === productId)?.product?.title
                        const selectedProducts = cartAfterUpdateMutation?.cartSelection.selectedProducts.map(product => ({
                            base64EncodedProductId: product.productId,
                            quantity: product.amount
                        }))
                        if (productName && cartTotalAfter && selectedProducts) {
                            trackAddToCart(
                                {
                                    base64EncodedProductId: productId,
                                    quantity: newAmount - amountBefore,
                                    productName: productName
                                },
                                selectedProducts,
                                cartTotalAfter - cartTotalBefore,
                                cartAfterUpdateMutation?.cartTotals.totalsIncludingAllDiscounts?.netPrice || 0
                            )
                        }
                    }
                } catch (e) {
                    Sentry.captureException(e)
                }
            }
        })
    }

    const ref = useRef<any>(null)

    useEffect(() => {
        ref.current?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }, [])

    const discountsInCart = cart?.cartItems.map(i => i.code).filter(c => c !== undefined) as string[]
    return <Container ref={ref}>
        {isInFlight && <LoadingOverlay/>}

        <Separator/>

        <BulkDiscount cartFragmentRef={cart}/>

        <Separator/>

        <CodeRefusalExplanation codeRefusalsFragmentRef={cart}/>

        <CartTable>
            {cart?.cartItems.map((cartItem, index) => {
                return <CartItem
                    removeDiscount={(code: string) => {
                        changeCart({
                            variables: {
                                updateCartInput: {
                                    orderId: orderId,
                                    cartSelection: {
                                        selectedProducts: [...cart.cartSelection.selectedProducts],
                                        selectedDiscountCodes: cart.cartSelection.selectedDiscountCodes?.filter(c => c !== code),
                                    }
                                }
                            }
                        })
                    }}
                    changeProductAmount={(productId, amount) => {
                        updateProductCount(productId, amount)
                    }}
                    cartItemFragmentRef={cartItem} key={index}/>
            })}
        </CartTable>

        <CartTotalDisplay cartTotalsFragmentRef={cart?.cartTotals}/>

        <Separator/>

        <DiscountCodeInput onAddedDiscount={code => {
            changeCart({
                variables: {
                    updateCartInput: {
                        orderId: orderId,
                        cartSelection: {
                            selectedProducts: cart?.cartSelection.selectedProducts ? [...cart?.cartSelection.selectedProducts] : [],
                            selectedDiscountCodes: [...(cart?.cartSelection.selectedDiscountCodes?.filter(c => c !== code) || []), code],
                        }
                    }
                }
            })
        }} discountCodesInCart={discountsInCart}/>

        <UpsellsDisplay cartFragmentRef={cart} addToCart={productId => {
            updateProductCount(productId, 1)
        }}/>
    </Container>
}

export const Separator = styled.hr`
  margin-top: 25px;
  border-top: solid 1px #e7ebf1;
`

const CartTable = styled.div`
  width: 100%;
  margin-bottom: 20px;
`
const Container = styled.div`
  scroll-margin: 50px;
  position: relative;
  width: 100%;
`
