/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdditionalContentType = "file" | "text" | "video" | "%future added value";
export type AdditionalContentDisplay_AdditionalContentFragment = {
    readonly contentType: AdditionalContentType;
    readonly " $fragmentRefs": FragmentRefs<"FileAdditionalContentDisplay_FileAdditionalContentFragment" | "TextAdditionalContentDisplay_TextAdditionalContentFragment" | "VideoAdditionalContentDisplay_VideoAdditionalContentFragment">;
    readonly " $refType": "AdditionalContentDisplay_AdditionalContentFragment";
};
export type AdditionalContentDisplay_AdditionalContentFragment$data = AdditionalContentDisplay_AdditionalContentFragment;
export type AdditionalContentDisplay_AdditionalContentFragment$key = {
    readonly " $data"?: AdditionalContentDisplay_AdditionalContentFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"AdditionalContentDisplay_AdditionalContentFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdditionalContentDisplay_AdditionalContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentType",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FileAdditionalContentDisplay_FileAdditionalContentFragment"
        }
      ],
      "type": "FileAdditionalContent",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TextAdditionalContentDisplay_TextAdditionalContentFragment"
        }
      ],
      "type": "TextAdditionalContent",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "VideoAdditionalContentDisplay_VideoAdditionalContentFragment"
        }
      ],
      "type": "VideoAdditionalContent",
      "abstractKey": null
    }
  ],
  "type": "AdditionalContent",
  "abstractKey": "__isAdditionalContent"
};
(node as any).hash = '1e48b52e3092fab8d1df2a40d0cf1d2d';
export default node;
