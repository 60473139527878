/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CelebrationPopup_LicensePools = {
    readonly " $fragmentRefs": FragmentRefs<"AdvanceCourseButton_LicensePoolsFragment">;
    readonly " $refType": "CelebrationPopup_LicensePools";
};
export type CelebrationPopup_LicensePools$data = CelebrationPopup_LicensePools;
export type CelebrationPopup_LicensePools$key = {
    readonly " $data"?: CelebrationPopup_LicensePools$data;
    readonly " $fragmentRefs": FragmentRefs<"CelebrationPopup_LicensePools">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CelebrationPopup_LicensePools",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdvanceCourseButton_LicensePoolsFragment"
    }
  ],
  "type": "LicensePoolsConnection",
  "abstractKey": null
};
(node as any).hash = '5385e4d3f56ad2dcfdfb866bb96a7214';
export default node;
