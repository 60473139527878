import React from "react";

import "./customer-type-select.scss";
import styled from "styled-components";
import {CustomerType} from "../../../../../../__generated__/usePaymentLogicV3_OrderFragment.graphql";

interface OwnProps {
    customerType: CustomerType;
    updateCustomerType: (customerType: CustomerType) => void;
}

export const CustomerTypeSelectorV3 = ({
                                           customerType,
                                           updateCustomerType
                                       }: OwnProps) => {

    return <CustomerTypeWrapper className="customer-type-select-field">
        <label htmlFor="private"
               className={`radio-wrapper d-flex align-items-center tk-academy-text tk-academy-bold tk-black mr-5`}>
            <input type="radio"
                   id="private"
                   name="customerType"
                   value={"private"}
                   checked={customerType === "private"}
                   className="mr-2"
                   onChange={() => {
                       updateCustomerType("private")
                   }}
            />
            <span className="checkmark"/>
            <span className="tk-academy-text tk-academy-bold tk-black">Privatkund:in</span>
        </label>
        <label htmlFor="business"
               className={`radio-wrapper d-flex align-items-center`}>
            <input type="radio"
                   id="business"
                   name="customerType"
                   value={"business"}
                   checked={customerType === "business"}
                   className="mr-2"
                   onChange={() => {
                       updateCustomerType("business")
                   }}
            />
            <span className="checkmark"/>
            <span className="tk-academy-text tk-academy-bold tk-black">Geschäftskund:in</span>
        </label>
    </CustomerTypeWrapper>
}

const CustomerTypeWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`
