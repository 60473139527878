import IHKIcon from "../../../../../../assets/images/ihk.png";
import React, {ReactNode} from "react";
import {CourseInformation} from "@thekeytechnology/thekey-academy-frontend-library";

interface OwnProps {
    className?: string;
    course: CourseInformation
    beforeTitleSlot?: ReactNode
    afterTitleSlot?: ReactNode
}

export const CourseDisplay = ({course, className, afterTitleSlot, beforeTitleSlot}: OwnProps) => {

    return <div className={`course-display d-flex align-items-center ${className ? className : ""}`}>
        <div>
            <div
                className="tk-academy-large tk-academy-bold tk-black">
                {beforeTitleSlot} Interaktivlehrgang:<br/>
                {course.title} {afterTitleSlot}
            </div>
            {course.isIHK ?
                <div className="tk-academy-text tk-color-waterblue-80 mt-1">
                    <img alt="IHK"
                         className="ihk-icon mr-2"
                         src={IHKIcon}/> inkl. IHK-Zertifikat</div> : null}
        </div>
    </div>
}
