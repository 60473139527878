/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseList_CourseStatesConnectionFragment = {
    readonly edges: ReadonlyArray<{
        readonly node: {
            readonly course: {
                readonly id: string;
            };
            readonly isBought: boolean;
            readonly isDone: boolean;
            readonly " $fragmentRefs": FragmentRefs<"CourseCard_CourseStateFragment">;
        };
    } | null> | null;
    readonly " $refType": "CourseList_CourseStatesConnectionFragment";
};
export type CourseList_CourseStatesConnectionFragment$data = CourseList_CourseStatesConnectionFragment;
export type CourseList_CourseStatesConnectionFragment$key = {
    readonly " $data"?: CourseList_CourseStatesConnectionFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseList_CourseStatesConnectionFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseList_CourseStatesConnectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CourseStatesEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CourseState",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Course",
              "kind": "LinkedField",
              "name": "course",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isBought",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isDone",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CourseCard_CourseStateFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CourseStatesConnection",
  "abstractKey": null
};
(node as any).hash = '38a612823446c8b2e0decfd04e523a08';
export default node;
