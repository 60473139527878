import React from "react";
import {CollapsibleCard} from "../../../../../core/components/collapsible-card/CollapsibleCard";
import {AdditionalContentItem} from "../../../../../additional-content/components/AdditionalContentItem";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {CourseAdditionalContentModuleCard_AdditionalContentByModuleFragment$key} from "../../../../../../__generated__/CourseAdditionalContentModuleCard_AdditionalContentByModuleFragment.graphql";
import {CourseAdditionalContentModuleCard_CourseStateFragment$key} from "../../../../../../__generated__/CourseAdditionalContentModuleCard_CourseStateFragment.graphql";

const COURSE_ADDITIONAL_CONTENT_MODULE_CARD_ADDITIONAL_CONTENT_BY_MODULE_FRAGMENT = graphql`
    fragment CourseAdditionalContentModuleCard_AdditionalContentByModuleFragment on AdditionalContentByModule {
        additionalContent {
            additionalContent {
                id
            }
            lessonPath {
                moduleIndex
            }
            ...AdditionalContentItem_AdditionalContentWithLessonPathFragment
        }
        module {
            containerMeta {
                title
            }
            modulePath {
                moduleIndex
            }
        }
    }`

const COURSE_ADDITIONAL_CONTENT_MODULE_CARD_COURSE_STATE_FRAGMENT = graphql`
    fragment CourseAdditionalContentModuleCard_CourseStateFragment on CourseState {
        ...AdditionalContentItem_CourseStateFragment
    }`

interface OwnProps {
    additionalContentByModuleFragmentRef: CourseAdditionalContentModuleCard_AdditionalContentByModuleFragment$key;
    courseStateFragmentRef: CourseAdditionalContentModuleCard_CourseStateFragment$key;
}

export const CourseAdditionalContentModuleCard = ({
                                                      additionalContentByModuleFragmentRef,
                                                      courseStateFragmentRef
                                                  }: OwnProps) => {

    const additionalContentByModule = useFragment<CourseAdditionalContentModuleCard_AdditionalContentByModuleFragment$key>(COURSE_ADDITIONAL_CONTENT_MODULE_CARD_ADDITIONAL_CONTENT_BY_MODULE_FRAGMENT, additionalContentByModuleFragmentRef)

    const courseState = useFragment<CourseAdditionalContentModuleCard_CourseStateFragment$key>(COURSE_ADDITIONAL_CONTENT_MODULE_CARD_COURSE_STATE_FRAGMENT, courseStateFragmentRef)

    const module = additionalContentByModule.module;

    return additionalContentByModule.additionalContent.length > 0 ? <CollapsibleCard
        className="tk-bg-light"
        indexText={`M${module.modulePath.moduleIndex + 1}`}
        title={module.containerMeta.title}
        renderChildren={() => {
            return <div className="d-flex flex-row">
                <div className="col-1">
                    &nbsp;
                </div>
                <div className="col-10">
                    {additionalContentByModule.additionalContent.map(aC => <AdditionalContentItem
                        key={aC.additionalContent.id} additionalContentWithLessonPathFragmentRef={aC}
                        courseStateFragmentRef={courseState}/>)}
                </div>
                <div className="col-1 d-flex justify-content-end">
                    &nbsp;
                </div>
            </div>
        }}
    /> : null;
}
