import React from 'react'
import {BaseElementDisplay} from "../../../../elementFlow/components/common/BaseElementDisplay";
import {LightboxImage} from "../../../../core/components/image/LightboxImage";
import './failed-order-display.scss'
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {FailedOrderElement_FailedElementFragment$key} from "../../../../../__generated__/FailedOrderElement_FailedElementFragment.graphql";

const FAILED_ELEMENT_FRAGMENT = graphql`
    fragment FailedOrderElement_FailedElementFragment on FailedElement {
        element {
            title
            ...on OrderElement {
                orderables {
                    text
                    image {
                        url
                    }
                }
            }
        }
        mistakes {
            ... on OrderMistakes {
                explanations {
                    userIndex
                }
            }
        }
    }`

interface OwnProps {
    failedElementFragmentRef: FailedOrderElement_FailedElementFragment$key;
}

export const FailedOrderElement = ({failedElementFragmentRef}: OwnProps) => {
    const failedElement = useFragment<FailedOrderElement_FailedElementFragment$key>(FAILED_ELEMENT_FRAGMENT, failedElementFragmentRef)

    return <BaseElementDisplay
        className="order-element-display"
        heading={failedElement.element.title}
        elementSpecificSlot={
            failedElement.mistakes.explanations!.map((explanation, index) => {
                    const orderable = failedElement.element.orderables![explanation.userIndex]

                    const isCorrect = index === failedElement.element.orderables?.indexOf(orderable)
                    const correctIndex = failedElement.element.orderables?.indexOf(orderable)

                    return (
                        <div key={index} className={`failed-orderable`}>
                            <div className="index">{index + 1}</div>
                            <div className="text">
                                {orderable.image?.url ? <LightboxImage image={orderable.image?.url}/> : null}
                                <div>{orderable.text}</div>
                            </div>
                            <div
                                className={`correct-index ${isCorrect ? 'correct-answer' : 'false-answer'}`}>
                                {isCorrect ?
                                    <span className="icon-a_tick"/> : correctIndex! + 1}
                            </div>
                        </div>
                    )
                }
            )
        }/>
}