import {NavLink} from "react-router-dom";
import {AcademyButton} from "../button/AcademyButton";
import React from "react";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {CourseAd_CourseStateFragment$key} from "../../../../__generated__/CourseAd_CourseStateFragment.graphql";
import {CourseAd_CourseFragment$key} from "../../../../__generated__/CourseAd_CourseFragment.graphql";
import styled from "styled-components";
import {AcademyCard} from "../../../theme/AcademyCard";
import {NormalText} from "../../../theme/Typography";
import {generateCartLink} from "../../../billing/v3/utils/cart-link";

const COURSE_FRAGMENT = graphql`
    fragment CourseAd_CourseFragment on Course {
        mainProduct
        mainDiscountCode
    }`

const COURSE_STATE_FRAGMENT = graphql`
    fragment CourseAd_CourseStateFragment on CourseState {
        isBought
    }`


interface OwnProps {
    courseFragmentRef: CourseAd_CourseFragment$key;
    courseStateFragmentRef: CourseAd_CourseStateFragment$key | null;
}

export const CourseAd = ({courseFragmentRef, courseStateFragmentRef}: OwnProps) => {

    const course = useFragment<CourseAd_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef);
    const courseState = useFragment<CourseAd_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, courseStateFragmentRef);

    return !courseState?.isBought && course.mainProduct ?
        <CourseAdContainer>
            <Text>
                Profitiere von unserer aktuellen Aktion und spare bei jeder weiteren Lizenz zusätzlich.
            </Text>
            <NavLink className="ml-auto" to={generateCartLink(course.mainProduct, course.mainDiscountCode)}>
                <AcademyButton text={"Jetzt buchen"}/>
            </NavLink>
        </CourseAdContainer> : null;
}

const CourseAdContainer = styled(AcademyCard)`
  padding: 25px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.palette.darkBlue};
  background: linear-gradient(90deg, rgba(220, 248, 231, 1) 100%, rgba(167, 203, 232, 1) 0%);
`

const Text = styled.div`
  ${NormalText};
  margin-right: 20px;
`
