import React from "react"
import DatePicker from "react-datepicker";
import { FieldProps } from "formik";
import { FormProps } from "react-bootstrap";
import de from "date-fns/locale/de"; // the locale you want
import subYears from "date-fns/subYears"

interface OwnProps {
    placeholder?: string
    disabled?: boolean;
}

type Props = FieldProps & FormProps & OwnProps;

export const DateField = ({field, form, disabled, placeholder}: Props) => {
    return <DatePicker
        placeholderText={placeholder}
        disabled={disabled}
        selected={field.value}
        locale={de}
        dateFormat="dd.MM.yyyy"
        maxDate={subYears(new Date(), 17)}
        showYearDropdown={true}
        yearDropdownItemNumber={15}
        scrollableYearDropdown={true}
        onChange={(date) => form.setFieldValue(field.name, date)}/>
}
