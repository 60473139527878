import React from "react";
import {useTranslation} from "react-i18next";
import "./edit-qna-modal.scss";
import {Formik} from "formik";
import * as yup from "yup";
import {useDispatch} from "react-redux";
import {ValidatedField} from "../../core/components/form/ValidatedField";
import {AcademyButton} from "../../core/components/button/AcademyButton";
import {BasicModal} from "../../core/components/modal/BasicModal";
import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {EditQnaModal_PostQnaMutation} from "../../../__generated__/EditQnaModal_PostQnaMutation.graphql";
import {Message, postMessageAction} from "@thekeytechnology/framework-react";

const EDIT_QNA_MODAL_POST_QNA_MUTATION = graphql`
    mutation EditQnaModal_PostQnaMutation($courseId: ID!, $elementId: ID, $lessonId: ID, $qnasConnectionIds: [ID!]!, $question: String!) {
        Qnas {
            postQna(input: {courseId: $courseId, elementId: $elementId, lessonId: $lessonId, question: $question}) {
                qna @appendEdge(connections: $qnasConnectionIds){
                    node {
                        ...QnaItem_QnaFragment
                    }
                }
            }
        }

    }
`;

interface OwnProps {
    courseId: string;
    elementId?: string;
    lessonId?: string;
    onClose: () => void;
    qnasConnectionId: string;
}

export const EditQnaModal = ({
                                 courseId,
                                 elementId,
                                 lessonId,
                                 onClose,
                                 qnasConnectionId
                             }: OwnProps) => {

    const [postQna, postQnaIsInFlight] = useMutation<EditQnaModal_PostQnaMutation>(EDIT_QNA_MODAL_POST_QNA_MUTATION)

    const dispatch = useDispatch();

    const {t} = useTranslation(["qnas", "core"])

    return <BasicModal centered={true} onHide={() => onClose()} dialogClassName="edit-qna-modal" show={true}>
        <div className="edit-qna-modal-content p-4">
            <h1 className="tk-academy-larger-bold tk-color-darkblue">{t(`edit-qna-modal-content.heading`)}</h1>
            <p className="mb-3">{t(`edit-qna-modal-content.explanation`)}</p>

            <Formik
                initialValues={{
                    question: ""
                }}
                enableReinitialize={true}
                validationSchema={
                    yup.object().shape({
                        question: yup
                            .string()
                            .min(1)
                            .required(t("core:forms.required-field", {fieldName: t("edit-qna-form.question")})),
                    })
                }
                onSubmit={values => {
                    postQna({
                        variables: {
                            courseId: courseId,
                            elementId: elementId,
                            lessonId: lessonId,
                            qnasConnectionIds: [qnasConnectionId],
                            question: values.question
                        }, onCompleted: () => {
                            onClose();
                            dispatch(postMessageAction(Message.TYPE_SUCCESS, "messages.qna-posted"));
                        }
                    })
                }}
            >
                {formikState => (
                    <div>
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            placeholder={t("edit-qna-form.question-placeholder")}
                                            name="question"
                                            component="textarea"
                                            className="form-control default-input"
                                            required/>
                        </div>
                        <AcademyButton
                            className="ml-auto"
                            onClick={() => formikState.handleSubmit()}
                            disabled={!formikState.isValid || formikState.isSubmitting || postQnaIsInFlight}
                            text={t("edit-qna-form.submit")}
                            type={"submit"}/>
                    </div>
                )}
            </Formik>

        </div>
    </BasicModal>
}