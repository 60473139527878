import React from "react";
import {GamificationScreen} from "./components/GamificationScreen";
import {RouteAuthState, RouteDefinition} from "../core/components/routing/RouteDefinition";

export const GamificationRouteDefinitions: RouteDefinition[] = [
    {
        path: "/achievements",
        component: () => <GamificationScreen/>,
        authState: RouteAuthState.OnlyLoggedIn
    }
]