import React from "react";
import {StartCourseButton} from "./StartCourseButton";
import {TrialOverButton} from "./TrialOverButton";
import {AtEndOfCourseButton} from "./AtEndOfCourseButton";
import {NextLessonButton} from "./NextLessonButton";
import {OrderCertificateButton} from "./OrderCertificateButton";
import {graphql} from "babel-plugin-relay/macro";
import {
    AdvanceCourseButton_CourseFragment$key,
    CourseAttributes
} from "../../../__generated__/AdvanceCourseButton_CourseFragment.graphql";
import {
    AdvanceCourseButton_CourseStateFragment$key
} from "../../../__generated__/AdvanceCourseButton_CourseStateFragment.graphql";
import {useFragment} from "react-relay";
import {
    AdvanceCourseButton_LicensePoolsFragment$key
} from "../../../__generated__/AdvanceCourseButton_LicensePoolsFragment.graphql";
import {ApplyLicenseButton} from "./ApplyLicenseButton";
import {TrialOverButtonEarlyBird} from "./TrialOverButtonEarlyBird";
import {BoughtEarlyBirdLicenseButton} from "./BoughtEarlyBirdLicenseButton";

const COURSE_FRAGMENT = graphql`
    fragment AdvanceCourseButton_CourseFragment on Course {
        id
        attributes
        ...AtEndOfCourseButton_CourseFragment
        ...StartCourseButton_CourseFragment
        ...TrialOverButton_CourseFragment
        ...TrialOverButtonEarlyBird_CourseFragment
        ...ApplyLicenseButton_CourseFragment
        ...BoughtEarlyBirdLicenseButton_CourseFragment
    }`

const COURSE_STATE_FRAGMENT = graphql`
    fragment AdvanceCourseButton_CourseStateFragment on CourseState {
        isAtEndOfTrial
        isBought
        isCanOrderCertificate
        isCertificateOrdered
        isDone
        ...NextLessonButton_CourseStateFragment
    }`

const LICENSE_POOLS_FRAGMENT = graphql`
    fragment AdvanceCourseButton_LicensePoolsFragment on LicensePoolsConnection {
        edges {
            node {
                id
                isMainPool
                applicationRuleStatus(courseId: $courseId)
                ...ApplyLicenseButton_LicensePoolFragment
            }
        }
    }`

interface OwnProps {
    className?: string
    courseFragmentRef: AdvanceCourseButton_CourseFragment$key
    courseStateFragmentRef: AdvanceCourseButton_CourseStateFragment$key | null
    licensePoolsFragmentRef: AdvanceCourseButton_LicensePoolsFragment$key
    disabled?: boolean
    onSwitchToLesson?: () => void
    refetchCourseDetailScreenQuery?: () => void
}

export const AdvanceCourseButton = ({
                                        className,
                                        courseFragmentRef,
                                        courseStateFragmentRef,
                                        disabled,
                                        licensePoolsFragmentRef,
                                        onSwitchToLesson,
                                        refetchCourseDetailScreenQuery
                                    }: OwnProps) => {

    const course = useFragment<AdvanceCourseButton_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef)
    const courseState = useFragment<AdvanceCourseButton_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, courseStateFragmentRef)
    const licensePools = useFragment<AdvanceCourseButton_LicensePoolsFragment$key>(LICENSE_POOLS_FRAGMENT, licensePoolsFragmentRef)

    const isEarlyBird = !!course.attributes.find(a => a === "earlyBird" as CourseAttributes)
    const usableLicenseInPool = licensePools.edges?.filter(e => e).map(e => e!.node).filter(lp => lp.isMainPool).find(lp => lp.applicationRuleStatus === "canFreelyUse")

    let Button

    if (courseState) {
        if (courseState.isCanOrderCertificate && !courseState.isCertificateOrdered) {
            Button = <OrderCertificateButton courseId={course.id}/>
        } else if (courseState.isAtEndOfTrial && !courseState.isBought) {
            if (isEarlyBird) {
                if (usableLicenseInPool) {
                    Button = <BoughtEarlyBirdLicenseButton courseFragmentRef={course}/>
                } else {
                    Button = <TrialOverButtonEarlyBird courseFragmentRef={course}/>
                }
            } else {
                if (usableLicenseInPool) {
                    Button =
                        <ApplyLicenseButton courseFragmentRef={course} licensePoolFragmentRef={usableLicenseInPool}/>
                } else {
                    Button = <TrialOverButton courseFragmentRef={course}/>
                }
            }
        } else if (courseState.isDone) {
            Button = <AtEndOfCourseButton courseFragmentRef={course}/>
        } else {
            Button = <NextLessonButton courseStateFragmentRef={courseState} disabled={disabled}
                                       onSwitchToLesson={onSwitchToLesson}/>
        }
    } else {
        Button = <StartCourseButton courseFragmentRef={course}/>
    }

    return <div className={className}>{Button}</div>
}

