import {useDispatch, useSelector} from "react-redux";
import {
    API_LOGIN,
    loginAttemptAction,
    selectCombinedApiState,
    selectCurrentUser
} from "@thekeytechnology/framework-react";
import React, {useState} from "react";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {ValidatedField} from "../../../../../core/components/form/ValidatedField";


interface OwnProps {
    className?: string;
}


export const CartLoginV2 = ({className}: OwnProps) => {
    const {t} = useTranslation(["auth", "core"]);

    const dispatch = useDispatch();
    const apiCallState = useSelector(selectCombinedApiState(API_LOGIN))
    const user = useSelector(selectCurrentUser);

    const [isExpanded, setExpanded] = useState(false);

    return user ? null : <div className={className}>
        <button className="btn-link tk-academy-large tk-academy-bold tk-color-keyblue"
                onClick={() => setExpanded(!isExpanded)}>Ich habe bereits ein Konto <span
            className="icon-a_forward"/></button>

        {isExpanded ? <>
            <hr className="mt-3 mb-3"/>

            <Formik
                initialValues={{email: "", password: ""}}
                validationSchema={Yup.object().shape({
                    email: Yup
                        .string()
                        .email(t("registration-form.email-error"))
                        .required(t("core:forms.required-field", {fieldName: t("registration-form.email")})),
                    password: Yup
                        .string()
                        .min(8, t("registration-form.password-error"))
                        .required(t("core:forms.required-field", {fieldName: t("registration-form.password")})),
                })}
                onSubmit={(values, {setSubmitting}) => {
                    dispatch(loginAttemptAction(values.email, values.password));
                    setSubmitting(false);
                }}
            >
                {formikState => (

                    <Form>
                        <div className="tk-form-row">
                            <div className="form-group">
                                <ValidatedField formikState={formikState}
                                                iconClass={"icon-d_mail"}
                                                type="email"
                                                placeholder={t("login-form.e-mail-placeholder")}
                                                name="email"
                                                className="form-control default-input"
                                                required/>
                            </div>
                        </div>
                        <div className="tk-form-row">
                            <div className="form-group">
                                <ValidatedField formikState={formikState}
                                                type="password"
                                                iconClass={"icon-a_lock"}
                                                placeholder={t("login-form.password-placeholder")}
                                                name="password"
                                                className="form-control default-input"
                                                required/>
                            </div>
                            <div className="form-group d-flex">
                                <button type="submit" disabled={formikState.isSubmitting || apiCallState.inProgress}
                                        className="btn btn-secondary align-self-end h-100">
                                    {t("login-form.login")}
                                </button>
                            </div>
                        </div>

                    </Form>

                )}
            </Formik>
        </> : null}
    </div>;
}
