/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ElementType = "clozeText" | "image" | "multipleChoice" | "order" | "persolog" | "questionnaire" | "resolution" | "text" | "video" | "%future added value";
export type NoteModal_NoteFragment = {
    readonly course: {
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"NoteLocationHeading_CourseFragment">;
    };
    readonly lesson: {
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"NoteLocationHeading_LessonFragment">;
    } | null;
    readonly element: {
        readonly id: string;
        readonly elementType: ElementType;
        readonly " $fragmentRefs": FragmentRefs<"ElementDisplay_ElementFragment">;
    } | null;
    readonly content: string;
    readonly id: string;
    readonly title: string;
    readonly videoTimestamp: number | null;
    readonly " $refType": "NoteModal_NoteFragment";
};
export type NoteModal_NoteFragment$data = NoteModal_NoteFragment;
export type NoteModal_NoteFragment$key = {
    readonly " $data"?: NoteModal_NoteFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"NoteModal_NoteFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NoteModal_NoteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "course",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NoteLocationHeading_CourseFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "lesson",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NoteLocationHeading_LessonFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "element",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "elementType",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ElementDisplay_ElementFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "videoTimestamp",
      "storageKey": null
    }
  ],
  "type": "Note",
  "abstractKey": null
};
})();
(node as any).hash = 'f4eaf99380d67ea14d1558122c655243';
export default node;
