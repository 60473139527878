import {Action} from "redux";
import {ApiCallSucceededAction, EntityWrapper, isApiSuccess, LogoutAction} from "@thekeytechnology/framework-react";
import {AccountMeta} from "../model/AccountMeta";
import {API_FETCH_ACCOUNT_META} from "../action/fetch-account-meta";
import {UsersInAccountContainer} from "../model/UsersInAccountContainer";
import {API_FETCH_USERS_IN_ACCOUNT} from "../action/fetch-users-in-accounts";
import {Invitation} from "../model/Invitation";
import {API_FETCH_INVITATIONS_IN_ACCOUNT} from "../action/fetch-invitations-in-account";
import {LicensesInAccountContainer} from "../model/LicensesInAccountContainer";
import {API_FETCH_LICENSES_IN_ACCOUNT} from "../action/fetch-licenses-in-accounts";

export interface ManagerModuleState {
    accountMeta?: EntityWrapper<AccountMeta>
    usersInAccountContainer?: UsersInAccountContainer
    invitations?: EntityWrapper<Invitation>[]
    licenses?: LicensesInAccountContainer
}

export const initialState: ManagerModuleState = {
    accountMeta: undefined,
    usersInAccountContainer: undefined
};

export function managerModuleReducer(state = initialState, action: Action): ManagerModuleState {
    if (action.type === LogoutAction) {
        return initialState;
    }

    if (isApiSuccess(API_FETCH_ACCOUNT_META)(action)) {
        const courseOverviewAction = action as ApiCallSucceededAction<EntityWrapper<AccountMeta>>;

        return {
            ...state,
            accountMeta: courseOverviewAction.payload,
        };
    }

    if (isApiSuccess(API_FETCH_USERS_IN_ACCOUNT)(action)) {
        const fetchAction = action as ApiCallSucceededAction<UsersInAccountContainer>;

        return {
            ...state,
            usersInAccountContainer: fetchAction.payload,
        };
    }

    if (isApiSuccess(API_FETCH_INVITATIONS_IN_ACCOUNT)(action)) {
        const fetchAction = action as ApiCallSucceededAction<EntityWrapper<Invitation>[]>;

        return {
            ...state,
            invitations: fetchAction.payload,
        };
    }

    if (isApiSuccess(API_FETCH_LICENSES_IN_ACCOUNT)(action)) {
        const fetchAction = action as ApiCallSucceededAction<LicensesInAccountContainer>;

        return {
            ...state,
            licenses: fetchAction.payload,
        };
    }

    return state;
}
