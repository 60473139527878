import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {BulkDiscount_Cart$key} from "../../../../../__generated__/BulkDiscount_Cart.graphql";
import React from "react";
import styled from "styled-components";
import {DiscountBox} from "./DiscountBox";

interface OwnProps {
    cartFragmentRef: BulkDiscount_Cart$key | undefined | null
}

export const BulkDiscount = ({cartFragmentRef}: OwnProps) => {

    const cart = useFragment<BulkDiscount_Cart$key>(graphql`
        fragment BulkDiscount_Cart on Cart {
            cartItems {
                itemType

                ... on ProductCartItem {
                    amount
                }
            }
        }
    `, cartFragmentRef || null)

    const eligibleProducts = cart ? cart?.cartItems.map(x => x.amount || 0).reduce((x, y) => x + y, 0) : 0;

    return <Container>
        <Heading>Mengenrabatt</Heading>

        <DiscountBoxWrapper>
            <DiscountBox eligibleProducts={eligibleProducts} percentage={0} licenseCount={1}/>
            <DiscountBox eligibleProducts={eligibleProducts} percentage={10} licenseCount={2}/>
            <DiscountBox eligibleProducts={eligibleProducts} percentage={12.5} licenseCount={3}/>
            <DiscountBox eligibleProducts={eligibleProducts} percentage={15} licenseCount={4}/>
            <DiscountBox eligibleProducts={eligibleProducts} percentage={17.5} licenseCount={5}/>
        </DiscountBoxWrapper>
    </Container>
}

const Container = styled.div`
  margin-top: 5px;
`

const Heading = styled.h3`
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  color: #184276;

  @media (max-width: 1024px) {
    margin-bottom: 20px;
  }
`

const DiscountBoxWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

