/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UsersInAccount_DownloadCourseStateInfoInAccountMutationVariables = {};
export type UsersInAccount_DownloadCourseStateInfoInAccountMutationResponse = {
    readonly Viewer: {
        readonly Manager: {
            readonly downloadCourseStateInfoInAccount: {
                readonly csv: string;
            } | null;
        };
    };
};
export type UsersInAccount_DownloadCourseStateInfoInAccountMutation = {
    readonly response: UsersInAccount_DownloadCourseStateInfoInAccountMutationResponse;
    readonly variables: UsersInAccount_DownloadCourseStateInfoInAccountMutationVariables;
};



/*
mutation UsersInAccount_DownloadCourseStateInfoInAccountMutation {
  Viewer {
    Manager {
      downloadCourseStateInfoInAccount(input: {}) {
        csv
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ViewerMutationType",
    "kind": "LinkedField",
    "name": "Viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ManagerViewerMutationSchema",
        "kind": "LinkedField",
        "name": "Manager",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "input",
                "value": {}
              }
            ],
            "concreteType": "DownloadCourseStateInfoInAccountPayload",
            "kind": "LinkedField",
            "name": "downloadCourseStateInfoInAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "csv",
                "storageKey": null
              }
            ],
            "storageKey": "downloadCourseStateInfoInAccount(input:{})"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UsersInAccount_DownloadCourseStateInfoInAccountMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UsersInAccount_DownloadCourseStateInfoInAccountMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "9409cc28ab3d2435ded8013de5b3b341",
    "id": null,
    "metadata": {},
    "name": "UsersInAccount_DownloadCourseStateInfoInAccountMutation",
    "operationKind": "mutation",
    "text": "mutation UsersInAccount_DownloadCourseStateInfoInAccountMutation {\n  Viewer {\n    Manager {\n      downloadCourseStateInfoInAccount(input: {}) {\n        csv\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '03579f9bd50927f29a8096e09ed01820';
export default node;
