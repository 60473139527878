/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ReviewSidebar_CourseFragment = {
    readonly id: string;
    readonly " $refType": "ReviewSidebar_CourseFragment";
};
export type ReviewSidebar_CourseFragment$data = ReviewSidebar_CourseFragment;
export type ReviewSidebar_CourseFragment$key = {
    readonly " $data"?: ReviewSidebar_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ReviewSidebar_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewSidebar_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = 'd9feb12dc57988bb513fd9495bfa96fc';
export default node;
