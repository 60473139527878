import React from "react";
import "./academy-button.scss";

type ButtonStyle = "primary" | "secondary" | "outline" | "danger" | "reset";

interface OwnProps {
    text: string;
    type?: "button" | "submit" | "reset";
    className?: string;
    disabled?: boolean;
    onClick?: () => void;
    buttonStyle?: ButtonStyle;
    hideIcon?: boolean;
    icon?: string;
}

export const AcademyButton = ({
                                  text,
                                  type,
                                  className,
                                  disabled,
                                  onClick,
                                  buttonStyle,
                                  hideIcon,
                                  icon
                              }: OwnProps) => {

    let buttonClass;

    switch (buttonStyle) {
        case "secondary":
            buttonClass = "btn-secondary";
            break;
        case "outline":
            buttonClass = "btn-outline-secondary"
            break;
        case "danger":
            buttonClass = "btn-danger"
            break;
        case "reset":
            buttonClass = "btn-reset";
            break;
        case "primary":
        default:
            buttonClass = "btn-primary"
    }

    return <button
        type={type ? type : "button"}
        disabled={disabled}
        onClick={onClick ? () => onClick() : undefined}
        className={(className ? className : "") + " btn pl-5 pr-5 position-relative d-flex justify-content-center align-items-center academy-button " + buttonClass}>
        {!hideIcon &&
        <span className={`position-absolute right-20 ${icon ? icon : "icon-a_forward"}  tk-academy-large-bold `}/>}
        {text}
    </button>;
}
