/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExpertList_CourseFragment = {
    readonly experts: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"ExpertItem_ExpertFragment">;
    }>;
    readonly " $refType": "ExpertList_CourseFragment";
};
export type ExpertList_CourseFragment$data = ExpertList_CourseFragment;
export type ExpertList_CourseFragment$key = {
    readonly " $data"?: ExpertList_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ExpertList_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExpertList_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Expert",
      "kind": "LinkedField",
      "name": "experts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExpertItem_ExpertFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = '35d602e394265f848de86ec57cd0d1f4';
export default node;
