import React from 'react';
import {useFragment} from "react-relay";
import {CourseStatusBadge} from "./CourseStatusBadge";
import {graphql} from "babel-plugin-relay/macro";
import {CourseProgressBar} from "./CourseProgressBar";
import {CourseIcon} from "./course-icon/CourseIcon";
import styled from "styled-components";
import IHKLogo from "../../../../assets/images/ihk.png"
import {academyTypographyForStyledComps} from "../../../theme/academy-typography-for-styled-comps";
import {academyTextSizesForStyledComps} from "../../../theme/academy-text-sizes-for-styled-comps";
import {academyFontsForStyledComps} from "../../../theme/academy-fonts-for-styled-comps";
import {academyColorsForStyledComps} from "../../../theme/academy-colors-for-styled-comps";
import {UpperCourseCard_CourseStateFragment$key} from "../../../../__generated__/UpperCourseCard_CourseStateFragment.graphql";
import {UpperCourseCard_CourseFragment$key} from "../../../../__generated__/UpperCourseCard_CourseFragment.graphql";

const COURSE_FRAGMENT = graphql`
    fragment UpperCourseCard_CourseFragment on Course {
        containerMeta {
            title
        }
        isIHK
        icon {
            url
        }
        shortTitle
    }`

const COURSE_STATE_FRAGMENT = graphql`
    fragment UpperCourseCard_CourseStateFragment on CourseState {
        ...CourseProgressBar_CourseStateFragment
    }
`

interface OwnProps {
    courseFragmentRef: UpperCourseCard_CourseFragment$key;
    courseStateFragmentRef?: UpperCourseCard_CourseStateFragment$key | null;
    courseStatus: string;
    showProgressBarOrTestItForFreeContainer: boolean;
    showBorderBottom: boolean;
}

export const UpperCourseCard = ({
                                    courseFragmentRef,
                                    courseStateFragmentRef,
                                    courseStatus,
                                    showProgressBarOrTestItForFreeContainer,
                                    showBorderBottom
                                }: OwnProps) => {

    const course = useFragment<UpperCourseCard_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef)
    const courseState = useFragment<UpperCourseCard_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, courseStateFragmentRef || null)

    return <Container>
        <LeftOfIconContainer showBorderBottom={showBorderBottom}>
            <div>
                <IHKAndBatchContainer>
                    {course.isIHK ? <IHKImage src={IHKLogo}/> : <div/>}
                    <div>
                        <CourseStatusBadge status={courseStatus}/>
                    </div>
                </IHKAndBatchContainer>
                <ExpertFor>Fachkraft für</ExpertFor>
                <Title>{course.shortTitle}{course.isIHK && ' (IHK)'}</Title>
            </div>
            {showProgressBarOrTestItForFreeContainer && <div>
                {courseState ? <CourseProgressBar courseStateFragmentRef={courseState}/> :
                    <TestItForFree>Kostenlos testen &rarr;</TestItForFree>}
            </div>}
        </LeftOfIconContainer>
        {course.icon ? <CourseIcon isTest={false}
                                   courseTitle={course.containerMeta.title}
                                   iconUrl={course.icon.url || undefined}/> : <CourseIconPlaceholder/>}
    </Container>
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
`

const LeftOfIconContainer = styled.div<{ showBorderBottom: boolean }>`
     display: flex;
     flex-grow: 1;
     flex-shrink: 1;
     margin-right: 10px;
     flex-direction: column;
     justify-content: space-between;
     ${props => props.showBorderBottom && 'border-bottom-style: solid; border-bottom-width: 1px; border-bottom-color: #e4e8ef;'}
`

const IHKAndBatchContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const IHKImage = styled.img`
    height: 16px;
    width: 32px;
    margin-bottom: 5px;
    margin-top: 2px;
`

const ExpertFor = styled.div`
    ${academyTypographyForStyledComps.smallText}
`

const Title = styled.div`
    ${academyFontsForStyledComps.bold}
    ${academyColorsForStyledComps.dark100}
    font-size: 13.9px;
    line-height: 1.5;
`

const TestItForFree = styled.span`
    ${academyTextSizesForStyledComps.small}
    ${academyFontsForStyledComps.bold}
    ${academyColorsForStyledComps.lightBlue100}
`

const CourseIconPlaceholder = styled.div`
    height: 110px;
`