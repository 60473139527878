import React from "react";

interface OwnProps {
    className?: string;
    questionnaireElementClass?: {
        readonly description: string;
        readonly imageUrl?: string;
        readonly pointsThreshold: number;
        readonly title: string;
    };
}

export const ClassCard = ({className, questionnaireElementClass}: OwnProps) => {
    if (!questionnaireElementClass) {
        return <div>KEINE KLASSE GEFUNDEN - DAS IST EIN FEHLER</div>
    }

    return <div className={`p-4 tk-academy-card group d-flex flex-row ${className ? className : ""}`}>
        {questionnaireElementClass.imageUrl ? <img className="mr-3" alt={questionnaireElementClass.title}
                                                   src={questionnaireElementClass.imageUrl}/> : null}
        <div className="flex-grow-1">
            <div
                className="tk-academy-text tk-academy-bold tk-color-waterblue-80">ab {questionnaireElementClass.pointsThreshold} Punkten
            </div>
            <h2 className="tk-academy-large-bold mb-3 tk-color-darkblue">{questionnaireElementClass.title}</h2>
            <div className="tk-academy-text" dangerouslySetInnerHTML={{__html: questionnaireElementClass.description}}/>
        </div>
    </div>
}
