import React, {useState} from 'react'
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {FailedElementsDisplay_SubmissionResultFragment$key} from "../../../../__generated__/FailedElementsDisplay_SubmissionResultFragment.graphql";
import {FailedElementCard} from "./FailedElementCard";
import {FailedElementFooter} from "./FailedElementFooter";
import {FailedElementDisplay} from "./FailedElementDisplay";
import {BasicModal} from "../../../core/components/modal/BasicModal";
import {useTranslation} from "react-i18next";

const SUBMISSION_RESULT_FRAGMENT = graphql`
    fragment FailedElementsDisplay_SubmissionResultFragment on SubmissionResult {
        failedElements {
            ...FailedElementCard_FailedElementFragment
            ...FailedElementDisplay_FailedElementFragment
        }
    }`

interface OwnProps {
    submissionResultFragmentRef: FailedElementsDisplay_SubmissionResultFragment$key;
}

interface State {
    showElementModal: boolean,
    currentPage: number
}

export const FailedElementsDisplay = ({submissionResultFragmentRef}: OwnProps) => {
    const {t} = useTranslation("submission")

    const submissionResult = useFragment<FailedElementsDisplay_SubmissionResultFragment$key>(SUBMISSION_RESULT_FRAGMENT, submissionResultFragmentRef)

    const [state, setState] = useState<State>({
        showElementModal: false,
        currentPage: 0
    })

    const moveOnePageBack = () => setState({
        ...state,
        currentPage: state.currentPage -= 1
    })

    const moveOnePageForward = () => setState({
        ...state,
        currentPage: state.currentPage += 1
    })

    return (
        <div className={"d-flex flex-column h-100 w-100 p-5"}>
            <div className="tk-academy-larger-bold mb-5 tk-black">{t("failed-elements-display.heading")}</div>
            {
                submissionResult.failedElements.map(
                    (element, index) =>
                        <FailedElementCard
                            key={index}
                            failedElementFragmentRef={element}
                            displayElement={() => setState({
                                ...state,
                                showElementModal: true,
                                currentPage: index
                            })}/>
                )}
            {state?.showElementModal ?
                <BasicModal size="xl" onHide={() => setState({...state, showElementModal: false})} show={true}>
                    <>
                        <FailedElementDisplay
                            failedElementFragmentRef={submissionResult.failedElements[state.currentPage]}/>
                        <FailedElementFooter
                            numberOfPages={submissionResult.failedElements.length}
                            currentPage={state.currentPage}
                            goNext={moveOnePageForward}
                            goBack={moveOnePageBack}
                            close={() => setState({...state, showElementModal: false})}/>
                    </>
                </BasicModal> : null}
        </div>
    )
}
