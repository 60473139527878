/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LessonDetailSidebar_CourseStateFragment = {
    readonly " $fragmentRefs": FragmentRefs<"CourseSidebar_CourseStateFragment">;
    readonly " $refType": "LessonDetailSidebar_CourseStateFragment";
};
export type LessonDetailSidebar_CourseStateFragment$data = LessonDetailSidebar_CourseStateFragment;
export type LessonDetailSidebar_CourseStateFragment$key = {
    readonly " $data"?: LessonDetailSidebar_CourseStateFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LessonDetailSidebar_CourseStateFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonDetailSidebar_CourseStateFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseSidebar_CourseStateFragment"
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};
(node as any).hash = 'd81cbac392a568c909324dd18c11b886';
export default node;
