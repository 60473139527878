import {changeAccountAction, selectAccounts, selectCurrentAccount} from "@thekeytechnology/framework-react";
import React from "react";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {ValueType} from "react-select/src/types";
import {AccountInfo} from "@thekeytechnology/framework-react/dist/auth/model/account-info";

export const AccountSwitcher = () => {
    const currentAccount = useSelector(selectCurrentAccount)
    const availableAccounts = useSelector(selectAccounts)
    const dispatch = useDispatch();

    if (!currentAccount || !availableAccounts || (availableAccounts && availableAccounts.length <= 1)) {
        return null;
    }

    return <label className="pl-4 pr-4 tk-key-blue-30 mb-3">
        <div>Konto wechseln</div>

        <Select
            className="react-select"
            classNamePrefix="react-select"
            options={availableAccounts}
            // @ts-ignore
            value={availableAccounts.find(acc => acc.accountId === currentAccount.accountId)}
            getOptionValue={(opt: AccountInfo) => opt.accountId}
            getOptionLabel={(item: AccountInfo) => item.name}
            onChange={(a: ValueType<AccountInfo>) => dispatch(changeAccountAction(a as AccountInfo))}
        />
    </label>;

};

