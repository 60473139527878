import React, {useState} from "react";
import {AcademyButton} from "../../../../../../core/components/button/AcademyButton";

interface OwnProps {
    applyVoucher: (code: string) => void;
    appliedVouchers: string[]
}

interface State {
    wasSubmitted: boolean
    code: string | undefined
    isVisible: boolean
}

export const VoucherInputRow = ({
                                    appliedVouchers,
                                    applyVoucher
                                }: OwnProps) => {

    const [state, setState] = useState<State>({
        wasSubmitted: false,
        code: undefined,
        isVisible: false
    });

    return appliedVouchers.length === 0 ? <div className="cart-table-row voucher-input-row">
        <hr className="w-100"/>
        <div className="row-content">
            <div className="mr-3 row-title">Code</div>
            <div className="ml-auto d-flex row-content">

                {state.isVisible ? <>
                    <div className="ml-auto mr-2 input-wrapper">
                        <input
                            className="form-control default-input"
                            value={state.code}
                            placeholder={"Rabattcode"}
                            onChange={event => setState({
                                ...state,
                                wasSubmitted: false,
                                code: event.target.value
                            })}
                        />
                        {state.wasSubmitted && state.code && !appliedVouchers.includes(state.code) ?
                            <small className="text-danger ml-auto">Dieser Code ist leider nicht gültig.</small> : null}
                    </div>
                    <AcademyButton buttonStyle={"secondary"}
                                   disabled={!state.code}
                                   text={"Rabattcode anwenden"}
                                   onClick={() => {
                                       setTimeout(() => {
                                           setState({
                                               ...state,
                                               wasSubmitted: true
                                           })
                                       }, 500)
                                       applyVoucher(state.code!)
                                   }}/>
                </> : <>
                    <button onClick={() => setState({...state, isVisible: true})} className="btn btn-link ml-auto mr-2">
                        Du hast einen Rabattcode?
                    </button>
                </>}


            </div>
        </div>

    </div> : null

}
