import {ApiCallAction, apiGet, apiPost, matchesAnyOfTheseApiSuccesses} from "@thekeytechnology/framework-react";
import {combineEpics} from "redux-observable";
import {API_FETCH_STATES, fetchStatesAction} from "../actions/fetch-states";
import {API_START_COURSE, StartCoursePayload} from "../actions/start-course";
import {Action} from "redux";
import {Observable} from "rxjs";
import {map} from "rxjs/operators"
import {API_APPLY_LICENSE, API_ORDER_CERTIFICATE} from "@thekeytechnology/thekey-academy-frontend-library";

const fetchCourseStates$ = apiGet(
    {apiType: API_FETCH_STATES, callType: API_FETCH_STATES},
    `/api/app/v1/states`,
);

const startCourse$ = apiPost(
    {apiType: API_START_COURSE, callType: API_START_COURSE},
    "/api/app/v1/states/start",
    (action: ApiCallAction<StartCoursePayload>) => action.payload
)

export const reloadCourseStateAfterStart$ = (action: Observable<Action>) => action.pipe(
    matchesAnyOfTheseApiSuccesses({apiType: API_START_COURSE}, {apiType: API_ORDER_CERTIFICATE}, {apiType: API_APPLY_LICENSE}),
    map(() => fetchStatesAction())
)

export const stateModuleEpics$ = combineEpics(
    fetchCourseStates$,
    reloadCourseStateAfterStart$,
    startCourse$
);
