/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TextAdditionalContentDisplay_TextAdditionalContentFragment = {
    readonly text: string;
    readonly title: string;
    readonly " $refType": "TextAdditionalContentDisplay_TextAdditionalContentFragment";
};
export type TextAdditionalContentDisplay_TextAdditionalContentFragment$data = TextAdditionalContentDisplay_TextAdditionalContentFragment;
export type TextAdditionalContentDisplay_TextAdditionalContentFragment$key = {
    readonly " $data"?: TextAdditionalContentDisplay_TextAdditionalContentFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"TextAdditionalContentDisplay_TextAdditionalContentFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TextAdditionalContentDisplay_TextAdditionalContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "TextAdditionalContent",
  "abstractKey": null
};
(node as any).hash = 'd433e16f7f02aebc5a613c08b7e196bc';
export default node;
