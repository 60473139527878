import React, {ReactNode} from "react"
import "./switch.scss";

interface OwnProps {
    className?: string;
    title: ReactNode;
    titleRight?: boolean;
    titleClassName?: string;

    isChecked: boolean
    setChecked: (checked: boolean) => void;
}

export const Switch = ({title, isChecked, setChecked, titleRight, titleClassName, className}: OwnProps) => {
    return <div
        className={`switch-wrapper ${titleRight ? "title-right" : "title-left"} d-flex align-items-center ${className ? className : ""}`}>

        {!titleRight ?
            <div className={"mr-auto w-100 " + titleClassName ? titleClassName : "tk-academy-large tk-color-darkblue"}>
                {title}
            </div> : null}
        <label className="switch m-0">
            <input type="checkbox" checked={isChecked} onChange={event => {
                setChecked(event.target.checked)
            }}/>
            <span className="slider round"/>
        </label>
        {titleRight ?
            <div className={"ml-2 " + titleClassName ? titleClassName : "tk-academy-large tk-color-darkblue"}>
                {title}
            </div> : null}
    </div>
}
