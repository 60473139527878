/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseInfo_LicensePoolsFragment = {
    readonly " $fragmentRefs": FragmentRefs<"LicenseAvailable_LicensePoolsFragment">;
    readonly " $refType": "CourseInfo_LicensePoolsFragment";
};
export type CourseInfo_LicensePoolsFragment$data = CourseInfo_LicensePoolsFragment;
export type CourseInfo_LicensePoolsFragment$key = {
    readonly " $data"?: CourseInfo_LicensePoolsFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseInfo_LicensePoolsFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseInfo_LicensePoolsFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LicenseAvailable_LicensePoolsFragment"
    }
  ],
  "type": "LicensePoolsConnection",
  "abstractKey": null
};
(node as any).hash = 'f3b9500926dc54abce3f064cbcc99128';
export default node;
