import React from "react";
import {FaqsScreen} from "./components/FaqsScreen";
import {RouteAuthState, RouteDefinition} from "../core/components/routing/RouteDefinition";

export const FaqsRouteDefinitions: RouteDefinition[] = [
    {
        path: "/faqs",
        component: () => <FaqsScreen/>,
        authState: RouteAuthState.OnlyLoggedIn
    }
]