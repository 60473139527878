import React, {Suspense} from "react";
import {composeWithDevTools} from "redux-devtools-extension";
import {createEpicMiddleware} from "redux-observable";
import {applyMiddleware, createStore} from "redux";
import {Provider} from "react-redux";
import {createBrowserHistory} from "history";
import {ConnectedRouter, routerMiddleware} from "connected-react-router";
import {appEpics} from "./_modules/epics";
import {appReducers} from "./_modules/reducers";
import {loadState, saveState} from "./localStorage";
import throttle from "lodash.throttle";
import * as Sentry from "@sentry/react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {SplashScreen} from "./components/SplashScreen";
import {MessagesToToast} from "./_modules/core/components/messages/MessagesToToast";
import {loadStripe} from "@stripe/stripe-js/pure";
import {Elements} from "@stripe/react-stripe-js";
import {RootNavigation} from "./_modules/core/components/routing/RootNavigation";
import {PageViewTracker} from "./_modules/analytics/PageViewTracker";
import {ErrorScreen} from "./components/ErrorScreen";
import {ThemeProvider} from "styled-components";
import {THEME} from "./_modules/theme/Theme";
import {CurrentRelayEnvironmentContextProvider} from "./CurrentRelayEnvironmentContextProvider";
import {ToastContainer} from "react-toastify";
import {CookieBanner} from "./_modules/tracking/components/CookieBanner.component";

const isProduction = process.env.REACT_APP_APP_ENVIRONMENT === "prod";
const isStaging = process.env.REACT_APP_APP_ENVIRONMENT === "staging";

export const history = createBrowserHistory();

const epicMiddleware = createEpicMiddleware();

const persistedState = loadState();

const middlewares = applyMiddleware(
    routerMiddleware(history), // for dispatching history actions
    epicMiddleware,
);

const storeEnhancers = isProduction ? middlewares : composeWithDevTools({
    trace: true,
})(middlewares);

const store = createStore(
    appReducers(history, process.env.REACT_APP_API_BASE!), // root reducer with router state
    persistedState,
    storeEnhancers,
);

store.subscribe(throttle(() => {
    saveState({
        auth: {
            authState: store.getState().auth.authState
        },
    });
}, 1000));

epicMiddleware.run(appEpics);

if (isProduction || isStaging) {
    Sentry.init({dsn: process.env.REACT_APP_SENTRY_DSN});
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY!);

export const App = () => {
    return <>
        <ToastContainer autoClose={5000} newestOnTop={true}/>
        <ThemeProvider theme={THEME}>
            <Sentry.ErrorBoundary fallback={errorData => <ErrorScreen error={errorData.error}/>}>
                <Elements stripe={stripePromise}>
                    <Provider store={store}>
                        <Suspense fallback={<SplashScreen/>}>
                            <CurrentRelayEnvironmentContextProvider store={store}>
                                <DndProvider backend={HTML5Backend}>
                                    <>
                                        <MessagesToToast/>
                                        <ConnectedRouter history={history}>
                                            <RootNavigation/>
                                            <PageViewTracker/>
                                        </ConnectedRouter>
                                        <CookieBanner/>
                                    </>
                                </DndProvider>
                            </CurrentRelayEnvironmentContextProvider>
                        </Suspense>
                    </Provider>
                </Elements>
            </Sentry.ErrorBoundary>
        </ThemeProvider>
    </>
}
