import React from "react";
import {generateString} from "@thekeytechnology/framework-react";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {Form, Formik} from "formik";
import {ValidatedField} from "../../../core/components/form/ValidatedField";
import {SimpleOptionSelectField} from "../../../core/components/select/SimpleOptionSelectField";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {AssignLicenseButton_LicensesInAccountContainerFragment$key} from "../../../../__generated__/AssignLicenseButton_LicensesInAccountContainerFragment.graphql";

interface OwnProps {
    courseId: string | null;
    applyLicense: (courseId: string, userId: string) => void
    LicensesInAccountContainerRef: AssignLicenseButton_LicensesInAccountContainerFragment$key
}

interface FormState {
    userId?: string
    courseId?: string
}

const LICENSES_IN_ACCOUNT_CONTAINER_FRAGMENT = graphql`
    fragment AssignLicenseButton_LicensesInAccountContainerFragment on LicenseInAccountContainer {
        id
        courseLocksByUser{
            user
            coursesToUnlock {
                courseId
                title
            }
            coursesUnlocked {
                title
            }
        }
        licenses {
            limitedToCoursesRef
        }
        courses {
            id
            containerMeta {
                title
            }
        }
        usersInAccount {
            id
            name
        }
    }`

export const AssignLicenseButton = ({LicensesInAccountContainerRef, applyLicense, courseId}: OwnProps) => {
    const {t} = useTranslation(["manager"])

    const licensesInAccount = useFragment<AssignLicenseButton_LicensesInAccountContainerFragment$key>(LICENSES_IN_ACCOUNT_CONTAINER_FRAGMENT, LicensesInAccountContainerRef)

    const popover = <Popover id={generateString(10)}>
        <Popover.Title as="h3" className="d-flex align-items-center roles-editor-popover">
            {t("assign-license-button.label")}
            <button
                onClick={() => {
                    document.body.click()
                }}
                className="ml-auto btn btn-link p-0">
                <FontAwesomeIcon icon={faTimes}/>
            </button>
        </Popover.Title>
        <Popover.Content>
            <Formik<FormState>
                initialValues={{
                    userId: undefined,
                    courseId: undefined
                }}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                    userId: Yup.string().required(t("assign-license-button.user-id-error")),
                    courseId: Yup.string().required(t("assign-license-button.course-id-error"))
                })}
                onSubmit={(values, {setSubmitting}) => {
                    applyLicense(values.courseId!, values.userId!)
                    document.body.click()
                    setSubmitting(false);
                }}
            >
                {formikState => {
                    const unlockedCourses = licensesInAccount.courseLocksByUser.filter(cl => cl.user === formikState.values.userId).map(l => l.coursesUnlocked)[0]
                    const filteredCourses = courseId ? (licensesInAccount.courseLocksByUser
                            .find(cl => cl.user === formikState.values.userId)?.coursesToUnlock
                            .filter(c => courseId && c.courseId === courseId)
                            .map(c => {
                                return {
                                    label: c.title,
                                    value: c.courseId!
                                }
                            }) || []) :
                        (licensesInAccount.courseLocksByUser
                            .find(cl => cl.user === formikState.values.userId)?.coursesToUnlock
                            .map(c => {
                                return {
                                    label: c.title,
                                    value: c.courseId!
                                }
                            }) || [])


                    return <Form className="d-flex flex-column">
                        <ValidatedField
                            formikState={formikState}
                            component={SimpleOptionSelectField}
                            options={licensesInAccount.usersInAccount.map(u => {
                                return {
                                    label: u.name,
                                    value: u.id
                                }
                            })}
                            placeholder={t("assign-license-button.user-id-placeholder")}
                            name="userId"
                            className="form-control default-input"
                        />
                        {unlockedCourses && unlockedCourses.length > 0 ? <div>
                            Benutzer hat
                            bereits: {unlockedCourses.map(uc => uc.title).join(", ")}
                        </div> : null}
                        <ValidatedField
                            disabled={!formikState.values.userId}
                            formikState={formikState}
                            component={SimpleOptionSelectField}
                            options={filteredCourses}
                            placeholder={t("assign-license-button.course-id-placeholder")}
                            name="courseId"
                            className="form-control default-input"
                        />

                        <button
                            disabled={formikState.isSubmitting || !formikState.isValid || Object.entries(formikState.touched).length === 0}
                            className="btn btn-success w-100 mt-3"
                            type="submit">
                            {t("assign-license-button.save")}
                        </button>
                    </Form>
                }}
            </Formik>
        </Popover.Content>
    </Popover>;

    return <OverlayTrigger
        rootClose={true}
        trigger="click"
        placement="right"
        overlay={popover}>
        <button className="btn btn-link p-0">
            {t("assign-license-button.assign")}
        </button>
    </OverlayTrigger>
};

