import {CardElement, IbanElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {FormikErrors} from "formik/dist/types";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {Message, postMessageAction} from "@thekeytechnology/framework-react";
import * as Sentry from "@sentry/react";
import {
    BusinessBillingDetails,
    createPaymentData,
    OrderContainer,
    payAction,
    PaymentMethodIds,
    PrivateBillingDetails,
    StripeData
} from "@thekeytechnology/thekey-academy-frontend-library";

export interface UsePaymentLogicSignature {
    startPayment: (values: any, setErrors: (errors: FormikErrors<any>) => void, setSubmitting: (submitting: boolean) => void) => void;
}

export const usePaymentLogic = (order: OrderContainer, selectedPaymentMethod?: PaymentMethodIds): UsePaymentLogicSignature => {
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();

    const url = new URL(window.location.href);
    const clientSecret = url.searchParams.get("payment_intent_client_secret");


    useEffect(() => {
        if (clientSecret) {
            stripe!.retrievePaymentIntent(clientSecret).then(response => {
                if (response.error) {
                    dispatch(postMessageAction(Message.TYPE_DANGER, "Deine Zahlung konnte nicht abgeschlossen werden. Bitte überprüfe die Zahlungsmethode."))
                    Sentry.captureException(response.error);
                } else if (response.paymentIntent && ["succeeded", "processing"].includes(response.paymentIntent.status)) {
                    dispatch(payAction(
                        order.order.id!,
                        createPaymentData(url.searchParams.get("payment_method") as PaymentMethodIds),
                        url.searchParams.get("ads_optin") === "false"
                    ))
                } else {
                    dispatch(postMessageAction(Message.TYPE_DANGER, "Deine Zahlung konnte nicht abgeschlossen werden. Bitte überprüfe die Zahlungsmethode."))
                }
            });
        }
        // eslint-disable-next-line
    }, [])

    const startPayment = (values: any, setErrors: (errors: FormikErrors<any>) => void, setSubmitting: (submitting: boolean) => void) => {
        if (selectedPaymentMethod === "paypal") {
            return;
        }

        const onPaymentResponse = (result: any) => {
            if (result.error) {
                setErrors({
                    paymentMethodField: result.error.message
                })
            } else {
                dispatch(payAction(
                    order.order.id!,
                    createPaymentData(selectedPaymentMethod),
                    values.adsOptIn
                ))
            }
            setSubmitting(false);
        };

        const onPaymentError = (reason: any) => {
            setErrors({
                paymentMethodField: reason
            })
            setSubmitting(false);
        };

        let billingDetails;
        if (order.order.entity.billingDetails.customerType === "private") {
            const privateBillingDetails = order.order.entity.billingDetails as PrivateBillingDetails
            billingDetails = {
                email: privateBillingDetails.invoiceEmail,
                name: privateBillingDetails.firstName + " " + privateBillingDetails.lastName,
                address: {
                    line1: privateBillingDetails.street + " " + privateBillingDetails.houseNumber,
                    postal_code: privateBillingDetails.postalCode,
                    city: privateBillingDetails.city,
                    country: privateBillingDetails.country
                }
            }
        } else {
            const privateBillingDetails = order.order.entity.billingDetails as BusinessBillingDetails
            billingDetails = {
                email: privateBillingDetails.invoiceEmail,
                name: privateBillingDetails.firstName + " " + privateBillingDetails.lastName,
                address: {
                    line1: privateBillingDetails.street + " " + privateBillingDetails.houseNumber,
                    postal_code: privateBillingDetails.postalCode,
                    city: privateBillingDetails.city,
                    country: privateBillingDetails.country
                }
            }
        }

        switch (selectedPaymentMethod) {
            case "card": {
                stripe!.confirmCardPayment((order.order.entity.paymentProviderData as StripeData).paymentIntentSecret, {
                    payment_method: {card: elements?.getElement(CardElement)!}
                }).then(onPaymentResponse).catch(onPaymentError)
                break;
            }
            case "sepa": {
                stripe!.confirmSepaDebitPayment((order.order.entity.paymentProviderData as StripeData).paymentIntentSecret, {
                    payment_method: {
                        billing_details: billingDetails,
                        sepa_debit: elements?.getElement(IbanElement)!
                    }
                }).then(onPaymentResponse).catch(onPaymentError)
                break;
            }
            case "giropay":
                stripe!.confirmGiropayPayment((order.order.entity.paymentProviderData as StripeData).paymentIntentSecret, {
                    payment_method: {
                        billing_details: billingDetails,
                    },
                    return_url: `${window.location.href}?ads_optin=${values.adsOptIn ? "true" : "false"}&payment_method=giropay`
                })
                break;
            case "sofort":
                // @ts-ignore
                stripe!.confirmSofortPayment((order.order.entity.paymentProviderData as StripeData).paymentIntentSecret, {
                    payment_method: {
                        billing_details: billingDetails,
                        sofort: {
                            country: billingDetails.address.country.toUpperCase()
                        }
                    },
                    return_url: `${window.location.href}?ads_optin=${values.adsOptIn ? "true" : "false"}&payment_method=sofort`
                })
                break;
            default:
                dispatch(payAction(
                    order.order.id!,
                    createPaymentData(selectedPaymentMethod, values.iban),
                    values.adsOptIn
                ))
        }
    }

    return {
        startPayment
    }

}
