/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ClozeTextPartType = "cloze" | "text" | "%future added value";
export type ElementType = "clozeText" | "image" | "multipleChoice" | "order" | "persolog" | "questionnaire" | "resolution" | "text" | "video" | "%future added value";
export type ClozeTextElementDisplay_ClozeTextElementFragment = {
    readonly additionalWords: ReadonlyArray<string>;
    readonly availableWordsInRandomOrder: ReadonlyArray<string>;
    readonly elementType: ElementType;
    readonly parts: ReadonlyArray<{
        readonly partType: ClozeTextPartType;
        readonly cloze?: string;
        readonly text?: string;
    }>;
    readonly image: {
        readonly url: string | null;
    } | null;
    readonly generalSettings: {
        readonly points: number;
    };
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"ClozeText_ClozeTextElementFragment">;
    readonly " $refType": "ClozeTextElementDisplay_ClozeTextElementFragment";
};
export type ClozeTextElementDisplay_ClozeTextElementFragment$data = ClozeTextElementDisplay_ClozeTextElementFragment;
export type ClozeTextElementDisplay_ClozeTextElementFragment$key = {
    readonly " $data"?: ClozeTextElementDisplay_ClozeTextElementFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ClozeTextElementDisplay_ClozeTextElementFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClozeTextElementDisplay_ClozeTextElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalWords",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "availableWordsInRandomOrder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "parts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "partType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cloze",
              "storageKey": null
            }
          ],
          "type": "ClozePart",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            }
          ],
          "type": "TextPart",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GeneralElementSettings",
      "kind": "LinkedField",
      "name": "generalSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "points",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClozeText_ClozeTextElementFragment"
    }
  ],
  "type": "ClozeTextElement",
  "abstractKey": null
};
(node as any).hash = 'be260a374b8678084d6e56b7bad40ae2';
export default node;
