import React, {useEffect} from "react";
import {Screen} from "../../core/components/screen/Screen";
import {connect} from "react-redux";
import {fetchGamificationDataAction} from "@thekeytechnology/thekey-academy-frontend-library";
import {BlueBackground} from "../../core/components/screen/BlueBackground";
import {RankMessage} from "./rank/RankMessage";
import {RanksDisplay} from "./rank/RanksDisplay";
import {AchievementsDisplay} from "./achievement/AchievementsDisplay";
import {StreakDisplay} from "./streak/StreakDisplay";
import {graphql} from "babel-plugin-relay/macro";
import {useLazyLoadQuery} from "react-relay";
import {MainSidebar} from "../../core/components/sidebar/MainSidebar";
import {GamificationScreenComponent_Query} from "../../../__generated__/GamificationScreenComponent_Query.graphql";

const QUERY = graphql`
    query GamificationScreenComponent_Query  {
        Viewer {
            CustomerMessageV2 {
                CustomerMessageInformation {
                    id
                    numUnseenCustomerMessages
                }
            }
            License{
                NewLicensesInAccount {
                    id
                    newLicenses
                }
            }
        }
    }
`

interface DispatchProps {
    fetchGamificationData: typeof fetchGamificationDataAction
}

type Props = DispatchProps

const GamificationScreenComponent = ({fetchGamificationData}: Props) => {
    const query = useLazyLoadQuery<GamificationScreenComponent_Query>(QUERY, {}, {fetchPolicy: "network-only"});

    useEffect(() => {
        fetchGamificationData()
    }, [fetchGamificationData])

    return <Screen
        headerBarBackgroundSlot={<BlueBackground height={60}/>}
        sidebarSlot={<MainSidebar
            numUnseenCustomerMessages={query.Viewer.CustomerMessageV2.CustomerMessageInformation.numUnseenCustomerMessages}
            hasNewLicensesInAccount={query.Viewer.License.NewLicensesInAccount.newLicenses}
        />}
        headerMargin={30}
    >
        <RankMessage title={"Ränge & Auszeichnungen"}
                     text={""}/>

        <div className="tk-academy-text mb-5 mt-5 tk-academy-card p-5">Dieser Bereich dreht sich um Dich, Dein Level und
            Deine bisherigen
            Auszeichnungen. Level und Auszeichnungen
            zählen übergreifend, also unabhängig von Deinen Interaktivlehrgängen. Alle Schlüssel, die Du in den
            Lehrgängen sammelst, werden zusammengezählt und ergeben Dein Level. Gleiches gilt für Auszeichnungen: in
            welchen oder in wie vielen Lehrgängen Du beispielsweise fünf Lektionen mit Gold abschließt, ist unerheblich
            für Deine Auszeichnung. Die Errungenschaften werden zusammengezählt.
        </div>
        <StreakDisplay className="mt-3"/>
        <RanksDisplay/>
        <AchievementsDisplay/>
    </Screen>;
}

export const GamificationScreen = connect<{}, DispatchProps, {}>(
    null,
    {
        fetchGamificationData: fetchGamificationDataAction
    }
)(GamificationScreenComponent)
