/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ReviewPreparationScreen_ReviewOptionsConnectionFragment = {
    readonly edges: ReadonlyArray<{
        readonly node: {
            readonly module: {
                readonly containerMeta: {
                    readonly title: string;
                };
                readonly id: string;
                readonly modulePath: {
                    readonly moduleIndex: number;
                };
            };
            readonly lessons: ReadonlyArray<{
                readonly containerMeta: {
                    readonly title: string;
                };
                readonly id: string;
                readonly lessonPath: {
                    readonly lessonIndex: number;
                    readonly moduleIndex: number;
                };
            }>;
        };
    } | null> | null;
    readonly " $refType": "ReviewPreparationScreen_ReviewOptionsConnectionFragment";
};
export type ReviewPreparationScreen_ReviewOptionsConnectionFragment$data = ReviewPreparationScreen_ReviewOptionsConnectionFragment;
export type ReviewPreparationScreen_ReviewOptionsConnectionFragment$key = {
    readonly " $data"?: ReviewPreparationScreen_ReviewOptionsConnectionFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ReviewPreparationScreen_ReviewOptionsConnectionFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "ContainerMeta",
  "kind": "LinkedField",
  "name": "containerMeta",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "moduleIndex",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewPreparationScreen_ReviewOptionsConnectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReviewOptionsEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReviewOption",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Module",
              "kind": "LinkedField",
              "name": "module",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ModulePath",
                  "kind": "LinkedField",
                  "name": "modulePath",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "lessons",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LessonPath",
                  "kind": "LinkedField",
                  "name": "lessonPath",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lessonIndex",
                      "storageKey": null
                    },
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ReviewOptionsConnection",
  "abstractKey": null
};
})();
(node as any).hash = 'c4ddddd298d1f088f381fc9f40cfbc51';
export default node;
