/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LessonType = "normal" | "test" | "%future added value";
export type LessonDetailSidebar_LessonFragment = {
    readonly containerMeta: {
        readonly title: string;
    };
    readonly course: {
        readonly " $fragmentRefs": FragmentRefs<"CourseSidebar_CourseFragment">;
    };
    readonly id: string;
    readonly lessonPath: {
        readonly lessonIndex: number;
        readonly moduleIndex: number;
    };
    readonly lessonType: LessonType;
    readonly additionalContent?: ReadonlyArray<{
        readonly additionalContent: {
            readonly id: string;
        };
    }>;
    readonly glossary?: ReadonlyArray<{
        readonly id: string;
    }>;
    readonly " $refType": "LessonDetailSidebar_LessonFragment";
};
export type LessonDetailSidebar_LessonFragment$data = LessonDetailSidebar_LessonFragment;
export type LessonDetailSidebar_LessonFragment$key = {
    readonly " $data"?: LessonDetailSidebar_LessonFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LessonDetailSidebar_LessonFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonDetailSidebar_LessonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContainerMeta",
      "kind": "LinkedField",
      "name": "containerMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "course",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CourseSidebar_CourseFragment"
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LessonPath",
      "kind": "LinkedField",
      "name": "lessonPath",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lessonIndex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleIndex",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lessonType",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AdditionalContentWithLessonPath",
          "kind": "LinkedField",
          "name": "additionalContent",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "additionalContent",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GlossaryEntry",
          "kind": "LinkedField",
          "name": "glossary",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "type": "NormalLesson",
      "abstractKey": null
    }
  ],
  "type": "Lesson",
  "abstractKey": "__isLesson"
};
})();
(node as any).hash = '37afb30b145e36b10faff211b26aff9e';
export default node;
