import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    DialogResult,
    selectCurrentAccount,
    selectCurrentUserRoles,
    showDialogAction,
    UserWithRoles
} from "@thekeytechnology/framework-react";
import {LicensesInAccount} from "./licenses/LicensesInAccount";
import {leaveAccountAction} from "../../auth/action/leave-account";
import {useTranslation} from "react-i18next";
import {UsersInAccount} from "./user/UsersInAccount";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {
    ManagerMainPage_LicenseInAccountContainerFragment$key
} from "../../../__generated__/ManagerMainPage_LicenseInAccountContainerFragment.graphql";

const LICENSES_IN_ACCOUNT_CONTAINER_FRAGMENT = graphql`
    fragment ManagerMainPage_LicenseInAccountContainerFragment on LicenseInAccountContainer {
        ...LicensesInAccount_LicenseInAccountContainerFragment
    }
`

interface OwnProps {
    licensesInAccountContainerFragmentRef: ManagerMainPage_LicenseInAccountContainerFragment$key
}

export const ManagerMainPage = ({licensesInAccountContainerFragmentRef}: OwnProps) => {
    const {t} = useTranslation("manager")
    const dispatch = useDispatch();
    const currentAccount = useSelector(selectCurrentAccount)
    const currentUserRoles = useSelector(selectCurrentUserRoles)

    const licensesInAccount = useFragment<ManagerMainPage_LicenseInAccountContainerFragment$key>(LICENSES_IN_ACCOUNT_CONTAINER_FRAGMENT, licensesInAccountContainerFragmentRef)

    const LeaveAccountButton = <button
        className="btn btn-danger"
        onClick={() => {
            dispatch(showDialogAction({
                title: t("manager-main-page.leave-dialog.title", {accountName: currentAccount?.name}),
                question: t("manager-main-page.leave-dialog.question", {accountName: currentAccount?.name}),
                explanation: t("manager-main-page.leave-dialog.explanation"),
                affirmativeText: t("manager-main-page.leave-dialog.affirmativeText"),
                negativeText: t("manager-main-page.leave-dialog.negativeText"),
                affirmativeButtonClass: "btn btn-danger"
            }, result => {
                if (result === DialogResult.ACCEPT) {
                    dispatch(leaveAccountAction())
                }
            }))
        }}>
        {t("manager-main-page.leave-button")}
    </button>;


    return currentUserRoles?.includes(UserWithRoles.ROLE_ADMIN) ? <div>
        <div
            className="tk-academy-text tk-academy-bold mb-2 tk-color-waterblue-80">{t("manager-main-page.subheading")}</div>
        <h1 className="tk-academy-larger-bold mb-2 tk-color-darkblue">{t("manager-main-page.heading-admin")}</h1>
        <p className="tk-academy-text mb-2"
           dangerouslySetInnerHTML={{__html: t("manager-main-page.explanation-admin")}}/>
        <UsersInAccount/>
        <LicensesInAccount licensesInAccountContainerFragmentRef={licensesInAccount}/>

        <h2 className="tk-academy-large-bold mb-2 mt-4">{t("manager-main-page.heading-leaving")}</h2>
        <p className="tk-academy-text mb-4"
           dangerouslySetInnerHTML={{__html: t("manager-main-page.explanation-leaving")}}/>
        {LeaveAccountButton}

    </div> : <div>
        <div
            className="tk-academy-text tk-academy-bold mb-2 tk-color-waterblue-80">{t("manager-main-page.subheading")}</div>
        <h1 className="tk-academy-larger-bold mb-3 tk-color-darkblue">{t("manager-main-page.heading-admin", {accountName: currentAccount?.name})}</h1>
        <p className="tk-academy-text mb-4">{t("manager-main-page.explanation-leaving")}</p>
        {LeaveAccountButton}
    </div>
}
