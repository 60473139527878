import React from "react";
import styled, {css} from "styled-components";

interface OwnProps {
    percentage: number
    licenseCount: number
    eligibleProducts: number
}

export const DiscountBox = ({eligibleProducts, percentage, licenseCount}: OwnProps) => {
    return <DiscountBoxComponent isEmpty={false} isReached={eligibleProducts >= licenseCount}>
        <div className="percentage-wrapper">
            <span className="percentage">{percentage}%</span>
        </div>
        {licenseCount > 1 && <div className="license-count">ab {licenseCount} Lizenzen</div>}
    </DiscountBoxComponent>
}

interface DiscountBoxProps {
    isReached: boolean
    isEmpty: boolean,
}

const DiscountBoxComponent = styled.div<DiscountBoxProps>`
  border-radius: 2px;
  height: 20px;
  text-align: center;
  vertical-align: center;
  position: relative;

  font-size: 14px;
  line-height: 1.57;
  transition: all 0.5s ease-in-out;

  ${props => props.isReached && css`
    background-color: #4ace7f;

    color: white;
    animation-fill-mode: both;

    position: relative;

    .progress-bar {
      display: block;
      animation: progressBar 1s ease-in-out;
      background-color: #4ace7f;
    }
  `};

  ${props => !props.isReached && css`
    background-color: #e7ebf1;
    color: #184276;
  `};

  @media (max-width: 1024px) {
    height: auto;
    padding: 5px;
    border-radius: 6px;
    border: solid 1px #e7ebf1;
    background-color: white;

    ${props => props.isReached && css`
      color: #466791;
      border-bottom: 5px solid #247dc5;
    `};
    ${props => !props.isReached && css`
      color: #a2b3c8;
      border-bottom: 5px solid #d0d9e3;
    `};

    .percentage {
      font-size: 12px;
    }

    .license-count {
      font-size: 12px;
    }

    .from {
      font-size: 10px;
    }
  }

  @media (min-width: 1025px) {
    .license-count, .from {
      display: none;
    }
  }


  ${props => props.isEmpty && css`
    @media (max-width: 1024px) {
      display: none;
    }
  `}
`
