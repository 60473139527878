import {
    acceptInvitationAttempt,
    API_ACCEPT_INVITATION,
    logoutAction,
    selectCombinedApiState,
    selectCurrentUser
} from "@thekeytechnology/framework-react";
import {Form, Formik} from "formik";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {push} from "connected-react-router";

interface OwnProps {
    token: string;
}

export const AcceptInvitation = ({token}: OwnProps) => {
    const dispatch = useDispatch();
    const {t} = useTranslation("auth");
    const currentUser = useSelector(selectCurrentUser)
    const apiCallState = useSelector(selectCombinedApiState(API_ACCEPT_INVITATION))


    const SuccessScreen = <>
        <div className="tk-bg-green-20 mb-5 p-5">
            {t("invitation-form.success-text")}
        </div>
        <button type="button"
                onClick={() => {
                    dispatch(logoutAction());
                    dispatch(push("/login"))
                }}
                className="btn btn-primary align-self-end w-100"
        >
            {t("invitation-form.success-to-login")}
        </button>
    </>;

    if (apiCallState.succeeded) {
        return SuccessScreen;
    }


    return currentUser ? <Formik
        initialValues={{}}
        onSubmit={(values, {setSubmitting}) => {
            dispatch(acceptInvitationAttempt(token));
            setSubmitting(false);
        }}
    >
        {({isSubmitting}) => (
            <Form>
                <div className="paragraph text-center mb-2">
                    {t("invitation-form.logged-in-as", {
                        userName: currentUser?.entity.name,
                        email: currentUser?.entity.email
                    })}
                </div>
                <button disabled={isSubmitting || apiCallState.inProgress}
                        type="submit"
                        className="btn btn-primary w-100">
                    {t("invitation-form.accept-invitation")}
                </button>
            </Form>
        )}
    </Formik> : null
}


