/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ElementType = "clozeText" | "image" | "multipleChoice" | "order" | "persolog" | "questionnaire" | "resolution" | "text" | "video" | "%future added value";
export type ReviewSidebar_ElementFragment = {
    readonly elementPath: {
        readonly courseRef: string;
    };
    readonly elementType: ElementType;
    readonly " $refType": "ReviewSidebar_ElementFragment";
};
export type ReviewSidebar_ElementFragment$data = ReviewSidebar_ElementFragment;
export type ReviewSidebar_ElementFragment$key = {
    readonly " $data"?: ReviewSidebar_ElementFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ReviewSidebar_ElementFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewSidebar_ElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ElementPath",
      "kind": "LinkedField",
      "name": "elementPath",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "courseRef",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementType",
      "storageKey": null
    }
  ],
  "type": "Element",
  "abstractKey": "__isElement"
};
(node as any).hash = 'd7260c85cd0310a597c153e971c07225';
export default node;
