/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CartStepClosed_CartFragment = {
    readonly cartTotals: {
        readonly totalsIncludingAllDiscounts: {
            readonly grossPrice: number;
        } | null;
    };
    readonly " $refType": "CartStepClosed_CartFragment";
};
export type CartStepClosed_CartFragment$data = CartStepClosed_CartFragment;
export type CartStepClosed_CartFragment$key = {
    readonly " $data"?: CartStepClosed_CartFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CartStepClosed_CartFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CartStepClosed_CartFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CartTotals",
      "kind": "LinkedField",
      "name": "cartTotals",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceV3",
          "kind": "LinkedField",
          "name": "totalsIncludingAllDiscounts",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "grossPrice",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Cart",
  "abstractKey": null
};
(node as any).hash = '7cfa1dcf4393fde8712ce865081155a4';
export default node;
