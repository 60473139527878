import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {CartItem_CartItemFragment$key} from "../../../../../__generated__/CartItem_CartItemFragment.graphql";
import React from "react";
import {AmountInput} from "./AmountInput";
import styled from "styled-components";
import {CurrencyDisplay} from "../../../../core/components/currency/CurrencyDisplay";
import {ProductImage} from "./ProductImage";
import {IHKBadge} from "./IHKBadge";
import {DateDisplay} from "../../../../core/components/display/DateDisplay";

const CART_ITEM_FRAGMENT = graphql`
    fragment CartItem_CartItemFragment on CartItem {
        itemType
        ... on DiscountCodeCartItem {
            title
            discountPercentage
            code
            validUntil
        }
        ... on BulkDiscountCartItem {
            eligibleProductCount
            discountPercentage
        }
        ... on ProductCartItem {
            product {
                id
                title
                ...on CourseProduct {
                    course {
                        isIHK
                        icon {
                            url
                        }
                    }
                }
            }

            totalPrice {
                netPrice
                grossPrice
            }

            individualPrice {
                netPrice
                grossPrice
            }
            amount
        }
    }
`;

interface OwnProps {
    cartItemFragmentRef: CartItem_CartItemFragment$key
    changeProductAmount: (productId: string, amount: number) => void;
    removeDiscount: (code: string) => void;
}

export const CartItem = ({cartItemFragmentRef, changeProductAmount, removeDiscount}: OwnProps) => {
    const cartItem = useFragment<CartItem_CartItemFragment$key>(CART_ITEM_FRAGMENT, cartItemFragmentRef)

    return <CartItemContainer>
        {cartItem.itemType === "productCartItem" &&
        <>
            <ProductImage url={cartItem.product?.course?.icon?.url || undefined}/>

            <ProductTitleWrapper>
                <ProductTitle>
                    {cartItem.product?.course && <>
                        {cartItem.product?.title}
                    </>}
                </ProductTitle>
                {cartItem.product?.course?.isIHK && <IHKBadge/>}
            </ProductTitleWrapper>

            <AmountInput value={cartItem.amount || 1} updateValue={newValue => {
                changeProductAmount(cartItem.product!.id, newValue)
            }}/>

            <RightSideWrapper>
                <TrashButton onClick={() => changeProductAmount(cartItem.product!.id, 0)}>
                    <span className="icon-a_trash"/>
                </TrashButton>
                <PriceDisplay>
                    <CurrencyDisplay value={cartItem.totalPrice?.grossPrice!}/>
                </PriceDisplay>
            </RightSideWrapper>

        </>}
        {cartItem.itemType === "discountCodeCartItem" &&
        <>
            <div/>

            <ProductTitleWrapper>
                <ProductTitle>
                    {cartItem.validUntil && <DiscountCodeValiditySpan>Noch bis <DateDisplay
                        date={cartItem.validUntil}/></DiscountCodeValiditySpan>}
                    {cartItem.title}
                </ProductTitle>
            </ProductTitleWrapper>

            <div/>

            <RightSideWrapper>
                <TrashButton onClick={() => removeDiscount(cartItem.code!)}>
                    <span className="icon-a_trash"/>
                </TrashButton>
                <PriceDisplay>
                    -{cartItem.discountPercentage}%
                </PriceDisplay>
            </RightSideWrapper>

        </>}

        {cartItem.itemType === "bulkDiscountCartItem" &&
        <>
            <div/>

            <ProductTitleWrapper>
                <ProductTitle>
                    Mengenrabatt für {cartItem.eligibleProductCount} Produkte im Warenkorb
                </ProductTitle>
            </ProductTitleWrapper>

            <div/>

            <RightSideWrapper>
                <PriceDisplay>
                    -{cartItem.discountPercentage?.toString().replace(".", ",")}%
                </PriceDisplay>
            </RightSideWrapper>
        </>}
    </CartItemContainer>

}

const CartItemContainer = styled.div`
  display: grid;
  grid-template-columns: 75px 2fr 1fr 1fr;
  grid-column-gap: 20px;

  @media (max-width: 1024px) {
    grid-template-columns: 75px 1fr;
    grid-row-gap: 20px;
  }

  padding-bottom: 20px;
  border-bottom: solid 1px #e7ebf1;
  margin-bottom: 20px;
`


const ProductTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 1024px) {
    padding-top: 20px;

    border-top: 1px solid #e7ebf1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

`

const TrashButton = styled.button`
  color: #d0d9e3;
  margin-bottom: 10px;
  @media (max-width: 1024px) {
    margin-bottom: 0;
    margin-right: 10px;
  }
`

export const PriceDisplay = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: "Nunito Sans", sans-serif;
  line-height: 1.57;
  align-items: center;
  display: flex;
  color: #031d3c;
`

export const DiscountCodeValiditySpan = styled.span`
  margin-right: 10px;
  padding: 0 3px 1px;
  border-radius: 2px;
  background-color: #247ac7;
  color: white;
  font-size: 12px;
`
export const ProductTitle = styled.div`
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  color: #031d3c;
  display: flex;
  align-items: center;
`
