import React from "react";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {ValidatedField} from "../../../../../core/components/form/ValidatedField";
import {useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {useDispatch} from "react-redux";
import {API_LOGIN, apiCallSucceededAction, LoginResponse} from "@thekeytechnology/framework-react";
import {BillingLoginV3_LoginMutation} from "../../../../../../__generated__/BillingLoginV3_LoginMutation.graphql";
import {StyledForm} from "../../../../../theme/Form";

const LOGIN_MUTATION = graphql`
    mutation BillingLoginV3_LoginMutation($login: LoginInput!) {
        Auth {
            login(input: $login) {
                login {
                    user {
                        id
                        name
                        email
                        meta {
                            firstName
                            lastName
                        }
                    }
                    accounts {
                        accountId
                        name
                        roles
                    }
                    rolesInCurrentAccount
                    token
                }
            }
        }
    }
`;

interface OwnProps {
    onSuccess?: () => void;
}

export const BillingLoginV3 = ({onSuccess}: OwnProps) => {
    const {t} = useTranslation(["auth", "core"]);
    const dispatch = useDispatch();


    const [mutate, isInFlight] = useMutation<BillingLoginV3_LoginMutation>(LOGIN_MUTATION)

    return <Formik
        initialValues={{email: "", password: ""}}
        validationSchema={Yup.object().shape({
            email: Yup
                .string()
                .email(t("registration-form.email-error"))
                .required(t("core:forms.required-field", {fieldName: t("registration-form.email")})),
            password: Yup
                .string()
                .min(8, t("registration-form.password-error"))
                .required(t("core:forms.required-field", {fieldName: t("registration-form.password")})),
        })}
        onSubmit={(values, {setSubmitting}) => {
            mutate({
                variables: {login: {email: values.email, password: values.password}},
                onCompleted: response => {
                    dispatch(apiCallSucceededAction({apiType: API_LOGIN}, {
                        token: response.Auth.login?.login.token,
                        accounts: response.Auth.login?.login.accounts,
                        user: {
                            id: response.Auth.login?.login.user.id,
                            entity: {
                                id: response.Auth.login?.login.user.id,
                                email: response.Auth.login?.login.user.email,
                                name: response.Auth.login?.login.user.name,
                                roles: response.Auth.login?.login.rolesInCurrentAccount
                            }
                        }
                    } as LoginResponse))
                    if (onSuccess) {
                        onSuccess();
                    }
                },
                updater: (store, data) => {
                    const authField = store.get("client:root:Viewer:Auth")
                    const newUser = authField?.getOrCreateLinkedRecord("User", "User");
                    const meta = newUser?.getOrCreateLinkedRecord("newUser", "UserMeta")
                    meta?.setValue(data.Auth.login?.login.user.meta?.firstName, "firstName")
                    meta?.setValue(data.Auth.login?.login.user.meta?.lastName, "lastName")
                    newUser?.setLinkedRecord(meta!, "meta")
                    newUser?.setValue(data.Auth.login?.login.user.id, "id")
                    newUser?.setValue(data.Auth.login?.login.user.email, "email")
                    newUser?.setValue(data.Auth.login?.login.user.name, "name")
                    authField?.setLinkedRecord(newUser!, "User")
                }
            })
            setSubmitting(false);
        }}
    >
        {formikState => (
            <StyledForm>
                <Form>
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        type="email"
                                        autoComplete="username"
                                        placeholder={t("login-form.e-mail-placeholder")}
                                        name="email"
                                        className="form-control default-input"
                                        required/>
                    </div>
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        type="password"
                                        autoComplete="password"
                                        placeholder={t("login-form.password-placeholder")}
                                        name="password"
                                        className="form-control default-input"
                                        required/>
                    </div>
                    <div className="form-group d-flex">
                        <button type="submit" disabled={isInFlight}
                                className="btn btn-secondary align-self-end h-100">
                            {t("login-form.login")}
                        </button>
                    </div>
                </Form>
            </StyledForm>
        )}
    </Formik>
}


