/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LicensePoolDisplay_CourseFragment = {
    readonly id: string;
    readonly mainProduct: string | null;
    readonly mainDiscountCode: string | null;
    readonly " $fragmentRefs": FragmentRefs<"ApplyLicenseButton_CourseFragment">;
    readonly " $refType": "LicensePoolDisplay_CourseFragment";
};
export type LicensePoolDisplay_CourseFragment$data = LicensePoolDisplay_CourseFragment;
export type LicensePoolDisplay_CourseFragment$key = {
    readonly " $data"?: LicensePoolDisplay_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LicensePoolDisplay_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LicensePoolDisplay_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mainProduct",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mainDiscountCode",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplyLicenseButton_CourseFragment"
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = '59fd04fb0e972d1885c99f0cc80a581b';
export default node;
