import React from "react";
import {Screen} from "../../core/components/screen/Screen";
import {GradientBackgroundHeader} from "../../core/components/screen/GradientBackground";
import {FeedbackButton} from "../../feedback/controllers/FeedbackButton";
import {ManagerMainPage} from "./ManagerMainPage";
import {graphql} from "babel-plugin-relay/macro";
import {useLazyLoadQuery} from "react-relay";
import {AccountManager_Query} from "../../../__generated__/AccountManager_Query.graphql";
import {MainSidebar} from "../../core/components/sidebar/MainSidebar";

const QUERY = graphql`
    query AccountManager_Query  {
        Viewer {
            CustomerMessageV2 {
                CustomerMessageInformation {
                    id
                    numUnseenCustomerMessages
                }
            }
            License {
                NewLicensesInAccount {
                    id
                    newLicenses
                }
                LicenseInAccountContainer {
                    ...ManagerMainPage_LicenseInAccountContainerFragment
                }
            }
        }
    }`

export const AccountManager = () => {
    const query = useLazyLoadQuery<AccountManager_Query>(QUERY, {}, {fetchPolicy: "network-only"})

    return <Screen
        sidebarSlot={<MainSidebar
            numUnseenCustomerMessages={query.Viewer.CustomerMessageV2.CustomerMessageInformation.numUnseenCustomerMessages}
            hasNewLicensesInAccount={query.Viewer.License.NewLicensesInAccount.newLicenses}/>}
        headerBarBackgroundSlot={
            <GradientBackgroundHeader
                buttonSlot={<div className="d-flex">
                    <FeedbackButton screen={"account-manager"}/>
                </div>}
                height={250}/>

        }
        headerMargin={60}
    >
        <ManagerMainPage licensesInAccountContainerFragmentRef={query.Viewer.License.LicenseInAccountContainer}/>
    </Screen>
}

