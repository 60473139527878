import React, {ReactNode} from "react";
import "./payment-option-wrapper.scss"
import {Radio} from "../../../../../core/components/form/Radio";

interface OwnProps {
    image?: string;
    title: string;
    explanation: ReactNode;
    isSelected: boolean;
    error?: string;
    children?: ReactNode;
    onSelected: () => void;
}

export const PaymentOptionWrapper = ({
                                         image,
                                         title,
                                         explanation,
                                         isSelected,
                                         children,
                                         error,
                                         onSelected
                                     }: OwnProps) => {

    return <div className="form-group d-flex flex-column payment-option-wrapper">
        <div className="always-visible has-columns">
            <div className="radio-wrapper" onClick={() => onSelected()}>
                <Radio isChecked={isSelected} onToggle={() => {
                    if (!isSelected) {
                        onSelected();
                    }
                }}/>
            </div>
            <div className="image-wrapper d-flex justify-content-center">
                {image ? <img src={image} alt={title}/> : null}
            </div>
            <div className="tk-academy-large tk-academy-bold tk-black">
                {title}
            </div>
        </div>
        {isSelected ? <div className="collapsible-wrapper mt-2">
            <div className={"stripe-wrapper has-columns " + (children ? "mb-3" : "")}>
                <div/>
                <div/>
                <div>
                    {children}
                </div>
            </div>
            <div className="explanation-wrapper has-columns">
                <div/>
                <div/>
                <div className="tk-academy-text">
                    {explanation}
                </div>
            </div>
            {error ? (
                <div
                    className="error-wrapper tk-academy-small-text tk-color-orange validation-error has-columns mt-3">
                    <div/>
                    <div/>
                    <div>
                        {error}
                    </div>
                </div>
            ) : null}
        </div> : null}
    </div>
}
