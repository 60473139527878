/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseAd_CourseFragment = {
    readonly mainProduct: string | null;
    readonly mainDiscountCode: string | null;
    readonly " $refType": "CourseAd_CourseFragment";
};
export type CourseAd_CourseFragment$data = CourseAd_CourseFragment;
export type CourseAd_CourseFragment$key = {
    readonly " $data"?: CourseAd_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseAd_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseAd_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mainProduct",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mainDiscountCode",
      "storageKey": null
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = 'a2026532b099e568a50ae6eed25b8d83';
export default node;
