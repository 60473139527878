/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RandomPhrasing_PhrasingAlternativesFragment = {
    readonly alternatives: ReadonlyArray<string>;
    readonly " $refType": "RandomPhrasing_PhrasingAlternativesFragment";
};
export type RandomPhrasing_PhrasingAlternativesFragment$data = RandomPhrasing_PhrasingAlternativesFragment;
export type RandomPhrasing_PhrasingAlternativesFragment$key = {
    readonly " $data"?: RandomPhrasing_PhrasingAlternativesFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"RandomPhrasing_PhrasingAlternativesFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RandomPhrasing_PhrasingAlternativesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alternatives",
      "storageKey": null
    }
  ],
  "type": "PhrasingAlternatives",
  "abstractKey": null
};
(node as any).hash = '68d60eaecc11d68b7f9f4bf3402aaeda';
export default node;
