import React from "react";
import "./search-field.scss"
import debounce from 'lodash/debounce'

interface OwnProps {
    text?: string;
    textChanged: (newValue: string) => void;
}

export const SearchField = ({
                                text,
                                textChanged
                            }: OwnProps) => {

    const debounceHandler = debounce((value: string) => textChanged(value), 500)

    return <div className="d-flex align-items-center ml-auto search-field-wrapper position-relative">
        <input placeholder="Glossar durchsuchen" className="ml-auto pl-3 tk-academy-text" type="text" value={text}
               onChange={event => debounceHandler(event.target.value)}/>
        <div className="icon-a_glas position-absolute right-20"/>
    </div>;
}