export const lightBackground = "#f3f5f8";
export const darkBlue = "#031d3c";
export const waterBlue = "#184276";
export const keyBlue = "#247ac7";
export const keyBlue30 = "#a7cbe8";
export const keyBlue20 = "#d3e5f4";
export const waterBlue10 = "#e7ebf1";
export const waterBlue20 = "#d0d9e3";
export const waterBlue40 = "#a2b3c8";
export const waterBlue80 = "#466791";
export const keyGreen = "#4ace7f";
export const keyGreen20 = "#dcf8e7";
export const orange = "#ea561c";
export const orange20 = "#fedecc";
export const white = "#ffffff";
export const black = "#000000";

export const THEME = {
    palette: {
        lightBackground: lightBackground,
        darkBlue: darkBlue,
        waterBlue: waterBlue,
        keyBlue: keyBlue,
        keyBlue30: keyBlue30,
        keyBlue20: keyBlue20,
        waterBlue10: waterBlue10,
        waterBlue20: waterBlue20,
        waterBlue40: waterBlue40,
        waterBlue80: waterBlue80,
        keyGreen: keyGreen,
        keyGreen20: keyGreen20,
        orange: orange,
        orange20: orange20,
        white: white,
        black: black,
    }
};
