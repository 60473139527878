import React, {ReactNode} from "react";
import styled from "styled-components";
import {tkBackgrounds} from "../core/style/tkBackgrounds";

interface OwnProps {
    children?: ReactNode;
}

export const LearnReviewSecondaryContentArea = ({children}: OwnProps) => {
    return children ? <Container className="flex-grow-1 d-flex">{children}</Container> : <Container/>
}

const Container = styled.div`
    ${tkBackgrounds.waterBlue10}
`