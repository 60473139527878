import React, {useCallback, useState} from "react";
import {API_LOGIN, apiCallSucceededAction, LoginResponse, selectCurrentUser} from "@thekeytechnology/framework-react";
import {useDispatch, useSelector} from "react-redux";
import {AcceptInvitation} from "./AcceptInvitation";
import {graphql} from "babel-plugin-relay/macro";
import {RegistrationData, RegistrationForm} from "../registration/RegistrationForm";
import {useMutation} from "react-relay";
import {InvitationScreen_SignUpWithInvitationMutation} from "../../../../__generated__/InvitationScreen_SignUpWithInvitationMutation.graphql";
import {trackRegistration} from "../../../analytics/analytics";
import * as Sentry from "@sentry/react";
import {InvitationScreen_LoginMutation} from "../../../../__generated__/InvitationScreen_LoginMutation.graphql";
import {LoginForm} from "../login/LoginForm";
import {LoginScreenContainer} from "../login/LoginScreenContainer";

const LOGIN_MUTATION = graphql`
    mutation InvitationScreen_LoginMutation($login: LoginInput!) {
        Auth {
            login(input: $login) {
                login {
                    user {
                        id
                        name
                        email
                    }
                    accounts {
                        accountId
                        name
                        roles
                    }
                    rolesInCurrentAccount
                    token
                }
            }
        }
    }
`;

const SIGN_UP_WITH_INVITATION_MUTATION = graphql`
    mutation InvitationScreen_SignUpWithInvitationMutation($input: ExtendedRegistrationInputInput!) {
        Manager {
            signUpWithInvitation(input: $input) {
                email
                firstName
                lastName
                userId
            }
        }
    }`

interface State {
    registrationSucceeded: boolean
    showInvitationLogin: boolean
    shownComponent: string
}

interface OwnProps {
    token: string
}

export const InvitationScreen = ({token}: OwnProps) => {
    const dispatch = useDispatch()

    const [state, setState] = useState<State>({
        registrationSucceeded: false,
        showInvitationLogin: true,
        shownComponent: 'login'
    })

    const currentUser = useSelector(selectCurrentUser)

    const [login, loginIsInFlight] = useMutation<InvitationScreen_LoginMutation>(LOGIN_MUTATION)

    const [register, registerIsInFlight] = useMutation<InvitationScreen_SignUpWithInvitationMutation>(SIGN_UP_WITH_INVITATION_MUTATION)

    const doLogin = useCallback((email: string, password: string) => login({
        variables: {login: {email: email, password: password}},
        onCompleted: response => {
            dispatch(apiCallSucceededAction({apiType: API_LOGIN}, {
                token: response.Auth.login?.login.token,
                accounts: response.Auth.login?.login.accounts,
                user: {
                    id: response.Auth.login?.login.user.id,
                    entity: {
                        id: response.Auth.login?.login.user.id,
                        email: response.Auth.login?.login.user.email,
                        name: response.Auth.login?.login.user.name,
                        roles: response.Auth.login?.login.rolesInCurrentAccount
                    }
                }
            } as LoginResponse))
        }
        // eslint-disable-next-line
    }), [])


    const doRegister = useCallback((input: RegistrationData) => {
        register({
            variables: {
                input: {
                    adsOptIn: input.adsOptIn,
                    companySize: input.companySize,
                    email: input.email,
                    firstName: input.firstName,
                    industry: input.industry,
                    lastName: input.lastName,
                    password: input.password,
                    position: input.position,
                    token: token
                }
            },
            onCompleted: (response) => {
                setState(state => ({...state, registrationSucceeded: true}))

                try {
                    if(response.Manager.signUpWithInvitation?.userId) trackRegistration(input.email, input.firstName, input.lastName, response.Manager.signUpWithInvitation.userId)
                } catch (e) {
                    Sentry.captureException(e)
                }
            }
        })
        // eslint-disable-next-line
    }, [token])

    const shownComponent = (_ => {
        switch (state.shownComponent) {
            case 'registration':
                return <RegistrationForm
                    doRegister={doRegister}
                    isInFlight={registerIsInFlight}
                    registrationSucceeded={state.registrationSucceeded}
                    switchToLogin={() => setState(state => ({
                        ...state,
                        shownComponent: 'login'
                    }))}
                />
            default:
                return <LoginForm
                    login={doLogin}
                    loginIsInFlight={loginIsInFlight}
                    switchToRegistration={() => setState(state => ({
                        ...state,
                        shownComponent: 'registration'
                    }))}
                    showForgotPassword={false}
                />
        }
    })()

    return <LoginScreenContainer>
        {!currentUser ? shownComponent : <AcceptInvitation token={token}/>}
    </LoginScreenContainer>
}