import React from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {showCelebrationAction} from "@thekeytechnology/thekey-academy-frontend-library";
import {AcademyButton} from "../../core/components/button/AcademyButton";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {AtEndOfCourseButton_CourseFragment$key} from "../../../__generated__/AtEndOfCourseButton_CourseFragment.graphql";

const COURSE_FRAGMENT = graphql`
    fragment AtEndOfCourseButton_CourseFragment on Course {
        attributes
        containerMeta {
            title
        }
    }`

interface OwnProps {
    courseFragmentRef: AtEndOfCourseButton_CourseFragment$key;
}

export const AtEndOfCourseButton = ({courseFragmentRef}: OwnProps) => {

    const {t} = useTranslation("state")

    const dispatch = useDispatch()

    const course = useFragment<AtEndOfCourseButton_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef);

    return <AcademyButton
        icon={"icon-a_tick"}
        text={t("at-end-of-course-button.label")}
        onClick={() => {
            dispatch(showCelebrationAction({
                title: t("at-end-of-course-button.celebration.title", {courseName: course.containerMeta.title}),
                explanation: t("at-end-of-course-button.celebration.explanation", {courseName: course.containerMeta.title}),
                text: t("at-end-of-course-button.celebration.text", {courseName: course.containerMeta.title}),
                image: "special-course-done"
            }))
        }
        }/>
}
