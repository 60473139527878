import React from "react";
import {useFragment, useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {
    MonthlyPaymentCategory_OrderFragment$key
} from "../../../../../__generated__/MonthlyPaymentCategory_OrderFragment.graphql";
import {paymentMethodIsMonthlyV3} from "../../utils/payment-method-utils";
import {
    MonthlyPaymentCategory_ChangePaymentMethodMutation
} from "../../../../../__generated__/MonthlyPaymentCategory_ChangePaymentMethodMutation.graphql";
import {CurrencyDisplay} from "../../../../core/components/currency/CurrencyDisplay";
import {
    PaymentOptionCategoryContainer,
    PaymentOptionContainer,
    PaymentOptionHeaderContainer,
    PaymentOptionHeading,
    PaymentOptionHeadingPrice,
    PaymentOptionsContainer
} from "./common/StyledComponents";
import {LoadingOverlay} from "../cart/LoadingOverlay";

const ORDER_FRAGMENT = graphql`
    fragment MonthlyPaymentCategory_OrderFragment on Order {
        id
        selectedPaymentMethod {
            paymentMethodId
            selectedPaymentMethodType
            ... on MonthlySelectedPaymentMethod {
                chosenOption {
                    rateCount
                }
            }
        }
        allowedPaymentMethods

        cart {
            cartTotals {
                monthlyOptions {
                    totalAmount {
                        grossPrice
                    }
                    downPayment {
                        netPrice
                        grossPrice
                        taxRatePercentage
                    }
                    financedAmount{
                        netPrice
                        grossPrice
                        taxRatePercentage
                    }
                    monthlyRate{
                        netPrice
                        grossPrice
                        taxRatePercentage
                    }
                    rateCount
                }
            }
        }
    }
`;

const CHANGE_PAYMENT_METHOD_MUTATION = graphql`
    mutation MonthlyPaymentCategory_ChangePaymentMethodMutation($input: ChangePaymentMethodInput!) {
        Billing {
            changePaymentMethod(input: $input) {
                order {
                    ...OrderFlowScreen_OrderFragment
                }
            }
        }
    }
`;

interface OwnProps {
    orderFragmentRef: MonthlyPaymentCategory_OrderFragment$key
}

export const MonthlyPaymentCategory = ({orderFragmentRef}: OwnProps) => {
    const order = useFragment<MonthlyPaymentCategory_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)

    const [changePaymentMethod, isInFlight] = useMutation<MonthlyPaymentCategory_ChangePaymentMethodMutation>(CHANGE_PAYMENT_METHOD_MUTATION)


    const allowedMonthlyPaymentMethod = order.allowedPaymentMethods.find(paymentMethodIsMonthlyV3);
    if (order.cart.cartTotals.monthlyOptions.length === 0 || allowedMonthlyPaymentMethod === undefined) {
        return <PaymentOptionCategoryContainer>
            Leider hat eine Bonitätsprüfung ergeben, dass wir Dir keine monatliche Zahlung anbieten können. Das kann
            auch passieren, wenn Du
            im vorherigen Schritt eine falsche Adresse oder Daten in ein falsches Feld eingegeben hast. Klicke auf den
            Zurück-Button, überprüfe die Daten und versuche es nochmal. Solltest Du Dir das nicht erklären können, wende
            Dich bitte an <a href="mailto:support@thekey.academy">support@thekey.academy</a>
        </PaymentOptionCategoryContainer>;
    }

    const rateCountsFromHighestToLowest = order.cart.cartTotals.monthlyOptions.map(x => x.rateCount).sort((a, b) => b - a);
    const highestAvailableRateCount = rateCountsFromHighestToLowest.find(() => true)
    const cheapestMonthlyOption = order.cart.cartTotals.monthlyOptions.find(x => x.rateCount === highestAvailableRateCount)
    const isOpen = order.selectedPaymentMethod?.selectedPaymentMethodType === "monthly"

    return <>
        <PaymentOptionCategoryContainer onClick={() => {
            if (!isOpen) {
                changePaymentMethod({
                    variables: {
                        input: {
                            orderId: order.id,
                            paymentMethod: allowedMonthlyPaymentMethod,
                            monthlyOption: highestAvailableRateCount
                        }
                    }
                })
            }
        }}>
            <PaymentOptionHeaderContainer>
                <PaymentOptionHeading>Monatliche Zahlung</PaymentOptionHeading>


                {cheapestMonthlyOption?.monthlyRate.grossPrice && !order.selectedPaymentMethod?.chosenOption &&
                    <PaymentOptionHeadingPrice>
                        ab <CurrencyDisplay value={cheapestMonthlyOption?.monthlyRate.grossPrice}/> pro Monat
                    </PaymentOptionHeadingPrice>}
            </PaymentOptionHeaderContainer>

            {isOpen && <PaymentOptionsContainer>
                {rateCountsFromHighestToLowest.map(rateCount => {
                    const monthlyOption = order.cart.cartTotals.monthlyOptions.find(x => x.rateCount === rateCount)!

                    return <PaymentOptionContainer
                        key={rateCount}
                        isSelected={monthlyOption.rateCount === order.selectedPaymentMethod?.chosenOption?.rateCount}
                        onClick={() => {
                            changePaymentMethod({
                                variables: {
                                    input: {
                                        orderId: order.id,
                                        paymentMethod: allowedMonthlyPaymentMethod,
                                        monthlyOption: monthlyOption.rateCount
                                    }
                                }
                            })
                        }
                        }>
                        <PaymentOptionHeaderContainer>
                            <PaymentOptionHeading>In {rateCount} monatlichen Zahlungen</PaymentOptionHeading>
                            <PaymentOptionHeadingPrice><CurrencyDisplay
                                value={monthlyOption.monthlyRate.grossPrice}/> pro
                                Monat</PaymentOptionHeadingPrice>
                        </PaymentOptionHeaderContainer>
                        {!!monthlyOption.downPayment.grossPrice &&
                            <small>
                                Es wird eine Anzahlung in Höhe von <CurrencyDisplay
                                value={monthlyOption.downPayment.grossPrice}/> zeitgleich mit der 1. monatlichen
                                Zahlung fällig.<br/>
                            </small>
                        }
                        {rateCount <= 12 ? <small>
                            Dadurch ergibt sich eine Gesamtzahlsumme von <CurrencyDisplay
                            value={monthlyOption.totalAmount.grossPrice}/>
                        </small> : <small>
                            Mit dem Aufschlag für monatliche Zahlweise ergibt sich eine Gesamtzahlsumme
                            von <CurrencyDisplay value={monthlyOption.totalAmount.grossPrice}/>
                        </small>

                        }

                    </PaymentOptionContainer>
                })}

            </PaymentOptionsContainer>}
        </PaymentOptionCategoryContainer>
        {isInFlight && <LoadingOverlay/>}
    </>
}
