/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseCard_CourseStateFragment = {
    readonly " $fragmentRefs": FragmentRefs<"UpperCourseCard_CourseStateFragment">;
    readonly " $refType": "CourseCard_CourseStateFragment";
};
export type CourseCard_CourseStateFragment$data = CourseCard_CourseStateFragment;
export type CourseCard_CourseStateFragment$key = {
    readonly " $data"?: CourseCard_CourseStateFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseCard_CourseStateFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseCard_CourseStateFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpperCourseCard_CourseStateFragment"
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};
(node as any).hash = 'f8ffdca889b848354f805729d5b4b920';
export default node;
