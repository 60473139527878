import {combineEpics} from "redux-observable";
import {extendedAcademyAuthModuleEpics$} from "./auth/epics";
import {authModuleEpics$, dialogModuleEpics$, userModuleEpics$} from "@thekeytechnology/framework-react";
import {stateModuleEpics$} from "./state/epics";
import {
    academyAuthModuleEpics$,
    achievementModuleEpics$,
    additionalContentModuleEpics$,
    billingModuleV2epics$,
    certificateModuleEpics$,
    courseModuleEpics$,
    customerMessageModuleEpics$,
    faqsModuleEpics$,
    feedbackModuleEpic$,
    gamificationModuleEpics$,
    glossaryModuleEpics$,
    learnModuleEpics$,
    licensesModuleEpics$,
    newsModuleEpics$,
    noteModuleEpics$,
    onboardingModuleEpics$,
    qnaModuleEpics$,
    reviewModuleEpics$,
    singleElementModuleEpics$,
    submissionModuleEpics$,
    userSettingsModuleEpics$
} from "@thekeytechnology/thekey-academy-frontend-library"
import {additionalSubmissionModuleEpics} from "./submission/epics/additional-submission-module-epcis";
import {additionalAchievementModuleEpics$} from "./achievements/epics";
import {customAPiModuleEpics$} from "./api/epics";
import {additionalCourseEpics$} from "./course/epics/addiitional-course-epics";
import {additionalBillingModuleV2Epics$} from "./billing/v2/epics";
import {managerModuleEpics$} from "./manager/epics";

export const appEpics = combineEpics(
    customAPiModuleEpics$,
    authModuleEpics$,
    academyAuthModuleEpics$,
    extendedAcademyAuthModuleEpics$,
    stateModuleEpics$,
    gamificationModuleEpics$,
    newsModuleEpics$,
    additionalContentModuleEpics$,
    faqsModuleEpics$,
    customerMessageModuleEpics$,
    submissionModuleEpics$,
    additionalSubmissionModuleEpics,
    achievementModuleEpics$,
    additionalAchievementModuleEpics$,
    reviewModuleEpics$,
    singleElementModuleEpics$,
    learnModuleEpics$,
    userSettingsModuleEpics$,
    noteModuleEpics$,
    qnaModuleEpics$,
    glossaryModuleEpics$,
    billingModuleV2epics$,
    additionalBillingModuleV2Epics$,
    feedbackModuleEpic$,
    certificateModuleEpics$,
    additionalCourseEpics$,
    courseModuleEpics$,
    onboardingModuleEpics$,
    licensesModuleEpics$,
    managerModuleEpics$,
    userModuleEpics$,
    dialogModuleEpics$
);
