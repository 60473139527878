/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LicensePoolDisplay_RequestLicenseMutationVariables = {
    courseId: string;
    licensePoolId: string;
};
export type LicensePoolDisplay_RequestLicenseMutationResponse = {
    readonly License: {
        readonly requestLicense: {
            readonly licensePool: {
                readonly node: {
                    readonly " $fragmentRefs": FragmentRefs<"LicensePoolDisplay_LicensePoolFragment">;
                };
            };
        } | null;
    };
};
export type LicensePoolDisplay_RequestLicenseMutation = {
    readonly response: LicensePoolDisplay_RequestLicenseMutationResponse;
    readonly variables: LicensePoolDisplay_RequestLicenseMutationVariables;
};



/*
mutation LicensePoolDisplay_RequestLicenseMutation(
  $courseId: ID!
  $licensePoolId: ID!
) {
  License {
    requestLicense(input: {courseId: $courseId, licensePoolId: $licensePoolId}) {
      licensePool {
        node {
          ...LicensePoolDisplay_LicensePoolFragment
          id
        }
      }
    }
  }
}

fragment ApplyLicenseButton_LicensePoolFragment on LicensePool {
  id
  isMainPool
  applicationRuleStatus(courseId: $courseId)
  account {
    name
    id
  }
}

fragment LicensePoolDisplay_LicensePoolFragment on LicensePool {
  id
  account {
    name
    id
  }
  applicationRuleStatus(courseId: $courseId)
  ...ApplyLicenseButton_LicensePoolFragment
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "courseId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "licensePoolId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "courseId",
  "variableName": "courseId"
},
v2 = [
  {
    "fields": [
      (v1/*: any*/),
      {
        "kind": "Variable",
        "name": "licensePoolId",
        "variableName": "licensePoolId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LicensePoolDisplay_RequestLicenseMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LicenseMutations",
        "kind": "LinkedField",
        "name": "License",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "RequestLicensePayload",
            "kind": "LinkedField",
            "name": "requestLicense",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LicensePoolsEdge",
                "kind": "LinkedField",
                "name": "licensePool",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LicensePool",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "LicensePoolDisplay_LicensePoolFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LicensePoolDisplay_RequestLicenseMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LicenseMutations",
        "kind": "LinkedField",
        "name": "License",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "RequestLicensePayload",
            "kind": "LinkedField",
            "name": "requestLicense",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LicensePoolsEdge",
                "kind": "LinkedField",
                "name": "licensePool",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LicensePool",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "account",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          (v1/*: any*/)
                        ],
                        "kind": "ScalarField",
                        "name": "applicationRuleStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isMainPool",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2094209a73392a88f7537f04d087e3a1",
    "id": null,
    "metadata": {},
    "name": "LicensePoolDisplay_RequestLicenseMutation",
    "operationKind": "mutation",
    "text": "mutation LicensePoolDisplay_RequestLicenseMutation(\n  $courseId: ID!\n  $licensePoolId: ID!\n) {\n  License {\n    requestLicense(input: {courseId: $courseId, licensePoolId: $licensePoolId}) {\n      licensePool {\n        node {\n          ...LicensePoolDisplay_LicensePoolFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment ApplyLicenseButton_LicensePoolFragment on LicensePool {\n  id\n  isMainPool\n  applicationRuleStatus(courseId: $courseId)\n  account {\n    name\n    id\n  }\n}\n\nfragment LicensePoolDisplay_LicensePoolFragment on LicensePool {\n  id\n  account {\n    name\n    id\n  }\n  applicationRuleStatus(courseId: $courseId)\n  ...ApplyLicenseButton_LicensePoolFragment\n}\n"
  }
};
})();
(node as any).hash = '5a7272266600f0a46dde24e009fbb5d5';
export default node;
