export const StripeStyle = {
    base: {
        color: "#184276",
        padding: "10px",
        "::placeholder": {
            color: "#466791"
        }
    },
    invalid: {
        color: "#ea561c"
    }
}
