export const tkTypography = {
    small: `
        font-size: 12px;
        line-height: 1.67;
        font-weight: 400;
    `,
    smallBold: `
        font-size: 12px;
        line-height: 1.67;
        font-weight: 700;
    `,
    regular: `
        font-size: 14px;
        line-height: 1.43;
        font-weight: 400;
    `,
    regularBold: `
        font-size: 14px;
        line-height: 1.43;
        font-weight: 700;
    `,
    large: `
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400;
    `,
    largeBold: `
        font-size: 16px;
        line-height: 1.5;
        font-weight: 700;
    `,
    largerExtraBold: `
        font-size: 22px;
        line-height: 1.5;
        font-weight: 800;
    `,
}