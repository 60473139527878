import React from "react";
import "./expert-list.scss"
import {ExpertItem} from "./ExpertItem";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {ExpertList_CourseFragment$key} from "../../../../../../__generated__/ExpertList_CourseFragment.graphql";
import {ExpertList_NormalLessonFragment$key} from "../../../../../../__generated__/ExpertList_NormalLessonFragment.graphql";

const EXPERT_LIST_COURSE_FRAGMENT = graphql`
    fragment ExpertList_CourseFragment on Course {
        experts {
            id
            ...ExpertItem_ExpertFragment
        }
    }`

const EXPERT_LIST_NORMAL_LESSON_FRAGMENT = graphql`
    fragment ExpertList_NormalLessonFragment on NormalLesson {
        additionalExperts {
            id
            ...ExpertItem_ExpertFragment
        }
}`

interface OwnProps {
    courseFragmentRef: ExpertList_CourseFragment$key;
    normalLessonFragmentRef?: ExpertList_NormalLessonFragment$key;
}

export const ExpertList = ({courseFragmentRef, normalLessonFragmentRef}: OwnProps) => {

    const course = useFragment<ExpertList_CourseFragment$key>(EXPERT_LIST_COURSE_FRAGMENT, courseFragmentRef)

    const normalLesson = useFragment<ExpertList_NormalLessonFragment$key>(EXPERT_LIST_NORMAL_LESSON_FRAGMENT, normalLessonFragmentRef || null)

    const expertsInCourse = course.experts;
    const additionalExpertsFromNormalLessonNotInCourse = normalLesson?.additionalExperts.filter(aE => !expertsInCourse.map(eIC => eIC.id).includes(aE.id)) || [];
    const experts = [...expertsInCourse, ...additionalExpertsFromNormalLessonNotInCourse];

    return <div className="w-100 tk-academy-card p-5 mb-5 expert-list" id="experts">
        <div className="tk-academy-text tk-academy-bold tk-color-waterblue-80 mb-4">Experten</div>
        {experts.map(expert => {
            return <ExpertItem key={expert.id} expertFragmentRef={expert}/>
        })}
    </div>
}
