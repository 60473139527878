/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseAttributes = "earlyBird" | "hidden" | "new" | "published" | "%future added value";
export type CourseProgressBar_CourseStateFragment = {
    readonly course: {
        readonly attributes: ReadonlyArray<CourseAttributes>;
        readonly isFreeCourse: boolean;
        readonly lessons: ReadonlyArray<{
            readonly isPartOfTrial: boolean;
        }>;
    };
    readonly isAtEndOfTrial: boolean;
    readonly isBought: boolean;
    readonly isDone: boolean;
    readonly progress: {
        readonly progressPercentage: number;
    };
    readonly " $refType": "CourseProgressBar_CourseStateFragment";
};
export type CourseProgressBar_CourseStateFragment$data = CourseProgressBar_CourseStateFragment;
export type CourseProgressBar_CourseStateFragment$key = {
    readonly " $data"?: CourseProgressBar_CourseStateFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseProgressBar_CourseStateFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseProgressBar_CourseStateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "course",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "attributes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isFreeCourse",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "lessons",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isPartOfTrial",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAtEndOfTrial",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBought",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Progress",
      "kind": "LinkedField",
      "name": "progress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "progressPercentage",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};
(node as any).hash = '2f496e3b29cb091ca7507578ef50e6e2';
export default node;
