import { MenuItem, SubItemFactory } from "./Menu";
import { NavLink } from "react-router-dom";
import React from "react";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

export const onlyLinkSubitemFactory: SubItemFactory = (subRoute, collapsed) => {
    return <OnlyLinkSubitem key={subRoute.key} subRoute={subRoute} collapsed={collapsed}/>
}

interface OwnProps {
    subRoute: MenuItem;
    collapsed?: boolean
}

const OnlyLinkSubitem = ({subRoute, collapsed}: OwnProps) => {
    const {t} = useTranslation("menu");

    const LinkContent = <li className="d-flex ml-4 pt-3 pb-3 d-flex pl-2 tk-academy-small-text only-link-subitem">
                                    <span
                                        className={"tk-academy-normal icon " + (subRoute.icon ? subRoute.icon : "") + (!collapsed ? " mr-2" : "")}/>
        <span>{t(`routes.${subRoute.key}`)}</span>
    </li>

    if (subRoute.path.includes("#")) {
        return <HashLink
            smooth
            key={subRoute.key} to={subRoute.path}
            className="tk-key-blue-30">
            {LinkContent}
        </HashLink>
    } else {
        return <NavLink key={subRoute.key} to={subRoute.path}
                        exact
                        activeClassName={"tk-white"}
                        className="tk-key-blue-30">
            {LinkContent}
        </NavLink>
    }
}
