/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FailedOrderElement_FailedElementFragment = {
    readonly element: {
        readonly title: string;
        readonly orderables?: ReadonlyArray<{
            readonly text: string;
            readonly image: {
                readonly url: string | null;
            } | null;
        }>;
    };
    readonly mistakes: {
        readonly explanations?: ReadonlyArray<{
            readonly userIndex: number;
        }>;
    };
    readonly " $refType": "FailedOrderElement_FailedElementFragment";
};
export type FailedOrderElement_FailedElementFragment$data = FailedOrderElement_FailedElementFragment;
export type FailedOrderElement_FailedElementFragment$key = {
    readonly " $data"?: FailedOrderElement_FailedElementFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"FailedOrderElement_FailedElementFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FailedOrderElement_FailedElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "element",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Orderable",
              "kind": "LinkedField",
              "name": "orderables",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "text",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "File",
                  "kind": "LinkedField",
                  "name": "image",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "OrderElement",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "mistakes",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderableExplanation",
              "kind": "LinkedField",
              "name": "explanations",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "userIndex",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "OrderMistakes",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FailedElement",
  "abstractKey": null
};
(node as any).hash = '37eedd872477ce7b1c28d627aabba086';
export default node;
