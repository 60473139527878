import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_FETCH_LESSON = "fetch-lesson";

export const fetchLessonAction = (id: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FETCH_LESSON,
            callType: API_FETCH_LESSON
        },
        payload: id
    } as ApiCallAction<string>;
};
