import {Action} from "redux";
import {ApiCallSucceededAction, isApiSuccess, LogoutAction} from "@thekeytechnology/framework-react";
import {LessonContainer} from "../model/LessonContainer";
import {API_FETCH_LESSON} from "../actions/fetch-lesson";

export interface LessonModuleState {
    currentLesson?: LessonContainer
}

export const initialState: LessonModuleState = {
    currentLesson: undefined
};

export function lessonModuleReducer(state = initialState, action: Action): LessonModuleState {
    if (action.type === LogoutAction) {
        return initialState;
    }

    if (isApiSuccess(API_FETCH_LESSON)(action)) {
        return {
            ...state,
            currentLesson: (action as ApiCallSucceededAction<LessonContainer>).payload
        }
    }
    return state;
}
