import React from "react";
import {
    multipleChoiceElementGraphQLToMultipleChoiceElement,
    useMultipleChoiceLogicV2
} from "@thekeytechnology/thekey-academy-frontend-library";
import "./multiple-choice-element.scss";
import {BaseElementDisplay} from "../common/BaseElementDisplay";
import {RandomPhrasing} from "../../../core/components/random/RandomPhrasing";
import {LightboxImage} from "../../../core/components/image/LightboxImage";
import {Checkbox} from "../../../core/components/form/Checkbox";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {MultipleChoiceElementDisplay_MultipleChoiceElementFragment$key} from "../../../../__generated__/MultipleChoiceElementDisplay_MultipleChoiceElementFragment.graphql";

const MULTIPLE_CHOICE_ELEMENT_DISPLAY_MULTIPLE_CHOICE_ELEMENT_FRAGMENT = graphql`
    fragment MultipleChoiceElementDisplay_MultipleChoiceElementFragment on MultipleChoiceElement {
        allowMultipleAnswers
        allCorrectAnswersNeedToBeGiven
        answerOptions {
            answer {
                ...RandomPhrasing_PhrasingAlternativesFragment
            }
            image {
                url
            }
            isCorrect
        }
        elementType
        generalSettings {
            points
        }
        id
        image {
            url
        }
        question {
            ...RandomPhrasing_PhrasingAlternativesFragment
        }
    }`

const alphabet = "ABCDEFGHIHKL"

interface OwnProps {
    multipleChoiceElementFragmentRef: MultipleChoiceElementDisplay_MultipleChoiceElementFragment$key;
}

export const MultipleChoiceElementDisplay = ({
                                                 multipleChoiceElementFragmentRef
                                             }: OwnProps) => {

    const element = useFragment<MultipleChoiceElementDisplay_MultipleChoiceElementFragment$key>(MULTIPLE_CHOICE_ELEMENT_DISPLAY_MULTIPLE_CHOICE_ELEMENT_FRAGMENT, multipleChoiceElementFragmentRef)

    const [checkedAnswerIndices, convertedIndices, toggleAnswer] = useMultipleChoiceLogicV2(multipleChoiceElementGraphQLToMultipleChoiceElement(element))


    let chooseText: string;
    if (element.allowMultipleAnswers && element.allCorrectAnswersNeedToBeGiven) {
        chooseText = "Bitte wähle <strong>alle</strong> richtigen Antworten aus"
    } else if (element.allowMultipleAnswers && !element.allCorrectAnswersNeedToBeGiven) {
        chooseText = "Bitte wähle <strong>mindestens eine</strong> richtige Antwort aus"
    } else {
        chooseText = "Bitte wähle <strong>die richtige Antwort</strong> aus"
    }

    return <BaseElementDisplay
        className="multiple-choice-element"
        heading={<RandomPhrasing phrasingAlternativesFragmentRef={element.question}
                                 className="tk-academy-extra-large-heading-bold mb-5"/>}
        elementSpecificSlot={<>
            <div className="tk-academy-text mb-3 tk-color-waterblue-80" dangerouslySetInnerHTML={{__html: chooseText}}/>

            {element.image?.url ? <div className="image-wrapper">
                <LightboxImage className="element-image" image={element.image.url}/>
            </div> : null}

            <div className="answers">
                {convertedIndices.map((answerIndex: number, index: number) => {
                    const answer = element.answerOptions[answerIndex]

                    if (!answer) {
                        return null;
                    }

                    const isSelected = checkedAnswerIndices.includes(answerIndex);

                    return answer ?
                        <div
                            className={`answer mb-3 pt-3 pb-3 d-flex align-items-center ${isSelected ? "checked" : ""} ${answer.image?.url ? "has-image" : ""}`}
                            onClick={() => toggleAnswer(answerIndex)}
                            key={index}>
                            <div
                                className="answer-index pl-4 pr-3 tk-color-darkblue tk-academy-bold">
                                {alphabet[index]}
                            </div>
                            <div className="answer-text flex-grow-1">
                                {answer.image?.url ?
                                    <div className="answer-image-wrapper">
                                        <LightboxImage image={answer.image.url} className="element-image"/>
                                    </div> : null}

                                <RandomPhrasing className="tk-academy-text tk-color-darkblue"
                                                phrasingAlternativesFragmentRef={answer.answer}/>
                            </div>
                            <Checkbox
                                className=" pl-3 pr-4"
                                isChecked={isSelected}
                                onToggle={() => toggleAnswer(answerIndex)}/>
                        </div> : null;
                })}
            </div>
        </>}/>
}
