import React from "react";
import AddButtonImage from "./add-button.png";
import "./add-button.scss"

interface OwnProps {
    onAdd: () => void;
}

export const AddButton = ({onAdd}: OwnProps) => {
    return <div className="add-button" onClick={() => onAdd()}>
        <img alt="Frage hinzufügen" src={AddButtonImage}/>
    </div>
}
