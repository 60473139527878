/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CustomerMessageModal_CustomerMessageFragment = {
    readonly id: string;
    readonly customerMessageDefinition: {
        readonly pages: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly imageOpt: {
                        readonly url: string | null;
                    } | null;
                    readonly " $fragmentRefs": FragmentRefs<"CustomerMessagePage_CustomerMessageDefinitionPageFragment">;
                };
            } | null> | null;
        };
    } | null;
    readonly hasSeen: boolean;
    readonly " $refType": "CustomerMessageModal_CustomerMessageFragment";
};
export type CustomerMessageModal_CustomerMessageFragment$data = CustomerMessageModal_CustomerMessageFragment;
export type CustomerMessageModal_CustomerMessageFragment$key = {
    readonly " $data"?: CustomerMessageModal_CustomerMessageFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CustomerMessageModal_CustomerMessageFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerMessageModal_CustomerMessageFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerMessageDefinitionV2",
      "kind": "LinkedField",
      "name": "customerMessageDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomerMessageDefinitionPagesConnection",
          "kind": "LinkedField",
          "name": "pages",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CustomerMessageDefinitionPagesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CustomerMessageDefinitionPage",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "File",
                      "kind": "LinkedField",
                      "name": "imageOpt",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "url",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "CustomerMessagePage_CustomerMessageDefinitionPageFragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasSeen",
      "storageKey": null
    }
  ],
  "type": "CustomerMessageV2",
  "abstractKey": null
};
})();
(node as any).hash = 'fac77f3d33d0e3d5089cf1467e81c6b8';
export default node;
