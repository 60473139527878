import React from "react";
import {useTranslation} from "react-i18next";
import {ValidatedFieldV2} from "../../../../../core/components/form/ValidatedFieldV2";
import {Switch} from "../../../../../core/components/form/Switch";

interface OwnProps {
    formikConfig: any
}

export const SignupFormPartV3 = ({formikConfig}: OwnProps) => {
    const {t} = useTranslation("billing")

    return <>
        <h3 className="tk-academy-large tk-color-waterblue-80 tk-academy-bold mb-2">{t("checkout-form.create-account-heading")}</h3>
        <div className="tk-form-row">
            <div className="form-group">
                <ValidatedFieldV2<any, string>
                    name={"email"} formikConfig={formikConfig}
                    required={true}
                    component={(fieldValue, updateField, name, required) =>
                        <input name={name}
                               className="form-control default-input"
                               placeholder={t("checkout-form.email")}
                               autoComplete="username"
                               required={required}
                               value={fieldValue}
                               onChange={event => updateField(event.target.value)}
                        />
                    }/>
            </div>
            <div className="form-group">
                <ValidatedFieldV2<any, string>
                    name={"email2"} formikConfig={formikConfig}
                    required={true}
                    component={(fieldValue, updateField, name, required) =>
                        <input name={name}
                               className="form-control default-input"
                               placeholder={t("checkout-form.email2")}
                               autoComplete="username"
                               required={required}
                               value={fieldValue}
                               onChange={event => updateField(event.target.value)}
                        />
                    }/>
            </div>
        </div>
        <div className="tk-form-row">
            <div className="form-group">
                <ValidatedFieldV2<any, string>
                    name={"password"} formikConfig={formikConfig}
                    required={true}
                    component={(fieldValue, updateField, name, required) =>
                        <input name={name}
                               type="password"
                               className="form-control default-input"
                               placeholder={t("checkout-form.password")}
                               autoComplete="new-password"
                               required={required}
                               value={fieldValue}
                               onChange={event => updateField(event.target.value)}
                        />
                    }/>
            </div>
            <div className="form-group">
                <ValidatedFieldV2<any, string>
                    name={"password2"} formikConfig={formikConfig}
                    required={true}
                    component={(fieldValue, updateField, name, required) =>
                        <input name={name}
                               type="password"
                               className="form-control default-input"
                               placeholder={t("checkout-form.password2")}
                               autoComplete="new-password"
                               required={required}
                               value={fieldValue}
                               onChange={event => updateField(event.target.value)}
                        />
                    }/>
            </div>
        </div>
        <div className="mb-3">
            <ValidatedFieldV2<any, boolean>
                name={"legal"} formikConfig={formikConfig}
                required={true}
                component={(fieldValue, updateField) =>
                    <Switch title={<>Mit der Anmeldung stimmst du der <a
                        rel="noopener noreferrer"
                        href='https://www.thekey.academy/datenschutzerklaerung/'
                        target='_blank'>Datenschutzerklärung</a>, der  <a
                        href='https://www.thekey.academy/transparenzerklaerung/'
                        rel="noopener noreferrer"
                        target='_blank'>Transparenzerklärung</a>  und den <a
                        rel="noopener noreferrer"
                        href='https://www.thekey.academy/agbs/'
                        target='_blank'>Nutzungsbedingungen</a> zu.</>}
                            titleClassName={"tk-academy-large tk-color-waterblue ml-3"}
                            titleRight={true}
                            isChecked={fieldValue}
                            setChecked={updateField}/>
                }/>
        </div>
        <div className="form-group">
            <ValidatedFieldV2<any, boolean>
                name={"adsOptIn"} formikConfig={formikConfig}
                required={true}
                component={(fieldValue, updateField) =>
                    <Switch title="Ich freue mich, über Aktionen, Rabatte und Neuigkeiten per E-Mail informiert zu werden."
                            titleClassName={"tk-academy-large tk-color-waterblue ml-3"}
                            titleRight={true}
                            isChecked={fieldValue}
                            setChecked={updateField}/>
                }/>
        </div>
    </>
}
