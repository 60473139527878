import React from "react";

import "./payment-methods.scss"

import Visa from "./visa.png"
import MasterCard from "./mastercard.png"
import Sepa from "./sepa.png"
import Sofort from "./sofort.png"
import Giropay from "./giropay.png"
import Paypal from "./paypal.png"
import Amex from "./amex.png"
import Ratenzahlung from "./ratenzahlung.png"
import Rechnung from "./rechnung.png"
import {PaymentMethodIds, paymentMethodIsMonthly} from "@thekeytechnology/thekey-academy-frontend-library";

interface OwnProps {
    exclude?: PaymentMethodIds[]
    include?: PaymentMethodIds[]
}

interface PMDisplay {
    paymentMethod: PaymentMethodIds,
    alt: string;
    image: string
}

const PAYMENT_METHODS: PMDisplay[] = [
    {
        paymentMethod: "card",
        alt: "Visa",
        image: Visa
    },
    {
        paymentMethod: "card",
        alt: "MasterCard",
        image: MasterCard
    },
    {
        paymentMethod: "card",
        alt: "American Express",
        image: Amex
    },
    {
        paymentMethod: "sepa",
        alt: "SEPA Lastschrift",
        image: Sepa
    },
    {
        paymentMethod: "sofort",
        alt: "SOFORT",
        image: Sofort
    },
    {
        paymentMethod: "giropay",
        alt: "Giropay",
        image: Giropay
    },
    {
        paymentMethod: "paypal",
        alt: "PayPal",
        image: Paypal
    },
    {
        paymentMethod: "monthly-partner",
        alt: "Monatliche Zahlung",
        image: Ratenzahlung
    },
    {
        paymentMethod: "monthly-tk",
        alt: "Monatliche Zahlung",
        image: Ratenzahlung
    },
    {
        paymentMethod: "invoice-tk",
        alt: "Kauf auf Rechnung",
        image: Rechnung
    },
    {
        paymentMethod: "invoice-partner",
        alt: "Kauf auf Rechnung",
        image: Rechnung
    },
]


export const PaymentMethods = ({
                                   exclude,
                                   include
                               }: OwnProps) => {

    let renderedMonthly = false
    let renderedInvoice = false

    return <div>
        <h3 className="tk-academy-large tk-color-waterblue-80 tk-academy-bold">Akzeptierte Zahlungsmittel</h3>
        <hr className="mt-3 mb-3"/>
        <div className="payment-methods">
            {PAYMENT_METHODS.filter(paymentMethod => {
                    if (include && include.length > 0) {
                        return include.includes(paymentMethod.paymentMethod)
                    } else {
                        return !exclude?.includes(paymentMethod.paymentMethod)
                    }
                }
            ).map(paymentMethod => {
                const isMonthly = paymentMethodIsMonthly(paymentMethod.paymentMethod);
                const isInvoice = paymentMethod.paymentMethod === "invoice-tk" || paymentMethod.paymentMethod === "invoice-partner";

                const Content = (isMonthly && renderedMonthly) || (isInvoice && renderedInvoice) ? null :
                    <div key={paymentMethod.alt} className="method-icon">
                        <img alt={paymentMethod.alt} src={paymentMethod.image}/>
                    </div>

                if (!renderedMonthly) {
                    renderedMonthly = isMonthly
                }
                if (!renderedInvoice) {
                    renderedInvoice = isInvoice
                }

                return Content;
            })}
        </div>
    </div>
}
