/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseAttributes = "earlyBird" | "hidden" | "new" | "published" | "%future added value";
export type AdvanceCourseButton_CourseFragment = {
    readonly id: string;
    readonly attributes: ReadonlyArray<CourseAttributes>;
    readonly " $fragmentRefs": FragmentRefs<"AtEndOfCourseButton_CourseFragment" | "StartCourseButton_CourseFragment" | "TrialOverButton_CourseFragment" | "TrialOverButtonEarlyBird_CourseFragment" | "ApplyLicenseButton_CourseFragment" | "BoughtEarlyBirdLicenseButton_CourseFragment">;
    readonly " $refType": "AdvanceCourseButton_CourseFragment";
};
export type AdvanceCourseButton_CourseFragment$data = AdvanceCourseButton_CourseFragment;
export type AdvanceCourseButton_CourseFragment$key = {
    readonly " $data"?: AdvanceCourseButton_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"AdvanceCourseButton_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdvanceCourseButton_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attributes",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AtEndOfCourseButton_CourseFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StartCourseButton_CourseFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TrialOverButton_CourseFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TrialOverButtonEarlyBird_CourseFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplyLicenseButton_CourseFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BoughtEarlyBirdLicenseButton_CourseFragment"
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = 'bea493d955e349b0de88159df18250b3';
export default node;
