/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ReviewProcessScreen_ElementsConnectionFragment = {
    readonly " $fragmentRefs": FragmentRefs<"ReviewProcessCurrentElementScreen_ElementsConnectionFragment">;
    readonly " $refType": "ReviewProcessScreen_ElementsConnectionFragment";
};
export type ReviewProcessScreen_ElementsConnectionFragment$data = ReviewProcessScreen_ElementsConnectionFragment;
export type ReviewProcessScreen_ElementsConnectionFragment$key = {
    readonly " $data"?: ReviewProcessScreen_ElementsConnectionFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ReviewProcessScreen_ElementsConnectionFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewProcessScreen_ElementsConnectionFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReviewProcessCurrentElementScreen_ElementsConnectionFragment"
    }
  ],
  "type": "ElementsConnection",
  "abstractKey": null
};
(node as any).hash = 'ea6f1c70f6adbaf49926daa635dc1c64';
export default node;
