/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseAdditionalContentModuleCard_CourseStateFragment = {
    readonly " $fragmentRefs": FragmentRefs<"AdditionalContentItem_CourseStateFragment">;
    readonly " $refType": "CourseAdditionalContentModuleCard_CourseStateFragment";
};
export type CourseAdditionalContentModuleCard_CourseStateFragment$data = CourseAdditionalContentModuleCard_CourseStateFragment;
export type CourseAdditionalContentModuleCard_CourseStateFragment$key = {
    readonly " $data"?: CourseAdditionalContentModuleCard_CourseStateFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseAdditionalContentModuleCard_CourseStateFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseAdditionalContentModuleCard_CourseStateFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdditionalContentItem_CourseStateFragment"
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};
(node as any).hash = '75fcd308d8bffca139172a77556ab11a';
export default node;
