import React from 'react';
import styled from "styled-components";
import {tkBackgrounds} from "../../../../core/style/tkBackgrounds";
import {tkColors} from "../../../../core/style/tkColors";

interface OwnProps {
    title: string
    onClick?: () => void
    modalWidth: number
}

export const ButtonWithTitle = ({title, onClick, modalWidth}: OwnProps) => {
    return <StyledButton onClick={onClick} modalWidth={modalWidth}>{title}</StyledButton>
}

const StyledButton = styled.button<{modalWidth: number}>`
    ${tkBackgrounds.waterBlue}
    ${tkColors.lightBackground}
    margin-left: ${props => props.modalWidth * 0.1}px;
    margin-right: ${props => props.modalWidth * 0.1}px;
    height: ${props => props.modalWidth * 0.125}px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${props => props.modalWidth * 0.045}px;
    font-weight: bold;
    border-radius: 30px;
`