/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type LearnScreen_SubmitMutationVariables = {
    courseId: string;
    moduleId: string;
    lessonId: string;
    elementSubmissionsJson: string;
};
export type LearnScreen_SubmitMutationResponse = {
    readonly Submission: {
        readonly submitLesson: {
            readonly isFirstSubmission: boolean;
            readonly submission: {
                readonly id: string;
                readonly userRef?: string;
            };
        } | null;
    };
};
export type LearnScreen_SubmitMutation = {
    readonly response: LearnScreen_SubmitMutationResponse;
    readonly variables: LearnScreen_SubmitMutationVariables;
};



/*
mutation LearnScreen_SubmitMutation(
  $courseId: ID!
  $moduleId: ID!
  $lessonId: ID!
  $elementSubmissionsJson: String!
) {
  Submission {
    submitLesson(input: {courseId: $courseId, moduleId: $moduleId, lessonId: $lessonId, elementSubmissions: $elementSubmissionsJson}) {
      isFirstSubmission
      submission {
        __typename
        id
        ... on LessonSubmission {
          userRef
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "courseId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "elementSubmissionsJson"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lessonId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "moduleId"
},
v4 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "courseId",
        "variableName": "courseId"
      },
      {
        "kind": "Variable",
        "name": "elementSubmissions",
        "variableName": "elementSubmissionsJson"
      },
      {
        "kind": "Variable",
        "name": "lessonId",
        "variableName": "lessonId"
      },
      {
        "kind": "Variable",
        "name": "moduleId",
        "variableName": "moduleId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFirstSubmission",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userRef",
      "storageKey": null
    }
  ],
  "type": "LessonSubmission",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LearnScreen_SubmitMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SubmissionMutations",
        "kind": "LinkedField",
        "name": "Submission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "LessonSubmissionPayload",
            "kind": "LinkedField",
            "name": "submitLesson",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "submission",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "LearnScreen_SubmitMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SubmissionMutations",
        "kind": "LinkedField",
        "name": "Submission",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "LessonSubmissionPayload",
            "kind": "LinkedField",
            "name": "submitLesson",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "submission",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9375ca2a8d1c0a2f6c1f2f3ad69191e0",
    "id": null,
    "metadata": {},
    "name": "LearnScreen_SubmitMutation",
    "operationKind": "mutation",
    "text": "mutation LearnScreen_SubmitMutation(\n  $courseId: ID!\n  $moduleId: ID!\n  $lessonId: ID!\n  $elementSubmissionsJson: String!\n) {\n  Submission {\n    submitLesson(input: {courseId: $courseId, moduleId: $moduleId, lessonId: $lessonId, elementSubmissions: $elementSubmissionsJson}) {\n      isFirstSubmission\n      submission {\n        __typename\n        id\n        ... on LessonSubmission {\n          userRef\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'aa743af4a85d99092f44214a52c3aac0';
export default node;
