import React from "react";
import {ProgressWithSlots} from "../../common/ProgressWithSlots";
import {NavLink} from "react-router-dom";
import "./lesson-card.scss"
import {AcademyButton} from "../../../../core/components/button/AcademyButton";
import {LessonImage} from "../../common/lesson-image/LessonImage";
import {
    useStateStatusLogic
} from "@thekeytechnology/thekey-academy-frontend-library"
import {StatusIcon} from "../../../../core/components/status/StatusIcon";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {
    LessonCard_LessonStateFragment$key
} from "../../../../../__generated__/LessonCard_LessonStateFragment.graphql";
import {LessonCard_LessonFragment$key} from "../../../../../__generated__/LessonCard_LessonFragment.graphql";
import {LessonCard_ModuleFragment$key} from "../../../../../__generated__/LessonCard_ModuleFragment.graphql";

const LESSON_CARD_LESSON_FRAGMENT = graphql`
    fragment LessonCard_LessonFragment on Lesson {
        containerMeta {
            description
            points
            title
        }
        course {
            id
        }
        id
        image {
            ...LessonImage_ImageFragment
        }
        lessonPath {
            lessonIndex
            moduleIndex
        }
    }
`

const LESSON_CARD_LESSON_STATE_FRAGMENT = graphql`
    fragment LessonCard_LessonStateFragment on LessonState {
        lastUpdate
        points
        status
        ...LessonImage_LessonStateFragment
    }
`

const LESSON_CARD_MODULE_FRAGMENT = graphql`
    fragment LessonCard_ModuleFragment on Module {
        id
    }
`

interface OwnProps {
    lessonFragmentRef: LessonCard_LessonFragment$key;
    lessonStateFragmentRef?: LessonCard_LessonStateFragment$key;
    moduleFragmentRef: LessonCard_ModuleFragment$key;
}

export const LessonCard = ({
                               lessonFragmentRef,
                               lessonStateFragmentRef,
                               moduleFragmentRef
                           }: OwnProps) => {

    const lesson = useFragment<LessonCard_LessonFragment$key>(LESSON_CARD_LESSON_FRAGMENT, lessonFragmentRef)

    const course = lesson.course;

    const lessonState = useFragment<LessonCard_LessonStateFragment$key>(LESSON_CARD_LESSON_STATE_FRAGMENT, lessonStateFragmentRef || null) || undefined;

    const module = useFragment<LessonCard_ModuleFragment$key>(LESSON_CARD_MODULE_FRAGMENT, moduleFragmentRef)

    const stateStatus = useStateStatusLogic(lessonState);

    return <div className="lesson-card tk-academy-card mb-4 d-flex flex-row">
        <LessonImage imageFragmentRef={lesson.image || undefined} lessonStateFragmentRef={lessonState}/>
        <div className="flex-grow-1 ml-5 pt-4 pb-4">
            <div className="mb-3 d-flex flex-row pr-4 align-items-start">
                <div className="flex-grow-1">
                    <div
                        className={"tk-academy-small-text tk-academy-bold " + (lessonState ? "tk-color-waterblue-80" : "tk-color-waterblue-40")}>
                        Lektion {lesson.lessonPath.moduleIndex + 1}.{lesson.lessonPath.lessonIndex + 1}
                    </div>
                    <div className={"tk-academy-larger-bold " + (lessonState ? "tk-black" : "tk-color-waterblue-40")}>
                        {lesson.containerMeta.title}
                    </div>
                </div>
                <div className="d-flex align-items-center ">
                    {stateStatus === "finished" ? <div
                        className="tk-academy-small-text pr-2 pl-2 d-flex align-items-center tk-color-waterblue-40 tk-bg-waterblue-10 tk-border-radius">
                        <span className="mr-1">{lessonState?.points}/{lesson.containerMeta.points}</span>
                        <span className="icon-c_key-coin"/>
                    </div> : null}
                    <StatusIcon stateStatus={stateStatus} possiblePoints={lesson.containerMeta.points}
                                pointsAwarded={lessonState?.points} showIsLocked={true}/>
                </div>
            </div>
            <div
                className={"tk-academy-text pr-4 " + (stateStatus === "current" || stateStatus === "finished" ? "mb-4" : "") + (lessonState ? " tk-color-waterblue-80" : " tk-color-waterblue-40")}
                dangerouslySetInnerHTML={{__html: lesson.containerMeta.description}}>
            </div>
            {stateStatus === "current" || stateStatus === "finished" ? <>
                <hr/>
                <div className="d-flex flex-row pr-4 align-items-center">
                    <ProgressWithSlots
                        className="flex-grow-1 progress-wrapper mr-4"

                        aboveBarSlot={<div className="tk-academy-small-text tk-color-waterblue-80">
                            {stateStatus === "finished" ? "Abgeschlossen" : "Unbearbeitet"}
                        </div>}

                        progress={{
                            isDone: stateStatus === "finished",
                            progressPercentage: stateStatus === "finished" ? 100 : 0,
                            lastUpdate: ""
                        }}/>
                    <div className="ml-auto">
                        <NavLink
                            to={`/courses/${course.id}/modules/${module.id}/lessons/${lesson.id}`}>
                            <AcademyButton buttonStyle={stateStatus === "finished" ? "outline" : "primary"}
                                           text={"Zur Lektion"}/>
                        </NavLink>
                    </div>
                </div>
            </> : null}
        </div>
    </div>
}
