import React from "react";
import "./loading-animation.scss"

export const LoadingAnimation = () => {

    return <div className="loading-animation d-flex justify-content-center align-items-center">
        <div className="spinner-grow tk-color-keygreen" role="status">
            <span className="sr-only">Lade...</span>
        </div>
    </div>
}
