/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PaymentMethod = "card" | "giropay" | "iapApple" | "iapGoogle" | "invoicePartner" | "invoiceTk" | "monthlyPartner" | "monthlyTk" | "paypal" | "sepa" | "sofort" | "%future added value";
export type PaymentDataFormPart_OrderFragment = {
    readonly selectedPaymentMethod: {
        readonly paymentMethodId: PaymentMethod;
    } | null;
    readonly " $refType": "PaymentDataFormPart_OrderFragment";
};
export type PaymentDataFormPart_OrderFragment$data = PaymentDataFormPart_OrderFragment;
export type PaymentDataFormPart_OrderFragment$key = {
    readonly " $data"?: PaymentDataFormPart_OrderFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"PaymentDataFormPart_OrderFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PaymentDataFormPart_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "selectedPaymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethodId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
(node as any).hash = '635a90738b6252ea50789fee25aee14b';
export default node;
