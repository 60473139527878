/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PrivateForm_BillingDetailsFragment = {
    readonly salutation: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly street: string;
    readonly houseNumber: string;
    readonly postalCode: string;
    readonly city: string;
    readonly dateOfBirth: unknown | null;
    readonly " $refType": "PrivateForm_BillingDetailsFragment";
};
export type PrivateForm_BillingDetailsFragment$data = PrivateForm_BillingDetailsFragment;
export type PrivateForm_BillingDetailsFragment$key = {
    readonly " $data"?: PrivateForm_BillingDetailsFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"PrivateForm_BillingDetailsFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PrivateForm_BillingDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salutation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "street",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "houseNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateOfBirth",
      "storageKey": null
    }
  ],
  "type": "BillingDetails",
  "abstractKey": null
};
(node as any).hash = 'cde34bd34f8f62dbbeb49ada0926d71d';
export default node;
