/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CartItemType = "bulkDiscountCartItem" | "discountCodeCartItem" | "productCartItem" | "%future added value";
export type UpsellsDisplay_CartFragment = {
    readonly cartItems: ReadonlyArray<{
        readonly itemType: CartItemType;
    }>;
    readonly otherAvailableProducts: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"ProductDisplay_ProductFragment">;
            };
        } | null> | null;
    };
    readonly " $refType": "UpsellsDisplay_CartFragment";
};
export type UpsellsDisplay_CartFragment$data = UpsellsDisplay_CartFragment;
export type UpsellsDisplay_CartFragment$key = {
    readonly " $data"?: UpsellsDisplay_CartFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"UpsellsDisplay_CartFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpsellsDisplay_CartFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "cartItems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "itemType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductsConnection",
      "kind": "LinkedField",
      "name": "otherAvailableProducts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProductDisplay_ProductFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Cart",
  "abstractKey": null
};
(node as any).hash = '5b053da2cd5b19bb063ddda882d29b18';
export default node;
