import {useFormik} from "formik";
import React, {useContext, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useFragment, useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {OrderFlowContext} from "../../../hooks/OrderFlowContext";
import {BusinessForm_BillingDetailsFragment$key} from "../../../../../../__generated__/BusinessForm_BillingDetailsFragment.graphql";
import {
    BusinessForm_UpdateBillingDetailsMutation,
    SignupInput
} from "../../../../../../__generated__/BusinessForm_UpdateBillingDetailsMutation.graphql";
import CompanyTypes from "./company-types.json"
import {BusinessForm_UserFragment$key} from "../../../../../../__generated__/BusinessForm_UserFragment.graphql";
import {StyledForm} from "../../../../../theme/Form";
import {ValidatedFieldV2} from "../../../../../core/components/form/ValidatedFieldV2";
import {SimpleOptionSelect} from "../../../../../core/components/select/SimpleOptionSelect";
import {Switch} from "../../../../../core/components/form/Switch";
import {trackRegistration} from "../../../../../analytics/analytics";
import * as Yup from "yup";
import "yup-phone-lite";


const BILLING_DETAILS_FRAGMENT = graphql`
    fragment BusinessForm_BillingDetailsFragment on BillingDetails {
        salutation,
        firstName,
        lastName,
        companyBillingDetails {
            company,
            companyDetails,
            companyType
        },
        street
        houseNumber
        postalCode
        city
    }
`;

const UPDATE_BILLING_DETAILS = graphql`
    mutation BusinessForm_UpdateBillingDetailsMutation($billingDetails: UpdateBillingDetailsV2Input!) {
        Billing {
            updateBillingDetailsV2(input: $billingDetails) {
                order {
                    purchaser {
                        userRef
                    }
                    ...OrderFlowScreen_OrderFragment
                }
            }
        }
    }
`;

const USER_FRAGMENT = graphql`
    fragment BusinessForm_UserFragment on User {
        id
        email
        meta {
            firstName
            lastName
        }
    }
`;

interface OwnProps {
    orderId: string
    userDataFragmentRef: BusinessForm_UserFragment$key | null
    billingDetailsFragmentRef: BusinessForm_BillingDetailsFragment$key | null
}

interface BusinessFormState {
    userId?: string
    salutation: string,
    firstName: string,
    lastName: string,
    company: string,
    companyDetails?: string,
    companyType: string,
    invoiceEmail: string,
    street: string,
    houseNumber: string,
    postalCode: string,
    city: string,
    phoneNumber?: string,
    email: string,
    email2: string,
    password: string,
    password2: string,
    legal: boolean,
    adsOptIn: boolean
}

export const BusinessForm = ({orderId, userDataFragmentRef, billingDetailsFragmentRef}: OwnProps) => {
    const {t} = useTranslation("billing")

    const {setSubmitBillingForm, advance} = useContext(OrderFlowContext)

    const billingDetails = useFragment<BusinessForm_BillingDetailsFragment$key>(BILLING_DETAILS_FRAGMENT, billingDetailsFragmentRef)
    const user = useFragment<BusinessForm_UserFragment$key>(USER_FRAGMENT, userDataFragmentRef)
    const [updateBillingDetails, isInFlight] = useMutation<BusinessForm_UpdateBillingDetailsMutation>(UPDATE_BILLING_DETAILS)

    const formikConfig = useFormik<BusinessFormState>({
        initialValues: {
            userId: user?.id,
            salutation: billingDetails?.salutation || "",
            firstName: user?.meta?.firstName || billingDetails?.firstName || "",
            lastName: user?.meta?.lastName || billingDetails?.lastName || "",
            company: billingDetails?.companyBillingDetails?.company || "",
            companyDetails: billingDetails?.companyBillingDetails?.companyDetails || "",
            companyType: billingDetails?.companyBillingDetails?.companyType || "",
            invoiceEmail: user?.email || "",
            street: billingDetails?.street || "",
            houseNumber: billingDetails?.houseNumber || "",
            postalCode: billingDetails?.postalCode || "",
            city: billingDetails?.city || "",
            phoneNumber:  "",
            email: "",
            email2: "",
            password: "",
            password2: "",
            legal: false,
            adsOptIn: false
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            salutation: Yup.string().when('$exist', {
                is: exist => exist,
                then: Yup.string()
                    .required(t("core:forms.required-field", {fieldName: t("checkout-form.salutation")}))
            }),
            firstName:
                Yup.string().when('$exist', {
                    is: exist => exist,
                    then: Yup
                        .string()
                        .required(t("core:forms.required-field", {fieldName: t("checkout-form.first-name")}))
                }),
            lastName: Yup.string().when('$exist', {
                is: exist => exist,
                then: Yup
                    .string()
                    .required(t("core:forms.required-field", {fieldName: t("checkout-form.last-name")}))
            }),
            company: Yup
                .string()
                .required(t("core:forms.required-field", {fieldName: t("checkout-form.company")})),
            companyType: Yup
                .string()
                .required(t("core:forms.required-field", {fieldName: t("checkout-form.company-type")})),
            invoiceEmail: Yup.string().email(
                t("core:forms.required-field", {fieldName: t("checkout-form.invoice-email")})
            ).required(
                t("core:forms.required-field", {fieldName: t("checkout-form.invoice-email")})
            ),
            street: Yup
                .string()
                .required(t("core:forms.required-field", {fieldName: t("checkout-form.street")})),
            houseNumber: Yup
                .string()
                .max(8, t("core:forms.max-length-housenumber"))
                .required(t("core:forms.required-field", {fieldName: t("checkout-form.house-number")})),
            postalCode: Yup
                .string()
                .matches(new RegExp("^[0-9]{5}$"), "Das ist keine gültige PLZ")
                .required(t("core:forms.required-field", {fieldName: t("checkout-form.postal-code")})),
            city: Yup
                .string()
                .required(t("core:forms.required-field", {fieldName: t("checkout-form.city")})),
            phoneNumber: Yup.string().phone("DE","Bitte gib eine gültige Telefonnummer ein"),
            email: Yup.string().when('$exist', {
                is: exist => exist,
                then: Yup.string()
                    .email(t("checkout-form.email-error"))
                    .test("test", "test", function (value) {
                        if (!user && !value) {
                            return this.createError({
                                path: "email",
                                message: t("core:forms.required-field", {fieldName: t("checkout-form.email")})
                            });
                        }
                        return true;
                    })
            }),
            email2: Yup.string().when('$exist', {
                is: exist => exist,
                then: Yup.string()
                    .oneOf([Yup.ref("email")], t("checkout-form.email2-error"))
            }),
            password:
                Yup.string().when('$exist', {
                    is: exist => exist,
                    then: Yup
                        .string()
                        .min(8, t("checkout-form.password-error"))
                        .test("test", "test", function (value) {
                            if (!user && !value) {
                                return this.createError({
                                    path: "password",
                                    message: t("core:forms.required-field", {fieldName: t("checkout-form.password")})
                                });
                            }
                            return true;
                        })
                }),
            password2: Yup.string().when('$exist', {
                is: exist => exist,
                then: Yup.string()
                    .oneOf([Yup.ref("password")], t("checkout-form.password2-error"))
            }),
            legal: Yup
                .boolean()
                .test("test", "test", function (value) {
                    if (!user && !value) {
                        return this.createError({
                            path: "legal",
                            message: t("core:forms.required-field", {fieldName: "Nutzungsbedingungen & Datenschutz"})
                        });
                    }
                    return true;
                }),
        }),
        onSubmit: (values, {setSubmitting}) => {
            updateBillingDetails({
                variables: {
                    billingDetails: {
                        orderId: orderId,
                        billingDetailsV3: {
                            customerType: "business",
                            salutation: values.salutation,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            street: values.street,
                            city: values.city,
                            postalCode: values.postalCode,
                            houseNumber: values.houseNumber,
                            companyBillingDetails: {
                                company: values.company!,
                                companyDetails: values.companyDetails,
                                companyType: values.companyType!,
                            },
                            invoiceEmail: values.invoiceEmail
                        },
                        signup: user ? undefined : {
                            email: values.email!,
                            firstName: values.firstName!,
                            lastName: values.lastName!,
                            password: values.password!,
                            adsOptIn: values.adsOptIn
                        } as SignupInput
                    }
                },
                onCompleted: (response) => {
                    advance()

                    const base64EncodedUserId = response.Billing.updateBillingDetailsV2?.order.purchaser?.userRef
                    if (!user && base64EncodedUserId) trackRegistration(values.email, values.firstName, values.lastName, base64EncodedUserId)
                }
            })
            setSubmitting(false);
        }
    })

    useEffect(() => {
        setSubmitBillingForm(formikConfig.handleSubmit)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return isInFlight ? <div>Lade...</div> :
        <StyledForm>
            <form onSubmit={formikConfig.handleSubmit}>
                <div className="tk-form-row">
                    <div className="form-group">
                        <ValidatedFieldV2<BusinessFormState, string>
                            name={"company"} formikConfig={formikConfig}
                            required={true}
                            component={(fieldValue, updateField, name, required) =>
                                <input name={name}
                                       className="form-control default-input"
                                       placeholder={t("checkout-form.company")}
                                       required={required}
                                       value={fieldValue}
                                       type="text"
                                       onChange={event => updateField(event.target.value)}
                                />
                            }/>
                    </div>
                    <div className="form-group">
                        <ValidatedFieldV2<BusinessFormState, string>
                            name={"companyDetails"} formikConfig={formikConfig}
                            required={true}
                            component={(fieldValue, updateField, name, required) =>
                                <input name={name}
                                       className="form-control default-input"
                                       placeholder={t("checkout-form.company-details")}
                                       required={required}
                                       type="text"
                                       value={fieldValue}
                                       onChange={event => updateField(event.target.value)}
                                />
                            }/>
                    </div>
                </div>
                <div className="tk-form-row">
                    <div className="form-group">
                        <ValidatedFieldV2<BusinessFormState, string>
                            name={"companyType"} formikConfig={formikConfig}
                            required={true}
                            component={(fieldValue, updateField, name) =>
                                <SimpleOptionSelect<string>
                                    value={fieldValue}
                                    name={name}
                                    options={Object.entries(CompanyTypes).map(entry => {
                                        return {
                                            value: entry[0].toLowerCase(),
                                            label: entry[1]
                                        }
                                    })}
                                    placeholder={t("checkout-form.company-type")}
                                    setValue={updateField as (value: string | string[]) => void}
                                />
                            }/>
                    </div>
                    <div className="form-group">
                        <ValidatedFieldV2<BusinessFormState, string>
                            name={"invoiceEmail"} formikConfig={formikConfig}
                            required={true}
                            component={(fieldValue, updateField, name, required) =>
                                <input name={name}
                                       type={"email"}
                                       className="form-control default-input"
                                       placeholder={t("checkout-form.invoice-email")}
                                       required={required}
                                       value={fieldValue}
                                       onChange={event => updateField(event.target.value)}
                                />
                            }/>
                    </div>
                </div>

                <div className="tk-form-row">
                    <div className="form-group">
                        <ValidatedFieldV2<BusinessFormState, string>
                            name={"street"} formikConfig={formikConfig}
                            required={true}
                            component={(fieldValue, updateField, name, required) =>
                                <input name={name}
                                       className="form-control default-input"
                                       placeholder={t("checkout-form.street")}
                                       required={required}
                                       type="text"
                                       value={fieldValue}
                                       onChange={event => updateField(event.target.value)}
                                />
                            }/>
                    </div>
                    <div className="form-group">
                        <ValidatedFieldV2<BusinessFormState, string>
                            name={"houseNumber"} formikConfig={formikConfig}
                            required={true}
                            component={(fieldValue, updateField, name, required) =>
                                <input name={name}
                                       className="form-control default-input"
                                       placeholder={t("checkout-form.house-number")}
                                       required={required}
                                       type="text"
                                       value={fieldValue}
                                       onChange={event => updateField(event.target.value)}
                                />
                            }/>
                    </div>
                </div>

                <div className="tk-form-row">
                    <div className="form-group">
                        <ValidatedFieldV2<BusinessFormState, string>
                            name={"postalCode"} formikConfig={formikConfig}
                            required={true}
                            component={(fieldValue, updateField, name, required) =>
                                <input name={name}
                                       className="form-control default-input"
                                       placeholder={t("checkout-form.postal-code")}
                                       required={required}
                                       type="text"
                                       value={fieldValue}
                                       onChange={event => updateField(event.target.value)}
                                />
                            }/>
                    </div>
                    <div className="form-group">
                        <ValidatedFieldV2<BusinessFormState, string>
                            name={"city"} formikConfig={formikConfig}
                            required={true}
                            component={(fieldValue, updateField, name, required) =>
                                <input name={name}
                                       className="form-control default-input"
                                       placeholder={t("checkout-form.city")}
                                       required={required}
                                       type="text"
                                       value={fieldValue}
                                       onChange={event => updateField(event.target.value)}
                                />
                            }/>
                    </div>
                </div>

                <div className="tk-form-row">
                    <div className="form-group validated-field">
                        <input name={"country"}
                               className="form-control default-input tk-cursor-na"
                               disabled={true}
                               value={"Deutschland"}
                               type="text"
                        />
                    </div>
                        <div className="form-group">
                            <ValidatedFieldV2<BusinessFormState, string>
                                name={"phoneNumber"}
                                formikConfig={formikConfig}
                                helpText={"Ausschließlich verwendet für Service-Zwecke, z.B. in Bezug auf Dein IHK-Zertifikat."}
                                component={(fieldValue, updateField, name, required) =>
                                    <input name={name}
                                           className="form-control default-input"
                                           placeholder={t("checkout-form.phone-number")}
                                           required={required}
                                           value={fieldValue}
                                           type="text"
                                           onChange={event => updateField(event.target.value)}
                                    />
                                }/>
                        </div>
                </div>

                {user ? null : <>
                    <h3 className="tk-academy-large tk-color-waterblue-80 tk-academy-bold mb-2">{"Teilnehmer anlegen"}</h3>
                    <p className="tk-academy-large mb-2">
                        <strong>Hinweis:</strong> Wenn Dein Unternehmen mehr als fünf Lizenzen erwerben will,
                        nimm&nbsp;
                        <a href='mailto:app@thekey.academy'>direkt Kontakt mit uns auf</a>. Wir melden uns, um ein
                        auf
                        Euch
                        zugeschnittenes Angebot mit attraktiven
                        Mengenrabatten zu erstellen.
                    </p>
                    <div className="tk-form-row">
                        <div className="form-group">
                            <ValidatedFieldV2<BusinessFormState, string>
                                name={"salutation"} formikConfig={formikConfig}
                                required={true}
                                component={(fieldValue, updateField, name) =>
                                    <SimpleOptionSelect<string>
                                        value={fieldValue}
                                        name={name}
                                        options={[
                                            {
                                                value: "mr",
                                                label: "Herr"
                                            },
                                            {
                                                value: "mrs",
                                                label: "Frau"
                                            },
                                            {
                                                value: "divers",
                                                label: "Divers"
                                            }
                                        ]}
                                        placeholder={t("checkout-form.salutation")}
                                        setValue={updateField as (value: string | string[]) => void}
                                    />
                                }/>
                        </div>
                    </div>
                    <div className="tk-form-row">
                        <div className="form-group">
                            <ValidatedFieldV2<BusinessFormState, string>
                                name={"firstName"} formikConfig={formikConfig}
                                required={true}
                                component={(fieldValue, updateField, name, required) =>
                                    <input name={name}
                                           className="form-control default-input"
                                           placeholder={t("checkout-form.first-name")}
                                           required={required}
                                           value={fieldValue}
                                           onChange={event => updateField(event.target.value)}
                                    />
                                }/>
                        </div>
                        <div className="form-group">
                            <ValidatedFieldV2<BusinessFormState, string>
                                name={"lastName"} formikConfig={formikConfig}
                                required={true}
                                component={(fieldValue, updateField, name, required) =>
                                    <input name={name}
                                           className="form-control default-input"
                                           placeholder={t("checkout-form.last-name")}
                                           required={required}
                                           value={fieldValue}
                                           onChange={event => updateField(event.target.value)}
                                    />
                                }/>
                        </div>
                    </div>

                    <div className="tk-form-row">
                        <div className="form-group">
                            <ValidatedFieldV2<BusinessFormState, string>
                                name={"email"} formikConfig={formikConfig}
                                required={true}
                                component={(fieldValue, updateField, name, required) =>
                                    <input name={name}
                                           type={"email"}
                                           className="form-control default-input"
                                           placeholder={t("checkout-form.email")}
                                           required={required}
                                           value={fieldValue}
                                           onChange={event => updateField(event.target.value)}
                                    />
                                }/>
                        </div>
                        <div className="form-group">
                            <ValidatedFieldV2<BusinessFormState, string>
                                name={"email2"} formikConfig={formikConfig}
                                required={true}
                                component={(fieldValue, updateField, name, required) =>
                                    <input name={name}
                                           type={"email"}
                                           className="form-control default-input"
                                           placeholder={t("checkout-form.email2")}
                                           required={required}
                                           value={fieldValue}
                                           onChange={event => updateField(event.target.value)}
                                    />
                                }/>
                        </div>
                    </div>
                    <p className="tk-academy-large mb-2">Bitte vergib ein Teilnehmer-Passwort. Bitte Deinen Kollegen
                        darum,
                        dieses in seinen Einstellungen zu
                        ändern:</p>
                    <div className="tk-form-row">
                        <div className="form-group">
                            <ValidatedFieldV2<any, string>
                                name={"password"} formikConfig={formikConfig}
                                required={true}
                                component={(fieldValue, updateField, name, required) =>
                                    <input name={name}
                                           type="password"
                                           className="form-control default-input"
                                           placeholder={t("checkout-form.password")}
                                           autoComplete="new-password"
                                           required={required}
                                           value={fieldValue}
                                           onChange={event => updateField(event.target.value)}
                                    />
                                }/>
                        </div>
                        <div className="form-group">
                            <ValidatedFieldV2<any, string>
                                name={"password2"} formikConfig={formikConfig}
                                required={true}
                                component={(fieldValue, updateField, name, required) =>
                                    <input name={name}
                                           type="password"
                                           className="form-control default-input"
                                           placeholder={t("checkout-form.password2")}
                                           autoComplete="new-password"
                                           required={required}
                                           value={fieldValue}
                                           onChange={event => updateField(event.target.value)}
                                    />
                                }/>
                        </div>
                    </div>
                    <div className="mb-3">
                        <ValidatedFieldV2<any, boolean>
                            name={"legal"} formikConfig={formikConfig}
                            required={true}
                            component={(fieldValue, updateField) =>
                                <Switch title={<>Mit der Anmeldung stimmst du der <a
                                    rel="noopener noreferrer"
                                    href='https://www.thekey.academy/datenschutzerklaerung/'
                                    target='_blank'>Datenschutzerklärung</a> und den <a
                                    rel="noopener noreferrer"
                                    href='https://www.thekey.academy/agbs/'
                                    target='_blank'>Nutzungsbedingungen</a> zu.</>}
                                        titleClassName={"tk-academy-large tk-color-waterblue ml-3"}
                                        titleRight={true}
                                        isChecked={fieldValue}
                                        setChecked={updateField}/>
                            }/>
                    </div>
                    <div className="form-group">
                        <ValidatedFieldV2<any, boolean>
                            name={"adsOptIn"} formikConfig={formikConfig}
                            required={true}
                            component={(fieldValue, updateField) =>
                                <Switch
                                    title="Ich freue mich, über Aktionen, Rabatte und Neuigkeiten per E-Mail informiert zu werden."
                                    titleClassName={"tk-academy-large tk-color-waterblue ml-3"}
                                    titleRight={true}
                                    isChecked={fieldValue}
                                    setChecked={updateField}/>
                            }/>
                    </div>
                </>}
            </form>
        </StyledForm>
}
