/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ElementType = "clozeText" | "image" | "multipleChoice" | "order" | "persolog" | "questionnaire" | "resolution" | "text" | "video" | "%future added value";
export type LearnCurrentElementScreen_LessonFragment = {
    readonly id: string;
    readonly course: {
        readonly " $fragmentRefs": FragmentRefs<"LearnContentArea_CourseFragment">;
    };
    readonly elements: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly elementType: ElementType;
                readonly " $fragmentRefs": FragmentRefs<"ElementDisplay_ElementFragment" | "LearnContentArea_ElementFragment" | "LearnHeader_ElementFragment" | "LearnSidebar_ElementFragment">;
            };
        } | null> | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"LearnContentArea_LessonFragment" | "LearnFooter_LessonFragment" | "LearnHeader_LessonFragment" | "LearnSidebar_LessonFragment">;
    readonly " $refType": "LearnCurrentElementScreen_LessonFragment";
};
export type LearnCurrentElementScreen_LessonFragment$data = LearnCurrentElementScreen_LessonFragment;
export type LearnCurrentElementScreen_LessonFragment$key = {
    readonly " $data"?: LearnCurrentElementScreen_LessonFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LearnCurrentElementScreen_LessonFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LearnCurrentElementScreen_LessonFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "course",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LearnContentArea_CourseFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ElementsConnection",
      "kind": "LinkedField",
      "name": "elements",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ElementsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "elementType",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ElementDisplay_ElementFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "LearnContentArea_ElementFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "LearnHeader_ElementFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "LearnSidebar_ElementFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LearnContentArea_LessonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LearnFooter_LessonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LearnHeader_LessonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LearnSidebar_LessonFragment"
    }
  ],
  "type": "Lesson",
  "abstractKey": "__isLesson"
};
})();
(node as any).hash = '07f92b7f98cf521a02d10118b54db676';
export default node;
