import React, {ReactNode} from "react";
import styled from "styled-components";

interface OwnProps {
    children?: ReactNode;
}

export const LearnReviewPrimaryContentArea = ({children}: OwnProps) => {
    return children ? <Container className="d-flex flex-row flex-nowrap flex-grow-1 ">{children}</Container> :
        <Container/>
}

const Container = styled.div`
    margin-top: 60px;
    z-index: 5;
    border-top-left-radius: 25px;
    overflow: hidden;
`