/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseAdditionalContentList_CourseFragment = {
    readonly additionalContent: ReadonlyArray<{
        readonly module: {
            readonly id: string;
        };
        readonly " $fragmentRefs": FragmentRefs<"CourseAdditionalContentModuleCard_AdditionalContentByModuleFragment">;
    }>;
    readonly " $refType": "CourseAdditionalContentList_CourseFragment";
};
export type CourseAdditionalContentList_CourseFragment$data = CourseAdditionalContentList_CourseFragment;
export type CourseAdditionalContentList_CourseFragment$key = {
    readonly " $data"?: CourseAdditionalContentList_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseAdditionalContentList_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseAdditionalContentList_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdditionalContentByModule",
      "kind": "LinkedField",
      "name": "additionalContent",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Module",
          "kind": "LinkedField",
          "name": "module",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CourseAdditionalContentModuleCard_AdditionalContentByModuleFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = '3b68fa6494616d0542cfa157ac1d971e';
export default node;
