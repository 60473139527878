import React from "react";

import "./menu.scss"
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import {LogoutLink} from "../../core/components/logout/LogoutLink";
import {AccountSwitcher} from "../../auth/components/account-switcher/AccountSwitcher";

interface OwnProps {
    collapsed?: boolean;
    hasNewLicensesInAccount: boolean;
}

type Props = OwnProps;

export const LowerSidebarMenu = ({collapsed, hasNewLicensesInAccount}: Props) => {
    const {t} = useTranslation("menu");

    return <ul
        className="menu pl-0 pr-0 pt-3 pb-0 d-flex flex-column align-item-center mb-0 tk-">

        {!collapsed ? <AccountSwitcher/> : null}

        <NavLink to="/manager" exact activeClassName="white" className="tk-key-blue-30">
            <li className={"d-flex pt-2 pb-2 mb-3 d-flex " + (collapsed ? "justify-content-center" : "pl-4")}>
                <span className={"icon icon-a_trainer " + (!collapsed ? "mr-3" : "")}/>
                {!collapsed && <span>{t(`routes.manager.title`)}</span>}
                {hasNewLicensesInAccount && !collapsed ?
                    <span className="badge badge-danger ml-2 d-flex align-items-center">{t(`routes.manager.new`)}</span> :
                    null
                }
            </li>
        </NavLink>

        <NavLink to="/account" exact activeClassName="white" className="tk-key-blue-30">
            <li className={"d-flex pt-2 pb-2 mb-3 d-flex " + (collapsed ? "justify-content-center" : "pl-4")}>
                <span className={"icon icon-a_trainer " + (!collapsed ? "mr-3" : "")}/>
                {!collapsed && <span>{t(`routes.account`)}</span>}
            </li>
        </NavLink>
        <li className={"d-flex pt-2 pb-2 mb-3 d-flex " + (collapsed ? "justify-content-center" : "pl-4")}>
            <LogoutLink className="tk-key-blue-30">
                <span className={"icon icon-a_logout " + (!collapsed ? "mr-3" : "")}/>
                {!collapsed && <span>{t(`routes.logout`)}</span>}
            </LogoutLink>
        </li>
    </ul>
}

