import React, {Suspense} from "react";
import {ReviewScreen} from "./components/ReviewScreen";
import {RouteAuthState, RouteDefinition} from "../../core/components/routing/RouteDefinition";
import {LoadingFakeDetailScreen} from "../../core/loading/detail/LoadingFakeDetailScreen";

export const ReviewRouteDefinitions: RouteDefinition[] = [
    {
        path: "/courses/:courseId/review*",
        component: () => <Suspense fallback={<LoadingFakeDetailScreen/>}>
                <ReviewScreen/>
            </Suspense>,
        authState: RouteAuthState.OnlyLoggedIn
    }
]