/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseInfo_CourseFragment = {
    readonly containerMeta: {
        readonly description: string;
        readonly title: string;
    };
    readonly " $fragmentRefs": FragmentRefs<"CourseAd_CourseFragment" | "CourseAdditionalContentList_CourseFragment" | "ExpertList_CourseFragment" | "LicenseAvailable_CourseFragment">;
    readonly " $refType": "CourseInfo_CourseFragment";
};
export type CourseInfo_CourseFragment$data = CourseInfo_CourseFragment;
export type CourseInfo_CourseFragment$key = {
    readonly " $data"?: CourseInfo_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseInfo_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseInfo_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContainerMeta",
      "kind": "LinkedField",
      "name": "containerMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseAd_CourseFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseAdditionalContentList_CourseFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExpertList_CourseFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LicenseAvailable_CourseFragment"
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = '1029e61c5784933f69f1c58210184dce';
export default node;
