/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SubmissionType = "certificationTest" | "learn" | "review" | "test" | "%future added value";
export type SubmissionFinishedHeader_SubmissionFragment = {
    readonly courseRef: string;
    readonly submissionType: SubmissionType;
    readonly lesson?: {
        readonly id: string;
        readonly lessonPath: {
            readonly courseRef: string;
            readonly moduleRef: string;
        };
    };
    readonly " $refType": "SubmissionFinishedHeader_SubmissionFragment";
};
export type SubmissionFinishedHeader_SubmissionFragment$data = SubmissionFinishedHeader_SubmissionFragment;
export type SubmissionFinishedHeader_SubmissionFragment$key = {
    readonly " $data"?: SubmissionFinishedHeader_SubmissionFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"SubmissionFinishedHeader_SubmissionFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "courseRef",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubmissionFinishedHeader_SubmissionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submissionType",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "lesson",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LessonPath",
              "kind": "LinkedField",
              "name": "lessonPath",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "moduleRef",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "LessonSubmission",
      "abstractKey": null
    }
  ],
  "type": "Submission",
  "abstractKey": "__isSubmission"
};
})();
(node as any).hash = '98b9c5e3003e62263dca1db151636f60';
export default node;
