import {Form, Formik} from "formik";
import * as Yup from "yup";
import {NavLink} from "react-router-dom";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {ValidatedField} from "../../../core/components/form/ValidatedField";
import {AuthScreenHeading} from "../common/AuthScreenHeading";
import {AuthFormWrapper} from "../common/AuthFormWrapper";
import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {LoginScreenContainer} from "../login/LoginScreenContainer";
import {ForgotPasswordScreen_ForgotPasswordMutation} from "../../../../__generated__/ForgotPasswordScreen_ForgotPasswordMutation.graphql";

const FORGOT_PASSWORD_MUTATION = graphql`
    mutation ForgotPasswordScreen_ForgotPasswordMutation($input: ForgotPasswordInput!) {
        Auth {
            forgotPassword(input: $input) {
                clientMutationId
            }
        }
    }`

interface State {
    showSuccessScreen: boolean
}

export const ForgotPasswordScreen = () => {
    const {t} = useTranslation(["auth", "core"]);

    const [state, setState] = useState<State>({showSuccessScreen: false})

    const [forgotPassword, forgotPasswordIsInFlight] = useMutation<ForgotPasswordScreen_ForgotPasswordMutation>(FORGOT_PASSWORD_MUTATION)

    const SuccessScreen = <>
        <AuthScreenHeading
            title={t("forgot-password-form.success.heading-1")}
            subtitle={t("forgot-password-form.success.heading-2")}
        />
        <p className="tk-bg-green-20 mb-5 p-5">
            {t("forgot-password-form.success-text")}
        </p>
        <NavLink to="/login">
            <button type="button"
                    className="btn btn-primary align-self-end w-100"
            >
                {t("forgot-password-form.success-to-login")}
            </button>
        </NavLink>
    </>;

    return <LoginScreenContainer><Formik
        initialValues={{email: "", password: ""}}
        validationSchema={Yup.object().shape({
            email: Yup.string().email(t("registration-form.email-error")).required(t("core:forms.required-field", {fieldName: t("login-form.email")})),
        })}
        onSubmit={(values, {setSubmitting}) => {
            forgotPassword({
                variables: {input: {email: values.email}},
                onCompleted: _ => setState(state => ({...state, showSuccessScreen: true}))
            })
            setSubmitting(false);
        }}
    >
        {formikState => (
            <AuthFormWrapper>
                {state.showSuccessScreen ? SuccessScreen : <Form className="w-100 login-form">
                    <AuthScreenHeading
                        title={t("forgot-password-form.heading-1")}
                        subtitle={t("forgot-password-form.heading-2")}
                    />
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        iconClass={"icon-d_mail"}
                                        type="email"
                                        placeholder={t("login-form.e-mail-placeholder")}
                                        name="email"
                                        className="form-control default-input"
                                        required/>
                    </div>
                    <div className="form-group d-flex mb-4">
                        <button type="submit" disabled={formikState.isSubmitting || forgotPasswordIsInFlight}
                                className="btn btn-primary align-self-end w-100">
                            {t("forgot-password-form.submit")}
                        </button>
                    </div>
                    <div className="tk-academy-small-text text-center paragraph mb-3">
                        {t("forgot-password-form.to-login-explanation")} <NavLink
                        className="mb-5"
                        to="/login">{t("forgot-password-form.to-login")}</NavLink>
                    </div>
                </Form>}
            </AuthFormWrapper>
        )}
    </Formik></LoginScreenContainer>
}
