/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ElementType = "clozeText" | "image" | "multipleChoice" | "order" | "persolog" | "questionnaire" | "resolution" | "text" | "video" | "%future added value";
export type FailedElementCard_FailedElementFragment = {
    readonly element: {
        readonly elementPath: {
            readonly moduleIndex: number;
            readonly lessonIndex: number;
        };
        readonly elementType: ElementType;
        readonly title?: string;
        readonly question?: {
            readonly alternatives: ReadonlyArray<string>;
        };
    };
    readonly " $refType": "FailedElementCard_FailedElementFragment";
};
export type FailedElementCard_FailedElementFragment$data = FailedElementCard_FailedElementFragment;
export type FailedElementCard_FailedElementFragment$key = {
    readonly " $data"?: FailedElementCard_FailedElementFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"FailedElementCard_FailedElementFragment">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FailedElementCard_FailedElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "element",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ElementPath",
          "kind": "LinkedField",
          "name": "elementPath",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "moduleIndex",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lessonIndex",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "elementType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "ClozeTextElement",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PhrasingAlternatives",
              "kind": "LinkedField",
              "name": "question",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "alternatives",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "MultipleChoiceElement",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "OrderElement",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FailedElement",
  "abstractKey": null
};
})();
(node as any).hash = '1820fd6f645ae5b4c7138c2ad1d6b9f8';
export default node;
