import React from "react";
import {AcademyButton} from "../../../components/button/AcademyButton";

export const LoadingFakeSubmitButton = () => {
    return <AcademyButton
        type={"reset"}
        disabled={true}
        text={"Lade..."}
    />
}
