import {CardElement, IbanElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {FormikErrors} from "formik/dist/types";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {Message, postMessageAction} from "@thekeytechnology/framework-react";
import * as Sentry from "@sentry/react";
import {useFragment, useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {usePaymentLogicV3_OrderFragment$key} from "../../../../../../__generated__/usePaymentLogicV3_OrderFragment.graphql";
import {usePaymentLogicV3_PayMutation} from "../../../../../../__generated__/usePaymentLogicV3_PayMutation.graphql";
import {trackPurchase} from "../../../../../analytics/analytics";

export interface UsePaymentLogicSignature {
    startPayment: (values: any, setErrors: (errors: FormikErrors<any>) => void, setSubmitting: (submitting: boolean) => void) => void;
    paymentInProcess: boolean
}

interface PaymentParameters {
    iban?: string
}

const ORDER_FRAGMENT = graphql`
    fragment usePaymentLogicV3_OrderFragment on Order {
        id
        selectedPaymentMethod {
            paymentMethodId
            ... on OneTimeSelectedPaymentMethod {
                stripeData {
                    paymentIntentSecret
                }
            }
        }
        billingDetails {
            firstName
            lastName
            street
            houseNumber
            postalCode
            city
            country
            invoiceEmail
            customerType
            companyBillingDetails {
                company
            }
        }
        cart {
            cartItems {
                ... on ProductCartItem {
                    product {
                        id
                        title
                    }
                }
            }
            cartSelection {
                selectedProducts {
                    amount
                    productId
                }
            }
            cartTotals {
                totalsIncludingAllDiscounts {
                    netPrice
                }
            }
        }
        selectedPaymentMethod {
            selectedPaymentMethodType 
            ... on MonthlySelectedPaymentMethod {
                chosenOption {
                    totalAmount {
                        netPrice
                    }
                }
            }
        }
    }
`;

export const usePaymentLogicV3 = (orderFragmentRef: usePaymentLogicV3_OrderFragment$key): UsePaymentLogicSignature => {
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();

    const url = new URL(window.location.href);
    const clientSecret = url.searchParams.get("payment_intent_client_secret");

    const order = useFragment<usePaymentLogicV3_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)
    const selectedProducts = order.cart.cartSelection.selectedProducts
    const selectedProductTitles = order.cart.cartSelection.selectedProducts.map(sp => order.cart.cartItems.find(ci => ci.product?.id === sp.productId)?.product?.title).filter(spt => !!spt).map(spt => spt!)
    const selectedProductsForTracking = selectedProductTitles.length === selectedProducts.length ? order.cart.cartSelection.selectedProducts.map((sp, i) => ({
        base64EncodedProductId: sp.productId,
        quantity: sp.amount,
        productName: selectedProductTitles[i]
    })) : undefined
    const netTotalValueIncludingAllDiscountsForTracking = order.selectedPaymentMethod?.chosenOption ?
        order.selectedPaymentMethod.chosenOption.totalAmount.netPrice:
        order.cart.cartTotals.totalsIncludingAllDiscounts?.netPrice

    const [pay, isInFLight] = useMutation<usePaymentLogicV3_PayMutation>(graphql`
        mutation usePaymentLogicV3_PayMutation($input: PayOrderInput!) {
            Billing {
                payOrder(input: $input) {
                    order {
                        ...OrderFlowScreen_OrderFragment
                    }
                }
            }
        }
    `)


    useEffect(() => {
        if (clientSecret) {
            stripe!.retrievePaymentIntent(clientSecret).then(response => {
                if (response.error) {
                    dispatch(postMessageAction(Message.TYPE_DANGER, "Deine Zahlung konnte nicht abgeschlossen werden. Bitte überprüfe die Zahlungsmethode."))
                    Sentry.captureException(response.error);
                } else if (response.paymentIntent && ["succeeded", "processing"].includes(response.paymentIntent.status)) {
                    pay({
                        variables: {
                            input: {
                                orderId: order.id
                            }
                        },
                        onCompleted: _ => {
                            try {
                                if (netTotalValueIncludingAllDiscountsForTracking && order.id && selectedProductsForTracking) trackPurchase(selectedProductsForTracking, netTotalValueIncludingAllDiscountsForTracking, order.id)
                            } catch (e) {
                                Sentry.captureException(e)
                            }
                        }
                    })
                } else {
                    dispatch(postMessageAction(Message.TYPE_DANGER, "Deine Zahlung konnte nicht abgeschlossen werden. Bitte überprüfe die Zahlungsmethode."))
                }
            });
        }
        // eslint-disable-next-line
    }, [])

    const startPayment = (values: PaymentParameters, setErrors: (errors: FormikErrors<any>) => void, setSubmitting: (submitting: boolean) => void) => {
        if (order.selectedPaymentMethod?.paymentMethodId === "paypal") {
            return;
        }

        const onPaymentResponse = (result: any) => {
            if (result.error) {
                setErrors({
                    paymentMethodField: result.error.message
                })
            } else {
                pay({
                    variables: {
                        input: {
                            orderId: order.id,
                            iban: values.iban
                        }
                    },
                    onCompleted: _ => {
                        try {
                            if (netTotalValueIncludingAllDiscountsForTracking && order.id && selectedProductsForTracking) trackPurchase(selectedProductsForTracking, netTotalValueIncludingAllDiscountsForTracking, order.id)
                        } catch (e) {
                            Sentry.captureException(e)
                        }
                    }
                })
            }
            setSubmitting(false);
        };

        const onPaymentError = (reason: any) => {
            setErrors({
                paymentMethodField: reason
            })
            setSubmitting(false);
        };

        let billingDetails = {
            email: order.billingDetails!.invoiceEmail,
            name: order.billingDetails!.customerType === "business" ? order.billingDetails?.companyBillingDetails?.company! : order.billingDetails!.firstName + " " + order.billingDetails!.lastName,
            address: {
                line1: order.billingDetails!.street + " " + order.billingDetails!.houseNumber,
                postal_code: order.billingDetails!.postalCode,
                city: order.billingDetails!.city,
                country: order.billingDetails!.country
            }
        }

        switch (order.selectedPaymentMethod?.paymentMethodId) {
            case "card": {
                stripe!.confirmCardPayment(order.selectedPaymentMethod?.stripeData!.paymentIntentSecret, {
                    payment_method: {card: elements?.getElement(CardElement)!}
                }).then(onPaymentResponse).catch(onPaymentError)
                break;
            }
            case "sepa": {
                stripe!.confirmSepaDebitPayment(order.selectedPaymentMethod?.stripeData!.paymentIntentSecret, {
                    payment_method: {
                        billing_details: billingDetails,
                        sepa_debit: elements?.getElement(IbanElement)!
                    }
                }).then(onPaymentResponse).catch(onPaymentError)
                break;
            }
            case "giropay":
                stripe!.confirmGiropayPayment(order.selectedPaymentMethod?.stripeData!.paymentIntentSecret, {
                    payment_method: {
                        billing_details: billingDetails,
                    },
                    return_url: `${window.location.href}?&payment_method=giropay`
                })
                break;
            case "sofort":
                stripe!.confirmSofortPayment(order.selectedPaymentMethod?.stripeData!.paymentIntentSecret, {
                    payment_method: {
                        billing_details: billingDetails,
                        sofort: {
                            country: billingDetails.address.country.toUpperCase()
                        }
                    },
                    return_url: `${window.location.href}?&payment_method=sofort`
                })
                break;
            default:
                pay({
                    variables: {
                        input: {
                            orderId: order.id,
                            iban: values.iban
                        }
                    },
                    onCompleted: _ => {
                        try {
                            if (netTotalValueIncludingAllDiscountsForTracking && order.id && selectedProductsForTracking) trackPurchase(selectedProductsForTracking, netTotalValueIncludingAllDiscountsForTracking, order.id)
                        } catch (e) {
                            Sentry.captureException(e)
                        }
                    }
                })
        }
    }

    return {
        startPayment,
        paymentInProcess: isInFLight
    }

}
