import React, {ReactNode} from "react";
import "./course-sidebar.scss"
import {ImageWithFallback} from "../../../../core/components/image/ImageWithFallback";
import {MainSidebar} from "../../../../core/components/sidebar/MainSidebar";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {CourseSidebar_CourseFragment$key} from "../../../../../__generated__/CourseSidebar_CourseFragment.graphql";
import {CourseSidebar_CourseStateFragment$key} from "../../../../../__generated__/CourseSidebar_CourseStateFragment.graphql";

const COURSE_FRAGMENT = graphql`
    fragment CourseSidebar_CourseFragment on Course {
        containerMeta {
            title
        }
        image {
            url
        }
    }`

const COURSE_STATE_FRAGMENT = graphql`
    fragment CourseSidebar_CourseStateFragment on CourseState {
        progress {
            progressPercentage
        }
    }`

interface OwnProps {
    courseFragmentRef: CourseSidebar_CourseFragment$key;
    courseStateFragmentRef?: CourseSidebar_CourseStateFragment$key | null;
    underHeaderSlot?: ReactNode;
    submenuSlot: ReactNode;
    numUnseenCustomerMessages: number;
    newLicensesInAccount: boolean;
}

export const CourseSidebar = ({
                                  courseFragmentRef,
                                  courseStateFragmentRef,
                                  underHeaderSlot,
                                  submenuSlot,
                                  numUnseenCustomerMessages,
                                  newLicensesInAccount
                              }: OwnProps) => {

    const course = useFragment<CourseSidebar_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef);
    const courseState = useFragment<CourseSidebar_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, courseStateFragmentRef || null);

    return <MainSidebar
        className="course-sidebar"
        collapsed={true}
        numUnseenCustomerMessages={numUnseenCustomerMessages}
        hasNewLicensesInAccount={newLicensesInAccount}
        secondSidebarSlot={<div className="submenu-column d-flex flex-column">
            <div className="tk-bg-gradient-top-down pb-3" style={{height: 250}}>
                <div className="pr-5 pb-2 pl-5 mb-2 text-center course-image-wrapper">
                    <ImageWithFallback alt={course.containerMeta.title} src={course.image?.url || undefined}/>
                </div>
                {courseState ? <div className="pl-3 tk-academy-small-text tk-academy-bold">
                    Mein Lernfortschritt {courseState.progress.progressPercentage.toFixed(0)}%
                </div> : <div className="pl-3 tk-academy-small-text tk-academy-bold">
                    Lehrgang
                </div>}
                <div className="pl-3 tk-academy-large tk-academy-bold">
                    {course.containerMeta.title}
                </div>
                {underHeaderSlot ? underHeaderSlot : null}
            </div>
            {submenuSlot}
        </div>}/>
}
