/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ElementType = "clozeText" | "image" | "multipleChoice" | "order" | "persolog" | "questionnaire" | "resolution" | "text" | "video" | "%future added value";
export type ReviewContentArea_ElementFragment = {
    readonly elementPath: {
        readonly courseRef: string;
        readonly lessonRef: string;
    };
    readonly elementType: ElementType;
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"NotesScreenContent_ElementFragment">;
    readonly " $refType": "ReviewContentArea_ElementFragment";
};
export type ReviewContentArea_ElementFragment$data = ReviewContentArea_ElementFragment;
export type ReviewContentArea_ElementFragment$key = {
    readonly " $data"?: ReviewContentArea_ElementFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ReviewContentArea_ElementFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewContentArea_ElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ElementPath",
      "kind": "LinkedField",
      "name": "elementPath",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "courseRef",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lessonRef",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotesScreenContent_ElementFragment"
    }
  ],
  "type": "Element",
  "abstractKey": "__isElement"
};
(node as any).hash = 'e0ff1ef0cfa01708a353753ed6c32eee';
export default node;
