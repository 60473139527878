/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type LoginInput = {
    email: string;
    password: string;
    clientMutationId?: string | null;
};
export type BillingLoginV3_LoginMutationVariables = {
    login: LoginInput;
};
export type BillingLoginV3_LoginMutationResponse = {
    readonly Auth: {
        readonly login: {
            readonly login: {
                readonly user: {
                    readonly id: string;
                    readonly name: string;
                    readonly email: string;
                    readonly meta: {
                        readonly firstName: string;
                        readonly lastName: string;
                    } | null;
                };
                readonly accounts: ReadonlyArray<{
                    readonly accountId: string;
                    readonly name: string;
                    readonly roles: ReadonlyArray<string>;
                }>;
                readonly rolesInCurrentAccount: ReadonlyArray<string>;
                readonly token: string;
            };
        } | null;
    };
};
export type BillingLoginV3_LoginMutation = {
    readonly response: BillingLoginV3_LoginMutationResponse;
    readonly variables: BillingLoginV3_LoginMutationVariables;
};



/*
mutation BillingLoginV3_LoginMutation(
  $login: LoginInput!
) {
  Auth {
    login(input: $login) {
      login {
        user {
          id
          name
          email
          meta {
            firstName
            lastName
            id
          }
        }
        accounts {
          accountId
          name
          roles
        }
        rolesInCurrentAccount
        token
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "login"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "login"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "AccountInfo",
  "kind": "LinkedField",
  "name": "accounts",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountId",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roles",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rolesInCurrentAccount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BillingLoginV3_LoginMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthMutations",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "LoginPayload",
            "kind": "LinkedField",
            "name": "login",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LoginResponse",
                "kind": "LinkedField",
                "name": "login",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserMeta",
                        "kind": "LinkedField",
                        "name": "meta",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BillingLoginV3_LoginMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthMutations",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "LoginPayload",
            "kind": "LinkedField",
            "name": "login",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LoginResponse",
                "kind": "LinkedField",
                "name": "login",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserMeta",
                        "kind": "LinkedField",
                        "name": "meta",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c79378e727ff47667a190197a5a8ada0",
    "id": null,
    "metadata": {},
    "name": "BillingLoginV3_LoginMutation",
    "operationKind": "mutation",
    "text": "mutation BillingLoginV3_LoginMutation(\n  $login: LoginInput!\n) {\n  Auth {\n    login(input: $login) {\n      login {\n        user {\n          id\n          name\n          email\n          meta {\n            firstName\n            lastName\n            id\n          }\n        }\n        accounts {\n          accountId\n          name\n          roles\n        }\n        rolesInCurrentAccount\n        token\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ca0dd61f16ffcf90291081d3174c5c1c';
export default node;
