import {AcademyButton} from '../../../core/components/button/AcademyButton';
import React from 'react';
import {useFragment} from 'react-relay';
import {LessonNextButton_LessonFragment$key} from '../../../../__generated__/LessonNextButton_LessonFragment.graphql';
import {NavLink} from 'react-router-dom';
import {graphql} from "babel-plugin-relay/macro";
import {LessonPreviousButton_SubmissionFragment$key} from "../../../../__generated__/LessonPreviousButton_SubmissionFragment.graphql";

const LESSON_FRAGMENT = graphql`
    fragment LessonPreviousButton_LessonFragment on Lesson {
        id
        lessonPath {
            courseRef
            moduleRef
        }
        lessonType
        ... on TestLesson {
            isCertificationTest
        }
    }`

const SUBMISSION_FRAGMENT = graphql`
    fragment LessonPreviousButton_SubmissionFragment on Submission {
        submissionType
    }`

interface OwnProps {
    lessonFragmentRef: LessonNextButton_LessonFragment$key;
    submissionFragmentRef: LessonPreviousButton_SubmissionFragment$key;

    canGoBack: boolean;
    goBackward: () => void;
}

export const LessonPreviousButton = ({lessonFragmentRef, submissionFragmentRef, canGoBack, goBackward}: OwnProps) => {
    const lesson = useFragment<LessonNextButton_LessonFragment$key>(LESSON_FRAGMENT, lessonFragmentRef)

    const submission = useFragment<LessonPreviousButton_SubmissionFragment$key>(SUBMISSION_FRAGMENT, submissionFragmentRef)

    return canGoBack ?
        <AcademyButton
            text={'Zurück'}
            onClick={goBackward}/> : submission.submissionType !== 'certificationTest' ?
        <NavLink
            to={`/courses/${lesson.lessonPath.courseRef}/modules/${lesson.lessonPath.moduleRef}/lessons/${lesson.id}`}>
            <AcademyButton text={'Wiederholen'}/>
        </NavLink> : <div/>;
}