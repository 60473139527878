import {
    persologElementGraphQLToPersologElement, usePersologLogicV2

} from "@thekeytechnology/thekey-academy-frontend-library";
import {BaseElementDisplay} from "../common/BaseElementDisplay";
import React from "react";
import {useTranslation} from "react-i18next";
import {PersologAnswerDisplay} from "./PersologAnswerDisplay";
import "./persolog-element-display.scss"
import {AcademyButton} from "../../../core/components/button/AcademyButton";
import {PersologResultDisplay} from "./result/PersologResultDisplay";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import * as Sentry from '@sentry/react'
import {PersologElementDisplay_PersologElementFragment$key} from "../../../../__generated__/PersologElementDisplay_PersologElementFragment.graphql";

const PERSOLOG_ELEMENT_DISPLAY_PERSOLOG_ELEMENT_FRAGMENT = graphql`
    fragment PersologElementDisplay_PersologElementFragment on PersologElement {
        elementType
        elementPath {
            courseRef
            moduleRef
            lessonRef
        }
        generalSettings {
            points
        }
        id
        questions {
            answers {
                answer
                classCode
                ...PersologAnswerDisplay_PersologAnswerFragment
            }
            question
        }
        title
        ...PersologResultDisplay_PersologElementFragment
    }`

interface OwnProps {
    persologElementFragmentRef: PersologElementDisplay_PersologElementFragment$key;
}

export const PersologElementDisplay = ({
                                           persologElementFragmentRef
                                       }: OwnProps) => {
    const {t} = useTranslation("elementflow");

    const persologElement = useFragment<PersologElementDisplay_PersologElementFragment$key>(PERSOLOG_ELEMENT_DISPLAY_PERSOLOG_ELEMENT_FRAGMENT, persologElementFragmentRef)

    const {
        currentOrder,
        currentQuestionIndex,
        previousQuestion,
        nextQuestion,
        moveElement,
        isAtEnd,
        canGoBack,
        canAdvance,
        result
    } = usePersologLogicV2(persologElementGraphQLToPersologElement(persologElement))

    const question = persologElement.questions[currentQuestionIndex]

    const ButtonRow = <div className="d-flex flex-row mb-3">
        {canGoBack && <AcademyButton
            buttonStyle="outline"
            hideIcon={true}
            text={t("persolog-element-display.previous-question")}
            onClick={() => previousQuestion()}/>}
        {canAdvance && <AcademyButton
            className="ml-auto"
            text={t("persolog-element-display.next-question")}
            onClick={() => nextQuestion()}/>}
    </div>

    return <BaseElementDisplay
        className="persolog-element-display"
        elementSpecificSlot={
            <>
                <h1 className="tk-academy-larger-bold tk-color-darkblue text-center mb-3">{persologElement.title}</h1>
                {isAtEnd && result ? <>
                    <PersologResultDisplay persologElementFragmentRef={persologElement} result={result}/>
                </> : <>
                    <h2 className="tk-academy-larger tk-color-darkblue text-center mb-3">Frage {currentQuestionIndex + 1}: {question.question}</h2>
                    <p className="tk-academy-large tk-color-darkblue text-center mb-3">{t("persolog-element-display.sorting-instructions")}</p>

                    {ButtonRow}
                    <div className="mb-3">
                        {currentOrder.map((orderableIndex, index) => {
                            const answer = question.answers.length > orderableIndex ? question.answers[orderableIndex] : undefined

                            if (!answer)
                                Sentry.captureException(`PersologElement: answer is undefined (Prop. question.answers out of index [CourseRef: ${persologElement.elementPath.courseRef} | ModuleRef: ${persologElement.elementPath.moduleRef} | LessonRef: ${persologElement.elementPath.lessonRef}] | ElementRef: ${persologElement.id}`)

                            return answer ? <PersologAnswerDisplay
                                    key={answer.classCode}
                                    currentIndex={index}
                                    moveElement={moveElement}
                                    persologAnswerFragmentRef={answer}
                                /> :
                                null
                        })}
                    </div>
                    {ButtonRow}
                </>}
            </>
        }/>
}
