/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LessonDetailScreen_LessonStateFragment = {
    readonly " $fragmentRefs": FragmentRefs<"LessonInfo_LessonStateFragment">;
    readonly " $refType": "LessonDetailScreen_LessonStateFragment";
};
export type LessonDetailScreen_LessonStateFragment$data = LessonDetailScreen_LessonStateFragment;
export type LessonDetailScreen_LessonStateFragment$key = {
    readonly " $data"?: LessonDetailScreen_LessonStateFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LessonDetailScreen_LessonStateFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonDetailScreen_LessonStateFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LessonInfo_LessonStateFragment"
    }
  ],
  "type": "LessonState",
  "abstractKey": null
};
(node as any).hash = '4d5652dda7dd224cbe5ad796ea6e654d';
export default node;
