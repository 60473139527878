/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ApplicationRuleStatus = "applicationInProgress" | "applicationNecessary" | "canFreelyUse" | "cannotApply" | "duplicateApplicationNotPossible" | "noPool" | "poolEmpty" | "%future added value";
export type ApplyLicenseInput = {
    licensePoolId: string;
    userId?: string | null;
    courseId: string;
    licenseIdOpt?: string | null;
    clientMutationId?: string | null;
};
export type ApplyLicenseButton_ApplyLicenseMutationVariables = {
    licenseInput: ApplyLicenseInput;
    courseId: string;
};
export type ApplyLicenseButton_ApplyLicenseMutationResponse = {
    readonly License: {
        readonly applyLicense: {
            readonly licensePool: {
                readonly node: {
                    readonly id: string;
                    readonly applicationRuleStatus: ApplicationRuleStatus;
                };
            };
            readonly courseState: {
                readonly node: {
                    readonly id: string;
                };
            };
        } | null;
    };
};
export type ApplyLicenseButton_ApplyLicenseMutation = {
    readonly response: ApplyLicenseButton_ApplyLicenseMutationResponse;
    readonly variables: ApplyLicenseButton_ApplyLicenseMutationVariables;
};



/*
mutation ApplyLicenseButton_ApplyLicenseMutation(
  $licenseInput: ApplyLicenseInput!
  $courseId: ID!
) {
  License {
    applyLicense(input: $licenseInput) {
      licensePool {
        node {
          id
          applicationRuleStatus(courseId: $courseId)
        }
      }
      courseState {
        node {
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "courseId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "licenseInput"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "LicenseMutations",
    "kind": "LinkedField",
    "name": "License",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "licenseInput"
          }
        ],
        "concreteType": "ApplyLicensePayload",
        "kind": "LinkedField",
        "name": "applyLicense",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LicensePoolsEdge",
            "kind": "LinkedField",
            "name": "licensePool",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LicensePool",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "courseId",
                        "variableName": "courseId"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "applicationRuleStatus",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseStatesEdge",
            "kind": "LinkedField",
            "name": "courseState",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CourseState",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ApplyLicenseButton_ApplyLicenseMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ApplyLicenseButton_ApplyLicenseMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "a9cfb01d2fb050443e11d27cf1e1f4c6",
    "id": null,
    "metadata": {},
    "name": "ApplyLicenseButton_ApplyLicenseMutation",
    "operationKind": "mutation",
    "text": "mutation ApplyLicenseButton_ApplyLicenseMutation(\n  $licenseInput: ApplyLicenseInput!\n  $courseId: ID!\n) {\n  License {\n    applyLicense(input: $licenseInput) {\n      licensePool {\n        node {\n          id\n          applicationRuleStatus(courseId: $courseId)\n        }\n      }\n      courseState {\n        node {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '771cf0595665f6682929378432b739d1';
export default node;
