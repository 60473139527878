/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseContent_CourseFragment = {
    readonly id: string;
    readonly modules: ReadonlyArray<{
        readonly id: string;
        readonly lessons: ReadonlyArray<{
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"LessonCard_LessonFragment">;
        }>;
        readonly modulePath: {
            readonly moduleIndex: number;
        };
        readonly " $fragmentRefs": FragmentRefs<"LessonCard_ModuleFragment">;
    }>;
    readonly " $refType": "CourseContent_CourseFragment";
};
export type CourseContent_CourseFragment$data = CourseContent_CourseFragment;
export type CourseContent_CourseFragment$key = {
    readonly " $data"?: CourseContent_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseContent_CourseFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseContent_CourseFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Module",
      "kind": "LinkedField",
      "name": "modules",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "lessons",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "LessonCard_LessonFragment"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ModulePath",
          "kind": "LinkedField",
          "name": "modulePath",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "moduleIndex",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LessonCard_ModuleFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Course",
  "abstractKey": null
};
})();
(node as any).hash = '5d4a140a950e0dc524c33af410910071';
export default node;
