import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {PaymentDataFormPart_OrderFragment$key} from "../../../../../../__generated__/PaymentDataFormPart_OrderFragment.graphql";
import {StripeStyle} from "../../../../v2/components/payment/methods/StripeStyle";
import {CardElement, IbanElement} from "@stripe/react-stripe-js";
import React from "react";
import {useTranslation} from "react-i18next";
import {FormikProps} from "formik";
import {LargeText} from "../../../../../theme/Typography";
import styled from "styled-components";
import {ValidatedFieldV2} from "../../../../../core/components/form/ValidatedFieldV2";
import {ReviewFormState} from "./ReviewForm";

interface OwnProps {
    orderFragmentRef: PaymentDataFormPart_OrderFragment$key
    formikState: FormikProps<ReviewFormState>
}

export const PaymentDataFormPart = ({orderFragmentRef, formikState}: OwnProps) => {
    const {t} = useTranslation("billing")
    const order = useFragment<PaymentDataFormPart_OrderFragment$key>(graphql`
        fragment PaymentDataFormPart_OrderFragment on Order {
            selectedPaymentMethod {
                paymentMethodId
            }
        }
    `, orderFragmentRef)

    let PaymentOptionDisplay = null
    const error = formikState.errors.paymentMethodField

    switch (order.selectedPaymentMethod?.paymentMethodId) {
        case "card":
            PaymentOptionDisplay = <CardElement
                id="card-element"
                onChange={() => {
                    formikState.setFieldError("paymentMethodField", "")
                }}
                options={{
                    style: StripeStyle
                }}
            />
            break;
        case "sepa":
            PaymentOptionDisplay = <IbanElement id="sepa-element"
                                                onChange={() => {
                                                    formikState.setFieldError("paymentMethodField", "")
                                                }}
                                                options={{
                                                    supportedCountries: ["SEPA"],
                                                    style: StripeStyle
                                                }}/>
            break;
        case "monthlyTk":
        case "monthlyPartner":
            PaymentOptionDisplay = <ValidatedFieldV2<ReviewFormState, string>
                name="iban"
                required={true}
                component={(value, updateValue) =>
                    <input name={"iban"}
                           className="form-control default-input"
                           required
                           placeholder={t("checkout-form.iban")}
                           value={value}
                           onChange={event => {
                               updateValue(event.target.value)
                           }}/>
                }
                formikConfig={formikState}/>
    }

    return PaymentOptionDisplay ? <Container>
        <Heading>Zahlung
            per {t("payment-methods." + order.selectedPaymentMethod?.paymentMethodId)}</Heading>

        <PaymentInputWrapper>
            {PaymentOptionDisplay}
        </PaymentInputWrapper>

        {error ? (
            <div
                className="error-wrapper tk-academy-small-text tk-color-orange validation-error has-columns mt-3">
                <div/>
                <div/>
                <div>
                    {error}
                </div>
            </div>
        ) : null}
    </Container> : null;

}

const Heading = styled.h3`
  ${LargeText};
  font-weight: bold;
  margin-bottom: 15px;
  color: #184276;
`

const Container = styled.div`
  margin-bottom: 25px;
  border-top: solid 1px #e7ebf1;
  padding-top: 25px;
`

const PaymentInputWrapper = styled.div`
  border-radius: 1px;
  border: solid 1px #e9eef8;
  padding: 10px;
`
