import React, {useState} from "react";
import {UsersInAccount} from "../../model/UsersInAccountContainer";
import {CourseInformation} from "@thekeytechnology/thekey-academy-frontend-library";
import {
    DialogResult,
    EntityWrapper,
    selectCurrentAccount,
    selectCurrentUser,
    showDialogAction
} from "@thekeytechnology/framework-react";
import {ManagerCourseState} from "../../model/ManagerCourseState";
import {useTranslation} from "react-i18next";
import {InlineRolesEditor} from "./InlineRoleEditorField";
import {DateTimeDisplay} from "../../../core/components/display/DateTimeDisplay";
import {useDispatch, useSelector} from "react-redux";
import {removeUserFromAccountAction} from "../../action/remove-user-from-account";

interface OwnProps {
    courses: CourseInformation[]
    courseStates: EntityWrapper<ManagerCourseState>[]
    user: UsersInAccount
}

export const UserCard = ({user, courses, courseStates}: OwnProps) => {
    const {t} = useTranslation("manager")
    const dispatch = useDispatch();
    const currentUser = useSelector(selectCurrentUser)
    const currentAccount = useSelector(selectCurrentAccount)

    const [isOpen, setOpen] = useState(false)

    return <div className="tk-academy-card p-3 mb-3 flex-column d-flex user-card" key={user.userId}>
        <div className="user-card-header">
            <div>
                {currentUser?.id !== user.userId ?
                    <button onClick={() => {
                        dispatch(showDialogAction({
                            title: t("manager-main-page.remove-dialog.title", {accountName: currentAccount?.name}),
                            question: t("manager-main-page.remove-dialog.question", {userName: user.userName}),
                            explanation: t("manager-main-page.remove-dialog.explanation"),
                            affirmativeText: t("manager-main-page.remove-dialog.affirmativeText"),
                            negativeText: t("manager-main-page.remove-dialog.negativeText"),
                            affirmativeButtonClass: "btn btn-danger"
                        }, result => {
                            if (result === DialogResult.ACCEPT) {
                                dispatch(removeUserFromAccountAction(user.userId))
                            }
                        }))
                    }}
                            className="btn-link text-danger"><span className={"icon-a_trash"}/></button> : null}
            </div>
            <div>
                {user.userName}
            </div>
            <div className="align-items-end d-flex">
                <InlineRolesEditor userId={user.userId} roles={user.roles}/>
            </div>
            <div className="align-items-end d-flex">
                <button className="btn-link tk-academy-large ml-auto" onClick={() => setOpen(!isOpen)}>
                    <span className={isOpen ? "icon-a_arrow-up" : "icon-a_arrow-down"}/>
                </button>
            </div>
        </div>
        {isOpen ? <div className="pt-3">
            {courseStates.length === 0 ? <>Keine Kurse</> : <div className="course-row">
                <div className="tk-academy-small-text tk-academy-bold">{t("user-card.course")}</div>
                <div className="tk-academy-small-text tk-academy-bold">{t("user-card.progress")}</div>
                <div className="tk-academy-small-text tk-academy-bold">{t("user-card.performance")}</div>
                <div className="tk-academy-small-text tk-academy-bold">{t("user-card.certificate")}</div>
            </div>}
            {courseStates.map(cs => {
                const course = courses.find(c => c.id === cs.entity.courseRef)
                return <div key={user.userId + cs.entity.courseRef} className="course-row">
                    <div>
                        {course?.title}
                    </div>
                    <div>
                        {cs.entity.progress.progressPercentage}%
                    </div>
                    <div>
                        {cs.entity.progress.performancePercentage ? <>{cs.entity.progress.performancePercentage}%</> : t("user-card.no-value")}
                    </div>
                    <div>
                        {cs.entity.certificateData ?
                            <a target="_blank" rel="noopener noreferrer"
                               href={`https://www.thekey.career/zertifikat/${cs.entity.certificateData.certificateId}`}><DateTimeDisplay
                                dateTime={cs.entity.certificateData.issuedAt}/></a> : t("user-card.not-certified")}
                    </div>
                </div>
            })}
        </div> : null}
    </div>
}
