import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useRouteMatch} from "react-router";
import {OrderCart} from "./OrderCart";
import {PaymentMethods} from "../methods/PaymentMethods";
import {PurchaseFormV2} from "./PurchaseFormV2";
import {ThankYouV2} from "./ThankYouV2";
import {fetchOrderV2Action, PaymentMethodIds, selectOrderV2} from "@thekeytechnology/thekey-academy-frontend-library";
import {BillingBaseV2} from "../common/BillingBaseV2";


export const PaymentV2 = () => {
    const dispatch = useDispatch();

    const route = useRouteMatch<{ orderId: string }>();
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethodIds>(undefined)

    useEffect(() => {
        dispatch(fetchOrderV2Action(route.params.orderId))
    }, [dispatch, route.params.orderId])

    const order = useSelector(selectOrderV2)

    let Content;

    switch (order?.order.entity.status) {
        case "open":
            Content = <PurchaseFormV2 order={order} selectedPaymentMethod={paymentMethod}
                                      selectPaymentMethod={pm => setPaymentMethod(pm)}/>
            break;
        case "payment-in-process":
            Content = <ThankYouV2/>
            break;
    }

    return <BillingBaseV2
        mainSlot={Content}
        underHeadingSlot={order ? <OrderCart selectedPaymentMethod={paymentMethod} order={order}/> : null}
        sidebarSlot={<>
            <div className="checkout-card p-3 hide-mobile">
                <PaymentMethods include={order?.order.entity.allowedPaymentMethods}/>
            </div>
        </>}
    />;
}
