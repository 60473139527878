/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseAttributes = "earlyBird" | "hidden" | "new" | "published" | "%future added value";
export type CourseList_CoursesConnectionFragment = {
    readonly edges: ReadonlyArray<{
        readonly node: {
            readonly id: string;
            readonly attributes: ReadonlyArray<CourseAttributes>;
            readonly " $fragmentRefs": FragmentRefs<"CourseCard_CourseFragment">;
        };
    } | null> | null;
    readonly " $refType": "CourseList_CoursesConnectionFragment";
};
export type CourseList_CoursesConnectionFragment$data = CourseList_CoursesConnectionFragment;
export type CourseList_CoursesConnectionFragment$key = {
    readonly " $data"?: CourseList_CoursesConnectionFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseList_CoursesConnectionFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseList_CoursesConnectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CoursesEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Course",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "attributes",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CourseCard_CourseFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CoursesConnection",
  "abstractKey": null
};
(node as any).hash = '531e704cd40f2a011d16c449eda0c607';
export default node;
