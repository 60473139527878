import React from "react";
import "./checkbox.scss";

interface OwnProps {
    className?: string;
    isChecked: boolean;
    onToggle: (newSelection: boolean) => void;
}

export const Checkbox = ({
                             className,
                             isChecked,
                             onToggle,
                         }: OwnProps) => {

    return <div className={`checkbox-wrapper ${className ? className : ""}`}
                onClick={event => {
                    onToggle(!isChecked)
                    return false;
                }}
    >
        <div className={`checkbox ${isChecked ? "is-checked" : ""}`}>
            {isChecked ? <span className="icon-a_tick"/> : null}
        </div>
    </div>
}
