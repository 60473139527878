/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ReviewProcessCurrentElementScreen_CourseFragment = {
    readonly " $fragmentRefs": FragmentRefs<"ReviewContentArea_CourseFragment" | "ReviewHeader_CourseFragment" | "ReviewSidebar_CourseFragment">;
    readonly " $refType": "ReviewProcessCurrentElementScreen_CourseFragment";
};
export type ReviewProcessCurrentElementScreen_CourseFragment$data = ReviewProcessCurrentElementScreen_CourseFragment;
export type ReviewProcessCurrentElementScreen_CourseFragment$key = {
    readonly " $data"?: ReviewProcessCurrentElementScreen_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ReviewProcessCurrentElementScreen_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewProcessCurrentElementScreen_CourseFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReviewContentArea_CourseFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReviewHeader_CourseFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReviewSidebar_CourseFragment"
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = '1c127ed4d758677647ccebbc5d74df68';
export default node;
