import {PaymentOption} from "./PaymentOption";
import {CardElement, IbanElement} from "@stripe/react-stripe-js";
import {StripeStyle} from "./StripeStyle";
import React from "react";
import {
    OrderContainer,
    PaymentMethodIds,
    paymentMethodIsMonthly
} from "@thekeytechnology/thekey-academy-frontend-library";


interface OwnProps {
    order: OrderContainer
    selectedPaymentMethod?: PaymentMethodIds
    selectPaymentMethod: (paymentMethod: PaymentMethodIds) => void;

    setFieldError: (fieldName: string, error: string) => void;
    errors: any;
}

export const PaymentOptionsFormPart = ({
                                           order,
                                           selectedPaymentMethod,
                                           selectPaymentMethod,
                                           setFieldError,
                                           errors
                                       }: OwnProps) => {
    const nonMonthlyPaymentMethods = order.order.entity.allowedPaymentMethods.filter(pm => !paymentMethodIsMonthly(pm));

    return <>
        {nonMonthlyPaymentMethods.map(paymentMethod => {
            switch (paymentMethod) {
                case "card":
                    return <PaymentOption
                        key={paymentMethod}
                        paymentMethod={paymentMethod}

                        isSelected={paymentMethod === selectedPaymentMethod}
                        onSelected={() => {
                            setFieldError("paymentMethodField", "")
                            selectPaymentMethod(paymentMethod)
                        }}
                        price={order.order.entity.priceTable.total}
                        monthlyPrice={order.order.entity.priceTable.monthlyTotal}
                        errors={errors.paymentMethodField}
                        paymentFieldData={<CardElement
                            id="card-element"
                            onChange={() => {
                                setFieldError("paymentMethodField", "")
                            }}
                            options={{
                                style: StripeStyle
                            }}
                        />
                        }

                    />
                case "giropay":
                    return <PaymentOption
                        key={paymentMethod}
                        paymentMethod={paymentMethod}

                        isSelected={paymentMethod === selectedPaymentMethod}
                        onSelected={() => {
                            setFieldError("paymentMethodField", "")
                            selectPaymentMethod(paymentMethod)
                        }}
                        price={order.order.entity.priceTable.total}
                        monthlyPrice={order.order.entity.priceTable.monthlyTotal}
                        errors={errors.paymentMethodField}
                    />
                case "paypal":
                    return <PaymentOption
                        key={paymentMethod}
                        paymentMethod={paymentMethod}

                        isSelected={paymentMethod === selectedPaymentMethod}
                        onSelected={() => {
                            setFieldError("paymentMethodField", "")
                            selectPaymentMethod(paymentMethod)
                        }}
                        price={order.order.entity.priceTable.total}
                        monthlyPrice={order.order.entity.priceTable.monthlyTotal}
                        errors={errors.paymentMethodField}/>
                case "sepa":
                    return <PaymentOption
                        key={paymentMethod}
                        paymentMethod={paymentMethod}

                        isSelected={paymentMethod === selectedPaymentMethod}
                        onSelected={() => {
                            setFieldError("paymentMethodField", "")
                            selectPaymentMethod(paymentMethod)
                        }}
                        price={order.order.entity.priceTable.total}
                        monthlyPrice={order.order.entity.priceTable.monthlyTotal}
                        errors={errors.paymentMethodField}
                        paymentFieldData={<IbanElement id="sepa-element"
                                                       onChange={() => {
                                                           setFieldError("paymentMethodField", "")
                                                       }}
                                                       options={{
                                                           supportedCountries: ["SEPA"],
                                                           style: StripeStyle
                                                       }}/>
                        }
                    />
                case "sofort":
                    return <PaymentOption
                        key={paymentMethod}
                        paymentMethod={paymentMethod}

                        isSelected={paymentMethod === selectedPaymentMethod}
                        onSelected={() => {
                            setFieldError("paymentMethodField", "")
                            selectPaymentMethod(paymentMethod)
                        }}
                        price={order.order.entity.priceTable.total}
                        monthlyPrice={order.order.entity.priceTable.monthlyTotal}
                        errors={errors.paymentMethodField}/>
                case "invoice-partner":
                case "invoice-tk":
                    return <PaymentOption
                        key={paymentMethod}
                        paymentMethod={paymentMethod}

                        isSelected={paymentMethod === selectedPaymentMethod}
                        onSelected={() => {
                            setFieldError("paymentMethodField", "")
                            selectPaymentMethod(paymentMethod)
                        }}
                        price={order.order.entity.priceTable.total}
                        monthlyPrice={order.order.entity.priceTable.monthlyTotal}
                        errors={errors.paymentMethodField}/>
            }
            return null;
        })}
    </>
}
