import { useSelector } from "react-redux";
import { selectCurrentLesson } from "../../../../lesson/selectors";
import { selectLearnCurrentElement } from "@thekeytechnology/thekey-academy-frontend-library";
import React from "react";
import { ProgressBar } from "../../../../core/components/progress/ProgressBar";
import "./progress-bottom-bar.scss"

export const ProgressBottomBar = () => {
    const lesson = useSelector(selectCurrentLesson)
    const element = useSelector(selectLearnCurrentElement);

    if (!lesson || !element) {
        return null;
    }
    const elementsInLesson = lesson?.lesson.entity.elements.length;
    const elementIndex = lesson?.lesson.entity.elements.indexOf(element)

    const percentageBoost = elementsInLesson < 5 ? 10 : 5

    return <div className="w-100 progress-bottom-bar">
        <ProgressBar
            dotsAtPercentages={lesson.lesson.entity.elements.map((el, index) => {
                const percentage = (index / elementsInLesson) * 100;
                return percentage + percentageBoost
            })}
            percentage100={(elementIndex / elementsInLesson * 100) + percentageBoost}
            showPercentage={false}/>
    </div>
}
