/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NotesScreenContent_ElementFragment = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"NoteModal_ElementFragment">;
    readonly " $refType": "NotesScreenContent_ElementFragment";
};
export type NotesScreenContent_ElementFragment$data = NotesScreenContent_ElementFragment;
export type NotesScreenContent_ElementFragment$key = {
    readonly " $data"?: NotesScreenContent_ElementFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"NotesScreenContent_ElementFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotesScreenContent_ElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NoteModal_ElementFragment"
    }
  ],
  "type": "Element",
  "abstractKey": "__isElement"
};
(node as any).hash = 'fb7f6b89b3ac6563fbc76e9106f3b2ea';
export default node;
