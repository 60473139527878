import React, {useContext, useEffect, useState} from "react";
import {
    ElementFlowContext
} from "@thekeytechnology/thekey-academy-frontend-library";
import {AcademyButton} from "../../../core/components/button/AcademyButton";

export const ReviewFooter = () => {
    const {flowState: {currentElementId, currentTry}, trySubmit} = useContext(ElementFlowContext)

    const [blockTemporarily, setBlockTemporarily] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setBlockTemporarily(false);
        }, 1000);
        return () => {
            clearTimeout(timer);
            setBlockTemporarily(true);
        }
    }, [currentElementId]);

    const isSubmissionPossible = !!currentTry

    const disabled = blockTemporarily || !isSubmissionPossible

    return <div className={`learn-footer d-flex justify-content-end p-3 position-relative`}>
        <AcademyButton
            disabled={disabled}
            onClick={() => {
                trySubmit()
            }}
            text={"Weiter"}
        />
    </div>

}
