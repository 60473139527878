import React from "react";
import "./points-display.scss";
import { showCelebrationAction } from "@thekeytechnology/thekey-academy-frontend-library";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Keys from "./keys.png";

interface OwnProps {
    points: number;
}

export const PointsDisplay = ({points}: OwnProps) => {
    const {t} = useTranslation("lessons")
    const dispatch = useDispatch();

    return <button
        onClick={() => {
            dispatch(showCelebrationAction({
                text: t("lesson-points.celebration.text"),
                explanation: t("lesson-points.celebration.explanation", {pointsCount: points}),
                title: t("lesson-points.celebration.title"),
                image: Keys
            }))
        }}
        className="btn-link points-display tk-color-waterblue-80 tk-academy-small-text align-items-center d-flex tk-academy-bold">
        <span>
            {points}
        </span>
        <span className="icon-c_key-coin ml-1"/>
    </button>
}
