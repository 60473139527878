import React, {useState} from "react";
import {useFragment, useLazyLoadQuery, useSubscribeToInvalidationState} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {SubmissionFinishedScreen_Query} from "../../../__generated__/SubmissionFinishedScreen_Query.graphql";
import {SubmissionFinishedScreen_SubmissionFragment$key} from "../../../__generated__/SubmissionFinishedScreen_SubmissionFragment.graphql";
import {MultiPageScreen} from "../../core/components/dynamic/MultiPageScreen";
import {ResultDisplay} from "./ResultDisplay";
import {LessonPreviousButton} from "./footer/LessonPreviousButton";
import {ReviewNextButton} from "./footer/ReviewNextButton";
import {LessonNextButton} from "./footer/LessonNextButton";
import {FailedElementsDisplay} from "./failed-elements/FailedElementsDisplay";
import {SubmissionFinishedScreen_CourseStateFragment$key} from "../../../__generated__/SubmissionFinishedScreen_CourseStateFragment.graphql";
import {ReviewPreviousButton} from "./footer/ReviewPreviousButton";
import {useRouteMatch} from "react-router";
import {BlueBackground} from "../../core/components/screen/BlueBackground";
import {Screen} from "../../core/components/screen/Screen";
import "./submission-finished-screen.scss"
import {SubmissionFinishedSidebar} from "./SubmissionFinishedSidebar";
import {SubmissionFinishedHeader} from "./SubmissionFinishedHeader";
import {CelebrationPopup} from "../../celebration/components/CelebrationPopup";

const QUERY = graphql`
    query SubmissionFinishedScreen_Query($submissionId: ID!, $courseId: ID!) {
        node(id: $submissionId) {
            id
            ... on Submission {
                ...SubmissionFinishedScreen_SubmissionFragment
            }
        }
        Viewer {
            CourseState {
                CourseState(Input: {courseId: $courseId}) {
                    ...SubmissionFinishedScreen_CourseStateFragment
                }
            }
        }
        Viewer {
            License {
                LicensePools {
                    ...LessonNextButton_LicensePoolsFragment
                    ...CelebrationPopup_LicensePools
                }
            }
        }
    }`

const SUBMISSION_FRAGMENT = graphql`
    fragment SubmissionFinishedScreen_SubmissionFragment on Submission {
        courseRef
        submissionType
        result {
            grading
            failedElements {
                element {
                    id
                }
            }
            ...FailedElementsDisplay_SubmissionResultFragment
        }
        ... on LessonSubmission {
            lesson {
                ...LessonNextButton_LessonFragment
                ...LessonPreviousButton_LessonFragment
            }
        }
        ...LessonNextButton_SubmissionFragment
        ...LessonPreviousButton_SubmissionFragment
        ...ResultDisplay_SubmissionFragment
        ...SubmissionFinishedHeader_SubmissionFragment
        ...SubmissionFinishedSidebar_SubmissionFragment
    }`

const COURSE_STATE_FRAGMENT = graphql`
    fragment SubmissionFinishedScreen_CourseStateFragment on CourseState {
        id
        course {
            ...LessonNextButton_CourseFragment
            ...CelebrationPopup_CourseFragment
        }
        ...CelebrationPopup_CourseStateFragment
        ...LessonNextButton_CourseStateFragment
    }`

interface State {
    fetchKey: number
}

interface OwnProps {
    courseId: string
    submissionId: string
}

export const SubmissionFinishedScreenComponent = ({courseId, submissionId}: OwnProps) => {

    const [state, setState] = useState<State>({fetchKey: 0})

    const submissionFinishedScreenQuery = useLazyLoadQuery<SubmissionFinishedScreen_Query>(QUERY, {
        courseId,
        submissionId
    }, {fetchPolicy: "network-only", fetchKey: state.fetchKey})

    const submission = useFragment<SubmissionFinishedScreen_SubmissionFragment$key>(SUBMISSION_FRAGMENT, submissionFinishedScreenQuery.node)

    const courseState = useFragment<SubmissionFinishedScreen_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, submissionFinishedScreenQuery.Viewer.CourseState.CourseState)

    useSubscribeToInvalidationState([courseState?.id].filter(id => !!id).map(id => id!), () => {
        setState(state => ({...state, fetchKey: state.fetchKey + 1}))
    })

    if (!submission) return null

    const pages = [<ResultDisplay submissionFragmentRef={submission}/>]

    if (submission.result.failedElements.length > 0 && submission.submissionType !== "certificationTest") {
        pages.push(<FailedElementsDisplay submissionResultFragmentRef={submission.result}/>)
    }
    return <Screen
        sidebarSlot={<SubmissionFinishedSidebar submissionFragmentRef={submission}/>}
        headerBarBackgroundSlot={
            <BlueBackground
                buttonSlot={<SubmissionFinishedHeader submissionFragmentRef={submission}/>}
                height={90}/>
        }
        floatingFooterSlot={null}
        contentAreaRenderer={(children) => <div
            className="d-flex flex-row flex-nowrap flex-grow-1 submission-finished-content-area"
            style={{marginTop: 60}}>{children}</div>
        }>
        <MultiPageScreen
            previousButtonSlot={(canGoBack, isLastPage, goBackward) =>
                submission.submissionType === 'review' ?
                    <ReviewPreviousButton
                        courseId={submission.courseRef}
                        canGoBack={canGoBack}
                        goBackward={goBackward}/> : <LessonPreviousButton
                        lessonFragmentRef={submission.lesson!}
                        submissionFragmentRef={submission}
                        canGoBack={canGoBack}
                        goBackward={goBackward}/>
            }
            nextButtonSlot={(canGoBack, isLastPage, goForward) =>
                submission.submissionType === 'review' ?
                    <ReviewNextButton
                        courseId={submission.courseRef}
                        canGoBack={canGoBack}
                        isLastPage={isLastPage}
                        goForward={goForward}
                    /> : <LessonNextButton
                        courseFragmentRef={courseState!.course}
                        courseStateFragmentRef={courseState!}
                        lessonFragmentRef={submission.lesson!}
                        licensePoolsFragmentRef={submissionFinishedScreenQuery.Viewer.License.LicensePools}
                        submissionFragmentRef={submission}
                        canGoBack={canGoBack}
                        isLastPage={isLastPage}
                        goForward={goForward}/>
            }
            pages={pages}
            initialPage={0}
        />
        <CelebrationPopup courseFragmentRef={courseState!.course}
                          courseStateFragmentRef={courseState!}
                          licensePoolsFragmentRef={submissionFinishedScreenQuery.Viewer.License.LicensePools}/>
    </Screen>;
}

export const SubmissionFinishedScreen = () => {
    const router = useRouteMatch<{ courseId?: string, submissionId?: string }>();

    if (!router.params.courseId || !router.params.submissionId) {
        return null;
    }

    return <SubmissionFinishedScreenComponent courseId={router.params.courseId}
                                              submissionId={router.params.submissionId}/>;
}
