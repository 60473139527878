import {connect} from "react-redux";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import React, {useEffect} from "react";
import {AcademyButton} from "../../core/components/button/AcademyButton";
import {useTranslation} from "react-i18next";
import {
    acknowledgeAchievementAction,
    fetchUnacknowledgedAchievementsAction,
    RewardedAchievement,
    selectAchievementForDisplay
} from "@thekeytechnology/thekey-academy-frontend-library";
import {CelebrationModal} from "../../celebration/components/CelebrationModal";

interface AchievementPopup {
    achievement?: EntityWrapper<RewardedAchievement>
}

interface DispatchProps {
    acknowledgeAchievement: typeof acknowledgeAchievementAction
    fetchUnacknowledgedAchievements: typeof fetchUnacknowledgedAchievementsAction
}

type Props = AchievementPopup & DispatchProps;

export const AchievementPopupComponent = ({
                                              achievement,
                                              acknowledgeAchievement,
                                              fetchUnacknowledgedAchievements
                                          }: Props) => {

    const {t} = useTranslation("achievements")

    useEffect(() => {
        fetchUnacknowledgedAchievements()
    }, [fetchUnacknowledgedAchievements])

    if (!achievement) {
        return null;
    }

    return <CelebrationModal title={achievement.entity.title}
                             text={achievement.entity.textGotten}
                             explanation={achievement.entity.explanation}
                             image={achievement.entity.image?.entity.url}
                             onHide={() => acknowledgeAchievement(achievement?.id!)}>
        <AcademyButton
            text={t("achievement-popup.close")}
            onClick={() => acknowledgeAchievement(achievement?.id!)}/>
    </CelebrationModal>
}

export const AchievementGotPopup = connect<{}, DispatchProps, {}>(
    (state: any) => ({
        achievement: selectAchievementForDisplay(state)
    }),
    ({
        acknowledgeAchievement: acknowledgeAchievementAction,
        fetchUnacknowledgedAchievements: fetchUnacknowledgedAchievementsAction
    })
)(AchievementPopupComponent)
