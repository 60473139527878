import styled, {css} from "styled-components";


export const LargeText = css`
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  line-height: 1.5;
`
export const NormalText = css`
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  line-height: 1.43;
`

export const LinkText = styled.div`
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  line-height: 1.43;
  margin: 10px 0;
  color: #466791;
  cursor: pointer;
`
