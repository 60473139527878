export const academyColorsForStyledComps = {
    dark100: `
        color: #031d3c;
     `,
    lightBlue100: `
        color: #247dc5;
    `,
    waterblue80: `
        color: #466791;
    `,
    white: `
        color: white;
    `
}