import {useLocation} from "react-router";
import React, {useEffect} from "react";
import {trackPageView} from "./analytics";
import * as Sentry from "@sentry/react";

export const PageViewTracker = () => {
    const location = useLocation();
    useEffect(() => {
        try {
            trackPageView(location.pathname)
        } catch (e) {
            Sentry.captureException(e)
        }
    }, [location])

    return <></>
}
