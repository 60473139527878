/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseCard_CourseFragment = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"UpperCourseCard_CourseFragment">;
    readonly " $refType": "CourseCard_CourseFragment";
};
export type CourseCard_CourseFragment$data = CourseCard_CourseFragment;
export type CourseCard_CourseFragment$key = {
    readonly " $data"?: CourseCard_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseCard_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseCard_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpperCourseCard_CourseFragment"
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = '314ce518d61d6f19d2f0978d72c6ba39';
export default node;
