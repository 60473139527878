import {AcademyButton} from '../../../core/components/button/AcademyButton';
import React from 'react';
import {NavLink} from "react-router-dom"

interface OwnProps {
    courseId: string

    canGoBack: boolean
    goBackward: () => void
}

export const ReviewPreviousButton = ({courseId, canGoBack, goBackward}: OwnProps) => {
    return canGoBack ?
        <AcademyButton text={'Zurück'} onClick={goBackward}/> :
        <NavLink to={`/courses/${courseId}/review`}><AcademyButton text={'Wiederholen'}/></NavLink>
}
