import React, {ReactNode} from "react";
import {MenuHeader} from "../components/header/MenuHeader";

interface OwnProps {
    className?: string;
    collapsed?: boolean;
    secondSidebarSlot?: ReactNode;
}

export const LoadingFakeMainSidebar = ({
                                           className,
                                           collapsed,
                                           secondSidebarSlot
                                       }: OwnProps) => {
    return <div className={(className ? className : "") + " flex-row flex-nowrap"}>
        <div className="academy-sidebar menu-column h-100 d-flex flex-column">
            <MenuHeader collapsed={collapsed} className="p-0 tk-bg-waterblue"/>
            <div className="tk-bg-keyblue flex-grow-1"/>
            <div className="tk-bg-keyblue"/>
        </div>
        {secondSidebarSlot ? secondSidebarSlot : null}
    </div>;
}
