import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {ReviewCart_CartFragment$key} from "../../../../../__generated__/ReviewCart_CartFragment.graphql";
import React from "react";
import {ReviewCartItem} from "./ReviewCartItem";

interface OwnProps {
    cartFragmentRef: ReviewCart_CartFragment$key
}

export const ReviewCart = ({cartFragmentRef}: OwnProps) => {
    const cart = useFragment<ReviewCart_CartFragment$key>(graphql`
        fragment ReviewCart_CartFragment on Cart {
            cartItems {
                ...ReviewCartItem_CartItemFragment
            }

        }
    `, cartFragmentRef)

    return <div>
        {cart.cartItems.map((item, index) => {
            return <ReviewCartItem key={index} cartItemFragmentRef={item}/>
        })}

    </div>

}
