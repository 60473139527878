/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Grading = "bronze" | "fail" | "gold" | "noPoints" | "perfect" | "silver" | "%future added value";
export type SubmissionType = "certificationTest" | "learn" | "review" | "test" | "%future added value";
export type SubmissionFinishedScreen_SubmissionFragment = {
    readonly courseRef: string;
    readonly submissionType: SubmissionType;
    readonly result: {
        readonly grading: Grading;
        readonly failedElements: ReadonlyArray<{
            readonly element: {
                readonly id: string;
            };
        }>;
        readonly " $fragmentRefs": FragmentRefs<"FailedElementsDisplay_SubmissionResultFragment">;
    };
    readonly lesson?: {
        readonly " $fragmentRefs": FragmentRefs<"LessonNextButton_LessonFragment" | "LessonPreviousButton_LessonFragment">;
    };
    readonly " $fragmentRefs": FragmentRefs<"LessonNextButton_SubmissionFragment" | "LessonPreviousButton_SubmissionFragment" | "ResultDisplay_SubmissionFragment" | "SubmissionFinishedHeader_SubmissionFragment" | "SubmissionFinishedSidebar_SubmissionFragment">;
    readonly " $refType": "SubmissionFinishedScreen_SubmissionFragment";
};
export type SubmissionFinishedScreen_SubmissionFragment$data = SubmissionFinishedScreen_SubmissionFragment;
export type SubmissionFinishedScreen_SubmissionFragment$key = {
    readonly " $data"?: SubmissionFinishedScreen_SubmissionFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"SubmissionFinishedScreen_SubmissionFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubmissionFinishedScreen_SubmissionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "courseRef",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submissionType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SubmissionResult",
      "kind": "LinkedField",
      "name": "result",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grading",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FailedElement",
          "kind": "LinkedField",
          "name": "failedElements",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "element",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FailedElementsDisplay_SubmissionResultFragment"
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "lesson",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "LessonNextButton_LessonFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "LessonPreviousButton_LessonFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "type": "LessonSubmission",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LessonNextButton_SubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LessonPreviousButton_SubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ResultDisplay_SubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubmissionFinishedHeader_SubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubmissionFinishedSidebar_SubmissionFragment"
    }
  ],
  "type": "Submission",
  "abstractKey": "__isSubmission"
};
(node as any).hash = 'c1bd3dea5d888f98cf88021191cfe111';
export default node;
