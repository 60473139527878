import React, {Suspense, useEffect} from "react";
import {GlossaryItem} from "./GlossaryItem";
import {SearchField} from "./SearchField";
import {AcademyButton} from "../../core/components/button/AcademyButton";
import {graphql} from "babel-plugin-relay/macro";
import {usePaginationFragment, usePreloadedQuery, useQueryLoader} from "react-relay";
import {GlossaryDisplay_Query} from "../../../__generated__/GlossaryDisplay_Query.graphql";
import {GlossaryFragmentRefetchQuery} from "../../../__generated__/GlossaryFragmentRefetchQuery.graphql";
import {GlossaryDisplay_GlossaryFragment$key} from "../../../__generated__/GlossaryDisplay_GlossaryFragment.graphql";
import {PreloadedQuery} from "react-relay/relay-hooks/EntryPointTypes";
import {LoadingAnimation} from "../../billing/v2/components/common/LoadingAnimation";

const NUM_GLOSSARY_ENTRIES_PER_PAGE = 1;

const QUERY = graphql`
    query GlossaryDisplay_Query($courseId: ID!, $queryString: String, $after: String, $first: Int!) {
        ...GlossaryDisplay_GlossaryFragment @arguments(courseId: $courseId, queryString: $queryString, after: $after, first: $first)
    }`

const GLOSSARY_FRAGMENT = graphql`
    fragment GlossaryDisplay_GlossaryFragment on Query @refetchable(queryName: "GlossaryFragmentRefetchQuery") @argumentDefinitions(
        courseId: {type: "ID"},
        queryString: {type: "String"},
        after: {type: "String"},
        first: {type: "Int"}
    ){
        Glossary {
            GlossaryEntries: Glossary(
                after: $after,
                courseId: $courseId,
                first: $first,
                queryString: $queryString
            ) @connection(key: "GlossaryDisplay_GlossaryEntries") {
                edges {
                    node {
                        ...GlossaryItem_GlossaryEntryFragment
                    }
                }
            }
        }
    }`

interface GlossaryEntriesDisplayOwnProps {
    queryRef: PreloadedQuery<GlossaryDisplay_Query>;
}

const GlossaryEntriesDisplay = ({queryRef}: GlossaryEntriesDisplayOwnProps) => {
    const query = usePreloadedQuery<GlossaryDisplay_Query>(QUERY, queryRef);

    const {
        data: glossaryEntriesData,
        loadNext: loadMoreGlossaryEntries,
        hasNext: hasMoreGlossaryEntries
    } = usePaginationFragment<GlossaryFragmentRefetchQuery, GlossaryDisplay_GlossaryFragment$key>(GLOSSARY_FRAGMENT, query)

    const glossaryEntries = glossaryEntriesData.Glossary.GlossaryEntries.edges?.filter(e => !!e).map(e => e!.node) || [];

    return <>
        {
            glossaryEntries.map((gE, index) => {
                return <GlossaryItem key={index} glossaryEntryFragmentRef={gE}/>
            })
        }
        {
            hasMoreGlossaryEntries ?
                <div className="pb-4 d-flex justify-content-center ">
                    <AcademyButton text={"mehr laden..."}
                                   hideIcon={true}
                                   onClick={() => loadMoreGlossaryEntries(NUM_GLOSSARY_ENTRIES_PER_PAGE)}
                                   buttonStyle={"outline"}/>
                </div> : null
        }</>
}

interface GlossaryDisplayOwnProps {
    courseId: string;
}

export const GlossaryDisplay = ({courseId}: GlossaryDisplayOwnProps) => {

    const [queryRef, loadQuery] = useQueryLoader<GlossaryDisplay_Query>(QUERY);

    useEffect(() => {
        loadQuery({courseId: courseId, queryString: null, after: null, first: NUM_GLOSSARY_ENTRIES_PER_PAGE})
    }, [courseId, loadQuery])

    return <>
        <div className="d-flex align-items-center mb-5">
            <div className="tk-academy-extra-large-heading-bold">Glossar</div>
            <SearchField textChanged={newValue => {
                loadQuery({
                    courseId: courseId,
                    queryString: newValue,
                    after: null,
                    first: NUM_GLOSSARY_ENTRIES_PER_PAGE
                })
            }}/>
        </div>
        {queryRef ?
            <Suspense fallback={<LoadingAnimation/>}>
                <GlossaryEntriesDisplay queryRef={queryRef}/>
            </Suspense>
            : <LoadingAnimation/>}
    </>
}
