import React from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {GlossaryItem_GlossaryEntryFragment$key} from "../../../__generated__/GlossaryItem_GlossaryEntryFragment.graphql";

const GLOSSARY_ITEM_GLOSSARY_ENTRY_FRAGMENT = graphql`
    fragment GlossaryItem_GlossaryEntryFragment on GlossaryEntry {
        additionalLinks
        definition
        title
    }`

interface OwnProps {
    glossaryEntryFragmentRef: GlossaryItem_GlossaryEntryFragment$key;
}

export const GlossaryItem = ({glossaryEntryFragmentRef}: OwnProps) => {

    const glossaryEntry = useFragment<GlossaryItem_GlossaryEntryFragment$key>(GLOSSARY_ITEM_GLOSSARY_ENTRY_FRAGMENT, glossaryEntryFragmentRef)

    return <div className="tk-academy-card p-4 mb-5">
        <div className="tk-academy-large-bold tk-color-darkblue">{glossaryEntry.title}</div>
        <div className="tk-academy-text tk-color-waterblue-80">{glossaryEntry.definition}</div>
        {glossaryEntry.additionalLinks.length ? <ul className="pl-3">
            {glossaryEntry.additionalLinks.map((link, index) =>
                <li key={index}>
                    <a href={link}
                       rel="noopener noreferrer"
                       target="_blank">{link}</a>
                </li>)}
        </ul> : null}
    </div>
}
