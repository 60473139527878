/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseDetailFooter_LicensePoolsFragment = {
    readonly " $fragmentRefs": FragmentRefs<"AdvanceCourseButton_LicensePoolsFragment">;
    readonly " $refType": "CourseDetailFooter_LicensePoolsFragment";
};
export type CourseDetailFooter_LicensePoolsFragment$data = CourseDetailFooter_LicensePoolsFragment;
export type CourseDetailFooter_LicensePoolsFragment$key = {
    readonly " $data"?: CourseDetailFooter_LicensePoolsFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseDetailFooter_LicensePoolsFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseDetailFooter_LicensePoolsFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdvanceCourseButton_LicensePoolsFragment"
    }
  ],
  "type": "LicensePoolsConnection",
  "abstractKey": null
};
(node as any).hash = 'aa973963d94c40e72910085a4b6a4f77';
export default node;
