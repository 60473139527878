import React from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";

interface OwnProps {
    date?: string;
}


export const DateDisplay = ({date}: OwnProps) => {
    const {t} = useTranslation("core")

    if (!date) {
        return null;
    }

    const formattedDate = moment(date).format(t("date-display.format"));

    return (
        <>
            {formattedDate}
        </>
    );
};

