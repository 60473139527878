import React from "react";
import styled from "styled-components";

interface OwnProps {
    className?: string
    url?: string
}

export const ReviewProductImage = ({url, className}: OwnProps) => {
    return url ?
        <ProductImageDisplay className={className} src={url} height={55} width={55}
                             alt={""}/> :
        <ProductImageEmpty className={className}/>
}

const ProductImageDisplay = styled.img`
  height: 55px;
  width: 55px;
  border-radius: 15px;
`

const ProductImageEmpty = styled.div`
  height: 0;
  width: 0;
`
