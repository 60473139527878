import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {Formik} from "formik";
import * as yup from "yup";
import {BasicModal} from "../../core/components/modal/BasicModal";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {AcademyButton} from "../../core/components/button/AcademyButton";
import {ValidatedField} from "../../core/components/form/ValidatedField";
import {useDispatch} from "react-redux";
import {Message, postMessageAction} from "@thekeytechnology/framework-react";
import {NoteContextV2} from "@thekeytechnology/thekey-academy-frontend-library";
import {ElementDisplay} from "../../elementFlow/components/ElementDisplay";
import {NoteModal_NoteFragment$key} from "../../../__generated__/NoteModal_NoteFragment.graphql";
import {NoteModal_PostNoteMutation} from "../../../__generated__/NoteModal_PostNoteMutation.graphql";
import {NoteModal_ElementFragment$key} from "../../../__generated__/NoteModal_ElementFragment.graphql";
import "./note-modal.scss"
import {NoteLocationHeading} from "./NoteLocationHeading";
import {NoteModal_CourseFragment$key} from "../../../__generated__/NoteModal_CourseFragment.graphql";
import {NoteModal_LessonFragment$key} from "../../../__generated__/NoteModal_LessonFragment.graphql";
import {NoteModal_DeleteNoteMutation} from "../../../__generated__/NoteModal_DeleteNoteMutation.graphql";

const POST_NOTE_MUTATION = graphql`
    mutation NoteModal_PostNoteMutation($noteId: ID, $courseId: ID!, $lessonId: ID, $elementId: ID, $title: String!, $content: String!, $videoTimestamp: Int, $notesConnectionIds: [ID!]!) {
        Notes {
            postNote(input: {nodeId: $noteId, courseId: $courseId, lessonId: $lessonId, elementId: $elementId, title: $title, content: $content, videoTimestamp: $videoTimestamp}) {
                note @appendEdge(connections: $notesConnectionIds){
                    node {
                        ...NoteItem_NoteFragment
                        ...NoteModal_NoteFragment
                    }
                }
            }
        }
    }`

const DELETE_NOTE_MUTATION = graphql`
    mutation NoteModal_DeleteNoteMutation($id: ID!, $notesConnectionIds: [ID!]!) {
        Notes {
            deleteNote(input: {nodeId: $id}) {
                deletedNoteId @deleteEdge(connections: $notesConnectionIds)
            }
        }
    }`;

const NOTE_FRAGMENT = graphql`
    fragment NoteModal_NoteFragment on Note {
        course {
            id
            ...NoteLocationHeading_CourseFragment
        }
        lesson {
            id
            ...NoteLocationHeading_LessonFragment
        }
        element {
            id
            elementType
            ...ElementDisplay_ElementFragment
        }
        content
        id
        title
        videoTimestamp
    }`

const COURSE_FRAGMENT = graphql`
    fragment NoteModal_CourseFragment on Course {
        id
        ...NoteLocationHeading_CourseFragment
    }`

const LESSON_FRAGMENT = graphql`
    fragment NoteModal_LessonFragment on Lesson {
        id
        ...NoteLocationHeading_LessonFragment
    }`

const ELEMENT_FRAGMENT = graphql`
    fragment NoteModal_ElementFragment on Element {
        id
        elementType
        ...ElementDisplay_ElementFragment
    }`

const isElementVisualizable = (elementType: string) => ["video", "text", "image"].includes(elementType)

interface OwnProps {
    courseFragmentRef?: NoteModal_CourseFragment$key | null;
    lessonFragmentRef?: NoteModal_LessonFragment$key | null;
    elementFragmentRef?: NoteModal_ElementFragment$key | null;
    onClose: () => void;
    notesConnectionId: string;
    editedNoteFragmentRef?: NoteModal_NoteFragment$key | null;
}

export const NoteModal = ({
                              courseFragmentRef,
                              lessonFragmentRef,
                              elementFragmentRef,
                              onClose,
                              notesConnectionId,
                              editedNoteFragmentRef
                          }: OwnProps) => {
    const {t} = useTranslation('notes')
    const dispatch = useDispatch();

    const editedNote = useFragment<NoteModal_NoteFragment$key>(NOTE_FRAGMENT, editedNoteFragmentRef || null);
    const [postNote] = useMutation<NoteModal_PostNoteMutation>(POST_NOTE_MUTATION)
    const [deleteNote] = useMutation<NoteModal_DeleteNoteMutation>(DELETE_NOTE_MUTATION);

    const course = useFragment<NoteModal_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef || null)
    const lesson = useFragment<NoteModal_LessonFragment$key>(LESSON_FRAGMENT, lessonFragmentRef || null)
    const element = useFragment<NoteModal_ElementFragment$key>(ELEMENT_FRAGMENT, elementFragmentRef || null)

    const videoTimestamp = useContext(NoteContextV2)?.noteContext.videoTimestamp;

    return <BasicModal centered={true} onHide={() => onClose()} dialogClassName="note-modal" show={true}>
        <div className="p-4">
            <div className="pb-2">
                {editedNote ?
                    <NoteLocationHeading courseFragmentRef={editedNote.course} lessonFragmentRef={editedNote.lesson}/> :
                    (course && <NoteLocationHeading courseFragmentRef={course} lessonFragmentRef={lesson}/>)}
            </div>

            {
                editedNote?.element && isElementVisualizable(editedNote.element.elementType) ?
                    <ElementDisplay
                        elementFragmentRef={editedNote.element}
                        videoElementSpecific={{timestamp: editedNote.videoTimestamp || undefined}}
                    /> : element && isElementVisualizable(element.elementType) ?
                        <ElementDisplay
                            elementFragmentRef={element}
                            videoElementSpecific={{timestamp: videoTimestamp}}
                        /> : <p className="mb-3">{t(`edit-notes-modal-content.explanation`)}</p>
            }

            <Formik
                initialValues={{
                    title: editedNote ? editedNote.title : "",
                    content: editedNote ? editedNote.content : ""
                }}
                enableReinitialize={true}
                validationSchema={
                    yup.object().shape({
                        title: yup
                            .string()
                            .min(1)
                            .required(t("core:forms.required-field", {fieldName: t("edit-note-form.title")})),
                        content: yup
                            .string()
                            .min(1)
                            .required(t("core:forms.required-field", {fieldName: t("edit-note-form.content")})),
                    })
                }
                onSubmit={(values, {setSubmitting}) => {
                    if (!editedNote && !course) {
                        setSubmitting(false);
                        onClose();
                        return;
                    }

                    postNote({
                        variables: {
                            noteId: editedNote?.id,
                            courseId: editedNote ? editedNote.course.id : course!.id,
                            lessonId: editedNote ? editedNote.lesson?.id : lesson?.id,
                            elementId: editedNote ? editedNote.element?.id : element?.id,
                            title: values.title,
                            content: values.content,
                            videoTimestamp: editedNote ? editedNote.videoTimestamp : videoTimestamp,
                            notesConnectionIds: [notesConnectionId],
                        }, onCompleted: () => {
                            setSubmitting(false);
                            onClose();
                            dispatch(postMessageAction(Message.TYPE_SUCCESS, t("dispatched-messages.note-posted")));
                        }
                    })
                }}
            >
                {formikState => (
                    <div>
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            placeholder={t("edit-note-form.title-placeholder")}
                                            name="title"
                                            className="form-control default-input"
                                            required/>
                        </div>
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            placeholder={t("edit-note-form.content-placeholder")}
                                            name="content"
                                            component="textarea"
                                            className="form-control default-input"
                                            required/>
                        </div>
                        <div className={"d-flex flex-row"}>
                            {editedNote && <AcademyButton
                                onClick={() => {
                                    onClose();
                                    deleteNote({
                                        variables: {
                                            id: editedNote.id,
                                            notesConnectionIds: [notesConnectionId]
                                        }, onCompleted: _ => {
                                            dispatch(postMessageAction(Message.TYPE_SUCCESS, t("dispatched-messages.note-deleted")))
                                        }
                                    });
                                }} text={t("edit-note-form.delete")}
                                buttonStyle={"danger"}/>}
                            <AcademyButton
                                className="ml-auto"
                                onClick={() => formikState.handleSubmit()}
                                disabled={!formikState.isValid || formikState.isSubmitting}
                                text={t("edit-note-form.submit")}
                                type={"submit"}/>
                        </div>
                    </div>
                )}
            </Formik>
        </div>
    </BasicModal>
}

