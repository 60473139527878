import React from 'react'
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {LinkButton_CustomerMessageDefinitionLinkButtonFragment$key} from "../../../../../__generated__/LinkButton_CustomerMessageDefinitionLinkButtonFragment.graphql";
import {ButtonWithTitle} from "./ButtonWithTitle";

const CUSTOMER_MESSAGE_DEFINITION_LINK_BUTTON_FRAGMENT = graphql`
    fragment LinkButton_CustomerMessageDefinitionLinkButtonFragment on CustomerMessageDefinitionLinkButton {
        title
        link
    }`

interface OwnProps {
    onClose: () => void
    modalWidth: number
    customerMessageDefinitionLinkButtonFragmentRef: LinkButton_CustomerMessageDefinitionLinkButtonFragment$key
}

export const LinkButton = ({onClose, modalWidth, customerMessageDefinitionLinkButtonFragmentRef}: OwnProps) => {
    const button = useFragment<LinkButton_CustomerMessageDefinitionLinkButtonFragment$key>(CUSTOMER_MESSAGE_DEFINITION_LINK_BUTTON_FRAGMENT, customerMessageDefinitionLinkButtonFragmentRef)

    return <ButtonWithTitle title={button.title} onClick={() => {
        onClose()
        window.open(button.link)
    }} modalWidth={modalWidth}/>
}