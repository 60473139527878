/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NoteModal_CourseFragment = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"NoteLocationHeading_CourseFragment">;
    readonly " $refType": "NoteModal_CourseFragment";
};
export type NoteModal_CourseFragment$data = NoteModal_CourseFragment;
export type NoteModal_CourseFragment$key = {
    readonly " $data"?: NoteModal_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"NoteModal_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NoteModal_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NoteLocationHeading_CourseFragment"
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = 'a16d79f7a86e02cd97b3c9c0c87bbefe';
export default node;
