import React from 'react';
import {AcademyButton} from '../../../../core/components/button/AcademyButton';
import {useTranslation} from 'react-i18next';
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {PersologClassExplanation_PersologClassFragment$key} from "../../../../../__generated__/PersologClassExplanation_PersologClassFragment.graphql";

const PERSOLOG_CLASS_EXPLANATION_PERSOLOG_CLASS_FRAGMENT = graphql`
    fragment PersologClassExplanation_PersologClassFragment on PersologClass {
        explanation
        image {
            url
        }
        title
    }`

interface OwnProps {
    onClose: () => void;
    persologClassFragmentRef: PersologClassExplanation_PersologClassFragment$key;
}

export const PersologClassExplanation = ({onClose, persologClassFragmentRef}: OwnProps) => {
    const {t} = useTranslation('elementflow')

    const persologClass = useFragment<PersologClassExplanation_PersologClassFragment$key>(PERSOLOG_CLASS_EXPLANATION_PERSOLOG_CLASS_FRAGMENT, persologClassFragmentRef)

    const ButtonRow = <div className="mb-3">
        <AcademyButton
            icon={'a_back'}
            buttonStyle={"outline"}
            text={t('persolog-element-display.result.back-to-results')}
            onClick={() => onClose()}/>
    </div>

    return <div className="persolog-class-explanation text-center">
        {ButtonRow}
        <h2 className="tk-academy-larger-bold tk-color-darkblue text-center mb-3">{persologClass.title}</h2>

        {persologClass.image ? <img className="mb-3" src={persologClass.image.url || undefined} alt={""}/> : null}
        <div dangerouslySetInnerHTML={{__html: persologClass.explanation}}/>
        {ButtonRow}
    </div>
}
