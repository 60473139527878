import {matchesAnyOfTheseApiSuccesses,} from "@thekeytechnology/framework-react";
import {combineEpics} from "redux-observable";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Action} from "redux";
import {API_START_COURSE} from "../../state/actions/start-course";
import {fetchUnacknowledgedAchievementsAction} from "@thekeytechnology/thekey-academy-frontend-library";

const fetchAchievementsOnCertainActions$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({apiType: API_START_COURSE}),
    map(() => fetchUnacknowledgedAchievementsAction())
)

export const additionalAchievementModuleEpics$ = combineEpics(
    fetchAchievementsOnCertainActions$
);

