import React from "react";
import "./course-card.scss";
import {NavLink} from "react-router-dom";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {CourseCard_CourseFragment$key} from "../../../../../__generated__/CourseCard_CourseFragment.graphql";
import {UpperCourseCard} from "../UpperCourseCard";
import {CourseCard_CourseStateFragment$key} from "../../../../../__generated__/CourseCard_CourseStateFragment.graphql";
import {AcademyCard} from "../../../../theme/AcademyCard";
import styled from "styled-components";

const COURSE_FRAGMENT = graphql`
    fragment CourseCard_CourseFragment on Course {
        id
        ...UpperCourseCard_CourseFragment
    }
`

const COURSE_STATE_FRAGMENT = graphql`
    fragment CourseCard_CourseStateFragment on CourseState {
        ...UpperCourseCard_CourseStateFragment
    }
`

interface OwnProps {
    courseFragmentRef: CourseCard_CourseFragment$key;
    courseStateFragmentRef?: CourseCard_CourseStateFragment$key;
    courseStatus: string;
}

export const CourseCard = ({
                               courseFragmentRef,
                               courseStateFragmentRef,
                               courseStatus
                           }: OwnProps) => {

    const course = useFragment<CourseCard_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef)
    const courseState = useFragment<CourseCard_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, courseStateFragmentRef || null)

    return <NavLink to={`/courses/${course.id}`}>
        <StyledCourseCard>
            <UpperCourseCard courseFragmentRef={course} courseStateFragmentRef={courseState}
                             courseStatus={courseStatus}
                             showProgressBarOrTestItForFreeContainer={true}
                             showBorderBottom={false}/>

        </StyledCourseCard>
    </NavLink>
}

const StyledCourseCard = styled(AcademyCard)`
    padding: 20px;
`
