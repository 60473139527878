import React, {ReactElement, useState} from "react";
import {useTranslation} from "react-i18next";
import {AddButton} from "./AddButton";
import {EditQnaModal} from "./EditQnaModal";
import {QnaItem} from "./QnaItem";
import {useLazyLoadQuery, usePaginationFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {QnasScreen_Query} from "../../../__generated__/QnasScreen_Query.graphql";
import {QnasScreen_QueryFragment$key} from "../../../__generated__/QnasScreen_QueryFragment.graphql";
import {QnasScreenQueryFragmentRefetchQuery} from "../../../__generated__/QnasScreenQueryFragmentRefetchQuery.graphql";

const QNAS_SCREEN_QUERY = graphql`
    query QnasScreen_Query($courseId: ID!, $elementId: ID, $lessonId: ID) {
        ...QnasScreen_QueryFragment @arguments(first: null, after: null, courseId: $courseId, lessonId: $lessonId, elementId: $elementId)
    }`

const QNAS_SCREEN_QUERY_FRAGMENT = graphql`
    fragment QnasScreen_QueryFragment on Query @refetchable(queryName: "QnasScreenQueryFragmentRefetchQuery") @argumentDefinitions(
        after: {type: "String"}
        courseId: {type: "ID"}
        elementId: {type: "ID"}
        first: {type: "Int"}
        lessonId: {type: "ID"}
    ) {
        Qnas {
            Qnas(first: $first, after: $after, courseId: $courseId, lessonId: $lessonId, elementId: $elementId) @connection(key: "QnasScreen_Query_Qnas") {
                __id
                edges {
                    node {
                        id
                        ...QnaItem_QnaFragment
                    }
                }
            }
        }
    }`

interface State {
    modalVisible: boolean;
}

interface OwnProps {
    className?: string;
    courseId: string;
    elementId?: string;
    headingSlot?: ReactElement;
    lessonId?: string;
}

export const QnasScreen = ({className, courseId, elementId, headingSlot, lessonId}: OwnProps) => {

    const qnasScreenQuery = useLazyLoadQuery<QnasScreen_Query>(QNAS_SCREEN_QUERY, {courseId: courseId, elementId: elementId, lessonId: lessonId});

    const {data: qnasData} = usePaginationFragment<QnasScreenQueryFragmentRefetchQuery, QnasScreen_QueryFragment$key>(QNAS_SCREEN_QUERY_FRAGMENT, qnasScreenQuery);

    const qnasConnection = qnasData.Qnas.Qnas;
    const qnas = qnasConnection?.edges?.filter(e => !!e).map(e => e!.node) || [];

    const {t} = useTranslation("qnas")

    const [state, setState] = useState<State>({
        modalVisible: false
    });

    let content;
    if (qnas.length === 0) {
        content = <div className="d-flex align-items-center flex-column">
            <div className="icon-a_text tk-academy-extra-large-heading mb-3 tk-color-darkblue"/>
            <div className="tk-academy-larger-bold tk-black mb-2">{t("qnas-tab.empty-list.title")}</div>
            <div className="tk-academy-text tk-color-darkblue">{t("qnas-tab.empty-list.explanation")}</div>
        </div>;
    } else {
        content = qnas.map(qna => {
            return <QnaItem key={qna.id} qnaFragmentRef={qna}/>
        });
    }

    return <div className={"d-flex flex-column " + className}>
        {headingSlot ? headingSlot : null}
        {content}
        <AddButton onAdd={() => {
            setState({
                ...state,
                modalVisible: true
            })
        }}/>

        {state.modalVisible && qnasConnection ? <EditQnaModal
            courseId={courseId}
            elementId={elementId}
            lessonId={lessonId}
            onClose={() => setState({...state, modalVisible: false})}
            qnasConnectionId={qnasConnection.__id}
        /> : null}
    </div>
}
