import React, {useCallback, useEffect} from "react";
import {RedirectToMobileAppScreen} from "../../../core/components/appscreen/RedirectToMobileAppScreen";
import {LoginForm} from "./LoginForm";
import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {LoginScreen_LoginMutation} from "../../../../__generated__/LoginScreen_LoginMutation.graphql";
import {API_LOGIN, apiCallSucceededAction, LoginResponse} from "@thekeytechnology/framework-react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router";
import {LoginScreenContainer} from "./LoginScreenContainer";
import {trackViewLogin} from "../../../analytics/analytics";
import * as Sentry from "@sentry/react";

const LOGIN_MUTATION = graphql`
    mutation LoginScreen_LoginMutation($login: LoginInput!) {
        Auth {
            login(input: $login) {
                login {
                    user {
                        id
                        name
                        email
                    }
                    accounts {
                        accountId
                        name
                        roles
                    }
                    rolesInCurrentAccount
                    token
                }
            }
        }
    }`

export const LoginScreen = () => {

    const dispatch = useDispatch()

    const history = useHistory()

    const [login, loginIsInFlight] = useMutation<LoginScreen_LoginMutation>(LOGIN_MUTATION)

    useEffect(() => {
        try {
            trackViewLogin()
        } catch (e) {
            Sentry.captureException(e)
        }
    }, [])

    const doLogin = useCallback((email: string, password: string) => login({
        variables: {login: {email: email, password: password}},
        onCompleted: response => {
            dispatch(apiCallSucceededAction({apiType: API_LOGIN}, {
                token: response.Auth.login?.login.token,
                accounts: response.Auth.login?.login.accounts,
                user: {
                    id: response.Auth.login?.login.user.id,
                    entity: {
                        id: response.Auth.login?.login.user.id,
                        email: response.Auth.login?.login.user.email,
                        name: response.Auth.login?.login.user.name,
                        roles: response.Auth.login?.login.rolesInCurrentAccount
                    }
                }
            } as LoginResponse))
        }
        // eslint-disable-next-line
    }), [])

    return <>
        <LoginScreenContainer>
            <LoginForm
                login={doLogin}
                loginIsInFlight={loginIsInFlight}
                switchToRegistration={() => history.push('/register')}
                showForgotPassword={true}
            />
        </LoginScreenContainer>
        <RedirectToMobileAppScreen/>
    </>
}
