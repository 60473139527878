/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CustomerMessageModal_AcknowledgeCustomerMessagesMutationVariables = {
    customerMessageId: string;
};
export type CustomerMessageModal_AcknowledgeCustomerMessagesMutationResponse = {
    readonly Viewer: {
        readonly CustomerMessageV2: {
            readonly acknowledgeCustomerMessagesV2: {
                readonly customerMessages: {
                    readonly edges: ReadonlyArray<{
                        readonly node: {
                            readonly id: string;
                            readonly hasSeen: boolean;
                        };
                    } | null> | null;
                };
                readonly customerMessageInformation: {
                    readonly id: string;
                    readonly numUnseenCustomerMessages: number;
                };
            } | null;
        };
    };
};
export type CustomerMessageModal_AcknowledgeCustomerMessagesMutation = {
    readonly response: CustomerMessageModal_AcknowledgeCustomerMessagesMutationResponse;
    readonly variables: CustomerMessageModal_AcknowledgeCustomerMessagesMutationVariables;
};



/*
mutation CustomerMessageModal_AcknowledgeCustomerMessagesMutation(
  $customerMessageId: ID!
) {
  Viewer {
    CustomerMessageV2 {
      acknowledgeCustomerMessagesV2(input: {customerMessageIds: [$customerMessageId]}) {
        customerMessages {
          edges {
            node {
              id
              hasSeen
            }
          }
        }
        customerMessageInformation {
          id
          numUnseenCustomerMessages
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customerMessageId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ViewerMutationType",
    "kind": "LinkedField",
    "name": "Viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomerMessageV2ViewerMutationSchema",
        "kind": "LinkedField",
        "name": "CustomerMessageV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "fields": [
                  {
                    "items": [
                      {
                        "kind": "Variable",
                        "name": "customerMessageIds.0",
                        "variableName": "customerMessageId"
                      }
                    ],
                    "kind": "ListValue",
                    "name": "customerMessageIds"
                  }
                ],
                "kind": "ObjectValue",
                "name": "input"
              }
            ],
            "concreteType": "AcknowledgeCustomerMessagesV2Payload",
            "kind": "LinkedField",
            "name": "acknowledgeCustomerMessagesV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerMessagesV2Connection",
                "kind": "LinkedField",
                "name": "customerMessages",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerMessagesV2Edge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomerMessageV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasSeen",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerMessageInformation",
                "kind": "LinkedField",
                "name": "customerMessageInformation",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numUnseenCustomerMessages",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerMessageModal_AcknowledgeCustomerMessagesMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomerMessageModal_AcknowledgeCustomerMessagesMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "7d469b3e5133fde6fa965b485c61e4f4",
    "id": null,
    "metadata": {},
    "name": "CustomerMessageModal_AcknowledgeCustomerMessagesMutation",
    "operationKind": "mutation",
    "text": "mutation CustomerMessageModal_AcknowledgeCustomerMessagesMutation(\n  $customerMessageId: ID!\n) {\n  Viewer {\n    CustomerMessageV2 {\n      acknowledgeCustomerMessagesV2(input: {customerMessageIds: [$customerMessageId]}) {\n        customerMessages {\n          edges {\n            node {\n              id\n              hasSeen\n            }\n          }\n        }\n        customerMessageInformation {\n          id\n          numUnseenCustomerMessages\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '044e2b26c25cf68c33d80a92fbce8f98';
export default node;
