import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Formik} from "formik";
import * as yup from "yup";
import {ValidatedField} from "../../core/components/form/ValidatedField";
import {AcademyButton} from "../../core/components/button/AcademyButton";
import {BasicModal} from "../../core/components/modal/BasicModal";
import {useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {FeedbackModal_PostFeedbackMutation} from "../../../__generated__/FeedbackModal_PostFeedbackMutation.graphql";
import {Message, postMessageAction} from "@thekeytechnology/framework-react";

const FEEDBACK_MODAL_POST_FEEDBACK_MUTATION = graphql`
    mutation FeedbackModal_PostFeedbackMutation($courseId: ID, $elementId: ID, $feedback: String!, $lessonId: ID, $location: String, $source: String) {
        Feedback {
            postFeedback(input: {courseId: $courseId, elementId: $elementId, feedback: $feedback, lessonId: $lessonId, location: $location, source:$source}) {
                clientMutationId
            }
        }
    }
`;

interface OwnProps {
    courseId?: string,
    lessonId?: string,
    elementId?: string;
    screen?: string;
    onClose: () => void;
}

export const FeedbackModal = ({
                                  courseId,
                                  lessonId,
                                  elementId,
                                  screen,
                                  onClose
                              }: OwnProps
) => {

    const [postFeedback, postFeedbackIsInFlight] = useMutation<FeedbackModal_PostFeedbackMutation>(FEEDBACK_MODAL_POST_FEEDBACK_MUTATION)

    const dispatch = useDispatch();

    const {t} = useTranslation("feedback");

    return <BasicModal centered={true} onHide={() => onClose()} dialogClassName="post-feedback-modal" show={true}>
        <div className="post-feedback-modal-content p-4">
            <h1 className="tk-academy-larger-bold tk-color-darkblue">{t(`feedback-modal-content.heading`)}</h1>
            <p className="mb-3 tk-academy-text">{t(`feedback-modal-content.explanation`)}</p>

            <Formik
                initialValues={{
                    feedback: ""
                }}
                enableReinitialize={true}
                validationSchema={
                    yup.object().shape({
                        feedback: yup
                            .string()
                            .min(1)
                            .required(t("core:forms.required-field", {fieldName: t("post-feedback-form.error")})),
                    })
                }
                onSubmit={values => {
                    postFeedback({
                        variables: {
                            courseId: courseId,
                            elementId: elementId,
                            feedback: values.feedback,
                            lessonId: lessonId,
                            location: screen,
                            source: "webapp"
                        },
                        onCompleted: () => {
                            onClose();
                            dispatch(postMessageAction(Message.TYPE_SUCCESS, "messages.feedback-posted"))
                        }
                    })
                }}
            >
                {formikState => (
                    <div>
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            placeholder={t("feedback-form.placeholder")}
                                            name="feedback"
                                            component="textarea"
                                            className="form-control default-input"
                                            required/>
                        </div>
                        <AcademyButton
                            className="ml-auto"
                            onClick={() => formikState.handleSubmit()}
                            disabled={!formikState.isValid || formikState.isSubmitting || postFeedbackIsInFlight}
                            text={t("feedback-form.submit")}
                            type={"submit"}/>
                    </div>
                )}
            </Formik>

        </div>
    </BasicModal>
};

