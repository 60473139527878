/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LessonStateStatus = "current" | "finished" | "%future added value";
export type LessonCard_LessonStateFragment = {
    readonly lastUpdate: string;
    readonly points: number;
    readonly status: LessonStateStatus;
    readonly " $fragmentRefs": FragmentRefs<"LessonImage_LessonStateFragment">;
    readonly " $refType": "LessonCard_LessonStateFragment";
};
export type LessonCard_LessonStateFragment$data = LessonCard_LessonStateFragment;
export type LessonCard_LessonStateFragment$key = {
    readonly " $data"?: LessonCard_LessonStateFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LessonCard_LessonStateFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonCard_LessonStateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "points",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LessonImage_LessonStateFragment"
    }
  ],
  "type": "LessonState",
  "abstractKey": null
};
(node as any).hash = 'ff15de2c245264ff51e5cccadc26af08';
export default node;
