import React, {useCallback, useState} from "react";
import {AcademyButton} from "../../../core/components/button/AcademyButton";
import {AchievementData, GenericAchievementImage} from "@thekeytechnology/thekey-academy-frontend-library";
import "./achievement-item.scss";
import {CelebrationModal} from "../../../celebration/components/CelebrationModal";

interface OwnProps {
    hasAchievement: boolean;
    achievement: AchievementData
}

export const AchievementItem = ({
                                    hasAchievement,
                                    achievement
                                }: OwnProps) => {

    const [state, setState] = useState({
        modalVisible: false
    });

    const hideModal = useCallback(() => {
        setState({...state, modalVisible: false})
    }, [state]);

    return <>

        <div className="achievement-item mr-5 mb-5">
            <button className="btn-link" onClick={() => setState({...state, modalVisible: true})}>
                <div
                    className={`image-wrapper d-flex justify-content-center align-items-center ${hasAchievement ? "has-rank" : ""}`}>
                    <img alt={achievement.title}
                         src={achievement.image ? achievement.image.entity.url : GenericAchievementImage}/>
                    {hasAchievement ? <div className="tick">
                        <span className={"icon-a_tick_circle"}/>
                    </div> : null}
                </div>
                <div className="title">{achievement.title}</div>
            </button>
        </div>
        {state.modalVisible ?
            <CelebrationModal
                title={achievement.title}
                explanation={achievement.explanation}
                text={hasAchievement ? achievement.textGotten : achievement.text}
                image={achievement.image?.entity.url}
                onHide={() => hideModal()}>
                <AcademyButton text={"Ok"} onClick={hideModal}/>
            </CelebrationModal>
            : null}
    </>
}
