import React from "react";
import {RouteAuthState, RouteDefinition} from "../../core/components/routing/RouteDefinition";
import {OrderFlowFromCartBase64Screen} from "./components/OrderFlowFromCartBase64Screen";
import {LoginErrorBoundary} from "./components/LogginErrorBoundary";
import {OrderFlowFromIdScreen} from "./components/OrderFlowFromIdScreen";

export const BillingV3RouteDefinitions: RouteDefinition[] = [
    {
        path: "/checkout/orders-v3/from-cart/:cartBase64?",
        component: (props) => <OrderFlowFromCartBase64Screen cartBase64={props.match?.params.cartBase64}/>,
        authState: RouteAuthState.Independent
    },
    {
        path: "/checkout/orders-v3/:orderId",
        component: (props) => <LoginErrorBoundary>
            <OrderFlowFromIdScreen orderId={props.match?.params.orderId}/>
        </LoginErrorBoundary>,
        authState: RouteAuthState.Independent
    },
]