import React, {useEffect, useRef} from "react";
import {ReviewForm} from "./forms/ReviewForm";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {ReviewStep_OrderFragment$key} from "../../../../../__generated__/ReviewStep_OrderFragment.graphql";
import {ReviewCart} from "./ReviewCart";
import {Separator} from "../cart/CartStep";
import {ReviewCartTotalsDisplay} from "./ReviewCartTotalsDisplay";
import styled from "styled-components";

interface OwnProps {
    orderFragmentRef: ReviewStep_OrderFragment$key
}

export const ReviewStep = ({orderFragmentRef}: OwnProps) => {
    const order = useFragment<ReviewStep_OrderFragment$key>(graphql`
        fragment ReviewStep_OrderFragment on Order {
            ...ReviewForm_OrderFragment
            ...ReviewCartTotalsDisplay_OrderFragment

            cart {
                ...ReviewCart_CartFragment
            }
        }
    `, orderFragmentRef)

    const ref = useRef<any>(null)

    useEffect(() => {
        ref.current?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }, [])

    return <Container ref={ref}>
        <Separator/>

        <ReviewCart cartFragmentRef={order.cart}/>
        <ReviewCartTotalsDisplay orderFragmentRef={order}/>
        <ReviewForm orderFragmentRef={order}/>
    </Container>
}

const Container = styled.div`
  scroll-margin: 50px;
`
