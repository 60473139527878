/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type GlossaryDisplay_QueryVariables = {
    courseId: string;
    queryString?: string | null;
    after?: string | null;
    first: number;
};
export type GlossaryDisplay_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"GlossaryDisplay_GlossaryFragment">;
};
export type GlossaryDisplay_Query = {
    readonly response: GlossaryDisplay_QueryResponse;
    readonly variables: GlossaryDisplay_QueryVariables;
};



/*
query GlossaryDisplay_Query(
  $courseId: ID!
  $queryString: String
  $after: String
  $first: Int!
) {
  ...GlossaryDisplay_GlossaryFragment_2ns3KT
}

fragment GlossaryDisplay_GlossaryFragment_2ns3KT on Query {
  Glossary {
    GlossaryEntries: Glossary(after: $after, courseId: $courseId, first: $first, queryString: $queryString) {
      edges {
        node {
          ...GlossaryItem_GlossaryEntryFragment
          id
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}

fragment GlossaryItem_GlossaryEntryFragment on GlossaryEntry {
  additionalLinks
  definition
  title
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "courseId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "queryString"
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "courseId",
    "variableName": "courseId"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "queryString",
    "variableName": "queryString"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GlossaryDisplay_Query",
    "selections": [
      {
        "args": (v4/*: any*/),
        "kind": "FragmentSpread",
        "name": "GlossaryDisplay_GlossaryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "GlossaryDisplay_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GlossaryQueries",
        "kind": "LinkedField",
        "name": "Glossary",
        "plural": false,
        "selections": [
          {
            "alias": "GlossaryEntries",
            "args": (v4/*: any*/),
            "concreteType": "GlossariesConnection",
            "kind": "LinkedField",
            "name": "Glossary",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GlossariesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GlossaryEntry",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "additionalLinks",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "definition",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "GlossaryEntries",
            "args": (v4/*: any*/),
            "filters": [
              "courseId",
              "queryString"
            ],
            "handle": "connection",
            "key": "GlossaryDisplay_GlossaryEntries",
            "kind": "LinkedHandle",
            "name": "Glossary"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c36a281561e0496fc9dbbe13af8f2634",
    "id": null,
    "metadata": {},
    "name": "GlossaryDisplay_Query",
    "operationKind": "query",
    "text": "query GlossaryDisplay_Query(\n  $courseId: ID!\n  $queryString: String\n  $after: String\n  $first: Int!\n) {\n  ...GlossaryDisplay_GlossaryFragment_2ns3KT\n}\n\nfragment GlossaryDisplay_GlossaryFragment_2ns3KT on Query {\n  Glossary {\n    GlossaryEntries: Glossary(after: $after, courseId: $courseId, first: $first, queryString: $queryString) {\n      edges {\n        node {\n          ...GlossaryItem_GlossaryEntryFragment\n          id\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n\nfragment GlossaryItem_GlossaryEntryFragment on GlossaryEntry {\n  additionalLinks\n  definition\n  title\n}\n"
  }
};
})();
(node as any).hash = '34dbfab72cd563ce8586839971f078c9';
export default node;
