/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type NoteModal_DeleteNoteMutationVariables = {
    id: string;
    notesConnectionIds: Array<string>;
};
export type NoteModal_DeleteNoteMutationResponse = {
    readonly Notes: {
        readonly deleteNote: {
            readonly deletedNoteId: string;
        } | null;
    };
};
export type NoteModal_DeleteNoteMutation = {
    readonly response: NoteModal_DeleteNoteMutationResponse;
    readonly variables: NoteModal_DeleteNoteMutationVariables;
};



/*
mutation NoteModal_DeleteNoteMutation(
  $id: ID!
) {
  Notes {
    deleteNote(input: {nodeId: $id}) {
      deletedNoteId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "notesConnectionIds"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "nodeId",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedNoteId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NoteModal_DeleteNoteMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NotesMutations",
        "kind": "LinkedField",
        "name": "Notes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "DeleteNotePayload",
            "kind": "LinkedField",
            "name": "deleteNote",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NoteModal_DeleteNoteMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NotesMutations",
        "kind": "LinkedField",
        "name": "Notes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "DeleteNotePayload",
            "kind": "LinkedField",
            "name": "deleteNote",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteEdge",
                "key": "",
                "kind": "ScalarHandle",
                "name": "deletedNoteId",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "notesConnectionIds"
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "878bff4f6be66adc526d308914383649",
    "id": null,
    "metadata": {},
    "name": "NoteModal_DeleteNoteMutation",
    "operationKind": "mutation",
    "text": "mutation NoteModal_DeleteNoteMutation(\n  $id: ID!\n) {\n  Notes {\n    deleteNote(input: {nodeId: $id}) {\n      deletedNoteId\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd258152bd23b928dbbe2ec95467fc1d8';
export default node;
