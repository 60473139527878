import React from "react";
import {HashLink} from "react-router-hash-link";
import {MenuItem, SubItemFactory} from "../../../../menu/components/Menu";
import {ModuleState, useStateStatusLogic} from "@thekeytechnology/thekey-academy-frontend-library"
import "./module-sub-item.scss"
import {StatusIcon} from "../../../../core/components/status/StatusIcon";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {ModuleSubItem_ModuleStateFragment$key} from "../../../../../__generated__/ModuleSubItem_ModuleStateFragment.graphql";
import {ModuleSubItem_ModuleFragment$key} from "../../../../../__generated__/ModuleSubItem_ModuleFragment.graphql";

const MODULE_SUB_ITEM_MODULE_FRAGMENT = graphql`
    fragment ModuleSubItem_ModuleFragment on Module {
        containerMeta {
            points
            title
        }
        modulePath {
            moduleIndex
        }
    }`

const MODULE_SUB_ITEM_MODULE_STATE_FRAGMENT = graphql`
    fragment ModuleSubItem_ModuleStateFragment on ModuleState {
        firstUpdate
        lastUpdate
        points
        status
    }`

export const moduleSubItemFactory: SubItemFactory = (subRoute) => {
    return <ModuleSubItem key={subRoute.key} subRoute={subRoute}/>
}

interface OwnProps {
    subRoute: MenuItem;
}

const ModuleSubItem = ({subRoute}: OwnProps) => {

    const subRouteData = subRoute.data as {moduleFragmentRef: ModuleSubItem_ModuleFragment$key, moduleStateFragmentRef: ModuleSubItem_ModuleStateFragment$key | null};

    const module = useFragment<ModuleSubItem_ModuleFragment$key>(MODULE_SUB_ITEM_MODULE_FRAGMENT, subRouteData.moduleFragmentRef)
    const moduleState = useFragment<ModuleSubItem_ModuleStateFragment$key>(MODULE_SUB_ITEM_MODULE_STATE_FRAGMENT, subRouteData.moduleStateFragmentRef)

    const moduleStateStatus = useStateStatusLogic(moduleState as ModuleState);

    const Content = <div className="d-flex flex-row pt-3 pb-3 ml-4 mr-1 align-items-center module-sub-item">
        <div
            className="mr-2 tk-academy-small-text tk-color-waterblue-40">M{module.modulePath.moduleIndex + 1}</div>
        <div
            className="flex-grow-1 tk-academy-small-text tk-color-waterblue-40">{module.containerMeta.title}</div>
        <div
            className="ml-2 tk-academy-small-text tk-color-waterblue-40">
            <StatusIcon stateStatus={moduleStateStatus} possiblePoints={module.containerMeta.points}
                        pointsAwarded={moduleState?.points} showIsLocked={true}/>
        </div>
    </div>

    return <HashLink
        smooth
        key={subRoute.key} to={subRoute.path}
        className="tk-key-blue-30">
        {Content}
    </HashLink>;
}
