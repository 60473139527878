import React, {useEffect, useRef} from "react";
import {MonthlyPaymentCategory} from "./MonthlyPaymentCategory";
import {OneTimePaymentMethodCategory} from "./OneTimePaymentMethodCategory";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {PaymentStep_OrderFragment$key} from "../../../../../__generated__/PaymentStep_OrderFragment.graphql";
import styled from "styled-components";

interface OwnProps {
    orderFragmentRef: PaymentStep_OrderFragment$key
}

export const PaymentStep = ({orderFragmentRef}: OwnProps) => {

    const order = useFragment<PaymentStep_OrderFragment$key>(graphql`
        fragment PaymentStep_OrderFragment on Order {
            ...MonthlyPaymentCategory_OrderFragment
            ...OneTimePaymentMethodCategory_OrderFragment
        }
    `, orderFragmentRef)

    const ref = useRef<any>(null)

    useEffect(() => {
        ref.current?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }, [])

    return <PaymentContainer ref={ref}>
        <Separator/>

        <Heading>Wie möchtest du bezahlen?</Heading>

        <MonthlyPaymentCategory orderFragmentRef={order}/>

        <OneTimePaymentMethodCategory orderFragmentRef={order}/>
    </PaymentContainer>
}

export const Separator = styled.hr`
  margin-top: 25px;
  border-top: solid 1px #e7ebf1;
`

const Heading = styled.h3`
  font-size: 24px;
  line-height: 1.42;
  color: #184276;
  margin-bottom: 25px;

  @media (max-width: 1024px) {
    font-size: 18px;
  }
`

const PaymentContainer = styled.div`
  width: 100%;
  position: relative;
  scroll-margin: 50px;
`
