import React, { ReactNode } from "react";
import "./base-element-display.scss";

interface OwnProps {
    className?: string;
    heading?: string | ReactNode;
    elementSpecificSlot: ReactNode
}

export const BaseElementDisplay = ({
                                       className,
                                       heading,
                                       elementSpecificSlot
                                   }: OwnProps) => {
    return <div
        className={`base-element-display d-flex flex-grow-1 justify-content-center ${className ? className : ""}`}>
        <div className="wrapper w-100">
            {heading ? <h1 className="tk-academy-larger-bold mb-3 tk-color-darkblue">{heading}</h1> : null}
            {elementSpecificSlot}
        </div>
    </div>
}
