import React from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {showCelebrationAction} from "@thekeytechnology/thekey-academy-frontend-library";
import {AcademyButton} from "../../core/components/button/AcademyButton";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {TrialOverButton_CourseFragment$key} from "../../../__generated__/TrialOverButton_CourseFragment.graphql";
import {generateCartLink} from "../../billing/v3/utils/cart-link";

const COURSE_FRAGMENT = graphql`
    fragment TrialOverButton_CourseFragment on Course {
        containerMeta {
            title
        }
        mainProduct
        mainDiscountCode
    }`

interface OwnProps {
    courseFragmentRef: TrialOverButton_CourseFragment$key;
}

export const TrialOverButton = ({courseFragmentRef}: OwnProps) => {

    const course = useFragment<TrialOverButton_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef);

    const dispatch = useDispatch();
    const {t} = useTranslation("state");
    return <AcademyButton
        icon={"icon-a_lock"}
        text={t("next-lesson-button.start-lesson")}
        onClick={() => {
            dispatch(showCelebrationAction({
                title: t("trial-over-button.celebration.title"),
                explanation: t("trial-over-button.celebration.explanation"),
                text: t(course.mainProduct ? "trial-over-button.celebration.text-v2" : "trial-over-button.celebration.text", {courseTitle: course.containerMeta.title}),
                image: "special-trial-over",
                buttonDescription: {
                    label: t("trial-over-button.celebration.cta-text"),
                    navigationPath: course.mainProduct ? generateCartLink(course.mainProduct, course.mainDiscountCode) : "",
                    specialType: "Link",
                    params: {}
                }
            }))
        }
        }/>
}
