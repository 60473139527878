import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Formik} from "formik";
import * as yup from "yup";
import {ValidationError} from "yup";
import {ValidatedField} from "../../../core/components/form/ValidatedField";
import {AcademyButton} from "../../../core/components/button/AcademyButton";
import {BasicModal} from "../../../core/components/modal/BasicModal";
import {inviteUsersAction} from "@thekeytechnology/framework-react";

interface OwnProps {
    onClose: () => void;
}

export const InviteUsersModal = ({
                                     onClose
                                 }: OwnProps
) => {

    const dispatch = useDispatch();

    const {t} = useTranslation("manager");

    return <BasicModal centered={true} onHide={() => onClose()} dialogClassName="invitation-modal" show={true}>
        <div className="invitation-modal-content p-4">
            <h1 className="tk-academy-larger-bold tk-color-darkblue">{t(`invitation-modal.heading`)}</h1>
            <p className="mb-3 tk-academy-text">{t(`invitation-modal.explanation`)}</p>

            <Formik
                initialValues={{
                    emails: ""
                }}
                enableReinitialize={true}
                validationSchema={
                    yup.object().shape({
                        emails: yup.string()
                            .test("test", "test", function (value) {
                                const emails = value?.trim().split("\n").map(e => e.trim());
                                try {
                                    yup.array().of(yup.string().email()).validateSync(emails)
                                } catch (e) {
                                    if (e instanceof ValidationError) {
                                        return this.createError({
                                            path: "emails",
                                            message: t("invitation-modal.error")
                                        });
                                    }
                                }
                                return true;
                            }),
                    })
                }
                onSubmit={values => {
                    dispatch(
                        inviteUsersAction(values.emails.trim().split("\n"))
                    )
                    onClose();
                }}
            >
                {formikState => (
                    <div>
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            placeholder={t("invitation-modal.placeholder")}
                                            name="emails"
                                            component="textarea"
                                            className="form-control default-input"
                                            required/>
                        </div>
                        <AcademyButton
                            className="ml-auto"
                            onClick={() => formikState.handleSubmit()}
                            disabled={!formikState.isValid || formikState.isSubmitting}
                            text={t("invitation-modal.submit")}
                            type={"submit"}/>
                    </div>
                )}
            </Formik>
        </div>
    </BasicModal>
};

