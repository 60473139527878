/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Grading = "bronze" | "fail" | "gold" | "noPoints" | "perfect" | "silver" | "%future added value";
export type SubmissionType = "certificationTest" | "learn" | "review" | "test" | "%future added value";
export type LessonNextButton_SubmissionFragment = {
    readonly submissionType: SubmissionType;
    readonly result: {
        readonly grading: Grading;
    };
    readonly " $refType": "LessonNextButton_SubmissionFragment";
};
export type LessonNextButton_SubmissionFragment$data = LessonNextButton_SubmissionFragment;
export type LessonNextButton_SubmissionFragment$key = {
    readonly " $data"?: LessonNextButton_SubmissionFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LessonNextButton_SubmissionFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonNextButton_SubmissionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submissionType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SubmissionResult",
      "kind": "LinkedField",
      "name": "result",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grading",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Submission",
  "abstractKey": "__isSubmission"
};
(node as any).hash = '77284c21e4ee08141b2b72cf13793e79';
export default node;
