import React from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {
    CourseAttributes,
    LicenseAvailable_CourseFragment$key
} from "../../../__generated__/LicenseAvailable_CourseFragment.graphql";
import {LicenseAvailable_CourseStateFragment$key} from "../../../__generated__/LicenseAvailable_CourseStateFragment.graphql";
import {LicenseAvailable_LicensePoolsFragment$key} from "../../../__generated__/LicenseAvailable_LicensePoolsFragment.graphql";
import {LicensePoolDisplay} from "./LicensePoolDisplay";

const COURSE_FRAGMENT = graphql`
    fragment LicenseAvailable_CourseFragment on Course {
        attributes
        ...LicensePoolDisplay_CourseFragment
    }`

const COURSE_STATE_FRAGMENT = graphql`
    fragment LicenseAvailable_CourseStateFragment on CourseState {
        isBought
    }`

const LICENSE_POOLS_CONNECTION = graphql`
    fragment LicenseAvailable_LicensePoolsFragment on LicensePoolsConnection {
        edges {
            node {
                id
                applicationRuleStatus(courseId: $courseId)
                isMainPool
                ...LicensePoolDisplay_LicensePoolFragment
            }
        }
    }`

interface OwnProps {
    courseFragmentRef: LicenseAvailable_CourseFragment$key
    courseStateFragmentRef: LicenseAvailable_CourseStateFragment$key | null
    licensePoolsFragmentRef: LicenseAvailable_LicensePoolsFragment$key
    refetchCourseDetailScreenQuery: () => void
}

export const LicenseAvailable = ({courseFragmentRef, courseStateFragmentRef, licensePoolsFragmentRef, refetchCourseDetailScreenQuery}: OwnProps) => {

    const courseState = useFragment<LicenseAvailable_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, courseStateFragmentRef);
    const licensePools = useFragment<LicenseAvailable_LicensePoolsFragment$key>(LICENSE_POOLS_CONNECTION, licensePoolsFragmentRef)
    const course = useFragment<LicenseAvailable_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef)

    const usableLicenseInPool = licensePools.edges?.filter(e => e).map(e => e!.node).filter(lp => lp.isMainPool).find(lp => lp.applicationRuleStatus === "canFreelyUse")

    if (courseState?.isBought || (!courseState && !usableLicenseInPool) || course.attributes.find(a => a === "earlyBird" as CourseAttributes)) return null

    return <>
        {licensePools.edges?.map(e => e!.node!).map(licensePool => {
            return <LicensePoolDisplay
                key={licensePool.id}
                courseFragmentRef={course}
                licensePoolFragmentRef={licensePool}
                refetchCourseDetailScreenQuery={!courseState && usableLicenseInPool ? refetchCourseDetailScreenQuery : undefined}
            />
        })}
    </>
}
