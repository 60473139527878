import { createSelector } from "reselect";
import { RootState } from "../../reducers";
import { LessonModuleState } from "../reducer";

export const selectLessonModuleState = (state: RootState) => state.lesson;

export const selectCurrentLesson = createSelector(
    selectLessonModuleState,
    (moduleState: LessonModuleState) =>
        moduleState.currentLesson
);
