import React, { ReactNode } from "react";
import "./header-background-slot.scss"

interface OwnProps {
    height: number;
    buttonSlot?: ReactNode
}

export const BlueBackground = ({
                                   buttonSlot,
                                   height
                               }: OwnProps) => {
    return <div className="header-background-slot tk-bg-waterblue d-flex" style={{height}}>
        {buttonSlot ? <div className="button-wrapper">{buttonSlot}</div> : null}
    </div>
}
