import {DraggableWord} from "./DraggableWord";
import React, {RefObject, useRef} from "react";

interface OwnProps {
    availableWords: ReadonlyArray<string>,
    selectWord: (word: string) => void;
}

export const WordArea = ({
                             availableWords,
                             selectWord,
                         }: OwnProps) => {
    const ref = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>

    return <div ref={ref} className={"d-flex flex-wrap p-3 mb-5"}>
        {availableWords.map(
            (word, index) =>
                <div className="ml-2 mb-3">
                    <DraggableWord
                        key={"dwinitial" + index + word}
                        selectWord={selectWord}
                        word={word}
                    />
                </div>
        )}
    </div>
}