import { PointsDisplay } from "../../../lesson/components/PointsDisplay";
import { NavLink } from "react-router-dom";
import React from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {ReviewHeader_CourseFragment$key} from "../../../../__generated__/ReviewHeader_CourseFragment.graphql";
import {ReviewHeader_ElementFragment$key} from "../../../../__generated__/ReviewHeader_ElementFragment.graphql";

const REVIEW_HEADER_COURSE_FRAGMENT = graphql`
    fragment ReviewHeader_CourseFragment on Course {
        id
    }`

const REVIEW_HEADER_ELEMENT_FRAGMENT = graphql`
    fragment ReviewHeader_ElementFragment on Element {
        generalSettings {
            points
        }
    }`

interface OwnProps {
    courseFragmentRef: ReviewHeader_CourseFragment$key;
    elementFragmentRef?: ReviewHeader_ElementFragment$key | null;
}

export const ReviewHeader = ({courseFragmentRef, elementFragmentRef}: OwnProps) => {

    const course = useFragment<ReviewHeader_CourseFragment$key>(REVIEW_HEADER_COURSE_FRAGMENT, courseFragmentRef)

    const element = useFragment<ReviewHeader_ElementFragment$key>(REVIEW_HEADER_ELEMENT_FRAGMENT, elementFragmentRef || null)

    return <div className="ml-auto d-flex align-items-center">
        {element && <PointsDisplay points={element.generalSettings.points}/>}
        <NavLink
            className="tk-academy-larger-bold tk-white ml-3 d-flex"
            to={`/courses/${course.id}`}>
            <span className="icon-a_close"/>
        </NavLink>
    </div>
}
