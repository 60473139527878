/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseDetailScreen_CourseStateFragment = {
    readonly id: string;
    readonly isBought: boolean;
    readonly " $fragmentRefs": FragmentRefs<"CelebrationPopup_CourseStateFragment" | "CourseContent_CourseStateFragment" | "CourseDetailFooter_CourseStateFragment" | "CourseDetailSidebar_CourseStateFragment" | "CourseInfo_CourseStateFragment">;
    readonly " $refType": "CourseDetailScreen_CourseStateFragment";
};
export type CourseDetailScreen_CourseStateFragment$data = CourseDetailScreen_CourseStateFragment;
export type CourseDetailScreen_CourseStateFragment$key = {
    readonly " $data"?: CourseDetailScreen_CourseStateFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseDetailScreen_CourseStateFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseDetailScreen_CourseStateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBought",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CelebrationPopup_CourseStateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseContent_CourseStateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseDetailFooter_CourseStateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseDetailSidebar_CourseStateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseInfo_CourseStateFragment"
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};
(node as any).hash = 'db1992e64da651248d8a720545d573ef';
export default node;
