import React from "react";
import {useTranslation} from "react-i18next";
import {AcademyButton} from "../../core/components/button/AcademyButton";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {ApplyLicenseButton_ApplyLicenseMutation} from "../../../__generated__/ApplyLicenseButton_ApplyLicenseMutation.graphql";
import {ApplyLicenseButton_CourseFragment$key} from "../../../__generated__/ApplyLicenseButton_CourseFragment.graphql";
import {useDispatch} from "react-redux";
import {DialogResult, showDialogAction} from "@thekeytechnology/framework-react";
import {showCelebrationAction} from "@thekeytechnology/thekey-academy-frontend-library";
import {ApplyLicenseButton_LicensePoolFragment$key} from "../../../__generated__/ApplyLicenseButton_LicensePoolFragment.graphql";

const COURSE_FRAGMENT = graphql`
    fragment ApplyLicenseButton_CourseFragment on Course {
        id
    }`

const LICENSE_POOL_FRAGMENT = graphql`
    fragment ApplyLicenseButton_LicensePoolFragment on LicensePool {
        id
        isMainPool
        applicationRuleStatus(courseId: $courseId)
        account{
            name
        }
    }`

const APPLY_LICENSE_MUTATION = graphql`
    mutation ApplyLicenseButton_ApplyLicenseMutation($licenseInput: ApplyLicenseInput!, $courseId: ID!)  {
        License {
            applyLicense(input: $licenseInput) {
                licensePool {
                    node {
                        id
                        applicationRuleStatus(courseId: $courseId)
                    }
                }
                courseState {
                    node {
                        id
                    }
                }
            }
        }
    }`

interface OwnProps {
    courseFragmentRef: ApplyLicenseButton_CourseFragment$key
    licensePoolFragmentRef: ApplyLicenseButton_LicensePoolFragment$key
    refetchCourseDetailScreenQuery?: () => void
}

export const ApplyLicenseButton = ({
                                       courseFragmentRef,
                                       licensePoolFragmentRef,
                                       refetchCourseDetailScreenQuery
                                   }: OwnProps) => {
    const {t} = useTranslation("state")

    const dispatch = useDispatch()

    const course = useFragment<ApplyLicenseButton_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef)
    const licensePool = useFragment<ApplyLicenseButton_LicensePoolFragment$key>(LICENSE_POOL_FRAGMENT, licensePoolFragmentRef)
    const [applyLicense, isInFlight] = useMutation<ApplyLicenseButton_ApplyLicenseMutation>(APPLY_LICENSE_MUTATION)

    return licensePool ? <AcademyButton
        icon={"icon-a_tick"}
        text={t("apply-license-button.label")}
        disabled={isInFlight}
        onClick={() => {
            dispatch(showDialogAction({
                title: t("apply-license-button.dialog.title", {accountName: licensePool.account.name}),
                explanation: t("apply-license-button.dialog.explanation", {accountName: licensePool.account.name}),
                question: t("apply-license-button.dialog.question", {accountName: licensePool.account.name}),
                affirmativeText: t("apply-license-button.dialog.affirmative-text", {accountName: licensePool.account.name}),
                negativeText: t("apply-license-button.dialog.negative-text", {accountName: licensePool.account.name}),
            }, result => {
                if (result === DialogResult.ACCEPT) {
                    applyLicense({
                        variables: {
                            licenseInput: {
                                courseId: course.id,
                                licensePoolId: licensePool.id,
                            },
                            courseId: course.id
                        },
                        updater: (store, data) => {
                            if (data.License.applyLicense?.courseState.node.id) store.get(data.License.applyLicense?.courseState.node.id)?.invalidateRecord()
                        },
                        onCompleted: () => {
                            dispatch(showCelebrationAction({
                                title: t("apply-license-button.success-dialog.title"),
                                explanation: t("apply-license-button.success-dialog.explanation"),
                                text: t("apply-license-button.success-dialog.text")
                            }))
                            if (refetchCourseDetailScreenQuery) refetchCourseDetailScreenQuery() // necessary if courseState does not exist prior to applying the license (then the subscription to invalidating the record does not exist). will be resolved in v2.0 through different structure
                        }
                    })
                }
            }))
        }}
    /> : null
}
