/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateOrderInput = {
    cartSelection?: CartSelectionInput | null;
    clientMutationId?: string | null;
};
export type CartSelectionInput = {
    selectedProducts: Array<SelectedProductInput>;
    selectedDiscountCodes: Array<string>;
};
export type SelectedProductInput = {
    amount: number;
    productId: string;
};
export type OrderFlowFromCartBase64Screen_CreateOrderMutationVariables = {
    createOrderInput: CreateOrderInput;
};
export type OrderFlowFromCartBase64Screen_CreateOrderMutationResponse = {
    readonly Billing: {
        readonly createOrder: {
            readonly order: {
                readonly id: string;
                readonly cart: {
                    readonly cartItems: ReadonlyArray<{
                        readonly product?: {
                            readonly id: string;
                            readonly title: string;
                        };
                    }>;
                    readonly cartTotals: {
                        readonly totalsIncludingAllDiscounts: {
                            readonly netPrice: number;
                        } | null;
                    };
                };
            };
        } | null;
    };
};
export type OrderFlowFromCartBase64Screen_CreateOrderMutation = {
    readonly response: OrderFlowFromCartBase64Screen_CreateOrderMutationResponse;
    readonly variables: OrderFlowFromCartBase64Screen_CreateOrderMutationVariables;
};



/*
mutation OrderFlowFromCartBase64Screen_CreateOrderMutation(
  $createOrderInput: CreateOrderInput!
) {
  Billing {
    createOrder(input: $createOrderInput) {
      order {
        id
        cart {
          cartItems {
            __typename
            ... on ProductCartItem {
              product {
                __typename
                id
                title
              }
            }
          }
          cartTotals {
            totalsIncludingAllDiscounts {
              netPrice
            }
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "createOrderInput"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "createOrderInput"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "CartTotals",
  "kind": "LinkedField",
  "name": "cartTotals",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceV3",
      "kind": "LinkedField",
      "name": "totalsIncludingAllDiscounts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "netPrice",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrderFlowFromCartBase64Screen_CreateOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BillingMutations",
        "kind": "LinkedField",
        "name": "Billing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CreateOrderPayload",
            "kind": "LinkedField",
            "name": "createOrder",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "order",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Cart",
                    "kind": "LinkedField",
                    "name": "cart",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "cartItems",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "product",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "ProductCartItem",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrderFlowFromCartBase64Screen_CreateOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BillingMutations",
        "kind": "LinkedField",
        "name": "Billing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CreateOrderPayload",
            "kind": "LinkedField",
            "name": "createOrder",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "order",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Cart",
                    "kind": "LinkedField",
                    "name": "cart",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "cartItems",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "product",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v2/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "ProductCartItem",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4b92d76d452ed107d29c6caafbcdb290",
    "id": null,
    "metadata": {},
    "name": "OrderFlowFromCartBase64Screen_CreateOrderMutation",
    "operationKind": "mutation",
    "text": "mutation OrderFlowFromCartBase64Screen_CreateOrderMutation(\n  $createOrderInput: CreateOrderInput!\n) {\n  Billing {\n    createOrder(input: $createOrderInput) {\n      order {\n        id\n        cart {\n          cartItems {\n            __typename\n            ... on ProductCartItem {\n              product {\n                __typename\n                id\n                title\n              }\n            }\n          }\n          cartTotals {\n            totalsIncludingAllDiscounts {\n              netPrice\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ec6c5d78be8d7c1838b04bf701037b05';
export default node;
