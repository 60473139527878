import React, {useEffect, useRef, useState} from 'react'
import {useFragment, useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {CustomerMessagePage} from "./CustomerMessagePage";
import ArrowBackward from "../../images/arrow-backward.svg";
import ArrowForward from "../../images/arrow-forward.svg";
import CloseButton from "../../images/close-button.svg";
import {PageIndicators} from "./PageIndicators";
import {LoadEachImageBeforeShowingComponentWrapper} from "../../../core/components/LoadEachImageBeforeShowingComponentWrapper";
import {useViewportDimensions} from "../../../core/components/useViewportDimensions";
import styled from "styled-components";
import {Carousel} from "primereact/carousel";
import {Dialog} from "primereact/dialog";
import {CustomerMessageModal_CustomerMessageFragment$key} from "../../../../__generated__/CustomerMessageModal_CustomerMessageFragment.graphql";
import {CustomerMessageModal_AcknowledgeCustomerMessagesMutation} from "../../../../__generated__/CustomerMessageModal_AcknowledgeCustomerMessagesMutation.graphql";

const CUSTOMER_MESSAGE_FRAGMENT = graphql`
    fragment CustomerMessageModal_CustomerMessageFragment on CustomerMessageV2 {
        id
        customerMessageDefinition {
            pages {
                edges {
                    node {
                        id
                        imageOpt {
                            url
                        }
                        ...CustomerMessagePage_CustomerMessageDefinitionPageFragment
                    }
                }
            }
        }
        hasSeen
    }`

const ACKNOWLEDGE_CUSTOMER_MESSAGE_MUTATION = graphql`
    mutation CustomerMessageModal_AcknowledgeCustomerMessagesMutation($customerMessageId: ID!) {
        Viewer {
            CustomerMessageV2 {
                acknowledgeCustomerMessagesV2(input: {customerMessageIds: [$customerMessageId]}) {
                    customerMessages {
                        edges {
                            node {
                                id
                                hasSeen
                            }
                        }
                    }
                    customerMessageInformation {
                        id
                        numUnseenCustomerMessages
                    }
                }
            }
        }
    }`

interface State {
    page: number
}

interface OwnProps {
    hideModal: () => void
    customerMessageFragment?: CustomerMessageModal_CustomerMessageFragment$key
}

export const CustomerMessageModal = ({hideModal, customerMessageFragment}: OwnProps) => {

    const carousel = useRef<any>(null)

    const customerMessage = useFragment<CustomerMessageModal_CustomerMessageFragment$key>(CUSTOMER_MESSAGE_FRAGMENT, customerMessageFragment || null)

    const [acknowledgeCustomerMessage] = useMutation<CustomerMessageModal_AcknowledgeCustomerMessagesMutation>(ACKNOWLEDGE_CUSTOMER_MESSAGE_MUTATION)

    const {height: viewportHeight, width: viewportWidth} = useViewportDimensions()

    const [state, setState] = useState<State>({
        page: 0
    })

    const pages = customerMessage?.customerMessageDefinition?.pages.edges?.filter(e => e).map(e => e!.node).filter(n => n.imageOpt?.url) || []

    const showCustomerMessage = customerMessage && pages.length > 0 && viewportWidth > 1024

    useEffect(() => {
            if (showCustomerMessage && !customerMessage!.hasSeen) {
                acknowledgeCustomerMessage({
                    variables: {customerMessageId: customerMessage!.id}
                })
            }
            // eslint-disable-next-line
        }, [showCustomerMessage]
    )

    if (!showCustomerMessage) return null

    const limitWidth = 400
    const modalHeightBellowMaxHeight = 0.8 * viewportHeight
    const modalWidthBellowMaxWidth = modalHeightBellowMaxHeight / 16 * 9
    const modalWidth = modalHeightBellowMaxHeight / 16 * 9 > limitWidth ? limitWidth : modalWidthBellowMaxWidth

    const canMoveForward = state.page < pages.length - 1
    const canMoveBackward = state.page > 0

    const navForward = () => {
        if (carousel.current) {
            setState(state => ({...state, page: state.page + 1}))
            carousel.current.navForward({cancelable: false}, state.page + 1)
        }
    }

    const navBackward = () => {
        if (carousel.current) {
            setState(state => ({...state, page: state.page - 1}))
            carousel.current.navBackward({cancelable: false}, state.page - 1)
        }
    }

    return <LoadEachImageBeforeShowingComponentWrapper imageUrls={pages.map(p => p.imageOpt!.url!)}
                                                       showBeforeLoaded={null}>
        <StyledDialog
            onHide={hideModal} visible={true}
            contentStyle={{borderRadius: 10}}
            resizable={false}
            draggable={false} modal={true} closable={false} dismissableMask={true}
            maskStyle={{backgroundColor: "rgba(3,29,60,0.8)"}} modalWidth={modalWidth}>
            <PageIndicators numPages={pages.length} modalWidth={modalWidth} activePageIndex={state.page}/>
            <CloseImage modalWidth={modalWidth} onClick={hideModal} src={CloseButton} alt={""}/>
            {canMoveForward &&
            <NavImage modalWidth={modalWidth} forward={true} onClick={navForward} src={ArrowForward} alt={""}/>}
            {canMoveBackward &&
            <NavImage modalWidth={modalWidth} forward={false} onClick={navBackward} src={ArrowBackward} alt={""}/>}
            <StyledCarousel
                page={0}
                value={pages}
                itemTemplate={(page) => <CustomerMessagePage
                    key={page.id}
                    modalWidth={modalWidth}
                    onClose={page.id === pages[pages.length - 1].id ? hideModal : undefined}
                    customerMessageDefinitionPageFragmentRef={page}
                />}
                numVisible={1}
                numScroll={1}
                ref={carousel}
            >
            </StyledCarousel>
        </StyledDialog>
    </LoadEachImageBeforeShowingComponentWrapper>
}

const CloseImage = styled.img<{ modalWidth: number }>`
    position: absolute;
    top: ${props => props.modalWidth * 0.1}px;
    z-index: 1;
    right: ${props => props.modalWidth * 0.025}px;
    cursor: pointer;
    width: ${props => props.modalWidth * 0.05}px;
    height: ${props => props.modalWidth * 0.05}px;
`

const NavImage = styled.img<{ modalWidth: number, forward: boolean }>`
    position: absolute;
    top: ${props => (16 / 18 - 0.05) * props.modalWidth}px;
    z-index: 1;
    ${props => props.forward ? "right: " + props.modalWidth / 40 + "px;" : "left: " + props.modalWidth / 40 + "px;"}
    cursor: pointer;
    width: ${props => props.modalWidth * 0.05}px;
    height: ${props => props.modalWidth * 0.05}px;
`

const StyledDialog = styled(Dialog)<{ modalWidth: number }>`
    width: ${props => props.modalWidth}px;
`

const StyledCarousel = styled(Carousel)`
    .p-carousel-indicators {
        display: none;
    }
    
    .p-carousel-next {
        display: none;
    }
    
    .p-carousel-prev {
        display: none;
    }
`