import React, {ReactNode, useEffect} from 'react'

interface OwnProps {
    pages: ReactNode[],
    pageIndex: number,

    navigation: (canGoBack: boolean, isLastScreen: boolean) => void
}

export const PageDisplay = ({pages, pageIndex, navigation}: OwnProps) => {
    useEffect(() => {
        navigation(pageIndex > 0, pageIndex === pages.length - 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ pageIndex, pages.length])

    return (
        <div className={'d-flex h-100 w-100'}>{pages[pageIndex]}</div>
    )
}