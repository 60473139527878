/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NoteModal_LessonFragment = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"NoteLocationHeading_LessonFragment">;
    readonly " $refType": "NoteModal_LessonFragment";
};
export type NoteModal_LessonFragment$data = NoteModal_LessonFragment;
export type NoteModal_LessonFragment$key = {
    readonly " $data"?: NoteModal_LessonFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"NoteModal_LessonFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NoteModal_LessonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NoteLocationHeading_LessonFragment"
    }
  ],
  "type": "Lesson",
  "abstractKey": "__isLesson"
};
(node as any).hash = '47dacb82212ff55a8bec34a479031e7d';
export default node;
