import React, {useEffect} from "react";
import {Screen} from "../../core/components/screen/Screen";
import {BlueBackground} from "../../core/components/screen/BlueBackground";
import {connect} from "react-redux";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {FAQItem} from "./FAQItem";
import {SearchField} from "./SearchField";
import {
    changeFaqsDocsPerPageAction,
    changeFaqsQueryAction,
    FAQ,
    fetchFaqsAction,
    selectFaqs
} from "@thekeytechnology/thekey-academy-frontend-library"
import {graphql} from "babel-plugin-relay/macro";
import {useLazyLoadQuery} from "react-relay";
import {MainSidebar} from "../../core/components/sidebar/MainSidebar";
import {FaqsScreen_Query} from "../../../__generated__/FaqsScreen_Query.graphql";

const QUERY = graphql`
    query FaqsScreen_Query  {
        Viewer {
            CustomerMessageV2 {
                CustomerMessageInformation {
                    id
                    numUnseenCustomerMessages
                }
            }
            License{
                NewLicensesInAccount {
                    id
                    newLicenses
                }
            }
        }
    }
`

interface StateProps {
    faqs: EntityWrapper<FAQ>[];
    query?: string;
}

interface DispatchProps {
    fetchFaqs: typeof fetchFaqsAction
    changeQuery: typeof changeFaqsQueryAction
    changeDocsPerPage: typeof changeFaqsDocsPerPageAction
}

type Props = StateProps & DispatchProps;

const FaqsScreenComponent = ({
                                 faqs,
                                 fetchFaqs,
                                 query,
                                 changeQuery,
                                 changeDocsPerPage
                             }: Props) => {

    const graphqlQuery = useLazyLoadQuery<FaqsScreen_Query>(QUERY, {}, {fetchPolicy: "network-only"})

    useEffect(() => {
        changeDocsPerPage(-1);
        fetchFaqs()
    }, [changeDocsPerPage, fetchFaqs])

    return <Screen sidebarSlot={<MainSidebar
        numUnseenCustomerMessages={graphqlQuery.Viewer.CustomerMessageV2.CustomerMessageInformation.numUnseenCustomerMessages}
        hasNewLicensesInAccount={graphqlQuery.Viewer.License.NewLicensesInAccount.newLicenses}
    />}
                   headerBarBackgroundSlot={<BlueBackground height={60}/>}
                   headerMargin={30}>
        <div className="d-flex align-items-center mb-5">
            <div className="tk-academy-extra-large-heading-bold">Häufige Fragen</div>
            <SearchField text={query} textChanged={newValue => {
                if (newValue.length >= 3) {
                    changeQuery(newValue)
                } else if (newValue.length === 0) {
                    changeQuery(undefined)
                }
            }}/>
        </div>
        {faqs.map((faq, index) => {
            return <FAQItem key={index} faq={faq}/>
        })}
    </Screen>
}

export const FaqsScreen = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        faqs: selectFaqs(state)
    }),
    {
        fetchFaqs: fetchFaqsAction,
        changeQuery: changeFaqsQueryAction,
        changeDocsPerPage: changeFaqsDocsPerPageAction
    }
)(FaqsScreenComponent)
