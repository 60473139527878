import React, {useRef} from "react";
import {useDrag, useDrop} from "react-dnd";
import {LightboxImage} from "../../../core/components/image/LightboxImage";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {OrderableDisplay_OrderableFragment$key} from "../../../../__generated__/OrderableDisplay_OrderableFragment.graphql";

const ORDERABLE_DISPLAY_ORDERABLE_FRAGMENT = graphql`
    fragment OrderableDisplay_OrderableFragment on Orderable {
        image {
            url
        }
        text
    }`

interface OwnProps {
    currentIndex: number
    orderableFragmentRef: OrderableDisplay_OrderableFragment$key;
    moveElement: (indexA: number, indexB: number) => void;
}

export interface OrderableDragItem {
    type: string;
    index: number;
}

export const OrderableDisplay = ({
                                     currentIndex,
                                     orderableFragmentRef,
                                     moveElement
                                 }: OwnProps) => {
    const orderable = useFragment<OrderableDisplay_OrderableFragment$key>(ORDERABLE_DISPLAY_ORDERABLE_FRAGMENT, orderableFragmentRef)

    const ref = useRef<HTMLDivElement>(null);

    const [{isOver, canDrop}, drop] = useDrop<OrderableDragItem, {}, {
        isOver: boolean
        canDrop: boolean
    }>({
        accept: "orderable",
        drop: (item) => {
            moveElement(item.index, currentIndex)
            return {}
        },
        collect: monitor => {
            return {
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            }
        }
    });

    const [{isDragging}, drag] = useDrag<OrderableDragItem, {}, { isDragging: boolean }>({
        type: "orderable",
        item: () => {
            return {type: "orderable", index: currentIndex}
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return <div ref={ref}
                className={`orderable ${isDragging ? "is-dragging" : ""} ${isOver ? "is-over" : ""} ${canDrop ? "can-drop" : ""} ${orderable.image?.url ? "has-image" : ""} `}>
        <div className="index">{currentIndex + 1}</div>
        <div className="text">
            {orderable.image?.url ? <LightboxImage image={orderable.image.url}/> : null}
            <div>{orderable.text}</div>
        </div>
    </div>

}
