import {Carousel, CarouselItem} from "react-bootstrap";
import React, {useEffect} from "react";
import Tut1 from './tut-1.png';
import Tut2 from './tut-2.png';
import Tut3 from './tut-3.png';
import Tut4 from './tut-4.png';
import Tut5 from './tut-5.png';
import Tut6 from './tut-6.png';
import Tut7 from './tut-7.png';
import "./onboarding.scss";
import {AcademyButton} from "../../core/components/button/AcademyButton";
import {useDispatch, useSelector} from "react-redux";
import {
    acknowledgeOnboardingAction,
    API_FETCH_ONBOARDING,
    fetchNeedsOnboardingAction,
    fetchOnboardingAction,
    Onboarding,
    OnboardingPage,
    selectCustomOnboarding,
    selectIsNeedsOnboarding
} from "@thekeytechnology/thekey-academy-frontend-library";
import {BasicModal} from "../../core/components/modal/BasicModal";
import {EntityWrapper, selectCombinedApiState, TkFile} from "@thekeytechnology/framework-react";
import {CustomOnboardingContainer} from "@thekeytechnology/thekey-academy-frontend-library/dist/onboarding/onboarding";
import {VideoPlayer} from "../../core/components/video/VideoPlayer";


export const DEFAULT_ONBOARDING: CustomOnboardingContainer = {
    onboarding: new EntityWrapper<Onboarding>(
        "",
        {
            internalTitle: "",
            useInAccountsRef: [],
            pages: [
                {
                    title: 'Herzlich Willkommen',
                    mainTitle: 'Schön, dass Du hier bist',
                    content: 'Hallo und Willkommen bei thekey.academy. Wir glauben daran, dass persönliche Entwicklung die nachhaltigste Investition in mehr Lebensqualität ist. Deshalb verschreiben wir uns der Mission, Dir die ideale Form persönlicher Weiterentwicklung zu bieten.',
                    gradient: {from: '#fff59b', to: '#dcf8e7'},
                    imageRef: "tut-1",
                    hasCloseButton: false
                } as OnboardingPage,
                {
                    title: 'Deine Vorteile',
                    mainTitle: 'Warum thekey.academy?',
                    content: 'Mit thekey.academy entscheidest Du Dich für e-Learning der Extraklasse. Unsere Interaktivlehrgänge sind so aufgebaut, dass sich Videos, Texte, Bilder und jede Menge Abfragen die Waage halten. Damit bleibst Du stets bei der Sache. Das macht uns zu Deutschlands interaktivster Lernapp für berufliche Persönlichkeitsentwicklung.',
                    gradient: {from: 'white', to: 'white'},
                    imageRef: "tut-2",
                    hasCloseButton: false
                } as OnboardingPage,
                {
                    title: 'Die IHK und Wir',
                    mainTitle: 'Das IHK-Zertifikat',
                    content: 'Zeige Dir und Deinem Umfeld, was Du geleistet hast! Gemeinsam mit der IHK-Projektgesellschaft Ostbrandenburg haben wir alle Interaktivlehrgänge so gestaltet, dass Du mit offiziellem IHK-Zertifikat abschließt. Das bedeutet auch, dass Du Deine Leistung sowohl in Zwischentests, als auch in der abschließenden Leistungsüberprüfung belegen darfst.',
                    gradient: {from: '#d9f6ff', to: '#a7cbe8'},
                    imageRef: "tut-3",
                    hasCloseButton: true
                } as OnboardingPage,
                {
                    title: 'Nachhaltiges Lernen',
                    mainTitle: 'Die Vorteile digitaler Weiterbildung',
                    content: 'Einer der wichtigsten Vorteile ist die Nachhaltigkeit des Lerneffekts. Du kannst auch nach Abschluss im Alltag immer wieder in Lektionen zurückspringen und Dein Wissen auffrischen - Dein Leben lang! Außerdem helfen wir Dir beim Übertrag in die Praxis und erinnern Dich an Deine Umsetzungsvorhaben. Zusatzmaterialien vertiefen Deine Lernerfahrung weiterhin.',
                    gradient: {from: 'white', to: 'white'},
                    imageRef: "tut-4",
                    hasCloseButton: false
                } as OnboardingPage,
                {
                    title: 'Kostenlos testen',
                    mainTitle: 'Probier uns aus',
                    content: 'Du kannst jeden Interaktivlehrgang kostenlos und ganz unverbindlich starten. Wenn Du am Ende der kostenlosen Inhalte angekommen bist, wirst Du dazu eingeladen, den Vollzugang zu kaufen. Wenn Du das möchtest, gibst Du Deine Zahlungsinformationen ein und kaufst bewusst. Also keine falsche Scheu - hier passiert nichts ohne Dein aktives Zutun.',
                    gradient: {from: '#e2dcf8', to: '#a7cbe8'},
                    imageRef: "tut-5",
                    hasCloseButton: true
                } as OnboardingPage,
                {
                    title: 'Spaß mit Sinn',
                    mainTitle: 'Spaß verstärkt Deinen Lerneffekt',
                    content: 'Mit jeder Lektion sammelst Du „Schlüssel zum Erfolg“, steigst dadurch im Level auf und schaltest Dir Ränge und kostenlose Lektionen im Bonus-Kurs frei. Dadurch macht Dir das Lernen hoffentlich noch mehr Spaß und das Gelernte bleibt länger im Kopf.',
                    gradient: {from: 'white', to: 'white'},
                    imageRef: "tut-6",
                    hasCloseButton: false
                } as OnboardingPage,
                {
                    title: 'Viel Erfolg!',
                    mainTitle: 'Jetzt aber los!',
                    content: 'Nun laden wir Dich herzlich dazu ein, jeden Interaktivlehrgang kostenlos und absolut unverbindlich zu testen. Spüre, wie es sich anfühlt, Dich mit thekey.academy, Deinen Experten und dem Endgerät Deiner Wahl weiterzuentwickeln wann und wo Du willst. Viel Spaß bei Deiner Lernerfahrung!',
                    gradient: {from: '#dcf8e7', to: '#dcf8e7'},
                    imageRef: "tut-7",
                    hasCloseButton: true
                } as OnboardingPage
            ],
            accountRef: "",
            inheritToSlaveAccounts: false
        } as Onboarding
    ),
    files: [
        new EntityWrapper("tut-1", new TkFile("", "", "", Tut1, "", "")),
        new EntityWrapper("tut-2", new TkFile("", "", "", Tut2, "", "")),
        new EntityWrapper("tut-3", new TkFile("", "", "", Tut3, "", "")),
        new EntityWrapper("tut-4", new TkFile("", "", "", Tut4, "", "")),
        new EntityWrapper("tut-5", new TkFile("", "", "", Tut5, "", "")),
        new EntityWrapper("tut-6", new TkFile("", "", "", Tut6, "", "")),
        new EntityWrapper("tut-7", new TkFile("", "", "", Tut7, "", "")),
    ]
}


export const OnboardingModal = () => {
    const dispatch = useDispatch();

    const apiCallState = useSelector(selectCombinedApiState(API_FETCH_ONBOARDING))

    const isNeedsOnboarding = useSelector(selectIsNeedsOnboarding)
    const customOnboarding = useSelector(selectCustomOnboarding)

    useEffect(() => {
        dispatch(fetchNeedsOnboardingAction());
    }, [dispatch])

    useEffect(() => {
        if (isNeedsOnboarding) {
            dispatch(fetchOnboardingAction())
        }
    }, [dispatch, isNeedsOnboarding])

    const onboardingToUse = customOnboarding?.onboarding ? customOnboarding : DEFAULT_ONBOARDING;

    return (
        <BasicModal
            dialogClassName="onboarding"
            size="xl"
            centered={true}
            show={isNeedsOnboarding && apiCallState.succeeded}
            onHide={() => dispatch(acknowledgeOnboardingAction())}>
            <Carousel touch={true}
                      interval={null}
                      wrap={true}
                      controls={true}
                      prevIcon={<div className="control-icon icon-a_back"/>}
                      nextIcon={<div className="control-icon icon-a_forward"/>}>
                {onboardingToUse.onboarding?.entity.pages.map(page => {
                    const image = onboardingToUse.files.find(file => file.id === page.imageRef);
                    const video = onboardingToUse.files.find(file => file.id === page.videoFileRef);

                    return <CarouselItem key={page.title}>
                        <div className="onboarding-slide">
                            <div className="onboarding-image-wrapper p-5"
                                 style={{backgroundImage: `linear-gradient(to bottom, ${page.gradient?.from},  ${page.gradient?.to})`}}>
                                {image ? <img alt={page.title}
                                              src={image?.entity.url}/> : null}
                                {video ? <VideoPlayer videoFile={video.entity.url}/> : null}
                            </div>
                            <div className="p-5 d-flex justify-content-center flex-column">
                                <div className="tk-academy-larger-bold">{page.title}</div>
                                <div className="tk-academy-extra-large-heading-bold mb-2">{page.mainTitle}</div>
                                <div className="tk-academy-large mb-4 pr-4">{page.content}</div>
                                {page.hasCloseButton ? <div className="pr-4 pb-4 pt-4"><AcademyButton onClick={() => {
                                    dispatch(acknowledgeOnboardingAction());
                                }} text="Jetzt starten."/></div> : null}
                            </div>
                        </div>
                    </CarouselItem>
                })}
            </Carousel>
        </BasicModal>
    )
}
