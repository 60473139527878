import styled from "styled-components";
import {tkColorCodes} from "../../core/style/tkColorCodes";

export const BackDrop = styled.div`
  height: 100%;
  width: 100%;
  opacity: 45%;
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
`

export const FirstLayerWrapper = styled.div`
  position: absolute;
  max-width: 718px;
  bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: white;
  border-radius: 12px;
  padding: 32px 40px;

`

export const CloseButton = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
`

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  left: -56px;
`

export const Heading = styled.h2`
  font-weight: bold;
  padding-left: 9px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  margin-top: 24px;
`

export const AcceptButton = styled.button`
  flex: 0 0 33%;
  height: 40px;
  border-radius: 100px;
  padding: 8px 16px;
  background-color: ${tkColorCodes.keyGreen};
  color: ${tkColorCodes.waterBlue};
  text-align: center;
  font-weight: bold;
`

export const DeclineButton = styled.button`
  flex: 0 0 33%;
  height: 40px;
  border-radius: 100px;
  padding: 8px 16px;
  color: ${tkColorCodes.waterBlue};
  text-align: center;
  border: 2px solid #E6E5EF;
`

export const MoreDetailsButtonWrapper = styled.div`
  flex: 0 0 33%;
  align-self: center;
  display: flex;
  justify-content: center;
`

export const MoreDetailsButton = styled.a`
  text-align: center;
  text-decoration-line: underline;
  color: #079E83;
`