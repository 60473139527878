/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ResolutionElementDisplay_ResolutionElementFragment = {
    readonly task: string;
    readonly title: string;
    readonly " $refType": "ResolutionElementDisplay_ResolutionElementFragment";
};
export type ResolutionElementDisplay_ResolutionElementFragment$data = ResolutionElementDisplay_ResolutionElementFragment;
export type ResolutionElementDisplay_ResolutionElementFragment$key = {
    readonly " $data"?: ResolutionElementDisplay_ResolutionElementFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ResolutionElementDisplay_ResolutionElementFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ResolutionElementDisplay_ResolutionElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "task",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "ResolutionElement",
  "abstractKey": null
};
(node as any).hash = '854e2f4cb5dd3ecb914d5cf144cff3f2';
export default node;
