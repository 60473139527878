import React from "react";
import "./lesson-card.scss";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {NextLessonCard_CourseFragment$key} from "../../../../../../__generated__/NextLessonCard_CourseFragment.graphql";
import {NextLessonCard_CourseStateFragment$key} from "../../../../../../__generated__/NextLessonCard_CourseStateFragment.graphql";
import {NextLessonCard_LessonFragment$key} from "../../../../../../__generated__/NextLessonCard_LessonFragment.graphql";
import {UpperCourseCard} from "../../../common/UpperCourseCard";
import styled from "styled-components";
import {AcademyCard} from "../../../../../theme/AcademyCard";
import {academyTypographyForStyledComps} from "../../../../../theme/academy-typography-for-styled-comps";
import {useTranslation} from "react-i18next";
import {OrderCertificateButton} from "../../../../../state/components/OrderCertificateButton";
import {AcademyButton} from "../../../../../core/components/button/AcademyButton";
import {NavLink} from "react-router-dom";
import {truncateText} from "../../../../../core/utils/utils";

const COURSE_FRAGMENT = graphql`
    fragment NextLessonCard_CourseFragment on Course {
        id
        containerMeta {
            title
        }
        isIHK
        icon {
            url
        }
        ...UpperCourseCard_CourseFragment
    }
`;

const COURSE_STATE_FRAGMENT = graphql`
    fragment NextLessonCard_CourseStateFragment on CourseState {
        isBought
        isCanOrderCertificate
        isCertificateOrdered
        ...UpperCourseCard_CourseStateFragment
    }
`;

const NEXT_LESSON_CARD_LESSON_FRAGMENT = graphql`
    fragment NextLessonCard_LessonFragment on Lesson {
        id
        lessonPath {
            moduleIndex
            lessonIndex
            moduleRef
        }
        containerMeta {
            title
        }
    }
`;

interface OwnProps {
    courseFragmentRef: NextLessonCard_CourseFragment$key;
    lessonFragmentRef: NextLessonCard_LessonFragment$key;
    courseStateFragmentRef: NextLessonCard_CourseStateFragment$key;
    courseStatus: string;
}

export const NextLessonCard = ({
                                   courseFragmentRef,
                                   lessonFragmentRef,
                                   courseStateFragmentRef,
                                   courseStatus
                               }: OwnProps) => {
    const {t} = useTranslation('courses');

    const course = useFragment<NextLessonCard_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef)

    const lesson = useFragment<NextLessonCard_LessonFragment$key>(NEXT_LESSON_CARD_LESSON_FRAGMENT, lessonFragmentRef)

    const courseState = useFragment<NextLessonCard_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, courseStateFragmentRef);

    return <StyledNextLessonCard>
        <UpperCourseCard courseFragmentRef={course} courseStateFragmentRef={courseState} courseStatus={courseStatus}
                         showProgressBarOrTestItForFreeContainer={false} showBorderBottom={true}/>
        <div>
            <LessonLocation>{t("next-lesson.module-shortcut")} {lesson.lessonPath.moduleIndex + 1} | {t("next-lesson.lesson")} {lesson.lessonPath.moduleIndex + 1}.{lesson.lessonPath.lessonIndex + 1}</LessonLocation>
            <LessonTitle>{truncateText(lesson.containerMeta.title, 40)}</LessonTitle>
            <div>
                {courseState?.isCanOrderCertificate && !courseState?.isCertificateOrdered ?
                    <OrderCertificateButton courseId={course.id}/> :
                    <NavLink
                        to={`/courses/${course.id}/modules/${lesson.lessonPath.moduleRef}/lessons/${lesson.id}`}>
                        <AcademyButton className="w-100 h-auto" text={t('next-lesson.button')}/>
                    </NavLink>}
            </div>
        </div>
    </StyledNextLessonCard>
}

const StyledNextLessonCard = styled(AcademyCard)`
    padding: 20px;
`

const LessonLocation = styled.div`
    margin-top: 12px;
    margin-bottom: 3px;
    ${academyTypographyForStyledComps.smallText}
`

const LessonTitle = styled.div`
    margin-bottom: 10px;
    ${academyTypographyForStyledComps.regularTextBold}
`