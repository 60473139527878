import {Form, Formik} from "formik";
import * as Yup from "yup";
import * as yup from "yup";
import {NavLink, useLocation} from "react-router-dom";
import React, {ReactNode, useState} from "react";
import {useTranslation} from "react-i18next";
import {ValidatedField} from "../../../core/components/form/ValidatedField";

import {AuthScreenHeading} from "../common/AuthScreenHeading";
import {AuthFormWrapper} from "../common/AuthFormWrapper";
import {SwitchField} from "../../../core/components/form/SwitchField";
import {SimpleOptionSelectField} from "../../../core/components/select/SimpleOptionSelectField";
import {tkColors} from "../../../core/style/tkColors";
import {tkTypography} from "../../../core/style/tkTypography";
import styled from "styled-components";

export interface RegistrationData {
    email: string
    password: string
    firstName: string
    lastName: string
    industry?: string
    companySize?: string
    position?: string
    adsOptIn?: boolean
    accountMeta?: { [key: string]: string }
    token?: string
}

interface OwnProps {
    doRegister: (registration: RegistrationData) => void
    isInFlight: boolean
    registrationSucceeded: boolean
    headerSlotPageOne?: ReactNode
    switchToLogin: () => void
}

export const RegistrationForm = ({
                                     doRegister,
                                     isInFlight,
                                     registrationSucceeded,
                                     headerSlotPageOne,
                                     switchToLogin
                                 }: OwnProps) => {
    const {t} = useTranslation(["auth", "core"]);
    const location = useLocation();
    const [page, setPage] = useState<number>(0);

    const SuccessScreen = <>
        <AuthScreenHeading
            title={t("registration-form.success.heading-1")}
            subtitle={t("registration-form.success.heading-2")}
        />
        <div className="tk-bg-green-20 mb-5 p-5">
            {t("registration-form.success-text")}
        </div>
        <NavLink to="/login">
            <button type="button"
                    className="btn btn-primary align-self-end w-100"
            >
                {t("registration-form.success-to-login")}
            </button>
        </NavLink>
    </>;
    const parameters = new URLSearchParams(location.search);

    return <Formik
        initialValues={{
            email: parameters.has("email") ? parameters.get("email") : "",
            password: "",
            password2: "",
            firstName: "",
            lastName: "",
            legal: false,
            position: "",
            companySize: "",
            industry: "",
            adsOptIn: false,
        }}
        validationSchema={
            Yup.object().shape({
                email: Yup
                    .string()
                    .email(t("registration-form.email-error"))
                    .required(t("core:forms.required-field", {fieldName: t("registration-form.email")})),
                password: Yup
                    .string()
                    .min(8, t("registration-form.password-error"))
                    .required(t("core:forms.required-field", {fieldName: t("registration-form.password")})),
                password2: Yup.string()
                    .oneOf([Yup.ref("password"), undefined], t("registration-form.passwords-must-match")),
                firstName: Yup
                    .string()
                    .required(t("core:forms.required-field", {fieldName: t("registration-form.first-name")})),
                lastName: Yup
                    .string()
                    .required(t("core:forms.required-field", {fieldName: t("registration-form.last-name")})),
                legal: yup
                    .boolean()
                    .oneOf([true], t("core:forms.required-field", {fieldName: t("registration-form.legal")}))
                    .required(t("core:forms.required-field", {fieldName: t("registration-form.legal")})),
            })
        }
        onSubmit={values => {
            if (values.password === values.password2) {
                doRegister({
                    email: values.email!,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    password: values.password,
                    companySize: values.companySize,
                    industry: values.industry,
                    position: values.position,
                    adsOptIn: values.adsOptIn
                })
            }
        }}
    >
        {formikState => (
            <AuthFormWrapper>
                {registrationSucceeded ? SuccessScreen : <Form>
                    {page === 0 ? <>
                        {headerSlotPageOne ? headerSlotPageOne : <AuthScreenHeading
                            title={t("registration-form.page0.heading-1")}
                            subtitle={t("registration-form.page0.heading-2")}
                        />}
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            iconClass={"icon-d_mail"}
                                            type="email"
                                            placeholder={t("registration-form.e-mail-placeholder")}
                                            name="email"
                                            className="form-control default-input"
                                            required/>
                        </div>
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            type="password"
                                            iconClass={"icon-a_lock"}
                                            placeholder={t("registration-form.password-placeholder")}
                                            name="password"
                                            className="form-control default-input"
                                            required/>
                        </div>
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            type="password"
                                            iconClass={"icon-a_lock"}
                                            placeholder={t("registration-form.password2-placeholder")}
                                            name="password2"
                                            className="form-control default-input"
                                            required/>
                        </div>
                    </> : <>
                        <AuthScreenHeading
                            title={t("registration-form.page1.heading-1")}
                            subtitle={t("registration-form.page1.heading-2")}
                        />
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            type="text"
                                            placeholder={t("registration-form.first-name-placeholder")}
                                            name="firstName"
                                            className="form-control default-input"
                                            required/>
                        </div>
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            type="text"
                                            placeholder={t("registration-form.last-name-placeholder")}
                                            name="lastName"
                                            className="form-control default-input"
                                            required/>
                        </div>
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            component={SimpleOptionSelectField}
                                            placeholder={t("registration-form.company-size-placeholder")}
                                            name="companySize"
                                            className="form-control default-input"
                                            options={["1-10", "11-50", "50-150", "150-250", "250-500", "500+"].map(key => ({
                                                label: key,
                                                value: key,
                                            }))}
                            />
                        </div>
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            component={SimpleOptionSelectField}
                                            placeholder={t("registration-form.industry-placeholder")}
                                            name="industry"
                                            className="form-control default-input"
                                            options={["automotive",
                                                "finance-insurance",
                                                "healthcare",
                                                "it",
                                                "tourism",
                                                "fashion",
                                                "furniture",
                                                "energy",
                                                "logistics-transport",
                                                "media-marketing",
                                                "food",
                                                "other"].map(key => ({
                                                label: t("registration-form.industry-options." + key),
                                                value: key,
                                            }))}
                            />
                        </div>
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            component={SimpleOptionSelectField}
                                            placeholder={t("registration-form.position-placeholder")}
                                            name="position"
                                            className="form-control default-input"
                                            options={["c-level",
                                                "management",
                                                "human-resources",
                                                "marketing",
                                                "it",
                                                "accounting",
                                                "admin",
                                                "other"].map(key => ({
                                                label: t("registration-form.position-options." + key),
                                                value: key,
                                            }))}
                            />
                        </div>
                        <div className="form-group">
                            <ValidatedField
                                name="legal"
                                component={SwitchField}
                                titleRight={true}
                                titleClassName="tk-academy-text tk-color-waterblue ml-3"
                                title={<>Mit deiner kostenlosen Registrierung stimmst du der <a
                                    href='https://www.thekey.academy/datenschutzerklaerung/'
                                    rel="noopener noreferrer"
                                    target='_blank'>Datenschutzerklärung</a>, der  <a
                                    href='https://www.thekey.academy/transparenzerklaerung/'
                                    rel="noopener noreferrer"
                                    target='_blank'>Transparenzerklärung</a> und den <a
                                    rel="noopener noreferrer"
                                    href='https://www.thekey.academy/agbs/'
                                    target='_blank'>Nutzungsbedingungen</a> zu. (erforderlich)</>}
                                formikState={formikState}/>
                        </div>
                        <div className="form-group">
                            <ValidatedField
                                name="adsOptIn"
                                component={SwitchField}
                                titleRight={true}
                                titleClassName="tk-academy-text tk-color-waterblue ml-3"
                                title="Ich freue mich, über Aktionen, Rabatte und Neuigkeiten per E-Mail informiert zu werden."
                                formikState={formikState}/>
                        </div>
                    </>}
                    <div className="form-group d-flex mb-4 mt-4">
                        <button type="button"
                                disabled={formikState.isSubmitting || isInFlight}
                                className="btn btn-primary align-self-end w-100"
                                onClick={() => {
                                    if (page === 0 && formikState.touched.email &&
                                        formikState.touched.password &&
                                        formikState.touched.password2 &&
                                        !formikState.errors.password &&
                                        !formikState.errors.password2 &&
                                        !formikState.errors.email) {
                                        formikState.setErrors({});
                                        formikState.setTouched({});
                                        setPage(1);
                                    } else {
                                        formikState.handleSubmit()
                                    }
                                }}
                        >
                            {t("registration-form.login")}
                        </button>
                    </div>

                    <div className="mb-4 text-center">
                        <ToLoginButton onClick={switchToLogin}>{t("registration-form.to-login")}</ToLoginButton>
                    </div>
                </Form>}
            </AuthFormWrapper>
        )}
    </Formik>
}

const ToLoginButton = styled.a`
    ${tkColors.keyBlue}
    ${tkTypography.small}
`
