import React from 'react'
import './failed-cloze-text-element-display.scss'
import {BaseElementDisplay} from "../../../../elementFlow/components/common/BaseElementDisplay";
import {useTranslation} from "react-i18next";
import {WordArea} from "../../../../elementFlow/components/cloze-text/WordArea";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {FailedClozeElement_FailedElementFragment$key} from "../../../../../__generated__/FailedClozeElement_FailedElementFragment.graphql";

const FAILED_ELEMENT_FRAGMENT = graphql`
    fragment FailedClozeElement_FailedElementFragment on FailedElement {
        element {
            ... on ClozeTextElement {
                additionalWords
                availableWordsInRandomOrder
                parts {
                    partType
                    ... on TextPart {
                        text
                    }
                    ... on ClozePart {
                        cloze
                    }
                }
            }

        }
        mistakes {
            ... on ClozeTextMistakes {
                explanations {
                    cloze
                    text
                    isCorrect
                }
            }
        }
    }
`

interface OwnProps {
    failedElementFragmentRef: FailedClozeElement_FailedElementFragment$key;
}

export const FailedClozeElement = ({failedElementFragmentRef}: OwnProps) => {
    const {t} = useTranslation("elementflow")

    const failedElement = useFragment<FailedClozeElement_FailedElementFragment$key>(FAILED_ELEMENT_FRAGMENT, failedElementFragmentRef)

    return <BaseElementDisplay heading={t("cloze-text-element-display.heading")} elementSpecificSlot={
        <div className={'failed-cloze-element'}>
            <WordArea
                selectWord={() => {
                }}
                availableWords={failedElement.element.availableWordsInRandomOrder || []}/>
            <div className="cloze-text">
                {failedElement.element.parts?.map((part, index) => {
                    if (part.partType === 'text') {
                        return <span key={`text-${index}`} className="text">{part.text!.trim() + " "}</span>
                    } else if (part.partType === 'cloze') {
                        const answer = failedElement.mistakes.explanations?.find(e => e.cloze === part.cloze)

                        const correctAnswer = <div className={`word-wrapper correct`}>{part.cloze}</div>;

                        if (!answer) return <React.Fragment key={`cloze-${index}`}>correctAnswer</React.Fragment>;

                        return <React.Fragment key={`cloze-${index}`}>
                            {answer.isCorrect ? correctAnswer : <>
                                <div className={`word-wrapper mistake`}>{answer?.text}</div>
                                {correctAnswer}
                            </>}</React.Fragment>;
                    }
                    return null;
                })}
            </div>
        </div>
    }
    />
}