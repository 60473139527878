import React from 'react';
import LockRed from '../../../../assets/images/lock-red.png';
import LockBlue from '../../../../assets/images/lock-blue.png';
import {
    CourseAttributes,
    CourseProgressBar_CourseStateFragment$key
} from "../../../../__generated__/CourseProgressBar_CourseStateFragment.graphql";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {ProgressBar} from "../../../core/components/progress/ProgressBar";

interface OwnProps {
    courseStateFragmentRef: CourseProgressBar_CourseStateFragment$key;
}

const COURSE_STATE_FRAGMENT = graphql`
    fragment CourseProgressBar_CourseStateFragment on CourseState {
        course {
            attributes
            isFreeCourse
        }
        isAtEndOfTrial
        isBought
        isDone
        progress {
            progressPercentage
        }
        course {
            lessons {
                isPartOfTrial
            }
        }
    }
`

export const CourseProgressBar = ({courseStateFragmentRef}: OwnProps) => {

    const courseState = useFragment<CourseProgressBar_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, courseStateFragmentRef)

    const course = courseState.course
    const numberOfTrialLessons = course.lessons.filter(l => l.isPartOfTrial).length
    const numberOfLessons = course.lessons.length

    return <ProgressBar
        percentage100={courseState.progress.progressPercentage}
        showPercentage={true}
        barColor={courseState.isDone ? 'green' : 'blue'}
        dotsAtPercentages={courseState.isBought || course.isFreeCourse || course.attributes.find(a => a === "earlyBird" as CourseAttributes) ? undefined : [(100 * numberOfTrialLessons) / numberOfLessons]}
        dotIcon={() => <img style={{height: 20, width: 20}} src={courseState.isAtEndOfTrial ? LockRed : LockBlue} alt={''}/>}
    />
}
