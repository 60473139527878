import {FieldProps} from "formik";
import React from "react";
import Select from "react-select";
import {ClearIndicator} from "./ClearIndicator";

interface OwnProps<T> {
    options: OptionType<T>[];
    placeholder: string;
    isMulti?: boolean
    isClearable?: boolean
    disabled?: boolean
}

type Props<T> = FieldProps & OwnProps<T>;

export interface OptionType<T> {
    value: T;
    label: string;
}

export function SimpleOptionSelectField<T>({
                                               field,
                                               form,
                                               placeholder,
                                               options,
                                               isClearable,
                                               isMulti,
                                               disabled
                                           }: Props<T>) {
    return (<Select
        className="react-select category-select"
        classNamePrefix="react-select"
        components={{ClearIndicator}}
        placeholder={placeholder}
        noOptionsMessage={() => "Nichts gefunden."}
        options={options}
        isDisabled={disabled}
        name={field.name}
        value={isMulti ? options.filter(o => field.value ? field.value.includes(o.value) : false) : options.find(o => o.value === field.value)}
        onChange={(item: any) => {
            if (isMulti) {
                form.setFieldValue(field.name, item ? item.map((i: any) => i.value) : [], true)
            } else {
                form.setFieldValue(field.name, item ? item.value : undefined, true)
            }
        }}
        onInputChange={() => {
            form.setTouched({
                [field.name]: true
            })
        }}
        onMenuOpen={() => {
            form.setTouched({
                [field.name]: true
            })
        }}
        isMulti={isMulti}
        isClearable={isClearable}
        getOptionValue={(opt: any) => opt.value}
        getOptionLabel={item => item.label}
        onBlur={field.onBlur}
    />);
}
