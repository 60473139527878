import React, {Suspense} from "react";
import {ElementFlowContextProvider} from "@thekeytechnology/thekey-academy-frontend-library";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {Element} from "@thekeytechnology/thekey-academy-frontend-library";
import {ReviewProcessScreen_SubmitReviewMutation} from "../../../../__generated__/ReviewProcessScreen_SubmitReviewMutation.graphql";
import {ReviewProcessCurrentElementScreen} from "./ReviewProcessCurrentElementScreen";
import {ReviewProcessScreen_ElementsConnectionFragment$key} from "../../../../__generated__/ReviewProcessScreen_ElementsConnectionFragment.graphql";
import {ReviewProcessScreen_CourseFragment$key} from "../../../../__generated__/ReviewProcessScreen_CourseFragment.graphql";
import {useHistory} from "react-router";
import {LoadingFakeLearnReviewScreen} from "../../../core/loading/flow/learnReview/LoadingFakeLearnReviewScreen";

const SUBMIT_REVIEW_MUTATION = graphql`
    mutation ReviewProcessScreen_SubmitReviewMutation($courseId: ID!, $elementSubmissionsJson: String!) {
        Submission {
            submitReview(input: {courseId: $courseId, elementSubmissions: $elementSubmissionsJson}) {
                submission {
                    id
                }
            }
        }
    }`

const COURSE_FRAGMENT = graphql`
    fragment ReviewProcessScreen_CourseFragment on Course {
        id
        ...ReviewProcessCurrentElementScreen_CourseFragment
    }`

const ELEMENTS_CONNECTION_FRAGMENT = graphql`
    fragment ReviewProcessScreen_ElementsConnectionFragment on ElementsConnection {
        ...ReviewProcessCurrentElementScreen_ElementsConnectionFragment
    }`

interface OwnProps {
    courseFragmentRef: ReviewProcessScreen_CourseFragment$key;
    elements: Element[];
    elementsConnectionFragmentRef: ReviewProcessScreen_ElementsConnectionFragment$key;
    onSubmitReviewCompleted: () => void;
}

export const ReviewProcessScreen = ({
                                        courseFragmentRef,
                                        elements,
                                        elementsConnectionFragmentRef,
                                        onSubmitReviewCompleted
                                    }: OwnProps) => {
    const history = useHistory();

    const [submitReview] = useMutation<ReviewProcessScreen_SubmitReviewMutation>(SUBMIT_REVIEW_MUTATION)

    const course = useFragment<ReviewProcessScreen_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef)

    const elementsConnection = useFragment<ReviewProcessScreen_ElementsConnectionFragment$key>(ELEMENTS_CONNECTION_FRAGMENT, elementsConnectionFragmentRef)

    return <Suspense fallback={<LoadingFakeLearnReviewScreen/>}><ElementFlowContextProvider
        elements={elements}
        isTestLesson={true}
        isCertificationTest={false}
        onFinished={finalState => {
            submitReview({
                variables: {
                    courseId: course.id,
                    elementSubmissionsJson: JSON.stringify(finalState.elementSubmissions)
                },
                onCompleted: (response) => {
                    onSubmitReviewCompleted();
                    history.push(`/courses/${course.id}/submissions/${response.Submission.submitReview?.submission.id}`)
                }
            })
        }
        }
    >
        <ReviewProcessCurrentElementScreen courseFragmentRef={course}
                                           elementsConnectionFragmentRef={elementsConnection}/>
    </ElementFlowContextProvider></Suspense>
}