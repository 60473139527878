import React, {useContext, useState} from "react";
import {Screen} from "../../../core/components/screen/Screen";
import {BlueBackground} from "../../../core/components/screen/BlueBackground";
import {LearnSidebar} from "./LearnSidebar";
import {LearnContentArea} from "./LearnContentArea";
import {ElementFlowContext} from "@thekeytechnology/thekey-academy-frontend-library"
import {LearnFooter} from "./footer/LearnFooter";
import {LearnHeader} from "./LearnHeader";
import {Prompt} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useFragment} from "react-relay";
import {ElementDisplay} from "../../../elementFlow/components/ElementDisplay";
import {graphql} from "babel-plugin-relay/macro";
import {LearnCurrentElementScreen_LessonFragment$key} from "../../../../__generated__/LearnCurrentElementScreen_LessonFragment.graphql";
import {LoadingFakeSubmitLessonOrReviewScreen} from "../../../core/loading/flow/submission/LoadingFakeSubmitLessonOrReviewScreen";

const LESSON_FRAGMENT = graphql`
    fragment LearnCurrentElementScreen_LessonFragment on Lesson {
        id
        course {
            ...LearnContentArea_CourseFragment
        }
        elements {
            edges {
                node {
                    id
                    elementType
                    ...ElementDisplay_ElementFragment
                    ...LearnContentArea_ElementFragment
                    ...LearnHeader_ElementFragment
                    ...LearnSidebar_ElementFragment
                }
            }
        }
        ...LearnContentArea_LessonFragment
        ...LearnFooter_LessonFragment
        ...LearnHeader_LessonFragment
        ...LearnSidebar_LessonFragment
    }
`

interface OwnProps {
    lessonFragmentRef: LearnCurrentElementScreen_LessonFragment$key;
}

export const LearnCurrentElementScreen = ({lessonFragmentRef}: OwnProps) => {

    const {t} = useTranslation("learn")

    const {flowState: {currentElementId, isFinished, triesNeeded}} = useContext(ElementFlowContext)

    const lesson = useFragment<LearnCurrentElementScreen_LessonFragment$key>(LESSON_FRAGMENT, lessonFragmentRef)

    const element = lesson.elements?.edges?.filter(e => !!e).find(e => e!.node.id === currentElementId)?.node

    const [timestampForSubtitles, setTimestampForSubtitles] = useState<number>(0)

    if (isFinished && !element) return <LoadingFakeSubmitLessonOrReviewScreen/>

    return element ? <>
        <Prompt
            message={location => {
                if (!location.pathname.includes("learn")) {
                    return t("learn-screen.cancel-dialog.question") + " " + t("learn-screen.cancel-dialog.explanation")
                }
                return true;
            }}
        /><Screen
        className="learn-screen"
        sidebarSlot={<LearnSidebar elementFragmentRef={element} lessonFragmentRef={lesson}/>}
        headerBarBackgroundSlot={
            <BlueBackground
                buttonSlot={<LearnHeader triesNeeded={triesNeeded} elementFragmentRef={element}
                                         lessonFragmentRef={lesson}/>}
                height={90}/>
        }
        floatingFooterSlot={<LearnFooter lessonFragmentRef={lesson}/>}
        contentAreaRenderer={(children => <LearnContentArea
            courseFragmentRef={lesson.course}
            lessonFragmentRef={lesson}
            elementFragmentRef={element}
            timestampForSubtitles={timestampForSubtitles}
        >{children}</LearnContentArea>)}>
        <ElementDisplay elementFragmentRef={element} videoElementSpecific={
            {
                setTimestampForSubtitles: setTimestampForSubtitles,
                isSettingTimestampInNoteContext: true
            }
        }/>
    </Screen>
    </> : null
}