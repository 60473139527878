/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ElementType = "clozeText" | "image" | "multipleChoice" | "order" | "persolog" | "questionnaire" | "resolution" | "text" | "video" | "%future added value";
export type LearnHeader_ElementFragment = {
    readonly elementType: ElementType;
    readonly generalSettings: {
        readonly points: number;
    };
    readonly id: string;
    readonly " $refType": "LearnHeader_ElementFragment";
};
export type LearnHeader_ElementFragment$data = LearnHeader_ElementFragment;
export type LearnHeader_ElementFragment$key = {
    readonly " $data"?: LearnHeader_ElementFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LearnHeader_ElementFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LearnHeader_ElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GeneralElementSettings",
      "kind": "LinkedField",
      "name": "generalSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "points",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Element",
  "abstractKey": "__isElement"
};
(node as any).hash = 'baf2b5a21c586bcbe6ea1f65b1c3a5c3';
export default node;
