import { NavLink } from "react-router-dom";
import React from "react";
import "./review-link.scss";

interface OwnProps {
    className?: string;
    courseId: string
}

export const ReviewLink = ({className, courseId}: OwnProps) => {
    return <NavLink to={`/courses/${courseId}/review`}
                    className={`review-link d-flex align-items-center tk-academy-small-text tk-color-keyblue ${className ? className : ""}`}>
        <span className="icon-a_quiz mr-2"/>
        Review
    </NavLink>
}
