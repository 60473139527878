import styled from "styled-components";
import {tkColorCodes} from "../../../core/style/tkColorCodes";

export const TextWrapper = styled.div<{ isExpanded: boolean }>`
  position: relative;
  ${props => props.isExpanded ? null : `height: 130px;`}

  ${props => props.isExpanded ? null : `:after {
  position: absolute;
  bottom: -1px;
  height: 100%;
  width: 100%;
  content: "";
  background: linear-gradient(to top,
  rgba(255, 255, 255, 1) 0%,
  rgba(255, 255, 255, 0) 100%);
  pointer-events: none;
  z-index: 101;
  }`
}
`


export const ToggleButton = styled.button<{ isExpanded: boolean }>`
  ${props => props.isExpanded ? null : `
  position: relative;
  top: -32px;
  `}
  border-radius: 8px;
  height: 30px;
  width: 42px;
  background-color: ${tkColorCodes.waterBlue};
  color: white;
  font-size: 16px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 0 auto;
  z-index: 102;
`