/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LessonInfo_CourseStateFragment = {
    readonly lessonStates: ReadonlyArray<{
        readonly lessonRef: string;
        readonly " $fragmentRefs": FragmentRefs<"LessonImage_LessonStateFragment">;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"LessonAdditionalContentList_CourseStateFragment" | "CourseAd_CourseStateFragment">;
    readonly " $refType": "LessonInfo_CourseStateFragment";
};
export type LessonInfo_CourseStateFragment$data = LessonInfo_CourseStateFragment;
export type LessonInfo_CourseStateFragment$key = {
    readonly " $data"?: LessonInfo_CourseStateFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LessonInfo_CourseStateFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonInfo_CourseStateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LessonState",
      "kind": "LinkedField",
      "name": "lessonStates",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lessonRef",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LessonImage_LessonStateFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LessonAdditionalContentList_CourseStateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseAd_CourseStateFragment"
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};
(node as any).hash = '45afe915898c872b576dc087aab98107';
export default node;
