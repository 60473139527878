import React from "react";
import {RouteAuthState, RouteDefinition} from "../core/components/routing/RouteDefinition";
import {ActivationScreen} from "./components/activate/ActivationScreen";
import {RegistrationScreen} from "./components/registration/RegistrationScreen";
import {LoginScreen} from "./components/login/LoginScreen";
import {ForgotPasswordScreen} from "./components/forgot-password/ForgotPasswordScreen";
import {ResetPasswordScreen} from "./components/reset-password/ResetPasswordScreen";
import {ResendActivationScreen} from "./components/resend-activation/ResendActivationScreen";
import {InvitationScreen} from "./components/invitation/InvitationScreen";

export const AuthRouteDefinitions: RouteDefinition[] = [
    {
        path: "/activate/:token",
        component: () => <ActivationScreen/>,
        authState: RouteAuthState.OnlyLoggedOut
    },
    {
        path: "/register",
        component: () => <RegistrationScreen/>,
        authState: RouteAuthState.OnlyLoggedOut
    },
    {
        path: "/login",
        component: () => <LoginScreen/>,
        authState: RouteAuthState.OnlyLoggedOut
    },
    {
        path: "/forgot-password",
        component: () => <ForgotPasswordScreen/>,
        authState: RouteAuthState.OnlyLoggedOut
    },
    {
        path: "/reset-password/:token",
        component: () => <ResetPasswordScreen/>,
        authState: RouteAuthState.OnlyLoggedOut
    },
    {
        path: "/resend-activation",
        component: () => <ResendActivationScreen/>,
        authState: RouteAuthState.OnlyLoggedOut
    },
    {
        path: "/accept-invitation/:token",
        component: (props) => <InvitationScreen token={props.match?.params.token}/>,
        authState: RouteAuthState.Independent
    },
]
