import {CurrencyDisplay} from "../../../../../core/components/currency/CurrencyDisplay";
import React, {ReactNode} from "react";
import {PaymentOptionWrapper} from "./PaymentOptionWrapper";
import {useTranslation} from "react-i18next";


import CardImage from "./images/card.png"
import GiropayImage from "./images/giropay.png"
import PaypalImage from "./images/paypal.png"
import SepaImage from "./images/sepa.png"
import SofortImage from "./images/sofort.png"
import InvoiceImage from "./images/invoice.png"
import {
    MonthlyPriceExplanation,
    PaymentMethodIds,
    paymentMethodIsMonthly,
    PriceExplanation
} from "@thekeytechnology/thekey-academy-frontend-library";

interface OwnProps {
    isSelected: boolean;
    onSelected: () => void;
    errors: any;

    paymentMethod: PaymentMethodIds
    price: PriceExplanation
    monthlyPrice?: MonthlyPriceExplanation
    paymentFieldData?: ReactNode
}

export const PaymentOption = ({
                                  isSelected,
                                  onSelected,
                                  errors,
                                  paymentFieldData,
                                  paymentMethod,
                                  price,
                                  monthlyPrice
                              }: OwnProps) => {

    const {t} = useTranslation("billing");

    let Image;
    switch (paymentMethod) {
        case "card":
            Image = CardImage
            break;
        case "giropay":
            Image = GiropayImage;
            break;
        case "paypal":
            Image = PaypalImage;
            break;
        case "sofort":
            Image = SofortImage;
            break;
        case "monthly-tk":
        case "monthly-partner":
        case "sepa":
            Image = SepaImage;
            break;
        case "invoice-tk":
        case "invoice-partner":
            Image = InvoiceImage;
            break;
    }

    const title = t("payment-methods." + paymentMethod);

    let Description;
    if (paymentMethodIsMonthly(paymentMethod) && monthlyPrice) {
        Description = <p className="tk-academy-large tk-black">
            Mit dieser Option entscheidest Du Dich für eine flexible monatliche Zahlungsweise.
            Wir
            buchen dann {monthlyPrice.rateCount} monatliche Zahlungen zu
            je <CurrencyDisplay
            value={monthlyPrice.discountedMonthlyRateGross}/> von Deinem
            Konto ab.
            Hierzu musst Du
            uns mit Bestätigung des Feldes ein Lastschriftmandat erteilen.<br/>
            <strong>Gesamtkosten: <CurrencyDisplay
                value={monthlyPrice.discountedSumGross}/></strong></p>
    } else {
        Description = <p
            className="tk-academy-text tk-black">
            Bei dieser Option entscheidest Du Dich für eine Einmalzahlung.<br/>
            {monthlyPrice ? <strong>Mit der Einmalzahlung per {title} sparst Du <CurrencyDisplay
                value={monthlyPrice.discountedSumGross - price.discountedGrossAmount}/> gegenüber der monatlichen
                Zahlungsweise.<br/>
                Gesamtkosten <CurrencyDisplay
                    value={price.discountedGrossAmount}/></strong> : null}
        </p>
    }

    return <PaymentOptionWrapper title={title}
                                 image={Image}
                                 explanation={Description}
                                 isSelected={isSelected}
                                 onSelected={() => onSelected()}
                                 error={errors}>
        {paymentFieldData}
    </PaymentOptionWrapper>
}
