import React, {useRef} from "react";
import {useDrag, useDrop} from "react-dnd";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {PersologAnswerDisplay_PersologAnswerFragment$key} from "../../../../__generated__/PersologAnswerDisplay_PersologAnswerFragment.graphql";

const PERSOLOG_ANSWER_DISPLAY_PERSOLOG_ANSWER_FRAGMENT = graphql`
    fragment PersologAnswerDisplay_PersologAnswerFragment on PersologAnswer {
        answer
    }`

interface OwnProps {
    currentIndex: number;
    moveElement: (indexA: number, indexB: number) => void;
    persologAnswerFragmentRef: PersologAnswerDisplay_PersologAnswerFragment$key;
}

export interface OrderableDragItem {
    type: string;
    index: number;
}

export const PersologAnswerDisplay = ({
                                          currentIndex,
                                          moveElement,
                                          persologAnswerFragmentRef
                                      }: OwnProps) => {
    const persologAnswer = useFragment<PersologAnswerDisplay_PersologAnswerFragment$key>(PERSOLOG_ANSWER_DISPLAY_PERSOLOG_ANSWER_FRAGMENT, persologAnswerFragmentRef)

    const ref = useRef<HTMLDivElement>(null);

    const [{isOver, canDrop}, drop] = useDrop<OrderableDragItem, {}, {
        isOver: boolean
        canDrop: boolean
    }>({
        accept: "persolog",
        drop(item: OrderableDragItem) {
            moveElement(item.index, currentIndex)
            return {};
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });

    const [{isDragging}, drag] = useDrag<OrderableDragItem, {}, { isDragging: boolean }>({
        type: "persolog",
        item: {type: "persolog", index: currentIndex} as OrderableDragItem,
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return <div ref={ref}
                className={`orderable ${isDragging ? "is-dragging" : ""} ${isOver ? "is-over" : ""} ${canDrop ? "can-drop" : ""}`}>
        <div className="index">{currentIndex + 1}</div>
        <div className="text">
            <div>{persologAnswer.answer}</div>
        </div>
    </div>

}
