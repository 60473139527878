/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type RegistrationInLicensePoolInput = {
    licensePoolId: string;
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    companySize?: string | null;
    industry?: string | null;
    position?: string | null;
    adsOptIn?: boolean | null;
    accountMeta: Array<AccountMeta>;
    clientMutationId?: string | null;
};
export type AccountMeta = {
    key: string;
    value: string;
};
export type RegistrationInLicensePoolScreen_RegisterMutationVariables = {
    input: RegistrationInLicensePoolInput;
};
export type RegistrationInLicensePoolScreen_RegisterMutationResponse = {
    readonly LicenseAuth: {
        readonly register: {
            readonly userId: string;
        } | null;
    };
};
export type RegistrationInLicensePoolScreen_RegisterMutation = {
    readonly response: RegistrationInLicensePoolScreen_RegisterMutationResponse;
    readonly variables: RegistrationInLicensePoolScreen_RegisterMutationVariables;
};



/*
mutation RegistrationInLicensePoolScreen_RegisterMutation(
  $input: RegistrationInLicensePoolInput!
) {
  LicenseAuth {
    register(input: $input) {
      userId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "LicenseAuthMutations",
    "kind": "LinkedField",
    "name": "LicenseAuth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "RegistrationInLicensePoolPayload",
        "kind": "LinkedField",
        "name": "register",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RegistrationInLicensePoolScreen_RegisterMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RegistrationInLicensePoolScreen_RegisterMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "71e92a5938b42cb702f16826e04fd40b",
    "id": null,
    "metadata": {},
    "name": "RegistrationInLicensePoolScreen_RegisterMutation",
    "operationKind": "mutation",
    "text": "mutation RegistrationInLicensePoolScreen_RegisterMutation(\n  $input: RegistrationInLicensePoolInput!\n) {\n  LicenseAuth {\n    register(input: $input) {\n      userId\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '51ebb0e1bf918a968ee9517357256915';
export default node;
