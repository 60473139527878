import React from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {ClassCardGraphQLWrapper_QuestionnaireClassFragment$key} from "../../../../__generated__/ClassCardGraphQLWrapper_QuestionnaireClassFragment.graphql";
import {ClassCard} from "./ClassCard";

const CLASS_CARD_GRAPHQL_WRAPPER_QUESTIONNAIRE_CLASS_FRAGMENT = graphql`
    fragment ClassCardGraphQLWrapper_QuestionnaireClassFragment on QuestionnaireClass {
        description
        image {
            url
        }
        pointsThreshold
        title
    }`

interface OwnProps {
    className?: string;
    questionnaireClassFragmentRef: ClassCardGraphQLWrapper_QuestionnaireClassFragment$key;
}

export const ClassCardGraphQLWrapper = ({className, questionnaireClassFragmentRef}: OwnProps) => {
    const questionnaireClass = useFragment<ClassCardGraphQLWrapper_QuestionnaireClassFragment$key>(CLASS_CARD_GRAPHQL_WRAPPER_QUESTIONNAIRE_CLASS_FRAGMENT, questionnaireClassFragmentRef || null)

    const questionnaireElementClass: {
        readonly description: string;
        readonly imageUrl?: string;
        readonly pointsThreshold: number;
        readonly title: string;
    } = {
        description: questionnaireClass.description,
        imageUrl: questionnaireClass.image?.url || undefined,
        pointsThreshold: questionnaireClass.pointsThreshold,
        title: questionnaireClass.title
    };

    return <ClassCard className={className} questionnaireElementClass={questionnaireElementClass}/>;
}
