import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_DOWNLOAD_LICENSE_LOG = "download-licenses-log";

export const downloadLicenseLogs = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DOWNLOAD_LICENSE_LOG,
            callType: API_DOWNLOAD_LICENSE_LOG
        },
        payload: undefined
    } as ApiCallAction<void>;
};
