import React, {ReactNode, Suspense} from "react";
import {QnasScreen} from "../../../qnas/components/QnasScreen";
import {useTranslation} from "react-i18next";
import {NotesScreenContent} from "../../../notes/components/NotesScreenContent";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {
    ReviewContentArea_ElementFragment$key
} from "../../../../__generated__/ReviewContentArea_ElementFragment.graphql";
import {ReviewContentArea_CourseFragment$key} from "../../../../__generated__/ReviewContentArea_CourseFragment.graphql";
import {LoadingFakeReviewContentArea} from "../../../core/loading/flow/learnReview/LoadingFakeReviewContentArea";
import {LearnReviewSecondarySidebar} from "../../LearnReviewSecondarySidebar";
import {LearnReviewSecondaryContentArea} from "../../LearnReviewSecondaryContentArea";
import {LearnReviewPrimaryContentArea} from "../../LearnReviewPrimaryContentArea";
import {Route, Switch} from "react-router-dom";

const COURSE_FRAGMENT = graphql`
    fragment ReviewContentArea_CourseFragment on Course {
        ...NotesScreenContent_CourseFragment
    }`

const ELEMENT_FRAGMENT = graphql`
    fragment ReviewContentArea_ElementFragment on Element {
        elementPath {
            courseRef
            lessonRef
        }
        elementType
        id
        ...NotesScreenContent_ElementFragment
    }`


interface OwnProps {
    children: ReactNode;
    courseFragmentRef: ReviewContentArea_CourseFragment$key;
    elementFragmentRef: ReviewContentArea_ElementFragment$key;
}

export const ReviewContentArea = ({children, courseFragmentRef, elementFragmentRef}: OwnProps) => {
    const {t} = useTranslation("menu")

    const course = useFragment<ReviewContentArea_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef)
    const element = useFragment<ReviewContentArea_ElementFragment$key>(ELEMENT_FRAGMENT, elementFragmentRef)

    return <Suspense fallback={<LoadingFakeReviewContentArea/>}>
        <LearnReviewPrimaryContentArea>
            <Suspense fallback={<LearnReviewSecondarySidebar/>}>
                <Switch>
                    <Route path="/courses/:courseId/review/notes"
                           exact
                           component={() => <LearnReviewSecondarySidebar>
                               <NotesScreenContent
                                   courseFragmentRef={course}
                                   elementFragmentRef={element}
                                   headingSlot={<div
                                       className="tk-academy-large-bold mb-3 ">{`Notizen für ${t("routes.course." + element.elementType)}`}</div>}
                               />
                           </LearnReviewSecondarySidebar>}/>
                    <Route path="/courses/:courseId/review/qnas"
                           exact
                           component={() => <LearnReviewSecondarySidebar>
                               <QnasScreen
                                   courseId={element.elementPath.courseRef}
                                   lessonId={element.elementPath.lessonRef}
                                   elementId={element.id}
                                   headingSlot={<div
                                       className="tk-academy-large-bold mb-3 ">{`Fragen & Antworten für ${t("routes.course." + element.elementType)}`}</div>}
                               />
                           </LearnReviewSecondarySidebar>}/>
                </Switch>
            </Suspense>
            <LearnReviewSecondaryContentArea>
                {children}
            </LearnReviewSecondaryContentArea>
        </LearnReviewPrimaryContentArea>
    </Suspense>
}
