import {useDispatch} from "react-redux";
import {NavLink} from "react-router-dom";
import React from "react";
import {AcademyButton} from "../../core/components/button/AcademyButton";
import {useTranslation} from "react-i18next";
import {
    hideCelebrationAction
} from "@thekeytechnology/thekey-academy-frontend-library";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {NextLessonButton_CourseStateFragment$key} from "../../../__generated__/NextLessonButton_CourseStateFragment.graphql";

const NEXT_LESSON_BUTTON_COURSE_STATE_FRAGMENT = graphql`
    fragment NextLessonButton_CourseStateFragment on CourseState {
        course {
            lessons {
                id
                lessonPath {
                    moduleRef
                }
            }
        }
        courseRef
        lessonStates {
            status
            lessonRef
        }
    }
`;

interface OwnProps {
    courseStateFragmentRef: NextLessonButton_CourseStateFragment$key | null;
    disabled?: boolean;
    onSwitchToLesson?: () => void;
}

export const NextLessonButton = ({courseStateFragmentRef, disabled, onSwitchToLesson}: OwnProps) => {

    const {t} = useTranslation("state");
    const dispatch = useDispatch()

    const courseState = useFragment<NextLessonButton_CourseStateFragment$key>(NEXT_LESSON_BUTTON_COURSE_STATE_FRAGMENT, courseStateFragmentRef)

    const nextLessonState = courseState?.lessonStates.find(lS => lS.status === 'current')

    const nextLesson = nextLessonState ? courseState?.course.lessons.find(l => nextLessonState.lessonRef === l.id) : undefined;

    return courseState && nextLessonState && nextLesson ? <NavLink
        onClick={() => disabled && onSwitchToLesson ? onSwitchToLesson() : null}
        to={`/courses/${courseState.courseRef}/modules/${nextLesson.lessonPath.moduleRef}/lessons/${nextLessonState.lessonRef}`}>
        <AcademyButton disabled={disabled} text={
            t("next-lesson-button.start-lesson")
        } onClick={() => dispatch(hideCelebrationAction())}/>
    </NavLink> : null;
}
