import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import React from "react";
import {CartTotalDisplay_CartTotalsFragment$key} from "../../../../../__generated__/CartTotalDisplay_CartTotalsFragment.graphql";
import styled, {css} from "styled-components";
import {LargeText} from "../../../../theme/Typography";
import {CurrencyDisplay} from "../../../../core/components/currency/CurrencyDisplay";

const CART_TOTAL_FRAGMENTS = graphql`
    fragment CartTotalDisplay_CartTotalsFragment on CartTotals {
        totalsWithoutDiscounts {
            grossPrice
            netPrice
            taxRatePercentage
        }
        monthlyOptions {
            monthlyRate {
                grossPrice
                netPrice
            }
            rateCount
            downPayment {
                netPrice
                grossPrice
            }
            financedAmount {
                netPrice
                grossPrice
            }
        }
        totalAppliedDiscountPercentage
        totalAppliedDiscount {
            netPrice
            grossPrice
        }
        totalsIncludingAllDiscounts {
            grossPrice
            netPrice
            taxRatePercentage
        }
    }
`;

interface OwnProps {
    cartTotalsFragmentRef?: CartTotalDisplay_CartTotalsFragment$key
}

export const CartTotalDisplay = ({cartTotalsFragmentRef}: OwnProps) => {


    const cartTotals = useFragment<CartTotalDisplay_CartTotalsFragment$key>(CART_TOTAL_FRAGMENTS, cartTotalsFragmentRef || null)

    const highestRateCount = cartTotals?.monthlyOptions.map(ct => ct.rateCount).sort((a, b) => b - a).find(() => true)
    const cheapestMonthly = cartTotals?.monthlyOptions.find(ct => ct.rateCount === highestRateCount)

    return cartTotals ? <div className="mb-5">
        {cartTotals.totalAppliedDiscountPercentage && <Row>
            <div>Zwischensumme</div>
            <RightColumn><CurrencyDisplay value={cartTotals.totalsWithoutDiscounts.grossPrice}/></RightColumn>
        </Row>}
        {cartTotals.totalAppliedDiscountPercentage && cartTotals.totalAppliedDiscount && <><Row>
            <div>Totaler Rabatt -{cartTotals.totalAppliedDiscountPercentage}%</div>
            <DiscountColumn>-<CurrencyDisplay value={cartTotals.totalAppliedDiscount.grossPrice}/></DiscountColumn>
        </Row>

        </>}
        <TotalRow>
            <div>Gesamtpreis<br/></div>
            <RightColumn>
                <TotalDisplay hasDiscount={(cartTotals.totalAppliedDiscountPercentage || 0) > 0}>
                    <CurrencyDisplay value={cartTotals.totalsIncludingAllDiscounts!.grossPrice}/>
                </TotalDisplay>
                {cheapestMonthly && <>Oder ab <CurrencyDisplay
                    value={cheapestMonthly.monthlyRate.grossPrice}/> monatlich</>}
            </RightColumn>
        </TotalRow>
    </div> : null;
}

const Row = styled.div`
  ${LargeText};
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const TotalRow = styled.div`
  font-weight: bold;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`

interface TotalDisplayProps {
    hasDiscount: boolean
}

const TotalDisplay = styled.div<TotalDisplayProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  };

  ${props => props.hasDiscount && css`
    color: #4ace7f;
  `}

`


const RightColumn = styled.div`
  margin-left: auto;
  text-align: right;
`

const DiscountColumn = styled(RightColumn)`
  color: #ea561c;

`
