import React from "react";
import {ImageWithFallback} from "../../../../../core/components/image/ImageWithFallback";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {ExpertItem_ExpertFragment$key} from "../../../../../../__generated__/ExpertItem_ExpertFragment.graphql";

const EXPERT_ITEM_EXPERT_FRAGMENT = graphql`
    fragment ExpertItem_ExpertFragment on Expert {
        biography
        image {
            url
        }
        name
        position
    }
`

interface OwnProps {
    expertFragmentRef: ExpertItem_ExpertFragment$key;
}

export const ExpertItem = ({expertFragmentRef}: OwnProps) => {

    const expert = useFragment<ExpertItem_ExpertFragment$key>(EXPERT_ITEM_EXPERT_FRAGMENT, expertFragmentRef)

    return <div className="d-flex mb-5 expert-entry">
        <div className="mr-4">
            <ImageWithFallback alt={expert.name} src={expert.image?.url || undefined}/>
        </div>
        <div>
            <div className="tk-academy-large">{expert.name}</div>
            <div
                className="mb-4 tk-academy-text tk-color-waterblue-80 tk-academy-bold">
                {expert.position}
            </div>
            <div className="tk-academy-text tk-color-waterblue-80"
                 dangerouslySetInnerHTML={{__html: expert.biography}}/>
        </div>
    </div>
}
