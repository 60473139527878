import React from "react";
import "./image-element-display.scss";
import {BaseElementDisplay} from "../common/BaseElementDisplay";
import {LightboxImage} from "../../../core/components/image/LightboxImage";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {ImageElementDisplay_ImageElementFragment$key} from "../../../../__generated__/ImageElementDisplay_ImageElementFragment.graphql";

const IMAGE_ELEMENT_DISPLAY_IMAGE_ELEMENT_FRAGMENT = graphql`
    fragment ImageElementDisplay_ImageElementFragment on ImageElement {
        image {
            url
        }
        title
    }`

interface OwnProps {
    imageElementFragmentRef: ImageElementDisplay_ImageElementFragment$key;
}

export const ImageElementDisplay = ({
                                        imageElementFragmentRef
                                    }: OwnProps) => {
    const element = useFragment<ImageElementDisplay_ImageElementFragment$key>(IMAGE_ELEMENT_DISPLAY_IMAGE_ELEMENT_FRAGMENT, imageElementFragmentRef)

    return element.image?.url ? <BaseElementDisplay
        className="image-element-display"
        heading={element.title}
        elementSpecificSlot={
            <LightboxImage image={element.image.url}/>
        }
    /> : null;
}
