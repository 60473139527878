/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PaymentMethod = "card" | "giropay" | "iapApple" | "iapGoogle" | "invoicePartner" | "invoiceTk" | "monthlyPartner" | "monthlyTk" | "paypal" | "sepa" | "sofort" | "%future added value";
export type SelectedPaymentMethodType = "monthly" | "oneTime" | "%future added value";
export type PaymentStepClosed_OrderFragment = {
    readonly selectedPaymentMethod: {
        readonly selectedPaymentMethodType: SelectedPaymentMethodType;
        readonly paymentMethodId: PaymentMethod;
        readonly chosenOption?: {
            readonly rateCount: number;
        };
    } | null;
    readonly " $refType": "PaymentStepClosed_OrderFragment";
};
export type PaymentStepClosed_OrderFragment$data = PaymentStepClosed_OrderFragment;
export type PaymentStepClosed_OrderFragment$key = {
    readonly " $data"?: PaymentStepClosed_OrderFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"PaymentStepClosed_OrderFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PaymentStepClosed_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "selectedPaymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "selectedPaymentMethodType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethodId",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyOption",
              "kind": "LinkedField",
              "name": "chosenOption",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rateCount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "MonthlySelectedPaymentMethod",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
(node as any).hash = '5406251936ca798bcafcad5811a82128';
export default node;
