/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseInfo_CourseStateFragment = {
    readonly " $fragmentRefs": FragmentRefs<"CourseAd_CourseStateFragment" | "CourseAdditionalContentList_CourseStateFragment" | "LicenseAvailable_CourseStateFragment">;
    readonly " $refType": "CourseInfo_CourseStateFragment";
};
export type CourseInfo_CourseStateFragment$data = CourseInfo_CourseStateFragment;
export type CourseInfo_CourseStateFragment$key = {
    readonly " $data"?: CourseInfo_CourseStateFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseInfo_CourseStateFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseInfo_CourseStateFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseAd_CourseStateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseAdditionalContentList_CourseStateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LicenseAvailable_CourseStateFragment"
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};
(node as any).hash = '8fef47e156ce724bdb7c7168f625d833';
export default node;
