/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CartItemType = "bulkDiscountCartItem" | "discountCodeCartItem" | "productCartItem" | "%future added value";
export type CartItem_CartItemFragment = {
    readonly itemType: CartItemType;
    readonly title?: string;
    readonly discountPercentage?: number;
    readonly code?: string;
    readonly validUntil?: string | null;
    readonly eligibleProductCount?: number;
    readonly product?: {
        readonly id: string;
        readonly title: string;
        readonly course?: {
            readonly isIHK: boolean;
            readonly icon: {
                readonly url: string | null;
            } | null;
        } | null;
    };
    readonly totalPrice?: {
        readonly netPrice: number;
        readonly grossPrice: number;
    };
    readonly individualPrice?: {
        readonly netPrice: number;
        readonly grossPrice: number;
    };
    readonly amount?: number;
    readonly " $refType": "CartItem_CartItemFragment";
};
export type CartItem_CartItemFragment$data = CartItem_CartItemFragment;
export type CartItem_CartItemFragment$key = {
    readonly " $data"?: CartItem_CartItemFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CartItem_CartItemFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountPercentage",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "netPrice",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "grossPrice",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CartItem_CartItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "itemType",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "validUntil",
          "storageKey": null
        }
      ],
      "type": "DiscountCodeCartItem",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "eligibleProductCount",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "type": "BulkDiscountCartItem",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "product",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Course",
                  "kind": "LinkedField",
                  "name": "course",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isIHK",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "File",
                      "kind": "LinkedField",
                      "name": "icon",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "url",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "CourseProduct",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceV3",
          "kind": "LinkedField",
          "name": "totalPrice",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceV3",
          "kind": "LinkedField",
          "name": "individualPrice",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        }
      ],
      "type": "ProductCartItem",
      "abstractKey": null
    }
  ],
  "type": "CartItem",
  "abstractKey": "__isCartItem"
};
})();
(node as any).hash = '126db385cd18c88b516c6f373c7851d4';
export default node;
