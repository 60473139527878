import {useEffect, useState} from 'react'

const getViewportDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window

    return {width, height}
}

interface State {
    width: number
    height: number
}

export const useViewportDimensions = () => {
    const [state, setState] = useState<State>(getViewportDimensions());

    const handleResize = () => {
        setState(_ => getViewportDimensions())
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return state
}