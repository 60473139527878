import React from "react";
import "./search-field.scss"

interface OwnProps {
    text?: string;
    textChanged: (newValue: string) => void;
}

export const SearchField = ({
                                text,
                                textChanged
                            }: OwnProps) => {
    return <div className="d-flex align-items-center ml-auto search-field-wrapper position-relative">
        <input placeholder="FAQ durchsuchen" className="ml-auto pl-3 tk-academy-text" type="text" value={text}
               onChange={event => {
                   textChanged(event.target.value)
               }}/>
        <div className="icon-a_glas position-absolute right-20"/>
    </div>;
}
