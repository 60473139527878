import {ProgressBar} from "../../../core/components/progress/ProgressBar";
import React, {ReactNode} from "react";
import {Progress} from "@thekeytechnology/thekey-academy-frontend-library"

interface OwnProps {
    className?: string;
    aboveBarSlot?: ReactNode
    progress: Progress;
    dotIcon?: () => ReactNode;
    dotsAtPercentages?: number[]
}

export const ProgressWithSlots = ({
                                      className,
                                      aboveBarSlot,
                                      progress,
                                      dotIcon,
                                      dotsAtPercentages
                                  }: OwnProps) => {
    return <div className={className}>
        {aboveBarSlot}
        <ProgressBar
            dotIcon={dotIcon}
            dotsAtPercentages={dotsAtPercentages}
            barColor={progress.isDone ? "green" : "blue"}
            percentage100={progress.progressPercentage}/>
    </div>
}