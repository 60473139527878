import React from 'react';
import {useFragment} from 'react-relay';
import {AdditionalContentItem} from "../../../../additional-content/components/AdditionalContentItem";
import {LessonAdditionalContentList_NormalLessonFragment$key} from "../../../../../__generated__/LessonAdditionalContentList_NormalLessonFragment.graphql";
import {LessonAdditionalContentList_CourseStateFragment$key} from "../../../../../__generated__/LessonAdditionalContentList_CourseStateFragment.graphql";
import {graphql} from "babel-plugin-relay/macro";

const LESSON_ADDITIONAL_CONTENT_LIST_COURSE_STATE_FRAGMENT = graphql`
    fragment LessonAdditionalContentList_CourseStateFragment on CourseState {
        ...AdditionalContentItem_CourseStateFragment
    }
`;

const LESSON_ADDITIONAL_CONTENT_LIST_NORMAL_LESSON_FRAGMENT = graphql`
    fragment LessonAdditionalContentList_NormalLessonFragment on NormalLesson {
        additionalContent {
            additionalContent {
                id
            }
            ...AdditionalContentItem_AdditionalContentWithLessonPathFragment
        }
        lessonPath {
            lessonIndex
            moduleIndex
        }
    }
`;

interface OwnProps {
    courseStateFragmentRef: LessonAdditionalContentList_CourseStateFragment$key;
    lessonFragmentRef: LessonAdditionalContentList_NormalLessonFragment$key;
}

export const LessonAdditionalContentList = ({
                                                courseStateFragmentRef,
                                                lessonFragmentRef
                                            }: OwnProps) => {
    const courseState = useFragment<LessonAdditionalContentList_CourseStateFragment$key>(LESSON_ADDITIONAL_CONTENT_LIST_COURSE_STATE_FRAGMENT, courseStateFragmentRef)

    const lesson = useFragment<LessonAdditionalContentList_NormalLessonFragment$key>(LESSON_ADDITIONAL_CONTENT_LIST_NORMAL_LESSON_FRAGMENT, lessonFragmentRef)

    return <div id="additional-content">{lesson.additionalContent.map(aC => <AdditionalContentItem key={aC.additionalContent.id}
                                                                        additionalContentWithLessonPathFragmentRef={aC}
                                                                        courseStateFragmentRef={courseState}/>)}</div>
}