/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NoteLocationHeading_LessonFragment = {
    readonly lessonPath: {
        readonly lessonIndex: number;
        readonly moduleIndex: number;
    };
    readonly " $refType": "NoteLocationHeading_LessonFragment";
};
export type NoteLocationHeading_LessonFragment$data = NoteLocationHeading_LessonFragment;
export type NoteLocationHeading_LessonFragment$key = {
    readonly " $data"?: NoteLocationHeading_LessonFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"NoteLocationHeading_LessonFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NoteLocationHeading_LessonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LessonPath",
      "kind": "LinkedField",
      "name": "lessonPath",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lessonIndex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleIndex",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Lesson",
  "abstractKey": "__isLesson"
};
(node as any).hash = 'ba1a86c74ef831f928c5b7f046d8f80a';
export default node;
