import React from "react";
import {Menu} from "../../../../menu/components/Menu";
import "../../common/course-sidebar/course-sidebar.scss"
import {CourseSidebar} from "../../common/course-sidebar/CourseSidebar";
import {AverageCoursePointsProgressBar} from "../../../../core/components/progress/AverageCoursePointsProgressBar";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {CourseDetailSidebar_CourseFragment$key} from "../../../../../__generated__/CourseDetailSidebar_CourseFragment.graphql";
import {CourseDetailSidebar_CourseStateFragment$key} from "../../../../../__generated__/CourseDetailSidebar_CourseStateFragment.graphql";
import {moduleSubItemFactory} from "./ModuleSubItem";

const COURSE_DETAIL_SIDEBAR_COURSE_FRAGMENT = graphql`
    fragment CourseDetailSidebar_CourseFragment on Course {
        additionalContent {
            additionalContent {
                additionalContent {
                    id
                }
            }
        }
        id
        modules {
            id
            ...ModuleSubItem_ModuleFragment
        }
        ...CourseSidebar_CourseFragment
    }`

const COURSE_DETAIL_SIDEBAR_COURSE_STATE_FRAGMENT = graphql`
    fragment CourseDetailSidebar_CourseStateFragment on CourseState {
        moduleStates {
            moduleRef
            ...ModuleSubItem_ModuleStateFragment
        }
        ...AverageCoursePointsProgressBar_CourseStateFragment
        ...CourseSidebar_CourseStateFragment
    }`

interface OwnProps {
    courseFragmentRef: CourseDetailSidebar_CourseFragment$key;
    courseStateFragmentRef: CourseDetailSidebar_CourseStateFragment$key | null;
    numUnseenCustomerMessages: number;
    newLicensesInAccount: boolean;
}

export const CourseDetailSidebar = ({courseFragmentRef, courseStateFragmentRef, numUnseenCustomerMessages, newLicensesInAccount}: OwnProps) => {

    const course = useFragment<CourseDetailSidebar_CourseFragment$key>(COURSE_DETAIL_SIDEBAR_COURSE_FRAGMENT, courseFragmentRef);

    const courseState = useFragment<CourseDetailSidebar_CourseStateFragment$key>(COURSE_DETAIL_SIDEBAR_COURSE_STATE_FRAGMENT, courseStateFragmentRef);

    const courseHasAdditionalContents = course.additionalContent.length > 0 && course.additionalContent.flatMap(aC => aC.additionalContent).length > 0;

    const subItems = [
        {
            key: "course.overview.info",
            path: `/courses/${course.id}#info`,
            icon: "icon-a_info"
        },
        {
            key: "course.overview.experts",
            path: `/courses/${course.id}#experts`,
            icon: "icon-a_trainer"
        }
    ];
    if (courseHasAdditionalContents) {
        subItems.push({
            key: "course.overview.additional-content",
            path: `/courses/${course.id}#additional-content`,
            icon: "icon-a_material"
        })
    }

    return <CourseSidebar courseFragmentRef={course}
                          courseStateFragmentRef={courseState}
                          numUnseenCustomerMessages={numUnseenCustomerMessages}
                          newLicensesInAccount={newLicensesInAccount}
                          submenuSlot={
        <div className="tk-bg-waterblue flex-grow-1 overflow-auto">
            {courseState ? <div className="pl-3 pr-3 mt-3 mb-3">
                    <AverageCoursePointsProgressBar courseStateFragmentRef={courseState}/>
                </div>
                : null}

            <Menu
                items={[
                    {
                        key: "course.overview.label",
                        path: `/courses/${course.id}`,
                        subItems: [
                            ...subItems,
                        ]
                    },
                    {
                        key: "course.content",
                        path: `/courses/${course.id}/modules`,
                        subItems: course.modules.map(m => {
                            const moduleStateFragmentRef = courseState?.moduleStates.find(mS => mS.moduleRef === m.id) || null;
                            return {
                                key: m.id,
                                path: `/courses/${course.id}/modules/${m.id}`,
                                data: {moduleFragmentRef: m, moduleStateFragmentRef: moduleStateFragmentRef},
                                subItemFactory: moduleSubItemFactory
                            }
                        })
                    },
                    {key: "course.notes", path: `/courses/${course.id}/notes`},
                    {key: "course.qnas", path: `/courses/${course.id}/qnas`},
                    {key: "course.glossary", path: `/courses/${course.id}/glossary`},
                ]}
                rootSizeClass="tk-academy-text"
            />
        </div>
    }/>
}
