/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ApplyLicenseButton_CourseFragment = {
    readonly id: string;
    readonly " $refType": "ApplyLicenseButton_CourseFragment";
};
export type ApplyLicenseButton_CourseFragment$data = ApplyLicenseButton_CourseFragment;
export type ApplyLicenseButton_CourseFragment$key = {
    readonly " $data"?: ApplyLicenseButton_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ApplyLicenseButton_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApplyLicenseButton_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = '950db3d9b1f9a07dd7a5bf61f5629f09';
export default node;
