import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {CodeRefusalExplanation_RefusalsFragment$key} from "../../../../../__generated__/CodeRefusalExplanation_RefusalsFragment.graphql";
import React from "react";
import {useTranslation} from "react-i18next";

interface OwnProps {
    codeRefusalsFragmentRef: CodeRefusalExplanation_RefusalsFragment$key | null
}

export const CodeRefusalExplanation = ({codeRefusalsFragmentRef}: OwnProps) => {
    const {t} = useTranslation("billing")

    const refusals = useFragment<CodeRefusalExplanation_RefusalsFragment$key>(graphql`
        fragment CodeRefusalExplanation_RefusalsFragment on Cart {
            discountCodeRefusals {
                code
                reason
                title
            }
        }
    `, codeRefusalsFragmentRef)

    return <div>
        {refusals?.discountCodeRefusals.map(refusal => {
            return <div className="alert alert-warning">
                Der Code {refusal.code} für das Angebot {refusal.title} kann nicht verwenden
                werden: {t("discount-code.refusal-reason." + refusal.reason)}
            </div>
        })}
    </div>
}
