import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./order-list.scss"
import {CurrencyDisplay} from "../../core/components/currency/CurrencyDisplay";
import {useTranslation} from "react-i18next";
import {DateTimeDisplay} from "../../core/components/display/DateTimeDisplay";
import {
    fetchOrderOverviewsV2Action,
    fetchReceiptActionV2,
    selectOrderOverviewsV2
} from "@thekeytechnology/thekey-academy-frontend-library";
import {graphql} from "babel-plugin-relay/macro";
import {useLazyLoadQuery} from "react-relay";
import {OrderList_Query} from "../../../__generated__/OrderList_Query.graphql";


const QUERY = graphql`
    query OrderList_Query {
        Viewer {
            Billing {
                Orders {
                    edges {
                        node {
                            createdAt
                            id

                            selectedPaymentMethod {
                                paymentMethodId
                                ... on MonthlySelectedPaymentMethod {
                                    chosenOption {
                                        totalAmount {
                                            grossPrice
                                        }
                                    }
                                }
                            }
                            cart {
                                cartTotals {
                                    totalsIncludingAllDiscounts {
                                        grossPrice
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export const OrderList = () => {
    const {t} = useTranslation("billing")
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchOrderOverviewsV2Action())
    }, [dispatch])

    const orderOverviewsV2 = useSelector(selectOrderOverviewsV2)
    const orderOverviewV3 = useLazyLoadQuery<OrderList_Query>(QUERY, {})

    return <div className="order-list">
        <h2 className="tk-academy-larger-bold mb-3 tk-color-darkblue mt-5">
            {t("order-list.heading")}
        </h2>

        {orderOverviewsV2?.map(overview => {
            return <div key={overview.orderId} className="order tk-academy-card mb-3 p-3">
                <div>
                    <DateTimeDisplay dateTime={overview.dateTime}/>
                </div>
                <div>
                    <strong>
                        <button
                            className="btn-link"
                            onClick={() => dispatch(fetchReceiptActionV2(overview.orderId))}>
                            {overview.courseTitle}
                        </button>
                    </strong>
                </div>
                <div>
                    <CurrencyDisplay value={overview.amountPaid}/>
                </div>
                <div>
                    {t("payment-methods." + (overview.paymentMethod ? overview.paymentMethod : "unknown"))}
                </div>
            </div>
        })}
        {orderOverviewV3.Viewer.Billing.Orders.edges?.map(e => e!.node!).map(overview => {
            return <div key={overview.id} className="order tk-academy-card mb-3 p-3">
                <div>
                    <DateTimeDisplay dateTime={overview.createdAt}/>
                </div>
                <div>
                    <strong>
                        {/*<DownloadButton orderId={overview.id}/>*/}
                    </strong>
                </div>
                <div>
                    <CurrencyDisplay value={overview.selectedPaymentMethod?.chosenOption ?
                        overview.selectedPaymentMethod.chosenOption.totalAmount.grossPrice! :
                        overview.cart.cartTotals.totalsIncludingAllDiscounts?.grossPrice!
                    }/>
                </div>
                <div>
                    {t("payment-methods." + (overview.selectedPaymentMethod?.paymentMethodId ? overview.selectedPaymentMethod?.paymentMethodId : "unknown"))}
                </div>
            </div>
        })}
    </div>
}
