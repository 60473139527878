/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type QnasScreen_QueryVariables = {
    courseId: string;
    elementId?: string | null;
    lessonId?: string | null;
};
export type QnasScreen_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"QnasScreen_QueryFragment">;
};
export type QnasScreen_Query = {
    readonly response: QnasScreen_QueryResponse;
    readonly variables: QnasScreen_QueryVariables;
};



/*
query QnasScreen_Query(
  $courseId: ID!
  $elementId: ID
  $lessonId: ID
) {
  ...QnasScreen_QueryFragment_4onWJQ
}

fragment QnaItem_QnaFragment on Qna {
  answer
  id
  isFromCurrentUser
  isPublic
  question
}

fragment QnasScreen_QueryFragment_4onWJQ on Query {
  Qnas {
    Qnas(courseId: $courseId, lessonId: $lessonId, elementId: $elementId) {
      edges {
        node {
          id
          ...QnaItem_QnaFragment
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "courseId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "elementId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lessonId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "courseId",
    "variableName": "courseId"
  },
  {
    "kind": "Variable",
    "name": "elementId",
    "variableName": "elementId"
  },
  {
    "kind": "Variable",
    "name": "lessonId",
    "variableName": "lessonId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QnasScreen_Query",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "QnasScreen_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QnasScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "QnasQueries",
        "kind": "LinkedField",
        "name": "Qnas",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "QnasConnection",
            "kind": "LinkedField",
            "name": "Qnas",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "QnasEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Qna",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "answer",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isFromCurrentUser",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isPublic",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "question",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "filters": [
              "courseId",
              "lessonId",
              "elementId"
            ],
            "handle": "connection",
            "key": "QnasScreen_Query_Qnas",
            "kind": "LinkedHandle",
            "name": "Qnas"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a179b9d967e7273511e49e9c1a7f0905",
    "id": null,
    "metadata": {},
    "name": "QnasScreen_Query",
    "operationKind": "query",
    "text": "query QnasScreen_Query(\n  $courseId: ID!\n  $elementId: ID\n  $lessonId: ID\n) {\n  ...QnasScreen_QueryFragment_4onWJQ\n}\n\nfragment QnaItem_QnaFragment on Qna {\n  answer\n  id\n  isFromCurrentUser\n  isPublic\n  question\n}\n\nfragment QnasScreen_QueryFragment_4onWJQ on Query {\n  Qnas {\n    Qnas(courseId: $courseId, lessonId: $lessonId, elementId: $elementId) {\n      edges {\n        node {\n          id\n          ...QnaItem_QnaFragment\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f992ebcc8b1318459c8a1484d053ab7f';
export default node;
