/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NextLessonsSlider_CoursesConnectionFragment = {
    readonly edges: ReadonlyArray<{
        readonly node: {
            readonly id: string;
            readonly lessons: ReadonlyArray<{
                readonly id: string;
                readonly lessonPath: {
                    readonly courseRef: string;
                };
                readonly " $fragmentRefs": FragmentRefs<"NextLessonCard_LessonFragment">;
            }>;
            readonly " $fragmentRefs": FragmentRefs<"NextLessonCard_CourseFragment">;
        };
    } | null> | null;
    readonly " $refType": "NextLessonsSlider_CoursesConnectionFragment";
};
export type NextLessonsSlider_CoursesConnectionFragment$data = NextLessonsSlider_CoursesConnectionFragment;
export type NextLessonsSlider_CoursesConnectionFragment$key = {
    readonly " $data"?: NextLessonsSlider_CoursesConnectionFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"NextLessonsSlider_CoursesConnectionFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NextLessonsSlider_CoursesConnectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CoursesEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Course",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "lessons",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LessonPath",
                  "kind": "LinkedField",
                  "name": "lessonPath",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "courseRef",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "NextLessonCard_LessonFragment"
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NextLessonCard_CourseFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CoursesConnection",
  "abstractKey": null
};
})();
(node as any).hash = '2c2bf1149749c521d487f86c21b77eb4';
export default node;
