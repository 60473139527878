/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type VideoElementDisplay_VideoElementFragment = {
    readonly highDefVideoFile: {
        readonly rawId: string;
        readonly url: string | null;
    } | null;
    readonly id: string;
    readonly title: string;
    readonly videoData: {
        readonly videoId: string;
    };
    readonly videoFile: {
        readonly url: string | null;
    } | null;
    readonly " $refType": "VideoElementDisplay_VideoElementFragment";
};
export type VideoElementDisplay_VideoElementFragment$data = VideoElementDisplay_VideoElementFragment;
export type VideoElementDisplay_VideoElementFragment$key = {
    readonly " $data"?: VideoElementDisplay_VideoElementFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"VideoElementDisplay_VideoElementFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VideoElementDisplay_VideoElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "highDefVideoFile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rawId",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VideoData",
      "kind": "LinkedField",
      "name": "videoData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "videoId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "videoFile",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "VideoElement",
  "abstractKey": null
};
})();
(node as any).hash = '6eed1a0c18becc1a93fb6ae259eaf0c3';
export default node;
