import {NavLink} from "react-router-dom";
import React, {useState} from "react";
import {MenuItem} from "./Menu";
import {useTranslation} from "react-i18next";
import {onlyLinkSubitemFactory} from "./OnlyLinkSubitem";

interface OwnProps {
    collapsed?: boolean;
    route: MenuItem;
    rootSizeClass?: string;
    showBadge?: boolean,
    badgeContent?: String
}

export const MenuItemDisplay = ({
                                    collapsed,
                                    route,
                                    rootSizeClass,
                                    badgeContent,
                                    showBadge
                                }: OwnProps) => {
    const {t} = useTranslation("menu");

    const [isOpen, setOpen] = useState(true);

    const Label = <div className={collapsed ? "text-center" : "flex-grow-1"}>
        {route.icon && <span
            className={"tk-academy-bold icon text-center " + route.icon + (!collapsed ? " mr-3" : " ")}/>}
        {
            !collapsed &&
            <span className="tk-academy-bold">
            {t(`routes.${route.key}`)}
                {showBadge ?
                    <span className="badge badge-danger ml-2">{badgeContent}</span> :
                    null
                }
        </span>
        }
    </div>

    const hasSubitems = route.subItems?.length;
    const Link = hasSubitems ? <button

        onClick={() => setOpen(!isOpen)}
        className={(rootSizeClass ? rootSizeClass : "") + " btn-link d-flex flex-row tk-white-on-hover tk-key-blue-30 d-flex align-items-center"}
    >
        {Label}
        <span
            className={"ml-2 tk-academy-large-text tk-academy-bold tk-key-blue-30 " + (isOpen ? " icon-a_arrow-up" : " icon-a_arrow-down")}/>
    </button> : <NavLink key={route.key} to={route.path}
                         exact
                         activeClassName={"tk-white"}
                         className={(rootSizeClass ? rootSizeClass : "") + " tk-key-blue-30 "}>
        {Label}
    </NavLink>;

    return <li
        className={`d-flex m-0 pt-3 pb-3 d-flex root-item ${collapsed ? "justify-content-center flex-column" : "ml-4 mr-4"} ${hasSubitems ? ("flex-column tk-key-blue-30") : ""} ${hasSubitems && isOpen ? "has-subitems" : ""}`}
    >
        {Link}

        {route.subItems && route.subItems.length && isOpen ?
            <ul className="p-0 mt-2">
                {route.subItems.map(subRoute => {
                    const factory = subRoute.subItemFactory ? subRoute.subItemFactory : onlyLinkSubitemFactory;
                    return factory(subRoute, collapsed)
                })}
            </ul> : null}
    </li>
}
