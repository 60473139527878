import {ApiCallSucceededAction, matchesApiSuccess} from "@thekeytechnology/framework-react";
import {combineEpics} from "redux-observable";
import {Observable} from "rxjs";
import {Action} from "redux";
import {map} from "rxjs/operators";
import {push} from "connected-react-router";
import {API_ENSURE_ORDER} from "@thekeytechnology/thekey-academy-frontend-library";

const redirectOnOrderEnsuring$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_ENSURE_ORDER),
    map((action: ApiCallSucceededAction<any>) => {
        return push("/checkout/orders-v2/" + action.payload.id)
    })
);


export const additionalBillingModuleV2Epics$ = combineEpics(
    redirectOnOrderEnsuring$,
);
