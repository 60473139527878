/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NextLessonCard_CourseStateFragment = {
    readonly isBought: boolean;
    readonly isCanOrderCertificate: boolean;
    readonly isCertificateOrdered: boolean;
    readonly " $fragmentRefs": FragmentRefs<"UpperCourseCard_CourseStateFragment">;
    readonly " $refType": "NextLessonCard_CourseStateFragment";
};
export type NextLessonCard_CourseStateFragment$data = NextLessonCard_CourseStateFragment;
export type NextLessonCard_CourseStateFragment$key = {
    readonly " $data"?: NextLessonCard_CourseStateFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"NextLessonCard_CourseStateFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NextLessonCard_CourseStateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBought",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCanOrderCertificate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCertificateOrdered",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpperCourseCard_CourseStateFragment"
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};
(node as any).hash = '0c6c1cdbc4df9a3c5477a08148555a3c';
export default node;
