import {PointsDisplay} from "../../../lesson/components/PointsDisplay";
import {NavLink} from "react-router-dom";
import React from "react";
import {
    calculateAwardablePointsV2,
    elementTypeGraphQLToElementType
} from "@thekeytechnology/thekey-academy-frontend-library";
import {FeedbackButton} from "../../../feedback/controllers/FeedbackButton";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {LearnHeader_ElementFragment$key} from "../../../../__generated__/LearnHeader_ElementFragment.graphql";
import {LearnHeader_LessonFragment$key} from "../../../../__generated__/LearnHeader_LessonFragment.graphql";
import {ElementTry} from "@thekeytechnology/thekey-academy-frontend-library/src/elementflow/reducers/validators/element-try";

const LEARN_HEADER_ELEMENT_FRAGMENT = graphql`
    fragment LearnHeader_ElementFragment on Element {
        elementType
        generalSettings {
            points
        }
        id
    }
`

const LEARN_HEADER_LESSON_FRAGMENT = graphql`
    fragment LearnHeader_LessonFragment on Lesson {
        id
        lessonPath {
            courseRef
            moduleRef
        }
    }
`

interface OwnProps {
    triesNeeded?: { [elementId: string]: ElementTry<any>[] };
    elementFragmentRef?: LearnHeader_ElementFragment$key | null;
    lessonFragmentRef: LearnHeader_LessonFragment$key;
}

export const LearnHeader = ({triesNeeded, elementFragmentRef, lessonFragmentRef}: OwnProps) => {
    const element = useFragment<LearnHeader_ElementFragment$key>(LEARN_HEADER_ELEMENT_FRAGMENT, elementFragmentRef || null)

    const lesson = useFragment<LearnHeader_LessonFragment$key>(LEARN_HEADER_LESSON_FRAGMENT, lessonFragmentRef)

    const numberOfTries = element?.id && triesNeeded ? triesNeeded[element.id]?.length || 0 : 0;

    const awardablePoints = element ? calculateAwardablePointsV2(elementTypeGraphQLToElementType(element.elementType), element.generalSettings.points, numberOfTries + 1) : undefined;

    return <div className="ml-auto d-flex align-items-center">
        <FeedbackButton className="mr-2" courseId={lesson.lessonPath.courseRef} lessonId={lesson.id}
                        elementId={element?.id} screen={"learn-screen"}/>
        <PointsDisplay points={awardablePoints!}/>
        <NavLink
            className="tk-academy-larger-bold tk-white ml -3 d-flex"
            to={`/courses/${lesson.lessonPath.courseRef}/modules/${lesson.lessonPath.moduleRef}/lessons/${lesson.id}`}>
            <span className="icon-a_close"/>
        </NavLink>
    </div>;
}
