import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {DialogResult, logoutAction, showDialogAction} from "@thekeytechnology/framework-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";


const ANONYMIZE_MUTATION = graphql`
    mutation AnonymizeMyselfButton_AnonymizeMutation {
        Viewer {
            User {
                anonymizeMyself(input: {}){
                    clientMutationId
                }
            }
        }
    }
`


export const AnonymizeMyselfButton = () => {
    const {t} = useTranslation("manager")
    const dispatch = useDispatch();

    const [anonymizeMyself, isAnonymizing] = useMutation(ANONYMIZE_MUTATION)


    return <button
        className="btn btn-danger"
        disabled={isAnonymizing}
        onClick={() => {
            dispatch(showDialogAction({
                title: t("manager-main-page.anonymize-myself.title"),
                question: t("manager-main-page.anonymize-myself.question"),
                explanation: t("manager-main-page.anonymize-myself.explanation"),
                affirmativeText: t("manager-main-page.anonymize-myself.affirmativeText"),
                negativeText: t("manager-main-page.anonymize-myself.negativeText"),
                affirmativeButtonClass: "btn btn-danger"
            }, result => {
                if (result === DialogResult.ACCEPT) {
                    anonymizeMyself({
                        variables: {},
                        onCompleted: () => {
                            dispatch(logoutAction())
                        }
                    })
                }
            }))
        }}>
        {t("manager-main-page.anonymize-myself-button")}
    </button>

}
