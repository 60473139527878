/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BillingDetailsStep_UserFragment = {
    readonly id: string;
    readonly meta: {
        readonly firstName: string;
        readonly lastName: string;
    } | null;
    readonly __typename: "User";
    readonly " $fragmentRefs": FragmentRefs<"BillingDetailsFormV3_UserFragment">;
    readonly " $refType": "BillingDetailsStep_UserFragment";
};
export type BillingDetailsStep_UserFragment$data = BillingDetailsStep_UserFragment;
export type BillingDetailsStep_UserFragment$key = {
    readonly " $data"?: BillingDetailsStep_UserFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"BillingDetailsStep_UserFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingDetailsStep_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserMeta",
      "kind": "LinkedField",
      "name": "meta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingDetailsFormV3_UserFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '84142ff72bbac89d39272a033be7a259';
export default node;
