import React, {Suspense} from "react";
import {LessonDetailScreen} from "../lesson/components/LessonDetailScreen";
import {CourseDetailScreen} from "./components/detail/CourseDetailScreen";
import {DashboardScreen} from "./components/dashboard/DashboardScreen";
import {LoadingFakeDashboardScreen} from "../core/loading/dashboard/LoadingFakeDashboardScreen";
import {LoadingFakeDetailScreen} from "../core/loading/detail/LoadingFakeDetailScreen";
import {RouteAuthState, RouteDefinition} from "../core/components/routing/RouteDefinition";

export const CourseRouteDefinitions: RouteDefinition[] = [
    {
        path: "/",
        component: () => <Suspense fallback={<LoadingFakeDashboardScreen/>}>
            <DashboardScreen/>
        </Suspense>,
        authState: RouteAuthState.OnlyLoggedIn
    },
    {
        path: "/courses/:courseId/modules/:moduleId/lessons/:lessonId",
        component: () => <Suspense fallback={<LoadingFakeDetailScreen/>}>
            <LessonDetailScreen/>
        </Suspense>,
        authState: RouteAuthState.OnlyLoggedIn
    },
    {
        path: "/courses/:courseId/modules/:moduleId/lessons/:lessonId/notes",
        component: () => <Suspense fallback={<LoadingFakeDetailScreen/>}>
            <LessonDetailScreen/>
        </Suspense>,
        authState: RouteAuthState.OnlyLoggedIn
    },
    {
        path: "/courses/:courseId/modules/:moduleId/lessons/:lessonId/qnas",
        component: () => <Suspense fallback={<LoadingFakeDetailScreen/>}>
            <LessonDetailScreen/>
        </Suspense>,
        authState: RouteAuthState.OnlyLoggedIn
    },
    {
        path: "/courses/:courseId/modules/:moduleId/lessons/:lessonId/glossary",
        component: () => <Suspense fallback={<LoadingFakeDetailScreen/>}>
            <LessonDetailScreen/>
        </Suspense>,
        authState: RouteAuthState.OnlyLoggedIn
    },
    {
        path: "/courses/:courseId",
        component: () => <Suspense fallback={<LoadingFakeDetailScreen/>}>
            <CourseDetailScreen/>
        </Suspense>,
        authState: RouteAuthState.OnlyLoggedIn
    },
    {
        path: "/courses/:courseId/modules/:moduleId",
        component: () => <Suspense fallback={<LoadingFakeDetailScreen/>}>
            <CourseDetailScreen/>
        </Suspense>,
        authState: RouteAuthState.OnlyLoggedIn
    },
    {
        path: "/courses/:courseId/notes",
        component: () => <Suspense fallback={<LoadingFakeDetailScreen/>}>
            <CourseDetailScreen/>
        </Suspense>,
        authState: RouteAuthState.OnlyLoggedIn
    },
    {
        path: "/courses/:courseId/qnas",
        component: () => <Suspense fallback={<LoadingFakeDetailScreen/>}>
            <CourseDetailScreen/>
        </Suspense>,
        authState: RouteAuthState.OnlyLoggedIn
    },
    {
        path: "/courses/:courseId/glossary",
        component: () => <Suspense fallback={<LoadingFakeDetailScreen/>}>
            <CourseDetailScreen/>
        </Suspense>,
        authState: RouteAuthState.OnlyLoggedIn
    }
]