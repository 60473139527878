/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type GlossaryItem_GlossaryEntryFragment = {
    readonly additionalLinks: ReadonlyArray<string>;
    readonly definition: string;
    readonly title: string;
    readonly " $refType": "GlossaryItem_GlossaryEntryFragment";
};
export type GlossaryItem_GlossaryEntryFragment$data = GlossaryItem_GlossaryEntryFragment;
export type GlossaryItem_GlossaryEntryFragment$key = {
    readonly " $data"?: GlossaryItem_GlossaryEntryFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"GlossaryItem_GlossaryEntryFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GlossaryItem_GlossaryEntryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalLinks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "definition",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "GlossaryEntry",
  "abstractKey": null
};
(node as any).hash = '5aef709a572916b115e1a1225312b047';
export default node;
