/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ExtendedRegistrationInputInput = {
    token: string;
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    companySize?: string | null;
    industry?: string | null;
    position?: string | null;
    adsOptIn?: boolean | null;
    clientMutationId?: string | null;
};
export type InvitationScreen_SignUpWithInvitationMutationVariables = {
    input: ExtendedRegistrationInputInput;
};
export type InvitationScreen_SignUpWithInvitationMutationResponse = {
    readonly Manager: {
        readonly signUpWithInvitation: {
            readonly email: string;
            readonly firstName: string;
            readonly lastName: string;
            readonly userId: string;
        } | null;
    };
};
export type InvitationScreen_SignUpWithInvitationMutation = {
    readonly response: InvitationScreen_SignUpWithInvitationMutationResponse;
    readonly variables: InvitationScreen_SignUpWithInvitationMutationVariables;
};



/*
mutation InvitationScreen_SignUpWithInvitationMutation(
  $input: ExtendedRegistrationInputInput!
) {
  Manager {
    signUpWithInvitation(input: $input) {
      email
      firstName
      lastName
      userId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ManagerMutations",
    "kind": "LinkedField",
    "name": "Manager",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "ExtendedRegistrationInputPayload",
        "kind": "LinkedField",
        "name": "signUpWithInvitation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvitationScreen_SignUpWithInvitationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvitationScreen_SignUpWithInvitationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c1a735d301d6e10cc1051f76cb973219",
    "id": null,
    "metadata": {},
    "name": "InvitationScreen_SignUpWithInvitationMutation",
    "operationKind": "mutation",
    "text": "mutation InvitationScreen_SignUpWithInvitationMutation(\n  $input: ExtendedRegistrationInputInput!\n) {\n  Manager {\n    signUpWithInvitation(input: $input) {\n      email\n      firstName\n      lastName\n      userId\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'bc50cca64a75d3ebd71d8227e1dd8298';
export default node;
