/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LessonType = "normal" | "test" | "%future added value";
export type LessonDetailFooter_LessonFragment = {
    readonly lessonPath: {
        readonly courseRef: string;
        readonly moduleRef: string;
    };
    readonly lessonType: LessonType;
    readonly id: string;
    readonly isCertificationTest?: boolean;
    readonly " $refType": "LessonDetailFooter_LessonFragment";
};
export type LessonDetailFooter_LessonFragment$data = LessonDetailFooter_LessonFragment;
export type LessonDetailFooter_LessonFragment$key = {
    readonly " $data"?: LessonDetailFooter_LessonFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LessonDetailFooter_LessonFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonDetailFooter_LessonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LessonPath",
      "kind": "LinkedField",
      "name": "lessonPath",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "courseRef",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleRef",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lessonType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCertificationTest",
          "storageKey": null
        }
      ],
      "type": "TestLesson",
      "abstractKey": null
    }
  ],
  "type": "Lesson",
  "abstractKey": "__isLesson"
};
(node as any).hash = 'fc5b5b6e8390048d5c93ff36330e7997';
export default node;
