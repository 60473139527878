import React, { useState } from "react";
import Lightbox from "react-image-lightbox";

interface OwnProps {
    className?: string;
    image: string;
}

export const LightboxImage = ({className, image}: OwnProps) => {

    const [isOpen, setOpen] = useState(false);

    return <>
        <img style={{cursor: "pointer"}}
             onClick={() => setOpen(true)}
             className={className}
             alt={""}
             src={image}/>
        {isOpen && (
            <Lightbox
                mainSrc={image}
                onCloseRequest={() => setOpen(false)}
            />
        )}
    </>
}
