import React from "react";
import "./../checkout/cart/cart-contents.scss"
import {CartTable} from "../checkout/cart/table/CartTable";
import {ShowOnePriceRenderer, ShowOptionsPriceRenderer} from "../checkout/cart/table/PriceRenderer";
import {
    OrderContainer,
    PaymentMethodIds,
    paymentMethodIsMonthly
} from "@thekeytechnology/thekey-academy-frontend-library";
import {CartTotalRow} from "../checkout/cart/table/CartTotalRow";
import {PriceDisplay} from "../checkout/cart/price/PriceDisplay";
import {RatesRow} from "../checkout/cart/table/RatesRow";

interface OwnProps {
    order?: OrderContainer
    selectedPaymentMethod?: PaymentMethodIds
}

export const OrderCart = ({
                              order,
                              selectedPaymentMethod
                          }: OwnProps) => {

    if (!order) {
        return null;
    }

    const monthlyPossible = order.order.entity.allowedPaymentMethods.find(pm => paymentMethodIsMonthly(pm)) !== undefined
    const isMonthly = paymentMethodIsMonthly(selectedPaymentMethod)

    return <div className="cart-contents-v2 tk-academy-card p-3 mb-3">
        <div className="d-flex align-items-center">
            <div
                className="tk-academy-large tk-color-waterblue-80 tk-academy-bold">
                Angebot {order.baseOffer.entity.title}
            </div>
        </div>
        <hr className="mt-3 mb-3"/>
        <CartTable priceTable={order.order.entity.priceTable}
                   referredCourses={order.referredCourses}
                   priceRenderer={((priceExplanation, monthlyPriceExplanation, isDiscount) =>
                       selectedPaymentMethod ?
                           (isMonthly && !monthlyPriceExplanation ? null : <ShowOnePriceRenderer
                               priceExplanation={priceExplanation}
                               monthlyPriceExplanation={monthlyPriceExplanation}
                               showNetPrices={order?.baseOffer.entity.showNetPrices}
                               showMonthly={isMonthly}
                               isDiscount={isDiscount}
                           />) :
                           <ShowOptionsPriceRenderer
                               priceExplanation={priceExplanation}
                               monthlyPriceExplanation={monthlyPriceExplanation}
                               showNetPrices={order?.baseOffer.entity.showNetPrices}
                               showMonthly={monthlyPossible}
                               isDiscount={isDiscount}
                           />)}
        />
        {selectedPaymentMethod && isMonthly && order.order.entity.priceTable.monthlyTotal ?
            <>
                <hr/>
                <RatesRow showNetPrices={order?.baseOffer.entity.showNetPrices}
                          priceTable={order.order.entity.priceTable}/>
            </>
            : null}


        <hr/>
        <CartTotalRow
            priceRenderer={((priceExplanation, monthlyPriceExplanation) =>
                selectedPaymentMethod ?
                    <div className="price-renderer show-one-price-renderer">
                        <div className="price">
                            <PriceDisplay
                                priceExplanation={
                                    {
                                        taxRate: order?.order.entity.priceTable.total.taxRate,
                                        hasDiscount: isMonthly && order.order.entity.priceTable.monthlyTotal ? order.order.entity.priceTable.monthlyTotal.discountedSumGross !== order.order.entity.priceTable.monthlyTotal.sumGross : order.order.entity.priceTable.total.hasDiscount,
                                        netAmount: isMonthly && order.order.entity.priceTable.monthlyTotal ? order.order.entity.priceTable.monthlyTotal.sumNet : order.order.entity.priceTable.total.netAmount,
                                        discountedNetAmount: isMonthly && order.order.entity.priceTable.monthlyTotal ? order.order.entity.priceTable.monthlyTotal.discountedSumNet : order.order.entity.priceTable.total.discountedNetAmount,
                                        grossAmount: isMonthly && order.order.entity.priceTable.monthlyTotal ? order.order.entity.priceTable.monthlyTotal.sumGross : order.order.entity.priceTable.total.grossAmount,
                                        discountedGrossAmount: isMonthly && order.order.entity.priceTable.monthlyTotal ? order.order.entity.priceTable.monthlyTotal.discountedSumGross : order.order.entity.priceTable.total.discountedGrossAmount,
                                    }
                                }/>
                        </div>
                    </div> : <ShowOptionsPriceRenderer
                        priceExplanation={priceExplanation}
                        monthlyPriceExplanation={monthlyPriceExplanation}
                        showNetPrices={order?.baseOffer.entity.showNetPrices}
                        showMonthly={monthlyPossible}
                    />)}

            priceTable={order.order.entity.priceTable}
            showNetPrices={order?.baseOffer.entity.showNetPrices}
            monthlyPossible={monthlyPossible}/>

    </div>;
}
