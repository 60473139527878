import React, {ReactNode} from "react"
import "./progress-bar.scss"

export type BarColors = "blue" | "green"
export const BAR_COLOR_BLUE: BarColors = "blue"

interface OwnProps {
    percentage100: number;
    showPercentage?: boolean;
    barColor?: BarColors,
    whiteBackground?: boolean;
    dotIcon?: () => ReactNode
    dotsAtPercentages?: number[];
}

export const ProgressBar = ({
                                percentage100,
                                showPercentage,
                                barColor,
                                whiteBackground,
                                dotIcon,
                                dotsAtPercentages
                            }: OwnProps) => {

    let textColorClass;
    let barColorClass;
    let backgroundColor;

    switch (barColor) {
        case "blue":
            textColorClass = "tk-color-waterblue-80"
            barColorClass = "tk-bg-waterblue-80"
            backgroundColor = "tk-bg-keyblue-20"
            break;
        case "green":
            textColorClass = "tk-color-keygreen"
            barColorClass = "tk-bg-keygreen"
            backgroundColor = "tk-bg-keygreen"
            break;
        default:
            textColorClass = "tk-color-waterblue-80"
            barColorClass = "tk-bg-waterblue-80"
            backgroundColor = "tk-bg-keyblue-20"
    }

    backgroundColor = whiteBackground ? "tk-bg-white" : backgroundColor;

    return <div className="d-flex align-items-center">
        <div className={"tk-progress-bar " + backgroundColor}>
            <div className={`inner-bar ${barColorClass}`} style={{
                width: percentage100 + "%",
            }}/>
            {dotsAtPercentages ? dotsAtPercentages.map((dot, index) => {
                const isActive = dot < percentage100;
                const isCurrent = dot === percentage100;

                return <div className="dot" style={{
                    left: dot + "%",
                }} key={index}>
                    {dotIcon ? dotIcon() : <div className={
                        isActive ? "innerDotActive" :
                            isCurrent ? "innerDotCurrent" :
                                "innerDotInactive"
                    }>
                    </div>}
                </div>
            }) : null}
        </div>
        {showPercentage ?
            <div className={`ml-2 tk-academy-small-text ${textColorClass}`}>
                {percentage100}%
            </div> : null}
    </div>
}
