import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_APPLY_LICENSE_FOR_USER = "apply-license-for-user";

interface ApplyLicensePayload {
    courseId: string
    userId: string
    licenseId?: string
}

export const applyLicenseAction = (userId: string, courseId: string, licenseId?: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_APPLY_LICENSE_FOR_USER,
            callType: API_APPLY_LICENSE_FOR_USER
        },
        payload: {
            licenseId,
            userId,
            courseId
        }
    } as ApiCallAction<ApplyLicensePayload>;
};
