import React from "react";
import {AccountManager} from "./components/AccountManager";
import {RouteAuthState, RouteDefinition} from "../core/components/routing/RouteDefinition";

export const ManagerRouteDefinitions: RouteDefinition[] = [
    {
        path: "/manager",
        component: () => <AccountManager/>,
        authState: RouteAuthState.OnlyLoggedIn
    }
]