import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_REMOVE_USER_FROM_ACCOUNT = "remove-user-from-account";

export const removeUserFromAccountAction = (userId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_REMOVE_USER_FROM_ACCOUNT,
            callType: API_REMOVE_USER_FROM_ACCOUNT
        },
        payload: userId
    } as ApiCallAction<string>;
};
