/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LicensesInAccount_ApplyLicenseMutationVariables = {
    courseId: string;
    licensePoolId: string;
    userId?: string | null;
    licenseIdOpt?: string | null;
};
export type LicensesInAccount_ApplyLicenseMutationResponse = {
    readonly License: {
        readonly applyLicense: {
            readonly licensesInAccount: {
                readonly id: string;
                readonly licenses: ReadonlyArray<{
                    readonly usageData: {
                        readonly usedByUser: string;
                        readonly usedAt: string;
                        readonly usedForCourse: string;
                    } | null;
                }>;
                readonly licenseRequests: ReadonlyArray<{
                    readonly rawId: string;
                    readonly courseRef: string;
                    readonly id: string;
                    readonly userRef: string;
                    readonly createdAt: string;
                }>;
                readonly " $fragmentRefs": FragmentRefs<"AssignLicenseButton_LicensesInAccountContainerFragment">;
            };
            readonly courseState: {
                readonly node: {
                    readonly id: string;
                };
            };
        } | null;
    };
};
export type LicensesInAccount_ApplyLicenseMutation = {
    readonly response: LicensesInAccount_ApplyLicenseMutationResponse;
    readonly variables: LicensesInAccount_ApplyLicenseMutationVariables;
};



/*
mutation LicensesInAccount_ApplyLicenseMutation(
  $courseId: ID!
  $licensePoolId: ID!
  $userId: ID
  $licenseIdOpt: ID
) {
  License {
    applyLicense(input: {courseId: $courseId, licensePoolId: $licensePoolId, userId: $userId, licenseIdOpt: $licenseIdOpt}) {
      licensesInAccount {
        id
        licenses {
          usageData {
            usedByUser
            usedAt
            usedForCourse
          }
          id
        }
        licenseRequests {
          rawId
          courseRef
          id
          userRef
          createdAt
        }
        ...AssignLicenseButton_LicensesInAccountContainerFragment
      }
      courseState {
        node {
          id
        }
      }
    }
  }
}

fragment AssignLicenseButton_LicensesInAccountContainerFragment on LicenseInAccountContainer {
  id
  courseLocksByUser {
    user
    coursesToUnlock {
      courseId
      title
    }
    coursesUnlocked {
      title
    }
  }
  licenses {
    limitedToCoursesRef
    id
  }
  courses {
    id
    containerMeta {
      title
    }
  }
  usersInAccount {
    id
    name
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "courseId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "licenseIdOpt"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "licensePoolId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v4 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "courseId",
        "variableName": "courseId"
      },
      {
        "kind": "Variable",
        "name": "licenseIdOpt",
        "variableName": "licenseIdOpt"
      },
      {
        "kind": "Variable",
        "name": "licensePoolId",
        "variableName": "licensePoolId"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "UsageData",
  "kind": "LinkedField",
  "name": "usageData",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usedByUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usedForCourse",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "LicenseRequest",
  "kind": "LinkedField",
  "name": "licenseRequests",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rawId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "courseRef",
      "storageKey": null
    },
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userRef",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "CourseStatesEdge",
  "kind": "LinkedField",
  "name": "courseState",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CourseState",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v5/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v10 = [
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LicensesInAccount_ApplyLicenseMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LicenseMutations",
        "kind": "LinkedField",
        "name": "License",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "ApplyLicensePayload",
            "kind": "LinkedField",
            "name": "applyLicense",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LicenseInAccountContainer",
                "kind": "LinkedField",
                "name": "licensesInAccount",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "License",
                    "kind": "LinkedField",
                    "name": "licenses",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AssignLicenseButton_LicensesInAccountContainerFragment"
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "LicensesInAccount_ApplyLicenseMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LicenseMutations",
        "kind": "LinkedField",
        "name": "License",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "ApplyLicensePayload",
            "kind": "LinkedField",
            "name": "applyLicense",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LicenseInAccountContainer",
                "kind": "LinkedField",
                "name": "licensesInAccount",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "License",
                    "kind": "LinkedField",
                    "name": "licenses",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "limitedToCoursesRef",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseLocksByUserType",
                    "kind": "LinkedField",
                    "name": "courseLocksByUser",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "user",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CourseIdWithTitle",
                        "kind": "LinkedField",
                        "name": "coursesToUnlock",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "courseId",
                            "storageKey": null
                          },
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CourseIdWithTitle",
                        "kind": "LinkedField",
                        "name": "coursesUnlocked",
                        "plural": true,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Course",
                    "kind": "LinkedField",
                    "name": "courses",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContainerMeta",
                        "kind": "LinkedField",
                        "name": "containerMeta",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "usersInAccount",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "33dedbdedabc8d9d59c9399281ec4670",
    "id": null,
    "metadata": {},
    "name": "LicensesInAccount_ApplyLicenseMutation",
    "operationKind": "mutation",
    "text": "mutation LicensesInAccount_ApplyLicenseMutation(\n  $courseId: ID!\n  $licensePoolId: ID!\n  $userId: ID\n  $licenseIdOpt: ID\n) {\n  License {\n    applyLicense(input: {courseId: $courseId, licensePoolId: $licensePoolId, userId: $userId, licenseIdOpt: $licenseIdOpt}) {\n      licensesInAccount {\n        id\n        licenses {\n          usageData {\n            usedByUser\n            usedAt\n            usedForCourse\n          }\n          id\n        }\n        licenseRequests {\n          rawId\n          courseRef\n          id\n          userRef\n          createdAt\n        }\n        ...AssignLicenseButton_LicensesInAccountContainerFragment\n      }\n      courseState {\n        node {\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment AssignLicenseButton_LicensesInAccountContainerFragment on LicenseInAccountContainer {\n  id\n  courseLocksByUser {\n    user\n    coursesToUnlock {\n      courseId\n      title\n    }\n    coursesUnlocked {\n      title\n    }\n  }\n  licenses {\n    limitedToCoursesRef\n    id\n  }\n  courses {\n    id\n    containerMeta {\n      title\n    }\n  }\n  usersInAccount {\n    id\n    name\n  }\n}\n"
  }
};
})();
(node as any).hash = '563634c3801152a8a98dcba183082913';
export default node;
