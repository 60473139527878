/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type LicensesInAccount_AcknowledgeNewLicensesInAccountMutationVariables = {};
export type LicensesInAccount_AcknowledgeNewLicensesInAccountMutationResponse = {
    readonly Viewer: {
        readonly License: {
            readonly acknowledgeNewLicensesInAccount: {
                readonly newLicensesInAccount: {
                    readonly id: string;
                    readonly newLicenses: boolean;
                };
                readonly clientMutationId: string | null;
            } | null;
        };
    };
};
export type LicensesInAccount_AcknowledgeNewLicensesInAccountMutation = {
    readonly response: LicensesInAccount_AcknowledgeNewLicensesInAccountMutationResponse;
    readonly variables: LicensesInAccount_AcknowledgeNewLicensesInAccountMutationVariables;
};



/*
mutation LicensesInAccount_AcknowledgeNewLicensesInAccountMutation {
  Viewer {
    License {
      acknowledgeNewLicensesInAccount(input: {}) {
        newLicensesInAccount {
          id
          newLicenses
        }
        clientMutationId
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ViewerMutationType",
    "kind": "LinkedField",
    "name": "Viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LicenseViewerMutationSchema",
        "kind": "LinkedField",
        "name": "License",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "input",
                "value": {}
              }
            ],
            "concreteType": "AcknowledgeNewLicensesInAccountInputPayload",
            "kind": "LinkedField",
            "name": "acknowledgeNewLicensesInAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NewLicensesInAccount",
                "kind": "LinkedField",
                "name": "newLicensesInAccount",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "newLicenses",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": "acknowledgeNewLicensesInAccount(input:{})"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LicensesInAccount_AcknowledgeNewLicensesInAccountMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LicensesInAccount_AcknowledgeNewLicensesInAccountMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "74f817e9f3f80b1e8c9be1bf695ea22b",
    "id": null,
    "metadata": {},
    "name": "LicensesInAccount_AcknowledgeNewLicensesInAccountMutation",
    "operationKind": "mutation",
    "text": "mutation LicensesInAccount_AcknowledgeNewLicensesInAccountMutation {\n  Viewer {\n    License {\n      acknowledgeNewLicensesInAccount(input: {}) {\n        newLicensesInAccount {\n          id\n          newLicenses\n        }\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '58222ddbdf8fb41f4894ab17f7dfe467';
export default node;
