import React from "react";
import {BlueBackground} from "../../../components/screen/BlueBackground";
import {Screen} from "../../../components/screen/Screen";
import {useLocation} from "react-router-dom";
import {LoadingFakeContinueButton} from "./LoadingFakeContinueButton";
import {LoadingFakeFlowSidebar} from "../LoadingFakeFlowSidebar";
import {LearnReviewSecondarySidebar} from "../../../../learnReview/LearnReviewSecondarySidebar";
import {LearnReviewPrimaryContentArea} from "../../../../learnReview/LearnReviewPrimaryContentArea";
import {LearnReviewSecondaryContentArea} from "../../../../learnReview/LearnReviewSecondaryContentArea";

export const LoadingFakeLearnReviewScreen = () => {

    const url = useLocation().pathname;
    const showSidebar = !(url.endsWith('/learn') || url.endsWith('/review'))

    return <Screen
        className="learn-screen"
        sidebarSlot={<LoadingFakeFlowSidebar/>}
        headerBarBackgroundSlot={
            <BlueBackground height={90}/>
        }
        floatingFooterSlot={
            <div className={`learn-footer d-flex justify-content-end p-3 position-relative`}>
                <LoadingFakeContinueButton/>
            </div>
        }
        contentAreaRenderer={children =>
            <LearnReviewPrimaryContentArea>
                {showSidebar && <LearnReviewSecondarySidebar/>}
                <LearnReviewSecondaryContentArea>{children}</LearnReviewSecondaryContentArea>
            </LearnReviewPrimaryContentArea>
        }>
        <></>
    </Screen>;
}