import {
    gtmTrackAddToCart, gtmTrackFirstSubmission,
    gtmTrackInitiateCheckout, gtmTrackViewLogin,
    gtmTrackPageView,
    gtmTrackPurchase,
    gtmTrackRegistration, gtmTrackStartTrial, gtmTrackViewCourseDetail
} from "./googleTagManager";

export const trackRegistration = (email: string, firstName: string, lastName: string, base64EncodedUserId: string) => {
    gtmTrackRegistration(base64EncodedUserId)
}

export const trackAddToCart = (
    lastAddedProduct: { base64EncodedProductId: string, quantity: number, productName: string },
    selectedProducts: { base64EncodedProductId: string, quantity: number }[],
    lastAddedProductPriceIncludingAllDiscounts: number,
    selectedProductsPriceIncludingAllDiscounts: number
) => {
    gtmTrackAddToCart(lastAddedProduct, selectedProducts, lastAddedProductPriceIncludingAllDiscounts, selectedProductsPriceIncludingAllDiscounts)
}

export const trackViewLogin = () => {
    gtmTrackViewLogin()
}

export const trackViewCourseDetail = (base64EncodedMainProductId: string, mainProductName: string, allNonHiddenBase64EncodedProductIds: string[]) => {
    gtmTrackViewCourseDetail(base64EncodedMainProductId, mainProductName, allNonHiddenBase64EncodedProductIds)
}

export const trackPurchase = (selectedProducts: { base64EncodedProductId: string, quantity: number, productName: string }[], productPriceIncludingAllDiscounts: number, base64EncodedOrderId: string) => {
    gtmTrackPurchase(selectedProducts, productPriceIncludingAllDiscounts, base64EncodedOrderId)
}

export const trackStartTrial = (base64EncodedCourseStateId: string) => {
    gtmTrackStartTrial(base64EncodedCourseStateId)
}

export const trackInitiateCheckout = (products: { base64EncodedProductId: string, quantity: number, productName: string }[], base64EncodedOrderId: string, productPriceIncludingAllDiscounts: number) => {
    gtmTrackInitiateCheckout(products, base64EncodedOrderId, productPriceIncludingAllDiscounts)
}


export const trackFirstSubmission = (base64EncodedUserId: string) => {
    gtmTrackFirstSubmission(base64EncodedUserId)
}

export const trackPageView = (url: string) => {
    gtmTrackPageView()
}
