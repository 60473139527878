import React from "react";
import { useTranslation } from "react-i18next";
import "./result.scss";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {Result_SubmissionFragment$key} from "../../../__generated__/Result_SubmissionFragment.graphql";

const RESULT_SUBMISSION_FRAGMENT = graphql`
      fragment Result_SubmissionFragment on Submission {
          result {
              grading
              pointsNeededForNextGrading
          }
          submissionType
      }`

interface OwnProps {
    submissionFragmentRef: Result_SubmissionFragment$key;
}

export const Result = ({submissionFragmentRef}: OwnProps) => {
    const {t} = useTranslation("submission")

    const submission = useFragment<Result_SubmissionFragment$key>(RESULT_SUBMISSION_FRAGMENT, submissionFragmentRef)

    return <div className="d-flex flex-column align-items-center result">
        <div className="tk-academy-larger-bold tk-color-darkblue mb-3">
            {t(`finished-display.${submission.submissionType}.heading.${submission.result.grading}`)}
        </div>

        <div className="tk-academy-large tk-color-waterblue-80 mb-3">
            {submission.result.pointsNeededForNextGrading === 1 ? t(`finished-display.${submission.submissionType}.missing-points.${submission.result.grading}.singular`) :
                t(`finished-display.${submission.submissionType}.missing-points.${submission.result.grading}.plural`, {missingPoints: submission.result.pointsNeededForNextGrading})}
        </div>

        <div className="tk-academy-large tk-color-waterblue-80 text-center">
            {t(`finished-display.${submission.submissionType}.text.${submission.result.grading}`)}
        </div>
    </div>
}
