import {
    questionnaireElementGraphQLToQuestionnaireElement,
    useQuestionnaireLogicV2
} from "@thekeytechnology/thekey-academy-frontend-library";
import {BaseElementDisplay} from "../common/BaseElementDisplay";
import React from "react";
import {useTranslation} from "react-i18next";
import "./questionnaire-element.scss";
import {PointsCircle} from "../../../core/components/radial-progress/PointsCircle";
import {ClassCard} from "./ClassCard";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {QuestionnaireElementDisplay_QuestionnaireElementFragment$key} from "../../../../__generated__/QuestionnaireElementDisplay_QuestionnaireElementFragment.graphql";
import {ClassCardGraphQLWrapper} from "./ClassCardGraphQLWrapper";

const QUESTIONNAIRE_ELEMENT_DISPLAY_QUESTIONNAIRE_ELEMENT_FRAGMENT = graphql`
    fragment QuestionnaireElementDisplay_QuestionnaireElementFragment on QuestionnaireElement {
        classes {
            description
            image {
                url
            }
            pointsThreshold
            title
            ...ClassCardGraphQLWrapper_QuestionnaireClassFragment
        }
        elementType
        generalSettings {
            points
        }
        id
        questions {
            reverseScale
            title
        }
    }`

interface OwnProps {
    questionnaireElementFragmentRef: QuestionnaireElementDisplay_QuestionnaireElementFragment$key;
}

export const QuestionnaireElementDisplay = ({
                                                questionnaireElementFragmentRef
                                            }: OwnProps) => {
    const element = useFragment<QuestionnaireElementDisplay_QuestionnaireElementFragment$key>(QUESTIONNAIRE_ELEMENT_DISPLAY_QUESTIONNAIRE_ELEMENT_FRAGMENT, questionnaireElementFragmentRef)

    const {t} = useTranslation("elementflow");

    const [question, selectAnswer, group, canGoBack, back, points] = useQuestionnaireLogicV2(questionnaireElementGraphQLToQuestionnaireElement(element))

    return <BaseElementDisplay
        className="questionnaire-element-display"
        elementSpecificSlot={
            question ? <div>
                <h1 className="tk-academy-larger-bold tk-color-darkblue text-center mb-3">{question.title}</h1>
                {[0, 1, 2, 3, 4].map(value => {
                    return <div
                        className="answer"
                        key={value}
                        onClick={() => selectAnswer(value)}>
                        <div>
                            {t(`questionnaire-element-display.answers.answer-${value}`)}
                        </div>
                    </div>
                })}
                {canGoBack ? <div>
                    <button className="btn btn-outline-secondary" onClick={() => back()}>
                        {t("questionnaire-element-display.back-button")}
                    </button>
                </div> : null}
            </div> : <div className="d-flex flex-column">
                <div className="d-flex mb-5 justify-content-center">
                    <PointsCircle pointsAwarded={points} pointsPossible={element.questions.length * 4}/>
                </div>
                <h1 className="tk-academy-larger-bold tk-color-darkblue text-center mb-3">Du bist {group?.title}</h1>
                <ClassCard questionnaireElementClass={group}/>
                <hr/>
                <h2 className="tk-academy-larger-bold tk-color-darkblue mb-3">Mögliche Klassen</h2>
                {element.classes.map((questionnaireClass, index) => {
                    return <ClassCardGraphQLWrapper
                        key={index}
                        className="mb-3"
                        questionnaireClassFragmentRef={questionnaireClass}/>
                })}
            </div>
        }/>
}
