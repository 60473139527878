/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ReviewContentArea_CourseFragment = {
    readonly " $fragmentRefs": FragmentRefs<"NotesScreenContent_CourseFragment">;
    readonly " $refType": "ReviewContentArea_CourseFragment";
};
export type ReviewContentArea_CourseFragment$data = ReviewContentArea_CourseFragment;
export type ReviewContentArea_CourseFragment$key = {
    readonly " $data"?: ReviewContentArea_CourseFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ReviewContentArea_CourseFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewContentArea_CourseFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotesScreenContent_CourseFragment"
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = '996cbbd87819b575b9164924ead9254b';
export default node;
