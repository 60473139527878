import React, {useState} from "react";
import {Welcome} from "./welcome/Welcome";
import {Screen} from "../../../core/components/screen/Screen";
import {BlueBackground} from "../../../core/components/screen/BlueBackground";
import {StreakDisplay} from "../../../gamification/components/streak/StreakDisplay";
import {NewsList} from "./news/NewsList";
import {NextLessonsSlider} from "./next-lessons/NextLessonsSlider";
import {OnboardingModal} from "../../../onboarding/components/OnboardingModal";
import {graphql} from "babel-plugin-relay/macro";
import {useLazyLoadQuery, useSubscribeToInvalidationState} from "react-relay";
import {MainSidebar} from "../../../core/components/sidebar/MainSidebar";
import {CelebrationPopup} from "../../../celebration/components/CelebrationPopup";
import {DashboardScreen_Query} from "../../../../__generated__/DashboardScreen_Query.graphql";
import {CourseList} from "./course-list/CourseList";

const QUERY = graphql`
    query DashboardScreen_Query  {
        Course {
            Courses {
                ...CourseList_CoursesConnectionFragment
                ...NextLessonsSlider_CoursesConnectionFragment
            }
        }
        Viewer {
            CourseState {
                CourseStates(first: null, after: null) @connection(key: "DashboardScreen_CourseStates") {
                    edges {
                        node {
                            id
                        }
                    }
                    ...CourseList_CourseStatesConnectionFragment
                    ...NextLessonsSlider_CourseStatesConnectionFragment
                }
            }
            CustomerMessageV2 {
                CustomerMessageInformation {
                    id
                    numUnseenCustomerMessages
                }
            }
            License{
                NewLicensesInAccount {
                    id
                    newLicenses
                }
            }
        }
    }`

interface State {
    fetchKey: number
}

export const DashboardScreen = () => {

    const [state, setState] = useState<State>({fetchKey: 0})

    const query = useLazyLoadQuery<DashboardScreen_Query>(QUERY, {}, {
        fetchPolicy: "network-only",
        fetchKey: state.fetchKey
    })

    const coursesConnection = query.Course.Courses
    const courseStatesConnection = query.Viewer.CourseState.CourseStates

    useSubscribeToInvalidationState(courseStatesConnection?.edges?.filter(e => !!e).map(e => e!.node.id) || [], () => {
        setState(state => ({...state, fetchKey: state.fetchKey + 1}))
    })

    return <Screen
        headerBarBackgroundSlot={<BlueBackground height={60}/>}
        sidebarSlot={<MainSidebar
            numUnseenCustomerMessages={query.Viewer.CustomerMessageV2.CustomerMessageInformation.numUnseenCustomerMessages}
            hasNewLicensesInAccount={query.Viewer.License.NewLicensesInAccount.newLicenses}
        />}
        headerMargin={30}>
        <div className="row">
            <div className="col-12 mb-5">
                <Welcome/>
                <StreakDisplay className="mt-3"/>
            </div>
        </div>
        <div className="row">
            <div className="col-12 mb-5">
                <NextLessonsSlider coursesConnectionFragmentRef={coursesConnection}
                                   courseStatesConnectionFragmentRef={courseStatesConnection}/>
            </div>
        </div>
        {coursesConnection && courseStatesConnection && <div className="row">
            <div className="col-12 mb-5">
                <CourseList coursesConnectionFragmentRef={coursesConnection}
                            courseStatesConnectionFragmentRef={courseStatesConnection}/>
            </div>

        </div>}
        <div className="row">
            <div className="col-12">
                <NewsList/>
            </div>
        </div>
        <OnboardingModal/>
        <CelebrationPopup/>
    </Screen>
}
