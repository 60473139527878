import {PayPalButton} from "react-paypal-button-v2";
import * as Sentry from "@sentry/react";
import React from "react";
import {useFragment, useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {PaypalButtonV3_OrderFragment$key} from "../../../../../__generated__/PaypalButtonV3_OrderFragment.graphql";
import styled from "styled-components";
import {PaypalButtonV3_PayMutation} from "../../../../../__generated__/PaypalButtonV3_PayMutation.graphql";
import {useDispatch} from "react-redux";
import {Message, postMessageAction} from "@thekeytechnology/framework-react";
import {trackPurchase} from "../../../../analytics/analytics";

const ORDER_FRAGMENT = graphql`
    fragment PaypalButtonV3_OrderFragment on Order {
        id,
        purchaser {
            userRef
        }
        cart {
            cartItems {
                ... on ProductCartItem {
                    product {
                        id
                        title
                    }
                }
            }
            cartSelection {
                selectedProducts {
                    amount
                    productId
                }
            }
            cartTotals {
                totalsIncludingAllDiscounts {
                    grossPrice
                    netPrice
                }
            }
        }
    }
`;

const PAY_MUTATION = graphql`
    mutation PaypalButtonV3_PayMutation($input: PayOrderInput!) {
        Billing {
            payOrder(input: $input) {
                order {
                    ...OrderFlowScreen_OrderFragment
                }
            }
        }
    }
`;

interface OwnProps {
    orderFragmentRef: PaypalButtonV3_OrderFragment$key
}

export const PaypalButtonV3 = ({orderFragmentRef}: OwnProps) => {
    const order = useFragment<PaypalButtonV3_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)
    const dispatch = useDispatch();

    const [pay, isInFLight] = useMutation<PaypalButtonV3_PayMutation>(PAY_MUTATION)

    return <PaypalButtonWrapper>
        {!isInFLight ? <PayPalButton
            createOrder={(data: any, actions: any) => {
                return actions.order.create({
                    purchase_units: [{
                        amount: {
                            currency_code: "EUR",
                            value: order.cart.cartTotals.totalsIncludingAllDiscounts?.grossPrice.toFixed(2)
                        },
                        invoice_id: order.id!,
                        custom_id: order.purchaser?.userRef!,
                        description: "thekey.academy Bestellung",

                    }],
                    application_context: {
                        shipping_preference: "NO_SHIPPING"
                    }
                });
            }}
            onApprove={(data: any, actions: any) => {
                // Capture the funds from the transaction
                return actions.order.capture().then((details: any) => {
                    if (details.error) {
                        Sentry.captureException(details.error);
                        dispatch(postMessageAction(Message.TYPE_DANGER, "Deine Zahlung konnte nicht abgeschlossen werden. Bitte überprüfe die Zahlungsmethode."))
                    } else {
                        pay({
                            variables: {
                                input: {
                                    orderId: order.id
                                }
                            },
                            onCompleted: _ => {
                                try {
                                    const selectedProducts = order.cart.cartSelection.selectedProducts
                                    const selectedProductTitles = order.cart.cartSelection.selectedProducts.map(sp => order.cart.cartItems.find(ci => ci.product?.id === sp.productId)?.product?.title).filter(spt => !!spt).map(spt => spt!)
                                    if (selectedProductTitles.length === selectedProducts.length && order.cart.cartTotals.totalsIncludingAllDiscounts?.netPrice && order.id) trackPurchase(selectedProducts.map((sp, i) => ({
                                        base64EncodedProductId: sp.productId,
                                        quantity: sp.amount,
                                        productName: selectedProductTitles[i]
                                    })), order.cart.cartTotals.totalsIncludingAllDiscounts.netPrice, order.id)
                                } catch (e) {
                                    Sentry.captureException(e)
                                }
                            }
                        })
                    }
                })
            }}
            onError={(error: any) => {
                dispatch(postMessageAction(Message.TYPE_DANGER, "Deine Zahlung konnte nicht abgeschlossen werden. Bitte überprüfe die Zahlungsmethode."))
                Sentry.captureException(error);
            }}
            options={{
                disableFunding: "sofort,sepa,giropay,card",
                clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
                currency: "EUR",
            }}
        /> : <div>Zahlung wird durchgeführt...</div>}
    </PaypalButtonWrapper>
}

const PaypalButtonWrapper = styled.div`
  margin-left: auto;
`

