import React, {useEffect} from "react";

import "./activation-screen.scss"
import {MenuHeader} from "../../../core/components/header/MenuHeader";
import {useDispatch, useSelector} from "react-redux";
import {activationAttemptAction, API_ACTIVATION, selectCombinedApiState} from "@thekeytechnology/framework-react";
import {AuthScreenHeading} from "../common/AuthScreenHeading";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useRouteMatch} from "react-router";

export const ActivationScreen = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation("auth");
    const activationCallState = useSelector(selectCombinedApiState(API_ACTIVATION));

    const params = useRouteMatch<{ token: string }>();

    useEffect(() => {
        if (params.params.token) {
            dispatch(activationAttemptAction(params.params.token));
        }
        // eslint-disable-next-line
    }, [])

    return <div className="h-100 row activation-screen">
        <div className="col-12 col-lg-4 tk-bg-light d-flex flex-column">
            <MenuHeader className="p-4" dark={true}/>
            <div className="h-100 align-items-center d-flex flex-column justify-content-center pt-0 pr-5 pb-5 pl-5">
                <AuthScreenHeading
                    title={t("activation-screen.heading-1")}
                    subtitle={t("activation-screen.heading-2")}
                />

                {activationCallState.inProgress ? <div>
                    <div className="tk-color-darkblue mb-5">
                        {t("activation-screen.activation-pending")}
                    </div>
                </div> : null}

                {activationCallState.succeeded ? <div className="tk-bg-green-20 mb-5 p-5">
                    {t("activation-screen.success-text")}
                </div> : null}

                {activationCallState.failed ? <div className="tk-bg-orange-20 mb-5 p-5">
                    {t("activation-screen.failure-text")}
                </div> : null}

                {!activationCallState.inProgress ? <NavLink to="/login">
                    <button type="button"
                            className="btn btn-primary align-self-end w-100"
                    >
                        {t("activation-screen.to-login")}
                    </button>
                </NavLink> : null}
            </div>
        </div>
        <div className="d-none d-lg-block col-8">
            <div className="registration-background">
            </div>
            <div className="p-5 registration-foreground">
                <div className="p-5 m-5">
                    <div className="tk-academy-huge-bold-text">Wir ermöglichen<br/> Weiterbildungen für jeden</div>
                    <div className="tk-academy-huge-text">Kurse digital und bequem von jedem<br/> Ort der Welt besuchen!
                    </div>
                </div>
            </div>
        </div>
    </div>
}
