import React from "react";
import styled from "styled-components";
import {tkColors} from "../style/tkColors";
import {Spinner} from "react-bootstrap";

export const LoadingSpinner = () => {
    return <SpinnerContainer className={"d-flex justify-content-center"}><StyledSpinner
        animation="grow"/></SpinnerContainer>
}

const SpinnerContainer = styled.div`
    height: 70%;
`

const StyledSpinner = styled(Spinner)`
    ${tkColors.keyGreen}
    align-self: center;
`