import React from "react";
import styled from "styled-components";
import {tkBackgrounds} from "../../../style/tkBackgrounds";
import {useLocation} from "react-router-dom";
import {LearnReviewSecondarySidebar} from "../../../../learnReview/LearnReviewSecondarySidebar";

export const LoadingFakeReviewContentArea = () => {

    const url = useLocation().pathname
    const showSidebar = !(url.endsWith('/learn') || url.endsWith('/review'))

    return <Container className={"d-flex flex-grow-1"}>
        {showSidebar && <LearnReviewSecondarySidebar/>}
        <Content className="d-flex flex-grow-1"/>
    </Container>
}

const Container = styled.div`
    z-index: 5;
    margin-top: 60px;
`

const Content = styled.div`
    ${tkBackgrounds.waterBlue10}
`
