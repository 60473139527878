import React, {useEffect, useState} from "react";
import {CourseDetailSidebar} from "./sidebar/CourseDetailSidebar";
import "./course-detail.scss"
import {Screen} from "../../../core/components/screen/Screen";
import {GradientBackgroundHeader} from "../../../core/components/screen/GradientBackground";
import {Switch, useRouteMatch} from "react-router";
import {ReviewLink} from "./ReviewLink";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment, useLazyLoadQuery, useSubscribeToInvalidationState,} from "react-relay";
import {
    CourseDetailScreen_CourseFragment$key
} from "../../../../__generated__/CourseDetailScreen_CourseFragment.graphql";
import {
    CourseDetailScreen_CourseStateFragment$key
} from "../../../../__generated__/CourseDetailScreen_CourseStateFragment.graphql";
import {CourseDetailScreen_Query} from "../../../../__generated__/CourseDetailScreen_Query.graphql";
import {CourseDetailFooter} from "./footer/CourseDetailFooter";
import {FeedbackButton} from "../../../feedback/controllers/FeedbackButton";
import {QnasScreen} from "../../../qnas/components/QnasScreen";
import {NotesScreenContent} from "../../../notes/components/NotesScreenContent";
import {CourseContent} from "./content/CourseContent";
import {CourseInfo} from "./info/CourseInfo";
import {CelebrationPopup} from "../../../celebration/components/CelebrationPopup";
import {GlossaryDisplay} from "../../../glossary/components/GlossaryDisplay";
import {Route} from "react-router-dom";
import {trackViewCourseDetail} from "../../../analytics/analytics";
import * as Sentry from "@sentry/react";

const QUERY = graphql`
    query CourseDetailScreen_Query($courseId: ID!) {
        node(id: $courseId) {
            ...CourseDetailScreen_CourseFragment
        }

        Viewer {
            CourseState {
                CourseState(Input: {courseId: $courseId}) {
                    ...CourseDetailScreen_CourseStateFragment
                }
            }
            License {
                NewLicensesInAccount {
                    id
                    newLicenses
                }
                LicensePools {
                    ...CourseInfo_LicensePoolsFragment
                    ...CourseDetailFooter_LicensePoolsFragment
                    ...CelebrationPopup_LicensePools
                }
            }
            CustomerMessageV2 {
                CustomerMessageInformation {
                    id
                    numUnseenCustomerMessages
                }
            }
            Billing {
                AllPublicProducts {
                    edges {
                        node {
                            id
                            title
                        }
                    }
                }
            }
        }
    }`

const COURSE_FRAGMENT = graphql`
    fragment CourseDetailScreen_CourseFragment on Course {
        containerMeta {
            title
        }
        mainProduct
        id
        ...CelebrationPopup_CourseFragment
        ...CourseContent_CourseFragment
        ...CourseDetailFooter_CourseFragment
        ...CourseDetailSidebar_CourseFragment
        ...CourseInfo_CourseFragment
        ...NotesScreenContent_CourseFragment
    }`

const COURSE_STATE_FRAGMENT = graphql`
    fragment CourseDetailScreen_CourseStateFragment on CourseState {
        id
        isBought
        ...CelebrationPopup_CourseStateFragment
        ...CourseContent_CourseStateFragment
        ...CourseDetailFooter_CourseStateFragment
        ...CourseDetailSidebar_CourseStateFragment
        ...CourseInfo_CourseStateFragment
    }`

interface State {
    fetchKey: number
}

interface OwnProps {
    courseId: string
}

const CourseDetailScreenComponent = ({courseId}: OwnProps) => {

    const [state, setState] = useState<State>({fetchKey: 0})

    const refetchCourseDetailScreenQuery = () => setState(state => ({...state, fetchKey: state.fetchKey + 1}))

    useEffect(() => {
        // workaround for https://github.com/facebook/relay/issues/3395 (maybe not necessary after relay version update)
        refetchCourseDetailScreenQuery()
    }, [])

    const query = useLazyLoadQuery<CourseDetailScreen_Query>(QUERY, {courseId: courseId}, {
        fetchPolicy: "network-only",
        fetchKey: state.fetchKey
    })

    const course = useFragment<CourseDetailScreen_CourseFragment$key>(COURSE_FRAGMENT, query.node)
    const allProducts = query.Viewer.Billing.AllPublicProducts.edges?.filter(e => !!e).map(e => e!.node) || []

    const courseState = useFragment<CourseDetailScreen_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, query.Viewer.CourseState.CourseState)

    useSubscribeToInvalidationState([courseState?.id].filter(id => !!id).map(id => id!), refetchCourseDetailScreenQuery)

    useEffect(() => {
        try {
            if (course && course.mainProduct && (!courseState ||courseState?.isBought === false)) {
                const mainProductName = allProducts.find(product => product?.id === course.mainProduct)?.title
                const allProductIds = allProducts.filter(product => !!product).map(l => l.id)
                if (mainProductName) {
                    trackViewCourseDetail(
                        course.mainProduct,
                        mainProductName,
                        allProductIds
                    )
                }
            }
        } catch (e) {
            Sentry.captureException(e)
        }
    })

    if (!course) return null

    return <Screen
        sidebarSlot={<CourseDetailSidebar
            courseFragmentRef={course} courseStateFragmentRef={courseState}
            numUnseenCustomerMessages={query.Viewer.CustomerMessageV2.CustomerMessageInformation.numUnseenCustomerMessages}
            newLicensesInAccount={query.Viewer.License.NewLicensesInAccount.newLicenses}
        />}
        headerBarBackgroundSlot={
            <GradientBackgroundHeader
                buttonSlot={<div className="d-flex">
                    <ReviewLink className="mr-2" courseId={course.id}/>
                    <FeedbackButton courseId={course.id} screen={"course-detail"}/>
                </div>}
                height={250}/>

        }
        headerMargin={60}
        floatingFooterSlot={<CourseDetailFooter
            courseFragmentRef={course} courseStateFragmentRef={courseState}
            licensePoolsFragmentRef={query.Viewer.License.LicensePools}
            refetchCourseDetailScreenQuery={refetchCourseDetailScreenQuery}
        />}
    >
        <Switch>
            <Route
                exact
                path="/courses/:courseId"
                component={
                    () => <CourseInfo
                        courseFragmentRef={course}
                        courseStateFragmentRef={courseState}
                        licensePoolsFragmentRef={query.Viewer.License.LicensePools}
                        refetchCourseDetailScreenQuery={refetchCourseDetailScreenQuery}
                    />
                }/>
            <Route
                exact
                path="/courses/:courseId/modules/:moduleId"
                component={() => <CourseContent courseFragmentRef={course} courseStateFragmentRef={courseState}/>}
            />
            <Route
                exact
                path="/courses/:courseId/notes"
                component={
                    () => <NotesScreenContent
                        courseFragmentRef={course}
                        headingSlot={
                            <div className="tk-academy-extra-large-heading-bold mb-5 ">
                                {`Notizen für ${course?.containerMeta.title}`}
                            </div>
                        }
                    />
                }
            />
            <Route
                exact
                path="/courses/:courseId/qnas"
                component={
                    () => <QnasScreen
                        courseId={course.id}
                        headingSlot={
                            <div className="tk-academy-extra-large-heading-bold mb-5 ">
                                {`Fragen & Antworten für ${course.containerMeta.title}`}
                            </div>
                        }
                    />
                }
            />
            <Route
                exact
                path="/courses/:courseId/glossary"
                component={() => <GlossaryDisplay courseId={course.id}/>}
            />
        </Switch>
        <CelebrationPopup courseFragmentRef={course} courseStateFragmentRef={courseState}
                          licensePoolsFragmentRef={query.Viewer.License.LicensePools}/>
    </Screen>
}

export const CourseDetailScreen = () => {

    const router = useRouteMatch<{ courseId?: string }>()

    if (!router.params.courseId) return null

    return <CourseDetailScreenComponent courseId={router.params.courseId}/>
}
