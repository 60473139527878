import React, {ReactNode} from "react";
import {PriceDisplay} from "../price/PriceDisplay";
import {MonthlyPriceExplanation, PriceExplanation} from "@thekeytechnology/thekey-academy-frontend-library";
import {PriceWithTaxDisplay} from "../price/PriceWithTaxDisplay";


export type PriceRenderer = (priceExplanation: PriceExplanation,
                             monthlyPriceExplanation?: MonthlyPriceExplanation, isDiscount?: boolean) => ReactNode;

interface OwnProps {
    priceExplanation: PriceExplanation
    monthlyPriceExplanation?: MonthlyPriceExplanation
    showNetPrices?: boolean
    showMonthly?: boolean
    isDiscount?: boolean
}

export const ShowOptionsPriceRenderer = ({
                                             priceExplanation,
                                             monthlyPriceExplanation,
                                             showNetPrices,
                                             showMonthly,
                                             isDiscount
                                         }: OwnProps) => {

    return <div className="price-renderer show-options-price-renderer">
        <div className="price">
            <PriceDisplay hideStrikePrice={isDiscount} priceExplanation={priceExplanation}
                          showNetPrices={showNetPrices}/>
        </div>
        {monthlyPriceExplanation && showMonthly ?
            <div className="monthly-price tk-academy-small-text tk-color-waterblue-80 mt-auto">
                {isDiscount ? <>
                <span>Oder ein monatlicher Rabatt von <strong className="monthly-rate"><PriceWithTaxDisplay
                    netPrice={monthlyPriceExplanation.discountedMonthlyRateNet}
                    grossPrice={monthlyPriceExplanation.discountedMonthlyRateGross}
                    showNetPrice={showNetPrices}/></strong></span>
                </> : <>
                <span>Oder in <strong>{monthlyPriceExplanation.rateCount}</strong> monatlichen Raten zu
                je <strong className="monthly-rate"><PriceWithTaxDisplay
                        netPrice={monthlyPriceExplanation.discountedMonthlyRateNet}
                        grossPrice={monthlyPriceExplanation.discountedMonthlyRateGross}
                        showNetPrice={showNetPrices}/></strong></span>
                </>}

            </div> : null}
    </div>
}

export const ShowOnePriceRenderer = ({
                                         priceExplanation,
                                         monthlyPriceExplanation,
                                         showNetPrices,
                                         showMonthly,
                                         isDiscount
                                     }: OwnProps) => {

    return showMonthly && !monthlyPriceExplanation ? null : <div className="price-renderer show-one-price-renderer">
        {showMonthly && monthlyPriceExplanation ? <div className="monthly-price">
            {isDiscount ? <>
                <span>Monatlicher Rabatt: <strong className="monthly-rate"><PriceWithTaxDisplay
                    netPrice={monthlyPriceExplanation.discountedMonthlyRateNet}
                    grossPrice={monthlyPriceExplanation.discountedMonthlyRateGross}
                    showNetPrice={showNetPrices}/></strong></span>
            </> : <>
            <span><strong>{monthlyPriceExplanation.rateCount}</strong> monatliche Raten zu
                je <strong className="monthly-rate"><PriceWithTaxDisplay
                    netPrice={monthlyPriceExplanation.discountedMonthlyRateNet}
                    grossPrice={monthlyPriceExplanation.discountedMonthlyRateGross}
                    showNetPrice={showNetPrices}/></strong></span>
            </>}
        </div> : <div className="price">
            <PriceDisplay
                hideStrikePrice={isDiscount}
                priceExplanation={priceExplanation} showNetPrices={showNetPrices}/>
        </div>}
    </div>

}
