import {Form, Formik} from "formik";
import * as yup from "yup";
import React, {useEffect} from "react";
import {PaymentOption} from "./methods/PaymentOption";
import {isValidIBANNumber} from "../../../utils/iban-utils";
import {useTranslation} from "react-i18next";
import {ValidatedField} from "../../../../core/components/form/ValidatedField";
import {SwitchField} from "../../../../core/components/form/SwitchField";
import {AcademyButton} from "../../../../core/components/button/AcademyButton";
import {useDispatch, useSelector} from "react-redux";
import {usePaymentLogic} from "./usePaymentLogic";
import {PaymentOptionsFormPart} from "./methods/PaymentOptionsFormPart";
import {PaypalFormPart} from "./PaypalFormPart";
import {selectCombinedApiState} from "@thekeytechnology/framework-react";
import {LoadingAnimation} from "../common/LoadingAnimation";
import {
    API_PAY,
    API_REGENERATE_PAYMENT_PROVIDER_DATA,
    OrderContainer,
    PaymentMethodIds,
    paymentMethodIsMonthly,
    paymentMethodNeedsSepaMandate,
    regeneratePaymentProviderDataAction
} from "@thekeytechnology/thekey-academy-frontend-library";

interface OwnProps {
    order: OrderContainer
    selectedPaymentMethod?: PaymentMethodIds
    selectPaymentMethod: (paymentMethod: PaymentMethodIds) => void;
}

export const PurchaseFormV2 = ({selectedPaymentMethod, order, selectPaymentMethod}: OwnProps) => {
    const {t} = useTranslation("billing")
    const dispatch = useDispatch();
    const apiCallState = useSelector(selectCombinedApiState(API_PAY))
    const regenerateApiCallState = useSelector(selectCombinedApiState(API_REGENERATE_PAYMENT_PROVIDER_DATA))

    const {startPayment} = usePaymentLogic(order, selectedPaymentMethod)

    useEffect(() => {
        if (selectedPaymentMethod) {
            dispatch(regeneratePaymentProviderDataAction(order.order.id!, selectedPaymentMethod))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPaymentMethod])

    return <div className="tk-academy-card purchase-form position-relative p-3">

        <Formik
            initialValues={{
                paymentMethodField: "",
                legal: false,
                foregoCancellation: false,
                adsOptIn: false,
                iban: "",
                sepaMandate: false
            }}
            enableReinitialize={true}
            validationSchema={
                yup.object().shape({
                    legal: yup
                        .boolean()
                        .oneOf([true], t("core:forms.required-field", {fieldName: t("purchase-form.legal")}))
                        .required(t("core:forms.required-field", {fieldName: t("purchase-form.legal")})),
                    foregoCancellation: yup
                        .boolean()
                        .oneOf([true], t("core:forms.required-field", {fieldName: t("purchase-form.forego-cancellation")}))
                        .required(t("core:forms.required-field", {fieldName: t("payment-form.forego-cancellation")})),
                    iban: yup
                        .string()
                        .test("test", "test", function (value) {
                            const valueIsFilled = !value || value.length > 8
                            if (paymentMethodIsMonthly(selectedPaymentMethod) && (valueIsFilled && !isValidIBANNumber(value!))) {
                                return this.createError({
                                    path: "iban",
                                    message: t("purchase-form.iban-error")
                                });
                            }
                            return true;
                        }),
                    sepaMandate: yup
                        .boolean()
                        .test("test", "test", function (value) {
                            if (paymentMethodNeedsSepaMandate(selectedPaymentMethod) && !value) {
                                return this.createError({
                                    path: "sepaMandate",
                                    message: t("purchase-form.sepa-mandate")
                                });
                            }
                            return true;
                        })
                })
            }
            onSubmit={(values, {setSubmitting, setErrors}) => {
                startPayment(values, setErrors, setSubmitting)
            }}
        >
            {formikState => {
                const monthlyPaymentMethods = order.order.entity.allowedPaymentMethods.filter(pm => paymentMethodIsMonthly(pm));

                return <Form>
                    <h3 className="tk-academy-large tk-color-waterblue-80 tk-academy-bold mb-2">Einmalzahlung</h3>
                    <div className="tk-academy-large tk-color-darkblue mb-3">Ich möchte den Rabatt in Anspruch nehmen
                        und die Lehrgangsgebühr in einer einmaligen Zahlung leisten:
                    </div>

                    <PaymentOptionsFormPart selectPaymentMethod={selectPaymentMethod} order={order}
                                            errors={formikState.errors} setFieldError={formikState.setFieldError}
                                            selectedPaymentMethod={selectedPaymentMethod}/>

                    {monthlyPaymentMethods.length > 0 ?
                        <div className="mb-5">
                            <hr className="mt-4 mb-4"/>
                            <h3 className="tk-academy-large tk-color-waterblue-80 tk-academy-bold mb-2">Monatliche
                                Zahlung</h3>
                            <div className="tk-academy-large tk-color-darkblue mb-3">Ich möchte flexibel bleiben und
                                monatlich
                                zahlen:
                            </div>
                            {monthlyPaymentMethods.map(paymentMethod => {
                                switch (paymentMethod) {
                                    case "monthly-tk":
                                    case "monthly-partner":
                                        return <PaymentOption
                                            key={paymentMethod}
                                            paymentMethod={paymentMethod}

                                            isSelected={paymentMethod === selectedPaymentMethod}
                                            onSelected={() => {
                                                formikState.setFieldError("paymentMethodField", "")
                                                selectPaymentMethod(paymentMethod)
                                            }}
                                            price={order.order.entity.priceTable.total}
                                            monthlyPrice={order.order.entity.priceTable.monthlyTotal}
                                            errors={formikState.errors.paymentMethodField}
                                            paymentFieldData={<div>
                                                <ValidatedField formikState={formikState}
                                                                placeholder={t("checkout-form.iban")}
                                                                name="iban"
                                                                className="form-control default-input"
                                                                required/>
                                            </div>}
                                        />
                                }
                                return null;
                            })}
                        </div>
                        : null}
                    {paymentMethodNeedsSepaMandate(selectedPaymentMethod) ? <div className="form-group">
                        <ValidatedField
                            name="sepaMandate"
                            component={SwitchField}
                            titleRight={true}
                            titleClassName="tk-academy-text tk-color-waterblue ml-3"
                            title={<><strong>SEPA-Lastschriftmandat:</strong> Hiermit ermächtige ich die Crefo-Factoring
                                Berlin-Brandenburg GmbH,
                                Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein
                                Geldinstitut an, die von der Crefo-Factoring Berlin-Brandenburg GmbH,
                                (Gläubiger-Identifikationsnummer: DE43ZZZ00000076116) von meinem Konto gezogenen
                                Lastschriften einzulösen. Ich kann innerhalb von acht Wochen, beginnend mit dem
                                Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die
                                mit
                                meinem Kreditinstitut vereinbarten Bedingungen. Ich verzichte zudem ausdrücklich auf
                                jegliche
                                Erinnerungen, bevor die Lastschrift eingezogen wird.<br/>
                                <br/>
                                <strong>Information:</strong> Um sich besser auf das Bereitstellen interaktiver
                                Lehrgänge konzentrieren zu
                                können, gibt die the key - academy GmbH die Debitorenbuchhaltung und den
                                Forderungseinzug
                                in die Hände der Crefo-Factoring Berlin-Brandenburg GmbH, an die wir unsere Forderungen
                                verkauft und übertragen haben.<br/>
                                Bitten denke daran, dass Du Deine Zahlungen mit befreiender Wirkung nur an die
                                Crefo-Factoring Berlin-Brandenburg GmbH per Überweisung oder Scheck an folgende
                                Bankverbindung leisten kannst: BIC: BELADEBEXXX IBAN: DE98 1005 0000 0190 3068 58, bei
                                der Landesbank Berlin – Berliner Sparkasse. Die Crefo-Factoring Berlin-Brandenburg GmbH
                                erhält die an Dich fakturierten Rechnungen, führt die Debitorenbuchhaltung und überwacht
                                den
                                Eingang der Beträge zu den mit Dir vereinbarten Fälligkeiten. Hinweise zum Datenschutz
                                findest
                                Du auf www.crefo-factoring.de/dsgvo-bb. Eventuelle Reklamationen sind der
                                Crefo-Factoring
                                Berlin-Brandenburg GmbH sofort zu melden, die sich umgehend mit uns in Verbindung setzen
                                wird. Für alle anderen Fragen zu thekey.academy sind wir natürlich weiterhin Dein 1.
                                Ansprechpartner. (erforderlich)</>}
                            formikState={formikState}/>
                    </div> : null}

                    <div className="form-group">
                        <ValidatedField
                            name="legal"
                            component={SwitchField}
                            titleRight={true}
                            titleClassName="tk-academy-text tk-color-waterblue ml-3"
                            title={<>Mit meiner Bestellung stimme ich der <a
                                rel="noopener noreferrer"
                                href='https://www.thekey.academy/datenschutzerklaerung/'
                                target='_blank'>Datenschutzerklärung</a> und den <a
                                rel="noopener noreferrer"
                                href='https://www.thekey.academy/agbs/'
                                target='_blank'>Nutzungsbedingungen</a> zu. (erforderlich)</>}
                            formikState={formikState}/>
                    </div>
                    <div className="form-group">
                        <ValidatedField
                            name="foregoCancellation"
                            component={SwitchField}
                            titleRight={true}
                            titleClassName="tk-academy-text tk-color-waterblue ml-3"
                            title="Ich bin einverstanden und verlange ausdrücklich, dass Sie vor Ende der Widerrufsfrist mit der Ausführung der beauftragten Dienstleistung beginnen. Mir ist bekannt, dass ich bei vollständiger Vertragserfüllung durch Sie mein Widerrufsrecht verliere. (erforderlich)"
                            formikState={formikState}/>
                    </div>
                    <div className="form-group">
                        <ValidatedField
                            name="adsOptIn"
                            component={SwitchField}
                            titleRight={true}
                            titleClassName="tk-academy-text tk-color-waterblue ml-3"
                            title="Ich freue mich, über Aktionen, Rabatte und Neuigkeiten per E-Mail informiert zu werden."
                            formikState={formikState}/>
                    </div>

                    {selectedPaymentMethod === "paypal" && formikState.isValid ?
                        <PaypalFormPart order={order} values={formikState.values}/> : <AcademyButton
                            className="ml-auto"
                            onClick={() => formikState.handleSubmit()}
                            disabled={apiCallState.inProgress || formikState.isSubmitting || regenerateApiCallState.inProgress}
                            text={"Jetzt verbindlich bestellen"}
                            type={"submit"}/>}
                </Form>


            }}
        </Formik>
        {apiCallState.inProgress ? <LoadingAnimation/> : null}
    </div>
}
