import React from "react";
import {Menu} from "../../../menu/components/Menu";
import {determineElementIcon} from "../../../elementFlow/components/ElementDisplay";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {LearnSidebar_LessonFragment$key} from "../../../../__generated__/LearnSidebar_LessonFragment.graphql";
import {LearnSidebar_ElementFragment$key} from "../../../../__generated__/LearnSidebar_ElementFragment.graphql";

const LEARN_SIDEBAR_ELEMENT_FRAGMENT = graphql`
    fragment LearnSidebar_ElementFragment on Element {
        elementType
    }
`

const LEARN_SIDEBAR_LESSON_FRAGMENT = graphql`
    fragment LearnSidebar_LessonFragment on Lesson {
        containerMeta {
            title
        }
        course {
            containerMeta {
                title
            }
            id
        }
        id
        lessonPath {
            courseRef
            lessonIndex
            moduleIndex
            moduleRef
        }
    }
`

interface OwnProps {
    elementFragmentRef?: LearnSidebar_ElementFragment$key | null;
    lessonFragmentRef: LearnSidebar_LessonFragment$key;
    className?: string;
}

export const LearnSidebar = ({elementFragmentRef, lessonFragmentRef, className}: OwnProps) => {
    const element = useFragment<LearnSidebar_ElementFragment$key>(LEARN_SIDEBAR_ELEMENT_FRAGMENT, elementFragmentRef || null)

    const lesson = useFragment<LearnSidebar_LessonFragment$key>(LEARN_SIDEBAR_LESSON_FRAGMENT, lessonFragmentRef)

    const mainMenuIcon = determineElementIcon(element?.elementType);

    const menuItems = element ? [
        {
            key: "course." + element.elementType,
            path: `/courses/${lesson.lessonPath.courseRef}/modules/${lesson.lessonPath.moduleRef}/lessons/${lesson.id}/learn`,
            icon: mainMenuIcon
        },
        {
            key: "course.notes",
            path: `/courses/${lesson.lessonPath.courseRef}/modules/${lesson.lessonPath.moduleRef}/lessons/${lesson.id}/learn/notes`,
            icon: "icon-a_notes"
        },
        {
            key: "course.qnas",
            path: `/courses/${lesson.lessonPath.courseRef}/modules/${lesson.lessonPath.moduleRef}/lessons/${lesson.id}/learn/qnas`,
            icon: "icon-a_qanda"
        }
    ] : [{
        key: "course.finished",
        path: `/courses/${lesson.lessonPath.courseRef}/modules/${lesson.lessonPath.moduleRef}/lessons/${lesson.id}/learn`,
        icon: mainMenuIcon
    }]

    return <div className={`flex-row flex-nowrap ${className ? className : ""}`}>
        <div className="academy-sidebar menu-column h-100 d-flex flex-column">
            <div className="tk-bg-waterblue p-4">
                <div className="tk-white tk-academy-small-text tk-academy-bold">Mein Kurs</div>
                <div
                    className="tk-white tk-academy-large tk-academy-bold">{lesson.course.containerMeta.title}</div>
                <div className="tk-academy-text tk-academy-bold tk-white"
                     id="info">
                    Lektion {lesson.lessonPath.moduleIndex + 1}.{lesson.lessonPath.lessonIndex + 1} | {lesson.containerMeta.title}
                </div>
            </div>
            <div className="tk-bg-waterblue flex-grow-1">
                <Menu items={menuItems}/>
            </div>
        </div>
    </div>;
}
