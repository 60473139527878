import React, {useCallback} from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {showCelebrationAction} from "@thekeytechnology/thekey-academy-frontend-library";
import {AcademyButton} from "../../core/components/button/AcademyButton";
import {graphql} from "babel-plugin-relay/macro";
import {ConnectionHandler, useFragment, useMutation} from "react-relay";
import {
    CourseAttributes,
    StartCourseButton_CourseFragment$key
} from "../../../__generated__/StartCourseButton_CourseFragment.graphql";
import {StartCourseButton_StartCourseMutation} from "../../../__generated__/StartCourseButton_StartCourseMutation.graphql";
import * as Sentry from "@sentry/react";
import {trackStartTrial} from "../../analytics/analytics";

const START_COURSE_MUTATION = graphql`
    mutation StartCourseButton_StartCourseMutation($courseId: ID!, $connections: [ID!]!) {
        CourseState {
            startCourse(input: {courseId: $courseId}) {
                courseState @appendEdge(connections: $connections) {
                    cursor
                    node {
                        id
                        courseRef
                        ...CourseDetailScreen_CourseStateFragment
                        ...AdvanceCourseButton_CourseStateFragment
                    }
                }
            }
        }
    }`

const COURSE_FRAGMENT = graphql`
    fragment StartCourseButton_CourseFragment on Course {
        attributes
        containerMeta {
            title
        }
        id
    }`

interface OwnProps {
    courseFragmentRef: StartCourseButton_CourseFragment$key;
}

export const StartCourseButton = ({courseFragmentRef}: OwnProps) => {

    const {t} = useTranslation("state")

    const dispatch = useDispatch()

    const [startCourse, isInFlight] = useMutation<StartCourseButton_StartCourseMutation>(START_COURSE_MUTATION)

    const course = useFragment<StartCourseButton_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef)

    const showPopupForRegular = useCallback(() => {
        dispatch(showCelebrationAction({
            title: t("start-course-button.regular-celebration-modal.title", {courseName: course.containerMeta.title}),
            text: t("start-course-button.regular-celebration-modal.text", {courseName: course.containerMeta.title}),
            explanation: t("start-course-button.regular-celebration-modal.explanation", {courseName: course.containerMeta.title}),
            buttonDescription: {
                specialType: "NextLesson",
                label: "",
                params: {
                    courseId: course.id!
                }
            }
        }))
        // eslint-disable-next-line
    }, [course])

    const showPopupForEarlyBird = useCallback(() => {
        dispatch(showCelebrationAction({
            title: t("start-course-button.early-bird-celebration-modal.title"),
            explanation: t("start-course-button.early-bird-celebration-modal.explanation"),
            image: "special-newbie",
            text: t("start-course-button.early-bird-celebration-modal.text", {courseName: course.containerMeta.title}),
            buttonDescription: {
                specialType: "NextLesson",
                label: "",
                params: {
                    courseId: course.id!
                }
            }
        }))
        // eslint-disable-next-line
    }, [course])

    return <AcademyButton
        disabled={isInFlight}
        icon={'a_forward'}
        text={t("start-course-button.button")}
        onClick={() => {
            startCourse({
                variables: {
                    courseId: course.id,
                    connections: [ConnectionHandler.getConnectionID(
                        'client:root:viewer:CourseState',
                        'DashboardScreen_CourseStates',
                    )]
                },
                onCompleted: response => {
                    try {
                        if (response.CourseState.startCourse?.courseState.node.id) trackStartTrial(response.CourseState.startCourse.courseState.node.id)
                    } catch (e) {
                        Sentry.captureException(e)
                    }
                },
                updater: (store, value) => {
                    const courseStateRecord = store.get(value.CourseState.startCourse?.courseState.node?.id!);
                    const courseId = value.CourseState.startCourse?.courseState.node?.courseRef;

                    const viewerField = store.getRoot().getLinkedRecord('Viewer');
                    const existingCourseStateField = viewerField?.getLinkedRecord('CourseState');

                    const courseStateField = existingCourseStateField ? existingCourseStateField : store.create('client:root:viewer:CourseState', 'CourseStateViewerSchema')
                    viewerField?.setLinkedRecord(courseStateField, 'CourseState')

                    if (courseStateField && courseStateRecord) {
                        courseStateField?.setLinkedRecord(courseStateRecord, 'CourseState', {Input: {courseId: courseId}})
                    }
                }
            })

            course.attributes.find(a => a === "earlyBird" as CourseAttributes) ? showPopupForEarlyBird() : showPopupForRegular()
        }}
    />
}


