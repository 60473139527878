import React, {useState} from 'react';
import {PersologClassCard} from './PersologClassCard';
import {PersologClassExplanation} from "./PersologClassExplanation";
import {PersologProgressBar} from "./PersologProgressBar";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {PersologResultDisplay_PersologElementFragment$key} from "../../../../../__generated__/PersologResultDisplay_PersologElementFragment.graphql";
import {PersologClassExplanation_PersologClassFragment$key} from "../../../../../__generated__/PersologClassExplanation_PersologClassFragment.graphql";
import {PERSOLOG_BULLSHIT_PERCENTAGES} from "@thekeytechnology/thekey-academy-frontend-library";

const PERSOLOG_RESULT_DISPLAY_PERSOLOG_ELEMENT_FRAGMENT = graphql`
    fragment PersologResultDisplay_PersologElementFragment on PersologElement {
        classes {
            classCode
            ...PersologClassCard_PersologClassFragment
            ...PersologClassExplanation_PersologClassFragment
        }
    }`

interface OwnProps {
    persologElementFragmentRef: PersologResultDisplay_PersologElementFragment$key;
    result: {
        pointsByClass: {
            [persologClass: string]: number
        }
    }
}

export const PersologResultDisplay = ({persologElementFragmentRef, result}: OwnProps) => {

    const persologElement = useFragment<PersologResultDisplay_PersologElementFragment$key>(PERSOLOG_RESULT_DISPLAY_PERSOLOG_ELEMENT_FRAGMENT, persologElementFragmentRef)

    const [shownClass, setShownClass] = useState<PersologClassExplanation_PersologClassFragment$key | undefined>()

    const classes: string[] = ['d', 'i', 's', 'g'];
    return shownClass ? <PersologClassExplanation onClose={() => {
        setShownClass(undefined)
    }} persologClassFragmentRef={shownClass}/> : <div>
        {classes.map(persologClass => {
            const percentage = PERSOLOG_BULLSHIT_PERCENTAGES[persologClass][result.pointsByClass[persologClass]]
            return <PersologProgressBar
                key={persologClass}
                persologClass={persologClass}
                percentage={percentage}
            />
        })}
        <div className="mt-4">
            {classes.sort((c1, c2) => {
                const percentageC1 = PERSOLOG_BULLSHIT_PERCENTAGES[c1][result.pointsByClass[c1]]
                const percentageC2 = PERSOLOG_BULLSHIT_PERCENTAGES[c2][result.pointsByClass[c2]]

                return percentageC1 > percentageC2 ? -1 : 1
            }).map(persologClassCode => {
                const persologClass = persologElement.classes.find(x => x.classCode === persologClassCode)!
                return <div className="tk-cursor-pointer" key={persologClassCode}
                            onClick={() => setShownClass(persologClass)}>
                    <PersologClassCard persologClassFragmentRef={persologClass}/>
                </div>
            })}
        </div>
    </div>
}
