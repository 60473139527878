/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LearnContentArea_LessonFragment = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"NotesScreenContent_LessonFragment">;
    readonly " $refType": "LearnContentArea_LessonFragment";
};
export type LearnContentArea_LessonFragment$data = LearnContentArea_LessonFragment;
export type LearnContentArea_LessonFragment$key = {
    readonly " $data"?: LearnContentArea_LessonFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LearnContentArea_LessonFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LearnContentArea_LessonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotesScreenContent_LessonFragment"
    }
  ],
  "type": "Lesson",
  "abstractKey": "__isLesson"
};
(node as any).hash = 'b5083f21fa97ed39b0bebdaaf5a62ba6';
export default node;
