import {ElementContext} from "@thekeytechnology/thekey-academy-frontend-library";
import React, {useContext} from "react";
import {HtmlTextWithResolvedImages} from "../../elementFlow/components/text/HtmlTextWithResolvedImages";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {TextAdditionalContentDisplay_TextAdditionalContentFragment$key} from "../../../__generated__/TextAdditionalContentDisplay_TextAdditionalContentFragment.graphql";

const TEXT_ADDITIONAL_CONTENT_DISPLAY_TEXT_ADDITIONAL_CONTENT_FRAGMENT = graphql`
    fragment TextAdditionalContentDisplay_TextAdditionalContentFragment on TextAdditionalContent {
        text
        title
    }`

interface OwnProps {
    textAdditionalContentFragmentRef: TextAdditionalContentDisplay_TextAdditionalContentFragment$key;
}

export const TextAdditionalContentDisplay = ({
                                                 textAdditionalContentFragmentRef
                                             }: OwnProps) => {

    const additionalContent = useFragment<TextAdditionalContentDisplay_TextAdditionalContentFragment$key>(TEXT_ADDITIONAL_CONTENT_DISPLAY_TEXT_ADDITIONAL_CONTENT_FRAGMENT, textAdditionalContentFragmentRef);

    const {fileResolutionStrategy} = useContext(ElementContext)

    return <div>
        <h1 className="tk-academy-larger-bold mb-3 tk-color-darkblue">{additionalContent.title}</h1>
        <HtmlTextWithResolvedImages text={additionalContent.text}
                                    fileResolutionStrategy={fileResolutionStrategy}/>
    </div>
}
