/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CartItemType = "bulkDiscountCartItem" | "discountCodeCartItem" | "productCartItem" | "%future added value";
export type OrderStatus = "hasBillingDetails" | "hasCart" | "hasPaymentDetails" | "paymentReceived" | "purchased" | "transient" | "%future added value";
export type OrderFlowContextProvider_OrderFragment = {
    readonly status: OrderStatus;
    readonly cart: {
        readonly cartItems: ReadonlyArray<{
            readonly itemType: CartItemType;
        }>;
    };
    readonly " $refType": "OrderFlowContextProvider_OrderFragment";
};
export type OrderFlowContextProvider_OrderFragment$data = OrderFlowContextProvider_OrderFragment;
export type OrderFlowContextProvider_OrderFragment$key = {
    readonly " $data"?: OrderFlowContextProvider_OrderFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"OrderFlowContextProvider_OrderFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderFlowContextProvider_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "cartItems",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "itemType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
(node as any).hash = '5ba0df0dff5f49dd3308d17db5b4fb33';
export default node;
