import React from "react"
import {BillingStepContainer, BillingStepHeader, BillingStepHeading, BillingStepIcon} from "./common/BillingStep"
import {Separator} from "./payment/PaymentStep"
import styled from "styled-components"
import {Widget} from "@typeform/embed-react"
import {useTranslation} from "react-i18next"
import {tkColors} from "../../../core/style/tkColors"
import {tkTypography} from "../../../core/style/tkTypography"


export const ThankYouV3 = () => {
    const {t} = useTranslation("billing")

    return <BillingStepContainer>
        <BillingStepHeader>
            <BillingStepIcon isLocked={false} isCurrent={false} className={"icon-a_tick_circle"}/>
            <BillingStepHeading>{t("thank-you.heading")}</BillingStepHeading>
        </BillingStepHeader>
        <div className="d-flex flex-row">
            <PaymentContainer>
                <Text>
                    {t("thank-you.description.part-one")}
                    <br/>
                    <strong>{t("thank-you.description.part-two")}</strong>
                    <br/>
                    <LinkToDashboard
                        href="/">{t("thank-you.description.part-three")}</LinkToDashboard>{t("thank-you.description.part-four")}
                </Text>
                <Separator/>
                <FeedbackFormHeader>{t("thank-you.form.heading")}</FeedbackFormHeader>
                <FeedbackForm id="iTpLqvPW"/>
            </PaymentContainer>
        </div>
    </BillingStepContainer>
}

const LinkToDashboard = styled.a`
  ${tkColors.keyGreen}
`

const FeedbackFormHeader = styled.div`
  ${tkTypography.largerExtraBold}
  ${tkColors.waterBlue}
`

const FeedbackForm = styled(Widget)`
  width: 100%;
  height: 800px;
  border: none;
`

const PaymentContainer = styled.div`
  width: 100%;
  position: relative;
`

const Text = styled.div`
  ${tkTypography.large}
  ${tkColors.waterBlue}
  margin: 20px 0;
`
