import React, {useCallback, useState} from "react";

import {RegistrationData, RegistrationForm} from "./RegistrationForm";
import "./registration-screen.scss"
import {RegistrationScreenContainer} from "./RegistrationScreenContainer";
import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {RegistrationScreen_RegisterMutation} from "../../../../__generated__/RegistrationScreen_RegisterMutation.graphql";
import {useHistory} from "react-router";
import {trackRegistration} from "../../../analytics/analytics";
import * as Sentry from "@sentry/react";

const MUTATION = graphql`
    mutation RegistrationScreen_RegisterMutation($input: RegistrationInput!) {
        Auth {
            register(input: $input) {
                userId
            }
        }
    }
`

interface State {
    registrationSucceeded: boolean
}

export const RegistrationScreen = () => {

    const [state, setState] = useState<State>({registrationSucceeded: false})

    const history = useHistory()

    const [register, isInFlight] = useMutation<RegistrationScreen_RegisterMutation>(MUTATION)

    const doRegister = useCallback((input: RegistrationData) => register({
        variables: {
            input: {
                adsOptIn: input.adsOptIn,
                companySize: input.companySize,
                email: input.email,
                firstName: input.firstName,
                industry: input.industry,
                lastName: input.lastName,
                password: input.password,
                position: input.position
            }
        },
        onCompleted: (response) => {
            setState(_ => ({registrationSucceeded: true}))

            try {
                if(response.Auth.register?.userId) trackRegistration(input.email, input.firstName, input.lastName, response.Auth.register.userId)
            } catch (e) {
                Sentry.captureException(e)
            }
        }
        // eslint-disable-next-line
    }), [])

    return <RegistrationScreenContainer>
        <RegistrationForm
            doRegister={doRegister}
            isInFlight={isInFlight}
            registrationSucceeded={state.registrationSucceeded}
            switchToLogin={() => history.push('/login')}
        />
    </RegistrationScreenContainer>
}
