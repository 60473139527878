/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PaymentStep_OrderFragment = {
    readonly " $fragmentRefs": FragmentRefs<"MonthlyPaymentCategory_OrderFragment" | "OneTimePaymentMethodCategory_OrderFragment">;
    readonly " $refType": "PaymentStep_OrderFragment";
};
export type PaymentStep_OrderFragment$data = PaymentStep_OrderFragment;
export type PaymentStep_OrderFragment$key = {
    readonly " $data"?: PaymentStep_OrderFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"PaymentStep_OrderFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PaymentStep_OrderFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MonthlyPaymentCategory_OrderFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OneTimePaymentMethodCategory_OrderFragment"
    }
  ],
  "type": "Order",
  "abstractKey": null
};
(node as any).hash = 'b38d466db6510b31092f03271a4b2b65';
export default node;
