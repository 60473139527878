import React from "react";
import {RouteAuthState, RouteDefinition} from "../core/components/routing/RouteDefinition";
import {RegistrationInLicensePoolScreen} from "./components/RegistrationInLicensePoolScreen";

export const LicensesRouteDefinitions: RouteDefinition[] = [
    {
        path: "/register/:licensePoolId",
        component: () => <RegistrationInLicensePoolScreen/>,
        authState: RouteAuthState.OnlyLoggedOut
    }
]
