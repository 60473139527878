import React, {useEffect} from "react";
import {
    changeUserSettingsAction,
    fetchUserSettingsAction,
    selectUserSettings,
    UserSettings
} from "@thekeytechnology/thekey-academy-frontend-library";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import * as yup from "yup"
import * as Yup from "yup"
import {ValidatedField} from "../../core/components/form/ValidatedField";
import {SwitchField} from "../../core/components/form/SwitchField";
import {SaveUserSettingsButton} from "./SaveUserSettingsButton";

interface StateProps {
    userSettings?: UserSettings;
}

interface DispatchProps {
    fetchUserSettings: typeof fetchUserSettingsAction;
    changeUserSettings: typeof changeUserSettingsAction;
}

type Props = StateProps & DispatchProps;

const UserSettingsFormComponent = ({
                                       userSettings,
                                       fetchUserSettings,
                                       changeUserSettings
                                   }: Props) => {

    const {t} = useTranslation(["usersettings", "core", "auth"]);

    useEffect(() => {
        fetchUserSettings();
    }, [fetchUserSettings])

    return userSettings ? <Formik
        initialValues={{
            firstName: userSettings.firstName,
            lastName: userSettings.lastName,
            email: userSettings.email,
            password: "",
            password2: "",
            learnReminder: userSettings.learnReminder
        }}
        enableReinitialize={true}
        validationSchema={
            yup.object().shape({
                email: Yup
                    .string()
                    .email(t("auth:registration-form.email-error")),
                password: Yup
                    .string()
                    .min(8, t("auth:registration-form.password-error")),
                password2: Yup.string()
                    .oneOf([Yup.ref("password"), undefined], t("auth:registration-form.passwords-must-match")),
                firstName: Yup
                    .string(),
                lastName: Yup
                    .string()
            })
        }
        onSubmit={values => {
            changeUserSettings({
                firstNameChange: userSettings.firstName !== values.firstName ? values.firstName : undefined,
                lastNameChange: userSettings.lastName !== values.lastName ? values.lastName : undefined,
                passwordChange: values.password.length > 0 ? values.password : undefined,
                emailChange: userSettings.email !== values.email ? values.email : undefined,
                learnReminderChange: userSettings.learnReminder !== values.learnReminder ? values.learnReminder : undefined
            })
        }}
    >
        {formikState => {
            return (
                <Form>
                    <h1 className="tk-academy-larger-bold mb-3 tk-color-darkblue">
                        {t("user-settings-form.heading")}
                    </h1>
                    <div className="row">
                        <div className="col-6">
                            <div
                                className="tk-academy-small-text tk-academy-semibold tk-color-waterblue-80 mb-2">
                                {t("user-settings-form.profile")}
                            </div>
                            <div className="form-group">
                                <ValidatedField
                                    name="firstName"
                                    type="text"
                                    placeholder={t("user-settings-form.first-name.placeholder")}
                                    formikState={formikState}/>
                            </div>
                            <div className="form-group">
                                <ValidatedField
                                    name="lastName"
                                    type="text"
                                    placeholder={t("user-settings-form.last-name.placeholder")}
                                    formikState={formikState}
                                    className="form-control default-input"
                                />
                            </div>
                            <div className="form-group">
                                <ValidatedField
                                    name="email"
                                    type="email"
                                    placeholder={t("auth:registration-form.e-mail-placeholder")}
                                    formikState={formikState}
                                    className="form-control default-input"
                                />
                            </div>
                            <div
                                className="tk-academy-small-text tk-academy-semibold tk-color-waterblue-80 mb-2">
                                {t("user-settings-form.password.label")}
                            </div>
                            <div className="form-group">
                                <ValidatedField
                                    name="password"
                                    type="password"
                                    placeholder={t("user-settings-form.password.placeholder")}
                                    formikState={formikState}
                                    className="form-control default-input"
                                />
                            </div>
                            {formikState.touched.password && formikState.values.password.length > 0 ?
                                <div className="form-group">
                                    <ValidatedField formikState={formikState}
                                                    type="password"
                                                    iconClass={"icon-a_lock"}
                                                    placeholder={t("auth:registration-form.password2-placeholder")}
                                                    name="password2"
                                                    className="form-control default-input"
                                                    required/>
                                </div> : null}
                        </div>
                        <div className="col-4">
                            <div
                                className="tk-academy-small-text tk-academy-semibold tk-color-waterblue-80 mb-2">
                                {t("user-settings-form.notifications")}
                            </div>
                            <div className="form-group">
                                <ValidatedField
                                    name="learnReminder"
                                    fieldName={"learnReminder"}
                                    component={SwitchField}
                                    title={t("user-settings-form.learn-reminder.label")}
                                    formikState={formikState}/>
                            </div>
                        </div>
                    </div>
                    <SaveUserSettingsButton blockSubmit={Object.keys(formikState.touched).length === 0}
                                            onSubmit={() => formikState.handleSubmit()} className="ml-auto"/>
                </Form>)
        }}
    </Formik> : null;
}

export const UserSettingsForm = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        userSettings: selectUserSettings(state)
    }),
    {
        fetchUserSettings: fetchUserSettingsAction,
        changeUserSettings: changeUserSettingsAction
    }
)(UserSettingsFormComponent)
