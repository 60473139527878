/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ElementType = "clozeText" | "image" | "multipleChoice" | "order" | "persolog" | "questionnaire" | "resolution" | "text" | "video" | "%future added value";
export type MultipleChoiceElementDisplay_MultipleChoiceElementFragment = {
    readonly allowMultipleAnswers: boolean;
    readonly allCorrectAnswersNeedToBeGiven: boolean;
    readonly answerOptions: ReadonlyArray<{
        readonly answer: {
            readonly " $fragmentRefs": FragmentRefs<"RandomPhrasing_PhrasingAlternativesFragment">;
        };
        readonly image: {
            readonly url: string | null;
        } | null;
        readonly isCorrect: boolean;
    }>;
    readonly elementType: ElementType;
    readonly generalSettings: {
        readonly points: number;
    };
    readonly id: string;
    readonly image: {
        readonly url: string | null;
    } | null;
    readonly question: {
        readonly " $fragmentRefs": FragmentRefs<"RandomPhrasing_PhrasingAlternativesFragment">;
    };
    readonly " $refType": "MultipleChoiceElementDisplay_MultipleChoiceElementFragment";
};
export type MultipleChoiceElementDisplay_MultipleChoiceElementFragment$data = MultipleChoiceElementDisplay_MultipleChoiceElementFragment;
export type MultipleChoiceElementDisplay_MultipleChoiceElementFragment$key = {
    readonly " $data"?: MultipleChoiceElementDisplay_MultipleChoiceElementFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"MultipleChoiceElementDisplay_MultipleChoiceElementFragment">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "RandomPhrasing_PhrasingAlternativesFragment"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MultipleChoiceElementDisplay_MultipleChoiceElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowMultipleAnswers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allCorrectAnswersNeedToBeGiven",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnswerOption",
      "kind": "LinkedField",
      "name": "answerOptions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PhrasingAlternatives",
          "kind": "LinkedField",
          "name": "answer",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCorrect",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GeneralElementSettings",
      "kind": "LinkedField",
      "name": "generalSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "points",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PhrasingAlternatives",
      "kind": "LinkedField",
      "name": "question",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "MultipleChoiceElement",
  "abstractKey": null
};
})();
(node as any).hash = '84259816c018d6663c59be58999d44b3';
export default node;
