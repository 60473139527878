/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PaymentMethod = "card" | "giropay" | "iapApple" | "iapGoogle" | "invoicePartner" | "invoiceTk" | "monthlyPartner" | "monthlyTk" | "paypal" | "sepa" | "sofort" | "%future added value";
export type SelectedPaymentMethodType = "monthly" | "oneTime" | "%future added value";
export type ReviewCartTotalsDisplay_OrderFragment = {
    readonly selectedPaymentMethod: {
        readonly paymentMethodId: PaymentMethod;
        readonly selectedPaymentMethodType: SelectedPaymentMethodType;
        readonly chosenOption?: {
            readonly totalAmount: {
                readonly grossPrice: number;
                readonly netPrice: number;
                readonly tax: number;
            };
            readonly downPayment: {
                readonly netPrice: number;
                readonly tax: number;
                readonly grossPrice: number;
            };
            readonly financedAmount: {
                readonly netPrice: number;
                readonly tax: number;
                readonly grossPrice: number;
            };
            readonly monthlyRate: {
                readonly netPrice: number;
                readonly tax: number;
                readonly grossPrice: number;
            };
            readonly rateCount: number;
        };
    } | null;
    readonly cart: {
        readonly cartTotals: {
            readonly totalAppliedDiscountPercentage: number | null;
            readonly totalsIncludingAllDiscounts: {
                readonly netPrice: number;
                readonly tax: number;
                readonly grossPrice: number;
            } | null;
            readonly totalsWithoutDiscounts: {
                readonly netPrice: number;
                readonly tax: number;
                readonly grossPrice: number;
            };
        };
    };
    readonly " $refType": "ReviewCartTotalsDisplay_OrderFragment";
};
export type ReviewCartTotalsDisplay_OrderFragment$data = ReviewCartTotalsDisplay_OrderFragment;
export type ReviewCartTotalsDisplay_OrderFragment$key = {
    readonly " $data"?: ReviewCartTotalsDisplay_OrderFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ReviewCartTotalsDisplay_OrderFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grossPrice",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netPrice",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tax",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewCartTotalsDisplay_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "selectedPaymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethodId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "selectedPaymentMethodType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyOption",
              "kind": "LinkedField",
              "name": "chosenOption",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceV3",
                  "kind": "LinkedField",
                  "name": "totalAmount",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceV3",
                  "kind": "LinkedField",
                  "name": "downPayment",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceV3",
                  "kind": "LinkedField",
                  "name": "financedAmount",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceV3",
                  "kind": "LinkedField",
                  "name": "monthlyRate",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rateCount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "MonthlySelectedPaymentMethod",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CartTotals",
          "kind": "LinkedField",
          "name": "cartTotals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalAppliedDiscountPercentage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceV3",
              "kind": "LinkedField",
              "name": "totalsIncludingAllDiscounts",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceV3",
              "kind": "LinkedField",
              "name": "totalsWithoutDiscounts",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();
(node as any).hash = 'a53ed3dbf6a34b3d5a5f3ad90f89aadf';
export default node;
