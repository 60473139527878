import React, {useEffect, useRef, useState} from "react";
import {Expandable} from "./parts/Expandable.component";
import Cookie from "./cookie.png"
import {
	AcceptButton,
	BackDrop,
	ButtonContainer,
	CloseButton, DeclineButton,
	FirstLayerWrapper,
	Heading,
	HeadingWrapper, MoreDetailsButton, MoreDetailsButtonWrapper
} from "./CookieBanner.styles";

declare global {
	// noinspection JSUnusedGlobalSymbols
	interface Window {
		Cookiebot: {
			consent: {
				statistics: boolean
			}
			show: () => void
			hide: () => void
			submitCustomConsent: (optinPreferences: boolean, optinStatistics: boolean, optinMarketing: boolean) => void

		}
	}
}

export const CookieBanner = () => {

	const [consentGivenForStatistics, setConsentGivenForStatistics] = useState<boolean | null>(null)
	const [showFirstLayer, setShowFirstLayer] = useState(false)
	const showFirstLayerRef = useRef<boolean>();
	showFirstLayerRef.current = showFirstLayer

	useEffect(() => {
		const handleDialogDisplay = () => {
			if (showFirstLayerRef.current) {
				setShowFirstLayer(false)
			} else {
				setShowFirstLayer(true)
				window.Cookiebot.hide()
			}
		}

		const removeCookieBotFix = () => {
			const cookiebotDialogShowFix = document.getElementById("cookiebotDialogShowFix")
			if (!cookiebotDialogShowFix) return
			cookiebotDialogShowFix?.remove()
			window.removeEventListener("CookiebotOnDialogDisplay", handleFirstDialogDisplay)
			window.addEventListener("CookiebotOnDialogDisplay", handleDialogDisplay)
		}

		const handleFirstDialogDisplay = () => {
			window.Cookiebot.hide()
			removeCookieBotFix()
			setShowFirstLayer(true)
		}

		const consentChangeHandler = () => {
			if (window["Cookiebot"]) {
				setConsentGivenForStatistics(window.Cookiebot.consent.statistics)
			}
			removeCookieBotFix()
		}

		window.addEventListener("CookiebotOnDialogDisplay", handleFirstDialogDisplay)

		window.addEventListener("CookiebotOnConsentReady", consentChangeHandler)
		window.addEventListener("CookiebotOnAccept", consentChangeHandler)
		window.addEventListener("CookiebotOnDecline", consentChangeHandler)

		return () => {
			window.removeEventListener("CookiebotOnDialogDisplay", handleFirstDialogDisplay)
			window.removeEventListener("CookiebotOnConsentReady", consentChangeHandler)
			window.removeEventListener("CookiebotOnAccept", consentChangeHandler)
			window.removeEventListener("CookiebotOnDecline", consentChangeHandler)
		}
	}, []);

	useEffect(() => {
		if (consentGivenForStatistics) {
			setShowFirstLayer(false)

			const trackers = document.createElement('script');

			trackers.src = "/trackers.js";
			trackers.async = true;

			document.body.appendChild(trackers);

			return () => {
				document.body.removeChild(trackers);
			}
		}
	}, [consentGivenForStatistics]);

	const handleAcceptAll = () => {
		window.Cookiebot.submitCustomConsent(
			true, true, true
		)
		setShowFirstLayer(false)
	}

	const handleDeclineAll = () => {
		window.Cookiebot.submitCustomConsent(
			false, false, false
		)
		setShowFirstLayer(false)
	}

	const handleMoreDetails = () => {
		window.Cookiebot.show()
	}

	return <>
		{showFirstLayer &&
			<>
				<BackDrop/>
				<FirstLayerWrapper>
					<CloseButton onClick={handleDeclineAll} className={"icon-a_close"}></CloseButton>
					<HeadingWrapper>
						<img src={Cookie} height={47} alt={"cookie"}/>
						<Heading>Datenschutzeinstellungen</Heading>
					</HeadingWrapper>
					<Expandable></Expandable>
					<ButtonContainer>
						<DeclineButton onClick={handleDeclineAll}>Ablehnen</DeclineButton>
						<MoreDetailsButtonWrapper>
							<MoreDetailsButton onClick={handleMoreDetails}>
								Mehr Details
							</MoreDetailsButton>
						</MoreDetailsButtonWrapper>
						<AcceptButton onClick={handleAcceptAll}>Alle Akzeptieren</AcceptButton>
					</ButtonContainer>
				</FirstLayerWrapper>
			</>
		}
	</>
}