import {PayPalButton} from "react-paypal-button-v2";
import * as Sentry from "@sentry/react";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {OrderContainer, payAction} from "@thekeytechnology/thekey-academy-frontend-library";
import {selectCurrentUser} from "@thekeytechnology/framework-react";

interface OwnProps {
    order: OrderContainer
    values: any
}

export const PaypalFormPart = ({order, values}: OwnProps) => {
    const user = useSelector(selectCurrentUser)

    const dispatch = useDispatch();

    return <div className="paypal-button-wrapper">
        <PayPalButton
            createOrder={(data: any, actions: any) => {
                return actions.order.create({
                    purchase_units: [{
                        amount: {
                            currency_code: "EUR",
                            value: order.order.entity.priceTable.total.discountedGrossAmount.toFixed(2)
                        },
                        invoice_id: order.order.id!,
                        custom_id: user?.id!,
                        description: "thekey.academy Angebot " + order.baseOffer.entity.title,

                    }],
                    application_context: {
                        shipping_preference: "NO_SHIPPING"
                    }
                });
            }}
            onApprove={(data: any, actions: any) => {
                // Capture the funds from the transaction
                return actions.order.capture().then((details: any) => {
                    if (details.error) {
                        Sentry.captureException(details.error);
                        // dispatch(postMessageAction(Message.TYPE_DANGER, "Deine Zahlung konnte nicht abgeschlossen werden. Bitte überprüfe die Zahlungsmethode."))
                    } else {
                        dispatch(payAction(
                            order.order.id!,
                            {
                                dataType: "default",
                                paymentMethod: "paypal"
                            },
                            values.adsOptIn
                        ));
                    }
                })
            }}
            onError={(error: any) => {
                // dispatch(postMessageAction(Message.TYPE_DANGER, "Deine Zahlung konnte nicht abgeschlossen werden. Bitte überprüfe die Zahlungsmethode."))
                Sentry.captureException(error);
            }}
            options={{
                disableFunding: "sofort,sepa,giropay,card",
                clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
                currency: "EUR",
            }}
        />
    </div>
}
