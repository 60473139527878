import {combineEpics} from "redux-observable";
import {API_FETCH_LESSON} from "../../lesson/actions/fetch-lesson";
import {ApiCallAction, apiGet} from "@thekeytechnology/framework-react";

const fetchLesson$ = apiGet(
    {apiType: API_FETCH_LESSON, callType: API_FETCH_LESSON},
    (action: ApiCallAction<string>) => `/api/webapp/v1/lessons/by-id/${action.payload}`,
);

export const additionalCourseEpics$ = combineEpics(
    fetchLesson$
)
