import React, {useState} from "react";
import {distinctArray, elementGraphQLToElement} from "@thekeytechnology/thekey-academy-frontend-library";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {CollapsibleCard} from "../../../core/components/collapsible-card/CollapsibleCard";
import {Checkbox} from "../../../core/components/form/Checkbox";
import {ClearIndicator} from "../../../core/components/select/ClearIndicator";
import {graphql} from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {Screen} from "../../../core/components/screen/Screen";
import {GradientBackgroundHeader} from "../../../core/components/screen/GradientBackground";
import {AcademyButton} from "../../../core/components/button/AcademyButton";
import {CourseDetailSidebar} from "../../../course/components/detail/sidebar/CourseDetailSidebar";
import {
    QuestionCount,
    ReviewPreparationScreen_StartReviewMutation
} from "../../../../__generated__/ReviewPreparationScreen_StartReviewMutation.graphql";
import {ReviewPreparationScreen_CourseFragment$key} from "../../../../__generated__/ReviewPreparationScreen_CourseFragment.graphql";
import {ReviewPreparationScreen_CourseStateFragment$key} from "../../../../__generated__/ReviewPreparationScreen_CourseStateFragment.graphql";
import {ReviewPreparationScreen_ReviewOptionsConnectionFragment$key} from "../../../../__generated__/ReviewPreparationScreen_ReviewOptionsConnectionFragment.graphql";
import {ReviewProcessScreen_ElementsConnectionFragment$key} from "../../../../__generated__/ReviewProcessScreen_ElementsConnectionFragment.graphql";
import {Element} from "@thekeytechnology/thekey-academy-frontend-library";

const REVIEW_PREPARATION_SCREEN_START_REVIEW_MUTATION = graphql`
    mutation ReviewPreparationScreen_StartReviewMutation($courseId: ID!, $selectedLessonIds: [ID!]!, $questionCount: QuestionCount!) {
        Review {
            startReview(input: {courseId: $courseId, selectedLessonIds: $selectedLessonIds, questionCount: $questionCount}){
                elements {
                    edges {
                        node {
                            elementType
                            generalSettings {
                                points
                            }
                            id
                            ... on ClozeTextElement {
                                additionalWords
                                parts {
                                    partType
                                    ... on ClozePart {
                                        cloze
                                    }
                                    ... on TextPart {
                                        text
                                    }
                                }
                            }
                            ... on MultipleChoiceElement {
                                allCorrectAnswersNeedToBeGiven
                                allowMultipleAnswers
                                answerOptions {
                                    isCorrect
                                }
                            }
                            ... on OrderElement {
                                orderables {
                                    text
                                }
                            }
                            ... on PersologElement {
                                questions {
                                    answers {
                                        classCode
                                    }
                                }
                            }
                            ... on QuestionnaireElement {
                                classes {
                                    description
                                    image {
                                        url
                                    }
                                    pointsThreshold
                                    title
                                }
                                questions {
                                    reverseScale
                                    title
                                }
                            }
                        }
                    }
                    ...ReviewProcessScreen_ElementsConnectionFragment
                }
            }
        }
    }`

const REVIEW_PREPARATION_SCREEN_COURSE_FRAGMENT = graphql`
    fragment ReviewPreparationScreen_CourseFragment on Course {
        id
        ...CourseDetailSidebar_CourseFragment
    }`

const REVIEW_PREPARATION_SCREEN_COURSE_STATE_FRAGMENT = graphql`
    fragment ReviewPreparationScreen_CourseStateFragment on CourseState {
        ...CourseDetailSidebar_CourseStateFragment
    }`

const REVIEW_PREPARATION_SCREEN_REVIEW_OPTIONS_CONNECTION_FRAGMENT = graphql`
    fragment ReviewPreparationScreen_ReviewOptionsConnectionFragment on ReviewOptionsConnection {
        edges {
            node {
                module {
                    containerMeta {
                        title
                    }
                    id
                    modulePath {
                        moduleIndex
                    }
                }
                lessons {
                    containerMeta {
                        title
                    }
                    id
                    lessonPath {
                        lessonIndex
                        moduleIndex
                    }
                }
            }
        }
    }`

interface State {
    selectedLessonIds: string[];
    count: QuestionCount
}

interface OwnProps {
    courseFragment: ReviewPreparationScreen_CourseFragment$key;
    courseStateFragment?: ReviewPreparationScreen_CourseStateFragment$key | null;
    numUnseenCustomerMessages: number;
    newLicensesInAccount: boolean;
    onStartReviewCompleted: (elements: Element[] | undefined, elementsConnectionFragmentRef: ReviewProcessScreen_ElementsConnectionFragment$key | undefined) => void;
    reviewOptionsConnectionFragment: ReviewPreparationScreen_ReviewOptionsConnectionFragment$key;
}

export const ReviewPreparationScreen = ({
                                            courseFragment,
                                            courseStateFragment,
                                            numUnseenCustomerMessages,
                                            onStartReviewCompleted,
                                            newLicensesInAccount,
                                            reviewOptionsConnectionFragment
                                        }: OwnProps) => {
    const [state, setState] = useState<State>({
        selectedLessonIds: [],
        count: 'all'
    })

    const [startReview, startReviewIsInFlight] = useMutation<ReviewPreparationScreen_StartReviewMutation>(REVIEW_PREPARATION_SCREEN_START_REVIEW_MUTATION)

    const course = useFragment<ReviewPreparationScreen_CourseFragment$key>(REVIEW_PREPARATION_SCREEN_COURSE_FRAGMENT, courseFragment)

    const courseState = useFragment<ReviewPreparationScreen_CourseStateFragment$key>(REVIEW_PREPARATION_SCREEN_COURSE_STATE_FRAGMENT, courseStateFragment || null)

    const reviewOptions = useFragment<ReviewPreparationScreen_ReviewOptionsConnectionFragment$key>(REVIEW_PREPARATION_SCREEN_REVIEW_OPTIONS_CONNECTION_FRAGMENT, reviewOptionsConnectionFragment)

    const {t} = useTranslation("review")

    const options = (['five', 'ten', 'fifteen', 'twenty', 'twentyFive'] as QuestionCount[])
        .map((count, index) => ({
            key: index,
            label: t(`review-screen.counts.count-${count}`),
            value: count
        }))

    return <Screen
        sidebarSlot={<CourseDetailSidebar courseFragmentRef={course}
                                          courseStateFragmentRef={courseState}
                                          numUnseenCustomerMessages={numUnseenCustomerMessages}
                                          newLicensesInAccount={newLicensesInAccount}/>}
        floatingFooterSlot={
            <div className="d-flex align-items-end p-3">
                <AcademyButton
                    className={"ml-auto"}
                    disabled={startReviewIsInFlight || state.selectedLessonIds.length === 0}
                    text={t("review-screen.start-review")}
                    onClick={() => startReview(
                        {
                            variables: {
                                courseId: course.id,
                                selectedLessonIds: state.selectedLessonIds,
                                questionCount: state.count
                            },
                            onCompleted: body => {
                                onStartReviewCompleted(
                                    body.Review.startReview?.elements.edges?.filter(e => !!e).map(e => elementGraphQLToElement(e!.node)).filter(e => !!e).map(e => e!),
                                    body.Review.startReview?.elements,
                                )
                            }
                        })}
                />
            </div>
        }
        headerBarBackgroundSlot={<GradientBackgroundHeader height={250}/>}
        headerMargin={60}
    >
        <>
            <div className="tk-academy-text tk-academy-bold tk-color-waterblue-80">Individuelle Wiederholung</div>
            <h1 className="tk-academy-larger-bold mb-3 tk-color-darkblue">Welche Inhalte sollen in die Review eingezogen
                werden?</h1>

            <p className="tk-academy-text mb-4">Mit diesem Feature kannst Du Deinen bisherigen Wissensstand vertiefen,
                indem
                Du bereits gestellte
                Lehrgangsfragen wiederholst. Dazu kannst Du im Review Feature auswählen, aus welchem Modul und wie viele
                Fragen Du wiederholen möchtest. Hier gilt: jede Wiederholung hilft Dir, Deinen Lernerfolg zu steigern.
                Deshalb belohnen wir die Nutzung dieses Features auch mit zusätzlichen Auszeichnungen. </p>

            <div className="tk-academy-small-text tk-academy-semibold tk-color-waterblue-80 mb-2">Wähle die Anzahl der
                Fragen
                aus
            </div>
            <Select
                className="react-select category-select mb-5"
                classNamePrefix="react-select"
                components={{ClearIndicator}}
                placeholder={t("review-screen.counts.count-placeholder")}
                options={options}
                value={options.find(option => option.value === state.count)}
                onChange={(item: any) => setState({...state, count: item ? item.value : undefined})}
                getOptionValue={(opt: any) => opt.value}
                getOptionLabel={item => item.label}
            />

            <div className="tk-academy-small-text tk-academy-semibold tk-color-waterblue-80 mb-2">
                Wähle die Inhalte aus
            </div>

            <div>
                {reviewOptions.edges?.filter(e => !!e).map(e => e!.node).map(node => {
                    return <CollapsibleCard
                        className="tk-academy-card mb-3"
                        key={node.module.id}
                        indexText={
                            <div className="d-flex align-items-center">
                                <Checkbox
                                    className="mr-3"
                                    isChecked={
                                        node.lessons.map(childLesson => state.selectedLessonIds.includes(childLesson!.id!))
                                            .reduce((x, y) => x && y)
                                    }
                                    onToggle={selected => {
                                        const newSelections = selected ? distinctArray([
                                            ...state.selectedLessonIds,
                                            ...node.lessons.map(l => l!.id!)
                                        ]) : state.selectedLessonIds.filter(lId => node.lessons.map(l => l.id).indexOf(lId) === -1);
                                        setState({...state, selectedLessonIds: newSelections})
                                    }}/>
                                <div
                                    className="tk-academy-text tk-color-waterblue-80">M{node.module.modulePath.moduleIndex + 1}</div>
                            </div>
                        }
                        title={node.module.containerMeta.title}
                        renderChildren={() => {
                            return node.lessons
                                .map(lesson => {
                                    return <div key={lesson.id}
                                                className="d-flex tk-bg-light tk-border-radius p-2 align-items-center">
                                        <Checkbox
                                            className="mr-3"
                                            isChecked={state.selectedLessonIds.includes(lesson.id)}
                                            onToggle={(selected: boolean) => {
                                                const newSelections = selected ? distinctArray([
                                                    ...state.selectedLessonIds,
                                                    lesson.id
                                                ]) : state.selectedLessonIds.filter(lId => lId !== lesson!.id);
                                                setState({...state, selectedLessonIds: newSelections})
                                            }}
                                        />
                                        <div>
                                            <div
                                                className="tk-academy-small-text tk-academy-bold tk-color-waterblue-80">Lektion {lesson.lessonPath.moduleIndex + 1}. {lesson.lessonPath.lessonIndex + 1}</div>
                                            <div
                                                className="tk-color-darkblue tk-academy-bold tk-academy-text">{lesson.containerMeta.title}</div>
                                        </div>
                                    </div>
                                })
                        }}/>
                })}
            </div>
        </>
    </Screen>;
}
