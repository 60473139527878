import {DialogResult, dialogResultReadyAction, DialogSettings} from "@thekeytechnology/framework-react";
import * as React from "react";
import {createPortal} from "react-dom";
import {connect} from "react-redux";
import "./dialog-portal.scss"

interface StateProps {
    dialogSettings: DialogSettings,
    isVisible: boolean;
}

interface DispatchProps {
    dialogResultReady: (r: DialogResult) => void;
}

type Props = StateProps & DispatchProps;

class DialogPortalComponent extends React.Component<Props> {
    public render() {
        const {dialogSettings, isVisible, dialogResultReady} = this.props;

        if (!dialogSettings) {
            return <></>;
        }

        const modal = (
            <div className={"modal " + (isVisible ? "open" : "")}>
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header p-3">
                            <h2 className="mb-0">
                                {dialogSettings.title}
                            </h2>
                        </div>
                        <div className="p-3">
                            <div className="modal-body  text-center mt-5 mb-5">
                                <p className="question">{dialogSettings.question}</p>
                                <p className="lead" dangerouslySetInnerHTML={{__html: dialogSettings.explanation}}/>
                            </div>

                            <div className="button d-flex justify-content-center mb-5">
                                <button type="button" onClick={() => dialogResultReady(DialogResult.ACCEPT)}
                                        className={(dialogSettings.affirmativeButtonClass ?
                                            dialogSettings.affirmativeButtonClass :
                                            "btn btn-primary") + " mr-3"}>
                                    {dialogSettings.affirmativeText}
                                </button>
                                <button type="button" onClick={() => dialogResultReady(DialogResult.DENY)}
                                        className="btn btn-secondary">
                                    {dialogSettings.negativeText}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        const element = document.querySelector("#dialog");

        return element ? createPortal(
            modal,
            element
        ) : null;
    }
}

export const DialogPortal = connect<StateProps, DispatchProps, {}>(
    (state: any) => {
        return {
            dialogSettings: state.dialog.dialogSettings,
            isVisible: state.dialog.isVisible
        };
    },
    {
        dialogResultReady: dialogResultReadyAction
    }
)(DialogPortalComponent);
